import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadPropertyNewMutation } from './__generated__/useUploadPropertyNewMutation.graphql';

export const useUploadPropertyNew = (
  input: {
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadPropertyNewMutation(
        $input: _sys_property_bulk_upload_input!
      ) {
        _sys_property_bulk_upload(input: $input) {
          added_property_codes
          ignored_property_codes
          duplicated_count
        }
      }
    ` as GraphQLReturn<useUploadPropertyNewMutation>,
    {
      trackEvent: false,
      mapVariables: (file_reference_source: string) => async () => {
        return {
          input: {
            file_reference_source,
            file_source: input.file_source,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
