import { useCherreValue } from '@cherre-frontend/data-fetching';
import { PageHeader } from '@cherre-frontend/ui';
import React from 'react';
import { unassignedPropertyCount } from './query';

const getTitle = (count: number | undefined) => {
  switch (count) {
    case undefined:
      return '';
    case 0:
      return 'No Unassigned Properties';
    case 1:
      return '1 Unassigned Property';
    default:
      return `${count} Unassigned Properties`;
  }
};

const Header: React.FC = () => {
  const count = useCherreValue(unassignedPropertyCount());

  return (
    <PageHeader
      title={getTitle(count)}
      subtitle='Please review and create new batches'
      backLink
    />
  );
};

Header.displayName = 'SingleFileValidationPage/Header';

export default Header;
