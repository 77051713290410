import React, { useCallback, useMemo, useState } from 'react';
import {
  constSelector,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  Dialog,
  Typography,
  styled,
  InfoIcon,
  Alert,
  useTheme,
  RadioGroup,
  Radio,
  Table,
  MRT_ColumnDef,
  useTable,
  MRT_RowSelectionState,
  CancelIcon,
  Tooltip,
  LinearProgress,
  Box,
} from '@cherre-frontend/ui';
import { getSubmissionBatch, Dataset } from '../PropertyBatches/recoil';
import {
  bulkUploadModalState,
  getAvailablePropertiesSubmission,
  getFileEntities,
} from './recoil';
import { useAppContext } from '@cherre-frontend/core';
import {
  useAsyncCreateIngestFile,
  useCreateIngestFileProgress,
} from '../../../../hooks/useCreateIngestFile';
import { useUploadDatasetPush } from 'src/products/data-submission-portal/hooks/useUploadDatasetPush';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useCreateIngestFileAsyncMutation } from 'src/products/data-submission-portal/hooks/__generated__/useCreateIngestFileAsyncMutation.graphql';

export const Icon = styled(InfoIcon)`
  color: ${({ theme }) => theme.accents.brown.main};
`;

const WarningBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.brown.light};
  color: ${({ theme }) => theme.accents.brown.main};
  padding: 6px;
  .MuiAlert-icon {
    padding: 0px;
  }
  .MuiAlert-message {
    padding: 0px;
    font-size: 12px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

type BulkUploadModalStepsProps = {
  closeModal: () => void;
};

const BulkUploadModalFirstStep: React.FC<BulkUploadModalStepsProps> = ({
  closeModal,
}) => {
  const theme = useTheme();
  const submissionBatch = useCherreValue(getSubmissionBatch());
  const [modalState, setModalState] = useCherreState(bulkUploadModalState);

  const { uploadDatasetBulkPush } = useUploadDatasetPush();

  const onClickContinue = () => {
    uploadDatasetBulkPush(
      Number(modalState?.selectedDataset),
      submissionBatch?.submission_id
    );
  };

  const datasets = useMemo(() => {
    const datasetTemplates = submissionBatch?.property_batches
      .map((batch) => batch.properties_flattened_union.properties_datasets)
      .flat();
    const datasets = new Map<number, Dataset & { isMixedStructure: boolean }>();
    submissionBatch?.property_batches.map((propertyBatch) => {
      const { property_batch_datasets } = propertyBatch;

      property_batch_datasets.map((dataset) => {
        const { dataset: propertyDataset } = dataset;
        if (propertyDataset && !datasets.has(propertyDataset.dataset_id)) {
          const currentDataset = datasetTemplates?.filter(
            (dataset) => dataset.dataset_id === propertyDataset.dataset_id
          );
          datasets.set(propertyDataset.dataset_id, {
            ...propertyDataset,
            isMixedStructure: currentDataset
              ? !currentDataset.every(
                  (value) => value.template_id === currentDataset[0].template_id
                )
              : false,
          });
        }
      });
    });

    return Array.from(datasets.values()).sort((a, b) =>
      a.dataset_label.localeCompare(b.dataset_label)
    );
  }, [submissionBatch]);

  return (
    <Grid padding='20px'>
      <Grid marginBottom='20px'>
        <Typography variant='h5' fontWeight='600' marginBottom='6px'>
          Bulk Data Upload
        </Typography>
        <Typography
          variant='body2'
          color={theme.palette.grey[600]}
          marginBottom='6px'
        >
          Please select the dataset type that you would like to upload.
        </Typography>
        <WarningBanner
          severity='warning'
          icon={<Icon />}
          style={{ alignItems: 'center', borderRadius: '10px' }}
        >
          The data in this file will be distributed among properties in this
          batch.
        </WarningBanner>
      </Grid>

      <Grid marginBottom='20px'>
        <RadioGroup
          value={modalState?.selectedDataset}
          onChange={(_, v) =>
            setModalState((prev) => ({
              ...prev,
              selectedDataset: v,
              selectedDatasetName:
                datasets?.find((d) => d && d.dataset_id === Number(v))
                  ?.dataset_label ?? null,
            }))
          }
          style={{ gap: '10px' }}
        >
          {datasets.map(
            (dataset) =>
              dataset &&
              (dataset.isMixedStructure ? (
                <Radio
                  key={dataset.dataset_id}
                  label={
                    <Tooltip title='To access bulk upload for this dataset, ask your DSP Admin to ensure all entity IDs are set to expect the same submission format.'>
                      <Typography>{dataset.dataset_label}</Typography>
                    </Tooltip>
                  }
                  value={dataset.dataset_id}
                  disabled
                />
              ) : (
                <Radio
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  key={dataset.dataset_id}
                  label={<Typography>{dataset.dataset_label}</Typography>}
                  value={dataset.dataset_id}
                />
              ))
          )}
        </RadioGroup>
      </Grid>

      <Grid display='flex' justifyContent='flex-end' gap='10px'>
        <Button color='primary' onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={!modalState?.selectedDataset}
          onClick={onClickContinue}
          variant='contained'
          color='primary'
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

const BulkUploadModalSecondStep: React.FC<
  BulkUploadModalStepsProps & {
    createIngestFileCallback: (
      params: useCreateIngestFileAsyncMutation['variables']
    ) => void;
  }
> = ({ closeModal, createIngestFileCallback }) => {
  const theme = useTheme();
  const newIngest = useFeatureFlag('DSPNewIngestion');
  const file_source = newIngest ? 'unified' : 'flatfile';
  const [modalState, setModalState] = useCherreState(bulkUploadModalState);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const fileEntities = useCherreValue(
    modalState?.uploadedFileId
      ? getFileEntities({
          file_reference_source: modalState.uploadedFileId,
          file_source,
        })
      : constSelector([])
  );

  const availableProperties = useCherreValue(
    getAvailablePropertiesSubmission()
  );

  const tableData = useMemo(
    () =>
      fileEntities?.map((e) => {
        const property = availableProperties?.find(
          (p) =>
            p.properties_flattened_union?.entity_id.toLowerCase() ===
            e?.entity.toLowerCase()
        );
        return {
          entity_id: e?.entity,
          entity_name: property?.properties_flattened_union?.entity_name,
          property_batch_id: property?.property_batch_id,
          is_valid: property?.property_batch_datasets?.find(
            (d) => d.dataset_id === Number(modalState?.selectedDataset ?? '')
          )?.is_valid,
          enabled: property?.enabled,
        };
      }) ?? [],
    [fileEntities, availableProperties]
  );

  const columns = useMemo<MRT_ColumnDef<(typeof tableData)[number]>[]>(
    () => [
      {
        accessorKey: 'entity_id',
        header: 'Entity ID',
        size: 150,
        enableSorting: true,
        sortingFn: 'alphanumeric',
        Cell: ({ cell, row }) => (
          <Typography
            variant='body1'
            color={
              row.original.enabled
                ? row.original.is_valid === false
                  ? theme.accents.error.main
                  : undefined
                : theme.palette.grey['500']
            }
          >
            {cell.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'entity_name',
        header: 'Entity Name',
        size: 540,
        enableSorting: true,
        Cell: ({ cell, row }) => (
          <Typography
            variant='body1'
            color={
              row.original.enabled
                ? row.original.is_valid === false
                  ? theme.accents.error.main
                  : undefined
                : theme.palette.grey['500']
            }
          >
            {cell.getValue()}
          </Typography>
        ),
      },
    ],
    []
  );

  const table = useTable({
    data: tableData,
    columns,
    layoutMode: 'grid',
    enableStickyHeader: true,
    manualSorting: false,
    enableRowSelection: (row) =>
      (row.original.enabled && !!row.original.property_batch_id) ?? false,
    enableSelectAll: true,
    enableSorting: true,
    getRowId: (originalRow) => {
      return originalRow.property_batch_id?.toString() ?? '';
    },
    muiTableContainerProps: {
      sx: {
        minHeight: '300px',
        maxHeight: '600px',
        border: '1px solid var(--Gray-400, #BDBDBD)',
      },
    },
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      sorting: [
        {
          id: 'enabled',
          desc: true,
        },
        {
          id: 'is_valid',
          desc: true,
        },
        {
          id: 'entity_id',
          desc: false,
        },
      ],
    },
  });

  const showWarning = tableData.some((row) => !row.enabled);
  const showError = tableData.some((row) => row.is_valid === false);

  const onClickContinue = useCallback(() => {
    createIngestFileCallback({
      params: {
        dataset_id: parseInt(modalState?.selectedDataset ?? ''),
        file_reference_source: modalState?.uploadedFileId ?? '',
        property_batch_ids: Object.keys(rowSelection).map((k) => parseInt(k)),
        file_source,
      },
    });
    setModalState((state) => ({
      ...state,
      selectedProperties: modalState?.rowSelection
        ? Object.keys(modalState?.rowSelection).map((k) => parseInt(k))
        : [],
      step: 'PROCESSING_PROPERTIES',
    }));
  }, [
    modalState,
    rowSelection,
    createIngestFileCallback,
    file_source,
    setModalState,
  ]);

  return (
    <Grid padding='20px' minWidth={'800px'}>
      <Grid>
        <Typography variant='h5' fontWeight='600'>
          Confirm Properties for Upload
        </Typography>
        <Typography variant='body1' marginBottom='15px'>
          {`Select the properties you would like to upload for the ${
            modalState?.selectedDatasetName ?? ''
          } dataset.`}
        </Typography>
        <Grid display={'grid'} gap={'10px'}>
          {showError && (
            <WarningBanner
              severity='warning'
              icon={<CancelIcon htmlColor={theme.accents.error.main} />}
              style={{
                alignItems: 'center',
                borderRadius: '10px',
                color: theme.accents.error.main,
              }}
            >
              Properties that have previously failed validation.
            </WarningBanner>
          )}
          {showWarning && (
            <WarningBanner
              severity='warning'
              icon={<Icon />}
              style={{ alignItems: 'center', borderRadius: '10px' }}
            >
              Properties that are not authorized are disabled.
            </WarningBanner>
          )}
        </Grid>
      </Grid>

      <Grid marginTop='20px' marginBottom='20px' gap='10px'>
        <Table table={table} />
      </Grid>

      <Grid display='flex' justifyContent='flex-end' gap='10px'>
        <Button color='primary' variant='outlined' onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={!Object.keys(rowSelection).length}
          onClick={onClickContinue}
          variant='contained'
          color='primary'
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

const BulkUploadModalThirdStep: React.FC<BulkUploadModalStepsProps> = ({
  closeModal,
}) => {
  const [modalState] = useCherreState(bulkUploadModalState);

  const { processed_row_count, row_count } =
    useCreateIngestFileProgress({
      file_reference_souce: modalState?.uploadedFileId ?? '',
    }) ?? {};

  const percentComplete = Math.round(
    ((processed_row_count ?? 0) / (row_count ?? 1)) * 100
  );
  const processCompleted = percentComplete >= 100;

  const dataLoading =
    processed_row_count === undefined || row_count === undefined;

  const onConfirm = () => {
    closeModal();
  };

  return (
    <Grid padding='20px'>
      <Grid marginBottom='20px'>
        <Typography variant='h5' fontWeight='600'>
          Confirm Properties for Upload
        </Typography>
        <Typography variant='body1' marginBottom='15px'>
          {`Select the properties you would like to upload for the ${
            modalState?.selectedDatasetName ?? ''
          } dataset.`}
        </Typography>
        <WarningBanner
          severity='warning'
          icon={<Icon />}
          style={{
            alignItems: 'center',
            borderRadius: '10px',
            marginBottom: '15px',
          }}
        >
          Your file upload is in progress. Please refrain from closing this
          screen or refreshing the page.
        </WarningBanner>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant='h6'>Processing</Typography>
          {!dataLoading && (
            <Typography variant='body1'>
              {`${percentComplete}% · ${processed_row_count ?? 0} / ${
                row_count ?? 0
              } rows`}
            </Typography>
          )}
        </Box>
        <LinearProgress
          sx={{ height: '10px', borderRadius: '10px' }}
          variant={dataLoading ? 'indeterminate' : 'determinate'}
          color={'primary'}
          value={percentComplete}
        />
      </Grid>

      <Grid display='flex' justifyContent='flex-end' gap='10px'>
        <Button color='primary' onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={!processCompleted}
          variant='contained'
          color='primary'
          onClick={() => onConfirm()}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

const BulkUploadModal: React.FC = () => {
  const context = useAppContext();
  const createIngestFile = useAsyncCreateIngestFile();
  const [modalState, setModalState] = useCherreState(bulkUploadModalState);

  const closeModal = () => {
    setModalState((state) => ({
      ...state,
      selectedDataset: null,
      closedSelectedDataset: state.selectedDataset,
      step: 'SELECT_DATASET',
      open: false,
    }));
  };

  const createIngestFileCallback = useCallback(
    (params: useCreateIngestFileAsyncMutation['variables']) => {
      createIngestFile(params)
        .then((res) => {
          if (res?.errors || !res?.output?.data.property_batches) {
            context.showSnackbar({
              message: `Bulk import failed! Please try again.`,
              type: 'error',
            });
            console.error('Bulk import failed!', res);
          }
        })
        .catch((error) => {
          context.showSnackbar({
            message: `Bulk import failed! Please try again.`,
            type: 'error',
          });
          console.error('Bulk import failed!', error);
        });
    },
    []
  );

  if (!modalState?.open) {
    return null;
  }

  let modalStep: React.ReactElement;

  switch (modalState?.step) {
    case 'SELECT_DATASET':
      modalStep = <BulkUploadModalFirstStep closeModal={closeModal} />;
      break;
    case 'SELECT_PROPERTIES':
      modalStep = (
        <BulkUploadModalSecondStep
          closeModal={closeModal}
          createIngestFileCallback={createIngestFileCallback}
        />
      );
      break;
    case 'PROCESSING_PROPERTIES':
      modalStep = <BulkUploadModalThirdStep closeModal={closeModal} />;
      break;
    default:
      throw new Error('Invalid step');
  }

  return (
    <Dialog open={modalState?.open} maxWidth='md' onClose={() => closeModal()}>
      {modalStep}
    </Dialog>
  );
};

export default BulkUploadModal;
