import React, { useMemo } from 'react';
import {
  Typography,
  styled,
  Grid,
  MRT_SortingState,
  Panel,
  Autocomplete,
  Tooltip,
  Button,
} from '@cherre-frontend/ui';
import { Provider } from '../../recoil/getProviders';
import { getUnassignedPropertiesPerProvider } from './query';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { PropertiesTable } from './PropertiesTable';
import { useCreateBatchForm } from 'src/products/data-submission-portal/hooks/useCreateBatchForm';
import { useHistory } from 'react-router';

const Container = styled(Grid)`
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 20px;
`;

const Title = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const Subtitle = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const getSubtitle = (count: number | undefined) => {
  switch (count) {
    case undefined:
      return 'Unknown property count';
    case 1:
      return '1 Unassigned property';
    default:
      return `${count} Unassigned properties`;
  }
};

export type ProviderContainerProps = {
  data?: Provider;
};

const INITIAL_SORTING: MRT_SortingState = [{ id: 'entity_id', desc: false }];

const ProviderContainer: React.FC<ProviderContainerProps> = ({ data }) => {
  const { push } = useHistory();
  const [sorting, onSortingChange] =
    React.useState<MRT_SortingState>(INITIAL_SORTING);
  const properties = useCherreValue(
    getUnassignedPropertiesPerProvider({
      provider_id: data?.provider_id,
      sorting,
    })
  );
  const {
    formState,
    setFormState,
    errorMessage,
    isValid,
    options,
    createBatch,
  } = useCreateBatchForm({
    initialState: { provider_id: data?.provider_id },
    propertyIds: () => properties?.map((p) => p.property_id) ?? [],
    onCompleted: () => push('/dsp/batch-management'),
  });

  const submissionTypes = useCherreValue(options.submissionTypes);

  const submissionTypeErrorMessage = useMemo(() => {
    const selectedSubmissionTypes = formState.submission_types;

    if (!selectedSubmissionTypes?.length || !submissionTypes?.length) {
      return '';
    }

    const propertiesWithNoReviewersSubmissionType = (type: number) => {
      return (
        properties?.filter(
          (p) => !p?.reviewers?.some((r) => r.submission_type_id === type)
        ) ?? []
      );
    };

    const noReviewers = selectedSubmissionTypes
      .map((type) => ({
        submissionType: submissionTypes.find((t) => t.value === type)?.label,
        propertiesWithNoReviewers:
          propertiesWithNoReviewersSubmissionType(type),
      }))
      .filter((p) => p.propertiesWithNoReviewers.length);

    if (!noReviewers.length) {
      return '';
    }

    const message = noReviewers
      .map((p) => {
        const entityIds = p.propertiesWithNoReviewers
          .map((p) => p.entity_id)
          .join(', ');
        const submissionTypeLabel = p.submissionType;

        return `No reviewers assigned for the selected submission type, ${submissionTypeLabel}, for ${entityIds}. Please review the property configuration.`;
      })
      .join(' ');

    return message;
  }, [formState.submission_types, properties, submissionTypes]);

  return (
    <Container container gap='10px'>
      <Grid item xs={12}>
        <Title className='suspend'>
          {data?.provider_name || 'Unknown Provider'}
        </Title>
      </Grid>
      <Grid item xs={12}>
        <Subtitle className='suspend'>
          {getSubtitle(properties?.length)}
        </Subtitle>
      </Grid>
      <Grid item xs={12}>
        {data?.provider_id ? (
          <Panel id={`PropertiesTableForProvider-${data.provider_id}`}>
            <PropertiesTable
              provider_id={data.provider_id}
              sorting={sorting}
              onSortingChange={onSortingChange}
            />
          </Panel>
        ) : (
          <PropertiesTable
            sorting={sorting}
            onSortingChange={onSortingChange}
          />
        )}
      </Grid>
      <Grid item minWidth='300px' maxWidth='600px'>
        <Autocomplete
          multiple
          label='Type'
          placeholder='Select batch type'
          selector={options.submissionTypes}
          onChange={(_, v) =>
            setFormState({ submission_types: v?.map((i) => i.value) })
          }
          getSelectedOption={(opt) =>
            formState?.submission_types?.includes(opt.value) ?? false
          }
          getOptionLabel={(opt) => opt.label}
        />
      </Grid>
      <Grid item width='200px'>
        <Autocomplete
          label='Period'
          placeholder='Select period'
          selector={options.submissionPeriod}
          onChange={(_, v) => setFormState({ submission_period: v?.value })}
          getSelectedOption={(opt) =>
            opt.value.isSame(formState?.submission_period)
          }
          getOptionLabel={(opt) => opt.label}
        />
      </Grid>
      <Grid item width='160px'>
        <Autocomplete
          label='Due Date'
          placeholder='Select due date'
          selector={options.dueDate}
          onChange={(_, v) => setFormState({ due_date: v?.value })}
          getSelectedOption={(opt) => opt.value === formState.due_date}
          getOptionLabel={(opt) => opt.label}
        />
      </Grid>
      <Grid item>
        <Tooltip title={errorMessage || submissionTypeErrorMessage}>
          <div>
            <Button
              variant='contained'
              color='primary'
              disabled={!isValid || !!submissionTypeErrorMessage}
              onClick={createBatch}
            >
              Create Batch
            </Button>
          </div>
        </Tooltip>
      </Grid>
    </Container>
  );
};

export default ProviderContainer;
