import { useAppContext } from '@cherre-frontend/core';
import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import jsonLogic from 'json-logic-js';
import moment from 'moment';
import { useCallback, useLayoutEffect } from 'react';
import { graphql } from 'react-relay';
import { z } from 'zod';
import { useJsonLogicGetReportConfigQuery } from './__generated__/useJsonLogicGetReportConfigQuery.graphql';
import { getDSPUser } from '../recoil/getUser';

// add custom operations to json logic

jsonLogic.add_operation('fromEntries', (...params) =>
  Object.fromEntries(params)
);

jsonLogic.add_operation('moment', (a, b) => {
  const d = moment(a).format(b);
  if (d === 'Invalid date') {
    return '';
  }
  return d;
});

jsonLogic.add_operation('percent', (a, parentheses = false) => {
  const n = Number(typeof a === 'number' ? a * 100 : 0);

  if (n < 0 && parentheses) {
    return `(${Math.abs(n).toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}%)`;
  }

  const value = n.toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  return `${value}%`;
});

jsonLogic.add_operation('percentage', (a, b = 2) => {
  const value = Number(typeof a === 'number' ? Math.abs(a) : 0).toLocaleString(
    undefined,
    {
      minimumFractionDigits: b,
    }
  );
  return `${value}%`;
});

jsonLogic.add_operation('numeric', (a, b = 2) => {
  const value = Number(typeof a === 'number' ? Math.abs(a) : 0).toLocaleString(
    undefined,
    {
      minimumFractionDigits: b,
    }
  );
  return value;
});

jsonLogic.add_operation('coalesce', (a, b) => {
  return a ?? b;
});

type ReportConfig = {
  dollar_sign?: boolean;
  negative_sign?: boolean;
  decimal_places?: number;
  decimal_separator?: string;
  thousands_separator?: string;
};

const $providerConfig = graphQLSelector({
  query: graphql`
    query useJsonLogicGetReportConfigQuery($provider_id: Int) {
      sys_providers(where: { provider_id: { _eq: $provider_id } }) {
        report_config
      }
    }
  ` as GraphQLReturn<useJsonLogicGetReportConfigQuery>,
  mapVariables:
    () =>
    ({ get }) => ({ provider_id: get(getDSPUser()).provider_id ?? -1 }),
  mapResponse: (data) =>
    (data.sys_providers[0]?.report_config ?? {}) as ReportConfig,
});

export const useJsonLogic = () => {
  const { logger, telemetry } = useAppContext();
  const providerConfig = useCherreValue($providerConfig());
  useLayoutEffect(() => {
    jsonLogic.add_operation('currency', (a) => {
      const nStr = Math.abs(Number(a)).toFixed(
        providerConfig?.decimal_places ?? 2
      );
      const x = nStr.split('.');
      let x1 = x[0];
      const x2 =
        x.length > 1 ? (providerConfig?.decimal_separator ?? '.') + x[1] : '';
      const rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(
          rgx,
          '$1' + (providerConfig?.thousands_separator ?? ',') + '$2'
        );
      }
      let prefix = '';
      if ((providerConfig?.negative_sign ?? true) && Number(a) < 0) {
        prefix += '-';
      }
      if (providerConfig?.dollar_sign ?? true) {
        prefix += '$';
      }
      return prefix + x1 + x2;
    });
  }, [providerConfig]);
  return useCallback(<T>(logic: any, data: any, schema?: z.ZodType<T>) => {
    try {
      const result = jsonLogic.apply(logic, data);
      return schema ? schema.parse(result) : result ?? null;
    } catch (e) {
      logger.error(e);
      telemetry.captureException(e);
      return null;
    }
  }, []);
};
