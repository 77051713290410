import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherreSetState,
  useCherrePartialState,
  useMutation,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { Button, Grid, Panel, Typography } from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import {
  addEditMappingDialogState,
  addEditMappingFormState,
  addEditMappingChecker,
} from '../recoil';
import {
  HeaderGrid,
  Container,
  LabelText,
  NameField,
  DialogTextField,
} from '../styles';
import { AddEditMappingDialogAddMutation } from './__generated__/AddEditMappingDialogAddMutation.graphql';
import { AddEditMappingDialogEditMutation } from './__generated__/AddEditMappingDialogEditMutation.graphql';
import { MappingTypes } from '../types';
import AddEditSourceCodeInput from './AddEditSourceCodeInput';
import AddEditSourceLabelInput from './AddEditSourceLabelInput';
import AddEditTargetCodeInput from './AddEditTargetCodeInput';
import AddEditTargetLabelInput from './AddEditTargetLabelInput';

export type AddEditMappingDialogProps = {
  providerName: string;
  targetOrganization: string;
  mappingType: MappingTypes;
  organizationId: number;
  mappingSetId: number;
};

const defaultState = {
  source_code: '',
  source_description: '',
  target_code: '',
  target_description: '',
  id: 0,
  custom_attributes: [],
};

const AddEditMappingDialogModal: React.FC<AddEditMappingDialogProps> = ({
  providerName,
  targetOrganization,
  mappingType,
  organizationId,
  mappingSetId,
}) => {
  const dialogState = useCherreValue(addEditMappingDialogState);

  const setAddEditMappingDialogState = useCherreSetState(
    addEditMappingDialogState
  );

  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingFormState
  );

  const validateForm = useMemo(() => {
    return addEditMappingChecker(mappingType)(mappingForm);
  }, [mappingForm, mappingType]);

  const addMappingSetValueMutation = useMutation(
    graphql`
      mutation AddEditMappingDialogAddMutation(
        $mapping_set_id: Int!
        $organization_id: Int!
        $source_code: String!
        $source_description: String
        $target_code: String
        $target_description: String
        $custom_attributes: jsonb
      ) {
        insert_sys_mapping_set_values_one(
          object: {
            mapping_set_id: $mapping_set_id
            organization_id: $organization_id
            source_mapping_code: $source_code
            source_mapping_label: $source_description
            target_mapping_code: $target_code
            target_mapping_label: $target_description
            custom_attributes: $custom_attributes
          }
        ) {
          mapping_set_value_id
        }
      }
    ` as GraphQLReturn<AddEditMappingDialogAddMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        if (validateForm.type === 'failure') {
          throw new Error('Form is not valid');
        }

        const custom_attributes = validateForm.value.custom_attributes.map(
          (attr) => [attr.slug, attr.value || null]
        );

        return {
          mapping_set_id: mappingSetId,
          organization_id: organizationId,
          source_code: validateForm.value.source_code,
          source_description: validateForm.value.source_description || null,
          target_code: validateForm.value.target_code || null,
          target_description: validateForm.value.target_description || null,
          custom_attributes: Object.fromEntries(custom_attributes),
        };
      },
      onCompleted: (result, ctx) => {
        ctx.showSnackbar({
          type: 'success',
          message: 'Mapping has been added',
        });
        ctx.recoil.set(addEditMappingFormState, defaultState);
        ctx.recoil.set(addEditMappingDialogState, (s) => ({
          ...s,
          isOpen: false,
        }));
      },
      onError: (result, ctx) => {
        if (Array.isArray(result) && result[0].message) {
          if (
            result[0].message ===
            'Uniqueness violation. duplicate key value violates unique constraint "mapping_set_values_mapping_set_id_source_mapping_code_key"'
          ) {
            ctx.showSnackbar({
              type: 'error',
              message:
                'This Source and Target code combination is already a record',
            });
          }
        }
        console.log('error result', result);
        console.log('error ctx', ctx);
      },
    }
  );

  const editMappingSetValueMutation = useMutation(
    graphql`
      mutation AddEditMappingDialogEditMutation(
        $id: Int!
        $source_code: String!
        $source_description: String
        $target_code: String
        $target_description: String
        $custom_attributes: jsonb
      ) {
        update_sys_mapping_set_values_by_pk(
          pk_columns: { mapping_set_value_id: $id }
          _set: {
            source_mapping_code: $source_code
            source_mapping_label: $source_description
            target_mapping_code: $target_code
            target_mapping_label: $target_description
            custom_attributes: $custom_attributes
          }
        ) {
          mapping_set_value_id
        }
      }
    ` as GraphQLReturn<AddEditMappingDialogEditMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        if (validateForm.type === 'failure') {
          throw new Error('Form is not valid');
        }

        const custom_attributes = validateForm.value.custom_attributes.map(
          (attr) => [attr.slug, attr.value || null]
        );

        return {
          id: validateForm.value.id,
          source_code: validateForm.value.source_code,
          source_description: validateForm.value.source_description || null,
          target_code: validateForm.value.target_code || null,
          target_description: validateForm.value.target_description || null,
          custom_attributes: Object.fromEntries(custom_attributes),
        };
      },
      onCompleted: (result, ctx) => {
        ctx.showSnackbar({
          type: 'success',
          message: 'Mapping has been edited',
        });
        ctx.recoil.set(addEditMappingFormState, defaultState);
        ctx.recoil.set(addEditMappingDialogState, (s) => ({
          ...s,
          isOpen: false,
        }));
      },
      onError: (result, ctx) => {
        if (Array.isArray(result) && result[0].message) {
          if (
            result[0].message ===
            'Uniqueness violation. duplicate key value violates unique constraint "mapping_set_values_mapping_set_id_source_mapping_code_key"'
          ) {
            ctx.showSnackbar({
              type: 'error',
              message:
                'This Source and Target code combination is already a record',
            });
          }
        }
        console.log('error result', result);
        console.log('error ctx', ctx);
      },
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed add mapping dialog',
    (ctx) => () => {
      ctx.recoil.set(addEditMappingDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
      ctx.recoil.set(addEditMappingFormState, defaultState);
    }
  );

  const onSubmit = () => {
    if (dialogState?.type === 'Add') {
      return addMappingSetValueMutation();
    }
    if (dialogState?.type === 'Edit') {
      return editMappingSetValueMutation();
    }
    return Promise.resolve();
  };

  return (
    <Container open={Boolean(dialogState?.isOpen)} onClose={onClose}>
      <Panel id='AddMappingDialog' config={{ logLevel: false }}>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          style={{ marginBottom: '24px' }}
        >
          <Typography variant='h5' style={{ fontWeight: 600 }}>
            {dialogState?.type === 'Edit' ? 'Edit Mapping' : 'Add Mapping'}
          </Typography>
        </HeaderGrid>

        <Grid
          container
          direction='row'
          spacing={2}
          style={{ marginBottom: '20px' }}
        >
          <Grid container item direction='column' xs={6} gap={2}>
            <LabelText variant='body1'>Source</LabelText>
            <Grid item>
              <NameField
                variant='outlined'
                size='small'
                value={providerName}
                disabled
              />
            </Grid>
            <Grid item>
              <AddEditSourceCodeInput mappingType={mappingType} />
            </Grid>
            <Grid item>
              <AddEditSourceLabelInput />
            </Grid>
          </Grid>
          <Grid container item direction='column' xs={6} gap={2}>
            <LabelText variant='body1'>Target</LabelText>
            <Grid item>
              <NameField
                variant='outlined'
                size='small'
                value={targetOrganization}
                disabled
              />
            </Grid>
            <Grid item>
              <AddEditTargetCodeInput mappingType={mappingType} />
            </Grid>
            <Grid item>
              <AddEditTargetLabelInput mappingType={mappingType} />
            </Grid>
          </Grid>
          <Grid container item direction='row' xs={12} gap={2}>
            {mappingForm?.custom_attributes.map((attr) => (
              <Grid key={attr.slug} item>
                <DialogTextField
                  variant='outlined'
                  size='small'
                  label={attr.label}
                  value={attr.value}
                  onChange={(e) => {
                    setMappingForm((oldMappingForm) => {
                      const newAttributes =
                        oldMappingForm.custom_attributes?.map((current) => ({
                          ...current,
                          value:
                            current.slug === attr.slug
                              ? e.target.value
                              : current.value,
                        }));

                      return {
                        ...oldMappingForm,
                        custom_attributes: newAttributes ?? [],
                      };
                    });
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='flex-end' gap={2}>
          <Grid item>
            <Button
              variant='text'
              style={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                setAddEditMappingDialogState((s) => ({
                  ...s,
                  isOpen: false,
                }));
                setMappingForm(defaultState);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              style={{
                width: dialogState?.type === 'Edit' ? '90px' : 'unset',
                padding: '6px 8px',
              }}
              disabled={validateForm.type === 'failure'}
              onClick={() => onSubmit()}
            >
              {dialogState?.type === 'Edit' ? 'Save' : 'Add Mapping'}
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};

export default AddEditMappingDialogModal;
