import React from 'react';
import { TabsConfig } from '../PropertyBatchAccordion.base/types';
import { PropertyBatch } from '../types';
import { TabsProps } from '@cherre-frontend/ui';
import { hasFile } from 'src/products/data-submission-portal/components/DatasetsTable/utils';
import { isInPreparation } from '../utils';
import { DatasetsTable } from '../../../../../components/DatasetsTable';
import { PackageReportsTable } from '../../../../../components/PackageReportsTable';
import {
  modalSearchState,
  packageReportModalState,
  reportSelectors,
  reportSlugModalState,
} from '../../../recoil';

export const useTabsConfigView = (propertyBatch: PropertyBatch): TabsConfig => {
  const [tabValue, setTabValue] = React.useState<TabsProps['value']>(0);

  const tabOnChange = (
    event: React.SyntheticEvent,
    newValue: TabsProps['value']
  ) => {
    setTabValue(newValue);
  };

  const disablePackageReports = propertyBatch.property_batch_datasets.some(
    (d) => !hasFile(d.dataset_status.dataset_status_description)
  );

  return {
    tabOnChange,
    tabValue,
    tabs: [
      {
        label: 'Package Reports',
        disabled: disablePackageReports,
        value: 0,
      },
      {
        label: 'Validation Reports',
        disabled: isInPreparation(
          propertyBatch.property_batch_stage.property_batch_stage_id
        ),
        value: 1,
      },
    ],
    tabPanels: [
      {
        value: 0,
        panel: (
          <PackageReportsTable
            disableLastRowBorder
            property_batch_id={propertyBatch.property_batch_id}
            modalState={packageReportModalState}
            modalSearchState={modalSearchState}
            reportSelectors={reportSelectors}
            reportSlugModalState={reportSlugModalState}
          />
        ),
      },
      {
        value: 1,
        panel: (
          <DatasetsTable property_batch_id={propertyBatch.property_batch_id} />
        ),
      },
    ],
  };
};
