//DEFINE BATCH DETAILS TEST IDS
export const PROVIDER_INPUT = 'PROVIDER_INPUT';
export const SUBMISSION_TYPE_INPUT = 'SUBMISSION_TYPE_INPUT';
export const MONTHLY_RADIO_BUTTON = 'MONTHLY_RADIO_BUTTON';
export const QUARTERLY_RADIO_BUTTON = 'QUARTERLY_RADIO_BUTTON';
export const SUBMISSION_PERIOD_INPUT = 'SUBMISSION_PERIOD_INPUT';
export const DUE_DATE_INPUT = 'DUE_DATE_INPUT';
export const NEXT_BUTTON = 'NEXT_BUTTON';

//SELECT PROPERTIES TEST IDS
export const BATCH_INFO = 'BATCH_INFO';
export const BACK_BUTTON = 'BACK_BUTTON';
export const CREATE_BATCH_BUTTON = 'CREATE_BATCH_BUTTON';
