import React from 'react';
import { Box } from '@cherre-frontend/ui';

import ReviewersSelectionForm from './ReviewersSelectionForm';
import WarningBox from './WarningBox';
import { useWarningBox } from '../hooks/useWarningBox';

const DifferentChainsForm: React.FC = () => {
  const { warningBoxState } = useWarningBox();

  return (
    <>
      <WarningBox {...warningBoxState} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 0,
        }}
      >
        <ReviewersSelectionForm reviewerSelectionType='Operational' />
        <ReviewersSelectionForm reviewerSelectionType='Financial' />
      </Box>
    </>
  );
};

export default DifferentChainsForm;
