import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { createBatchDialogOpenState } from '../../recoil';
import { Panel } from '@cherre-frontend/ui';
import { useAppContext } from '@cherre-frontend/core';
import { dialogStage } from './recoil';
import DefineBatchDetails from './DefineBatchDetails';
import SelectProperties from './SelectProperties';
import { Dialog } from 'src/products/data-submission-portal/components';

const width = {
  DefineBatchDetails: '640px',
  SelectProperties: '1000px',
};

const CreateSubmissionBatchModal: React.FC = () => {
  const { logger } = useAppContext();

  const isModalOpen = useCherreValue(createBatchDialogOpenState);
  const stage = useCherreValue(dialogStage) ?? '';
  const suspended = useIsSuspended();

  const onClose = useCherreEventWithRecoil(
    'user closed create batch dialog',
    (ctx) => () => {
      ctx.recoil.set(createBatchDialogOpenState, false);
    }
  );

  logger.info('current dialog stage', stage, suspended);

  return (
    <Dialog open={Boolean(isModalOpen)} width={width[stage]} onClose={onClose}>
      <Panel id='CreateBatchDialog' config={{ logLevel: false }}>
        {stage === 'DefineBatchDetails' ? <DefineBatchDetails /> : null}
        {stage === 'SelectProperties' ? <SelectProperties /> : null}
      </Panel>
    </Dialog>
  );
};

export default CreateSubmissionBatchModal;
