import {
  GraphQLReturn,
  graphQLSelector,
  useCherreStateDebounced,
  useCherreValue,
  useMutation,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  ConfirmationModal,
  DotSeparator,
  DownloadFileIcon,
  FormControl,
  Grid,
  PackageReportCommentIcon,
  SearchInput,
  Tooltip,
  Typography,
  UploadFileIcon,
  styled,
  useConfirmationModal,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useMemo } from 'react';
import { graphql } from 'react-relay';
import { useDownloadReportTable } from 'src/products/data-submission-portal/components/Reports/useDownloadTable';
import {
  PersonaScope,
  PropertyScope,
  usePersona,
} from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import RejectReportModal from '../RejectReportModal';
import { ReportHeaderGetPropertyInfoQuery } from './__generated__/ReportHeaderGetPropertyInfoQuery.graphql';
import {
  canReviewPackageReport,
  canUploadTSAQuery,
  reviewPackageReportMutation,
} from './queries';
import {
  ModalState,
  $packageReports,
  ModalSearchState,
  $packageReportState,
} from '../recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  ReportViewTypeSelect,
  useReportViewTypeSelector,
} from '../../Reports/ReportViewTypeSelector';
import { DownloadAdjustmentModal } from 'src/products/data-submission-portal/components/DownloadAdjustmentsModal';
import { downloadAdjustmentsModalOpenState } from 'src/products/data-submission-portal/recoil/dialog';

const DownloadButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  min-width: 0px;
  & .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ToggleCommentsButton = styled(Button, {
  shouldForwardProp: (prop) => !['active'].includes(prop.toString()),
})<{ active: boolean | undefined }>`
  color: ${({ theme, active }) =>
    active ? 'white' : theme.palette.primary.main};
  background-color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : 'white'};
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  min-width: 0px;
  & .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Separator = styled('div')`
  height: 34px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[400]};
`;

const ApproveButton = styled(Button)`
  width: 80px;
  background-color: ${({ theme }) => theme.accents.positive.main};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.accents.positive.main};
    opacity: 0.8;
  }
`;

const RejectButton = styled(Button)`
  width: 80px;
  background-color: ${({ theme }) => theme.accents.error.main};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.accents.error.main};
    opacity: 0.8;
  }
`;

const TooltipStyled = styled(Tooltip)`
  z-index: 99999 !important;
`;

const UploadTopsideAdjustmentButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  min-width: 0px;
  & .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const packageReportHeaderInfo = graphQLSelector({
  query: graphql`
    query ReportHeaderGetPropertyInfoQuery($property_batch_id: Int!) {
      sys_property_batches(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        property_batch_stage_id
        properties_flattened_union {
          parent_property_id
          property_id
          property_name
          entity_id
          property_models {
            property_model_slug
          }
        }
        property {
          property_name
          property_code
          property_id
        }
        submission {
          submission_name
          reporting_period_end_date
          user {
            sakura_user {
              first_name
              last_name
            }
          }
        }
      }
    }
  ` as GraphQLReturn<ReportHeaderGetPropertyInfoQuery>,
  mapVariables: (property_batch_id?: number) => () => ({
    property_batch_id: property_batch_id || -1,
  }),
  mapResponse: (rawResponse) => rawResponse.sys_property_batches[0],
});

interface ReportHeaderProps {
  sidebarOpen?: boolean;
  setSidebarOpen?: (value: boolean) => void;
  modalState: ModalState;
  modalSearchState: ModalSearchState;
  alertComponent?: React.ReactNode;
}

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  sidebarOpen,
  setSidebarOpen,
  modalState,
  modalSearchState,
  alertComponent,
}) => {
  const modalStateInfo = useCherreValue(modalState);
  const packageReports = useCherreValue(
    $packageReports(modalStateInfo?.property_batch_id)
  );
  const info = useCherreValue(
    packageReportHeaderInfo(modalStateInfo?.property_batch_id)
  );
  const [search, , setSearch] = useCherreStateDebounced(modalSearchState);
  const downloadReport = useDownloadReportTable();
  const persona = usePersona();
  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);

  const selectedReport = packageReports?.find(
    (r) => modalStateInfo?.report_slug === r.slug
  );

  const setDownloadAdjustmentsModalOpenState = useCherreSetState(
    downloadAdjustmentsModalOpenState
  );

  const state = useCherreValue(
    $packageReportState({
      property_batch_id: modalStateInfo?.property_batch_id,
      persona,
    })
  );

  const currentState = useMemo(
    () => state?.find((p) => p.package_report_id === selectedReport?.id),
    [selectedReport?.id, state]
  );

  const reviewPackageReport = useMutation(reviewPackageReportMutation, {
    mapVariables:
      (input: { approve: boolean; comment?: string }) => async () => {
        if (!modalStateInfo) {
          throw new Error(`no modal open`);
        }

        const { report_slug, property_batch_id } = modalStateInfo;
        const report = packageReports?.find((r) => r.slug === report_slug);
        if (!report) {
          throw new Error(`report for slug ${report_slug} not found`);
        }
        return {
          params: {
            approve: input.approve,
            comment: input.comment,
            package_report_id: report.id,
            property_batch_id: property_batch_id,
          },
        };
      },
    onCompleted: (data, ctx) => {
      const is_approved = data._sys_review_package_report.is_reviewed;
      ctx.showSnackbar({
        message: `The report was ${is_approved ? 'approved' : 'rejected'}`,
        type: 'success',
      });
    },
  });

  const { modalProps } = useConfirmationModal();

  const handleReportRejection = async (comment: string) =>
    reviewPackageReport({ approve: false, comment }).then(() =>
      setRejectModalOpen(false)
    );

  const propertyBatchId = modalStateInfo?.property_batch_id;
  const canReview = useCherreValue(canReviewPackageReport(propertyBatchId));
  const {
    canUploadTSA,
    tsaPropertyBatchDatasetId,
    property_code,
    dataset_id,
    submission_name,
    dataset_slug,
  } = useCherreValue(canUploadTSAQuery(propertyBatchId)) ?? {};
  const remindersFF = useFeatureFlag('DSPReminders');

  const [reportViewType] = useReportViewTypeSelector();

  if (!selectedReport) {
    return null;
  }

  const filename = `${info?.submission.submission_name}_${selectedReport.label}_${info?.submission.user.sakura_user?.first_name}_${info?.submission.user.sakura_user?.last_name}.xlsx`;

  let reportLabelSuffix = '';
  switch (reportViewType) {
    case 'adjusted':
      reportLabelSuffix = ' (Adjusted)';
      break;
    case 'combined':
      reportLabelSuffix = ' (Combined)';
      break;
    default:
      break;
  }

  const isCombinedPackageReport = !info?.properties_flattened_union;

  const Scope: React.FC = useMemo(
    () =>
      ({ children }) =>
        isCombinedPackageReport ? (
          <PropertyScope propertyId={info?.property.property_id ?? null}>
            {children}
          </PropertyScope>
        ) : (
          <PropertyScope property={info?.properties_flattened_union ?? null}>
            {children}
          </PropertyScope>
        ),
    [info, isCombinedPackageReport]
  );

  return (
    <Scope>
      <DownloadAdjustmentModal
        report_name={selectedReport.label}
        tsa_property_batch_dataset_id={tsaPropertyBatchDatasetId}
        property_code={property_code}
        dataset_id={dataset_id || 0}
        dataset_slug={dataset_slug}
        submission_name={submission_name}
      />
      <ConfirmationModal {...modalProps} />
      <RejectReportModal
        open={rejectModalOpen}
        handleCancel={() => setRejectModalOpen(false)}
        handleConfirm={handleReportRejection}
      />
      <Grid display='grid' gap={2} style={{ paddingBottom: '20px' }}>
        <Grid container direction={'row'}>
          <Grid item xs>
            <Typography variant='h3'>
              {selectedReport.label} Report{reportLabelSuffix}
            </Typography>
            <Typography variant='body1'>
              <DotSeparator>
                {`ID ${
                  info?.properties_flattened_union?.entity_id ??
                  info?.property.property_code
                }`}
                {info?.properties_flattened_union?.property_name ??
                  info?.property.property_name}
                {`Period Ended: ${moment(
                  info?.submission.reporting_period_end_date
                ).format('MMM DD, YYYY')}`}
              </DotSeparator>
            </Typography>
          </Grid>
          <Grid item>
            <Grid display='flex' gap={1} alignItems={'center'}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <ReportViewTypeSelect tsa={canUploadTSA} />
              </FormControl>
              {canReview && !isCombinedPackageReport && (
                <PersonaScope persona={'reviewer'}>
                  <TooltipStyled title='Approve Report'>
                    <div>
                      <ApproveButton
                        onClick={() => reviewPackageReport({ approve: true })}
                        disabled={currentState?.is_reviewed === true}
                      >
                        Approve
                      </ApproveButton>
                    </div>
                  </TooltipStyled>

                  <TooltipStyled title='Reject Report'>
                    <div>
                      <RejectButton
                        onClick={() => setRejectModalOpen(true)}
                        disabled={currentState?.is_reviewed === false}
                      >
                        Reject
                      </RejectButton>
                    </div>
                  </TooltipStyled>
                  <Separator />
                </PersonaScope>
              )}

              <SearchInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Tooltip title='Download Report'>
                <div>
                  <DownloadButton
                    onClick={() =>
                      downloadReport(selectedReport.slug, filename)
                    }
                    startIcon={<DownloadFileIcon />}
                    variant='outlined'
                  />
                </div>
              </Tooltip>
              {canUploadTSA && (
                <PersonaScope persona={'reviewer'}>
                  <Tooltip title='Make topside adjustments to this report.'>
                    <div>
                      <UploadTopsideAdjustmentButton
                        onClick={() => {
                          setDownloadAdjustmentsModalOpenState(true);
                        }}
                        startIcon={<UploadFileIcon />}
                        variant='outlined'
                      />
                    </div>
                  </Tooltip>
                </PersonaScope>
              )}
              <Tooltip
                title={
                  sidebarOpen
                    ? `Close Comments${remindersFF ? ' & Reminders' : ''}`
                    : `View Comments${remindersFF ? ' & Reminders' : ''}`
                }
              >
                <div>
                  <ToggleCommentsButton
                    onClick={() => setSidebarOpen?.(!sidebarOpen)}
                    startIcon={<PackageReportCommentIcon />}
                    variant='outlined'
                    active={sidebarOpen as boolean}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {alertComponent ? alertComponent : null}
      </Grid>
    </Scope>
  );
};
