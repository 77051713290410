import {
  selector,
  GraphQLReturn,
  graphQLSelector,
  selectorFamily,
  DefaultValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { number, withDefault, array } from '@recoiljs/refine';
import { SubmissionRoute } from 'src/products/data-submission-portal/routes';
import { getSubmissionBatch } from '../recoil';
import { recoilSubmissionBulkActionsQuery } from './__generated__/recoilSubmissionBulkActionsQuery.graphql';

export const getSubmissionBulkActions = graphQLSelector({
  polling: true,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        submission_id: get(SubmissionRoute.routeParamSelector).submission_id,
      };
    },
  mapResponse: (response) => {
    return response._sys_workflow_get_available_bulk_actions_for_submission
      ?.actions;
  },
  query: graphql`
    query recoilSubmissionBulkActionsQuery($submission_id: Int!) {
      _sys_workflow_get_available_bulk_actions_for_submission(
        params: { submission_id: $submission_id }
      ) {
        actions {
          property_batch_ids
          slug
          name
        }
      }
    }
  ` as GraphQLReturn<recoilSubmissionBulkActionsQuery>,
});

// State related with the property batches selected for Bulk Actions:
const selectedCardsSearchParamsState =
  SubmissionRoute.searchParamsSelectorFamily({
    key: 'selectedCards',
    refine: withDefault(array(number()), []),
  });

// gets/updates the selected state of all property batches
export const selectedCardsState = selector<number[]>({
  key: 'selected-all-cards-state',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedCardsSearchParams = get(selectedCardsSearchParamsState);
      const selected = selectedCardsSearchParams as number[];

      const bulkActions = get(getSubmissionBulkActions());
      const ids =
        bulkActions?.flatMap((a) => a?.property_batch_ids).filter(Boolean) ??
        [];

      return selected.filter((id) => ids.includes(id));
    },
  set:
    () =>
    ({ set }, cardsToSelect: number[] | DefaultValue) => {
      if (cardsToSelect instanceof DefaultValue) {
        set(selectedCardsSearchParamsState, []);
        return;
      }

      set(selectedCardsSearchParamsState, cardsToSelect);
    },
});

// returns all selectable property batches in the submission
export const selectableCardsState = selector<number[]>({
  key: 'selectable-cards',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const bulkActions = get(getSubmissionBulkActions());

      if (!bulkActions) {
        return [];
      }

      return bulkActions
        .flatMap((action) => action?.property_batch_ids ?? [])
        .filter(Boolean);
    },
});

// returns true if the property batch can be selected
export const canCardBeSelectedState = selectorFamily<boolean, number>({
  key: 'card-can-be-selected-state',
  scoped: true,
  get:
    (cardId) =>
    ({ get }) => {
      const selectableCards = get(selectableCardsState);
      return selectableCards.includes(cardId) ?? false;
    },
});

// returns all selectable property batches in the submission currently in the page
export const selectableCardsInPageState = selector<number[]>({
  key: 'selectable-cards-in-page',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const submission = get(getSubmissionBatch());
      const selectableCards = get(selectableCardsState);

      if (!submission?.property_batches || !selectableCards) {
        return [];
      }

      const investmentPropertiesInPage = submission.property_batches.map(
        (pb) => pb.properties_flattened_union.parent_property_id
      );

      const batches = [
        ...submission.investment_property_batches.filter((ipb) =>
          investmentPropertiesInPage.includes(ipb.property_id)
        ),
        ...submission.property_batches,
      ];

      const propertyBatchesInPage = batches.map((pb) => pb.property_batch_id);

      return selectableCards.filter((id) => propertyBatchesInPage.includes(id));
    },
});

// returns true if all selectable property batches in the page are all selected for bulk upload
export const allCardsInPageSelectedState = selector<boolean>({
  key: 'all-cards-in-page-selected-state',
  scoped: true,
  get:
    (scope) =>
    ({ get }) => {
      const selectedCards = get(selectedCardsState(scope));
      const selectableCardsInPage = get(selectableCardsInPageState(scope));

      return (
        selectableCardsInPage.length > 0 &&
        selectableCardsInPage.every((card) => selectedCards.includes(card))
      );
    },
});

// returns true if all selectable property batches in the submission are selected for bulk upload
export const allCardsSelectedState = selector<boolean>({
  key: 'all-cards-selected-state',
  scoped: true,
  get:
    (scope) =>
    ({ get }) => {
      const selectedCards = get(selectedCardsState(scope));
      const selectableCards = get(selectableCardsState(scope));

      return (
        selectableCards.length > 0 &&
        selectableCards.length === selectedCards.length &&
        selectedCards.every((card) => selectableCards.includes(card))
      );
    },
});

// gets/updates the selected state of a single property batch
export const selectedCardState = selectorFamily<boolean, number>({
  key: 'selected-cards-state',
  scoped: true,
  get:
    (cardId) =>
    ({ get }) => {
      const selectedCards = get(selectedCardsState);
      return selectedCards.some((id) => id === cardId) ?? false;
    },
  set:
    (cardId: number) =>
    () =>
    ({ set }, newValue) => {
      const getNewList = (old: readonly number[]) => {
        const filtered = old.filter((id) => id !== cardId);
        if (newValue === true) {
          return [...filtered, cardId];
        } else {
          return filtered;
        }
      };

      set(selectedCardsSearchParamsState, getNewList);
    },
});
