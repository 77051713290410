import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Box,
  Grid,
  MuiTabs,
  Panel,
  Tab,
  TabsProps,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import { ModalState } from '../recoil';
import { CommentsTab } from './CommentsTab';
import { RemindersTab } from './RemindersTab';
import { packageReportSidebarTabState } from './recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const Container = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-top: 1px solid ${(props) => props.theme.palette.grey[400]};
  min-height: 0%;
  min-width: 340px;
`;

const CommentSectionGrid = styled(Grid)`
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TabsContainer = styled(Box)`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const TabPanelContainer = styled(Box)`
  overflow-y: hidden;
  height: 100%;
  display: flex;
`;

export type PackageReportSidebarProps = {
  modalState: ModalState;
};

export function PackageReportSidebar({
  modalState,
}: PackageReportSidebarProps) {
  const modalStateInfo = useCherreValue(modalState);
  const [tabValue, setTabValue] = useCherreState(
    packageReportSidebarTabState(modalStateInfo?.report_slug)
  );

  const tabOnChange = (
    event: React.SyntheticEvent,
    newValue: TabsProps['value']
  ) => {
    setTabValue(newValue);
  };

  const remindersFF = useFeatureFlag('DSPReminders');

  return (
    <Container>
      <CommentSectionGrid>
        <TabsContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <MuiTabs value={tabValue ?? 0} onChange={tabOnChange}>
              <Tab label='Comments' value={0} />
              {remindersFF && <Tab label='Reminders' value={1} />}
            </MuiTabs>
          </Box>
          <Panel
            id='tabs-sidebars'
            disableParent={false}
            style={{ height: '100%' }}
          >
            <TabPanelContainer>
              {tabValue === 0 ? <CommentsTab modalState={modalState} /> : null}
              {tabValue === 1 ? <RemindersTab modalState={modalState} /> : null}
            </TabPanelContainer>
          </Panel>
        </TabsContainer>
      </CommentSectionGrid>
    </Container>
  );
}
