import { Grid, PageContent, Typography, styled } from '@cherre-frontend/ui';
import React from 'react';
import KeyValue from './components/KeyValue';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { fileInfo } from './query';
import moment from 'moment';
import StatusChip, {
  ValidationStatusChipCodes,
} from '../../../../components/ValidationStatusChip';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 15px;
`;

const statusChip = (isValid: boolean, warningCount: number) => {
  if (isValid === true && warningCount > 0) {
    return (
      <StatusChip statusCode={ValidationStatusChipCodes.validation_warning} />
    );
  }
  if (isValid === true) {
    return (
      <StatusChip statusCode={ValidationStatusChipCodes.validation_passed} />
    );
  }
  if (isValid === false) {
    return (
      <StatusChip statusCode={ValidationStatusChipCodes.validation_failed} />
    );
  }
  return null;
};

const timestamp = (datetime?: string | null) => {
  if (datetime) {
    return moment(datetime).format('DD/MM/YYYY HH:mma');
  }
  return null;
};

const FileInfo: React.FC = () => {
  const info = useCherreValue(fileInfo());
  return (
    <PageContent>
      <Grid container width='min(20vw, 300px)' spacing='6px'>
        <Grid item xs={12}>
          <Title>File Info</Title>
        </Grid>
        <KeyValue label='File Name' value={info?.fileName} />
        <KeyValue label='Submission Batch' value={info?.submissionBatch} />
        <KeyValue label='Added By' value={info?.addedBy} />
        <KeyValue label='Checked On' value={timestamp(info?.checkedOn)} />
        <KeyValue
          label='Validation Status'
          value={statusChip(info?.isValid || false, info?.warningCount || 0)}
        />
      </Grid>
    </PageContent>
  );
};

FileInfo.displayName = 'SingleFileValidationPage/FileInfo';

export default FileInfo;
