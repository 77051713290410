import {
  GetCherreValue,
  GraphQLReturn,
  graphQLSelector,
  Writeable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  getPropertiesPagePropertiesSubscription,
  sys_properties_flattened_union_bool_exp,
} from './__generated__/getPropertiesPagePropertiesSubscription.graphql';
import { getPropertiesPagePropertiesTotalSubscription } from './__generated__/getPropertiesPagePropertiesTotalSubscription.graphql';
import {
  propertiesPageTableSearch,
  propertiesPageTableState,
  PropertiesTableFiltersState,
} from '../recoil';
import { getPropertyTypeCustomAttributes } from './getPropertyTypeCustomAttributes';

export type Property = Writeable<
  getPropertiesPagePropertiesSubscription['response']['sys_properties_flattened_union'][number]
>;

const buildPropertiesPageWhere = (
  get: GetCherreValue
): sys_properties_flattened_union_bool_exp => {
  const hasSearch = (get(propertiesPageTableSearch) ?? '').length > 0;
  const rawSearch = get(propertiesPageTableSearch) ?? '';
  const search = `%${rawSearch}%`;

  const _or: sys_properties_flattened_union_bool_exp[] = [
    {
      _and: [
        { property_name: { _ilike: search } },
        { property_name: { _is_null: false } },
      ],
    },
    { property_code: { _ilike: search } },
    { entity_id: { _ilike: search } },
    {
      _and: [{ address: { _ilike: search } }, { address: { _is_null: false } }],
    },
    { fund: { _ilike: search } },
  ];

  const customAttributes = get(getPropertyTypeCustomAttributes());
  customAttributes.forEach((customAttribute) => {
    _or.push({
      custom_attributes: {
        _contains: { [customAttribute.slug]: rawSearch },
      },
    });
  });

  const searchClause: sys_properties_flattened_union_bool_exp = !hasSearch
    ? {}
    : {
        _or,
      };

  // filters
  const filterClause: sys_properties_flattened_union_bool_exp[] = [];
  const { Provider, Status, Type, Fund } = get(PropertiesTableFiltersState);
  if (Provider !== 'Any Provider') {
    filterClause.push({
      provider: { provider_name: { _ilike: `%${Provider}%` } },
    });
  }
  if (Fund !== 'Any Fund') {
    filterClause.push({ fund: { _ilike: `%${Fund}%` } });
  }
  if (Type !== 'Any Type') {
    filterClause.push({ type: { _ilike: `%${Type}%` } });
  }
  if (Status !== 'Any Status') {
    filterClause.push({
      is_ready_for_submission: { _eq: Status === 'Active' },
    });
  }
  const where = {
    _and: [{ is_active: { _eq: true } }, { _and: filterClause }, searchClause],
  };
  return where;
};

export const getPropertiesPageProperties = graphQLSelector({
  query: graphql`
    subscription getPropertiesPagePropertiesSubscription(
      $where: sys_properties_flattened_union_bool_exp = {}
      $order_by: [sys_properties_flattened_union_order_by!] = []
      $limit: Int = 50
      $offset: Int = 0
    ) {
      sys_properties_flattened_union(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        property_id
        address
        entity_id
        entity_name
        property_code
        property_name
        property_mapping
        is_ready_for_submission
        fund
        is_active
        organization_id
        type
        custom_attributes
        provider {
          provider_name
          provider_id
        }
        parent_property_id
        mappings_count
        mapping_set_ids: properties_mapping_sets(
          where: { is_active: { _eq: true } }
        ) {
          mapping_set_id
        }
        coa_mappings: properties_mapping_sets(
          where: {
            is_active: { _eq: true }
            mapping_set: {
              mapping_field: { mapping_field_name: { _eq: "gl_account_code" } }
            }
          }
        ) {
          mapping_set_id
        }
        users_count
        users_preparers: properties_roles_users(
          where: {
            _and: { is_active: { _eq: true }, property_role_id: { _eq: 1 } }
          }
        ) {
          user_id
        }
        users_financial_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role: { property_role_set: { _eq: "reviewer" } }
              submission_type: { submission_type_slug: { _eq: "financial" } }
            }
          }
        ) {
          user_id
        }
        users_operational_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role: { property_role_set: { _eq: "reviewer" } }
              submission_type: { submission_type_slug: { _eq: "operational" } }
            }
          }
        ) {
          user_id
        }
        datasets_count
        properties_batch_validation_rules(where: { is_active: { _eq: true } }) {
          batch_validation_rule_id
          validation_mode
        }
        properties_dataset_validation_rules(
          where: { is_active: { _eq: true } }
        ) {
          dataset_validation_rule_id
          validation_mode
        }
        investment_properties_dataset_validation_rules(
          where: { is_active: { _eq: true } }
        ) {
          dataset_validation_rule_id
          validation_mode
        }
        properties_datasets(where: { is_active: { _eq: true } }) {
          dataset_id
        }
      }
    }
  ` as GraphQLReturn<getPropertiesPagePropertiesSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const tableState = get(propertiesPageTableState);

      // search input
      const where = buildPropertiesPageWhere(get);
      return {
        where,
        // supports relationships sorting e.g. provider.provider_name
        order_by: tableState?.sorting.map(
          (sort) =>
            sort.id
              .split('.')
              .reverse()
              .reduce<object | undefined>(
                (acc, val) =>
                  acc ? { [val]: acc } : { [val]: sort.desc ? 'desc' : 'asc' },
                undefined
              ) || []
        ),
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
      };
    },
  mapResponse: (resp) => {
    return resp.sys_properties_flattened_union;
  },
});

export const getPropertiesPagePropertiesTotal = graphQLSelector({
  query: graphql`
    subscription getPropertiesPagePropertiesTotalSubscription(
      $where: sys_properties_flattened_union_bool_exp = {}
    ) {
      total: sys_properties_flattened_union_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getPropertiesPagePropertiesTotalSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      return { where: buildPropertiesPageWhere(get) };
    },
  mapResponse: (resp) => {
    return resp.total;
  },
});
