/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  ForbiddenError,
  GraphQLReturn,
  RecoilState,
  graphQLSelector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { getDSPUser } from '../../recoil/getUser';
import { graphql } from 'react-relay';
import { recoilPropertyBatchPackageReportInfoQuery } from './__generated__/recoilPropertyBatchPackageReportInfoQuery.graphql';
import { recoilPropertyBatchesPackageReportInfoQuery } from './__generated__/recoilPropertyBatchesPackageReportInfoQuery.graphql';
import { recoilSubmissionQueryPackageReportsTableSubscription } from './__generated__/recoilSubmissionQueryPackageReportsTableSubscription.graphql';
import { PersonasType } from '../../packages/dsp-role-based-rendering/context';
import { recoilGetUserPropertyRoleStageIdsQuery } from './__generated__/recoilGetUserPropertyRoleStageIdsQuery.graphql';
import { recoilGetUnresolvedRemindersForPropertyBatchSubscription } from './__generated__/recoilGetUnresolvedRemindersForPropertyBatchSubscription.graphql';

export const $propertyBatchPackageReportInfo = graphQLSelector({
  query: graphql`
    subscription recoilPropertyBatchPackageReportInfoQuery(
      $property_batch_id: Int!
    ) {
      sys_property_batches_by_pk(property_batch_id: $property_batch_id) {
        property_batch_stage {
          property_batch_stage_id
        }
        package_reports(order_by: { package_report_label: asc }) {
          package_report_label
          package_report_id
          report @required(action: THROW) {
            report_slug
          }
        }
        comments {
          package_report_id
        }
        reminders(where: { is_resolved: { _eq: false } }) {
          package_report_id
        }
        property {
          entity_id
          property_id
        }
      }
    }
  ` as GraphQLReturn<recoilPropertyBatchPackageReportInfoQuery>,
  mapVariables:
    (property_batch_id: number = -1) =>
    () => ({ property_batch_id }),
  mapResponse: (response) => response.sys_property_batches_by_pk,
});

export const $propertyBatchesPackageReportInfo = graphQLSelector({
  query: graphql`
    subscription recoilPropertyBatchesPackageReportInfoQuery(
      $property_batch_ids: [Int!]
    ) {
      sys_property_batches(
        where: { property_batch_id: { _in: $property_batch_ids } }
      ) {
        package_reports(order_by: { package_report_label: asc }) {
          package_report_label
          package_report_id
          report @required(action: THROW) {
            report_slug
          }
        }
      }
    }
  ` as GraphQLReturn<recoilPropertyBatchesPackageReportInfoQuery>,
  mapVariables:
    (property_batch_ids: number[] = []) =>
    () => ({ property_batch_ids }),
  mapResponse: (response) => response,
});

export type PackageReport = NonNullable<
  NonNullable<
    recoilPropertyBatchPackageReportInfoQuery['response']['sys_property_batches_by_pk']
  >['package_reports']
>[0];

const $userPropertyRoleStageIds = graphQLSelector({
  resetCache: false,
  query: graphql`
    query recoilGetUserPropertyRoleStageIdsQuery(
      $user_id: Int!
      $property_batch_id: Int!
    ) {
      sys_property_batches(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        property {
          properties_roles_users(
            where: { user_id: { _eq: $user_id }, is_active: { _eq: true } }
          ) {
            property_role {
              property_batch_role_status {
                property_batch_stage_id
              }
            }
          }
        }
        have_investment_access: submission {
          property_batches(
            where: {
              property: {
                property_type: { property_type_slug: { _eq: "investment" } }
                to_property_relationships: {
                  from_property: {
                    property_batches: {
                      property_batch_id: { _eq: $property_batch_id }
                    }
                  }
                }
              }
            }
          ) {
            property {
              properties_roles_users(
                where: { user_id: { _eq: $user_id }, is_active: { _eq: true } }
              ) {
                property_role {
                  property_batch_role_status {
                    property_batch_stage_id
                  }
                }
              }
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetUserPropertyRoleStageIdsQuery>,
  mapVariables:
    (property_batch_id: number) =>
    ({ get }) => ({
      property_batch_id,
      user_id: get(getDSPUser()).user_id,
    }),
  mapResponse: (rawResponse) => {
    const hasInvestmentAccess =
      rawResponse.sys_property_batches[0]?.have_investment_access
        .property_batches[0]?.property.properties_roles_users[0];

    const propertyRoleUser = hasInvestmentAccess
      ? hasInvestmentAccess
      : rawResponse.sys_property_batches[0]?.property
          ?.properties_roles_users[0];

    const propertyBatchStageIds =
      propertyRoleUser?.property_role.property_batch_role_status.map(
        (status) => status.property_batch_stage_id || 0
      );

    if (!propertyBatchStageIds || propertyBatchStageIds.length === 0) {
      throw new ForbiddenError('User does not have access to this property');
    }

    return propertyBatchStageIds;
  },
});

export const $packageReportState = graphQLSelector({
  polling: true,
  query: graphql`
    subscription recoilSubmissionQueryPackageReportsTableSubscription(
      $where: sys_property_batch_package_reports_bool_exp!
    ) {
      sys_property_batch_package_reports(where: $where) {
        user_id
        is_reviewed
        reviewed_datetime
        package_report_id
        property_batch_id
        property_batch_package_report_id
      }
    }
  ` as GraphQLReturn<recoilSubmissionQueryPackageReportsTableSubscription>,
  mapVariables:
    (params: { persona: PersonasType; property_batch_id?: number }) =>
    ({ get }) => {
      if (params.persona === 'view_admin') {
        return {
          where: {
            property_batch_id: { _eq: params.property_batch_id || -1 },
          },
        };
      }
      const propertyStageIds = get(
        $userPropertyRoleStageIds(params.property_batch_id || -1)
      );

      return {
        where: {
          property_batch_id: { _eq: params.property_batch_id || -1 },
          _or: [
            {
              property_batch_stage_id: { _in: propertyStageIds },
            },
            {
              is_reviewed: { _eq: false },
            },
          ],
        },
      };
    },
  mapResponse: (rawResponse) => rawResponse.sys_property_batch_package_reports,
});

export const $packageReports = selectorFamily({
  key: 'submission-batch-details/get-package-reports',
  scoped: true,
  get:
    (property_batch_id?: number | undefined) =>
    ({ get }) => {
      if (!property_batch_id) {
        return [];
      }
      const batch = get($propertyBatchPackageReportInfo(property_batch_id));

      if (!batch) {
        return [];
      }

      return (
        batch.package_reports?.map((r) => ({
          label: r.package_report_label,
          slug: r.report.report_slug,
          id: r.package_report_id,
        })) || []
      );
    },
});

export const getUnresolvedRemindersForPropertyBatch = graphQLSelector({
  mapVariables: (property_batch_id: number) => () => {
    return {
      property_batch_id,
    };
  },
  mapResponse: (response) => response.sys_reminders,
  query: graphql`
    subscription recoilGetUnresolvedRemindersForPropertyBatchSubscription(
      $property_batch_id: Int!
    ) {
      sys_reminders(
        where: {
          is_resolved: { _eq: false }
          property_batch_id: { _eq: $property_batch_id }
        }
      ) {
        package_report_id
      }
    }
  ` as GraphQLReturn<recoilGetUnresolvedRemindersForPropertyBatchSubscription>,
});

export type ModalState = RecoilState<{
  property_batch_id: number;
  report_slug: string;
  property_id?: number;
} | null>;

export type ModalSearchState = RecoilState<string>;
