import {
  GraphQLReturn,
  NotFoundError,
  QueryResult,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { queryDatasetsSubscription } from './__generated__/queryDatasetsSubscription.graphql';
import { isInPreparation } from 'src/products/data-submission-portal/utils/propertyBatchStage';

export const $datasets = graphQLSelector({
  query: graphql`
    subscription queryDatasetsSubscription($property_batch_id: Int!) {
      sys_property_batches(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        property_batch_stage_id
        submission {
          is_closed
        }
        property_batch_datasets {
          property_batch_dataset_id
          updated_at_datetime
          is_valid
          dataset_row_count
          validated_datetime
          dataset @required(action: THROW) {
            dataset_label
          }
          dataset_status {
            dataset_status_description
          }
          batch_file {
            file_name
          }
          warning_count: property_batch_dataset_validations_aggregate(
            where: {
              is_valid: { _eq: false }
              validation_mode: { _eq: "soft" }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  ` as GraphQLReturn<queryDatasetsSubscription>,
  mapVariables: (property_batch_id: number) => () => ({ property_batch_id }),
  swr: (o, n) => o.params.property_batch_id !== n.params.property_batch_id,
  mapResponse: (rawResponse) => {
    const propertyBatch = rawResponse.sys_property_batches[0];
    if (!propertyBatch) {
      throw new NotFoundError('Property batch not found');
    }
    return {
      isInPreparation: propertyBatch.submission.is_closed
        ? false
        : isInPreparation(propertyBatch.property_batch_stage_id),
      datasets: propertyBatch.property_batch_datasets.map((d) => ({
        id: d.property_batch_dataset_id,
        name: d.dataset.dataset_label,
        validation: d.dataset_status.dataset_status_description,
        isValid: d.is_valid,
        warningCount: d.warning_count.aggregate?.count ?? 0,
        rowCount: d.dataset_row_count,
        filename: d.batch_file?.file_name,
        updatedOn: d.updated_at_datetime,
      })),
    };
  },
});

export type Dataset = QueryResult<typeof $datasets>['datasets'][number];
