import React, { useMemo, useState } from 'react';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Panel,
  Tooltip,
  InfoIcon,
  useTheme,
} from '@cherre-frontend/ui';
import { expandedCardState } from '../../PropertyBatches/recoil';
import { usePropertyGroupStatus } from '../hooks/usePropertyStatus';
import { Line } from './styles';
import { GenericPropertyBatchBaseAccordion } from './generic';
import { GroupedPropertyBatchBaseAccordionProps } from './types';
import { PropertyBatchTab, PropertyBatchTabTitle } from './PropertyBatchTab';
import { PropertyBatch } from '../types';

type View = 'individual' | 'combined';

const viewValues: View[] = ['individual', 'combined'];

const viewMenuItems = new Map<
  View,
  {
    text: string;
    tooltipText: string;
  }
>([
  [
    'individual',
    {
      text: 'Individual',
      tooltipText:
        'View submissions for each property individually within the group',
    },
  ],
  [
    'combined',
    {
      text: 'Combined',
      tooltipText:
        'View submissions aggregated for all properties within the group',
    },
  ],
]);

const ViewMenuItem: React.FC<{
  text: string;
  tooltipText: string;
}> = ({ text, tooltipText }) => {
  const theme = useTheme();

  return (
    <>
      {text}
      <Tooltip title={tooltipText}>
        <InfoIcon
          style={{
            width: 16,
            height: 16,
            color: theme.palette.grey[400],
          }}
        />
      </Tooltip>
    </>
  );
};

const IndividualTabs: React.FC<{
  propertyBatches: PropertyBatch[];
  useTabsConfig: GroupedPropertyBatchBaseAccordionProps['useTabsConfig'];
}> = ({ propertyBatches, useTabsConfig }) => {
  return (
    <>
      {propertyBatches.map((propertyBatch, i) => {
        const tabConfig = useTabsConfig(propertyBatch);

        return (
          <React.Fragment key={propertyBatch.property_batch_id}>
            <PropertyBatchTabTitle propertyBatch={propertyBatch} />
            <PropertyBatchTab tabConfig={tabConfig} />
            {i + 1 < propertyBatches.length && <Line />}
          </React.Fragment>
        );
      })}
    </>
  );
};

const CombinedTab: React.FC<{
  propertyBatches: PropertyBatch[];
  useTabsConfigCombined: GroupedPropertyBatchBaseAccordionProps['useTabsConfigCombined'];
}> = ({ propertyBatches, useTabsConfigCombined }) => {
  if (!useTabsConfigCombined) {
    return null;
  }

  const tabConfig = useTabsConfigCombined(propertyBatches);
  return <PropertyBatchTab tabConfig={tabConfig} />;
};

export const GroupedPropertyBatchBaseAccordion: React.FC<
  GroupedPropertyBatchBaseAccordionProps
> = ({
  propertyBatches,
  investmentPropertyBatch,
  alertComponent,
  useTabsConfig,
  useTabsConfigCombined,
  showViewToggle,
  ...props
}) => {
  const [view, setView] = useState<View>('individual');

  const expanded = useCherreValue(
    expandedCardState(investmentPropertyBatch.property_batch_id)
  );

  const chipStatus = usePropertyGroupStatus(
    propertyBatches.map((pb) => pb.role_status)
  );

  const batchHasComments = useMemo(() => {
    return propertyBatches.some(
      (propertyBatch) =>
        !!propertyBatch.comments_count.aggregate?.count ||
        !!propertyBatch.reminders_count.aggregate?.count
    );
  }, [propertyBatches]);

  return (
    <GenericPropertyBatchBaseAccordion
      {...props}
      id={investmentPropertyBatch.property_batch_id}
      hasComments={batchHasComments}
      chipStatus={chipStatus}
      accordionTopRightComponent={
        showViewToggle &&
        expanded && (
          <FormControl
            data-testid='view-select'
            onClick={(e) => e.stopPropagation()}
            sx={{
              m: 1,
              mr: 2,
              minWidth: 175,
            }}
            size='small'
          >
            <InputLabel id='batch-view-select-label'>View</InputLabel>
            <Select
              labelId='batch-view-select-label'
              value={view}
              renderValue={(value) => viewMenuItems.get(value)?.text}
              label='View'
              onChange={(e) => {
                setView(e.target.value as View);
              }}
            >
              {viewValues.map((view) => (
                <MenuItem
                  key={view}
                  sx={{ justifyContent: 'space-between' }}
                  value={view}
                >
                  <ViewMenuItem
                    {...(viewMenuItems.get(view) ?? {
                      text: '',
                      tooltipText: '',
                    })}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
      accordionHeaderComponent={
        <>
          Target ID{' '}
          {propertyBatches[0].properties_flattened_union.property_code} •{' '}
          {propertyBatches.length} Property Group
        </>
      }
    >
      {alertComponent}
      <Panel
        id={`property-batch-accordion-tabs-${propertyBatches
          .map((p) => p.property_batch_id)
          .join('-')}`}
      >
        {(!showViewToggle || view === 'individual') && (
          <IndividualTabs
            useTabsConfig={useTabsConfig}
            propertyBatches={propertyBatches}
          />
        )}
        {showViewToggle && view === 'combined' && (
          <CombinedTab
            propertyBatches={propertyBatches}
            useTabsConfigCombined={useTabsConfigCombined}
          />
        )}
      </Panel>
    </GenericPropertyBatchBaseAccordion>
  );
};
