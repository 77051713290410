/**
 * @generated SignedSource<<287bc461583ebcdd0f37a036c5bd1091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilReassignUserDetailsQuery$variables = {
  id: number;
};
export type recoilReassignUserDetailsQuery$data = {
  readonly user: ReadonlyArray<{
    readonly email: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly organization: {
      readonly organization_id: number;
      readonly organization_label: string;
    };
    readonly sys_user: {
      readonly provider: {
        readonly provider_id: number;
        readonly provider_name: string;
      } | null;
    } | null;
    readonly user_id: number;
  }>;
};
export type recoilReassignUserDetailsQuery = {
  response: recoilReassignUserDetailsQuery$data;
  variables: recoilReassignUserDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": "user_id",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_users",
  "kind": "LinkedField",
  "name": "sys_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "sys_providers",
      "kind": "LinkedField",
      "name": "provider",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": "organization",
  "args": null,
  "concreteType": "sakura_organization",
  "kind": "LinkedField",
  "name": "sakura_organization",
  "plural": false,
  "selections": [
    {
      "alias": "organization_id",
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "organization_label",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilReassignUserDetailsQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v1/*: any*/),
        "concreteType": "sakura_user",
        "kind": "LinkedField",
        "name": "sakura_user",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "RequiredField",
            "field": (v3/*: any*/),
            "action": "THROW",
            "path": "user.first_name"
          },
          {
            "kind": "RequiredField",
            "field": (v4/*: any*/),
            "action": "THROW",
            "path": "user.last_name"
          },
          {
            "kind": "RequiredField",
            "field": (v5/*: any*/),
            "action": "THROW",
            "path": "user.email"
          },
          (v6/*: any*/),
          {
            "kind": "RequiredField",
            "field": (v7/*: any*/),
            "action": "THROW",
            "path": "user.organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilReassignUserDetailsQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v1/*: any*/),
        "concreteType": "sakura_user",
        "kind": "LinkedField",
        "name": "sakura_user",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e07a7a70286873645397c7178c4419cb",
    "id": null,
    "metadata": {},
    "name": "recoilReassignUserDetailsQuery",
    "operationKind": "query",
    "text": "query recoilReassignUserDetailsQuery(\n  $id: Int!\n) {\n  user: sakura_user(where: {id: {_eq: $id}}) {\n    user_id: id\n    first_name\n    last_name\n    email\n    sys_user {\n      provider {\n        provider_name\n        provider_id\n      }\n    }\n    organization: sakura_organization {\n      organization_id: id\n      organization_label: name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "479eeae4149bc7f3c4b59d66148d366d";

export default node;
