import React from 'react';
import {
  useTable,
  Table,
  MRT_ColumnDef,
  Typography,
  styled,
  MRT_SortingState,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from '@cherre-frontend/ui';
import { DatasetsCell } from './DatasetsCell';

const columns: MRT_ColumnDef<any>[] = [
  { accessorKey: 'entity_id', header: 'Source ID', size: 0 },
  { accessorKey: 'property_code', header: 'Target ID', size: 0 },
  { accessorKey: 'property_mapping', header: 'Mapping', size: 0 },
  {
    accessorKey: 'entity_name',
    header: 'Name',
    size: 0,
  },
  {
    accessorKey: 'address',
    header: 'Address',
    size: 0,
  },
  {
    accessorKey: 'fund',
    header: 'Fund',
    size: 0,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    size: 0,
  },
  {
    accessorKey: 'datasets_count',
    header: 'Datasets',
    size: 0,
    Cell: DatasetsCell,
  },
];

const EmptyRowsFallback = styled(Typography)`
  display: grid;
  place-items: center;
  padding: 50px;
`;

export type PropertiesTableProps = {
  sorting: MRT_SortingState;
  onSortingChange: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
  rowSelection?: MRT_RowSelectionState;
  onRowSelectionChange?: React.Dispatch<
    React.SetStateAction<MRT_RowSelectionState>
  >;
  data?: any[];
  height?: string;
  muiSelectCheckboxProps?: MRT_TableOptions<any>['muiSelectCheckboxProps'];
};

const PropertiesTable: React.FC<PropertiesTableProps> = ({
  sorting,
  onSortingChange,
  rowSelection,
  onRowSelectionChange,
  data,
  height,
  muiSelectCheckboxProps,
}) => {
  const table = useTable({
    data,
    columns,
    enableRowSelection: Boolean(onRowSelectionChange),
    state: { rowSelection: rowSelection || {}, sorting },
    muiTableContainerProps: { sx: { height } },
    onRowSelectionChange,
    onSortingChange,
    getRowId: (row) => `${row.property_id}`,
    skeletonRowCount: 5,
    renderEmptyRowsFallback: () => (
      <EmptyRowsFallback>No Properties found</EmptyRowsFallback>
    ),
    muiSelectCheckboxProps,
  });

  return <Table table={table} />;
};

PropertiesTable.displayName = 'PropertiesTable';

export default PropertiesTable;
