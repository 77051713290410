import { styled, AlertTitle, Alert } from '@cherre-frontend/ui';

export const AlertTitleStyled = styled(AlertTitle)`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const AlertStyled = styled(Alert)`
  color: ${({ theme }) => theme.accents.info.main};
  padding: 6px;
  & .MuiAlert-message {
    padding: 0;
  }
`;
