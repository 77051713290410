import { PageContainer, Panel, styled } from '@cherre-frontend/ui';
import React from 'react';
import Header from './panels/Header';
import SubmissionInfo from './panels/SubmissionInfo';
import BatchValidationTable from './panels/BatchValidationTable';
import BatchValidationReportModal from './panels/BatchValidationReportModal';

const Container = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  width: 100%;
  gap: 20px;
  overflow: hidden;
`;

const BatchValidation: React.FC = () => {
  return (
    <PageContainer id='BatchValidationPage'>
      <Panel id='BatchValidationHeader' behavior='fallback'>
        <Header />
      </Panel>
      <Container>
        <Panel id='BatchValidationSubmissionInfo' behavior='fallback'>
          <SubmissionInfo />
        </Panel>
        <Panel
          id='BatchValidationTable'
          behavior='fallback'
          style={{ overflow: 'hidden' }}
        >
          <BatchValidationTable />
        </Panel>
        <Panel id='BatchValidationModal'>
          <BatchValidationReportModal />
        </Panel>
      </Container>
    </PageContainer>
  );
};

BatchValidation.displayName = 'BatchValidation';

export default BatchValidation;
