import React from 'react';
import { Grid, Alert, AlertTitle, styled, InfoIcon } from '@cherre-frontend/ui';

const InfoBannerInternal = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.info.light};
  color: ${({ theme }) => theme.accents.info.main};
  .MuiAlert-message {
    padding: 0px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

const Icon = styled(InfoIcon)`
  color: ${({ theme }) => theme.accents.info.main};
`;

export const InfoBanner: React.FC<{ periods: string[] }> = ({ periods }) => {
  return (
    <Grid item xs={12}>
      <InfoBannerInternal severity='info' icon={<Icon />}>
        <AlertTitle>Attention</AlertTitle>
        You are about to create new batches for the next submission period
        {periods.length > 1 ? 's' : ''}
        {periods.join(', ')}. They will include all properties associated with
        an organization.
      </InfoBannerInternal>
    </Grid>
  );
};

InfoBanner.displayName = 'InfoBanner';
