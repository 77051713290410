import React, { ReactNode } from 'react';
import { AlertStyled, AlertTitleStyled } from './styles';
import {
  CancelIcon,
  AlertProps,
  AlertColor,
  useTheme,
  Grid,
  CheckCircleIcon,
} from '@cherre-frontend/ui';

export const AlertWithCta: React.FC<
  Pick<AlertProps, 'icon' | 'title'> & {
    severity: AlertColor;
    testId: string;
    cta?: ReactNode;
  }
> = ({ severity, icon, title, children, testId, cta }) => {
  const theme = useTheme();

  const colorsMap = new Map<AlertColor, [string, string, ReactNode]>([
    [
      'error',
      [
        theme.accents.warning.light,
        theme.accents.error.main,
        <CancelIcon htmlColor={theme.accents.error.main} />,
      ],
    ],
    [
      'success',
      [
        theme.accents.warning.light,
        theme.accents.positive.main,
        <CheckCircleIcon htmlColor={theme.accents.positive.main} />,
      ],
    ],
    [
      'warning',
      [
        theme.accents.warning.light,
        theme.accents.brown.main,
        <CheckCircleIcon htmlColor={theme.accents.positive.main} />,
      ],
    ],
  ]);

  const [backgroundColor, color, severityIcon] = colorsMap.get(severity) ?? [];

  return (
    <AlertStyled
      data-testid={testId}
      severity={severity}
      title={title}
      icon={icon ?? severityIcon}
      sx={{
        '& .MuiAlert-message': { display: 'flex', flex: 1 },
        backgroundColor,
      }}
    >
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          backgroundColor,
          color,
        }}
      >
        <Grid item>
          <AlertTitleStyled>{title}</AlertTitleStyled>
          {children}
        </Grid>
        {cta && <Grid item>{cta}</Grid>}
      </Grid>
    </AlertStyled>
  );
};
