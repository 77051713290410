import { searchParamsSelector } from '@cherre-frontend/data-fetching';
import {
  nullable,
  object,
  string,
  withDefault,
  number,
  array,
  bool,
  writableObject,
  writableArray,
} from '@recoiljs/refine';

export const ingestFlowState = searchParamsSelector({
  key: 'ingest-upload-account-groupings-flow-state',
  refine: withDefault(
    object({
      signedUrl: nullable(string()),
      fileId: nullable(string()),
      selectedSheet: nullable(string()),
      activeStepKey: withDefault(string(), 'upload'),
      selectedHeaderRows: nullable(array(number())),
      validationResults: nullable(
        object({
          validation_id: string(),
          checks_summary: writableArray(
            writableObject({
              failed: bool(),
              failed_rows: writableArray(number()),
              field: string(),
              rule: string(),
            })
          ),
        })
      ),
    }),
    {
      signedUrl: null,
      fileId: null,
      selectedSheet: null,
      activeStepKey: 'upload',
      selectedHeaderRows: null,
      validationResults: null,
    }
  ),
  routes: ['/**'],
});
