import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { reopenBatchesDialogOpenState } from '../../recoil';
import { Dialog, DialogProps, styled, Panel } from '@cherre-frontend/ui';
import ReopenBatchesDialog from './ReopenBatchesDialog';

const Container = styled<React.FC<DialogProps & { width: string }>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
  }
`;

const ReopenBatchesModal: React.FC = () => {
  const isModalOpen = useCherreValue(reopenBatchesDialogOpenState)!;

  const onClose = useCherreEventWithRecoil(
    'user closed reopen batches dialog',
    (ctx) => () => {
      ctx.recoil.set(reopenBatchesDialogOpenState, false);
    }
  );

  return (
    <Container open={Boolean(isModalOpen)} width='1150px' onClose={onClose}>
      <Panel id='ReopenBatchesDialog' config={{ logLevel: false }}>
        <ReopenBatchesDialog />
      </Panel>
    </Container>
  );
};

export default ReopenBatchesModal;
