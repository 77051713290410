import {
  GraphQLReturn,
  Writeable,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { querygetUnassignedPropertiesPerProviderQuery } from './__generated__/querygetUnassignedPropertiesPerProviderQuery.graphql';

type OrderBy = NonNullable<
  querygetUnassignedPropertiesPerProviderQuery['variables']['order_by']
>;

export const getUnassignedPropertiesPerProvider = graphQLSelector({
  query: graphql`
    query querygetUnassignedPropertiesPerProviderQuery(
      $provider_id: Int!
      $order_by: [sys_properties_order_by!] = {}
    ) {
      sys_properties(
        where: {
          _not: { property_batches: {} }
          properties_roles_users: {
            is_active: { _eq: true }
            property_role_id: { _in: [2, 3, 4] }
          }
          properties_datasets: { is_active: { _eq: true } }
          provider_id: { _eq: $provider_id }
        }
        order_by: $order_by
      ) {
        organization_id
        provider_id
        property_id
        property_name
        property_code
        entity_id
        entity_name
        address
        type
        fund
        is_active
        is_sold
        sold_date
        created_at_datetime
        updated_at_datetime
        reviewers: properties_roles_users(
          where: {
            is_active: { _eq: true }
            property_role_id: { _in: [2, 3, 4] }
          }
        ) {
          submission_type_id
        }
        properties_datasets {
          dataset {
            dataset_label
            dataset_id
          }
        }
        properties_datasets_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<querygetUnassignedPropertiesPerProviderQuery>,
  mapVariables:
    ({
      provider_id,
      sorting,
    }: {
      provider_id?: number;
      sorting?: { id: string; desc: boolean }[];
    }) =>
    () => {
      if (!provider_id) {
        throw new Promise(() => {});
      }
      const order_by: Writeable<OrderBy> = [];
      if (sorting) {
        order_by.push(
          ...sorting.map(({ id, desc }) => ({ [id]: desc ? 'desc' : 'asc' }))
        );
      }
      return { provider_id, order_by };
    },
  mapResponse: (response) => response.sys_properties,
});
