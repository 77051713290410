import { useAppContext } from '@cherre-frontend/core';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  MuiButton,
  PageContainer,
  PageHeader,
  Paper,
  ReportProblemIcon,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';
import { reminderDrawerState } from 'src/components/RemindersDrawer/recoil';
import ReminderAlert from '../../components/ReminderAlert';
import {
  PersonaScope,
  SubmissionScope,
} from '../../packages/dsp-role-based-rendering/context';
import BulkUploadModal from './components/BulkUploadModal';
import { bulkUploadModalState } from './components/BulkUploadModal/recoil';
import { PropertyBatches } from './components/PropertyBatches';
import { SubmissionBatchKPIs } from './components/SubmissionBatchKPIs';
import { getSubmission, getUnresolvedReminders } from './recoil';
import { createPortal } from 'react-dom';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';

export default function SubmissionBatchDetailsPage() {
  const [bulkModalState, setModalState] = useCherreState(bulkUploadModalState);

  const setReminderDrawer = useCherreSetState(reminderDrawerState);

  const submissionBatch = useCherreValue(getSubmission());

  const unresolvedReminders = useCherreValue(getUnresolvedReminders());

  if (!submissionBatch) {
    return <Redirect to='/notfound' />;
  }

  const propertyNum =
    submissionBatch?.property_batches_aggregate.aggregate?.count;

  const dueDate = submissionBatch?.due_date;
  const due = dueDate && moment(dueDate).format('MMM Do');

  const ctx = useAppContext();

  const hasBulkFailedValidation =
    !!bulkModalState?.closedSelectedDataset &&
    submissionBatch?.property_batches?.some(
      (p) =>
        bulkModalState.selectedProperties.includes(p.property_batch_id) &&
        p.property_batch_datasets.find(
          (d) => d.dataset_id === Number(bulkModalState.closedSelectedDataset)
        )?.is_valid === false
    );

  const theme = useTheme();
  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!hasBulkFailedValidation) {
      return;
    }

    ctx.showSnackbar({
      message: '',
      type: 'error',
      autoHideDuration: 30000,
      content: (key) => (
        <Paper
          sx={{
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.grey[100],
            padding: '10px',
            width: '630px',
            borderRadius: '10px',
          }}
        >
          <Grid
            display='grid'
            gridTemplateColumns={'auto 1fr auto'}
            gap='10px'
            alignItems={'center'}
          >
            <ReportProblemIcon htmlColor={theme.accents.deals.main} />
            <Grid>
              <Typography variant='h6' fontSize={'14px'}>
                One or more Properties Failed Validation
              </Typography>
              <Typography
                variant='body1'
                fontSize={'12px'}
                color={theme.palette.grey[500]}
              >
                You may replace data for multiple properties by selecting Bulk
                Upload.
              </Typography>
            </Grid>
            <MuiButton onClick={() => closeSnackbar(key)}>Dismiss</MuiButton>
          </Grid>
        </Paper>
      ),
    });
  }, [hasBulkFailedValidation]);

  const onViewRemindersClicked = () => {
    const entityIds = submissionBatch.property_batches.map(
      (batch) => batch.property.entity_id
    );
    setReminderDrawer({
      isOpen: true,
      showCompleted: false,
      filterSelections: {
        Batch: [],
        'Entity ID': entityIds,
        Type: [submissionBatch.submission_type.submission_type_label],
      },
    });
  };

  const subtitle = useMemo<string | undefined>(() => {
    const reminders = unresolvedReminders?.filter((r) => {
      const propertyBatch = submissionBatch.property_batches.find(
        (p) => p.property.entity_id === r.property.entity_id
      );

      const reminderDatasets = new Set(
        r.package_report.package_reports_datasets.map((d) => d.dataset_id ?? 0)
      );

      const propertyBatchDatasets = new Set(
        propertyBatch?.property_batch_datasets.map((d) => d.dataset_id ?? 0)
      );

      const matchDatasets = [...reminderDatasets].every((d) =>
        propertyBatchDatasets.has(d)
      );

      return matchDatasets;
    });

    if (!reminders || reminders.length === 0) {
      return undefined;
    }

    // Reminders not added to current submission should be addressed now
    const currentReminders = reminders?.filter((reminder) => {
      return (
        reminder.submission.submission_id !== submissionBatch.submission_id
      );
    });

    // Reminders added to current submission are for future submissions
    const futureReminders = reminders?.filter((reminder) => {
      return (
        reminder.submission.submission_id === submissionBatch.submission_id
      );
    });

    if (currentReminders?.length > 0) {
      return `There ${currentReminders.length === 1 ? 'is' : 'are'} ${
        currentReminders.length
      } unresolved reminder${
        currentReminders.length === 1 ? '' : 's'
      } for this submission cycle.`;
    }

    return `There ${futureReminders.length === 1 ? 'is' : 'are'} ${
      futureReminders.length
    } unresolved reminder${
      futureReminders.length === 1 ? '' : 's'
    } for the next submission cycle.`;
  }, [unresolvedReminders]);

  return (
    <PageContainer id='SubmissionBatchDetailsPage'>
      <SubmissionScope submissionId={submissionBatch.submission_id}>
        <FeatureFlagGate flag='DSPReminders'>
          {subtitle &&
            createPortal(
              <ReminderAlert
                subtitle={subtitle}
                onViewRemindersClicked={onViewRemindersClicked}
                localStorageKey={`reminderBannerClosed-${submissionBatch.submission_id}`}
              />,
              document.body
            )}
        </FeatureFlagGate>

        <PageHeader
          title={submissionBatch?.submission_name ?? ''}
          subtitle={`${submissionBatch?.submission_type?.submission_type_label} • ${propertyNum} Properties • Due ${due}`}
          backLink
        >
          <PersonaScope persona='preparer'>
            <Button
              variant='contained'
              color='primary'
              hidden
              onClick={() =>
                setModalState((oldState) => ({
                  ...oldState,
                  open: true,
                }))
              }
            >
              Bulk Upload
            </Button>
          </PersonaScope>
        </PageHeader>
        <SubmissionBatchKPIs />
        <PropertyBatches numberOfProperties={propertyNum ?? 0} />
      </SubmissionScope>
      <BulkUploadModal />
    </PageContainer>
  );
}
