import { Chip, styled } from '@cherre-frontend/ui';

type ChipProps = {
  background: string;
  htmlColor: string;
};

export const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop) =>
    !['htmlColor', 'background'].includes(prop.toString()),
})<ChipProps>`
  background: ${({ background }) => background};
  color: ${({ htmlColor }) => htmlColor};
  .MuiChip-icon {
    color: inherit;
  }
`;
