import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getCoaFileQuery } from './__generated__/getCoaFileQuery.graphql';

export const getCOAFile = graphQLSelector({
  query: graphql`
    query getCoaFileQuery(
      $where: sys_organization_master_chart_of_account_values_bool_exp = {}
    ) {
      sys_organization_master_chart_of_account_values(where: $where) {
        account_code
        account_name
      }
    }
  ` as GraphQLReturn<getCoaFileQuery>,
  mapVariables: (id?: number) => () => {
    return {
      where: {
        master_chart_of_account_id: { _eq: id },
      },
    };
  },
  mapResponse: (resp) => {
    return {
      data: resp.sys_organization_master_chart_of_account_values,
    };
  },
});
