import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Button,
  Dialog,
  DialogProps,
  Grid,
  MRT_ColumnDef,
  Table,
  styled,
  useTable,
  Typography,
} from '@cherre-frontend/ui';
import React, { useEffect, useMemo } from 'react';
import { assignPropertiesConfirmationDialogOpenState } from '../recoil';
import { AssignProviderDialogHeader } from 'src/products/data-submission-portal/pages/properties/Panels/PropertiesPanel/components/AssignProviderDialog/AssignProviderDialogHeader';
import { getManyToOneChildPropertiesWithParams } from 'src/products/data-submission-portal/pages/properties/Panels/PropertiesPanel/components/AssignProviderDialog/recoil';
import { getAllProperties } from '../queries/getAllProperties';
import { AssignPropertiesDialogMutation$variables } from './__generated__/AssignPropertiesDialogMutation.graphql';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';

const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    min-width: 627px;
  }
`;

type AssignConfirmationDialogModalProps = {
  onClose: () => void;
  onSubmit: (params: AssignPropertiesDialogMutation$variables) => Promise<void>;
  selectedPropertyIds: number[];
};

const AssignConfirmationDialogModal: React.FC<
  AssignConfirmationDialogModalProps
> = ({ onClose, onSubmit, selectedPropertyIds }) => {
  const isModalOpen = useCherreValue(
    assignPropertiesConfirmationDialogOpenState
  );

  const properties = useCherreValue(getAllProperties());
  const provider_id =
    useCherreValue(ProviderDetailsRoute.routeParamSelector)?.provider_id ?? 0;

  const parentPropertyIds =
    (properties
      ?.filter((p) => selectedPropertyIds.includes(p.property_id))
      .map((p) => p.parent_property_id)
      .filter(Boolean) as number[]) ?? [];

  const data = useCherreValue(
    getManyToOneChildPropertiesWithParams({
      propertyIds: parentPropertyIds,
      ignorePropertyIds: selectedPropertyIds,
    })
  );

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'property_code',
          header: 'Target ID',
        },
        {
          accessorKey: 'entity_id',
          header: 'Source ID',
        },
        {
          accessorKey: 'provider.provider_name',
          header: 'Provider Name',
        },
      ] satisfies MRT_ColumnDef<any>[],
    []
  );

  const table = useTable({
    data,
    columns,
    muiTablePaperProps: {
      sx: {
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '10px',
      },
      elevation: 0,
    },
  });

  useEffect(() => {
    if (
      selectedPropertyIds.length &&
      isModalOpen &&
      data &&
      data.length === 0
    ) {
      onClose();
      onSubmit({
        property_ids: selectedPropertyIds,
        provider_id,
      });
    }
  }, [selectedPropertyIds.length, isModalOpen, data?.length]);

  if (data?.length === 0) {
    return null;
  }

  if (!isModalOpen) {
    return null;
  }

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Grid display={'grid'} gap={'20px'}>
        <Grid display={'grid'} gap={'8px'}>
          <AssignProviderDialogHeader
            title={'M:1 Property Groups'}
            subtitle={`${selectedPropertyIds?.length} ${
              selectedPropertyIds?.length === 1 ? 'Property' : 'Properties'
            } Selected`}
            onClose={onClose}
          />
          <Typography variant='body1' fontSize={12}>
            These properties are part of M:1 property groups. Are there any
            other properties within these property groups that you would like to
            select and assign to a provider?
          </Typography>
        </Grid>
        <Table table={table} />
        <Grid container direction='row' gap={2} justifyContent='flex-end'>
          <Grid item>
            <Button
              size='medium'
              variant='outlined'
              color='primary'
              onClick={onClose}
              sx={{
                border: 'none',
                minWidth: '73px',
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size='medium'
              variant='contained'
              color='primary'
              disabled={false}
              sx={{
                minWidth: '80px',
              }}
              onClick={() => {
                const property_ids = [
                  ...new Set([
                    ...selectedPropertyIds,
                    ...table
                      .getSelectedRowModel()
                      .flatRows.map((r) => r.original.property_id),
                  ]),
                ];
                onSubmit({
                  property_ids,
                  provider_id,
                });
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AssignConfirmationDialogModal;
