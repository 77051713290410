import { Grid, PageContent, Typography, styled } from '@cherre-frontend/ui';
import React from 'react';
import KeyValue from './components/KeyValue';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { fileInfo } from './query';
import moment from 'moment';
import StatusChip, {
  ValidationStatusChipCodes,
} from '../../../../components/ValidationStatusChip';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 15px;
`;

const statusChip = (
  is_valid: boolean | undefined | null,
  warningCount: number | undefined | null
) => {
  const statusCode = is_valid
    ? warningCount === 0
      ? ValidationStatusChipCodes.validation_passed
      : ValidationStatusChipCodes.validation_warning
    : ValidationStatusChipCodes.validation_failed;
  if (statusCode) {
    StatusChip;
  }
  return null;
};

const timestamp = (datetime?: string | null) => {
  if (datetime) {
    return moment(datetime).format('DD/MM/YYYY hh:mma');
  }
  return null;
};

const SubmissionInfo: React.FC = () => {
  const info = useCherreValue(fileInfo());
  return (
    <PageContent>
      <Grid container width='min(20vw, 300px)' spacing='6px'>
        <Grid item xs={12}>
          <Title>Submission Info</Title>
        </Grid>
        <Grid item container spacing={'6px'}>
          <KeyValue
            label='Submission Batch'
            value={info?.submission.submission_name}
          />
          <KeyValue
            label='Added By'
            value={`${info?.submission.user.sakura_user?.first_name} ${info?.submission.user.sakura_user?.last_name}`}
          />
          <KeyValue
            label='Checked On'
            value={timestamp(info?.validated_at_datetime)}
          />
          <KeyValue
            label='Validation Status'
            value={statusChip(
              info?.is_valid,
              info?.warning_count?.aggregate?.count
            )}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
};

SubmissionInfo.displayName = 'BatchValidationPage/SubmissionInfo';

export default SubmissionInfo;
