import {
  CheckerReturnType,
  atom,
  refine,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { string } from '@recoiljs/refine';
import {
  MappingTypes,
  isExcludedPropertyType,
  isSourcedFromMasterCOA,
} from '../types';
import { getMappingSet } from '../queries/getMappingSet';
import { getTargetCodeDropdown as getTargetCodeDropdownDefault } from '../queries/getTargetCodeDropdown';
export interface Dropdown {
  readonly label: string;
  readonly value: string | null;
}

export const addEditMappingChecker = (type: MappingTypes) =>
  refine.object({
    source_code: string(/.+/),
    source_description: string(),
    target_code: isExcludedPropertyType(type) ? string() : string(/.+/),
    target_description: string(),
    id: refine.number(),
    custom_attributes: refine.array(
      refine.object({ slug: string(), label: string(), value: string() })
    ),
  });

export type AddEditMappingFormType = CheckerReturnType<
  ReturnType<typeof addEditMappingChecker>
>;

export const addEditMappingFormState = atom<AddEditMappingFormType>({
  key: 'add-edit-mapping-form-state',
  default: {
    source_code: '',
    source_description: '',
    target_code: '',
    target_description: '',
    custom_attributes: [],
    id: 0,
  },
});

export const getTargetDropdowns = selectorFamily<Dropdown[], string>({
  key: 'get-target-dropdowns',
  scoped: true,
  get:
    (type) =>
    ({ get }) => {
      if (isSourcedFromMasterCOA(type as MappingTypes)) {
        return (
          get(getMappingSet())?.organization_master_chart_of_accounts
            ?.organization_master_chart_of_account_values ?? []
        );
      }

      return get(getTargetCodeDropdownDefault() ?? []);
    },
});
