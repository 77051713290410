import { useCherreState } from '@cherre-frontend/data-fetching';
import { Button, DeleteIcon, useConfirmationModal } from '@cherre-frontend/ui';
import React, { useEffect } from 'react';
import { accountGroupingToDelete } from '../../recoil';
import { useDeleteAccountTree } from '../../hooks/useDeleteAccountTree';

export const dialogTitleCloseBatches = 'Close Batches';
export const dialogTitleDeleteAccountGrouping = 'Delete Account Grouping';

type DeleteAccountGroupingButtonProps = {
  accountGroupingID: number;
  confirmFunction: typeof useConfirmationModal.arguments.confirm;
};

const DeleteAccountGroupingButton: React.FC<
  DeleteAccountGroupingButtonProps
> = ({ accountGroupingID, confirmFunction }) => {
  const [accountGroupingToDeleteID, setAccountGroupingToDeleteID] =
    useCherreState(accountGroupingToDelete);
  const deleteAccountGrouping = useDeleteAccountTree(
    accountGroupingToDeleteID || 0,
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Account Grouping Deleted',
      });
      setAccountGroupingToDeleteID(0);
    }
  );

  useEffect(() => {
    if (!accountGroupingToDeleteID) {
      // nothing to do
      return;
    }
    confirmFunction({
      title: dialogTitleDeleteAccountGrouping,
      confirmLabel: 'Delete',
      handleConfirm: () => {
        deleteAccountGrouping();
      },
      handleCancel: () => setAccountGroupingToDeleteID(0),
    });
  }, [accountGroupingToDeleteID]);

  return (
    <Button
      style={{ minWidth: 'unset' }}
      onClick={() => setAccountGroupingToDeleteID(accountGroupingID)}
    >
      <DeleteIcon
        sx={{
          display: 'flex',
        }}
        color='primary'
      />
    </Button>
  );
};

export default DeleteAccountGroupingButton;
