import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { masterFilesTableSearch } from '../recoil';
import { getAccountTreesListSubscription } from './__generated__/getAccountTreesListSubscription.graphql';

export const getAccountTreesList = graphQLSelector({
  query: graphql`
    subscription getAccountTreesListSubscription($search: String = "%") {
      sys_organization_account_trees(
        where: { account_tree_name: { _ilike: $search } }
      ) {
        organization_account_tree_id
        account_tree_name
        updated_at_datetime
        organization {
          organization_label
        }
      }
    }
  ` as GraphQLReturn<getAccountTreesListSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(masterFilesTableSearch);
      return {
        search: `%${search ?? ''}%`,
      };
    },
  mapResponse: (resp) => resp.sys_organization_account_trees,
});
