import { Grid, Dialog, Panel, styled } from '@cherre-frontend/ui';
import Tabs from '@material-ui/core/Tabs';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
    width: 480px;
    min-height: 370px;
    max-height: 465px;
  }
`;

export const DialogPanel = styled(Panel)`
  display: flex;
  flex-grow: 1;
  :first-child {
    flex-grow: 1;
  }
`;

export const ManageUsersTabs = styled(Tabs)`
  display: flex;
  align-self: flex-start;
  padding: 0px 20px 0px 20px;
  .MuiTabs-indicator {
    border-radius: 0;
  }
`;
