import {
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  MRT_ColumnDef,
  PageContent,
  useTable,
  Table,
  Grid,
  IconButton,
  ReportInfoIcon,
  tableBaseStyle,
} from '@cherre-frontend/ui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  RawValidation,
  ValidationInfo,
  validationsInfo,
  modalValidationSlugState,
} from '../../recoil';
import StatusChip, {
  ValidationStatusChipCodes,
} from 'src/products/data-submission-portal/components/ValidationStatusChip';

const getValidationStatus = (validation: RawValidation) => {
  if (validation.is_valid) {
    return ValidationStatusChipCodes.validation_passed;
  }
  if (validation.validation_mode === 'soft') {
    return ValidationStatusChipCodes.validation_warning;
  }
  return ValidationStatusChipCodes.validation_failed;
};

//TODO: abstract table width logic
const ValidationTable: React.FC = () => {
  const [tableWidth, setTableWidth] = useState<number | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const setValidationModal = useCherreSetState(modalValidationSlugState);

  useEffect(() => {
    const listener = () => {
      const boundingRect = tableRef.current?.getBoundingClientRect();
      if (boundingRect) {
        setTableWidth(boundingRect.width || 1);
      }
    };
    window.addEventListener('resize', listener);
    listener();
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const info = useCherreValue(validationsInfo());

  const columns = useMemo<MRT_ColumnDef<ValidationInfo>[]>(
    () =>
      tableWidth
        ? [
            {
              header: 'Rule',
              accessorKey: 'dataset_validation_rule.validation_label',
              size: (tableWidth - 150) * 0.25,
              muiTableBodyCellProps: {
                sx: {
                  ...tableBaseStyle.muiTableBodyCellProps.sx,
                  fontWeight: 700,
                },
              },
            },
            {
              header: 'Description',
              accessorKey: 'dataset_validation_rule.validation_description',
              size: (tableWidth - 150) * 0.75,
            },
            {
              header: 'Validation',
              id: 'validation',
              size: 100,
              Cell: ({ row }) => (
                <StatusChip statusCode={getValidationStatus(row.original)} />
              ),
            },
            {
              header: '',
              accessorKey: 'property_batch_dataset_validation_id',
              size: 50,
              Cell: ({ row }) => (
                <Grid container justifyContent={'flex-end'}>
                  <IconButton
                    size='small'
                    onClick={() =>
                      setValidationModal(
                        row.original.dataset_validation_rule.validation_slug ??
                          ''
                      )
                    }
                  >
                    <ReportInfoIcon fontSize='small' />
                  </IconButton>
                </Grid>
              ),
            },
          ]
        : [],
    [tableWidth]
  );
  const table = useTable({
    data: info,
    columns,
    getRowId: (row) => `${row.property_batch_dataset_validation_id}`,
    skeletonRowCount: 5,
    enableRowSelection: false,
    enableSorting: false,
    muiTableContainerProps: {
      sx: {
        overflow: 'hidden',
      },
    },
  });
  return (
    <PageContent>
      <div ref={tableRef}>
        <Table table={table} />
      </div>
    </PageContent>
  );
};

ValidationTable.displayName = 'SingleFileValidationPage/ValidationTable';

export default ValidationTable;
