import { useCherreValue } from '@cherre-frontend/data-fetching';
import { Button, PageContainer, PageHeader, styled } from '@cherre-frontend/ui';
import React from 'react';
import { useFlatfile } from '../../hooks/useFlatfile';
import { getSystemFlatfileToken } from './queries/getSystemFlatfileTokenQuery';

const FlatfileContainer = styled('div')`
  width: 100%;
  display: flex;
  flex: 1;
  border: 0;
  & iframe {
    width: 100%;
    flex: 1;
    border: 0;
  }
`;

type FlatfileSystemUploadProps = {
  schemaSlug: string;
  title: string;
  onComplete: (data: { batchId: string }) => void;
  onCancel: () => void;
};

const FlatfileSystemUpload: React.FC<FlatfileSystemUploadProps> = ({
  schemaSlug,
  title,
  onComplete,
  onCancel,
}) => {
  const flatfile_token = useCherreValue(
    getSystemFlatfileToken({ slug: schemaSlug })
  );

  if (!flatfile_token) {
    onCancel();
    return null;
  }

  useFlatfile(flatfile_token, '#flatfile', { onComplete });

  return (
    <PageContainer id='UploadDatasetPage'>
      <PageHeader title={title}>
        <Button color='primary' variant='outlined' onClick={() => onCancel()}>
          Cancel Upload
        </Button>
      </PageHeader>
      <FlatfileContainer id='flatfile' />
    </PageContainer>
  );
};

export default FlatfileSystemUpload;
