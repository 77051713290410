import {
  GraphQLReturn,
  NotFoundError,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { ilike } from 'src/utils/ilike';
import { recoilReassignUserServiceProvidersQuery } from './__generated__/recoilReassignUserServiceProvidersQuery.graphql';

type ProviderOption = { label: string; value: number | null };

type ProvidersQueryVariables = {
  organization_id: number;
  search: string | null;
};

export const $providers = graphQLSelector({
  query: graphql`
    query recoilReassignUserServiceProvidersQuery($id: Int!, $search: String!) {
      sys_organizations(where: { organization_id: { _eq: $id } }) {
        organization_id
        label: organization_label
        providers(where: { provider_name: { _ilike: $search } }) {
          value: provider_id
          label: provider_name
        }
      }
    }
  ` as GraphQLReturn<recoilReassignUserServiceProvidersQuery>,
  mapVariables:
    ({ organization_id, search }: ProvidersQueryVariables) =>
    () => ({ id: organization_id, search: `%${search || ''}%` }),
  mapResponse: (rawResponse, { search }) => {
    const [org] = rawResponse.sys_organizations;
    if (!org) {
      throw new NotFoundError(`Organization not found`);
    }
    const providers: ProviderOption[] = [...org.providers];
    if (ilike(org.label, search)) {
      providers.push({ label: org.label, value: null });
    }
    providers.sort((a, b) => a.label.localeCompare(b.label));
    return providers;
  },
});
