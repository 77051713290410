/**
 * @generated SignedSource<<cede06dcc66140afa7918ff7ce8fc9d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getMappingFieldQuery$variables = {
  id?: number | null;
};
export type getMappingFieldQuery$data = {
  readonly sys_mapping_fields: ReadonlyArray<{
    readonly custom_attributes: any | null;
    readonly mapping_field_description: string;
    readonly mapping_field_id: number;
    readonly mapping_field_name: string;
    readonly organization: {
      readonly organization_slug: string;
    };
  }>;
};
export type getMappingFieldQuery = {
  response: getMappingFieldQuery$data;
  variables: getMappingFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_field_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_fields",
    "kind": "LinkedField",
    "name": "sys_mapping_fields",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "custom_attributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organizations",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization_slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getMappingFieldQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getMappingFieldQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2b09c11dfa1c4458d21c3219e0c1aa77",
    "id": null,
    "metadata": {},
    "name": "getMappingFieldQuery",
    "operationKind": "query",
    "text": "query getMappingFieldQuery(\n  $id: Int\n) {\n  sys_mapping_fields(where: {mapping_field_id: {_eq: $id}}) {\n    mapping_field_id\n    mapping_field_name\n    mapping_field_description\n    custom_attributes\n    organization {\n      organization_slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2e8c25a6e3164a9c365ff53db004f86";

export default node;
