import React, { useMemo, useCallback } from 'react';
import { useCherreValue, useCherreState } from '@cherre-frontend/data-fetching';
import { useAppContext } from '@cherre-frontend/core';
import { useHistory } from 'react-router';
import {
  IngestionFlow,
  IngestionFlowActiveStepKey,
  IngestionFlowState,
} from 'src/products/ingestion/components/IngestionFlow/IngestionFlow';
import { ingestFlowState } from './recoil';
import {
  TargetSchemaSlug,
  useUnifiedIngestGetTargetSchema,
} from '../../hooks/useGetTargetSchema';
import { addEditMappingSetFormState } from '../provider-detail/Panels/MappingsTab/recoil';
import { getMappingFieldNew } from './queries/getMappingFieldNew';
import { useUploadMappingNew } from './hooks/useUploadMappingNew';
import { UploadMappingSetNewRoute } from '../../routes';
import { useSelector } from 'react-redux';

const UploadMappingSetNewPage: React.FC = () => {
  const context = useAppContext();
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();
  const mappingField = useCherreValue(getMappingFieldNew());
  const mappingForm = useCherreValue(addEditMappingSetFormState);

  const mappingFieldId = useCherreValue(
    UploadMappingSetNewRoute.routeParamSelector
  )?.mapping_field_id;

  if (!mappingForm) {
    throw new Error('Mapping form is not set');
  }

  const target_schema = useUnifiedIngestGetTargetSchema(
    mappingField?.mapping_field_name === 'excluded_property'
      ? TargetSchemaSlug.EXCLUDED_PROPERTY
      : TargetSchemaSlug.MAPPING,
    mappingFieldId
  );

  const pushOrGoBack = useCallback(() => {
    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      push(`/dsp/properties`);
    }
  }, [location.state?.shouldGoBack]);

  const [flowState, setFlowState] = useCherreState(ingestFlowState);

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);
  if (!ownerName) {
    console.error('Missing ownerName');
    pushOrGoBack();
    return null;
  }

  const onUploadMappingComplete = useUploadMappingNew();

  const onComplete = useCallback(
    async (file_reference_source: string) => {
      await onUploadMappingComplete({
        input: {
          mapping_field_id: mappingForm.mapping_field_id,
          mapping_set_description: mappingForm.mapping_set_description,
          master_chart_of_account_id: mappingForm.master_chart_of_account_id,
          provider_id: mappingForm.provider_id,
          file_reference_source: `${ownerName}/${file_reference_source}`,
          file_source: 'unified',
        },
      });
      context.showSnackbar({
        type: 'success',
        message: 'Mapping uploaded successfully.',
      });
      goBack();
    },
    [context, onUploadMappingComplete, mappingForm]
  );

  const state = useMemo(
    () => ({
      fileId: flowState?.fileId ?? undefined,
      signedUrl: flowState?.signedUrl ?? undefined,
      selectedSheet: flowState?.selectedSheet ?? undefined,
      activeStepKey: (flowState?.activeStepKey ??
        'upload') as IngestionFlowActiveStepKey,
      selectedHeaderRows: flowState?.selectedHeaderRows ?? undefined,
      validationResults: flowState?.validationResults ?? undefined,
    }),
    [flowState]
  );

  const setState = useCallback(
    (newState: React.SetStateAction<IngestionFlowState>) => {
      setFlowState((prev) => ({
        ...prev,
        ...(typeof newState === 'function'
          ? newState({
              fileId: prev?.fileId ?? undefined,
              signedUrl: prev?.signedUrl ?? undefined,
              selectedSheet: prev?.selectedSheet ?? undefined,
              activeStepKey: (prev?.activeStepKey ??
                'upload') as IngestionFlowActiveStepKey,
              selectedHeaderRows: prev?.selectedHeaderRows ?? undefined,
              validationResults: prev?.validationResults ?? undefined,
            })
          : newState),
      }));
    },
    []
  );

  return (
    <IngestionFlow
      config={{
        templateId: 'None',
        ownerName,
        performValidation: true,
        datasetName: 'Mapping',
        datasetId: '',
        targetSchema:
          (target_schema.data as unknown as {
            label: string;
            name: string;
            mandatory: boolean;
            dataType: string;
          }[]) ?? [],
      }}
      ingestionConfig={undefined}
      onComplete={onComplete}
      onCancel={pushOrGoBack}
      state={state}
      setState={setState}
    />
  );
};

export default UploadMappingSetNewPage;
