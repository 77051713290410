import {
  useCherreSetPartialState,
  useCherreState,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import React from 'react';
import {
  ReportsModal,
  TableConfig,
} from 'src/products/data-submission-portal/components/Reports';
import { usePersona } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { PackageReportSidebar } from '../PackageReportSidebar';
import {
  packageReportSidebarOpenState,
  packageReportSidebarState,
} from '../PackageReportSidebar/recoil';
import { $packageReports, ModalSearchState, ModalState } from '../recoil';
import PackageReportReminderAlert from './PackageReportReminderAlert';
import { ReportHeader } from './ReportHeader';
import {
  canReviewPackageReport,
  packageReportRemindersCount,
  reviewPackageReportMutation,
} from './queries';

export type PackageReportModalProps = {
  property_batch_id: number;
  modalState: ModalState;
  modalSearchState: ModalSearchState;
  reportSelectors: Parameters<typeof ReportsModal>[0]['selectors'];
  reportSlugModalState: Parameters<typeof ReportsModal>[0]['reportSlugState'];
};

const PackageReportModal: React.FC<PackageReportModalProps> = ({
  property_batch_id,
  modalState,
  modalSearchState,
  reportSelectors,
  reportSlugModalState,
}) => {
  const modalStateInfo = useCherreValue(modalState);
  const packageReports = useCherreValue(
    $packageReports(modalStateInfo?.property_batch_id)
  );

  const remindersCount = useCherreValue(
    packageReportRemindersCount(property_batch_id)
  );
  const persona = usePersona();
  const [sidebarOpen, setSidebarOpen] = useCherreState(
    packageReportSidebarOpenState(modalStateInfo?.report_slug)
  );
  const setSidebarState = useCherreSetPartialState(packageReportSidebarState);

  const propertyBatchId = modalStateInfo?.property_batch_id;
  const canReview = useCherreValue(canReviewPackageReport(propertyBatchId));
  const reviewPackageReport = useMutation(reviewPackageReportMutation, {
    mapVariables: (report_slug: string) => async () => {
      if (!modalStateInfo) {
        throw new Error(`no modal open`);
      }

      const report = packageReports?.find((r) => r.slug === report_slug);
      if (!report) {
        throw new Error(`report for slug ${report_slug} not found`);
      }

      return {
        params: {
          approve: true,
          package_report_id: report.id,
          property_batch_id: modalStateInfo.property_batch_id,
        },
      };
    },
  });

  const onReportOpen = (report_slug: string) => {
    if (!canReview) {
      return;
    }
    if (persona !== 'preparer') {
      return;
    }
    return reviewPackageReport(report_slug);
  };

  const getWhere = (_: string, table_config: TableConfig) => {
    const groupWhere = table_config.group_values
      ? Object.fromEntries(
          Object.entries(table_config.group_values).map(([k, v]) => [
            k,
            { _eq: v },
          ])
        )
      : undefined;

    return groupWhere;
  };

  const getArgs = (_: string) => {
    return {
      property_batch_id: modalStateInfo?.property_batch_id,
    };
  };

  const onViewRemindersClicked = () => {
    if (!modalStateInfo?.report_slug) {
      return;
    }

    setSidebarState((prevState) => ({
      ...prevState,
      [modalStateInfo.report_slug]: {
        comment: '',
        reminder: '',
        showCompletedReminders: true,
        tab: 1,
        sidebarOpen: true,
      },
    }));
  };

  if (!modalStateInfo) {
    return null;
  }

  if (modalStateInfo.property_batch_id !== property_batch_id) {
    return null;
  }

  return (
    <ReportsModal
      selectors={reportSelectors}
      reportSlugState={reportSlugModalState}
      reports={packageReports || []}
      getWhere={getWhere}
      getArgs={getArgs}
      onReportOpen={onReportOpen}
      getParams={({ report_view_type }) => ({ report_view_type })}
      sidebarComponent={<PackageReportSidebar modalState={modalState} />}
      sidebarOpen={sidebarOpen}
    >
      <ReportHeader
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        modalState={modalState}
        modalSearchState={modalSearchState}
        alertComponent={
          persona === 'preparer' && remindersCount && remindersCount > 0 ? (
            <PackageReportReminderAlert
              subtitle={`There ${
                remindersCount === 1 ? 'is' : 'are'
              } ${remindersCount} reminder${
                remindersCount === 1 ? '' : 's'
              } for next submission cycle.`}
              onViewRemindersClicked={onViewRemindersClicked}
              localStorageKey={`reminder-alert-hidden-${property_batch_id}`}
            />
          ) : null
        }
      />
    </ReportsModal>
  );
};

export default PackageReportModal;
