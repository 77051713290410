import { useCherreValue, useIsSuspended } from '@cherre-frontend/data-fetching';
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { SelectPropertiesState as state } from '../recoil';

type TabProps = Omit<MuiTabProps, 'value'> & {
  value: number;
  label: string;
};

const StyledTab = styled(MuiTab)`
  min-height: 44px;
  height: 44px;
  padding: 10px 16px 10px 16px;
`;

export const Tab: React.FC<TabProps> = ({ value, label, ...props }) => {
  const suspended = useIsSuspended();
  const selection = useCherreValue(state.selection(value));
  const count = useMemo(
    () => Object.values(selection || {}).filter((v) => v).length,
    [selection]
  );

  return (
    <StyledTab
      {...props}
      label={`${label} Batch (${count})`}
      value={value}
      disabled={suspended}
    />
  );
};
