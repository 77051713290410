import React from 'react';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { Filter as OgFilter, FilterOption } from '@cherre-frontend/ui';
import { Filter } from '../../../consts';
import { $filterOption } from '../recoil';
import { monitorSubmissionsFilterState } from '../../../recoil';

type Option = FilterOption<string>;

export const SingleFilter: React.FC<{ label: Filter }> = ({ label }) => {
  const options = useCherreValue($filterOption(label)) as Option[];
  const [state, setState] = useCherreState(
    monitorSubmissionsFilterState(label)
  );
  if (Array.isArray(state)) {
    throw new Error('SingleFilter should not be used for multi-select filters');
  }
  const selected = options?.find((o) => o.value === state);
  return (
    <OgFilter
      label={label}
      options={options || []}
      selected={selected}
      onChange={(newValue) => setState(newValue?.value || '')}
    />
  );
};
