import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { UploadDatasetBulkRoute } from 'src/products/data-submission-portal/routes';
import { getDatasetQuery } from './__generated__/getDatasetQuery.graphql';

export const getDataset = graphQLSelector({
  query: graphql`
    query getDatasetQuery($dataset_id: Int) {
      sys_datasets(where: { dataset_id: { _eq: $dataset_id } }, limit: 1) {
        dataset_description
        dataset_label
      }
    }
  ` as GraphQLReturn<getDatasetQuery>,
  mapVariables:
    () =>
    ({ get }) => ({
      dataset_id: get(UploadDatasetBulkRoute.routeParamSelector).dataset_id,
    }),
  mapResponse: (resp) => resp.sys_datasets[0],
});
