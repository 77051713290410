import {
  QueryResult,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  DatasetsIcon,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  PersonIcon,
  Table,
  TogglesIcon,
  styled,
  tableBaseStyle,
  useTable,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { ClickCard } from 'src/products/data-submission-portal/components/ClickCard';
import { StatusChip } from 'src/products/data-submission-portal/components/StatusChip';
import {
  getAllProperties,
  getAllPropertiesTotal,
  getAllPropertiesUsedValues,
} from '../queries/getAllProperties';
import {
  getClickCardDataMappingsByIds,
  getDatasetsByIds,
} from '../queries/getClickCardData';
import { assignPropertiesTableState } from '../recoil';
import NoAvailableProperties from './NoAvailableProperties';
import { NoPropertiesFound } from './NoPropertiesFound';
import { ProviderNameChip } from './ProviderNameChip';
import { UsersClickCard } from '../../../../../components/UsersClickCard';
import { isPropertyActive } from 'src/products/data-submission-portal/utils/isPropertyActive';

const Container = styled('div')`
  padding-top: 20px;
  overflow: auto;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const disableColumnGrow = {
  muiTableHeadCellProps: (props) => ({
    sx: {
      ...tableBaseStyle.muiTableHeadCellProps(props).sx,
      flex: '0 0 auto',
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      ...tableBaseStyle.muiTableBodyCellProps.sx,
      flex: '0 0 auto',
    },
  },
};

export type AssignPropertiesTableProps = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  rowSelection: MRT_RowSelectionState;
  search?: string;
};

const AssignPropertiesTable: React.FC<AssignPropertiesTableProps> = ({
  search,
  setRowSelection,
  rowSelection,
}) => {
  const [tableState, setTableState] = useCherreState(
    assignPropertiesTableState
  );

  const data = useCherreValue(getAllProperties());
  const total = useCherreValue(getAllPropertiesTotal());
  const usedValues = useCherreValue(getAllPropertiesUsedValues());

  const columns: MRT_ColumnDef<QueryResult<typeof getAllProperties>[number]>[] =
    useMemo(
      () => [
        {
          accessorKey: 'entity_id',
          header: 'Entity ID',
          size: 120,
          ...disableColumnGrow,
        },
        {
          accessorKey: 'property_code',
          header: 'Target Entity ID',
          size: 170,
          ...disableColumnGrow,
        },
        {
          accessorKey: 'property_mapping',
          header: 'Mapping',
          size: 120,
          ...disableColumnGrow,
        },
        {
          id: 'provider.provider_name',
          header: 'Provider',
          accessorFn: (row) => row.provider?.provider_name,
          ...disableColumnGrow,
          Cell: ({ cell }) => {
            const providerName = cell.getValue();
            if (!providerName) {
              return null;
            }
            return <ProviderNameChip label={cell.getValue() as string} />;
          },
        },
        ...(usedValues?.property_name_is_used
          ? [
              {
                accessorKey: 'property_name',
                header: 'Name',
              },
            ]
          : []),
        ...(usedValues?.address_is_used
          ? [
              {
                accessorKey: 'address',
                header: 'Address',
              },
            ]
          : []),
        ...(usedValues?.fund_is_used
          ? [
              {
                accessorKey: 'fund',
                header: 'Fund',
                maxSize: 120,
                ...disableColumnGrow,
              },
            ]
          : []),
        ...(usedValues?.type_is_used
          ? [
              {
                accessorKey: 'type',
                header: 'Type',
                maxSize: 120,
                ...disableColumnGrow,
              },
            ]
          : []),
        {
          accessorFn: (row) => row.users_count.aggregate?.count ?? 0,
          id: 'properties_roles_users_aggregate.count',
          header: 'Users',
          size: 70,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '70px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '70px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <UsersClickCard
              assigned_preparers={
                row.original.users_preparers?.map((obj) => obj.user_id) ?? []
              }
              assigned_fin_reviewers={
                row.original.users_financial_reviewers?.map(
                  (obj) => obj.user_id
                ) ?? []
              }
              assigned_op_reviewers={
                row.original.users_operational_reviewers?.map(
                  (obj) => obj.user_id
                ) ?? []
              }
              link_text={renderedCellValue}
              icon={<PersonIcon />}
            />
          ),
        },
        {
          accessorFn: (row) => row.mapping_sets_count.aggregate?.count ?? 0,
          id: 'properties_mapping_sets_aggregate.count',
          header: 'Mappings',
          size: 100,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '105px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '105px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <ClickCard
              title='Mappings'
              data={useCherreValue(
                getClickCardDataMappingsByIds({
                  mapping_ids: row.original.mapping_set_ids.map(
                    (obj) => obj.mapping_set_id
                  ),
                })
              )}
              link_text={renderedCellValue}
              icon={<TogglesIcon />}
            />
          ),
        },
        {
          accessorFn: (row) => row.datasets_count.aggregate?.count ?? 0,
          id: 'properties_datasets_aggregate.count',
          header: 'Datasets',
          size: 90,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '95px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '95px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <ClickCard
              title='Datasets'
              data={useCherreValue(
                getDatasetsByIds({
                  dataset_ids: row.original.properties_datasets.map(
                    (obj) => obj.dataset_id
                  ),
                })
              )}
              link_text={renderedCellValue}
              icon={<DatasetsIcon />}
            />
          ),
        },
        {
          accessorKey: 'is_active',
          header: 'DSP Status',
          accessorFn: (row) => {
            return isPropertyActive(row);
          },
          Cell: ({ renderedCellValue }) => (
            <StatusChip active={renderedCellValue as boolean} />
          ),
          size: 130,
          muiTableHeadCellProps: (props) => ({
            align: 'right',
            sx: tableBaseStyle.muiTableHeadCellProps(props).sx,
          }),
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
      ],
      []
    );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: true,
    enableRowActions: true,
    enableRowSelection: (row) => {
      const totalOpened = row.original.total_opened?.aggregate?.count || 0;
      return totalOpened === 0;
    },
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    state: {
      rowSelection,
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    onRowSelectionChange: setRowSelection,
    enableStickyHeader: true,
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.property_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
    muiTableBodyRowProps: {
      ...tableBaseStyle.muiTableBodyRowProps.sx,
      sx: {
        '& td:last-child': {
          width: '50px',
        },
      },
    },
  });

  if (data && data.length === 0) {
    return search ? (
      <NoPropertiesFound title='No Properties Found' />
    ) : (
      <NoAvailableProperties />
    );
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

export default AssignPropertiesTable;
