import {
  useCherreEventWithRecoil,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Dialog, DialogProps, Panel, styled } from '@cherre-frontend/ui';
import React from 'react';
import {
  uploadMasterFileDialogOpenState,
  uploadMasterFileDialogStep,
  UploadMasterFileDialogStepValues,
  uploadMasterFileFormState,
} from '../../recoil';
import UploadMasterFileDialogStep1 from './UploadMasterFileDialogStep1';
import UploadMasterFileDialogStep2 from './UploadMasterFileDialogStep2';

const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: 540px;
  }
`;

const UploadMasterFileDialog: React.FC = () => {
  const isModalOpen = useCherreValue(uploadMasterFileDialogOpenState);

  const dialogStep = useCherreValue(uploadMasterFileDialogStep);

  const onClose = useCherreEventWithRecoil(
    'user closed upload master files dialog',
    (ctx) => () => {
      ctx.recoil.set(uploadMasterFileDialogOpenState, false);
      ctx.recoil.set(uploadMasterFileFormState, { name: '', fileType: '' });
      ctx.recoil.set(
        uploadMasterFileDialogStep,
        UploadMasterFileDialogStepValues.STEP_1
      );
    }
  );

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Panel id='UploadMasterFileDialog' config={{ logLevel: false }}>
        {dialogStep === UploadMasterFileDialogStepValues.STEP_1 ? (
          <UploadMasterFileDialogStep1 />
        ) : (
          <UploadMasterFileDialogStep2 />
        )}
      </Panel>
    </Container>
  );
};

export default UploadMasterFileDialog;
