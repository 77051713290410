import {
  getSelector,
  graphQLSelector,
  refine,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { getUserQuery } from './__generated__/getUserQuery.graphql';

export const getUser = getSelector({
  url: '/api/v1/user/me',
  checker: refine.object({ id: refine.number() }),
  resetCache: false,
});

export type DSPUser = getUserQuery['response']['sys_users'][number];

export const getDSPUser = graphQLSelector({
  query: graphql`
    query getUserQuery($id: Int!) {
      sys_users(where: { user_id: { _eq: $id } }) {
        is_active
        organization_id
        provider_id
        user_id
        created_at_datetime
        updated_at_datetime
        provider {
          ingestion_config
        }
        organization {
          settings
        }
      }
    }
  ` as GraphQLReturn<getUserQuery>,
  mapVariables:
    (id?: number) =>
    ({ get }) => ({ id: id || get(getUser).id }),
  mapResponse: (response, variables) => {
    const user = response.sys_users[0];
    if (!user) {
      throw new Error(`DSP user with ID ${variables.id} not found`);
    }
    return user;
  },
  resetCache: false,
});
