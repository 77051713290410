import moment from 'moment';

export const getSubmissionPeriod = (
  reporting_period_start_date: string,
  reporting_period_end_date: string
) => {
  const diff = moment(reporting_period_end_date).diff(
    moment(reporting_period_start_date),
    'months',
    true
  );

  if (diff < 2) {
    return moment(reporting_period_start_date).format('MMM YYYY');
  }

  const quarter = moment(reporting_period_start_date).quarter();

  const year = moment(reporting_period_start_date).format('YYYY');

  return `Q${quarter} ${year}`;
};
