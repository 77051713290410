import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useClaims } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';

export function useSubmissionBulkActionsEnabled() {
  const user = useCherreValue(getDSPUser());

  const isReviewer = useClaims('reviewer');

  const organizationEnabled =
    !!user?.organization?.settings?.bulk_approve_submit_properties;

  const providerEnabled =
    !user?.provider ||
    !!user?.provider?.ingestion_config?.bulk_approve_submit_properties;

  return (
    providerEnabled &&
    organizationEnabled &&
    isReviewer &&
    useFeatureFlag('DSPBulkApproveSettings')
  );
}
