/**
 * @generated SignedSource<<cbb21bad25964129bce4cc25e448e5af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useWorkflowApplyActionToBatchMutation$variables = {
  action: string;
  property_batch_id: number;
};
export type useWorkflowApplyActionToBatchMutation$data = {
  readonly _sys_workflow_apply_action_to_batch: {
    readonly message: string;
  };
};
export type useWorkflowApplyActionToBatchMutation = {
  response: useWorkflowApplyActionToBatchMutation$data;
  variables: useWorkflowApplyActionToBatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_batch_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "action",
            "variableName": "action"
          },
          {
            "kind": "Variable",
            "name": "property_batch_id",
            "variableName": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "_sys_workflow_apply_action_to_batch_output",
    "kind": "LinkedField",
    "name": "_sys_workflow_apply_action_to_batch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useWorkflowApplyActionToBatchMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useWorkflowApplyActionToBatchMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25818307e6c577210580514febc3f3d6",
    "id": null,
    "metadata": {},
    "name": "useWorkflowApplyActionToBatchMutation",
    "operationKind": "mutation",
    "text": "mutation useWorkflowApplyActionToBatchMutation(\n  $property_batch_id: Int!\n  $action: String!\n) {\n  _sys_workflow_apply_action_to_batch(params: {property_batch_id: $property_batch_id, action: $action}) {\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c1b00e3b14efb078f5f3d9c0dfa588b";

export default node;
