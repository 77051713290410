/**
 * @generated SignedSource<<366b6d41a99bcdf9461c16c0da331982>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type batchManagementCloseBatchesMutation$variables = {
  closed_at_datetime: string;
  submission_ids: ReadonlyArray<number>;
};
export type batchManagementCloseBatchesMutation$data = {
  readonly update_sys_submissions: {
    readonly affected_rows: number;
  } | null;
};
export type batchManagementCloseBatchesMutation = {
  response: batchManagementCloseBatchesMutation$data;
  variables: batchManagementCloseBatchesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "closed_at_datetime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "closed_at_datetime",
            "variableName": "closed_at_datetime"
          },
          {
            "kind": "Literal",
            "name": "is_closed",
            "value": true
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "submission_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_submissions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "batchManagementCloseBatchesMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "batchManagementCloseBatchesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d26c51e8e02f3b6ce193729355d5163",
    "id": null,
    "metadata": {},
    "name": "batchManagementCloseBatchesMutation",
    "operationKind": "mutation",
    "text": "mutation batchManagementCloseBatchesMutation(\n  $closed_at_datetime: timestamptz!\n  $submission_ids: [Int!]!\n) {\n  update_sys_submissions(where: {submission_id: {_in: $submission_ids}}, _set: {is_closed: true, closed_at_datetime: $closed_at_datetime}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e6f5ab20afb46009e9f28db00af1e03";

export default node;
