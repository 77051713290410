import React from 'react';
import { AddButton } from '../../../styles';
import { Link, useParams } from 'react-router-dom';

export type ParamsProps = {
  provider_id: string;
};

export const AddUserButton: React.FC = () => {
  const { provider_id } = useParams<ParamsProps>();

  return (
    <Link to={`/dsp/providers/${provider_id}/new-user`}>
      <AddButton sx={{ margin: '0px' }} variant='contained'>
        Add User
      </AddButton>
    </Link>
  );
};
