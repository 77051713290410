import React, { useState } from 'react';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '../../../../components';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useStateDebounced,
} from '@cherre-frontend/data-fetching';
import { reopenBatchesDialogOpenState } from '../../recoil';
import { InfoBanner } from './components/BannerInfo';
import {
  Button,
  Filter,
  FilterOption,
  Grid,
  MRTUtils,
  MRT_RowSelectionState,
  Panel,
  SearchInput,
} from '@cherre-frontend/ui';
import { getFilterValues } from './query';
import BatchesTable from './components/BatchesTable';
import { useRenewBatches } from '../../hooks/useRenewBatches';

const DEFAULT_PROVIDER: FilterOption<number | null> = {
  label: 'Any Provider',
  value: null,
  default: true,
};

const DEFAULT_TYPE: FilterOption<number | null> = {
  label: 'Any Type',
  value: null,
  default: true,
};

const ReopenBatchesDialog: React.FC = () => {
  const [search, debouncedSearch, setSearch] = useStateDebounced('');
  const [provider, setProvider] = useState(DEFAULT_PROVIDER);
  const [type, setType] = useState(DEFAULT_TYPE);

  const [rowSelection, onRowSelectionChange] = useState<MRT_RowSelectionState>(
    {}
  );

  const [periods, setPeriods] = useState<string[]>([]);

  const options = useCherreValue(getFilterValues());

  const onClose = useCherreEventWithRecoil(
    'user closed reopen batches dialog',
    (ctx) => () => {
      ctx.recoil.set(reopenBatchesDialogOpenState, false);
    }
  );

  const onCreateBatches = useRenewBatches({
    batches: MRTUtils.selection(rowSelection).map((id) => Number(id)),
    onCompleted: (_, ctx) =>
      ctx.recoil.set(reopenBatchesDialogOpenState, false),
  });
  return (
    <>
      <DialogHeader
        title='Create New Batches'
        subtitle={
          <>
            Choose batches which you wish to recreate for the next submission
            period.
            <br />
            New batches will include all properties associated with an
            organization.
          </>
        }
        onClose={onClose}
      />
      <DialogContent rowSpacing='20px'>
        <InfoBanner periods={periods} />
        <Grid item container xs={12} gap='10px'>
          <Grid item>
            <SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Filter
              options={[DEFAULT_PROVIDER, ...(options?.providers || [])]}
              selected={provider}
              onChange={(p) => setProvider(p || DEFAULT_PROVIDER)}
            />
          </Grid>
          <Grid item>
            <Filter
              options={[DEFAULT_TYPE, ...(options?.types || [])]}
              selected={type}
              onChange={(t) => setType(t || DEFAULT_TYPE)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Panel id='ReopenBatchesDialogBatchesTable'>
            <BatchesTable
              providerId={provider.value}
              submissionTypeId={type.value}
              search={debouncedSearch}
              rowSelection={rowSelection}
              onRowSelectionChange={onRowSelectionChange}
              setPeriods={setPeriods}
            />
          </Panel>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          disabled={MRTUtils.selection(rowSelection).length === 0}
          onClick={() => onCreateBatches()}
        >
          Create Batches
        </Button>
      </DialogActions>
    </>
  );
};

export default ReopenBatchesDialog;
