import React, { createContext, useContext } from 'react';
import { useIsApproverPlus, usePersona } from './hooks';
import { useAppContext } from '@cherre-frontend/core';
import { RelayEnvironmentScope } from '@cherre-frontend/data-fetching';
import { PropertyModel } from '../../__generated__/constants';

export const personasList = [
  'preparer',
  'reviewer',
  'view_only',
  'view_admin',
] as const;

export type PersonasType = (typeof personasList)[number];

export const PropertyScopeContext = createContext<number | null>(null);
export const SubmissionScopeContext = createContext<number | null>(null);
export const PersonaScopeContext = createContext<PersonasType | null>(null);

const PermissionGate = ({ children }) => {
  const persona = usePersona(false);
  if (!persona) {
    return null;
  }
  return <>{children}</>;
};

type PropertyFlattenedUnion = {
  readonly parent_property_id: number | null;
  readonly property_id: number;
  readonly property_models: {
    readonly property_model_slug: string;
  } | null;
};

const getPropertyId = (property: PropertyFlattenedUnion | null) => {
  if (!property) {
    return null;
  }

  return property.property_models?.property_model_slug ===
    PropertyModel.MANY_TO_ONE.label
    ? property.parent_property_id
    : property.property_id;
};

type PropertyScopeProps =
  | {
      propertyId: number | null;
    }
  | {
      property: PropertyFlattenedUnion | null;
    };

export const PropertyScope: React.FC<PropertyScopeProps> = ({
  children,
  ...props
}) => {
  const parentPropertyId = useContext(PropertyScopeContext);
  const { logger } = useAppContext();

  const propertyId =
    'propertyId' in props ? props.propertyId : getPropertyId(props.property);

  // Check if we are using nested PropertyScopes with different values
  if (parentPropertyId && parentPropertyId !== propertyId) {
    logger.info(
      'A PropertyScope with different value is being used in a subtree of another PropertyScope'
    );
    return null;
  }

  return (
    <PropertyScopeContext.Provider value={propertyId}>
      <PermissionGate>{children}</PermissionGate>
    </PropertyScopeContext.Provider>
  );
};

export const SubmissionScope: React.FC<{ submissionId: number }> = ({
  submissionId,
  children,
}) => {
  const parentSubmissionId = useContext(SubmissionScopeContext);
  const { logger } = useAppContext();

  // Check if we are using nested SubmissionScopes with different values
  if (parentSubmissionId && parentSubmissionId !== submissionId) {
    logger.info(
      'A SubmissionScope with different value is being used in a subtree of another SubmissionScope'
    );
    return null;
  }

  return (
    <SubmissionScopeContext.Provider value={submissionId}>
      <PermissionGate>{children}</PermissionGate>
    </SubmissionScopeContext.Provider>
  );
};

export type PersonaScopeProps = {
  persona: PersonasType;
};

export const PersonaScope: React.FC<PersonaScopeProps> = ({
  persona,
  children,
}) => {
  const parentPersona = useContext(PersonaScopeContext);
  const { logger } = useAppContext();

  // Check if we are using nested PersonaScopes with different values
  if (parentPersona && parentPersona !== persona) {
    logger.info(
      'A PersonaScope with different value is being used in a subtree of another PersonaScope'
    );
    return null;
  }

  return (
    <PersonaScopeContext.Provider value={persona}>
      <PermissionGate>
        <RelayEnvironmentScope environment={persona}>
          {children}
        </RelayEnvironmentScope>
      </PermissionGate>
    </PersonaScopeContext.Provider>
  );
};

export const ApproverPlusScope: React.FC = ({ children }) => {
  const isApproverPlus = useIsApproverPlus();

  if (!isApproverPlus) {
    return null;
  }

  return <>{children}</>;
};
