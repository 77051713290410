import React, { useState } from 'react';

import {
  Box,
  CancelIcon,
  IconButton,
  MuiAutocomplete as Autocomplete,
  SearchIcon,
  TextField,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import UserOption from './UserOption';
import { UserTypeSelection } from './UserTypeSelection';
import { userCheckerType } from '../../../recoil/dialog';

export type UserRoleSelectProps = {
  title: React.ReactNode;
  level: number;
  approverPlusLevel: number | undefined;
  onChange: (
    event: React.SyntheticEvent,
    value: userCheckerType | null,
    reason: 'createOption' | 'selectOption' | 'removeOption' | 'clear' | 'blur',
    details?: { option: userCheckerType }
  ) => void;
  selectedUsers: userCheckerType[];
  options: userCheckerType[];
  onUpdateUserRoleType: (user_id: number, property_role_id: number) => void;
  onClickRemoveUser?: (user: userCheckerType) => void;
};

export const UserRoleSelect: React.FC<UserRoleSelectProps> = ({
  title,
  options,
  selectedUsers,
  level,
  approverPlusLevel,
  onChange,
  onClickRemoveUser,
  onUpdateUserRoleType,
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');

  return (
    <Box sx={{ width: '100%' }}>
      {typeof title === 'string' ? (
        <Typography variant='body1' fontWeight='bold'>
          {title}
        </Typography>
      ) : (
        title
      )}
      <Autocomplete
        placeholder='search'
        size='small'
        inputValue={search}
        value={null}
        isOptionEqualToValue={(opt, value) => opt.user_id === value.user_id}
        options={options}
        getOptionLabel={(opt) =>
          `${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`
        }
        renderOption={(props, opt) => {
          return (
            <li {...props} key={opt.user_id}>
              <Box>
                <UserOption
                  name={`${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`}
                  email={opt.sakura_user?.email as string}
                />
              </Box>
            </li>
          );
        }}
        popupIcon={<SearchIcon style={{ color: theme.palette.grey[500] }} />}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'reset') {
            setSearch('');
          } else {
            setSearch(newInputValue);
          }
        }}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Search'
            size='small'
          />
        )}
        sx={{
          '> div > div > div > button': {
            transform: 'unset',
          },
          marginBottom: '16px',
        }}
      />
      {selectedUsers.length > 0 ? (
        selectedUsers.map((user) => (
          <Box
            key={user.user_id}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            <UserOption
              name={`${user.sakura_user?.first_name} ${user.sakura_user?.last_name}`}
              email={user.sakura_user?.email as string}
            />
            <UserTypeSelection
              user_id={user.user_id}
              property_role_id={user.property_role_id ?? undefined}
              level={level}
              approverPlusLevel={approverPlusLevel}
              onChange={onUpdateUserRoleType}
            />
            <IconButton
              size='small'
              sx={{ height: '30px', alignSelf: 'center' }}
              onClick={() => {
                setSearch('');
                onClickRemoveUser?.(user);
              }}
            >
              <CancelIcon fontSize='small' />
            </IconButton>
          </Box>
        ))
      ) : (
        <Typography
          variant='body1'
          style={{
            textAlign: 'center',
            fontSize: '12px',
            color: theme.palette.grey[700],
          }}
        >
          No users assigned
        </Typography>
      )}
    </Box>
  );
};
