/**
 * @generated SignedSource<<f2bcd67f91d862f112c02e1845063dac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getMappingSetQuery$variables = {
  id?: number | null;
};
export type getMappingSetQuery$data = {
  readonly sys_mapping_sets: ReadonlyArray<{
    readonly mapping_field: {
      readonly custom_attributes: any | null;
      readonly mapping_field_description: string;
      readonly mapping_field_name: string;
    };
    readonly mapping_set_description: string;
    readonly mapping_set_id: number;
    readonly mapping_set_values_aggregate: {
      readonly aggregate: {
        readonly max: {
          readonly updated_at_datetime: string | null;
        } | null;
      } | null;
    };
    readonly organization: {
      readonly organization_label: string;
    };
    readonly organization_id: number;
    readonly organization_master_chart_of_accounts: {
      readonly master_chart_of_account_id: number;
      readonly organization_master_chart_of_account_values: ReadonlyArray<{
        readonly is_active: boolean;
        readonly label: string;
        readonly master_chart_of_account_values_id: number;
        readonly value: string;
      }>;
    } | null;
    readonly provider: {
      readonly provider_id: number;
      readonly provider_name: string;
    };
    readonly updated_at_datetime: string;
  }>;
};
export type getMappingSetQuery = {
  response: getMappingSetQuery$data;
  variables: getMappingSetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated_at_datetime",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_set_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_sets",
    "kind": "LinkedField",
    "name": "sys_mapping_sets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_mapping_fields",
        "kind": "LinkedField",
        "name": "mapping_field",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_field_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_field_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "custom_attributes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organizations",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization_label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organization_master_chart_of_accounts",
        "kind": "LinkedField",
        "name": "organization_master_chart_of_accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "master_chart_of_account_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_organization_master_chart_of_account_values",
            "kind": "LinkedField",
            "name": "organization_master_chart_of_account_values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "master_chart_of_account_values_id",
                "storageKey": null
              },
              {
                "alias": "label",
                "args": null,
                "kind": "ScalarField",
                "name": "account_code",
                "storageKey": null
              },
              {
                "alias": "value",
                "args": null,
                "kind": "ScalarField",
                "name": "account_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_active",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_mapping_set_values_aggregate",
        "kind": "LinkedField",
        "name": "mapping_set_values_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_mapping_set_values_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_mapping_set_values_max_fields",
                "kind": "LinkedField",
                "name": "max",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getMappingSetQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getMappingSetQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "29cd94ba3102c5cad80cbde555691111",
    "id": null,
    "metadata": {},
    "name": "getMappingSetQuery",
    "operationKind": "query",
    "text": "query getMappingSetQuery(\n  $id: Int\n) {\n  sys_mapping_sets(where: {mapping_set_id: {_eq: $id}}) {\n    mapping_set_id\n    mapping_set_description\n    mapping_field {\n      mapping_field_name\n      mapping_field_description\n      custom_attributes\n    }\n    provider {\n      provider_id\n      provider_name\n    }\n    organization_id\n    organization {\n      organization_label\n    }\n    organization_master_chart_of_accounts {\n      master_chart_of_account_id\n      organization_master_chart_of_account_values {\n        master_chart_of_account_values_id\n        label: account_code\n        value: account_name\n        is_active\n      }\n    }\n    updated_at_datetime\n    mapping_set_values_aggregate {\n      aggregate {\n        max {\n          updated_at_datetime\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2acbb77a0940bb8d2a8def5e6037c1b5";

export default node;
