import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getReportTemplateDataQuery } from './__generated__/getReportTemplateDataQuery.graphql';

type TSAItem = {
  label: string;
};

type UnifiedIngestSchemas = {
  tsa?: TSAItem[];
};

type SysDataset = {
  unified_ingest_schemas: UnifiedIngestSchemas;
};

export const getReportTemplateData = graphQLSelector({
  query: graphql`
    query getReportTemplateDataQuery($dataset_id: Int!) {
      sys_datasets(where: { dataset_id: { _eq: $dataset_id } }) {
        unified_ingest_schemas
        dataset_slug
      }
    }
  ` as GraphQLReturn<getReportTemplateDataQuery>,
  mapResponse: (response) => {
    const datasets: SysDataset[] = response.sys_datasets || [];
    if (datasets.length === 0) {
      return [];
    }
    const schema: UnifiedIngestSchemas = datasets[0].unified_ingest_schemas;
    const tsaSchema: TSAItem[] = schema.tsa || [];
    return tsaSchema.map((item) => item.label);
  },
});
