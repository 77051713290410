import { useMutation } from '@cherre-frontend/data-fetching';
import {
  CheckCircleIcon,
  Grid,
  GridProps,
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  Tooltip,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useEffect } from 'react';
import { recoilGetPackageReportResolvedRemindersQuery$data } from './__generated__/recoilGetPackageReportResolvedRemindersQuery.graphql';
import { markReminderResolvedMutation } from './recoil';

const Container = styled(Grid, {
  shouldForwardProp: (prop) =>
    !['closed', 'height', 'showBottomBorder'].includes(prop.toString()),
})<
  GridProps & { closed?: boolean; height: string; showBottomBorder?: boolean }
>`
  transition: all 0.4s ease-in-out;
  flex-shrink: 0;
  overflow: hidden;
  height: ${({ height, closed }) => (closed ? '0px' : height)};
  margin-bottom: ${({ theme, closed }) =>
    closed ? `-${theme.spacing(2)}` : '0px'};
  border-bottom: ${({ theme, showBottomBorder }) =>
    showBottomBorder ? `1px solid ${theme.palette.grey[200]}` : 'none'};
  opacity: ${({ closed }) => (closed ? 0 : 1)};
`;

const Body = styled(Grid, {
  shouldForwardProp: (prop) => !['closed', 'height'].includes(prop.toString()),
})<GridProps & { closed?: boolean; height: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  height: ${({ height, closed }) => (closed ? '0px' : height)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ResolveButton = styled(MuiLink, {
  shouldForwardProp: (prop) => !['resolved'].includes(prop.toString()),
})<MuiLinkProps & { resolved?: boolean }>`
  color: ${({ theme, resolved }) =>
    resolved ? theme.palette.info.main : theme.palette.grey[400]};
  cursor: pointer;
  font-size: 12px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const ReminderBody = styled(Typography)`
  white-space: pre-line;
  word-break: break-word;
`;

const Author = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 270px;
`;

interface ReminderProps {
  reminder?: recoilGetPackageReportResolvedRemindersQuery$data['sys_reminders'][0];
  closeReminder?: () => void;
  showBottomBorder?: boolean;
}

const Reminder: React.FC<ReminderProps> = ({
  reminder,
  closeReminder,
  showBottomBorder,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState<string>('auto');
  const [closed, setClosed] = React.useState<boolean>(false);

  const markReminderResolved = useMutation(markReminderResolvedMutation, {
    mapVariables: () => async () => {
      if (!reminder) {
        throw new Error('reminder_id is required');
      }

      return {
        params: {
          reminder_id: reminder.reminder_id,
          is_resolved: !reminder.is_resolved,
        },
      };
    },
    onCompleted: (data, ctx) => {
      setClosed(true);
      if (closeReminder) {
        closeReminder();
      }
      ctx.showSnackbar({
        message: 'Reminder marked resolved',
        type: 'success',
      });
    },
    onError: (_, ctx) => {
      setClosed(false);
      ctx.showSnackbar({
        message: 'Reminder failed to post. Please try again.',
        type: 'error',
      });
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerHeight(`${containerRef.current.scrollHeight}px`);
  }, [containerRef.current]);

  const authorText = `${reminder?.user.sakura_user?.first_name} ${
    reminder?.user.sakura_user?.last_name
  } • ${moment(reminder?.created_at_datetime).format(
    'MMM DD, YYYY [at] h:mm A'
  )}`;

  return (
    <Container
      height={containerHeight}
      closed={closed}
      showBottomBorder={showBottomBorder}
    >
      <Body ref={containerRef} height={containerHeight} closed={closed}>
        <Grid>
          <ReminderBody>{reminder?.text}</ReminderBody>
          <Tooltip title={authorText}>
            <Author variant='body2'>
              {reminder?.user.sakura_user?.first_name}{' '}
              {reminder?.user.sakura_user?.last_name} •{' '}
              {moment(reminder?.created_at_datetime).format(
                'MMM DD, YYYY [at] h:mm A'
              )}
            </Author>
          </Tooltip>
        </Grid>

        <Tooltip title={reminder?.is_resolved ? 'Unresolved' : 'Mark Resolved'}>
          <ResolveButton
            onClick={markReminderResolved}
            resolved={reminder?.is_resolved || closed}
          >
            <CheckCircleIcon />
          </ResolveButton>
        </Tooltip>
      </Body>
    </Container>
  );
};

export default Reminder;
