import { useCherreEventWithRecoil } from '@cherre-frontend/data-fetching';
import { capitalize } from 'lodash';
import XLSX from 'xlsx';
import { getAccountTreeFile } from './queries/getAccountTreeFile';

export const useDownloadAccountTreeFile = () => {
  return useCherreEventWithRecoil(
    'download account tree file',
    (ctx) => async (id: number | undefined, fileName: string) => {
      const rawData = await ctx.recoil.getPromise(getAccountTreeFile(id));

      const keys = Object.keys(rawData.data[0]);

      const getMappedData = () => {
        return rawData.data.map((value) => {
          const mappedValues = keys
            .map((key: string) => ({
              [key.split('_').map(capitalize).join(' ')]:
                value[key] !== null ? value[key] : '',
            }))
            .flat();
          return Object.assign({}, ...mappedValues);
        });
      };

      const mappedData = getMappedData();

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(mappedData);

      const columns = Object.keys(mappedData[0]).map((key) => {
        const maxWidth = mappedData.reduce(
          (prev, current) => Math.max(prev, current[key].length),
          10
        );
        return { wch: Math.max(maxWidth, key.length) };
      });
      worksheet['!cols'] = columns;

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Account Grouping');
      XLSX.writeFile(workbook, fileName);
    }
  );
};
