import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';

const IN_PREPARATION_STAGES: number[] = [
  PropertyBatchStage.PENDING.id,
  PropertyBatchStage.IN_PREPARATION.id,
  PropertyBatchStage.REJECTED.id,
];

export const isInPreparation = (stage_id: number) =>
  IN_PREPARATION_STAGES.includes(stage_id);
