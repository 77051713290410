import React from 'react';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  addEditMappingSetDialogState,
  addEditMappingSetFormName,
  addEditMappingSetFormState,
} from '../recoil';
import { CloudUploadIcon, Button } from '@cherre-frontend/ui';

const UploadMappingButton: React.FC = () => {
  const isAdminUploadMappingEnabled = useFeatureFlag('DspAdminUploadMapping');
  const setAddEditMappingSetDialogState = useCherreSetState(
    addEditMappingSetDialogState
  );
  const setAddEditMappingSetFormState = useCherreSetState(
    addEditMappingSetFormState
  );
  const setAddEditMappingFormName = useCherreSetState(
    addEditMappingSetFormName
  );

  if (isAdminUploadMappingEnabled) {
    return (
      <Button
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          setAddEditMappingSetFormState({
            provider_id: 0,
            mapping_field_id: 0,
            mapping_field_name: '',
            mapping_set_description: '',
            master_chart_of_account_id: 0,
            id: 0,
          });
          setAddEditMappingFormName('');
          setAddEditMappingSetDialogState({ isOpen: true, type: 'Add' });
        }}
        variant='contained'
        color='primary'
        sx={{ marginTop: '12px' }}
      >
        <span>Upload Mapping</span>
      </Button>
    );
  }

  return null;
};

export default UploadMappingButton;
