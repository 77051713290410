import {
  GraphQLReturn,
  graphQLSelector,
  NotFoundError,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilCanReassignUserQuery } from './__generated__/recoilCanReassignUserQuery.graphql';
import { recoilReassignUserDetailsQuery } from './__generated__/recoilReassignUserDetailsQuery.graphql';

export const $canReassignUser = graphQLSelector({
  query: graphql`
    query recoilCanReassignUserQuery($id: Int!) {
      _sys_can_reassign_user(params: { user_id: $id }) {
        entities {
          entity_id
          submissions {
            submission_id
            submission_name
          }
        }
      }
    }
  ` as GraphQLReturn<recoilCanReassignUserQuery>,
  mapVariables: (id: number) => () => ({ id }),
  mapResponse: (rawResponse) => {
    return rawResponse._sys_can_reassign_user?.entities || [];
  },
});

export const $reassignUserDetails = graphQLSelector({
  query: graphql`
    query recoilReassignUserDetailsQuery($id: Int!) {
      user: sakura_user(where: { id: { _eq: $id } }) {
        user_id: id
        first_name @required(action: THROW)
        last_name @required(action: THROW)
        email @required(action: THROW)
        sys_user {
          provider {
            provider_name
            provider_id
          }
        }
        organization: sakura_organization @required(action: THROW) {
          organization_id: id
          organization_label: name
        }
      }
    }
  ` as GraphQLReturn<recoilReassignUserDetailsQuery>,
  mapVariables: (id: number) => () => ({ id }),
  mapResponse: (response) => {
    const [user] = response.user;
    if (!user) {
      throw new NotFoundError(`User not found`);
    }
    return user;
  },
});
