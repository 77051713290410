import { Avatar, Box, Typography, useTheme } from '@cherre-frontend/ui';
import React from 'react';
import { getNameInitials } from 'src/utils/getNameInitials';

type Props = {
  name?: string;
  email?: string;
};

const UserRow: React.FC<Props> = ({ name, email }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{
          width: '36px',
          height: '36px',
          fontSize: '12px',
          marginRight: '11px',
          fontWeight: 500,
          lineHeight: 1,
          backgroundColor: theme.palette.secondary.light,
        }}
      >
        {name ? getNameInitials(name) : null}
      </Avatar>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {name && (
          <Typography variant='body1' sx={{ fontWeight: 700 }}>
            {name}
          </Typography>
        )}
        {email && (
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.grey[700],
              maxWidth: '270px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {email}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UserRow;
