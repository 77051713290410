/**
 * @generated SignedSource<<63e439409765c3a8071942796a5ed22c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetProviderTypesQuery$variables = {};
export type recoilGetProviderTypesQuery$data = {
  readonly sys_provider_types: ReadonlyArray<{
    readonly name: string;
    readonly type_id: number;
  }>;
};
export type recoilGetProviderTypesQuery = {
  response: recoilGetProviderTypesQuery$data;
  variables: recoilGetProviderTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_provider_types",
    "kind": "LinkedField",
    "name": "sys_provider_types",
    "plural": true,
    "selections": [
      {
        "alias": "type_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetProviderTypesQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "recoilGetProviderTypesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "48b0b9bde5eb60dcfe124da975f3c2b9",
    "id": null,
    "metadata": {},
    "name": "recoilGetProviderTypesQuery",
    "operationKind": "query",
    "text": "query recoilGetProviderTypesQuery {\n  sys_provider_types {\n    type_id: id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "277cf4303d6f520f7520b95f17eb13ff";

export default node;
