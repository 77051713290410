import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { AccountGroupingDetailRoute } from 'src/products/data-submission-portal/routes';
import {
  accountGroupingDetailPageTableSearch,
  accountGroupingDetailPageTableState,
  accountGroupingDetailTableFiltersState,
} from '../recoil';
import { getAccountTreeDetailsLastestUpdateQuery } from './__generated__/getAccountTreeDetailsLastestUpdateQuery.graphql';
import {
  getAccountTreeDetailsQuery,
  sys_organization_account_tree_details_bool_exp,
} from './__generated__/getAccountTreeDetailsQuery.graphql';
import { getAccountTreeDetailsGroupCodesQuery } from './__generated__/getAccountTreeDetailsGroupCodesQuery.graphql';
import { getAccountTreeDetailsCodeDescriptionsQuery } from './__generated__/getAccountTreeDetailsCodeDescriptionsQuery.graphql';
import { getAccountTreeDetailsGLCodesQuery } from './__generated__/getAccountTreeDetailsGLCodesQuery.graphql';
import { getAccountTreeDetailsNestsQuery } from './__generated__/getAccountTreeDetailsNestsQuery.graphql';
import { getAccountTreeDetailsTotalIntoCodesQuery } from './__generated__/getAccountTreeDetailsTotalIntoCodesQuery.graphql';
import { getAccountTreeDetailsTypesQuery } from './__generated__/getAccountTreeDetailsTypesQuery.graphql';

export const getAccountTreeDetails = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
      $limit: Int = 25
      $offset: Int = 0
    ) {
      sys_organization_account_tree_details(
        where: $where
        limit: $limit
        offset: $offset
      ) {
        chart_of_account_id
        account_tree_id
        account_type
        account_group_code
        account_code_description
        gl_account_code
        is_normal_balance
        is_contiguous
        total_into_code
        indent
        is_hidden
        is_negate
        nest_level
        order_index
      }
      total: sys_organization_account_tree_details_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;
      const tableState = get(accountGroupingDetailPageTableState);

      // search input
      const hasSearch =
        (get(accountGroupingDetailPageTableSearch) ?? '').length > 0;
      const search = `%${get(accountGroupingDetailPageTableSearch) ?? ''}%`;
      const searchClause: sys_organization_account_tree_details_bool_exp =
        !hasSearch
          ? {}
          : {
              _or: [
                {
                  _and: [{ account_type: { _ilike: search } }],
                },
                {
                  _and: [{ account_group_code: { _ilike: search } }],
                },
                {
                  _and: [{ account_code_description: { _ilike: search } }],
                },
                {
                  _and: [{ gl_account_code: { _ilike: search } }],
                },
                {
                  _and: [{ total_into_code: { _ilike: search } }],
                },
              ],
            };

      // filters
      const filterClause: sys_organization_account_tree_details_bool_exp[] = [];
      const {
        account_group_record_type,
        account_group_code,
        account_group_description,
        gl_account_code,
        total_into_code,
        nest,
      } = get(accountGroupingDetailTableFiltersState);
      if (account_group_record_type.length > 0) {
        filterClause.push({
          account_type: { _in: account_group_record_type },
        });
      }
      if (account_group_code.length > 0) {
        filterClause.push({
          account_group_code: { _in: account_group_code },
        });
      }
      if (account_group_description.length > 0) {
        filterClause.push({
          account_code_description: { _in: account_group_description },
        });
      }
      if (gl_account_code.length > 0) {
        filterClause.push({
          gl_account_code: { _in: gl_account_code },
        });
      }
      if (total_into_code.length > 0) {
        filterClause.push({
          total_into_code: { _in: total_into_code },
        });
      }
      if (nest.length > 0) {
        filterClause.push({
          nest_level: { _in: nest.map((value) => parseInt(value) ?? 0) },
        });
      }

      return {
        where: {
          _and: [
            { _and: filterClause },
            { account_tree_id: { _eq: id } },
            searchClause,
          ],
        },
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
      };
    },
  mapResponse: (resp) => {
    return {
      data: resp.sys_organization_account_tree_details,
      total: resp.total,
    };
  },
});

export const getTypesList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsTypesQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: account_type
        where: $where
        order_by: { account_type: asc }
      ) {
        account_type
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsTypesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.account_type
    );
  },
});

export const getGroupCodesList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsGroupCodesQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: account_group_code
        where: $where
        order_by: { account_group_code: asc }
      ) {
        account_group_code
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsGroupCodesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.account_group_code
    );
  },
});

export const getCodeDescriptionList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsCodeDescriptionsQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: account_code_description
        where: $where
        order_by: { account_code_description: asc }
      ) {
        account_code_description
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsCodeDescriptionsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.account_code_description
    );
  },
});

export const getGlCodesList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsGLCodesQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: gl_account_code
        where: $where
        order_by: { gl_account_code: asc }
      ) {
        gl_account_code
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsGLCodesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.gl_account_code
    );
  },
});

export const getTotalIntoCodesList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsTotalIntoCodesQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: total_into_code
        where: $where
        order_by: { total_into_code: asc }
      ) {
        total_into_code
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsTotalIntoCodesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.total_into_code
    );
  },
});

export const getNestsList = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsNestsQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        distinct_on: nest_level
        where: $where
        order_by: { nest_level: asc }
      ) {
        nest_level
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsNestsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details.map(
      (value) => value.nest_level
    );
  },
});

export const getAccountTreeLatestUpdate = graphQLSelector({
  query: graphql`
    query getAccountTreeDetailsLastestUpdateQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(
        where: $where
        limit: 1
        order_by: { updated_at_datetime: desc }
      ) {
        updated_at_datetime
      }
    }
  ` as GraphQLReturn<getAccountTreeDetailsLastestUpdateQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;

      return {
        where: { account_tree_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_account_tree_details[0].updated_at_datetime;
  },
});
