/**
 * @generated SignedSource<<0b62643e3861c48284c1a83978d98d52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilSubmissionBulkActionsQuery$variables = {
  submission_id: number;
};
export type recoilSubmissionBulkActionsQuery$data = {
  readonly _sys_workflow_get_available_bulk_actions_for_submission: {
    readonly actions: ReadonlyArray<{
      readonly name: string;
      readonly property_batch_ids: ReadonlyArray<number>;
      readonly slug: string;
    } | null>;
  } | null;
};
export type recoilSubmissionBulkActionsQuery = {
  response: recoilSubmissionBulkActionsQuery$data;
  variables: recoilSubmissionBulkActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "submission_id",
            "variableName": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "_sys_workflow_get_available_bulk_actions_for_submission_output",
    "kind": "LinkedField",
    "name": "_sys_workflow_get_available_bulk_actions_for_submission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "_sys_workflow_available_bulk_action",
        "kind": "LinkedField",
        "name": "actions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_batch_ids",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilSubmissionBulkActionsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilSubmissionBulkActionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "92091603dc705e845ab510b20602d0a0",
    "id": null,
    "metadata": {},
    "name": "recoilSubmissionBulkActionsQuery",
    "operationKind": "query",
    "text": "query recoilSubmissionBulkActionsQuery(\n  $submission_id: Int!\n) {\n  _sys_workflow_get_available_bulk_actions_for_submission(params: {submission_id: $submission_id}) {\n    actions {\n      property_batch_ids\n      slug\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "488113a2446bac7976a806dbf81db4fa";

export default node;
