import React from 'react';
import { Dialog as OgDialog, DialogProps, styled } from '@cherre-frontend/ui';

export const Dialog = styled<
  React.FC<DialogProps & { width: string; height?: string; minHeight?: string }>
>(OgDialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (props.height ? `height: ${props.height};` : '')}
  }
`;
