import {
  SubmissionIcon,
  UploadFileIcon,
  DashboardIcon,
  FileOkIcon,
  ReportWindowIcon,
  AdminIcon,
  CityIcon,
  DocumentStackIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import { usePermission } from 'src/hooks';
import { RouteListType } from 'src/utils/routing';
import {
  createRoute,
  createRouteGroup,
  PersonaWrapper,
  isDspRole,
  groupRoutes,
} from 'src/utils/routing/routeFactory';
import { Redirect, useParams } from 'react-router';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const SUBMISSION_PORTAL_GROUP = 'Data Submission Portal';

export const BatchManagementRoute = createRoute({
  path: '/dsp/batch-management',
  component: React.lazy(() => import('./pages/batch-management')),
  menu: {
    title: 'Batch Management',
    icon: UploadFileIcon,
    group: SUBMISSION_PORTAL_GROUP,
  },
  permissionHook: () => usePermission('DSPAdmin'),
});

export const BatchDetailRoute = createRoute({
  path: '/dsp/batch-detail/:submission_id(\\d+)',
  component: React.lazy(() => import('./pages/batch-detail')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const BatchValidationSummaryRoute = createRouteGroup({
  path: '/dsp/{{persona}}/batch-validations/:property_batch_id(\\d+)',
  component: React.lazy(() => import('./pages/batch-validations')),
});

export const SingleFileValidationSummaryRoute = createRouteGroup({
  path: '/dsp/{{persona}}/single-file-validations/:dataset_id(\\d+)',
  component: React.lazy(() => import('./pages/single-file-validations')),
});

export const SubmissionsRoute = createRouteGroup({
  path: '/dsp/{{persona}}/submissions',
  component: React.lazy(() => import('./pages/submissions')),
  menu: (persona) => {
    if (persona === 'view_admin') {
      return;
    }

    let title = '';
    let icon: React.ReactNode = SubmissionIcon;
    switch (persona) {
      case 'preparer':
        title = 'Prepare';
        break;
      case 'view_only':
        title = 'View';
        icon = DashboardIcon;
        break;
      case 'reviewer':
        title = 'Review';
        icon = FileOkIcon;
        break;
    }

    return {
      title: `${title} Submissions`,
      icon,
      group: SUBMISSION_PORTAL_GROUP,
    };
  },
});

export const SubmissionRoute = createRouteGroup({
  path: '/dsp/{{persona}}/submissions/:submission_id(\\d+)',
  component: React.lazy(() => import('./pages/submission-batch-details')),
});

export const MonitorSubmissionsRoute = createRoute({
  path: '/dsp/submissions-insights',
  component: React.lazy(() => import('./pages/monitor-submissions')),
  menu: {
    title: 'Submissions Insights',
    icon: ReportWindowIcon,
    group: SUBMISSION_PORTAL_GROUP,
  },
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPMonitorSubmissions') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const UploadMappingSetRoute = createRoute({
  path: '/dsp/upload-mapping/:mapping_field_id(\\d+)',
  component: React.lazy(() => import('./pages/upload-mapping-set')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DspAdminUploadMapping') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const UploadMappingSetNewRoute = createRoute({
  path: '/dsp/upload-mapping-new/:mapping_field_id(\\d+)',
  component: React.lazy(() => import('./pages/upload-mapping-set-new')),
  permissionHook: () => {
    const hasUploadMappingFlag =
      useFeatureFlag('DspAdminUploadMapping') ?? false;
    const hasIngestionFlag = useFeatureFlag('DSPNewIngestionAdmin') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasUploadMappingFlag && hasIngestionFlag && isAdmin;
  },
});

export const UploadPropertyRoute = createRoute({
  path: '/dsp/upload-property/',
  component: React.lazy(() => import('./pages/upload-property')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DspAdminUploadProperty') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const UploadPropertyNewRoute = createRoute({
  path: '/dsp/upload-property-new',
  component: React.lazy(() => import('./pages/upload-property-new')),
  permissionHook: () => {
    const hasUploadPropertyFlag =
      useFeatureFlag('DspAdminUploadProperty') ?? false;
    const hasIngestionFlag = useFeatureFlag('DSPNewIngestionAdmin') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasUploadPropertyFlag && hasIngestionFlag && isAdmin;
  },
});

export const UploadDatasetRoute = createRoute({
  path: '/dsp/upload-dataset/:dataset_id(\\d+)',
  component: PersonaWrapper(
    React.lazy(() => import('./pages/upload-dataset')),
    'preparer'
  ),
  permissionHook: isDspRole('preparer'),
});

export const UploadDatasetBulkRoute = createRoute({
  path: '/dsp/upload-dataset/:dataset_id(\\d+)/submission/:submission_id(\\d+)',
  component: PersonaWrapper(
    React.lazy(() => import('./pages/upload-dataset-bulk')),
    'preparer'
  ),
  permissionHook: isDspRole('preparer'),
});

export const UploadDatasetNewRoute = groupRoutes(
  createRoute({
    path: '/dsp/upload-dataset-new/:dataset_id(\\d+)',
    component: PersonaWrapper(
      React.lazy(() => import('./pages/upload-dataset-new')),
      'preparer'
    ),
    permissionHook: () =>
      isDspRole('preparer') && (useFeatureFlag('DSPNewIngestion') ?? false),
  }),
  createRoute({
    path: '/dsp/reviewer/upload-dataset-new/:dataset_id(\\d+)',
    component: PersonaWrapper(
      React.lazy(() => import('./pages/upload-dataset-new')),
      'reviewer'
    ),
    permissionHook: () =>
      isDspRole('reviewer') && (useFeatureFlag('DSPNewIngestion') ?? false),
  })
);

export const UploadDatasetBulkNewRoute = createRoute({
  path: '/dsp/upload-dataset-new/:dataset_id(\\d+)/submission/:submission_id(\\d+)',
  component: PersonaWrapper(
    React.lazy(() => import('./pages/upload-dataset-bulk-new')),
    'preparer'
  ),
  permissionHook: () =>
    isDspRole('preparer') && (useFeatureFlag('DSPNewIngestion') ?? false),
});

export const ProvidersRoute = createRoute({
  path: '/dsp/providers',
  component: React.lazy(() => import('./pages/providers')),
  menu: {
    title: 'Service Providers',
    icon: AdminIcon,
    group: SUBMISSION_PORTAL_GROUP,
  },
  permissionHook: () => usePermission('DSPAdmin'),
});

export const AddProviderRoute = createRoute({
  path: '/dsp/providers/add',
  component: React.lazy(() => import('./pages/add-edit-provider')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddEditProvider') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const EditProviderRoute = createRoute({
  path: '/dsp/providers/:provider_id(\\d+)/edit',
  component: React.lazy(() => import('./pages/add-edit-provider')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddEditProvider') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const ProviderDetailsRoute = createRoute({
  path: '/dsp/providers/:provider_id(\\d+)',
  component: React.lazy(() => import('./pages/provider-detail')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const MappingDetailRoute = createRoute({
  path: '/dsp/providers/:provider_id(\\d+)/mapping/:mapping_set_id(\\d+)',
  component: React.lazy(() => import('./pages/mapping-detail')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const ProviderUserAddNewRoute = createRoute({
  path: '/dsp/providers/:provider_id(\\d+)/new-user',
  component: React.lazy(() => import('./pages/add-provider-user')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const ProviderUserEditUserRoute = createRoute({
  path: '/dsp/providers/:provider_id(\\d+)/user/:user_id(\\d+)/edit-user',
  component: React.lazy(() => import('./pages/edit-provider-user')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const ReviewPropertiesRoute = createRoute({
  path: '/dsp/review-properties',
  component: React.lazy(() => import('./pages/review-properties')),
  permissionHook: () => usePermission('DSPAdmin'),
});

export const PropertiesRoute = createRoute({
  path: '/dsp/properties',
  component: React.lazy(() => import('./pages/properties')),
  menu: {
    title: 'Properties',
    icon: CityIcon,
    group: SUBMISSION_PORTAL_GROUP,
  },
  permissionHook: () => usePermission('DSPAdmin'),
});

export const MasterFilesRoute = createRoute({
  path: '/dsp/master-files',
  component: React.lazy(() => import('./pages/master-files')),
  menu: {
    title: 'Master Files',
    icon: DocumentStackIcon,
    group: SUBMISSION_PORTAL_GROUP,
  },
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const UploadMasterFilesRoute = createRoute({
  path: '/dsp/master-files/upload',
  component: React.lazy(() => import('./pages/upload-master-file')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const UploadMasterFilesNewRoute = createRoute({
  path: '/dsp/master-files-new/upload',
  component: React.lazy(() => import('./pages/upload-master-file-new')),
  permissionHook: () => {
    const hasMasterFileFlag =
      useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const hasIngestionFlag = useFeatureFlag('DSPNewIngestionAdmin') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasMasterFileFlag && hasIngestionFlag && isAdmin;
  },
});

export const UploadAccountGroupingsRoute = createRoute({
  path: '/dsp/account-groupings/upload',
  component: React.lazy(() => import('./pages/upload-account-groupings')),
  permissionHook: () => {
    const hasMasterFileFlag =
      useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const hasIngestionFlag = useFeatureFlag('DSPNewIngestionAdmin') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasMasterFileFlag && hasIngestionFlag && isAdmin;
  },
});

export const MasterFileDetailRoute = createRoute({
  path: '/dsp/master-files/:master_file_id(\\d+)',
  component: React.lazy(() => import('./pages/master-files-detail')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const AccountGroupingDetailRoute = createRoute({
  path: '/dsp/master-files/account-grouping/:account_grouping_id(\\d+)',
  component: React.lazy(() => import('./pages/account-grouping-detail')),
  permissionHook: () => {
    const hasFF = useFeatureFlag('DSPAddDeleteMasterFiles') ?? false;
    const isAdmin = usePermission('DSPAdmin');
    return hasFF && isAdmin;
  },
});

export const SubmissionsReviewerRedirect = createRoute({
  path: '/dsp/review-submissions',
  component: () => <Redirect to='/dsp/reviewer/submissions' />,
  permissionHook: isDspRole('reviewer'),
});

export const SubmissionsDetailReviewerRedirect = createRoute({
  path: '/dsp/review-submissions/:submission_id(\\d+)',
  component: () => {
    const { submission_id } = useParams<{ submission_id: string }>();
    return <Redirect to={`/dsp/reviewer/submissions/${submission_id}`} />;
  },
  permissionHook: isDspRole('reviewer'),
});

export const SubmissionsPreparerRedirect = createRoute({
  path: '/dsp/prepare-submissions',
  component: () => <Redirect to='/dsp/preparer/submissions' />,
  permissionHook: isDspRole('preparer'),
});

export const SubmissionsDetailPreparerRedirect = createRoute({
  path: '/dsp/prepare-submissions/:submission_id(\\d+)',
  component: () => {
    const { submission_id } = useParams<{ submission_id: string }>();
    return <Redirect to={`/dsp/preparer/submissions/${submission_id}`} />;
  },
  permissionHook: isDspRole('preparer'),
});

export const NotificationLinkRedirectRoute = createRoute({
  path: '/dsp/notification-link',
  component: React.lazy(() => import('./pages/notification-link')),
});

export const dspRoutes = [
  {
    permissions: ['DSPAdmin', 'DSPRegular'],
    header: SUBMISSION_PORTAL_GROUP,
    routes: [
      SubmissionsReviewerRedirect.routeDescriptor,
      SubmissionsDetailReviewerRedirect.routeDescriptor,
      SubmissionsPreparerRedirect.routeDescriptor,
      SubmissionsDetailPreparerRedirect.routeDescriptor,
      NotificationLinkRedirectRoute.routeDescriptor,
      MonitorSubmissionsRoute.routeDescriptor,
      UploadMappingSetRoute.routeDescriptor,
      UploadPropertyRoute.routeDescriptor,
      ProvidersRoute.routeDescriptor,
      ProviderDetailsRoute.routeDescriptor,
      BatchManagementRoute.routeDescriptor,
      BatchDetailRoute.routeDescriptor,
      PropertiesRoute.routeDescriptor,
      ...SingleFileValidationSummaryRoute.routeDescriptors,
      ...BatchValidationSummaryRoute.routeDescriptors,
      ...SubmissionsRoute.routeDescriptors,
      ...SubmissionRoute.routeDescriptors,
      UploadDatasetRoute.routeDescriptor,
      UploadDatasetBulkRoute.routeDescriptor,
      MappingDetailRoute.routeDescriptor,
      ReviewPropertiesRoute.routeDescriptor,
      ProviderUserAddNewRoute.routeDescriptor,
      ProviderUserEditUserRoute.routeDescriptor,
      AddProviderRoute.routeDescriptor,
      EditProviderRoute.routeDescriptor,
      MasterFilesRoute.routeDescriptor,
      UploadMasterFilesRoute.routeDescriptor,
      MasterFileDetailRoute.routeDescriptor,
      ...UploadDatasetNewRoute.routeDescriptors,
      UploadDatasetBulkNewRoute.routeDescriptor,
      UploadMasterFilesNewRoute.routeDescriptor,
      UploadPropertyNewRoute.routeDescriptor,
      UploadMappingSetNewRoute.routeDescriptor,
      UploadAccountGroupingsRoute.routeDescriptor,
      AccountGroupingDetailRoute.routeDescriptor,
    ],
  },
] satisfies RouteListType;
