/**
 * @generated SignedSource<<1934a5722f8899c86524d8b99f2a83e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getDuplicatedMappingSetQuery$variables = {
  mapping_field_id: number;
  mapping_set_description: string;
  provider_id: number;
};
export type getDuplicatedMappingSetQuery$data = {
  readonly sys_mapping_sets: ReadonlyArray<{
    readonly mapping_set_id: number;
  }>;
};
export type getDuplicatedMappingSetQuery = {
  response: getDuplicatedMappingSetQuery$data;
  variables: getDuplicatedMappingSetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mapping_field_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mapping_set_description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "provider_id"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "mapping_field_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_field_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "mapping_set_description"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_set_description"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "provider_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_sets",
    "kind": "LinkedField",
    "name": "sys_mapping_sets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getDuplicatedMappingSetQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "getDuplicatedMappingSetQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "8cb008e6474eaf6bb35036e6db159396",
    "id": null,
    "metadata": {},
    "name": "getDuplicatedMappingSetQuery",
    "operationKind": "query",
    "text": "query getDuplicatedMappingSetQuery(\n  $provider_id: Int!\n  $mapping_set_description: String!\n  $mapping_field_id: Int!\n) {\n  sys_mapping_sets(where: {provider_id: {_eq: $provider_id}, is_active: {_eq: true}, mapping_set_description: {_ilike: $mapping_set_description}, mapping_field_id: {_eq: $mapping_field_id}}, limit: 1) {\n    mapping_set_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "40c5c9308ad82ffccdcca5a95224327b";

export default node;
