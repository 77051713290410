type Property = {
  readonly coa_mappings: ReadonlyArray<{
    readonly mapping_set_id: number;
  }>;
  readonly datasets_count: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly entity_id: string;
  readonly provider: {
    readonly provider_name: string;
  } | null;
  readonly users_count: {
    readonly aggregate: { count: number } | null;
  };
};

export const isPropertyActive = (row: Property) => {
  const hasUsers = (row.users_count?.aggregate?.count ?? 0) > 0;
  const hasProvider = !!row.provider;

  const hasDatasets = (row.datasets_count.aggregate?.count ?? 0) > 0;
  const hasMappings = row.coa_mappings.length > 0;
  const hasEntityId = !!row.entity_id;
  return hasUsers && hasDatasets && hasMappings && hasEntityId && hasProvider;
};
