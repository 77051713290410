import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getOpenedBatchesWithChartOfAccountsSubscription } from './__generated__/getOpenedBatchesWithChartOfAccountsSubscription.graphql';

export const getOpenedBatchesWithChartOfAccounts = graphQLSelector({
  query: graphql`
    subscription getOpenedBatchesWithChartOfAccountsSubscription(
      $master_chart_of_account_id: Int!
    ) {
      sys_submissions(
        where: {
          is_closed: { _eq: false }
          property_batches: {
            property: {
              is_active: { _eq: true }
              properties_mapping_sets: {
                is_active: { _eq: true }
                mapping_set: {
                  master_chart_of_account_id: {
                    _eq: $master_chart_of_account_id
                  }
                }
              }
            }
          }
        }
        order_by: { submission_name: asc }
      ) {
        submission_id
        submission_name
      }
    }
  ` as GraphQLReturn<getOpenedBatchesWithChartOfAccountsSubscription>,
  mapResponse: (response) => response.sys_submissions,
  mapVariables: (master_chart_of_account_id: number) => () => ({
    master_chart_of_account_id,
  }),
});
