import {
  atom,
  atomFamily,
  dependsOn,
  GetCherreValue,
  selector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { MRT_SortingState } from '@cherre-frontend/ui';
import { namespace } from '../consts';
import { mrtToHasuraSorting } from '../utils';
import { sys_properties_flattened_union_bool_exp } from './__generated__/queriesGetPropertiesQuery.graphql';
import { newBatchInfo } from './localState';
import {
  Property,
  Provider,
  getProperties,
  getProviders,
  getSubmissionName,
} from './queries';
import { PropertyRoleSet } from 'src/products/data-submission-portal/__generated__/constants';
import { getOrganizationPropertyModel } from 'src/products/data-submission-portal/recoil/getOrganizationPropertyModel';

export const currentProvider = selector<Provider>({
  key: namespace('current-provider'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const info = get(newBatchInfo);
      if (!info?.provider_id) {
        throw new Error('Provider ID is missing');
      }
      const providersResult = get(
        getProviders({ where: { provider_id: { _eq: info.provider_id } } })
      );
      if (providersResult.length === 0) {
        throw new Error(`Provider with ID ${info.provider_id} does not exist`);
      }
      return providersResult[0];
    },
});

export const generatedBatchName = selectorFamily<string, number>({
  key: namespace('generated-batch-name'),
  scoped: true,
  get:
    (submission_type) =>
    ({ get }) => {
      const info = get(newBatchInfo);
      if (!info) {
        throw new Error('Batch info is missing');
      }
      const { provider_id, submission_interval, submission_period } = info;
      if (!provider_id) {
        throw new Error('Provider ID is missing');
      }
      if (!submission_interval) {
        throw new Error('Submission interval is missing');
      }
      if (!submission_period) {
        throw new Error('Submission period is missing');
      }
      return get(
        getSubmissionName({
          params: {
            provider_id,
            submission_interval,
            submission_period: submission_period.toISOString(),
            submission_type,
          },
        })
      );
    },
});

export const tableSearch = atomFamily<string, number>({
  key: namespace('table-search'),
  default: () => '',
  effects: () => [dependsOn(newBatchInfo)],
});

export const tableSort = atomFamily<MRT_SortingState, number>({
  key: namespace('table-sort'),
  default: () => [
    { id: 'property_mapping', desc: true },
    { id: 'entity_id', desc: false },
  ],
  effects: () => [dependsOn(newBatchInfo)],
});

const getValidPropertiesWhere = (
  get: GetCherreValue,
  provider_id: number,
  type: number
) => {
  /*
    properties assigned to a Service Provider
    at least one Preparer assigned to the property/properties
    at least one Reviewer assigned to the property/properties for the intended submission type
    a GL account mapping assigned to the property/properties
    at least one dataset assigned to the property/properties for the corresponding submission type
*/

  const { isManyToOne } = get(getOrganizationPropertyModel());

  const mappingSetsCondition: sys_properties_flattened_union_bool_exp =
    isManyToOne
      ? // a GL account mapping assigned to the property/properties. In ManyToOne, we need to check if all sibilings have a mapping
        {
          parent_property: {
            to_property_relationships_aggregate: {
              // count of provider properties W/OUT a GL account mapping. It should be 0
              count: {
                predicate: { _eq: 0 },
                filter: {
                  is_active: { _eq: true },
                  properties_flattened_union: {
                    provider_id: {
                      _eq: provider_id,
                    },
                  },
                  _not: {
                    from_property: {
                      properties_mapping_sets: {
                        is_active: { _eq: true },
                        mapping_set: {
                          mapping_field: {
                            mapping_field_name: { _eq: 'gl_account_code' },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        }
      : // a GL account mapping assigned to the property/properties. In OneToOne, we need to check if the property has a mapping
        {
          properties_mapping_sets: {
            is_active: { _eq: true },
            mapping_set: {
              mapping_field: {
                mapping_field_name: { _eq: 'gl_account_code' },
              },
            },
          },
        };
  // end mappingSetsCondition

  const datasetCondition: sys_properties_flattened_union_bool_exp = isManyToOne
    ? // at least one dataset assigned. In ManyToOne, we need to check if all sibilings have a mapping
      {
        parent_property: {
          to_property_relationships_aggregate: {
            count: {
              predicate: { _eq: 0 },
              filter: {
                is_active: { _eq: true },
                _not: {
                  from_property: {
                    properties_datasets: {
                      is_active: { _eq: true },
                      dataset: {
                        submission_type: {
                          submission_type_id: { _eq: type },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
    : // at least one dataset assigned. In OneToOne, we need to check if the property has a dataset
      {
        properties_datasets: {
          is_active: { _eq: true },
          dataset: {
            submission_type: {
              submission_type_id: { _eq: type },
            },
          },
        },
      };

  const and: NonNullable<sys_properties_flattened_union_bool_exp>[] = [
    mappingSetsCondition,
    datasetCondition,
    // properties assigned to a Service Provider
    { provider_id: { _eq: provider_id } },
    // at least one Preparer assigned to the property/properties
    {
      properties_roles_users: {
        is_active: { _eq: true },
        property_role: {
          property_role_set: { _eq: PropertyRoleSet.PREPARER.id },
        },
      },
    },
    // at least one Reviewer assigned to the property/properties for the intended submission type
    {
      properties_roles_users: {
        is_active: { _eq: true },
        submission_type_id: { _eq: type },
        property_role: {
          property_role_set: {
            _in: [
              PropertyRoleSet.REVIEWER_1.id,
              PropertyRoleSet.APPROVER_PLUS_1.id,
            ],
          },
        },
      },
    },
  ];

  return and;
};

export const tableData = selectorFamily<Property[], number>({
  key: namespace('table-data'),
  scoped: true,
  get:
    (type) =>
    async ({ get }) => {
      const provider = get(currentProvider);
      const search = get(tableSearch(type));
      const sort = get(tableSort(type));
      const or: NonNullable<sys_properties_flattened_union_bool_exp>[] = [];
      if (search) {
        or.push({ entity_id: { _ilike: `%${search}%` } });
        or.push({ entity_name: { _ilike: `%${search}%` } });
        or.push({ address: { _ilike: `%${search}%` } });
        or.push({ fund: { _ilike: `%${search}%` } });
        or.push({ type: { _ilike: `%${search}%` } });
      }
      const and = getValidPropertiesWhere(get, provider.provider_id, type);
      if (or.length) {
        and.push({ _or: or });
      }
      return get(
        getProperties({
          where: { _and: and },
          order_by: mrtToHasuraSorting(sort),
          datasets_where: {
            is_active: { _eq: true },
            dataset: {
              submission_type: {
                submission_type_id: { _eq: type },
              },
            },
          },
        })
      );
    },
});

export type RowSelection = Record<string, boolean>;

const initialSelection = selectorFamily<RowSelection, number>({
  key: namespace('initial-selection'),
  scoped: true,
  get:
    (type) =>
    ({ get }) => {
      const provider = get(currentProvider);
      const and = getValidPropertiesWhere(get, provider.provider_id, type);
      const properties = get(
        getProperties({
          where: {
            _and: and,
          },
          datasets_where: {
            is_active: { _eq: true },
            dataset: {
              submission_type: {
                submission_type_id: { _eq: type },
              },
            },
          },
        })
      );
      return Object.fromEntries(
        properties.map(({ property_id }) => [`${property_id}`, true])
      );
    },
});

export const selection = atomFamily<RowSelection, number>({
  key: namespace('selection'),
  scoped: true,
  default: (type) => initialSelection(type),
  effects: () => [dependsOn(newBatchInfo)],
});

export const excludedCount = selector<number>({
  key: namespace('excluded-count'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const info = get(newBatchInfo);
      if (!info?.submission_types) {
        return 0;
      }
      const initial = info.submission_types.reduce(
        (acc, type) => acc + Object.keys(get(initialSelection(type))).length,
        0
      );
      const current = info.submission_types.reduce(
        (acc, type) => acc + Object.keys(get(selection(type))).length,
        0
      );
      return initial - current;
    },
});

export const excludedPropertiesBannerDimissed = atom<boolean>({
  key: namespace('excluded-properties-banner-dismissed'),
  default: false,
  effects: [dependsOn(newBatchInfo)],
});

export const selectedTab = atom<number>({
  key: namespace('selected-tab'),
  default: selector({
    key: namespace('selected-tab-default'),
    get: ({ get }) => {
      const t = get(newBatchInfo)?.submission_types?.[0];
      if (typeof t !== 'number') {
        throw new Error(`Invalid submission type: ${t}`);
      }
      return t;
    },
  }),
  effects: [dependsOn(newBatchInfo)],
});
