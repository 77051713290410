import React, { useMemo } from 'react';
import { PropertyBatchAccordionProps } from '../PropertyBatchAccordion/types';
import { PropertyScope } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { Submission } from './recoil';
import { PropertyModel } from '../../../../__generated__/constants';
import { groupBy } from 'lodash';

const getPropertyId = (
  propertyBatch: Submission['property_batches'][number]
) => {
  const property = propertyBatch.properties_flattened_union;
  return property.property_models?.property_model_slug ===
    PropertyModel.MANY_TO_ONE.label
    ? property.parent_property_id
    : property.property_id;
};

export const Accordions: React.FC<{
  Component: React.FC<PropertyBatchAccordionProps>;
  submission?: Submission;
}> = ({ Component, submission }) => {
  return (
    <>
      {submission?.property_batches?.map((propertyBatch) => (
        <PropertyScope
          key={propertyBatch.property_batch_id}
          property={propertyBatch.properties_flattened_union}
        >
          <Component propertyBatch={propertyBatch} />
        </PropertyScope>
      ))}
    </>
  );
};

export const AccordionsWithPropertyBatchesGrouped: React.FC<{
  Component: React.FC<PropertyBatchAccordionProps>;
  submission?: Submission;
  sortBy?: string;
  sortDirection?: string;
}> = ({ Component, submission, sortDirection }) => {
  const groupedPropertyBatches = useMemo(() => {
    const groups = groupBy(submission?.property_batches ?? [], getPropertyId);
    return Object.entries(groups).sort(([, a], [, b]) => {
      const codeA =
        sortDirection === 'asc'
          ? a[0]?.properties_flattened_union.property_code
          : b[0]?.properties_flattened_union.property_code;
      const codeB =
        sortDirection === 'asc'
          ? b[0]?.properties_flattened_union.property_code
          : a[0]?.properties_flattened_union.property_code;
      return codeA?.localeCompare(codeB);
    });
  }, [submission?.property_batches, sortDirection]);

  return (
    <>
      {groupedPropertyBatches.map(([id, propertyBatches]) => {
        const isM1 = propertyBatches.every(
          (pb) =>
            pb.properties_flattened_union.property_models
              ?.property_model_slug === PropertyModel.MANY_TO_ONE.label
        );

        const investmentPropertyBatch =
          submission?.investment_property_batches.find(
            (p) => p.property_id === Number(id)
          ) ?? null;

        const key = propertyBatches
          .map(
            (p) =>
              `${p.property_batch_id}:${p.properties_flattened_union.property_id}`
          )
          .join('-');

        return (
          <PropertyScope key={key} propertyId={Number(id)} property={null}>
            {isM1 && investmentPropertyBatch && propertyBatches.length > 1 ? (
              <Component
                investmentPropertyBatch={investmentPropertyBatch}
                propertyBatches={propertyBatches}
              />
            ) : (
              <Component
                investmentPropertyBatch={investmentPropertyBatch ?? undefined}
                propertyBatch={propertyBatches[0]}
              />
            )}
          </PropertyScope>
        );
      })}
    </>
  );
};
