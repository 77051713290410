import React, { useEffect } from 'react';
import {
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@cherre-frontend/ui';
import { withDefault, nullable, stringLiterals } from '@recoiljs/refine';
import {
  searchParamsSelector,
  selectorFamily,
  DefaultValue,
} from '@cherre-frontend/data-fetching';
import {
  PersonasType,
  usePersona,
} from '../../packages/dsp-role-based-rendering';
import { useRecoilState } from 'recoil';

export const ReportViewTypes = {
  source: 'source',
  target: 'target',
  adjusted: 'adjusted',
  combined: 'combined',
} as const;

export const ReportViewTypeLabels = {
  source: 'Submitted',
  target: 'Mapped',
  adjusted: 'Adjusted',
  combined: 'Combined',
} as const;

export type ReportViewType = keyof typeof ReportViewTypes;

const ReportViewTypesByPersona = {
  preparer: [ReportViewTypes.source, ReportViewTypes.target],
  reviewer: (tsa) =>
    tsa
      ? [
          ReportViewTypes.target,
          ReportViewTypes.adjusted,
          ReportViewTypes.combined,
        ]
      : [ReportViewTypes.source, ReportViewTypes.target],
  view_admin: (tsa) =>
    tsa
      ? [
          ReportViewTypes.target,
          ReportViewTypes.adjusted,
          ReportViewTypes.combined,
        ]
      : [ReportViewTypes.source, ReportViewTypes.target],
  view_only: (tsa) =>
    tsa
      ? [
          ReportViewTypes.target,
          ReportViewTypes.adjusted,
          ReportViewTypes.combined,
        ]
      : [ReportViewTypes.source, ReportViewTypes.target],
} satisfies Record<
  PersonasType,
  ReportViewType[] | ((tsa: boolean) => ReportViewType[])
>;

const __INTERNAL_reportSelectedViewTypeSelector = searchParamsSelector({
  key: 'report-selected-view-type-internal',
  refine: withDefault(nullable(stringLiterals(ReportViewTypes)), null),
  routes: ['/**'],
});

export const reportSelectedViewTypeSelector = selectorFamily<
  ReportViewType | null,
  PersonasType
>({
  key: 'report-selected-view-type',
  get:
    (persona) =>
    ({ get }) => {
      const value = get(__INTERNAL_reportSelectedViewTypeSelector);
      if (!value) {
        return persona === 'preparer' ? 'source' : 'target';
      }
      return value ?? null;
    },
  set:
    () =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) {
        set(__INTERNAL_reportSelectedViewTypeSelector, null);
      } else {
        set(__INTERNAL_reportSelectedViewTypeSelector, newValue);
      }
    },
});

export const useReportViewTypeSelector = () => {
  const persona = usePersona();

  const [value, setViewType] = useRecoilState(
    reportSelectedViewTypeSelector(persona)
  );

  return [value, setViewType] as const;
};

export type ReportViewTypeSelectProps = {
  tsa?: boolean;
  onChange?: (viewType: ReportViewType) => void;
};

export const ReportViewTypeSelect: React.FC<ReportViewTypeSelectProps> = ({
  tsa = false,
  onChange,
}) => {
  const [value, setViewType] = useReportViewTypeSelector();
  const persona = usePersona();

  const personaViewTypes = ReportViewTypesByPersona[persona];
  const types =
    personaViewTypes instanceof Function
      ? (personaViewTypes(tsa) as ReportViewType[])
      : (personaViewTypes as ReportViewType[]);

  useEffect(() => {
    return () => {
      setViewType(null);
    };
  }, []);

  return (
    <>
      <InputLabel id='source-to-target-select-label'>Report View</InputLabel>
      <Select
        labelId='source-to-target-select-label'
        id='source-to-target-select'
        value={value ?? ''}
        label='Report View'
        onChange={(e: SelectChangeEvent<ReportViewType>) => {
          if (!types.find((type) => type === e.target.value)) {
            throw new Error('Invalid ReportViewType');
          }
          setViewType(e.target.value as ReportViewType);
          onChange?.(e.target.value as ReportViewType);
        }}
      >
        {types.map((viewType) => (
          <MenuItem key={viewType} value={viewType}>
            {ReportViewTypeLabels[viewType]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
