/**
 * @generated SignedSource<<533a1daec3c3bd55654ef8d698f12d10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type renew_batches_params = {
  submission_ids: ReadonlyArray<number>;
};
export type useRenewBatchesMutation$variables = {
  params: renew_batches_params;
};
export type useRenewBatchesMutation$data = {
  readonly _sys_renew_batches: {
    readonly submission_ids: ReadonlyArray<number>;
  };
};
export type useRenewBatchesMutation = {
  response: useRenewBatchesMutation$data;
  variables: useRenewBatchesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "renew_batches_output",
    "kind": "LinkedField",
    "name": "_sys_renew_batches",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRenewBatchesMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRenewBatchesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "17e9438cf031b3fd9e4a3bcaad9d14df",
    "id": null,
    "metadata": {},
    "name": "useRenewBatchesMutation",
    "operationKind": "mutation",
    "text": "mutation useRenewBatchesMutation(\n  $params: renew_batches_params!\n) {\n  _sys_renew_batches(params: $params) {\n    submission_ids\n  }\n}\n"
  }
};
})();

(node as any).hash = "d074ee3bc998582d21a59f0c134675ad";

export default node;
