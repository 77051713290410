/**
 * @generated SignedSource<<7b6d8d99ca645293b0fa3d4942226f90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getCurrentPreparersAndReviewersQuery$variables = {
  property_ids: ReadonlyArray<number>;
};
export type getCurrentPreparersAndReviewersQuery$data = {
  readonly sys_properties_flattened_union: ReadonlyArray<{
    readonly properties_roles_users: ReadonlyArray<{
      readonly property: {
        readonly entity_id: string;
      };
      readonly property_id: number;
      readonly property_role: {
        readonly property_role_set: string;
        readonly property_role_slug: string;
      };
      readonly property_role_id: number;
      readonly property_role_user_id: number;
      readonly submission_type: {
        readonly submission_type_slug: string;
      } | null;
      readonly submission_type_id: number | null;
      readonly user: {
        readonly sakura_user: {
          readonly email: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
        } | null;
      };
      readonly user_id: number;
    }>;
  }>;
};
export type getCurrentPreparersAndReviewersQuery = {
  response: getCurrentPreparersAndReviewersQuery$data;
  variables: getCurrentPreparersAndReviewersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_in",
    "variableName": "property_ids"
  }
],
v2 = {
  "_eq": true
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "fields": (v1/*: any*/),
                        "kind": "ObjectValue",
                        "name": "property_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "_or.0"
                  },
                  {
                    "fields": [
                      {
                        "fields": (v1/*: any*/),
                        "kind": "ObjectValue",
                        "name": "parent_property_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "_or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "_or"
              },
              {
                "kind": "Literal",
                "name": "is_active",
                "value": (v2/*: any*/)
              }
            ],
            "kind": "ObjectValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order_by",
            "value": [
              {
                "user": {
                  "sakura_user": {
                    "first_name": "asc"
                  }
                }
              },
              {
                "user": {
                  "sakura_user": {
                    "last_name": "asc"
                  }
                }
              }
            ]
          },
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "is_active": (v2/*: any*/)
            }
          }
        ],
        "concreteType": "sys_properties_roles_users",
        "kind": "LinkedField",
        "name": "properties_roles_users",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_role_user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_role_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_roles",
            "kind": "LinkedField",
            "name": "property_role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_role_slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_role_set",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sakura_user",
                "kind": "LinkedField",
                "name": "sakura_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submission_types",
            "kind": "LinkedField",
            "name": "submission_type",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_type_slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "properties_roles_users(order_by:[{\"user\":{\"sakura_user\":{\"first_name\":\"asc\"}}},{\"user\":{\"sakura_user\":{\"last_name\":\"asc\"}}}],where:{\"is_active\":{\"_eq\":true}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getCurrentPreparersAndReviewersQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getCurrentPreparersAndReviewersQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "aea09de2a8dc007ad839ace5fdfd3374",
    "id": null,
    "metadata": {},
    "name": "getCurrentPreparersAndReviewersQuery",
    "operationKind": "query",
    "text": "query getCurrentPreparersAndReviewersQuery(\n  $property_ids: [Int!]!\n) {\n  sys_properties_flattened_union(where: {_and: {is_active: {_eq: true}, _or: [{property_id: {_in: $property_ids}}, {parent_property_id: {_in: $property_ids}}]}}) {\n    properties_roles_users(where: {is_active: {_eq: true}}, order_by: [{user: {sakura_user: {first_name: asc}}}, {user: {sakura_user: {last_name: asc}}}]) {\n      property_role_user_id\n      user_id\n      property_id\n      property {\n        entity_id\n      }\n      property_role_id\n      property_role {\n        property_role_slug\n        property_role_set\n      }\n      user {\n        sakura_user {\n          first_name\n          email\n          last_name\n        }\n      }\n      submission_type_id\n      submission_type {\n        submission_type_slug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "167d1d7d42fbe2558694e7d5d45ed6d0";

export default node;
