/**
 * @generated SignedSource<<5f0b0cd783760463f89b6f575bcf477f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertiesUsedValuesQuery$variables = {
  id?: number | null;
};
export type getPropertiesUsedValuesQuery$data = {
  readonly address_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly fund_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly property_name_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly type_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getPropertiesUsedValuesQuery = {
  response: getPropertiesUsedValuesQuery$data;
  variables: getPropertiesUsedValuesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_eq",
          "variableName": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "provider_id"
    }
  ],
  "kind": "ObjectValue",
  "name": "_and.0"
},
v2 = {
  "_is_null": false
},
v3 = {
  "kind": "Literal",
  "name": "_and.2",
  "value": {
    "is_active": {
      "_eq": true
    }
  }
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_properties_flattened_union_aggregate_fields",
    "kind": "LinkedField",
    "name": "aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": "fund_is_used",
    "args": [
      {
        "fields": [
          {
            "items": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "fund": (v2/*: any*/)
                }
              },
              (v3/*: any*/)
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "property_name_is_used",
    "args": [
      {
        "fields": [
          {
            "items": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "property_name": (v2/*: any*/)
                }
              },
              (v3/*: any*/)
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "address_is_used",
    "args": [
      {
        "fields": [
          {
            "items": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "address": (v2/*: any*/)
                }
              },
              (v3/*: any*/)
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "type_is_used",
    "args": [
      {
        "fields": [
          {
            "items": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "type": (v2/*: any*/)
                }
              },
              (v3/*: any*/)
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertiesUsedValuesQuery",
    "selections": (v5/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertiesUsedValuesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "57413343584c96bc7d26061ad3edca85",
    "id": null,
    "metadata": {},
    "name": "getPropertiesUsedValuesQuery",
    "operationKind": "query",
    "text": "query getPropertiesUsedValuesQuery(\n  $id: Int\n) {\n  fund_is_used: sys_properties_flattened_union_aggregate(where: {_and: [{provider_id: {_eq: $id}}, {fund: {_is_null: false}}, {is_active: {_eq: true}}]}) {\n    aggregate {\n      count\n    }\n  }\n  property_name_is_used: sys_properties_flattened_union_aggregate(where: {_and: [{provider_id: {_eq: $id}}, {property_name: {_is_null: false}}, {is_active: {_eq: true}}]}) {\n    aggregate {\n      count\n    }\n  }\n  address_is_used: sys_properties_flattened_union_aggregate(where: {_and: [{provider_id: {_eq: $id}}, {address: {_is_null: false}}, {is_active: {_eq: true}}]}) {\n    aggregate {\n      count\n    }\n  }\n  type_is_used: sys_properties_flattened_union_aggregate(where: {_and: [{provider_id: {_eq: $id}}, {type: {_is_null: false}}, {is_active: {_eq: true}}]}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "47d6c2c354a19c3ab1d5e3ba5d419fcf";

export default node;
