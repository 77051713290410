import React, { useMemo } from 'react';
import {
  Table,
  useTable,
  MRT_ColumnDef,
  Box,
  IconButton,
  EditIcon,
  DeleteIcon,
} from '@cherre-frontend/ui';
import {
  useCherreState,
  useCherreValue,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import {
  getMappingSetValues,
  getMappingSetValuesTotal,
} from '../queries/getMappingSetValues';
import {
  QueryResult,
  Writeable,
} from '@cherre-frontend/data-fetching/src/recoil/graphQLSelector';
import {
  mappingDetailsTableState,
  addEditMappingDialogState,
  deleteMappingDialogState,
  addEditMappingFormState,
} from '../recoil';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { TableContainer } from '../styles';

export type MappingDetailsTableProps = {
  providerName: string;
  targetOrganization: string;
  customAttributes?: { slug: string; label: string }[];
};

const MappingDetailsTable: React.FC<MappingDetailsTableProps> = ({
  providerName,
  targetOrganization,
  customAttributes,
}) => {
  const [tableState, setTableState] = useCherreState(mappingDetailsTableState);

  const setAddEditMappingDialogState = useCherreSetState(
    addEditMappingDialogState
  );

  const setDeleteMappingDialogState = useCherreSetState(
    deleteMappingDialogState
  );

  const setAddEditMappingFormState = useCherreSetState(addEditMappingFormState);

  const data = useCherreValue(getMappingSetValues());
  const total = useCherreValue(getMappingSetValuesTotal());

  const columns: MRT_ColumnDef<
    QueryResult<typeof getMappingSetValues>[number]
  >[] = useMemo(
    () => [
      {
        header: 'Source Provider',
        Cell: () => {
          return <span>{providerName}</span>;
        },
      },
      {
        accessorKey: 'source_mapping_code',
        header: 'Source Code',
        size: 100,
      },
      {
        accessorKey: 'source_mapping_label',
        header: 'Source Label',
        size: 300,
      },
      {
        header: 'Target Organization',
        size: 0,
        maxSize: 40,
        Cell: () => {
          return <span>{targetOrganization}</span>;
        },
      },
      {
        accessorKey: 'target_mapping_code',
        header: 'Target Code',
        size: 100,
      },
      {
        accessorKey: 'target_mapping_label',
        header: 'Target Label',
        size: 300,
      },
      ...(customAttributes || []).map((attr) => ({
        id: attr.slug,
        accessorFn: (row) => row.custom_attributes?.[attr.slug],
        header: attr.label,
        enableSorting: false,
      })),
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowSelection: false,
    enableRowActions: true,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '& td:last-child': {
          paddingRight: '0px',
        },
      },
    },
    renderRowActions: ({ row }) => (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          onClick={() => {
            setAddEditMappingFormState((s) => ({
              ...s,
              id: row.original.mapping_set_value_id ?? '',
              source_code: row.original.source_mapping_code ?? '',
              source_description: row.original.source_mapping_label ?? '',
              target_code: row.original.target_mapping_code ?? '',
              target_description: row.original.target_mapping_label ?? '',
              custom_attributes:
                customAttributes?.map((attr) => ({
                  slug: attr.slug,
                  label: attr.label,
                  value:
                    (
                      row.original.custom_attributes as Writeable<
                        Record<string, string>
                      >
                    )?.[attr.slug] ?? '',
                })) ?? [],
            }));
            setAddEditMappingDialogState({ isOpen: true, type: 'Edit' });
          }}
        >
          <EditIcon color='primary' />
        </IconButton>
        <IconButton
          onClick={() =>
            setDeleteMappingDialogState({
              isOpen: true,
              mappingSetValueId: row.original.mapping_set_value_id,
            })
          }
        >
          <DeleteIcon color='primary' />
        </IconButton>
      </Box>
    ),
    state: {
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.mapping_set_value_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  if (data && data.length === 0) {
    return <NoItemsFound title='No Mapping Details Found' />;
  }

  return (
    <TableContainer>
      <Table table={table} />
    </TableContainer>
  );
};

export default MappingDetailsTable;
