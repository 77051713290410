import {
  Accordion,
  Grid,
  PackageReportCommentIcon,
  PropertiesIcon,
  Tooltip,
  Panel,
} from '@cherre-frontend/ui';
import { BulkActionsCheckbox } from './BulkActionsCheckbox';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { expandedCardState } from '../../PropertyBatches/recoil';

import { useSubmissionBulkActionsEnabled } from '../../PropertyBatches/hooks/useSubmissionBulkActionsEnabled';
import React, { ReactNode } from 'react';
import { usePropertyStatus } from '../hooks/usePropertyStatus';
import {
  AccordionDetails,
  AccordionHeader,
  AccordionSubtitle,
  AccordionSummary,
  Chip,
  batchIconStyle,
  batchIconWrapperStyle,
} from './styles';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export type GenericPropertyBatchBaseAccordionProps = {
  id: number;
  chipStatus: ReturnType<typeof usePropertyStatus>;
  hasComments: boolean;
  accordionHeaderComponent?: ReactNode;
  accordionSubtitleComponent?: ReactNode;
  accordionTopRightComponent?: ReactNode;
  children: ReactNode;
};

export const GenericPropertyBatchBaseAccordion: React.FC<
  GenericPropertyBatchBaseAccordionProps
> = ({
  id,
  hasComments,
  chipStatus,
  accordionHeaderComponent,
  accordionSubtitleComponent,
  accordionTopRightComponent,
  children,
}) => {
  const remindersFF = useFeatureFlag('DSPReminders');

  const submissionBulkActionsEnabled = useSubmissionBulkActionsEnabled();

  const [expanded, setExpanded] = useCherreState(expandedCardState(id));

  return (
    <Accordion
      expanded={expanded ?? false}
      onChange={(_event, expanded) => setExpanded(expanded)}
      summary={
        <AccordionSummary data-testid='property-batch-accordion-summary'>
          <Grid>
            <AccordionHeader>{accordionHeaderComponent}</AccordionHeader>
            <AccordionSubtitle variant='body1'>
              {accordionSubtitleComponent}
            </AccordionSubtitle>
          </Grid>
          <Grid display={'flex'} alignItems={'center'}>
            {accordionTopRightComponent}
            <Chip
              sx={{
                color: chipStatus.color.text,
                backgroundColor: chipStatus.color.bg,
              }}
              icon={chipStatus.icon}
              size='small'
              label={chipStatus.label}
            />
            <Tooltip
              title={
                hasComments
                  ? `Comments ${remindersFF ? 'or reminders ' : ''}available`
                  : `No comments ${remindersFF ? 'or reminders' : ''}`
              }
            >
              <div style={batchIconWrapperStyle}>
                <PackageReportCommentIcon
                  color={hasComments ? 'primary' : 'disabled'}
                  style={batchIconStyle}
                />
              </div>
            </Tooltip>
          </Grid>
        </AccordionSummary>
      }
      icon={
        submissionBulkActionsEnabled ? (
          <Panel id='bulk-actions-checkbox'>
            <BulkActionsCheckbox id={id} />
          </Panel>
        ) : (
          <PropertiesIcon />
        )
      }
    >
      <AccordionDetails data-testid='accordion-details'>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
