import React from 'react';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { MenuItem, TogglesIcon } from '@cherre-frontend/ui';
import { Property } from '../queries/getProperties';
import {
  assignMappingToPropertyState,
  mappingIdToAssignState,
} from 'src/products/data-submission-portal/components/AssignMappingDialog/localState';
import { propertiesTableSelectedProvider } from 'src/products/data-submission-portal/recoil/dialog';

type ManageMappingsActionMenuItemProps = {
  property: Property;
  openAssignMappingDialogModalWithTitles: (
    title: string,
    subtitle: string
  ) => void;
};
export const ManageMappingsActionMenuItem: React.FC<
  ManageMappingsActionMenuItemProps
> = ({ property, openAssignMappingDialogModalWithTitles }) => {
  const setSelectedProvider = useCherreSetState(
    propertiesTableSelectedProvider
  );
  const setMappingIdToAssignState = useCherreSetState(mappingIdToAssignState);
  const assignMappingToProperty = useCherreSetState(
    assignMappingToPropertyState
  );

  const onClick = () => {
    const currentMappingId = property.coa_mappings?.[0]?.mapping_set_id;
    setSelectedProvider(property.provider?.provider_id ?? null);
    setMappingIdToAssignState(currentMappingId);
    assignMappingToProperty(property);
    openAssignMappingDialogModalWithTitles(
      'Manage COA Mapping',
      `Entity ID ${property.entity_id} - ${property.entity_name}`
    );
  };

  return (
    <MenuItem onClick={onClick}>
      <TogglesIcon sx={{ height: 16 }} />
      Manage Mappings
    </MenuItem>
  );
};
