import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyDatasetsQuery } from './__generated__/getPropertyDatasetsQuery.graphql';

export const $getPropertyDatasets = graphQLSelector({
  query: graphql`
    query getPropertyDatasetsQuery($dataset_ids: [Int!]!, $property_id: Int!) {
      sys_properties_datasets(
        where: {
          dataset_id: { _in: $dataset_ids }
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
        }
      ) {
        dataset_id
        template_id
      }
    }
  ` as GraphQLReturn<getPropertyDatasetsQuery>,
  mapVariables:
    ({
      property_id,
      dataset_ids,
    }: {
      property_id: number;
      dataset_ids: number[];
    }) =>
    () => ({
      dataset_ids,
      property_id,
    }),
  mapResponse: (resp) => resp.sys_properties_datasets,
});
