import {
  type CallbackRecoilContext,
  type CherreCallbackInterface,
  type GraphQLReturn,
  useMutation,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  startingFinancialReviewerUsers,
  startingOperationalReviewerUsers,
  selectedPreparerUsers,
  startingPreparerUsers,
} from '../../../recoil/dialog';
import {
  SubmissionTypeSlugs,
  getSubmissionTypeReviewers,
} from '../../../pages/provider-detail/Panels/PropertiesTab/mutations/useAssignSingleChainReviewer';
import { useWarningBox } from '../hooks/useWarningBox';
import { getSubmissionTypes } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/queries';
import { managedPropertyState } from 'src/products/data-submission-portal/recoil/dialog';
import { useManageUsersMutation } from './__generated__/useManageUsersMutation.graphql';
import { useGetProviderId } from '../hooks/useGetProviderId';

const getPreparersData = async ({ getPromise }: CherreCallbackInterface) => {
  const startingPreparers = await getPromise(startingPreparerUsers);
  const selectedPreparers = await getPromise(selectedPreparerUsers);

  const selectedPreparersIds =
    selectedPreparers?.map((user) => user.user_id) ?? [];

  const deletedPreparerUsersRolesIds = startingPreparers
    .filter((user) => !selectedPreparersIds.includes(user.user_id))
    .map((user) => user.property_role_user_id as number);

  return { selectedPreparersIds, deletedPreparerUsersRolesIds };
};

const getReviewersData = async ({ getPromise }: CherreCallbackInterface) => {
  const submissionTypes = await getPromise(getSubmissionTypes());
  const getSubmissionType = (slug: SubmissionTypeSlugs) => {
    const submissionType = submissionTypes.find(
      (submissionType) => submissionType.submission_type_slug === slug
    );
    return submissionType?.submission_type_id ?? 0;
  };

  const selectorsMap = {
    [SubmissionTypeSlugs.FINANCIAL]: {
      startingReviewersSelector: startingFinancialReviewerUsers,
      selectedReviewersSelector: selectedFinancialReviewerUsers,
    },
    [SubmissionTypeSlugs.OPERATIONAL]: {
      startingReviewersSelector: startingOperationalReviewerUsers,
      selectedReviewersSelector: selectedOperationalReviewerUsers,
    },
  };

  const getReviewersDataBySubmissionType = async (
    submissionType: SubmissionTypeSlugs
  ) => {
    const { startingReviewersSelector, selectedReviewersSelector } =
      selectorsMap[submissionType];
    const startingReviewers = await getPromise(startingReviewersSelector);
    const selectedReviewers = getSubmissionTypeReviewers(
      getSubmissionType(submissionType),
      await getPromise(selectedReviewersSelector)
    );
    const selectedUserIds = selectedReviewers.map((user) => user.user_id);
    const deletedReviewersUsersRolesIds = startingReviewers
      .filter((user) => !selectedUserIds.includes(user.user_id))
      .map((user) => user.property_role_user_id as number);

    return { selectedReviewers, deletedReviewersUsersRolesIds };
  };

  const financialReviewersData = await getReviewersDataBySubmissionType(
    SubmissionTypeSlugs.FINANCIAL
  );
  const operationalReviewersData = await getReviewersDataBySubmissionType(
    SubmissionTypeSlugs.OPERATIONAL
  );

  return {
    selectedReviewers: [
      ...financialReviewersData.selectedReviewers,
      ...operationalReviewersData.selectedReviewers,
    ],
    deletedReviewersUsersRolesIds: [
      ...financialReviewersData.deletedReviewersUsersRolesIds,
      ...operationalReviewersData.deletedReviewersUsersRolesIds,
    ],
  };
};

const resetRecoilStates = (ctx: CallbackRecoilContext) => {
  ctx.recoil.set(selectedFinancialReviewerUsers, {
    reviewer1: [],
    reviewer2: [],
    reviewer3: [],
  });
  ctx.recoil.set(selectedOperationalReviewerUsers, {
    reviewer1: [],
    reviewer2: [],
    reviewer3: [],
  });
  ctx.recoil.set(startingFinancialReviewerUsers, []);
  ctx.recoil.set(startingOperationalReviewerUsers, []);
  ctx.recoil.set(selectedPreparerUsers, []);
  ctx.recoil.set(startingPreparerUsers, []);
  ctx.recoil.set(managedPropertyState, {
    entity_id: '',
    property_name: '',
    organization_id: 0,
    property_id: 0,
    property_mapping: null,
  });
};

export const useManageUsers = () => {
  const { clearWarningBoxState } = useWarningBox();
  const providerId = useGetProviderId();

  return useMutation(
    graphql`
      mutation useManageUsersMutation(
        $delete_ids: [Int!]!
        $preparers_input: _sys_property_assign_preparers_input!
        $reviewers_input: _sys_property_assign_reviewers_input!
      ) {
        delete_sys_properties_roles_users(
          where: { property_role_user_id: { _in: $delete_ids } }
        ) {
          affected_rows
        }
        _sys_property_assign_preparers(input: $preparers_input) {
          affected_rows
        }
        _sys_property_assign_reviewers(input: $reviewers_input) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useManageUsersMutation>,
    {
      mapVariables: () => async (callbacks) => {
        const { getPromise } = callbacks;
        const managedProperty = await getPromise(managedPropertyState);
        const property_ids = managedProperty?.property_id
          ? [managedProperty.property_id]
          : [];

        const { selectedPreparersIds, deletedPreparerUsersRolesIds } =
          await getPreparersData(callbacks);

        const { selectedReviewers, deletedReviewersUsersRolesIds } =
          await getReviewersData(callbacks);

        return {
          preparers_input: {
            property_ids,
            user_ids: selectedPreparersIds,
            provider_id: providerId,
          },
          reviewers_input: {
            property_ids,
            reviewers: selectedReviewers,
            provider_id: providerId,
          },
          delete_ids: [
            ...deletedReviewersUsersRolesIds,
            ...deletedPreparerUsersRolesIds,
          ],
        };
      },
      onError: (result, ctx) => {
        ctx.showSnackbar({
          type: 'error',
          message: `Error: ${result}`,
        });
        resetRecoilStates(ctx);
        clearWarningBoxState();
      },
      onCompleted: async (result, ctx) => {
        const managedProperty = await ctx.recoil.getPromise(
          managedPropertyState
        );

        ctx.showSnackbar({
          type: 'success',
          message: `Users for Entity ID ${managedProperty?.entity_id} have been successfully edited.`,
        });
        resetRecoilStates(ctx);
        clearWarningBoxState();
      },
    }
  );
};
