import React, { useEffect, useState } from 'react';
import {
  useCherreEventWithRecoil,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Divider,
  Dialog,
  Grid,
  Panel,
  Typography,
  Button,
  Tab,
  styled,
  MRT_RowSelectionState,
} from '@cherre-frontend/ui';
import { useCurrentPreparersAndReviewers } from '../hooks/useCurrentPreparersAndReviewers';
import {
  managedPropertyState,
  managedPropertyStateIsManyToOne,
  selectedPreparerUsers,
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  startingPreparerUsers,
  startingFinancialReviewerUsers,
  startingOperationalReviewerUsers,
  userCheckerType,
} from '../../../recoil/dialog';
import ManageUsersPreparerTab from './components/ManageUsersPreparerTab';
import ManageUsersReviewerTab from './components/ManageUsersReviewerTab';
import WarningBox from '../components/WarningBox';
import { DialogPanel, HeaderGrid, ManageUsersTabs } from '../styles';
import { useManageUsers } from '../mutations/useManageUsers';
import { useWarningBox } from '../hooks/useWarningBox';
import { groupBy } from 'lodash';
import { useCheckApproverPresent } from '../hooks/useCheckApproverPresent';
import { isReviewerLikePropertyRoleSet } from 'src/products/data-submission-portal/consts';

export enum ManageUsersTabValues {
  PREPARERS = 'Preparers',
  REVIEWERS = 'Reviewers',
}

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    border-radius: 10px;
    box-shadow: none;
    min-width: 520px;
  }
`;

type Props = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
};

const getManyToOneMessage = (tab: ManageUsersTabValues) => {
  const subject =
    tab === ManageUsersTabValues.PREPARERS ? 'preparer' : 'reviewer';
  return `Note: Assigning a ${subject} to a M:1 property group will assign that ${subject} to all properties within the associated group(s).`;
};

const ManageUsersDialog: React.FC<Props> = ({ setRowSelection }) => {
  const manageUsersMutation = useManageUsers();
  const [tab, setTab] = useState<ManageUsersTabValues>(
    ManageUsersTabValues.PREPARERS
  );
  const setStartingPreparerUsers = useCherreSetState(startingPreparerUsers);

  const setSelectedUsers = useCherreSetState(selectedPreparerUsers);

  const setStartingFinancialUsers = useCherreSetState(
    startingFinancialReviewerUsers
  );

  const setStartingOperationalUsers = useCherreSetState(
    startingOperationalReviewerUsers
  );

  const [selectedFinancialReviewers, setSelectedFinancialReviewers] =
    useCherreState(selectedFinancialReviewerUsers);

  const [selectedOperationalReviewers, setSelectedOperationalReviewers] =
    useCherreState(selectedOperationalReviewerUsers);

  const [managedProperty, setManagedPropertyState] =
    useCherreState(managedPropertyState);
  const shouldDisplayManyToOneMessage = useCherreValue(
    managedPropertyStateIsManyToOne
  );

  const currentPreparersAndReviewers = useCurrentPreparersAndReviewers(
    !!managedProperty?.property_id
  );

  // Syncronize data returned from graphql to the form recoil state
  useEffect(() => {
    if (
      !currentPreparersAndReviewers ||
      currentPreparersAndReviewers.length === 0
    ) {
      return;
    }

    const preparers = currentPreparersAndReviewers.filter(
      (user) => user.property_role.property_role_slug === 'preparer'
    );

    const startingPreparers = preparers.map(
      (preparer) =>
        ({
          property_role_user_id: preparer.property_role_user_id,
          user_id: preparer.user_id,
          sakura_user: {
            first_name: preparer.user.sakura_user?.first_name,
            last_name: preparer.user.sakura_user?.last_name,
            email: preparer.user.sakura_user?.email,
          },
        } as userCheckerType)
    );

    setStartingPreparerUsers(startingPreparers ?? []);
    setSelectedUsers(startingPreparers ?? []);

    const reviewers = currentPreparersAndReviewers.filter((u) =>
      isReviewerLikePropertyRoleSet(u.property_role.property_role_set)
    );

    const reviewersBySubmissionType = groupBy(
      reviewers,
      'submission_type.submission_type_slug'
    );

    const transformReviewerGroups = (
      reviewers: typeof currentPreparersAndReviewers
    ) => {
      const reviewersByPropertyRoleSlug = groupBy(
        reviewers,
        'property_role.property_role_slug'
      );

      const transformReviewer = (
        reviewer: typeof currentPreparersAndReviewers
      ) =>
        reviewer.map(
          (reviewer) =>
            ({
              property_role_id: reviewer.property_role_id,
              property_role_user_id: reviewer.property_role_user_id,
              user_id: reviewer.user_id,
              sakura_user: {
                first_name: reviewer.user.sakura_user?.first_name,
                last_name: reviewer.user.sakura_user?.last_name,
                email: reviewer.user.sakura_user?.email,
              },
            } as userCheckerType)
        );

      const sortByUserFullName = (
        a: (typeof reviewers)[number],
        b: (typeof reviewers)[number]
      ) => {
        const fullNameA = `${a.user.sakura_user?.first_name} ${a.user.sakura_user?.last_name}`;
        const fullNameB = `${b.user.sakura_user?.first_name} ${b.user.sakura_user?.last_name}`;
        return fullNameA.localeCompare(fullNameB);
      };

      return {
        reviewer1: transformReviewer(
          [
            ...(reviewersByPropertyRoleSlug['reviewer_1'] ?? []),
            ...(reviewersByPropertyRoleSlug['approver_plus_1'] ?? []),
            ...(reviewersByPropertyRoleSlug['view_only_1'] ?? []),
          ].sort(sortByUserFullName)
        ),
        reviewer2: transformReviewer(
          [
            ...(reviewersByPropertyRoleSlug['reviewer_2'] ?? []),
            ...(reviewersByPropertyRoleSlug['approver_plus_2'] ?? []),
            ...(reviewersByPropertyRoleSlug['view_only_2'] ?? []),
          ].sort(sortByUserFullName)
        ),
        reviewer3: transformReviewer(
          [
            ...(reviewersByPropertyRoleSlug['reviewer_3'] ?? []),
            ...(reviewersByPropertyRoleSlug['approver_plus_3'] ?? []),
            ...(reviewersByPropertyRoleSlug['view_only_3'] ?? []),
          ].sort(sortByUserFullName)
        ),
      };
    };

    const reviewersOperational = transformReviewerGroups(
      reviewersBySubmissionType['operational'] ?? []
    );

    const reviewersFinancial = transformReviewerGroups(
      reviewersBySubmissionType['financial'] ?? []
    );

    setStartingFinancialUsers([
      ...reviewersFinancial.reviewer1,
      ...reviewersFinancial.reviewer2,
      ...reviewersFinancial.reviewer3,
    ]);

    setStartingOperationalUsers([
      ...reviewersOperational.reviewer1,
      ...reviewersOperational.reviewer2,
      ...reviewersOperational.reviewer3,
    ]);

    setSelectedFinancialReviewers(reviewersFinancial);
    setSelectedOperationalReviewers(reviewersOperational);
  }, [currentPreparersAndReviewers]);

  const {
    warningBoxState,
    clearWarningBoxState,
    check: checkApproverPresent,
  } = useCheckApproverPresent([
    selectedFinancialReviewers,
    selectedOperationalReviewers,
  ]);

  return (
    <>
      <HeaderGrid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        padding='20px 20px 0px 20px'
      >
        <Typography variant='h5' style={{ fontWeight: 600 }}>
          Manage Users
        </Typography>
      </HeaderGrid>
      {managedProperty && (
        <Typography
          variant='body1'
          style={{ marginBottom: '8px' }}
          padding='0px 20px 0px 20px'
        >
          {`Entity ID ${managedProperty.entity_id}${
            managedProperty.property_name
              ? ` - ${managedProperty.property_name}`
              : ''
          }`}
        </Typography>
      )}
      <ManageUsersTabs
        indicatorColor='primary'
        textColor='primary'
        variant='standard'
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
      >
        <Tab
          value={ManageUsersTabValues.PREPARERS}
          label={ManageUsersTabValues.PREPARERS}
          style={{ minWidth: 'unset' }}
        />
        <Tab
          value={ManageUsersTabValues.REVIEWERS}
          label={ManageUsersTabValues.REVIEWERS}
          style={{ minWidth: 'unset' }}
        />
      </ManageUsersTabs>
      <Divider style={{ borderWidth: '1px', width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          marginTop: '12px',
          height: tab === ManageUsersTabValues.REVIEWERS ? '550px' : 'unset',
          overflow: 'auto',
          padding: '0px 20px 0px 20px',
        }}
      >
        {shouldDisplayManyToOneMessage && (
          <Typography variant='body2' marginBottom='20px'>
            {getManyToOneMessage(tab)}
          </Typography>
        )}
        <WarningBox {...warningBoxState} />
        {tab === ManageUsersTabValues.PREPARERS && (
          <Panel id='manage-users-preparers-tab'>
            <ManageUsersPreparerTab />
          </Panel>
        )}
        {tab === ManageUsersTabValues.REVIEWERS && <ManageUsersReviewerTab />}
      </Box>
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        gap={2}
        style={{ marginTop: '16px' }}
        padding='0px 20px 20px 20px'
      >
        <Grid item>
          <Button
            variant='text'
            style={{ width: '90px', padding: '6px 8px' }}
            onClick={() => {
              setSelectedFinancialReviewers({
                reviewer1: [],
                reviewer2: [],
                reviewer3: [],
              });
              setSelectedOperationalReviewers({
                reviewer1: [],
                reviewer2: [],
                reviewer3: [],
              });
              setSelectedUsers([]);
              clearWarningBoxState();
              setStartingFinancialUsers([]);
              setStartingOperationalUsers([]);
              setManagedPropertyState({
                entity_id: '',
                property_name: '',
                organization_id: 0,
                property_id: null,
                property_mapping: null,
              });
              setRowSelection({});
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            style={{ width: '90px', padding: '6px 8px' }}
            onClick={() => {
              if (checkApproverPresent()) {
                manageUsersMutation();
              }
            }}
            disabled={warningBoxState?.type ? true : false}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const ManageUsersDialogContainer: React.FC<Props> = ({ setRowSelection }) => {
  const managedProperty = useCherreValue(managedPropertyState);

  const { clearWarningBoxState } = useWarningBox();

  const onClose = useCherreEventWithRecoil(
    'user closed assign preparers dialog',
    (ctx) => () => {
      setRowSelection({});
      ctx.recoil.set(selectedPreparerUsers, []);
      ctx.recoil.set(startingPreparerUsers, []);
      clearWarningBoxState();
      ctx.recoil.set(managedPropertyState, {
        entity_id: '',
        property_name: '',
        organization_id: 0,
        property_id: null,
        property_mapping: null,
      });
    }
  );

  return (
    <DialogPanel
      id='ManageUsersDialog'
      config={{ logLevel: false }}
      style={{ display: 'flex', flexGrow: 1 }}
    >
      <Container open={Boolean(managedProperty!.property_id)} onClose={onClose}>
        <ManageUsersDialog setRowSelection={setRowSelection} />
      </Container>
    </DialogPanel>
  );
};

export default ManageUsersDialogContainer;
