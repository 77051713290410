import { useCherreState } from '@cherre-frontend/data-fetching';
import { Button, Grid } from '@cherre-frontend/ui';
import React from 'react';
import { UnwrapRecoilValue } from 'recoil';
import { monitorSubmissionsFiltersState } from '../../recoil';
import { MultiFilter } from './components/MultiFilter';
import { FILTERS } from '../../consts';
import { SingleFilter } from './components/SingleFilter';

type FilterStateType = UnwrapRecoilValue<typeof monitorSubmissionsFiltersState>;

export const noFiltersApplied = (filterState?: FilterStateType) => {
  if (!filterState) {
    return true;
  }
  return !FILTERS.some((f) => filterState[f].length > 0);
};

const MonitorSubmissionsFilters: React.FC = () => {
  const [filterState, setFilterState] = useCherreState(
    monitorSubmissionsFiltersState
  );

  const disabledClearAll = noFiltersApplied(filterState);

  return (
    <Grid display='flex' gap={2} flexWrap={'wrap'}>
      <MultiFilter label='Type' />
      <MultiFilter label='Fund' />
      <MultiFilter label='Status' />
      <SingleFilter label='Interval' />
      <SingleFilter label='Period' />
      <MultiFilter label='Provider' />
      <SingleFilter label='Validation' />
      <MultiFilter label='Stage' />
      <MultiFilter label='Batch' />
      <Button
        onClick={() =>
          setFilterState({
            Type: [],
            Fund: [],
            Status: [],
            Interval: '',
            Period: '',
            Provider: [],
            Validation: '',
            Stage: [],
            Batch: [],
          })
        }
        variant='text'
        color='primary'
        disabled={disabledClearAll}
      >
        Clear all
      </Button>
    </Grid>
  );
};

MonitorSubmissionsFilters.displayName = 'MonitorSubmissionsFilters';

export default MonitorSubmissionsFilters;
