/**
 * @generated SignedSource<<a1f173e14c8ceb1ef4caa6908a88f19a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilGetPackageReportCommentsQuery$variables = {
  package_report_id: number;
  property_batch_id: number;
};
export type recoilGetPackageReportCommentsQuery$data = {
  readonly sys_comments: ReadonlyArray<{
    readonly comment_id: number;
    readonly comment_type_id: number;
    readonly created_at_datetime: string;
    readonly text: string;
    readonly user: {
      readonly sakura_user: {
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
};
export type recoilGetPackageReportCommentsQuery = {
  response: recoilGetPackageReportCommentsQuery$data;
  variables: recoilGetPackageReportCommentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "package_report_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "package_report_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "package_report_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_comments",
    "kind": "LinkedField",
    "name": "sys_comments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sakura_user",
            "kind": "LinkedField",
            "name": "sakura_user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment_type_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetPackageReportCommentsQuery",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetPackageReportCommentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72fb223642f73728eaf23530747a730a",
    "id": null,
    "metadata": {},
    "name": "recoilGetPackageReportCommentsQuery",
    "operationKind": "subscription",
    "text": "subscription recoilGetPackageReportCommentsQuery(\n  $package_report_id: Int!\n  $property_batch_id: Int!\n) {\n  sys_comments(where: {package_report_id: {_eq: $package_report_id}, property_batch_id: {_eq: $property_batch_id}}) {\n    user {\n      sakura_user {\n        first_name\n        last_name\n      }\n    }\n    comment_id\n    comment_type_id\n    created_at_datetime\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "11f12adc54970dcba38ad851781fd1bf";

export default node;
