import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadAccountGroupingsMutation } from './__generated__/useUploadAccountGroupingsMutation.graphql';

export const useUploadAccountGroupings = (
  input: {
    account_grouping_name: string;
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadAccountGroupingsMutation(
        $input: SysUploadAccountGroupingInput!
      ) {
        _sys_upload_account_grouping(input: $input) {
          account_grouping_id
        }
      }
    ` as GraphQLReturn<useUploadAccountGroupingsMutation>,
    {
      trackEvent: false,
      mapVariables: (file_reference_source: string) => async () => {
        return {
          input: {
            file_reference_source: file_reference_source,
            account_grouping_name: input.account_grouping_name,
            file_source: input.file_source,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
