import { useCherreState } from '@cherre-frontend/data-fetching';
import { warningBoxState } from 'src/products/data-submission-portal/recoil/dialog';

export const useWarningBox = () => {
  const [currentWarningBoxState, setWarningBoxState] =
    useCherreState(warningBoxState);

  const triggerReviewerCannotBeAssigned = (
    user_name: string,
    set_as: string,
    entity_ids: string[]
  ) => {
    setWarningBoxState({
      type: 'error',
      header: 'User Cannot Be Assigned',
      subtitle: `${user_name} is already assigned as a ${set_as} on Entity ID ${entity_ids.join(
        ', '
      )}. A user cannot be a Preparer and Reviewer on the same Entity ID`,
    });
  };

  const triggerReviewerCannotBeAssignedSameEntity = (
    user_name: string,
    set_as: string
  ) => {
    setWarningBoxState({
      type: 'error',
      header: 'User Cannot Be Assigned',
      subtitle: `${user_name} is already assigned as a ${set_as} on this Entity ID. A user cannot be a Preparer and Reviewer on the same Entity ID`,
    });
  };

  const triggerPreparerAlreadyAssigned = (
    type: 'warning' | 'error',
    user_name: string,
    set_as: string,
    entity_ids: string[]
  ) => {
    setWarningBoxState({
      type,
      header: `User Already Assigned as a ${set_as}`,
      subtitle: `${user_name} is already assigned as a ${set_as} on Entity ID ${entity_ids.join(
        ', '
      )}. The user will be assigned to any entities where they are not a Preparer`,
    });
  };

  const triggerPreparerAlreadyAssignedSameEntity = (
    type: 'warning' | 'error',
    user_name: string,
    set_as: string
  ) => {
    setWarningBoxState({
      type,
      header: `User Already Assigned as a ${set_as}`,
      subtitle: `${user_name} is already assigned as a ${set_as} on this Entity ID.`,
    });
  };

  const triggerMissingReviewerPreviousLevel = () => {
    setWarningBoxState({
      type: 'error',
      header: `Reviewer Required On Prior Review Level`,
      subtitle: `A preceding review level (i.e. First reviewer) must always have a Reviewer assigned in order for the subsequent levels (i.e. Second reviewer) to contain Reviewers.`,
    });
  };

  const triggerApproverRequired = () => {
    setWarningBoxState({
      type: 'error',
      header: `Approver User Required`,
      subtitle: `Each level of review that contains a Viewer must also contain an Approver to advance the submission. Please add an Approver to proceed.`,
    });
  };

  const clearWarningBoxState = () => {
    setWarningBoxState({
      type: undefined,
      header: undefined,
      subtitle: undefined,
    });
  };

  return {
    warningBoxState: currentWarningBoxState,
    triggerApproverRequired,
    triggerMissingReviewerPreviousLevel,
    triggerReviewerCannotBeAssignedSameEntity,
    triggerReviewerCannotBeAssigned,
    triggerPreparerAlreadyAssignedSameEntity,
    triggerPreparerAlreadyAssigned,
    clearWarningBoxState,
  };
};
