/**
 * @generated SignedSource<<d214c433eb4ff72c20595ca45f33b424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyTypeCustomAttributesQuery$variables = {};
export type getPropertyTypeCustomAttributesQuery$data = {
  readonly sys_property_type_custom_attributes: ReadonlyArray<{
    readonly custom_attributes: any;
  }>;
};
export type getPropertyTypeCustomAttributesQuery = {
  response: getPropertyTypeCustomAttributesQuery$data;
  variables: getPropertyTypeCustomAttributesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_property_type_custom_attributes",
    "kind": "LinkedField",
    "name": "sys_property_type_custom_attributes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "custom_attributes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyTypeCustomAttributesQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getPropertyTypeCustomAttributesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0a0b8dcf7047d50ffe7d316a9337b584",
    "id": null,
    "metadata": {},
    "name": "getPropertyTypeCustomAttributesQuery",
    "operationKind": "query",
    "text": "query getPropertyTypeCustomAttributesQuery {\n  sys_property_type_custom_attributes {\n    custom_attributes\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d95259a882c8ab67a90bf4b029034b3";

export default node;
