import React, { useMemo } from 'react';
import { Writeable, useCherreValue } from '@cherre-frontend/data-fetching';
import { SubmissionBatchItem } from '../../PropertyBatches/recoil';
import { Button } from '@cherre-frontend/ui';
import { isInPreparation } from '../utils';
import { $packageReportState } from 'src/products/data-submission-portal/components/PackageReportsTable/recoil';
import { usePersona } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { getWorkflowAvailableActions } from '../recoil';
import { WORKFLOW } from '../consts';
import { AlertWithCta } from '../AlertWithCta';

export interface PropertyBatchPreparerAlertsProps {
  propertyBatch: Writeable<SubmissionBatchItem['property_batches'][number]>;
  onClickRunSubmissionValidation: () => void;
  onClickReviewPackageReports: () => void;
  onClickViewSubmissionValidationReport: () => void;
  onClickSubmitProperty: () => void;
}

export const PropertyBatchPreparerAlerts: React.FC<
  PropertyBatchPreparerAlertsProps
> = ({
  propertyBatch,
  onClickRunSubmissionValidation,
  onClickReviewPackageReports,
  onClickViewSubmissionValidationReport,
  onClickSubmitProperty,
}) => {
  const persona = usePersona();
  const state = useCherreValue(
    $packageReportState({
      persona,
      property_batch_id: propertyBatch.property_batch_id,
    })
  );
  const workflowAvailableActions = useCherreValue(
    getWorkflowAvailableActions(propertyBatch.property_batch_id)
  );
  const showSubmitPropertyAlert = workflowAvailableActions?.includes(
    WORKFLOW.PREPARER_SUBMIT
  );

  const hasPackageReportRejected = state?.some(
    (pr) => pr.is_reviewed === false
  );
  const propertyBatchDatasets = useMemo(
    () =>
      [...propertyBatch.property_batch_datasets].sort((a, b) => {
        if (a.validated_datetime === null) {
          return 1;
        }
        if (b.validated_datetime === null) {
          return -1;
        }

        return (
          new Date(b.validated_datetime).getTime() -
          new Date(a.validated_datetime).getTime()
        );
      }),
    [propertyBatch.property_batch_datasets]
  );

  const latestDataset = propertyBatchDatasets?.[0];

  if (
    !latestDataset?.batch_file ||
    !isInPreparation(propertyBatch.property_batch_stage.property_batch_stage_id)
  ) {
    return null;
  }

  const someHasErrorOrNotUploaded = propertyBatchDatasets.some(
    (a) => a.batch_file === null || a.is_valid === null || a.is_valid === false
  );

  const someHasWarning = propertyBatchDatasets.some(
    (a) => a.warning_count.aggregate?.count !== 0
  );

  const hasCrossFileValidations =
    propertyBatch.property_batch_validations.length !== 0;

  const someCrossFileRan = propertyBatch.is_valid !== null;

  if (hasPackageReportRejected) {
    return (
      <AlertWithCta
        testId='property-batch-preparer-alerts-rejected'
        title='Package Report Rejected'
        severity='error'
      >
        Please review the rejected reports and replace any corresponding
        submission files.
      </AlertWithCta>
    );
  }

  if (showSubmitPropertyAlert) {
    return (
      <AlertWithCta
        title='Package Report Review Complete'
        severity='success'
        testId='property-batch-preparer-alerts-package-reports-reviewed'
        cta={
          <Button
            variant={'contained'}
            color='primary'
            onClick={onClickSubmitProperty}
          >
            Submit Property
          </Button>
        }
      >
        This property can be submitted for review.
      </AlertWithCta>
    );
  }

  const reviewPackageReportsButton = (
    <Button
      variant={'contained'}
      color='primary'
      onClick={onClickReviewPackageReports}
    >
      Review Package Reports
    </Button>
  );

  const runValidationButton = (
    <Button
      variant={'contained'}
      color='primary'
      onClick={onClickRunSubmissionValidation}
    >
      Run Validation
    </Button>
  );

  // crossfile validation alerts
  if (someCrossFileRan) {
    const allCrossFileValidationsPassed =
      propertyBatch.property_batch_validations.every(
        (validation) => validation.is_valid
      );

    const errorFilter = (validation) =>
      validation.is_valid === false && validation.validation_mode === 'hard';

    const someCrossFileValidationHasError =
      propertyBatch.property_batch_validations.some(errorFilter);

    return (
      <>
        {allCrossFileValidationsPassed && (
          <AlertWithCta
            title='Cross-Dataset Validation Complete'
            severity='success'
            testId='property-batch-preparer-alerts-crossfile-validation-complete'
            cta={reviewPackageReportsButton}
          >
            All cross-dataset validations passed.
          </AlertWithCta>
        )}
        {!allCrossFileValidationsPassed &&
          (someCrossFileValidationHasError ? (
            // has hard errors
            <AlertWithCta
              title='Cross-Dataset Validation Failed'
              severity='error'
              testId='property-batch-preparer-alerts-crossfile-validation-failed'
              cta={
                <Button
                  variant={'contained'}
                  color='primary'
                  onClick={onClickViewSubmissionValidationReport}
                >
                  View Submission Validation Report
                </Button>
              }
            >
              {
                propertyBatch.property_batch_validations.filter(errorFilter)
                  .length
              }{' '}
              out of {propertyBatch.property_batch_validations.length}{' '}
              cross-dataset validations failed.
            </AlertWithCta>
          ) : (
            // has warnings
            <AlertWithCta
              title='Cross-Dataset Validation Complete'
              severity='warning'
              testId='property-batch-preparer-alerts-crossfile-validation-complete'
              cta={reviewPackageReportsButton}
            >
              Please review warning failures prior to property submission.
            </AlertWithCta>
          ))}
      </>
    );
  }

  // dataset validation alerts
  return (
    <>
      {latestDataset.is_valid === false && (
        <AlertWithCta
          title='Dataset Validation Failed'
          severity={'error'}
          testId={'property-batch-preparer-alerts-validation-failed'}
        >
          {latestDataset.dataset?.dataset_label} failed{' '}
          {latestDataset.error_count.aggregate?.count} validation rules.
        </AlertWithCta>
      )}
      {latestDataset.is_valid === true && (
        <>
          {someHasErrorOrNotUploaded ? (
            <AlertWithCta
              title='Dataset Validation Passed'
              severity='success'
              testId='property-batch-preparer-alerts-validation-passed-some-error'
            >
              {latestDataset.dataset?.dataset_label} passed{' '}
              {latestDataset.valid_count.aggregate?.count} validation rules.
            </AlertWithCta>
          ) : (
            //   all dataset uploaded without errors
            <AlertWithCta
              title='Dataset Validation Complete'
              severity={someHasWarning ? 'warning' : 'success'}
              testId='property-batch-preparer-alerts-validation-complete'
              cta={
                hasCrossFileValidations
                  ? runValidationButton
                  : reviewPackageReportsButton
              }
            >
              {someHasWarning
                ? 'Please review warning failures prior to property submission'
                : 'All dataset level validations passed'}
            </AlertWithCta>
          )}
        </>
      )}
    </>
  );
};
