import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getOpenedBatchesWithMappingsQuery } from './__generated__/getOpenedBatchesWithMappingsQuery.graphql';

export const getOpenedBatchesWithMappings = graphQLSelector({
  query: graphql`
    query getOpenedBatchesWithMappingsQuery($mapping_set_id: Int!) {
      sys_submissions(
        where: {
          is_closed: { _eq: false }
          property_batches: {
            property: {
              is_active: { _eq: true }
              properties_mapping_sets: {
                is_active: { _eq: true }
                mapping_set_id: { _eq: $mapping_set_id }
              }
            }
          }
        }
        order_by: { submission_name: asc }
      ) {
        submission_id
        submission_name
      }
    }
  ` as GraphQLReturn<getOpenedBatchesWithMappingsQuery>,
  mapResponse: (response) => response.sys_submissions,
  mapVariables: (mapping_set_id: number) => () => ({ mapping_set_id }),
});
