import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { Box, Button, styled } from '@cherre-frontend/ui';
import React from 'react';
import UserRowSkeleton from './UserRowSkeleton';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  height: 100%;
`;

const UserList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  height: 200px;
  overflow-y: auto;
  align-items: flex-start;
  flex-grow: 1;
`;

type SubmissionUsersTabProps = {
  ctaTitle: string;
  ctaLink: string;
  userListComponent: React.ReactNode;
  hideCta?: boolean;
};

export const SubmissionUsersBaseTab: React.FC<SubmissionUsersTabProps> = ({
  ctaTitle,
  ctaLink,
  userListComponent,
  hideCta,
}) => {
  const isSuspeneded = useIsSuspended();

  if (isSuspeneded) {
    return (
      <Container>
        <UserList>
          {Array(2)
            .fill(null)
            .map((_, idx) => (
              <UserRowSkeleton key={idx} />
            ))}
        </UserList>
      </Container>
    );
  }

  return (
    <Container>
      <UserList>{userListComponent}</UserList>

      {!hideCta && (
        <Box>
          <Button href={ctaLink} variant='contained'>
            {ctaTitle}
          </Button>
        </Box>
      )}
    </Container>
  );
};
