import { Chip, styled, tableBaseStyle } from '@cherre-frontend/ui';
import {
  DatasetStatusLabel,
  DatasetStatus,
} from '../../__generated__/constants';
import { themeV5 } from 'src/const/themev5';

export const muiTableHeadCellProps = (props) => ({
  sx: {
    ...tableBaseStyle.muiTableHeadCellProps(props).sx,
    borderBottom: '1px solid var(--gray-200, #E4E4E4)',
  },
});

export const muiTableBodyCellProps = {
  sx: {
    ...tableBaseStyle.muiTableBodyCellProps.sx,
    height: '42px',
  },
};

export const disableColumnGrow = {
  muiTableHeadCellProps: (props) => ({
    sx: {
      ...muiTableHeadCellProps(props).sx,
      flex: '0 0 auto',
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      ...muiTableBodyCellProps.sx,
      flex: '0 0 auto',
    },
  },
};

export const actionsColumnStyle: React.CSSProperties = {
  display: 'flex',
  gap: 2,
  justifyContent: 'flex-end',
  width: '100%',
};

export const reportIconButtonStyle: React.CSSProperties = {
  width: 30,
  paddingLeft: 6,
};

export const reportIconStyle: React.CSSProperties = { fontSize: 20 };

const chipColorMap = new Map<DatasetStatusLabel, string[]>([
  [DatasetStatus.VALIDATED.label, [themeV5.accents.positive.main]],
  [DatasetStatus.VALIDATION_FAILED.label, [themeV5.accents.error.main]],
  [DatasetStatus.UPLOAD_FAILED.label, [themeV5.accents.error.main]],
  [
    DatasetStatus.VALIDATING.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    DatasetStatus.UPLOADING.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    DatasetStatus.UPLOADED.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
]);

export const ChipStyled = styled(Chip)<{ validation: DatasetStatusLabel }>`
  background: ${({ validation }) => chipColorMap.get(validation)?.[0]};
  color: ${({ validation, theme }) =>
    chipColorMap.get(validation)?.[1] ?? theme.palette.grey[50]};
  .MuiChip-icon {
    color: inherit;
  }
`;
