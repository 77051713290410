import {
  useCherreState,
  useCherreValue,
  useIsSuspended,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  SendIcon,
  Skeleton,
  TextField,
  Tooltip,
  styled,
} from '@cherre-frontend/ui';
import React, { useEffect, useRef } from 'react';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { $packageReports, ModalState } from '../recoil';
import { Comment } from './Comment';
import NoContentMessage from './NoContentMessage';
import {
  getPackageReportComments,
  packageReportSidebarCommentState,
  packageReportSidebarCreateCommentMutation,
} from './recoil';

const Container = styled(Box)`
  width: 100%;
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
`;

const CommentList = styled(Box)`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const SubmitButton = styled(SendIcon)`
  cursor: pointer;
  align-self: flex-end;
`;

export type CommentsTabProps = {
  modalState: ModalState;
};

export const CommentsTab: React.FC<CommentsTabProps> = ({ modalState }) => {
  const endRef = useRef<HTMLDivElement | null>(null);
  const isSuspeneded = useIsSuspended();
  const textFieldRef = useRef<HTMLDivElement | null>(null);

  const modalStateInfo = useCherreValue(modalState);
  const packageReports = useCherreValue(
    $packageReports(modalStateInfo?.property_batch_id)
  );

  const report = packageReports?.find(
    (r) => r.slug === modalStateInfo?.report_slug
  );

  const comments = useCherreValue(
    getPackageReportComments({
      package_report_id: report!.id,
      property_batch_id: modalStateInfo!.property_batch_id,
    })
  );

  const [comment, setComment] = useCherreState(
    packageReportSidebarCommentState(modalStateInfo?.report_slug)
  );

  const createCommentMutation = useMutation(
    packageReportSidebarCreateCommentMutation,
    {
      mapVariables:
        () =>
        async ({ getPromise }) => {
          if (!report) {
            throw new Error('no report');
          }

          return {
            text: comment,
            author_id: (await getPromise(getDSPUser())).user_id,
            package_report_id: report.id,
            property_batch_id: modalStateInfo?.property_batch_id,
            comment_type_id: 1,
          };
        },
      onCompleted: (_, ctx) => {
        setComment('');
        ctx.showSnackbar({
          message: 'Comment has been added',
          type: 'success',
        });
      },
      onError: (error, ctx) => {
        console.error(error);
        ctx.showSnackbar({
          message: 'Comment failed to post. Please try again.',
          type: 'error',
        });
      },
    }
  );
  const [userScrolledToEnd, setUserScrolledToEnd] =
    React.useState<boolean>(false);

  const handleOnScroll = (e) => {
    const scrollDifference = e.target.scrollHeight - e.target.scrollTop;
    const bottom = scrollDifference - 20 < e.target.clientHeight;
    setUserScrolledToEnd(bottom);
  };

  useEffect(() => {
    endRef.current?.scrollIntoView(false);
  }, [endRef.current]);

  useEffect(() => {
    if (userScrolledToEnd) {
      endRef.current?.scrollIntoView(false);
    }
  }, [comments]);

  useEffect(() => {
    if (!textFieldRef.current || !endRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (userScrolledToEnd) {
        endRef.current?.scrollIntoView(false);
      }
    });
    resizeObserver.observe(textFieldRef.current);

    return () => resizeObserver.disconnect();
  }, [endRef.current, userScrolledToEnd]);

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      createCommentMutation();
    }
  };

  useEffect(() => {
    if (!endRef.current) {
      return;
    }

    endRef.current?.scrollIntoView(false);

    const resizeObserver = new ResizeObserver(() => {
      endRef.current?.scrollIntoView(false);
    });
    resizeObserver.observe(endRef.current);

    return () => resizeObserver.disconnect();
  }, [endRef.current]);

  if (isSuspeneded || !comments) {
    return (
      <Container>
        {Array(3)
          .fill(null)
          .map((_, idx) => {
            return (
              <div key={idx} style={{ display: 'grid', gap: 4 }}>
                <Skeleton variant='rectangular' width={'60%'} height={16} />
                <Skeleton variant='rectangular' width={'100%'} height={36} />
                <Skeleton variant='rectangular' width={'30%'} height={16} />
              </div>
            );
          })}
      </Container>
    );
  }

  return (
    <Container>
      <CommentList onScroll={handleOnScroll}>
        {comments.length ? (
          comments?.map((comment) => {
            return <Comment key={comment.comment_id} comment={comment} />;
          })
        ) : (
          <NoContentMessage body='No comments added' />
        )}
        <div ref={endRef} />
      </CommentList>

      <TextField
        ref={textFieldRef}
        onKeyDown={onEnterPress}
        multiline
        minRows={2}
        placeholder={'Add your comment'}
        value={comment || ''}
        onChange={(e) => setComment(e.target.value)}
        autoFocus
        InputProps={{
          sx: {
            padding: 1,
          },
          endAdornment: (
            <Tooltip title={comment ? 'Send Now' : ''}>
              <SubmitButton
                color={comment ? 'primary' : 'disabled'}
                onClick={createCommentMutation}
              />
            </Tooltip>
          ),
        }}
      />
    </Container>
  );
};
