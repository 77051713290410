import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyBatchDatasetNewQuery } from './__generated__/getPropertyBatchDatasetNewQuery.graphql';
import { UploadDatasetNewRoute } from 'src/products/data-submission-portal/routes';
import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';
import { getPropertyBatchDatasetIsApproverPlusQuery } from './__generated__/getPropertyBatchDatasetIsApproverPlusQuery.graphql';

export const $getPropertyBatchDatasetNew = graphQLSelector({
  query: graphql`
    query getPropertyBatchDatasetNewQuery($id: Int) {
      sys_property_batch_datasets(
        where: {
          property_batch_dataset_id: { _eq: $id }
          property_batch: { submission: { is_closed: { _neq: true } } }
        }
        limit: 1
      ) {
        property_batch_dataset_id
        dataset_id
        dataset {
          dataset_description
          dataset_label
          unified_ingest_schemas
        }
        property_batch_id
        property_batch {
          property {
            provider {
              ingestion_config
              provider_id
            }
          }
          property_id
          submission_id
          submission {
            submission_type_id
          }
        }
      }
    }
  ` as GraphQLReturn<getPropertyBatchDatasetNewQuery>,
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(UploadDatasetNewRoute.routeParamSelector).dataset_id,
    }),
  mapResponse: (resp) => resp.sys_property_batch_datasets[0],
});

export const $isApproverPlusQuery = graphQLSelector({
  query: graphql`
    query getPropertyBatchDatasetIsApproverPlusQuery(
      $property_batch_dataset_id: Int!
      $submission_type_id: Int!
      $approver_plus_slugs: [String!]!
    ) {
      sys_property_batch_datasets(
        where: {
          property_batch_dataset_id: { _eq: $property_batch_dataset_id }
          property_batch: {
            submission: { is_closed: { _neq: true } }
            property: {
              properties_roles_users: {
                property_role: {
                  property_role_slug: { _in: $approver_plus_slugs }
                }
                submission_type_id: { _eq: $submission_type_id }
              }
            }
          }
        }
      ) {
        property_batch_dataset_id
      }
    }
  ` as GraphQLReturn<getPropertyBatchDatasetIsApproverPlusQuery>,
  mapVariables:
    ({
      submission_type_id,
      property_batch_dataset_id,
    }: {
      submission_type_id: number;
      property_batch_dataset_id: number;
    }) =>
    () => {
      return {
        property_batch_dataset_id,
        submission_type_id,
        approver_plus_slugs: [
          PropertyRole.APPROVER_PLUS_1.label,
          PropertyRole.APPROVER_PLUS_2.label,
          PropertyRole.APPROVER_PLUS_3.label,
        ],
      };
    },
  mapResponse: (res) => res.sys_property_batch_datasets.length > 0,
  swr: false,
  resetCache: false,
});
