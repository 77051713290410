/**
 * Auto generated by Cherre Scripts (generateConstants.ts)
 * Data Submission Portal Constants File
 * DO NOT EDIT THIS FILE MANUALLY
 * to update this file run "npm run dsp-scripts" with hasura container up
 * if you don't have the schema downloaded run "npm run dsp" to run the entire workflow
 */

/* tslint:disable */
/* eslint-disable */

type id = string | number;

type Constant<ID extends id = id, LABEL extends string = string> = {
  id: ID;
  label: LABEL;
};

type Constants<ID extends id = id, LABEL extends string = string> = Record<string, Constant<ID,LABEL>>;

type LabelsOf<T> = T[keyof T] extends Constant<id, infer LABEL> ? LABEL : never;
type IDsOf<T> = T[keyof T] extends Constant<infer ID, string> ? ID : never;
type IDFromLabelType<T extends Constants,LABEL extends LabelsOf<T>> = { [K in keyof T]: T[K] extends Constant<infer ID, LABEL> ? ID : never }[keyof T];
type LabelFromIDType<T extends Constants, ID extends IDsOf<T>> = { [K in keyof T]: T[K] extends Constant<ID, infer LABEL> ? LABEL : never }[keyof T];

type ConstantsStore<T extends Constants> = T & {
  labels: LabelsOf<T>[],
  IDs: IDsOf<T>[],
  IDFromLabel: <LABEL extends LabelsOf<T>>(label: LABEL) => IDFromLabelType<T,LABEL>
  LabelFromID: <ID extends IDsOf<T>>(id: ID) => LabelFromIDType<T,ID>
}

const labels = <T extends Constants>(constants: T): LabelsOf<T>[] => {
  return Object.values(constants).map((c) => c.label) as LabelsOf<T>[];
}

const IDs = <T extends Constants>(constants: T): IDsOf<T>[] => {
  return Object.values(constants).map((c) => c.id) as IDsOf<T>[];
}

const IDfromLabel = <T extends Constants>(constants: T) => <LABEL extends LabelsOf<T>>(label: LABEL): IDFromLabelType<T, LABEL> => {
  const constant = Object.values(constants).find((c) => c.label === label);
  if(!constant) {
    throw new Error(`cannot find constant for label ${label}`);
  }
  return constant.id as IDFromLabelType<T, LABEL>;
}

const LabelFromID = <T extends Constants>(constants: T) => <ID extends IDsOf<T>>(id: ID): LabelFromIDType<T, ID> => {
  const constant = Object.values(constants).find((c) => c.id === id);
  if(!constant) {
    throw new Error(`cannot find constant for ID ${id}`);
  }
  return constant.label as LabelFromIDType<T, ID>;
};

const createStore = <T extends Constants>(constants: T): ConstantsStore<T> => {
  const frozenConstants = Object.fromEntries(Object.entries(constants).map(([key, value]) => [key, Object.freeze(value)]))
  return Object.freeze(Object.assign(frozenConstants, {
    labels: labels(constants),
    IDs: IDs(constants),
    IDFromLabel: IDfromLabel(constants),
    LabelFromID: LabelFromID(constants),
  })) as ConstantsStore<T>;
}


const DatasetStatusConsts = {
    "PENDING": {
        "label": "Pending",
        "id": 10
    },
    "UPLOADING": {
        "label": "Uploading",
        "id": 20
    },
    "UPLOADED": {
        "label": "Uploaded",
        "id": 25
    },
    "UPLOAD_FAILED": {
        "label": "Upload failed",
        "id": 29
    },
    "VALIDATING": {
        "label": "Validating",
        "id": 30
    },
    "VALIDATED": {
        "label": "Validated",
        "id": 35
    },
    "VALIDATED_FAILED": {
        "label": "Validated failed",
        "id": 36
    },
    "VALIDATION_FAILED": {
        "label": "Validation failed",
        "id": 39
    }
} as const;

export const DatasetStatus = createStore(DatasetStatusConsts);
export type DatasetStatusLabel = LabelsOf<typeof DatasetStatusConsts>;
export type DatasetStatusID = IDsOf<typeof DatasetStatusConsts>;


const PropertyBatchStageConsts = {
    "PENDING": {
        "label": "Pending",
        "id": 10
    },
    "IN_PREPARATION": {
        "label": "In Preparation",
        "id": 20
    },
    "REJECTED": {
        "label": "Rejected",
        "id": 21
    },
    "REVIEW_1": {
        "label": "Review 1",
        "id": 30
    },
    "REVIEW_2": {
        "label": "Review 2",
        "id": 31
    },
    "REVIEW_3": {
        "label": "Review 3",
        "id": 32
    },
    "APPROVED": {
        "label": "Approved",
        "id": 40
    },
    "SUBMITTED_INVESTMENT": {
        "label": "Submitted Investment",
        "id": 130
    },
    "APPROVED_INVESTMENT": {
        "label": "Approved Investment",
        "id": 140
    }
} as const;

export const PropertyBatchStage = createStore(PropertyBatchStageConsts);
export type PropertyBatchStageLabel = LabelsOf<typeof PropertyBatchStageConsts>;
export type PropertyBatchStageID = IDsOf<typeof PropertyBatchStageConsts>;


const PropertyTypeConsts = {
    "ONE_TO_ONE": {
        "label": "one_to_one",
        "id": 1
    },
    "INVESTMENT": {
        "label": "investment",
        "id": 2
    },
    "PROVIDER": {
        "label": "provider",
        "id": 3
    }
} as const;

export const PropertyType = createStore(PropertyTypeConsts);
export type PropertyTypeLabel = LabelsOf<typeof PropertyTypeConsts>;
export type PropertyTypeID = IDsOf<typeof PropertyTypeConsts>;


const PropertyRoleConsts = {
    "PREPARER": {
        "label": "preparer",
        "id": 1
    },
    "REVIEWER_1": {
        "label": "reviewer_1",
        "id": 2
    },
    "REVIEWER_2": {
        "label": "reviewer_2",
        "id": 3
    },
    "REVIEWER_3": {
        "label": "reviewer_3",
        "id": 4
    },
    "VIEW_ONLY_1": {
        "label": "view_only_1",
        "id": 5
    },
    "VIEW_ONLY_2": {
        "label": "view_only_2",
        "id": 6
    },
    "VIEW_ONLY_3": {
        "label": "view_only_3",
        "id": 7
    },
    "APPROVER_PLUS_1": {
        "label": "approver_plus_1",
        "id": 8
    },
    "APPROVER_PLUS_2": {
        "label": "approver_plus_2",
        "id": 9
    },
    "APPROVER_PLUS_3": {
        "label": "approver_plus_3",
        "id": 10
    }
} as const;

export const PropertyRole = createStore(PropertyRoleConsts);
export type PropertyRoleLabel = LabelsOf<typeof PropertyRoleConsts>;
export type PropertyRoleID = IDsOf<typeof PropertyRoleConsts>;


const PropertyRoleSetConsts = {
    "PREPARER": {
        "label": "preparer",
        "id": "preparer"
    },
    "REVIEWER_1": {
        "label": "reviewer_1",
        "id": "reviewer"
    },
    "REVIEWER_2": {
        "label": "reviewer_2",
        "id": "reviewer"
    },
    "REVIEWER_3": {
        "label": "reviewer_3",
        "id": "reviewer"
    },
    "APPROVER_PLUS_3": {
        "label": "approver_plus_3",
        "id": "reviewer"
    },
    "APPROVER_PLUS_1": {
        "label": "approver_plus_1",
        "id": "reviewer"
    },
    "APPROVER_PLUS_2": {
        "label": "approver_plus_2",
        "id": "reviewer"
    },
    "VIEW_ONLY_1": {
        "label": "view_only_1",
        "id": "view_only"
    },
    "VIEW_ONLY_2": {
        "label": "view_only_2",
        "id": "view_only"
    },
    "VIEW_ONLY_3": {
        "label": "view_only_3",
        "id": "view_only"
    }
} as const;

export const PropertyRoleSet = createStore(PropertyRoleSetConsts);
export type PropertyRoleSetLabel = LabelsOf<typeof PropertyRoleSetConsts>;
export type PropertyRoleSetID = IDsOf<typeof PropertyRoleSetConsts>;


const PropertyModelConsts = {
    "ONE_TO_ONE": {
        "label": "one_to_one",
        "id": 1
    },
    "MANY_TO_ONE": {
        "label": "many_to_one",
        "id": 2
    }
} as const;

export const PropertyModel = createStore(PropertyModelConsts);
export type PropertyModelLabel = LabelsOf<typeof PropertyModelConsts>;
export type PropertyModelID = IDsOf<typeof PropertyModelConsts>;

