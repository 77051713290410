import {
  GraphQLReturn,
  graphQLSelector,
  NotFoundError,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getProviderQuery } from './__generated__/getProviderQuery.graphql';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';

export const getProvider = graphQLSelector({
  query: graphql`
    query getProviderQuery($id: Int) {
      sys_providers(limit: 1, where: { provider_id: { _eq: $id } }) {
        provider_id
        provider_name
        provider_type {
          name
        }
      }
    }
  ` as GraphQLReturn<getProviderQuery>,
  mapResponse: (resp) => {
    if (resp.sys_providers.length === 0) {
      throw new NotFoundError('Provider Not Found');
    }
    return resp.sys_providers[0];
  },
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
    }),
});
