import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  managedPropertyState,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';
import { getCurrentPreparersAndReviewersQuery } from './__generated__/getCurrentPreparersAndReviewersQuery.graphql';

export const getCurrentPreparersAndReviewers = graphQLSelector({
  query: graphql`
    query getCurrentPreparersAndReviewersQuery($property_ids: [Int!]!) {
      sys_properties_flattened_union(
        where: {
          _and: {
            is_active: { _eq: true }
            _or: [
              { property_id: { _in: $property_ids } }
              { parent_property_id: { _in: $property_ids } }
            ]
          }
        }
      ) {
        properties_roles_users(
          where: { is_active: { _eq: true } }
          order_by: [
            { user: { sakura_user: { first_name: asc } } }
            { user: { sakura_user: { last_name: asc } } }
          ]
        ) {
          property_role_user_id
          user_id
          property_id
          property {
            entity_id
          }
          property_role_id
          property_role {
            property_role_slug
            property_role_set
          }
          user {
            sakura_user {
              first_name
              email
              last_name
            }
          }
          submission_type_id
          submission_type {
            submission_type_slug
          }
        }
      }
    }
  ` as GraphQLReturn<getCurrentPreparersAndReviewersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const selectedProperties = get(selectedPropertiesToAssign);
      const managedProperty = get(managedPropertyState);
      return {
        property_ids: managedProperty.property_id
          ? [managedProperty.property_id]
          : selectedProperties.map((property) => property.property_id),
      };
    },
  mapResponse: (response) => response.sys_properties_flattened_union,
});
