/**
 * @generated SignedSource<<dc6a71510673dcf82103d43d0b4a63ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyBatchDatasetQuery$variables = {
  id?: number | null;
};
export type getPropertyBatchDatasetQuery$data = {
  readonly sys_property_batch_datasets: ReadonlyArray<{
    readonly dataset: {
      readonly dataset_description: string;
      readonly dataset_label: string;
    } | null;
    readonly dataset_id: number | null;
    readonly property_batch: {
      readonly submission_id: number;
    };
    readonly property_batch_dataset_id: number;
    readonly property_batch_id: number;
  }>;
};
export type getPropertyBatchDatasetQuery = {
  response: getPropertyBatchDatasetQuery$data;
  variables: getPropertyBatchDatasetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "property_batch",
            "value": {
              "submission": {
                "is_closed": {
                  "_neq": true
                }
              }
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_datasets",
    "kind": "LinkedField",
    "name": "sys_property_batch_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets",
        "kind": "LinkedField",
        "name": "dataset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyBatchDatasetQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertyBatchDatasetQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73fd8679cd1797dcc680944406f0cbb6",
    "id": null,
    "metadata": {},
    "name": "getPropertyBatchDatasetQuery",
    "operationKind": "query",
    "text": "query getPropertyBatchDatasetQuery(\n  $id: Int\n) {\n  sys_property_batch_datasets(where: {property_batch_dataset_id: {_eq: $id}, property_batch: {submission: {is_closed: {_neq: true}}}}, limit: 1) {\n    property_batch_dataset_id\n    dataset_id\n    dataset {\n      dataset_description\n      dataset_label\n    }\n    property_batch_id\n    property_batch {\n      submission_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ddd0466292d21bcdbf29da688f36516";

export default node;
