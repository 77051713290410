import {
  QueryResult,
  useCherreState,
  useCherreValue,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import {
  ActionsMenu,
  Chip,
  DatasetsIcon,
  Grid,
  MRT_ColumnDef,
  PersonIcon,
  Table,
  TogglesIcon,
  tableBaseStyle,
  useTable,
  useTheme,
  MRT_RowSelectionState,
  useConfirmationModal,
  ConfirmationModal,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo, useEffect } from 'react';
import { ClickCard } from 'src/products/data-submission-portal/components/ClickCard';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { UsersClickCard } from '../../../../components/UsersClickCard';
import { NoProperties } from './components/NoProperties';
import {
  getPropertiesPageProperties,
  getPropertiesPagePropertiesTotal,
} from './queries/getProperties';
import {
  getClickCardPropertyDataMappingsByIds,
  getClickCardPropertyDatasetsByIds,
} from './queries/getClickCardData';
import { getPropertyTypeCustomAttributes } from './queries/getPropertyTypeCustomAttributes';
import { StatusChip } from 'src/products/data-submission-portal/components/StatusChip';
import AddEditPropertyDialogModal from './components/AddEditPropertyDialog';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { DisablePropertyActionMenuItem } from './components/DisablePropertyActionMenuItem';
import { UploadPropertyButton } from './components/UploadPropertyButton';
import { EditPropertyActionMenuItem } from './components/EditPropertyActionMenuItem';
import { propertiesPageTableState } from './recoil';
import { ManageUsersActionMenuItem } from './components/ManageUsersActionMenuItem';
import { ManageMappingsActionMenuItem } from './components/ManageMappingsActionMenuItem';
import { ManageDatasetsActionMenuItem } from './components/ManageDatasetsActionMenuItem';
import { useOpenAssignMapping } from './hooks/useOpenAssignMapping';
import {
  propertiesTableSelectedProvider,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export type PropertiesPanelProps = {
  search?: string;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  rowSelection: MRT_RowSelectionState;
};

export const disableColumnGrow = {
  muiTableHeadCellProps: (props) => ({
    sx: {
      ...tableBaseStyle.muiTableHeadCellProps(props).sx,
      flex: '0 0 auto',
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      ...tableBaseStyle.muiTableBodyCellProps.sx,
      flex: '0 0 auto',
    },
  },
};

export const PropertiesPanel: React.FC<PropertiesPanelProps> = ({
  search,
  rowSelection,
  setRowSelection,
}) => {
  const theme = useTheme();
  const isAdminUploadPropertyEnabled = useFeatureFlag('DspAdminUploadProperty');

  const customAttributes = useCherreValue(getPropertyTypeCustomAttributes());
  const setSelectedPropertiesToAssign = useCherreSetState(
    selectedPropertiesToAssign
  );

  const setSelectedProvider = useCherreSetState(
    propertiesTableSelectedProvider
  );

  const openAssignMappingDialogModalWithTitles = useOpenAssignMapping();

  // table
  const [tableState, setTableState] = useCherreState(propertiesPageTableState);
  const data = useCherreValue(getPropertiesPageProperties());
  const totalCount = useCherreValue(getPropertiesPagePropertiesTotal());
  const resultMode: 'no_properties' | 'no_items_found' | 'table' =
    useMemo(() => {
      if (data && data.length > 0) {
        return 'table';
      } else if (search) {
        return 'no_items_found';
      } else {
        return 'no_properties';
      }
    }, [data, search]);

  // control row action options
  const { modalProps, confirm } = useConfirmationModal();
  const rowActionOptions = [
    {
      name: 'manage-users',
      enabled: true,
      renderFn: (row) => (
        <ManageUsersActionMenuItem
          setRowSelection={setRowSelection}
          property={row}
        />
      ),
    },
    {
      name: 'manage-mapping',
      enabled: true,
      renderFn: (row) => (
        <ManageMappingsActionMenuItem
          property={row}
          openAssignMappingDialogModalWithTitles={
            openAssignMappingDialogModalWithTitles
          }
        />
      ),
    },
    {
      name: 'manage-datasets',
      enabled: true,
      renderFn: (row) => <ManageDatasetsActionMenuItem property={row} />,
    },
    {
      name: 'edit',
      enabled: isAdminUploadPropertyEnabled,
      renderFn: (row) => (
        <EditPropertyActionMenuItem
          data={row}
          key={`edit-${row.property_id}`}
        />
      ),
    },
    {
      name: 'disable',
      enabled: isAdminUploadPropertyEnabled,
      renderFn: (row) => (
        <DisablePropertyActionMenuItem
          propertyId={row.property_id}
          propertyCode={row.property_code}
          confirmFunction={confirm}
          key={`disable-${row.property_id}`}
        />
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      const rowSelectionIds = Object.keys(rowSelection);
      const rowSelectionData = data?.filter((property) =>
        rowSelectionIds.includes(property.property_id.toString())
      );
      const mappedRowSelectionData = rowSelectionData?.map((property) => ({
        entity_id: property.entity_id,
        property_id: property.property_id,
        property_name: property.property_name,
        organization_id: property.organization_id,
        property_mapping: property.property_mapping,
        property_code: property.property_code,
        provider: property.provider ?? null,
        parent_property_id: property.parent_property_id,
      }));
      const mappedProviderIds =
        rowSelectionData?.map((property) => {
          return property.provider?.provider_id;
        }) ?? [];

      setSelectedProvider(
        [...new Set(mappedProviderIds)].length === 1
          ? mappedProviderIds[0]
          : null
      );
      setSelectedPropertiesToAssign(mappedRowSelectionData);
    }
  }, [rowSelection, data]);

  const columnsRendered = useMemo(() => {
    return {
      entity_name: data?.some((property) => property.entity_name) ?? true,
      address: data?.some((property) => property.address) ?? true,
      fund: data?.some((property) => property.fund) ?? true,
      type: data?.some((property) => property.type) ?? true,
    };
  }, [data]);

  const getMappingSetCardData = (mappingSetIds: number[]) => {
    return (): string[] =>
      useCherreValue(
        getClickCardPropertyDataMappingsByIds({
          mapping_ids: mappingSetIds,
        })
      ) ?? [];
  };

  const getDatasetsCardData = (datasetIds: number[]) => {
    return (): string[] =>
      useCherreValue(
        getClickCardPropertyDatasetsByIds({
          dataset_ids: datasetIds,
        })
      ) ?? [];
  };

  const columns: MRT_ColumnDef<
    QueryResult<typeof getPropertiesPageProperties>[number]
  >[] = useMemo(
    () => [
      {
        accessorKey: 'property_code',
        header: 'Target ID',
        size: 95,
      },
      {
        accessorKey: 'entity_id',
        header: 'Source ID',
        size: 95,
      },
      {
        accessorKey: 'property_mapping',
        header: 'Mapping',
        size: 95,
      },
      {
        accessorFn: (row) => row.provider?.provider_name,
        header: 'Provider',
        id: 'provider.provider_name',
        size: 180,
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && (
            <Chip
              size='small'
              label={renderedCellValue}
              sx={{
                background: theme.accents.info.light,
                color: theme.palette.grey[900],
              }}
            />
          ),
      },
      {
        accessorKey: 'property_name',
        header: 'Target Name',
        size: 140,
      },

      {
        accessorKey: 'address',
        header: 'Address',
      },
      {
        accessorKey: 'fund',
        header: 'Fund',
        maxSize: 100,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        maxSize: 130,
      },
      ...(customAttributes || []).map((attr) => ({
        id: attr.slug,
        accessorFn: (row) => row.custom_attributes?.[attr.slug],
        header: attr.label,
        enableSorting: false,
        size: 40,
      })),
      {
        accessorFn: (row) => row.users_count ?? 0,
        id: 'users_count',
        header: 'Users',
        size: 80,
        muiTableHeadCellProps: (props) => ({
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps(props).sx,
            '& > .Mui-TableHeadCell-Content': {
              width: '80px',
            },
          },
        }),
        muiTableBodyCellProps: {
          sx: {
            ...tableBaseStyle.muiTableBodyCellProps.sx,
            '& > .MuiTypography-root': {
              width: '80px',
            },
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <UsersClickCard
            assigned_preparers={
              row.original.users_preparers?.map((obj) => obj.user_id) ?? []
            }
            assigned_fin_reviewers={
              row.original.users_financial_reviewers?.map(
                (obj) => obj.user_id
              ) ?? []
            }
            assigned_op_reviewers={
              row.original.users_operational_reviewers?.map(
                (obj) => obj.user_id
              ) ?? []
            }
            link_text={renderedCellValue}
            icon={<PersonIcon />}
          />
        ),
      },
      {
        accessorFn: (row) => row.mappings_count ?? 0,
        id: 'mappings_count',
        header: 'Mappings',
        size: 120,
        muiTableHeadCellProps: (props) => ({
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps(props).sx,
            '& > .Mui-TableHeadCell-Content': {
              width: '120px',
              paddingLeft: '10px',
              paddingRight: '10px',
            },
          },
        }),
        muiTableBodyCellProps: {
          sx: {
            ...tableBaseStyle.muiTableBodyCellProps.sx,
            '& > .MuiTypography-root': {
              width: '90px',
              paddingLeft: '10px',
              paddingRight: '10px',
            },
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <ClickCard
            title='Mappings'
            data={getMappingSetCardData(
              row.original.mapping_set_ids.map((obj) => obj.mapping_set_id)
            )}
            link_text={renderedCellValue}
            icon={<TogglesIcon />}
            dataLength={row.original.mapping_set_ids.length}
          />
        ),
      },
      {
        accessorFn: (row) => row.datasets_count ?? 0,
        header: 'Datasets',
        id: 'datasets_count',
        maxSize: 95,
        muiTableHeadCellProps: (props) => ({
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps(props).sx,
            '& > .Mui-TableHeadCell-Content': {
              width: '95px',
            },
          },
        }),
        muiTableBodyCellProps: {
          sx: {
            ...tableBaseStyle.muiTableBodyCellProps.sx,
            '& > .MuiTypography-root': {
              width: '80px',
            },
          },
        },
        Cell: ({ renderedCellValue, row }) => (
          <ClickCard
            title='Datasets'
            data={getDatasetsCardData(
              row.original.properties_datasets.map((obj) => obj.dataset_id)
            )}
            id={row.original.property_id}
            link_text={renderedCellValue}
            icon={<DatasetsIcon />}
            dataLength={row.original.properties_datasets.length}
          />
        ),
      },
      {
        accessorFn: (row) => {
          return row.is_ready_for_submission ?? false;
        },
        header: 'DSP Status',
        id: 'is_ready_for_submission',
        Cell: ({ renderedCellValue }) => (
          <StatusChip active={renderedCellValue as boolean} />
        ),
        size: 80,
      },
    ],
    [columnsRendered, customAttributes]
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowActions: true,
    enableRowSelection: true,
    enableSelectAll: true,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    rowCount: totalCount?.aggregate?.count,
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    renderRowActions: (ctx) =>
      //render when has actions
      rowActionOptions.some((option) => option.enabled) ? (
        <Grid
          container
          justifyContent={'flex-end'}
          sx={{
            color: theme.palette.grey[500],
            '& .MuiIconButton-root': {
              color: theme.palette.grey[700],
            },
          }}
        >
          <ActionsMenu>
            {rowActionOptions.map((option) =>
              option.renderFn(ctx.row.original)
            )}
          </ActionsMenu>
        </Grid>
      ) : null,
    state: {
      rowSelection,
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
      columnPinning: {
        right: ['mrt-row-actions'],
      },
      columnVisibility: {
        ...columnsRendered,
      },
    },
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.property_id?.toString(),
    enableMultiSort: true,
  });

  return (
    <div>
      <AddEditPropertyDialogModal />
      <ConfirmationModal {...modalProps} />
      <Container>
        {resultMode === 'table' && <Table table={table} />}
        {resultMode === 'no_items_found' && (
          <Grid container direction='column' alignItems='center'>
            <NoItemsFound title='No Properties Found' />
          </Grid>
        )}
        {resultMode === 'no_properties' && (
          <Grid container direction='column' alignItems='center'>
            <NoProperties />
            {isAdminUploadPropertyEnabled && (
              <UploadPropertyButton mode='upload' confirmFunction={confirm} />
            )}
          </Grid>
        )}
      </Container>
    </div>
  );
};

PropertiesPanel.displayName = 'PropertiesPanel';
