import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getMappingSetQuery } from './__generated__/getMappingSetQuery.graphql';
import { MappingDetailRoute } from 'src/products/data-submission-portal/routes';

export const getMappingSet = graphQLSelector({
  query: graphql`
    query getMappingSetQuery($id: Int) {
      sys_mapping_sets(where: { mapping_set_id: { _eq: $id } }) {
        mapping_set_id
        mapping_set_description
        mapping_field {
          mapping_field_name
          mapping_field_description
          custom_attributes
        }
        provider {
          provider_id
          provider_name
        }
        organization_id
        organization {
          organization_label
        }
        organization_master_chart_of_accounts {
          master_chart_of_account_id
          organization_master_chart_of_account_values {
            master_chart_of_account_values_id
            label: account_code
            value: account_name
            is_active
          }
        }
        updated_at_datetime
        mapping_set_values_aggregate {
          aggregate {
            max {
              updated_at_datetime
            }
          }
        }
      }
    }
  ` as GraphQLReturn<getMappingSetQuery>,
  mapResponse: (resp) => {
    return resp.sys_mapping_sets[0];
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        id: get(MappingDetailRoute.routeParamSelector).mapping_set_id,
      };
    },
});
