import React from 'react';

import { SearchAssetIcon, styled } from '@cherre-frontend/ui';

const NoBatchesFoundContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const NoBatchesFoundHeader = styled('h3')`
  font-size: 20px;
  margin: 6px 0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

type NoItemsFoundProps = {
  title: string;
};

export const NoItemsFound: React.FC<NoItemsFoundProps> = ({
  title,
  children,
}) => {
  return (
    <NoBatchesFoundContainer>
      <SearchAssetIcon fontSize='large' color='disabled' />
      <NoBatchesFoundHeader>{title}</NoBatchesFoundHeader>
      {children}
    </NoBatchesFoundContainer>
  );
};

NoItemsFound.displayName = 'NoBatchesFound';
