/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { ReportTableQuery } from './__generated__/ReportTableQuery.graphql';
import {
  MRT_ColumnDef,
  Table,
  useTable,
  useTheme,
  tableBaseStyle,
  SxProps,
  Theme,
} from '@cherre-frontend/ui';
import { useJsonLogic } from '../../hooks/useJsonLogic';
import {
  useTableArgs,
  useTableSearch,
  useReportParams,
  useTableSorting,
  useTableWhere,
} from './ReportContext';
import { Column, TableConfig } from './recoil';
import { sizeCalculator } from './utils';
import { aggregateBy } from './aggregateBy';

const reportTableSelector = graphQLSelector({
  query: graphql`
    query ReportTableQuery($params: report_table_data_input!) {
      _sys_get_report_table_data(params: $params) {
        data
      }
    }
  ` as GraphQLReturn<ReportTableQuery>,
  mapResponse: (resp) => resp._sys_get_report_table_data.data,
  swr: (o, n) =>
    o.params.params.report_slug === n.params.params.report_slug &&
    o.params.params.table_slug === n.params.params.table_slug,
});
const isNumber = (type: string) => {
  return ['Int', 'numeric', 'bigint', 'float8'].includes(type);
};

type JsonLogic = ReturnType<typeof useJsonLogic>;

type JsonLogicData = {
  query_arguments?: Record<string, unknown>;
  where?: Record<string, unknown>;
  report_params?: Record<string, unknown>;
  tableData: Record<string, unknown>[];
};

//format

const accessorFn = (
  jsonLogic: JsonLogic,
  column: Column,
  data: JsonLogicData
): MRT_ColumnDef<any>['accessorFn'] => {
  if (column.dynamicColumn) {
    return (rowData) => {
      const rowValue =
        rowData[column.accessorKey!] ||
        rowData[column.valuesField!]?.find(
          (item) => item[column.accessorKey!]
        )?.[column.accessorKey!];

      if (column.format) {
        return jsonLogic(column.format, {
          ...data,
          rowData,
          value: rowValue,
        });
      }
    };
  }

  if (column.format) {
    return (rowData) => {
      return jsonLogic(column.format, { ...data, rowData });
    };
  }
};

//aggregation

const getAggregationRow = (
  jsonLogic: JsonLogic,
  columns: Column[],
  data: JsonLogicData
): any => {
  //avoid aggregating grouping rows
  const tableData = data.tableData.filter((d) => !d.grouping_code);
  const cleanData = { ...data, tableData };
  return columns.reduce((acc, c) => {
    if (c.columns) {
      return { ...acc, ...getAggregationRow(jsonLogic, c.columns, cleanData) };
    }
    if (c.aggregation) {
      if (c.dynamicColumn) {
        const list = tableData
          .flatMap(
            (row) =>
              (row[c.valuesField!] as Record<string, any>[])?.find(
                (item) => item[c.accessorKey!]
              )?.[c.accessorKey!]
          )
          .filter(Boolean);

        const aggregation = jsonLogic(c.aggregation.value, {
          ...cleanData,
          list,
        });

        return {
          ...acc,
          [c.accessorKey!]: aggregation,
        };
      }

      return {
        ...acc,
        [c.accessorKey!]: jsonLogic(c.aggregation.value, cleanData),
      };
    }
    return { ...acc, [c.accessorKey!]: null };
  }, {});
};

//stylers

const cellStyle = (
  jsonLogic: JsonLogic,
  theme: Theme,
  column: Column,
  data: JsonLogicData,
  rowData?: any
): SxProps => {
  // build style map resolver
  const stylesMap: Map<string, SxProps> = new Map([
    ['center', { justifyContent: 'center' }],
    ['right', { justifyContent: 'flex-end' }],
    ['left', { justifyContent: 'flex-start' }],
    ['bold', { fontWeight: 700 }],
    ['error', { color: theme.accents.error.main }],
    ['success', { backgroundColor: theme.palette.success.light }],
    ['paddingBottom', { paddingBottom: '20px', height: '62px' }],
    ['borderTop', { borderTop: '2px solid var(--Gray-900, #212121)' }],
  ]);
  // resolve style string and initialize return object
  const style: string = jsonLogic(column.style, { ...data, rowData }) || '';
  const stylesStr = style.split(' ') || [];
  return stylesStr
    .map((s) => stylesMap.get(s.trim()))
    .filter((s): s is SxProps => !!s)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
};

const cellAlignment = (column: Column) => {
  if (column.align === 'right') {
    return 'flex-end';
  }
  if (column.align === 'left') {
    return 'flex-start';
  }
  if (isNumber(column.type)) {
    return 'flex-end';
  }
  return 'flex-start';
};

const footerAlignment = (column: Column) => {
  if (column.aggregation?.align === 'right') {
    return 'flex-end';
  }
  if (column.aggregation?.align === 'left') {
    return 'flex-start';
  }
  return cellAlignment(column);
};

const headerAlignment = (column: Column) => {
  if (column.header!.align === 'right') {
    return 'flex-end';
  }
  if (column.header!.align === 'left') {
    return 'flex-start';
  }
  return cellAlignment(column);
};

const mapColumns = (
  jsonLogic: JsonLogic,
  columns: Column[],
  data: JsonLogicData,
  theme: Theme
): [Column[], any] => {
  const calculateSize = sizeCalculator(columns, 0);
  const hasAggregation = columns.some((c) => c.aggregation);

  const withDynamicColumns = columns.reduce((acc, curr) => {
    if (!curr.dynamicColumn) {
      return [...acc, curr];
    }

    const dynamicColumns = [
      ...new Set(
        data.tableData
          .flatMap((row) => row[curr.columnsField!] as string[])
          .sort((a, b) => a.localeCompare(b))
      ),
    ];

    return [
      ...acc,
      ...dynamicColumns.map((column) => ({
        ...curr,
        id: column,
        accessorKey: column,
        header: {
          label: column,
          align: curr.align,
        },
      })),
    ];
  }, [] as Column[]);

  const aggregationRow = getAggregationRow(jsonLogic, withDynamicColumns, data);

  return [
    withDynamicColumns,
    withDynamicColumns.map(
      (c) =>
        ({
          id: c.accessorKey!,
          accessorKey: c.accessorKey!,
          accessorFn: accessorFn(jsonLogic, c, data),
          header: c.header!.label,
          enableSorting: c.sortable ?? false,
          footer: hasAggregation
            ? accessorFn(jsonLogic, c, data)?.(aggregationRow) ||
              aggregationRow[c.accessorKey!]
            : undefined,
          minSize: calculateSize(c),
          muiTableFooterCellProps: {
            ...tableBaseStyle.muiTableBodyCellProps,
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              justifyContent: footerAlignment(c),
              alignItems: 'center',
              fontWeight: 700,
              ...cellStyle(jsonLogic, theme, c, data, aggregationRow),
            },
          },
          muiTableBodyCellProps: ({ row }) => ({
            ...tableBaseStyle.muiTableBodyCellProps,
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              justifyContent: cellAlignment(c),
              ...cellStyle(jsonLogic, theme, c, data, row.original),
            },
          }),
          muiTableHeadCellProps: (props) => ({
            ...tableBaseStyle.muiTableHeadCellProps(props),
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > div': {
                justifyContent: headerAlignment(c),
              },
              '& .Mui-TableHeadCell-Content-Wrapper': {
                textOverflow: 'unset',
                whiteSpace: 'unset',
              },
            },
          }),
          columns: c.columns
            ? mapColumns(jsonLogic, c.columns, data, theme)[1]
            : undefined,
          // eslint-disable-next-line prettier/prettier
        } satisfies MRT_ColumnDef<any>)
    ),
  ];
};

export type ReportTableProps = {
  report_slug: string;
  pagination?: { pageSize: number; pageIndex: number };
  table_config: TableConfig;
  columnSizing?: Record<string, number>;
  onAddDynamicColumns: (columns: Column[]) => void;
  onSizingChange: (
    updater: (sizing: Record<string, number>) => Record<string, number>
  ) => void;
};

const getColumnOrder = (columns: Column[]) => {
  return columns.flatMap((c) =>
    c.columns ? getColumnOrder(c.columns) : [c.accessorKey]
  );
};

export const ReportTable: React.FC<ReportTableProps> = ({
  report_slug,
  pagination,
  table_config,
  columnSizing,
  onAddDynamicColumns,
  onSizingChange,
}) => {
  const jsonLogic = useJsonLogic();
  const query_arguments = useTableArgs(report_slug, table_config);
  const where = useTableWhere(report_slug, table_config);
  const report_params = useReportParams(report_slug);
  const search = useTableSearch();
  const [sorting, onSortingChange] = useTableSorting(table_config);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLTableElement>(null);

  const data = useCherreValue(
    reportTableSelector({
      params: {
        report_slug,
        table_slug: table_config.table_slug,
        query_arguments,
        where,
        search,
        report_params,
        order_by: sorting,
        limit: pagination ? pagination.pageSize : undefined,
        offset: pagination
          ? pagination.pageIndex * pagination.pageSize
          : undefined,
      },
    })
  );

  const data_with_aggregations = useMemo<typeof data>(() => {
    if (!table_config.groupings) {
      return data;
    }
    if (!data) {
      return data;
    }
    return aggregateBy(
      { query_arguments, where, report_params, tableData: data },
      table_config.groupings.keys,
      table_config.groupings.aggregations,
      sorting,
      jsonLogic
    );
  }, [
    data,
    sorting,
    table_config.groupings,
    jsonLogic,
    query_arguments,
    where,
    report_params,
  ]);

  useEffect(() => {
    const updateHeight = () => {
      const rect = containerRef.current?.getBoundingClientRect();
      if (rect) {
        setHeight(rect.height);
      }
    };
    updateHeight();
    containerRef.current?.addEventListener('resize', updateHeight);
    window.addEventListener('resize', updateHeight);
    const interval = setInterval(updateHeight, 100);
    return () => {
      containerRef.current?.removeEventListener('resize', updateHeight);
      window.removeEventListener('resize', updateHeight);
      clearInterval(interval);
    };
  }, []);

  const theme = useTheme();

  const width = useMemo(
    () =>
      columnSizing ? Object.values(columnSizing).reduce((a, b) => a + b, 0) : 0,
    [columnSizing]
  );

  const [columns, muiColumns] = useMemo(() => {
    return mapColumns(
      jsonLogic,
      table_config.columns,
      {
        where,
        query_arguments,
        report_params,
        tableData: data_with_aggregations || [],
      },
      theme
    );
  }, [
    table_config.columns,
    where,
    query_arguments,
    data_with_aggregations,
    report_params,
  ]);

  useEffect(() => {
    onAddDynamicColumns(columns.filter((c) => c.dynamicColumn));
  }, [columns]);

  const columnPinning = useMemo(() => {
    const left = table_config.columns
      .filter((c) => c.frozen === 'left')
      .map((c) => c.accessorKey!);
    const right = table_config.columns
      .filter((c) => c.frozen === 'right')
      .map((c) => c.accessorKey!);
    return { left, right };
  }, [table_config.columns]);

  const hasRecords = data && data.length > 0;

  const table = useTable({
    columns: muiColumns,
    data: data_with_aggregations,
    enableTableHead: hasRecords,
    enableTableFooter: hasRecords,
    enableSelectAll: false,
    enableRowSelection: false,
    enableColumnResizing: true,
    layoutMode: 'grid',
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        overflow: 'hidden',
        height,
      },
    },
    muiTableContainerProps: {
      sx: {
        overflow: 'unset',
        position: 'absolute',
        maxWidth: 'unset',
        width,
      },
    },
    muiTableProps: {
      ref: containerRef,
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualSorting: true,
    enableColumnPinning: true,
    muiTableHeadProps: {
      sx: {
        display: table_config.hide_header ? 'none' : undefined,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: 'none',
        borderRadius: '0',
        backgroundColor: theme.palette.grey[100],
        '&.Mui-selected': {
          backgroundColor: theme.palette.grey[100],
        },
        '& > td': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBaseStyle.muiTableBodyRowProps.sx,
        '& > td': {
          minHeight: '42px',
          height: 'auto',
        },
      },
    },
    muiTableFooterRowProps: {
      sx: {
        ...tableBaseStyle.muiTableBodyRowProps.sx,
      },
    },
    state: {
      sorting,
      columnPinning,
      columnSizing,
      columnOrder: getColumnOrder(columns),
    },
    onSortingChange,
    onColumnSizingChange: columnSizing ? (onSizingChange as any) : undefined,
    renderEmptyRowsFallback: () => <span />,
  });

  useEffect(() => {
    table.getRowModel().rows.forEach((row) => (row._valuesCache = {}));
    const columnIds = table_config.columns.map((c) => c.accessorKey);
    onSortingChange((old) => old.filter((c) => columnIds.includes(c.id)));
  }, [table_config]);

  if (!columnSizing) {
    return null;
  }

  return <Table table={table} />;
};
