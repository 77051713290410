import { Grid, Typography, styled } from '@cherre-frontend/ui';
import React from 'react';

export type KeyValueProps = {
  label: string;
  value?: React.ReactNode;
  children?: never;
};

const Key = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Value = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.common.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const KeyValue: React.FC<KeyValueProps> = ({ label, value }) => {
  return (
    <Grid container item xs={12} spacing='5px' style={{ placeItems: 'center' }}>
      <Grid item xs='auto'>
        <Key>{label + ':'}</Key>
      </Grid>
      <Grid item xs>
        {!value || typeof value === 'string' ? (
          <Value className='suspend'>{value || 'Unknown'}</Value>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

KeyValue.displayName = 'SingleFileValidations/KeyValue';

export default KeyValue;
