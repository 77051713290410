import React from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  Grid,
  Typography,
  styled,
  IconButton,
  CloseIcon,
  List,
  ListItem,
} from '@cherre-frontend/ui';
import { getReportTemplateData } from './queries/getReportTemplateData';
import { useHistory } from 'react-router-dom';
import {
  useCherreValue,
  useCherreEventWithRecoil,
} from '@cherre-frontend/data-fetching';
import { downloadAdjustmentsModalOpenState } from 'src/products/data-submission-portal/recoil/dialog';
import XLSX from 'xlsx';
import moment from 'moment';

export const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
  }
`;

type DownloadAdjustmentModalProps = {
  tsa_property_batch_dataset_id?: number;
  report_name?: string;
  property_code?: string;
  dataset_id?: number;
  dataset_slug?: string;
  submission_name?: string;
};

export const DownloadAdjustmentModal: React.FC<
  DownloadAdjustmentModalProps
> = ({
  tsa_property_batch_dataset_id,
  report_name = '',
  property_code = '',
  dataset_id = 0,
  dataset_slug,
  submission_name,
}) => {
  const arrTemplateData = useCherreValue(getReportTemplateData({ dataset_id }));
  const isModalOpen = useCherreValue(downloadAdjustmentsModalOpenState);
  const { push } = useHistory();

  const onClose = useCherreEventWithRecoil(
    'user closed assign provider dialog',
    (ctx) => () => {
      ctx.recoil.set(downloadAdjustmentsModalOpenState, false);
    }
  );

  const downloadTSATemplate = () => {
    const filename = `${dataset_slug}_adjustment_${submission_name}_${moment().format(
      'MMMDDYYYY'
    )}.xls`;
    const workbook = XLSX.utils.book_new();
    const validTemplateData = arrTemplateData ? [arrTemplateData] : [[]];
    const worksheet = XLSX.utils.aoa_to_sheet(validTemplateData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TSATemplate');
    XLSX.writeFile(workbook, filename);
  };

  const handleContinueClick = () => {
    onClose();
    push('/dsp/reviewer/upload-dataset-new/' + tsa_property_batch_dataset_id, {
      shouldGoBack: true,
    });
  };

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Grid container direction='row' gap={2} justifyContent='flex-end'>
        <Grid item>
          <Typography variant='h4'>Upload Topside Adjustments</Typography>
          <Typography variant='body1'>
            Make topside adjustments to the <b>{report_name}</b> for{' '}
            <b>Entity ID {property_code}</b>
          </Typography>
        </Grid>
        <Grid item xs='auto' sx={{ marginTop: -1 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          1. Download the topside adjustment template&nbsp;
          <button
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              font: 'inherit',
              color: '#dd0a5c',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={downloadTSATemplate}
          >
            here
          </button>
          .
        </ListItem>
        <ListItem>
          2. Add data to the template for entry rows that need adjustments.
        </ListItem>
        <ListItem>3. Select 'Continue' to upload the adjusted file.</ListItem>
      </List>
      <Grid container direction='row' gap={2} justifyContent='flex-end'>
        <Grid item>
          <Button
            size='medium'
            variant='outlined'
            color='primary'
            onClick={onClose}
            sx={{
              border: 'none',
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            size='medium'
            variant='contained'
            color='primary'
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
