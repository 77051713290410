import {
  GraphQLReturn,
  Writeable,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { object, string, withDefault } from '@recoiljs/refine';
import { graphql } from 'relay-runtime';
import { SubmissionsRoute } from '../../routes';
import {
  recoilSubmissionBatchesQuery,
  recoilSubmissionBatchesQuery$data,
  sys_submissions_bool_exp,
  sys_submissions_order_by,
} from './__generated__/recoilSubmissionBatchesQuery.graphql';
import { recoilGetAllUnresolvedRemindersCountSubscription } from './__generated__/recoilGetAllUnresolvedRemindersCountSubscription.graphql';

export const searchSelector = SubmissionsRoute.searchParamsSelectorFamily({
  key: 'search',
  refine: withDefault(string(), ''),
});

const SubmissionBatchesStateRefine = withDefault(
  object({
    sortBy: string(),
    sortDirection: string(),
  }),
  {
    sortBy: 'created_at_datetime',
    sortDirection: 'desc',
  }
);

export const submissionBatchesState =
  SubmissionsRoute.searchParamsSelectorFamily({
    key: 'submissionBatchesState',
    refine: SubmissionBatchesStateRefine,
  });

export type SubmissionBatchResponse = recoilSubmissionBatchesQuery$data;
export type SubmissionBatchItem = Writeable<
  recoilSubmissionBatchesQuery$data['sys_submissions'][0]
>;

export const getSubmissionBatches = graphQLSelector({
  query: graphql`
    query recoilSubmissionBatchesQuery(
      $limit: Int
      $offset: Int
      $where: sys_submissions_bool_exp!
      $order_by: [sys_submissions_order_by!] = { created_at_datetime: desc }
    ) {
      sys_submissions(
        limit: $limit
        offset: $offset
        where: $where
        order_by: $order_by
      ) {
        is_closed
        due_date
        reporting_period_start_date
        reporting_period_end_date
        owner_id
        submission_id
        submission_type_id
        submission_name
        closed_at_datetime
        created_at_datetime
        updated_at_datetime
        submission_type {
          submission_type_label
          submission_type_id
          created_at_datetime
        }
        provider {
          provider_id
          provider_name
        }
        not_started: property_batches_aggregate(
          where: { property_batch_stage_id: { _eq: 10 } }
        ) {
          aggregate {
            count
          }
        }
        in_progress: property_batches_aggregate(
          where: { property_batch_stage_id: { _in: [20, 21] } }
        ) {
          aggregate {
            count
          }
        }
        submitted: property_batches_aggregate(
          where: {
            property_batch_stage_id: { _gte: 30 }
            _and: {
              property: {
                property_type: { property_type_slug: { _neq: "investment" } }
              }
            }
          }
        ) {
          aggregate {
            count
          }
        }

        property_batches_aggregate(
          where: {
            property: {
              property_type: { property_type_slug: { _neq: "investment" } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        property_batches {
          is_approved
          comments_count: comments_aggregate {
            aggregate {
              count
            }
          }
          reminders_count: reminders_aggregate(
            where: { is_resolved: { _eq: false } }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilSubmissionBatchesQuery>,
  mapVariables:
    ({
      is_closed,
      provider_id,
      pageSize,
      pageIndex,
      value,
      order_by,
    }: {
      is_closed: boolean;
      pageSize: number;
      pageIndex: number;
      value?: string;
      provider_id?: number;
      order_by?: sys_submissions_order_by[];
    }) =>
    ({ get }) => {
      let orderBy = order_by;
      if (order_by === undefined) {
        const { sortBy, sortDirection } = get(submissionBatchesState);
        orderBy = [{ [sortBy]: sortDirection }];
      }

      const propertyCount = value === '' ? Number.NaN : Number(value);

      const isSearchNumber = !!propertyCount || !isNaN(propertyCount);

      const _and: sys_submissions_bool_exp[] = [];
      _and.push({
        is_closed: { _eq: is_closed },
      });

      if (provider_id) {
        _and.push({
          provider_id: { _eq: provider_id },
        });
      }

      const _or: sys_submissions_bool_exp[] = [];

      if (value) {
        _or.push({ submission_name: { _iregex: value } });
        _or.push({
          submission_type: {
            submission_type_label: { _iregex: value },
          },
        });
      }

      if (isSearchNumber) {
        _or.push({
          property_batches_aggregate: {
            count: { predicate: { _eq: propertyCount } },
          },
        });
      }

      if (_or.length) {
        _and.push({ _or });
      }

      const where: sys_submissions_bool_exp = {
        _and,
      };

      return {
        limit: is_closed ? pageSize : null,
        offset: is_closed ? pageIndex * pageSize : null,
        where,
        order_by: orderBy,
      };
    },
  mapResponse: (response) => response.sys_submissions,
  swr: (o, n) => o.is_closed === n.is_closed,
});

export const getAllUnresolvedReminders = graphQLSelector({
  mapResponse: (response) => response.sys_reminders,
  query: graphql`
    subscription recoilGetAllUnresolvedRemindersCountSubscription {
      sys_reminders(
        where: {
          is_resolved: { _eq: false }
          submission: { is_closed: { _eq: true } }
        }
      ) {
        property {
          entity_id
        }
        property_batch {
          submission {
            submission_type {
              submission_type_label
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetAllUnresolvedRemindersCountSubscription>,
});
