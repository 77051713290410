import React from 'react';

import {
  Chip,
  ChipProps,
  styled,
  SvgIconComponent,
  WarningIcon,
  RefreshIcon,
  InfoIcon,
  CheckIcon,
} from '@cherre-frontend/ui';
import { themeV5 } from 'src/const/themev5';

const statusMap = {
  'Not Started': {
    label: 'Not Started',
    bgColor: themeV5.palette.grey[100],
    textColor: themeV5.palette.grey[700],
  },
  Open: {
    label: 'Open',
    bgColor: themeV5.accents.deals.main,
    textColor: themeV5.palette.common.white,
  },
  'Past Due': {
    label: 'Past Due',
    bgColor: themeV5.accents.error.main,
    textColor: themeV5.palette.common.white,
    icon: WarningIcon,
  },
  Stuck: {
    label: 'Stuck',
    bgColor: themeV5.accents.warning.main,
    textColor: themeV5.palette.common.white,
    icon: InfoIcon,
  },
  'In progress': {
    label: 'In progress',
    bgColor: themeV5.accents.info.light,
    textColor: themeV5.accents.info.main,
    icon: RefreshIcon,
  },
  Approved: {
    label: 'Approved',
    bgColor: themeV5.accents.positive.main,
    textColor: themeV5.palette.common.white,
    icon: CheckIcon,
  },
  Closed: {
    label: 'Closed',
    bgColor: themeV5.accents.positive.light,
    textColor: themeV5.accents.positive.main,
  },
} as const;

export type StatusCode = keyof typeof statusMap;

export const statusCodeFromStr = (str: string | null): StatusCode | undefined =>
  str && str in statusMap ? (str as StatusCode) : undefined;

type BatchStatusChipStyledProps = {
  bgColor: string;
  textColor: string;
  icon?: React.ReactElement;
} & ChipProps;

const BatchStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'textColor' !== prop && 'bgColor' !== prop,
})<BatchStatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type BatchStatusChipProps = {
  statusCode: StatusCode;
};

export const BatchStatusChip: React.FC<BatchStatusChipProps> = ({
  statusCode,
}) => {
  const status = statusMap[statusCode];

  if (!status) {
    throw new Error(`Invalid status code ${statusCode}`);
  }

  const Icon: SvgIconComponent | undefined =
    'icon' in status ? status.icon : undefined;

  return (
    <BatchStatusChipStyled
      label={status.label}
      icon={
        Icon ? (
          <Icon style={{ color: status.textColor, width: 12, height: 12 }} />
        ) : undefined
      }
      bgColor={status.bgColor}
      textColor={status.textColor}
    />
  );
};

BatchStatusChip.displayName = 'BatchStatusChip';
