import { styled, Container } from '@cherre-frontend/ui';

export const TitleContainer = styled(Container)`
  display: flex;
  gap: 10px;
  padding: 0;
  flex: 3;
  align-items: center;
`;
export const ProgressContainer = styled(Container)`
  display: flex;
  padding: 0;
  align-items: center;
  flex: 2;
  gap: 10px;
  justify-content: flex-end;
`;

export const BatchListContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
  max-width: 100% !important;
  padding: 0 !important;
`;
