import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { getMappingSetsSubscription } from './__generated__/getMappingSetsSubscription.graphql';
import { getMappingSetsTotalSubscription } from './__generated__/getMappingSetsTotalSubscription.graphql';
import { graphql } from 'react-relay';
import { mappingSetsTableSearch, mappingSetsTableState } from '../recoil';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import { MRT_SortingState } from 'material-react-table';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

export const getMappingSetsTotal = graphQLSelector({
  query: graphql`
    subscription getMappingSetsTotalSubscription(
      $id: Int
      $search: String = "%"
    ) {
      total: sys_mapping_sets_aggregate(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            {
              _or: [
                { mapping_set_description: { _ilike: $search } }
                {
                  mapping_field: {
                    mapping_field_description: { _ilike: $search }
                    mapping_field_name: { _ilike: $search }
                  }
                }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getMappingSetsTotalSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(mappingSetsTableSearch);

      return {
        search: `%${search ?? ''}%`,
        id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
      };
    },
  mapResponse: (resp) => resp.total,
});

export const getMappingSets = graphQLSelector({
  query: graphql`
    subscription getMappingSetsSubscription(
      $id: Int
      $search: String = "%"
      $order_by: [sys_mapping_sets_order_by!] = {}
      $limit: Int = 25
      $offset: Int = 0
    ) {
      sys_mapping_sets(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            { is_active: { _eq: true } }
            {
              _or: [
                { mapping_set_description: { _ilike: $search } }
                {
                  mapping_field: {
                    mapping_field_description: { _ilike: $search }
                    mapping_field_name: { _ilike: $search }
                  }
                }
              ]
            }
          ]
        }
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        mapping_set_id
        mapping_set_description
        mapping_field {
          mapping_field_name
          mapping_field_description
          is_single_mapping_set
        }
        organization {
          organization_label
        }
        provider_id
        provider {
          provider_name
        }
        updated_at_datetime
        mapping_set_values_aggregate {
          aggregate {
            max {
              updated_at_datetime
            }
          }
        }
      }
    }
  ` as GraphQLReturn<getMappingSetsSubscription>,
  mapResponse: (resp) => {
    return resp.sys_mapping_sets;
  },
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(mappingSetsTableSearch);
      const tableState = get(mappingSetsTableState);

      return {
        id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
        search: `%${search ?? ''}%`,
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
      };
    },
});
