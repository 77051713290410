import { Grid, Skeleton, styled } from '@cherre-frontend/ui';
import React from 'react';

const AvatarSkeleton = styled(Skeleton)`
  width: 36px;
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const UserRowSkeleton: React.FC = () => {
  return (
    <Grid display='flex' direction='row'>
      <AvatarSkeleton variant='circular' />
      <Grid>
        <Skeleton
          variant='rectangular'
          width={160}
          height={16}
          sx={{ marginBottom: 1 }}
        />
        <Skeleton variant='rectangular' width={100} height={16} />
      </Grid>
    </Grid>
  );
};

export default UserRowSkeleton;
