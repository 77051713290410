/**
 * @generated SignedSource<<c7a44eac593fc612d1257b76b0dad143>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _sys_property_bulk_upload_input = {
  file_reference_source: string;
  file_source: string;
};
export type useUploadPropertyMutation$variables = {
  input: _sys_property_bulk_upload_input;
};
export type useUploadPropertyMutation$data = {
  readonly _sys_property_bulk_upload: {
    readonly added_property_codes: ReadonlyArray<string> | null;
    readonly duplicated_count: number;
    readonly ignored_property_codes: ReadonlyArray<string> | null;
  } | null;
};
export type useUploadPropertyMutation = {
  response: useUploadPropertyMutation$data;
  variables: useUploadPropertyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "_sys_property_bulk_upload_output",
    "kind": "LinkedField",
    "name": "_sys_property_bulk_upload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "added_property_codes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ignored_property_codes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duplicated_count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadPropertyMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadPropertyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9fee9b08cfd1b2dc7ce9cc03165a93cc",
    "id": null,
    "metadata": {},
    "name": "useUploadPropertyMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadPropertyMutation(\n  $input: _sys_property_bulk_upload_input!\n) {\n  _sys_property_bulk_upload(input: $input) {\n    added_property_codes\n    ignored_property_codes\n    duplicated_count\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3bac5dd63db8ac3643913a858ba11b7";

export default node;
