import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Button,
  FileFailedIcon,
  FileOkIcon,
  Panel,
  Tabs,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from 'src/products/data-submission-portal/components';
import { DatasetsTable } from 'src/products/data-submission-portal/components/DatasetsTable';
import { PackageReportsTable } from 'src/products/data-submission-portal/components/PackageReportsTable';
import { usePersonaAwarePush } from 'src/products/data-submission-portal/hooks/usePersonaAwarePush';
import {
  BatchStatusChip,
  statusCodeFromStr,
} from '../../../batch-management/Panels/SubmissionsPanel/components/BatchStatus';
import { showSubmissionDetailsLink } from '../../components/MonitorSubmissionsTable';
import {
  Batch,
  getPropertyBatches,
  modalSearchState,
  packageReportModalState,
  reportSelectors,
  reportSlugModalState,
  submissionOverviewDialogPropertyBatchIdState,
  submissionOverviewDialogTabState,
} from '../../recoil';
import ContactsTab from './ContactsTab';
import { getStageLabelFromId } from '../../utils';

const formatSubtitle = (batch?: Batch) => {
  if (!batch?.property_batch) {
    return '';
  }
  const parts1 = [`Entity ID: ${batch.property_batch.property.entity_id}`];
  if (batch.property_batch.property.property_name) {
    parts1.push(batch.property_batch.property.property_name);
  } else if (batch.property_batch.property.address) {
    parts1.push(batch.property_batch.property.address);
  }
  if (batch.property_batch.submission.reporting_period === 'monthly') {
    parts1.push(
      `${moment(
        batch.property_batch.submission.reporting_period_start_date
      ).format('MMMM YYYY')} Period`
    );
  }
  if (batch.property_batch.submission.reporting_period === 'quarterly') {
    parts1.push(
      `Q${moment(
        batch.property_batch.submission.reporting_period_start_date
      ).format('Q YYYY')} Period`
    );
  }
  const parts2 = [
    `${batch.datasets_uploaded ?? 0}/${batch.datasets_total ?? 0} Datasets`,
    `${batch.validations_failed ?? 0} Failed Validations`,
    `${
      batch.property_batch.reviewedPackageReports.aggregate?.count ?? 0
    } Reviewed Reports`,
  ];
  const stage = getStageLabelFromId(
    batch.property_batch.last_stage[0]?.property_batch_stage_id ?? 0
  );
  return [
    parts1.join(' • '),
    <br />,
    parts2.join(' • '),
    <br />,
    `Submission Stage: ${stage}`,
  ];
};

const statusCode = (batch?: Batch) => {
  return (
    statusCodeFromStr(
      (batch?.submission_status === 'Open'
        ? 'In progress'
        : batch?.submission_status) ?? null
    ) ?? 'Not Started'
  );
};

export const SubmissionOverviewDialog: React.FC = () => {
  const propertyBatches = useCherreValue(getPropertyBatches());
  const [submissionId, setId] = useCherreState(
    submissionOverviewDialogPropertyBatchIdState
  );

  const [tabValue, setTabValue] = useCherreState(
    submissionOverviewDialogTabState
  );

  const selectedBatch = propertyBatches?.find(
    (batch) => batch.property_batch_id === submissionId
  );

  const close = () => {
    setId(undefined);
    setTabValue(0);
  };

  const push = usePersonaAwarePush();

  const onClickViewSubmissionValidationReport = () =>
    selectedBatch &&
    push(
      `/dsp/{{persona}}/batch-validations/${selectedBatch.property_batch_id}`
    );

  const onClickViewSubmissionBatchDetail = () => {
    if (!selectedBatch) {
      return;
    }
    const url = showSubmissionDetailsLink(
      selectedBatch.property_batch.submission.submission_id,
      selectedBatch.property_batch.property_batch_id
    );
    push(url);
  };
  return (
    <Dialog
      open={submissionId !== undefined}
      onClose={() => close()}
      width='890px'
      height='620px'
    >
      <DialogHeader
        title={
          <>
            Submission Summary{' '}
            <BatchStatusChip statusCode={statusCode(selectedBatch)} />
          </>
        }
        subtitle={formatSubtitle(selectedBatch)}
        subtitleSize='small'
        onClose={() => close()}
      />
      <DialogContent minHeight='480px' rowSpacing='20px'>
        {selectedBatch && (
          <Tabs
            onChange={(_, newValue) => setTabValue(newValue)}
            value={tabValue}
            actions={
              selectedBatch.property_batch.is_valid !== null &&
              tabValue !== 2 && (
                <Button
                  onClick={onClickViewSubmissionValidationReport}
                  color={
                    selectedBatch.property_batch.is_valid ? 'positive' : 'error'
                  }
                  startIcon={
                    selectedBatch.property_batch.is_valid ? (
                      <FileOkIcon />
                    ) : (
                      <FileFailedIcon />
                    )
                  }
                >
                  Submission Validation Report
                </Button>
              )
            }
            tabs={[
              { label: 'Datasets', value: 0 },
              {
                label: 'Package Reports',
                value: 1,
                disabled:
                  selectedBatch.datasets_total !==
                  selectedBatch.datasets_uploaded,
              },
              {
                label: 'Contacts',
                value: 2,
              },
            ]}
            tabPanels={[
              {
                value: 0,
                panel: (
                  <DatasetsTable
                    property_batch_id={selectedBatch.property_batch_id}
                  />
                ),
              },
              {
                value: 1,
                panel: (
                  <PackageReportsTable
                    disableLastRowBorder
                    property_batch_id={selectedBatch.property_batch_id}
                    modalState={packageReportModalState}
                    modalSearchState={modalSearchState}
                    reportSelectors={reportSelectors}
                    reportSlugModalState={reportSlugModalState}
                  />
                ),
              },
              {
                value: 2,
                panel: (
                  <Panel
                    id='monitoring-submissions-contact'
                    disableParent={false}
                    style={{ height: '100%' }}
                  >
                    <ContactsTab />
                  </Panel>
                ),
              },
            ]}
          />
        )}
      </DialogContent>
      {tabValue !== 2 && (
        <DialogActions>
          <Button onClick={onClickViewSubmissionBatchDetail}>
            View Submission Batch Detail
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
