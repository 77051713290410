import React from 'react';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import {
  allCardsInPageSelectedState,
  selectedCardsState,
  selectableCardsState,
  allCardsSelectedState,
} from './recoil';
import { Alert, Button, Typography, styled, alpha } from '@cherre-frontend/ui';

const StyledAlert = styled(Alert)`
  padding: 6px;
  grid-column: span 2;
  background: ${({ theme }) => alpha(theme.palette.primary.main, 0.1)};

  .MuiAlert-message {
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 500;
`;

export const SelectAllAlert = () => {
  const [selectedCards, setSelectedCards] = useCherreState(selectedCardsState);

  const selectableCards = useCherreValue(selectableCardsState);

  const allCardsSelected = useCherreValue(allCardsSelectedState);
  const allCardsInPageSelected = useCherreValue(allCardsInPageSelectedState);

  const showSelectAllMessage = allCardsInPageSelected && !allCardsSelected;

  const onClickSelectAll = () => {
    setSelectedCards(selectableCards ?? []);
  };

  if (!showSelectAllMessage) {
    return null;
  }

  return (
    <StyledAlert icon={false}>
      <Typography onClick={() => {}} color='grey.900' variant='body1'>
        <b>{selectedCards?.length}</b> property submissions on this page are
        selected.
      </Typography>
      <StyledButton onClick={onClickSelectAll} variant='text' color='primary'>
        Select all <b>{selectableCards?.length}</b> property submissions in this
        batch
      </StyledButton>
    </StyledAlert>
  );
};
