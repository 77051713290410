import {
  Panel as BasePanel,
  styled,
  TablePagination as BaseTablePagination,
} from '@cherre-frontend/ui';

export const Panel = styled(BasePanel)`
  flex: 1;
`;

export const TablePagination = styled(BaseTablePagination)<{
  component: React.ElementType;
}>`
  margin-top: auto;
`;

export const PropertySubmissions = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 12px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  h5,
  .MuiFormControl-root {
    margin: 0;
  }

  .MuiInputBase-root {
    background: ${({ theme }) => theme.palette.grey[50]};
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  h5 {
    color: ${({ theme }) => theme.palette.grey[900]};
    font-size: 20px;
    font-weight: bold;
    grid-column: 1;
  }
`;
