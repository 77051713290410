/**
 * @generated SignedSource<<d6c4d8ebfc5b939a188ee1276c67ccd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getDuplicatedAccountTreeQuery$variables = {
  name?: string | null;
};
export type getDuplicatedAccountTreeQuery$data = {
  readonly sys_organization_account_trees: ReadonlyArray<{
    readonly organization_account_tree_id: number;
  }>;
};
export type getDuplicatedAccountTreeQuery = {
  response: getDuplicatedAccountTreeQuery$data;
  variables: getDuplicatedAccountTreeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "account_tree_name"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organization_account_trees",
    "kind": "LinkedField",
    "name": "sys_organization_account_trees",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization_account_tree_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getDuplicatedAccountTreeQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getDuplicatedAccountTreeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a24ff6aa17c4a5555c57b6c7c0815aa",
    "id": null,
    "metadata": {},
    "name": "getDuplicatedAccountTreeQuery",
    "operationKind": "query",
    "text": "query getDuplicatedAccountTreeQuery(\n  $name: String\n) {\n  sys_organization_account_trees(where: {account_tree_name: {_ilike: $name}}, limit: 1) {\n    organization_account_tree_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d97daf26115522fcfd0ccbab1f87a952";

export default node;
