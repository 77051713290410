import React from 'react';
import { GroupedPropertyBatchAccordionProps } from '../types';
import { PropertyBatchBaseAccordion } from '../PropertyBatchAccordion.base';
import { useTabsConfigView } from './useTabsConfigView';
import { getPreparers } from '../recoil';
import { useCherreValue } from '@cherre-frontend/data-fetching';

export const GroupPropertyBatchViewAccordion: React.FC<
  GroupedPropertyBatchAccordionProps
> = ({ propertyBatches, investmentPropertyBatch }) => {
  const preparers = useCherreValue(
    getPreparers({
      user_ids: propertyBatches.flatMap((pb) =>
        pb.properties_flattened_union.properties_roles_users.map(
          (p) => p.user_id
        )
      ),
    })
  );

  const preparersText = preparers?.sys_users
    ?.map(
      ({ sakura_user }) =>
        `${sakura_user?.first_name} ${sakura_user?.last_name}`
    )
    .join(', ');

  const hasPreparers = !!preparers?.sys_users?.length;

  const propertiesApproved = propertyBatches.filter(
    (p) => p.is_approved
  ).length;

  return (
    <>
      <PropertyBatchBaseAccordion
        propertyBatches={propertyBatches}
        investmentPropertyBatch={investmentPropertyBatch}
        accordionSubtitleComponent={
          <>
            {hasPreparers ? <>Preparer: {preparersText} • </> : null}
            {propertiesApproved}/{propertyBatches.length} Propert
            {propertiesApproved === 1 ? 'y' : 'ies'} Approved
          </>
        }
        useTabsConfig={useTabsConfigView}
      />
    </>
  );
};
