import React, { useEffect } from 'react';
import { TableConfig, createReportTableStateSelectors } from './recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRecoilScope } from '@cherre-frontend/data-fetching';
import {
  ReportViewType,
  useReportViewTypeSelector,
} from './ReportViewTypeSelector';

export type ReportContextType = {
  getArgs: (
    report_slug: string,
    table_config: Partial<TableConfig>
  ) => Record<string, any> | undefined;
  getWhere: (
    report_slug: string,
    table_config: TableConfig
  ) => Record<string, any> | undefined;
  getParams?: ({
    report_slug,
    report_view_type,
  }: {
    report_slug: string;
    report_view_type?: ReportViewType;
  }) => Record<string, any> | undefined;
  onReportOpen?: (report_slug: string) => void;
  selectors: ReturnType<typeof createReportTableStateSelectors>;
};

export const reportContext = React.createContext<ReportContextType | null>(
  null
);

export const useReportContext = () => {
  const ctx = React.useContext(reportContext);
  if (!ctx) {
    throw new Error(`useReportContext was used outside of a ReportModal`);
  }
  return ctx;
};

export const useTableArgs = (
  report_slug: string,
  table_config: TableConfig
) => {
  const ctx = useReportContext();
  return ctx.getArgs(report_slug, table_config);
};

export const useTableWhere = (
  report_slug: string,
  table_config: TableConfig
) => {
  const ctx = useReportContext();
  return ctx.getWhere(report_slug, table_config);
};

export const useReportParams = (report_slug: string) => {
  const ctx = useReportContext();

  const [reportViewType] = useReportViewTypeSelector();

  return ctx.getParams?.({
    report_slug,
    report_view_type: reportViewType ?? undefined,
  });
};

export const usePagination = () => {
  const ctx = useReportContext();
  return useRecoilState(ctx.selectors.paginationState);
};

export const useTableSorting = (table_config: TableConfig) => {
  const ctx = useReportContext();
  const scope = useRecoilScope();
  return useRecoilState(ctx.selectors.sortingState(table_config)(scope));
};

export const useTableSearch = () => {
  const ctx = useReportContext();
  return useRecoilValue(ctx.selectors.searchState);
};

export const useOnReportOpen = (report_slug: string) => {
  const ctx = useReportContext();
  useEffect(() => ctx.onReportOpen?.(report_slug), [report_slug]);
};
