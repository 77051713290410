import {
  useCherreState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  SearchInput,
  PageContainer,
  PageContent,
  Panel,
  MultiFilter,
} from '@cherre-frontend/ui';
import React from 'react';
import MasterFilesDetailHeader from './components/Header';
import MasterFilesDetailTable from './components/MasterFilesDetailTable';
import { getCodesList, getNamesList } from './queries/getCOADetail';
import {
  masterFileDetailPageTableSearch,
  masterFileDetailsHasData,
  masterFileDetailTableFiltersState,
} from './recoil';

const MasterFileDetailPage: React.FC = () => {
  const hasData = useCherreValue(masterFileDetailsHasData);

  const [filters, setFilters] = useCherreState(
    masterFileDetailTableFiltersState
  );
  const [search, , setSearch] = useCherreStateDebounced(
    masterFileDetailPageTableSearch
  );
  const codes = useCherreValue(getCodesList());
  const names = useCherreValue(getNamesList());

  return (
    <>
      <MasterFilesDetailHeader />
      <PageContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Panel id='masterFileDetailSearch'>
            <SearchInput
              defaultValue={search ?? ''}
              sx={{ width: '200px' }}
              //onBlur={handleFocusOut}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Panel>
          {hasData && (
            <>
              <MultiFilter
                options={
                  codes?.map((code) => ({ label: code, value: code })) ?? []
                }
                selected={filters?.account_code ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    account_code: selected,
                  }))
                }
                label='Account Code'
              />
              <MultiFilter
                options={
                  names?.map((name) => ({ label: name, value: name })) ?? []
                }
                selected={filters?.account_description ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    account_description: selected,
                  }))
                }
                label='Account Description'
              />
            </>
          )}
        </Box>

        <Panel id='masterFileDetails'>
          <MasterFilesDetailTable />
        </Panel>
      </PageContent>
    </>
  );
};

const MasterFileDetailWrapper: React.FC = () => {
  return (
    <PageContainer id='MasterFileDetailsPage'>
      <MasterFileDetailPage />
    </PageContainer>
  );
};

MasterFileDetailWrapper.displayName = 'MasterFileDetailWrapper';

export default MasterFileDetailWrapper;
