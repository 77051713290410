import React, { useMemo } from 'react';
import { styled, Avatar, Typography } from '@cherre-frontend/ui';
import { $reassignUserDetails } from 'src/products/data-submission-portal/components/ReassignUserModal/recoil';
import { useCherreValue } from '@cherre-frontend/data-fetching';

const Container = styled('div')`
  display: grid;
  grid-template-areas: 'avatar . name' 'avatar . email' '. . .' 'org org org';
  grid-template-columns: 36px 10px 1fr;
  grid-template-rows: 18px 18px 10px 18px;
`;

const StyledAvatar = styled(Avatar)`
  grid-area: avatar;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
  width: 36px;
  height: 36px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Name = styled(Typography)`
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

const Email = styled(Typography)`
  grid-area: email;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

const CurrentProvider = styled(Typography)`
  grid-area: org;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
  letter-spacing: 0.14px;
`;

export type UserCardProps = {
  userId: number;
};

const UserCard: React.FC<UserCardProps> = ({ userId }) => {
  const userDetails = useCherreValue($reassignUserDetails(userId));
  const fullName = `${userDetails?.first_name} ${userDetails?.last_name}`;
  const email = userDetails?.email;
  const provider = userDetails?.sys_user?.provider?.provider_name || 'none';
  const initials = useMemo(() => {
    const first_letter = userDetails?.first_name[0];
    const second_letter = userDetails?.last_name[0];
    return `${first_letter}${second_letter}`.toUpperCase();
  }, [userDetails]);
  return (
    <Container>
      <StyledAvatar className='suspend'>{initials}</StyledAvatar>
      <Name className='suspend'>{fullName}</Name>
      <Email className='suspend'>{email}</Email>
      <CurrentProvider className='suspend'>
        Current Service Provider: <b>{provider}</b>
      </CurrentProvider>
    </Container>
  );
};

export default UserCard;
