import React from 'react';
import { PageContainer, Panel, SuspenseList } from '@cherre-frontend/ui';
import Header from './panels/Header';
import ProviderContainer from './panels/ProviderContainer';
import { getProviders } from './recoil/getProviders';

const ReviewPropertiesPage: React.FC = () => {
  return (
    <PageContainer id='ReviewPropertiesPage'>
      <Panel id='ReviewPropertiesHeader' behavior='fallback'>
        <Header />
      </Panel>
      <SuspenseList id='ReviewPropertiesProviderList' selector={getProviders()}>
        <ProviderContainer />
      </SuspenseList>
    </PageContainer>
  );
};

export default ReviewPropertiesPage;
