import { styled, Typography, Box, Chip as BaseChip } from '@cherre-frontend/ui';
import {
  PropertyBatchStageLabel,
  PropertyBatchStage,
} from '../../../../../__generated__/constants';
import { themeV5 } from 'src/const/themev5';

export const AccordionHeader = styled('h6')`
  font-size: 16px;
  margin: 0;
`;

export const AccordionSummary = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
`;

export const AccordionSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[700]};
`;

export const TabTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const AccordionDetails = styled(Box)`
  padding-left: 30px;
  padding-right: 30px;
`;

const chipColorMap = new Map<PropertyBatchStageLabel, [string, string]>([
  [
    PropertyBatchStage.PENDING.label,
    [themeV5.accents.warning.light, themeV5.accents.warning.main],
  ],
  [
    PropertyBatchStage.IN_PREPARATION.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    PropertyBatchStage.REVIEW_1.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    PropertyBatchStage.REVIEW_2.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    PropertyBatchStage.REVIEW_3.label,
    [themeV5.accents.info.light, themeV5.accents.info.main],
  ],
  [
    PropertyBatchStage.APPROVED.label,
    [themeV5.accents.positive.light, themeV5.accents.positive.main],
  ],
]);

export const Chip = styled(BaseChip)`
  background: ${({ label }) =>
    chipColorMap.get(label as PropertyBatchStageLabel)?.[0]};
  color: ${({ label }) =>
    chipColorMap.get(label as PropertyBatchStageLabel)?.[1]};

  .MuiChip-icon {
    color: inherit;
  }
`;

export const batchIconWrapperStyle: React.CSSProperties = {
  marginLeft: 16,
  display: 'flex',
};

export const batchIconStyle: React.CSSProperties = {
  fontSize: 24,
};

export const Line = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
  margin: 10px -15px;
  padding: 0;
`;
