/**
 * @generated SignedSource<<1491d49452b2527a22e3c26e3246ffe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getSubmissionsReportingPeriodsFilterSubscription$variables = {};
export type getSubmissionsReportingPeriodsFilterSubscription$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly reporting_period_start_date: string;
  }>;
};
export type getSubmissionsReportingPeriodsFilterSubscription = {
  response: getSubmissionsReportingPeriodsFilterSubscription$data;
  variables: getSubmissionsReportingPeriodsFilterSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "reporting_period_start_date"
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "reporting_period_start_date": "desc"
        }
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reporting_period_start_date",
        "storageKey": null
      }
    ],
    "storageKey": "sys_submissions(distinct_on:\"reporting_period_start_date\",order_by:{\"reporting_period_start_date\":\"desc\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSubmissionsReportingPeriodsFilterSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSubmissionsReportingPeriodsFilterSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c2427ff7fb3a75719fb693a5f30c9cd9",
    "id": null,
    "metadata": {},
    "name": "getSubmissionsReportingPeriodsFilterSubscription",
    "operationKind": "subscription",
    "text": "subscription getSubmissionsReportingPeriodsFilterSubscription {\n  sys_submissions(distinct_on: reporting_period_start_date, order_by: {reporting_period_start_date: desc}) {\n    reporting_period_start_date\n  }\n}\n"
  }
};
})();

(node as any).hash = "462684377b5dcdc9c0d247c3f6d42b3d";

export default node;
