import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { atom } from 'recoil';
import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';
import { recoilGetAvailablePropertiesSubmissionQuery } from './__generated__/recoilGetAvailablePropertiesSubmissionQuery.graphql';
import { recoilGetFileEntitiesQuery } from './__generated__/recoilGetFileEntitiesQuery.graphql';
import { SubmissionRoute } from 'src/products/data-submission-portal/routes';
import { MRT_RowSelectionState } from '@cherre-frontend/ui';

export const bulkUploadModalState = atom<{
  open: boolean;
  selectedDataset: string | null;
  selectedDatasetName: string | null;
  closedSelectedDataset: string | null;
  uploadedFileId: string | null;
  selectedProperties: number[];
  rowSelection: MRT_RowSelectionState;
  step: 'SELECT_DATASET' | 'SELECT_PROPERTIES' | 'PROCESSING_PROPERTIES';
}>({
  key: 'submission-batch-details/bulk-upload-modal-state',
  default: {
    open: false,
    selectedDataset: null,
    selectedDatasetName: null,
    closedSelectedDataset: null,
    selectedProperties: [],
    uploadedFileId: null,
    rowSelection: {},
    step: 'SELECT_DATASET',
  },
});

export const getAvailablePropertiesSubmission = graphQLSelector({
  query: graphql`
    query recoilGetAvailablePropertiesSubmissionQuery($submission_id: Int!) {
      sys_property_batches(where: { submission_id: { _eq: $submission_id } }) {
        property_batch_id
        property_batch_stage_id
        properties_flattened_union {
          property_id
          property_name
          entity_id
          entity_name
        }
        property_batch_datasets {
          dataset_id
          is_valid
        }
      }
    }
  ` as GraphQLReturn<recoilGetAvailablePropertiesSubmissionQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(SubmissionRoute.routeParamSelector),
  mapResponse: (resp) => {
    return resp.sys_property_batches.map((p) => ({
      ...p,
      enabled: [
        PropertyBatchStage.PENDING.id,
        PropertyBatchStage.IN_PREPARATION.id,
        PropertyBatchStage.REJECTED.id as number,
      ].includes(p.property_batch_stage_id),
    }));
  },
});

export const getFileEntities = graphQLSelector({
  query: graphql`
    query recoilGetFileEntitiesQuery(
      $file_reference_source: String!
      $file_source: String!
    ) {
      _sys_ingestion_get_entities(
        params: {
          file_reference_source: $file_reference_source
          file_source: $file_source
        }
      ) {
        entity
        count
      }
    }
  ` as GraphQLReturn<recoilGetFileEntitiesQuery>,
  mapResponse: (resp) => resp._sys_ingestion_get_entities,
});
