import { searchParamsSelector } from '@cherre-frontend/data-fetching';
import {
  nullable,
  object,
  string,
  withDefault,
  array,
  number,
} from '@recoiljs/refine';

export const ingestFlowState = searchParamsSelector({
  key: 'ingest-flow-state',
  refine: withDefault(
    object({
      signedUrl: nullable(string()),
      fileId: nullable(string()),
      selectedSheet: nullable(string()),
      activeStepKey: withDefault(string(), 'upload'),
      selectedHeaderRows: nullable(array(number())),
    }),
    {
      signedUrl: null,
      fileId: null,
      selectedSheet: null,
      activeStepKey: 'upload',
      selectedHeaderRows: null,
    }
  ),
  routes: ['/**'],
});
