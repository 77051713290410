import React from 'react';
import {
  useCherrePartialState,
  useCherreSetState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Grid,
  PageContent,
  SearchInput,
  styled,
  Panel,
} from '@cherre-frontend/ui';
import { AddUserButton } from './components/AddUserButton';
import { UsersPanelTable } from './components/UserStatusTable';
import {
  providerUsersTableSearch,
  selectedUserState,
  sendEmailDialogOpenState,
} from './recoil';
import DisableUserModal from './components/DisableUserModal';
import ResendEmail from 'src/components/modals/ResendEmail';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export type ProviderUsersPanelProps = {
  search?: string;
};

const UsersTab: React.FC<ProviderUsersPanelProps> = () => {
  const [search, , setSearch] = useCherreStateDebounced(
    providerUsersTableSearch
  );

  const emailModalOpenState = useCherreValue(sendEmailDialogOpenState);
  const setSendEmailModalState = useCherreSetState(sendEmailDialogOpenState);
  const selectedUser = useCherrePartialState(selectedUserState);

  const emailProps = {
    open: emailModalOpenState ?? false,
    onRequestClose: () => setSendEmailModalState(false),
    data: {
      refresh: true,
      row: {
        firstName: selectedUser[0]?.user_first_name,
        lastName: selectedUser[0]?.user_last_name,
        email: selectedUser[0]?.user_email,
        id: selectedUser[0]?.user_id,
      },
    },
  };

  return (
    <PageContent style={{ height: '100%' }}>
      <DisableUserModal />
      <ResendEmail {...emailProps} />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        gap={10}
        style={{ marginBottom: '20px' }}
      >
        <SearchInput
          value={search ?? ''}
          onChange={(e) => setSearch(e.target.value)}
        />
        <AddUserButton />
      </Grid>
      <PanelStyled id='users'>
        <UsersPanelTable search={search} />
      </PanelStyled>
    </PageContent>
  );
};

export default UsersTab;
