import { Chip, styled } from '@cherre-frontend/ui';

export const SubmissionSettingsChip = styled(Chip)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  padding: 0 7px;
  background-color: ${({ theme }) => theme.palette.primary.main};

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
    color: white;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;
