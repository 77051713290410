import React, { useMemo } from 'react';
import {
  Box,
  Button,
  DownloadFileIcon,
  Grid,
  PageHeader,
  styled,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import moment from 'moment';
import { AccountGroupingDetailRoute } from 'src/products/data-submission-portal/routes';
import { snakeCase } from 'lodash';
import { getAccountTree } from '../queries/getAccountTree';
import { getAccountTreeLatestUpdate } from '../queries/getAccountTreeDetail';
import { useDownloadAccountTreeFile } from 'src/products/data-submission-portal/hooks/useDownloadAccountTree';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

const AccountGroupingDetailHeader: React.FC = () => {
  const theme = useTheme();

  const downloadAccountTreeFile = useDownloadAccountTreeFile();

  const accountTree = useCherreValue(getAccountTree());

  const latestUpdate = useCherreValue(getAccountTreeLatestUpdate());

  const lastUpdatedValue = useMemo(() => {
    if (accountTree) {
      if (latestUpdate) {
        const masterLastUpdated = moment(accountTree.updated_at_datetime);
        const latestDetailUpdate = moment(latestUpdate);
        return masterLastUpdated.isAfter(latestDetailUpdate)
          ? accountTree.updated_at_datetime
          : latestUpdate;
      }
      return accountTree.updated_at_datetime;
    }
  }, [accountTree, latestUpdate]);

  const accountGroupingId = useCherreValue(
    AccountGroupingDetailRoute.routeParamSelector
  )?.account_grouping_id;

  return (
    <HeaderGrid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          style={{ height: '48px', alignSelf: 'center' }}
          component={Link}
          to='/dsp/master-files'
        >
          <ArrowBack style={{ fill: theme.palette.grey[900] }} />
        </IconButton>
        <PageHeader
          title={'Account Groupings'}
          subtitle={
            <span>
              {accountTree?.account_tree_name} &bull; Last Update:{' '}
              {lastUpdatedValue
                ? moment(lastUpdatedValue).format('MMM D, YYYY')
                : ''}
            </span>
          }
        />
      </Box>
      <Button
        sx={{ height: '40px' }}
        onClick={() =>
          downloadAccountTreeFile(
            accountGroupingId,
            `${snakeCase(
              accountTree?.organization?.organization_label
            )}_Account_Grouping.xlsx`
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <DownloadFileIcon sx={{ width: '15px', height: '15px' }} />
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            Download File
          </Typography>
        </Box>
      </Button>
    </HeaderGrid>
  );
};

export default AccountGroupingDetailHeader;
