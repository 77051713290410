import React from 'react';
import { usePersona } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { TabTitle } from './styles';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { Tabs } from '@cherre-frontend/ui';
import LockIcon from '@material-ui/icons/Lock';

import { PropertyBatch } from '../types';
import { TabsConfig } from './types';
import { $packageReportState } from 'src/products/data-submission-portal/components/PackageReportsTable/recoil';

export const PropertyBatchTab: React.FC<{ tabConfig: TabsConfig }> = ({
  tabConfig,
}) => {
  return (
    <Tabs
      onChange={tabConfig.tabOnChange}
      value={tabConfig.tabValue}
      disabledIcon={<LockIcon style={{ color: '#9E9E9E' }} />}
      tabs={tabConfig.tabs}
      tabPanels={tabConfig.tabPanels}
      actions={tabConfig.tabActionsComponent}
    />
  );
};

export const PropertyBatchTabTitle: React.FC<{
  propertyBatch: PropertyBatch;
}> = ({ propertyBatch }) => {
  const persona = usePersona();

  const package_reports_state = useCherreValue(
    $packageReportState({
      persona,
      property_batch_id: propertyBatch.property_batch_id,
    })
  );

  const {
    property_batch_datasets: datasets,
    property_batch_validations: validations,
  } = propertyBatch;

  const failedValidations = validations.filter((v) => !v.is_valid).length;
  const packagesReviewed = package_reports_state?.length ?? 0;

  return (
    <TabTitle variant='body1'>
      <b>Source ID {propertyBatch.properties_flattened_union.entity_id}</b> •{' '}
      {propertyBatch.datasets_uploaded.aggregate?.count}/{datasets.length}{' '}
      Dataset
      {datasets.length !== 1 && 's'} • {failedValidations} Failed Validation
      {failedValidations !== 1 && 's'} • {packagesReviewed} Packages Reviewed
    </TabTitle>
  );
};
