import { useAppContext } from '@cherre-frontend/core';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Button,
  Container,
  FormEntry,
  Grid,
  PageContainer,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@cherre-frontend/ui';
import IconButton from '@material-ui/core/IconButton';
import grey from '@material-ui/core/colors/grey';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getProviderOrganization } from '../provider-detail/queries/getProviderOrganization';
import { getProviderUserById } from './queries/getProviderUserById';
import {
  editProviderFirstNameUserFormChecker,
  editProviderLastNameUserFormChecker,
} from './recoil';
import { HeaderGrid } from './styles';

export type ParamsProps = {
  provider_id: string;
  user_id: string;
};

export const ContentContainer = styled('div')`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 20px;
  padding: 30px;
  .MuiFormControl-root {
    padding-bottom: 30px;
  }
  .MuiButtonBase-root.MuiButton-contained {
    padding-left: 25px;
    padding-right: 25px;
  }

  .MuiButtonBase-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.grey[50]};
    background-color: rgb(223, 36, 103);
    opacity: 50%;
  }
`;

export const ProviderDetailPage: React.FC = () => {
  const theme = useTheme();
  const { provider_id, user_id } = useParams<ParamsProps>();
  const { logger } = useAppContext();
  const organization = useCherreValue(getProviderOrganization());

  const user_data = useCherreValue(getProviderUserById());

  const [formData, setFormData] = useState({
    first_name: user_data?.first_name,
    last_name: user_data?.last_name,
  });
  console.log('initialize form data', formData);
  logger.info('Edit user form data', formData);

  const checkResult = useMemo(() => {
    const firstNameResult = editProviderFirstNameUserFormChecker(formData);
    const lastNameResult = editProviderLastNameUserFormChecker(formData);
    return { firstNameResult, lastNameResult };
  }, [formData]);
  logger.info('validation result', checkResult);

  const endpoint = `/api/v1/organizations/${organization}/users/${user_id}`;

  const context = useAppContext();

  const editUser = async (data) => {
    const putData = {
      firstName: data.first_name,
      lastName: data.last_name,
    };
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(putData),
    });
    if (response.ok) {
      const json = await response.json();
      context?.showSnackbar({
        type: 'success',
        message: `User details were updated.`,
      });
      return json;
    } else {
      const errorJson = await response.json();
      context?.showSnackbar({
        type: 'error',
        message: `Error updating user details: ${errorJson.errorMessage}`,
      });
      throw new Error('User details update failed');
    }
  };

  const history = useHistory();

  const onSubmit = () => {
    console.log('formdata', formData);
    editUser(formData);
    history.push(`/dsp/providers/${provider_id}`);
  };

  return (
    <PageContainer id='dsp/provider-detail-page/add-user'>
      <Container sx={{ width: '55%', margin: '0 auto 0 auto' }}>
        <HeaderGrid container direction='row' justifyContent='flex-start'>
          <IconButton
            style={{ height: '48px', alignSelf: 'center' }}
            component={Link}
            to={`/dsp/providers/${provider_id}`}
          >
            <ArrowBack style={{ fill: grey[900] }} />
          </IconButton>
          <h1>Edit User</h1>
        </HeaderGrid>
        <ContentContainer>
          <Typography variant='body2' sx={{ paddingBottom: '30px' }}>
            Required fields marked with asterisk (*)
          </Typography>
          <Grid item sx={{ width: '63%', minWidth: '480px' }}>
            <FormEntry required title='First Name'>
              <TextField
                id='first-name'
                variant='outlined'
                size='small'
                required
                defaultValue={user_data?.first_name}
                onChange={(e) => {
                  setFormData({ ...formData, first_name: e.target.value });
                }}
              />
            </FormEntry>
            <FormEntry required title='Last Name'>
              <TextField
                id='last-name'
                variant='outlined'
                size='small'
                required
                defaultValue={user_data?.last_name}
                onChange={(e) => {
                  setFormData({ ...formData, last_name: e.target.value });
                }}
              />
            </FormEntry>
            <FormEntry title='Email'>
              <TextField
                sx={{
                  '& .MuiInputBase-root': {
                    background: theme.palette.grey[100],
                    color: theme.palette.grey[500],
                  },
                }}
                id='email'
                variant='outlined'
                size='small'
                disabled
                value={user_data?.email}
              />
            </FormEntry>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            disabled={
              checkResult.firstNameResult.type === 'failure' &&
              checkResult.lastNameResult.type === 'failure'
            }
            onClick={onSubmit}
          >
            Save
          </Button>
        </ContentContainer>
      </Container>
    </PageContainer>
  );
};

export default ProviderDetailPage;
