import {
  graphQLSelector,
  Writeable,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { queriesGetProvidersQuery } from './__generated__/queriesGetProvidersQuery.graphql';
import { queriesGetPropertiesQuery } from './__generated__/queriesGetPropertiesQuery.graphql';
import { queriesGetSubmissionNameQuery } from './__generated__/queriesGetSubmissionNameQuery.graphql';
import { graphql } from 'relay-runtime';

export type Provider = Writeable<
  queriesGetProvidersQuery['response']['sys_providers'][number]
>;

export const getProviders = graphQLSelector({
  query: graphql`
    query queriesGetProvidersQuery(
      $limit: Int
      $offset: Int
      $order_by: [sys_providers_order_by!] = {}
      $where: sys_providers_bool_exp = {}
    ) {
      sys_providers(
        limit: $limit
        offset: $offset
        order_by: $order_by
        where: $where
      ) {
        is_active
        provider_id
        organization_id
        provider_name
        created_at_datetime
        updated_at_datetime
      }
    }
  ` as GraphQLReturn<queriesGetProvidersQuery>,
  mapResponse: (response) => response.sys_providers,
});

export type Property = Writeable<
  queriesGetPropertiesQuery['response']['sys_properties_flattened_union'][number]
>;

export const getProperties = graphQLSelector({
  query: graphql`
    query queriesGetPropertiesQuery(
      $limit: Int
      $offset: Int
      $order_by: [sys_properties_flattened_union_order_by!] = {}
      $where: sys_properties_flattened_union_bool_exp = {}
      $datasets_where: sys_properties_datasets_bool_exp = {}
    ) {
      sys_properties_flattened_union(
        limit: $limit
        offset: $offset
        order_by: $order_by
        where: $where
      ) {
        organization_id
        provider_id
        property_id
        property_name
        property_code
        entity_id
        entity_name
        address
        type
        fund
        property_mapping
        parent_property_id
        properties_datasets(where: $datasets_where) {
          dataset {
            dataset_label
            dataset_id
          }
        }
        datasets_count
        properties_dataset_validation_rules(
          where: {
            is_active: { _eq: true }
            dataset_validation_rule: { is_active: { _eq: true } }
          }
        ) {
          dataset_validation_rule {
            dataset_id
          }
          dataset_validation_rule_id
          validation_mode
        }
        properties_batch_validation_rules(where: { is_active: { _eq: true } }) {
          property_batch_validation_rule_id
          validation_mode
          batch_validation_rule {
            datasets_batch_validation_rules {
              dataset_id
            }
          }
        }
      }
    }
  ` as GraphQLReturn<queriesGetPropertiesQuery>,
  mapResponse: (response) => {
    // for each properties, filter the validation rules to only include the ones that are relevant to the datasets
    const data = response.sys_properties_flattened_union.map((property) => {
      const datasetIds = property.properties_datasets.map(
        (pd) => pd.dataset.dataset_id
      );
      const relevantSingleValidationRules =
        property?.properties_dataset_validation_rules?.filter((rule) =>
          datasetIds.includes(rule.dataset_validation_rule.dataset_id)
        );
      const relevantBatchValidationRules =
        property?.properties_batch_validation_rules?.filter((rule) =>
          rule.batch_validation_rule.datasets_batch_validation_rules.every(
            (dataset) => datasetIds.includes(dataset.dataset_id)
          )
        );
      return {
        ...property,
        properties_dataset_validation_rules: relevantSingleValidationRules,
        properties_batch_validation_rules: relevantBatchValidationRules,
      };
    });

    return data;
  },
});

export const getSubmissionName = graphQLSelector({
  query: graphql`
    query queriesGetSubmissionNameQuery($params: get_batch_name_params!) {
      _sys_get_batch_name(params: $params) {
        name
      }
    }
  ` as GraphQLReturn<queriesGetSubmissionNameQuery>,
  mapResponse: (response) => response._sys_get_batch_name.name,
});
