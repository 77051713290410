import {
  Alert,
  AlertTitle,
  Box,
  Button,
  InfoAltIcon,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import React, { useEffect, useState } from 'react';

const AlertStyled = styled(Alert)`
  border-radius: 10px;
  padding: 6px;
  align-items: center;

  .MuiAlert-message {
    padding: 0;
  }

  .MuiAlert-action {
    padding: 0;
    margin-right: 0;
  }
`;

const Icon = styled(InfoAltIcon)`
  color: ${({ theme }) => theme.accents.info.main};
`;

const Title = styled(AlertTitle)`
  color: ${({ theme }) => theme.accents.info.main};
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 2px;
`;

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.accents.info.main};
  font-size: 12px;
  line-height: 14px;
`;

const ViewRemindersButton = styled(Button)`
  margin-right: 0;
`;

export type ReminderAlertProps = {
  subtitle: string;
  onViewRemindersClicked: () => void;
  localStorageKey: string;
};

const PackageReportReminderAlert: React.FC<ReminderAlertProps> = ({
  subtitle,
  onViewRemindersClicked,
  localStorageKey,
}) => {
  const [userHidBanner, setUserHidBanner] = useState(false);

  useEffect(() => {
    const reminderBannerClosed = sessionStorage.getItem(localStorageKey);
    setUserHidBanner(!!reminderBannerClosed);
  }, []);

  if (userHidBanner) {
    return null;
  }

  const onButtonClicked = () => {
    setUserHidBanner(true);
    sessionStorage.setItem(localStorageKey, 'true');
    onViewRemindersClicked();
  };

  return (
    <Box>
      <AlertStyled
        icon={<Icon />}
        severity='info'
        action={
          <ViewRemindersButton
            color='primary'
            variant='contained'
            onClick={onButtonClicked}
          >
            View Reminders
          </ViewRemindersButton>
        }
      >
        <Title color=''>Reminders</Title>
        <Subtitle>{subtitle}</Subtitle>
      </AlertStyled>
    </Box>
  );
};

export default PackageReportReminderAlert;
