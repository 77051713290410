import React from 'react';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import {
  excludedCount,
  excludedPropertiesBannerDimissed,
} from '../recoil/state.SelectProperties';
import {
  Grid,
  Alert,
  AlertTitle,
  styled,
  WarningIcon,
  Button,
} from '@cherre-frontend/ui';

const WarningBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.brown.light};
  color: ${({ theme }) => theme.accents.brown.main};
  .MuiAlert-message {
    padding: 0px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

const Icon = styled(WarningIcon)`
  color: ${({ theme }) => theme.accents.brown.main};
`;

export const ExcludedPropertiesBanner: React.FC = () => {
  const count = useCherreValue(excludedCount);
  const [dismissed, setDismissed] = useCherreState(
    excludedPropertiesBannerDimissed
  );
  if (!count || dismissed) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <WarningBanner
        severity='warning'
        icon={<Icon />}
        action={
          <Button variant='outlined' onClick={() => setDismissed(true)}>
            Dismiss
          </Button>
        }
      >
        <AlertTitle>Attention!</AlertTitle>
        You have excluded {count} {count > 1 ? 'properties' : 'property'} from
        the batch. Be sure to create another batch for{' '}
        {count > 1 ? 'their' : 'its'} submission.
      </WarningBanner>
    </Grid>
  );
};

ExcludedPropertiesBanner.displayName = 'ExcludedPropertiesBanner';
