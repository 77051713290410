/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useState } from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherreState,
  useMutation,
  GraphQLReturn,
  constSelector,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
  IconButton,
  CancelIcon,
  SearchIcon,
  useTheme,
  MRT_RowSelectionState,
} from '@cherre-frontend/ui';
import Autocomplete from '@mui/material/Autocomplete';
import {
  assignPreparersDialogOpenState,
  selectedPreparerUsers,
  selectedPropertiesToAssign,
  selectedPropertiesToAssignContainsManyToOneProperty,
  userCheckerType,
} from 'src/products/data-submission-portal/recoil/dialog';
import { getUserList, User } from './queries/getUserList';
import UserOption from './components/UserOption';
import WarningBox from './components/WarningBox';
import { Container, DialogPanel, HeaderGrid } from './styles';
import { AssignPreparersDialogMutation } from './__generated__/AssignPreparersDialogMutation.graphql';
import { useCurrentPreparersAndReviewers } from './hooks/useCurrentPreparersAndReviewers';
import { useWarningBox } from './hooks/useWarningBox';
import { useGetProviderId } from './hooks/useGetProviderId';

type Props = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
};

const reviewerSlugs = [
  'reviewer_1',
  'reviewer_2',
  'reviewer_3',
  'approver_plus_1',
  'approver_plus_2',
  'approver_plus_3',
];

const AssignPreparersDialog: React.FC<Props> = ({ setRowSelection }) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useCherreState(
    selectedPreparerUsers
  );
  const selectedProperties = useCherreValue(selectedPropertiesToAssign);
  const shouldDisplayManyToOneMessage = useCherreValue(
    selectedPropertiesToAssignContainsManyToOneProperty
  );

  const provider_id = useGetProviderId();

  const [isOpen, setAssignPreparersDialogOpenState] = useCherreState(
    assignPreparersDialogOpenState
  );

  const userOptions = useCherreValue(
    isOpen ? getUserList(provider_id) : constSelector([])
  );

  const currentPreparersAndReviewers = useCurrentPreparersAndReviewers(
    !!isOpen
  );

  const {
    warningBoxState,
    clearWarningBoxState,
    triggerPreparerAlreadyAssigned,
  } = useWarningBox();

  const onClose = useCherreEventWithRecoil(
    'user closed assign preparers dialog',
    (ctx) => () => {
      ctx.recoil.set(assignPreparersDialogOpenState, false);
      ctx.recoil.set(selectedPreparerUsers, []);
      clearWarningBoxState();
    }
  );

  const selectedUsersIsEmpty = useMemo(() => {
    return selectedUsers!.length === 0;
  }, [selectedUsers]);

  const filteredUserOptions = useMemo(() => {
    if (selectedUsers) {
      const selectedUserIds = selectedUsers.map((user) => user.user_id);
      return userOptions?.filter(
        (user) => !selectedUserIds.includes(user.user_id)
      );
    } else {
      return userOptions;
    }
  }, [selectedUsers]);

  const currentPreparersAndReviewersArrays = useMemo(() => {
    const allPreparers = currentPreparersAndReviewers?.filter(
      (user) => user.property_role.property_role_slug === 'preparer'
    );
    const allReviewers = currentPreparersAndReviewers?.filter((user) =>
      reviewerSlugs.includes(user.property_role.property_role_slug)
    );
    const preparerUserIds = [
      ...new Set(allPreparers?.map((user) => user.user_id)),
    ];
    const preparerUserList = preparerUserIds.map((id) => ({
      user_id: id,
      entity_ids: [
        ...new Set(
          allPreparers
            ?.filter((user) => user.user_id === id)
            .map((user) => user.property.entity_id)
        ),
      ],
    }));
    const reviewerUserIds = [
      ...new Set(allReviewers?.map((user) => user.user_id)),
    ];
    const reviewerUserList = reviewerUserIds.map((id) => ({
      user_id: id,
      entity_ids: [
        ...new Set(
          allReviewers
            ?.filter((user) => user.user_id === id)
            .map((user) => user.property.entity_id)
        ),
      ],
    }));
    return {
      preparers: preparerUserList ?? [],
      reviewers: reviewerUserList ?? [],
    };
  }, [currentPreparersAndReviewers]);

  const assignPreparers = useMutation(
    graphql`
      mutation AssignPreparersDialogMutation(
        $input: _sys_property_assign_preparers_input!
      ) {
        _sys_property_assign_preparers(input: $input) {
          affected_rows
        }
      }
    ` as GraphQLReturn<AssignPreparersDialogMutation>,
    {
      mapVariables: () => async () => {
        const user_ids = selectedUsers!.map((user) => user.user_id);
        const property_ids = selectedProperties!.map(
          (property) => property.property_id
        );

        return { input: { user_ids, property_ids, provider_id } };
      },
      onCompleted: async (result, ctx) => {
        const manyToOneMessage = 'Preparers were successfully assigned.';
        const oneToOneMessage = `Preparers have been assigned to ${
          selectedProperties!.length
        } properties.`;
        ctx.showSnackbar({
          type: 'success',
          message: shouldDisplayManyToOneMessage
            ? manyToOneMessage
            : oneToOneMessage,
        });
        ctx.recoil.set(assignPreparersDialogOpenState, false);
        ctx.recoil.set(selectedPreparerUsers, []);
        clearWarningBoxState();
        setRowSelection({});
      },
    }
  );

  const isSuspended = useIsSuspended();

  return (
    <Container open={Boolean(isOpen && !isSuspended)} onClose={onClose}>
      <DialogPanel
        id='AssignPreparersDialog'
        config={{ logLevel: false }}
        style={{ display: 'grid', flexGrow: 1 }}
      >
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Typography variant='h5' style={{ fontWeight: 600 }}>
            Assign Preparers
          </Typography>
        </HeaderGrid>
        {selectedProperties && (
          <Typography variant='body1' style={{ marginBottom: '8px' }}>
            {selectedProperties.length}{' '}
            {selectedProperties.length === 1 ? 'property' : 'properties'}{' '}
            selected
          </Typography>
        )}
        {shouldDisplayManyToOneMessage && (
          <Typography variant='body2' marginBottom='20px'>
            Note: Assigning a preparer to a M:1 property group will assign that
            preparer to all properties within the associated group(s).
          </Typography>
        )}
        <WarningBox {...warningBoxState} />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Autocomplete
            placeholder='search'
            size='small'
            inputValue={search}
            isOptionEqualToValue={(opt, value) => opt.user_id === value.user_id}
            options={filteredUserOptions as User[]}
            getOptionLabel={(opt) =>
              `${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`
            }
            renderOption={(props, opt) => {
              return (
                <li {...props} key={opt.user_id}>
                  <Box>
                    <UserOption
                      name={`${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`}
                      email={opt.sakura_user?.email as string}
                    />
                  </Box>
                </li>
              );
            }}
            popupIcon={
              <SearchIcon style={{ color: theme.palette.grey[500] }} />
            }
            onInputChange={(_, newInputValue, reason) => {
              if (reason === 'reset') {
                setSearch('');
              } else {
                setSearch(newInputValue);
              }
            }}
            onChange={(_, value) => {
              clearWarningBoxState();

              if (!value) {
                return;
              }

              const isAlreadyReviewer =
                currentPreparersAndReviewersArrays.reviewers
                  .map((user) => user.user_id)
                  .includes(value.user_id);

              if (isAlreadyReviewer) {
                triggerPreparerAlreadyAssigned(
                  'error',
                  `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
                  'Reviewer',
                  currentPreparersAndReviewersArrays.reviewers.find(
                    (user) => user.user_id === value.user_id
                  )?.entity_ids ?? []
                );
                return;
              }

              const isAlreadyPreparer =
                currentPreparersAndReviewersArrays.preparers
                  .map((user) => user.user_id)
                  .includes(value.user_id);

              if (isAlreadyPreparer) {
                triggerPreparerAlreadyAssigned(
                  'warning',
                  `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
                  'Preparer',
                  currentPreparersAndReviewersArrays.preparers.find(
                    (user) => user.user_id === value.user_id
                  )?.entity_ids ?? []
                );
              }

              setSelectedUsers((s) => {
                return s.concat(value as userCheckerType);
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Search'
                size='small'
                value=''
              />
            )}
            sx={{
              '> div > div > div > button': {
                transform: 'unset',
              },
            }}
          />
          <Box
            style={{
              display: 'flex',
              flexGrow: 1,
              marginTop: selectedUsersIsEmpty ? 'unset' : '24px',
              justifyContent: selectedUsersIsEmpty ? 'center' : 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '16px',
              maxHeight: selectedUsers!.length > 3 ? '160px' : 'unset',
              overflow: 'auto',
            }}
          >
            {!selectedUsersIsEmpty ? (
              selectedUsers?.map((user) => (
                <Box
                  key={user.user_id}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                  }}
                >
                  <UserOption
                    name={`${user.sakura_user?.first_name} ${user.sakura_user?.last_name}`}
                    email={user.sakura_user?.email as string}
                  />
                  <IconButton
                    size='small'
                    style={{ height: '30px', alignSelf: 'center' }}
                    onClick={() => {
                      setSelectedUsers((s) =>
                        s.filter(
                          (selectedUser) =>
                            selectedUser.user_id !== user.user_id
                        )
                      );
                    }}
                  >
                    <CancelIcon fontSize='small' />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Box sx={{ height: '100%', paddingTop: '24px' }}>
                <Typography variant='body1'>No users assigned</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          gap={2}
          style={{ marginTop: '16px' }}
        >
          <Grid item>
            <Button
              variant='text'
              style={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                setAssignPreparersDialogOpenState(false);
                setSelectedUsers([]);
                clearWarningBoxState();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              style={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                assignPreparers();
              }}
              disabled={selectedUsersIsEmpty}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogPanel>
    </Container>
  );
};

export default AssignPreparersDialog;
