import {
  constSelector,
  GraphQLReturn,
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  FormEntry,
  Grid,
  MRT_RowSelectionState,
  MenuItem,
  Panel,
  Select,
  SelectProps,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { graphql } from 'react-relay';
import { getProvider } from '../../pages/provider-detail/queries/getProvider';
import { AssignMappingDialogMutation } from './__generated__/AssignMappingDialogMutation.graphql';
import { DialogHeader } from './components/DialogHeader';
import {
  assignMappingDialogSubtitle,
  assignMappingDialogTitle,
  assignMappingToPropertyState,
  mappingIdToAssignState,
} from './localState';
import { getCOAMappings } from './queries/getCOAMappings';
import { useLocation } from 'react-router';
import { assignMappingsDialogOpenState } from '../../recoil/dialog';
import { useGetProviderId } from '../dialogs/hooks/useGetProviderId';

const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: 480px;
  }
`;

const SelectStyled = styled<React.FC<SelectProps>>(Select)`
  height: 40px;
`;

type AssignMappingDialogProps = {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: (rowSelection: MRT_RowSelectionState) => void;
};

const AssignMappingDialog: React.FC<AssignMappingDialogProps> = ({
  rowSelection,
  setRowSelection,
}) => {
  const isModalOpen = useCherreValue(assignMappingsDialogOpenState);

  const assignMappingToProperty = useCherreValue(assignMappingToPropertyState);

  const [mappingIdToAssign, setMappingIdToAssign] = useCherreState(
    mappingIdToAssignState
  );

  const route = useLocation();

  const currentProviderId = useGetProviderId();

  const coaMappings = useCherreValue(
    isModalOpen ? getCOAMappings(currentProviderId) : constSelector([])
  );

  const selectedCoaMapping = useMemo(() => {
    if (!mappingIdToAssign) {
      return null;
    }
    return coaMappings?.find(
      (mapping) => mapping.mapping_set_id === mappingIdToAssign
    );
  }, [mappingIdToAssign, coaMappings]);

  const title = useCherreValue(assignMappingDialogTitle);
  const subtitle = useCherreValue(assignMappingDialogSubtitle);

  const onClose = useCherreEventWithRecoil(
    'user closed assign mappings dialog',
    (ctx) => () => {
      setMappingIdToAssign(null);
      ctx.recoil.set(assignMappingsDialogOpenState, false);
    }
  );

  const assignMapping = useMutation(
    graphql`
      mutation AssignMappingDialogMutation(
        $objects: [sys_properties_mapping_sets_insert_input!]!
        $property_ids: [Int!]
        $mapping_field_id: Int
      ) {
        update_sys_properties_mapping_sets(
          where: {
            mapping_field_id: { _eq: $mapping_field_id }
            property_id: { _in: $property_ids }
          }
          _set: { is_active: false }
        ) {
          affected_rows
        }

        insert_sys_properties_mapping_sets(objects: $objects) {
          affected_rows
        }
      }
    ` as GraphQLReturn<AssignMappingDialogMutation>,
    {
      trackEvent: false,
      mapVariables:
        () =>
        async ({ getPromise }) => {
          // All COA mappings will have the same mapping_field_id
          const mappingFieldId = coaMappings?.[0].mapping_field_id;

          const provider = await getPromise(
            route.pathname.includes('properties')
              ? constSelector(null)
              : getProvider()
          );
          const provider_id =
            provider !== null ? provider.provider_id : currentProviderId;

          const selectedPropertyIds = assignMappingToProperty
            ? [assignMappingToProperty.property_id]
            : Object.keys(rowSelection);

          const objects = selectedPropertyIds.map((id) => ({
            provider_id,
            property_id: parseInt(id),
            mapping_set_id: mappingIdToAssign,
            mapping_field_id: mappingFieldId,
          }));
          return {
            objects,
            property_ids: selectedPropertyIds.map((id) => parseInt(id)),
            mapping_field_id: mappingFieldId,
          };
        },
      onCompleted: (result, ctx) => {
        setRowSelection({});
        ctx.recoil.set(assignMappingsDialogOpenState, false);
        ctx.recoil.set(mappingIdToAssignState, null);
        const mappingName = selectedCoaMapping?.mapping_set_description;
        ctx.showSnackbar({
          type: 'success',
          message: `${mappingName} assigned to ${result.insert_sys_properties_mapping_sets?.affected_rows} properties`,
        });
      },
    }
  );

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Panel id='AssignMappingsDialog' config={{ logLevel: false }}>
        <DialogHeader title={title} subtitle={subtitle} />

        <Box my={3}>
          <FormEntry title='Select mapping'>
            <SelectStyled
              value={mappingIdToAssign ?? ''}
              onChange={(e) => setMappingIdToAssign(e.target.value as number)}
            >
              <MenuItem value={''} disabled>
                Select
              </MenuItem>
              {coaMappings?.map((mapping) => (
                <MenuItem
                  key={mapping.mapping_set_id}
                  value={mapping.mapping_set_id}
                >
                  {mapping.mapping_set_description}
                </MenuItem>
              ))}
            </SelectStyled>
          </FormEntry>
        </Box>

        <Grid container direction='row' gap={2} justifyContent='flex-end'>
          <Grid item>
            <Button
              size='medium'
              variant='outlined'
              color='primary'
              onClick={onClose}
              sx={{
                border: 'none',
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size='medium'
              variant='contained'
              color='primary'
              disabled={
                !mappingIdToAssign ||
                assignMappingToProperty?.coa_mappings.some(
                  (mapping) => mapping.mapping_set_id === mappingIdToAssign
                )
              }
              onClick={assignMapping}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};

export default AssignMappingDialog;
