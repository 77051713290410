import { graphQLSelector, GraphQLReturn } from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { BatchValidationSummaryRoute } from 'src/products/data-submission-portal/routes';
import { queryBatchInfoQuery } from './__generated__/queryBatchInfoQuery.graphql';

export const fileInfo = graphQLSelector({
  query: graphql`
    query queryBatchInfoQuery($property_batch_id: Int!) {
      sys_property_batches(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        is_valid
        validated_at_datetime

        submission {
          submission_name
          user {
            sakura_user {
              first_name
              last_name
            }
          }
        }

        warning_count: property_batch_validations_aggregate(
          where: { is_valid: { _eq: false }, validation_mode: { _eq: "soft" } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<queryBatchInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(BatchValidationSummaryRoute.routeParamSelector),
  mapResponse: (rawResponse) => rawResponse.sys_property_batches[0],
});
