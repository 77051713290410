/**
 * @generated SignedSource<<bc44599aadb14e00628f2303c24a0e35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _sys_property_assign_preparers_input = {
  property_ids: ReadonlyArray<number>;
  provider_id: number;
  user_ids: ReadonlyArray<number>;
};
export type _sys_property_assign_reviewers_input = {
  property_ids: ReadonlyArray<number>;
  provider_id: number;
  reviewers: ReadonlyArray<_sys_property_assign_reviewers_input_reviewer_user>;
};
export type _sys_property_assign_reviewers_input_reviewer_user = {
  is_active: boolean;
  property_role_id: number;
  submission_type_id: number;
  user_id: number;
};
export type useManageUsersMutation$variables = {
  delete_ids: ReadonlyArray<number>;
  preparers_input: _sys_property_assign_preparers_input;
  reviewers_input: _sys_property_assign_reviewers_input;
};
export type useManageUsersMutation$data = {
  readonly _sys_property_assign_preparers: {
    readonly affected_rows: number | null;
  } | null;
  readonly _sys_property_assign_reviewers: {
    readonly affected_rows: number | null;
  } | null;
  readonly delete_sys_properties_roles_users: {
    readonly affected_rows: number;
  } | null;
};
export type useManageUsersMutation = {
  response: useManageUsersMutation$data;
  variables: useManageUsersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "delete_ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "preparers_input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reviewers_input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "delete_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_role_user_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_roles_users_mutation_response",
    "kind": "LinkedField",
    "name": "delete_sys_properties_roles_users",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "preparers_input"
      }
    ],
    "concreteType": "_sys_property_assign_preparers_output",
    "kind": "LinkedField",
    "name": "_sys_property_assign_preparers",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "reviewers_input"
      }
    ],
    "concreteType": "_sys_property_assign_reviewers_output",
    "kind": "LinkedField",
    "name": "_sys_property_assign_reviewers",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useManageUsersMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useManageUsersMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9856e048cdcd9fc2bf5f36ebb245e0dd",
    "id": null,
    "metadata": {},
    "name": "useManageUsersMutation",
    "operationKind": "mutation",
    "text": "mutation useManageUsersMutation(\n  $delete_ids: [Int!]!\n  $preparers_input: _sys_property_assign_preparers_input!\n  $reviewers_input: _sys_property_assign_reviewers_input!\n) {\n  delete_sys_properties_roles_users(where: {property_role_user_id: {_in: $delete_ids}}) {\n    affected_rows\n  }\n  _sys_property_assign_preparers(input: $preparers_input) {\n    affected_rows\n  }\n  _sys_property_assign_reviewers(input: $reviewers_input) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebf195ce63b1f400c830ec64192af07b";

export default node;
