/**
 * @generated SignedSource<<ba307b73808311aec939249be1449bb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetPropertyModelQuery$variables = {
  submission_id: number;
};
export type recoilGetPropertyModelQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly properties_flattened_union: {
      readonly property_models: {
        readonly property_model_slug: string;
      } | null;
    } | null;
  }>;
};
export type recoilGetPropertyModelQuery = {
  response: recoilGetPropertyModelQuery$data;
  variables: recoilGetPropertyModelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "properties_flattened_union",
            "value": {}
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "submission_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_flattened_union",
        "kind": "LinkedField",
        "name": "properties_flattened_union",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_models",
            "kind": "LinkedField",
            "name": "property_models",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_model_slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetPropertyModelQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetPropertyModelQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "554f531a1fbb3f9d70acb7d2430c9f05",
    "id": null,
    "metadata": {},
    "name": "recoilGetPropertyModelQuery",
    "operationKind": "query",
    "text": "query recoilGetPropertyModelQuery(\n  $submission_id: Int!\n) {\n  sys_property_batches(where: {submission: {submission_id: {_eq: $submission_id}}, properties_flattened_union: {}}, limit: 1) {\n    properties_flattened_union {\n      property_models {\n        property_model_slug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "60bd33e86fa94db9a1b81a060e32b14b";

export default node;
