import { useCherreValue } from '@cherre-frontend/data-fetching';
import React from 'react';
import { SinglePropertyBatchAccordionProps } from '../types';
import { PropertyBatchBaseAccordion } from '../PropertyBatchAccordion.base';
import { getPreparers } from '../recoil';
import { useTabsConfigView } from './useTabsConfigView';

export const SinglePropertyBatchViewAccordion: React.FC<
  SinglePropertyBatchAccordionProps
> = ({ propertyBatch }) => {
  const {
    properties_flattened_union: { properties_roles_users },
  } = propertyBatch;

  const preparers = useCherreValue(
    getPreparers({
      user_ids: properties_roles_users.map((p) => p.user_id),
    })
  );

  const preparersText = preparers?.sys_users
    ?.map(
      ({ sakura_user }) =>
        `${sakura_user?.first_name} ${sakura_user?.last_name}`
    )
    .join(', ');

  const hasPreparers = !!preparers?.sys_users?.length;

  const tabConfig = useTabsConfigView(propertyBatch);

  return (
    <>
      <PropertyBatchBaseAccordion
        {...tabConfig}
        propertyBatch={propertyBatch}
        accordionSubtitleComponent={
          hasPreparers ? <>Preparer: {preparersText} • </> : null
        }
      />
    </>
  );
};
