import { useCherreValue } from '@cherre-frontend/data-fetching';
import { DotSeparator, PageHeader } from '@cherre-frontend/ui';
import React from 'react';
import { batchSummaryInfo } from './query';

const Header: React.FC = () => {
  const info = useCherreValue(batchSummaryInfo());

  const propertyName = info?.property.property_name ? (
    <>{` - ${info.property.property_name}`}</>
  ) : (
    <>{''}</>
  );

  return (
    <PageHeader
      title='Submission Validation Summary Report'
      subtitle={
        <>
          <DotSeparator>
            <>
              <span className='suspend'>
                ID {info?.property.property_id || 'Unknown'}
              </span>
              {propertyName}
            </>
            <span className='suspend'>
              {info?.submission.submission_name || 'Unknown Submissions'}
            </span>
          </DotSeparator>
        </>
      }
      backLink
    />
  );
};

Header.displayName = 'BatchValidationPage/Header';

export default Header;
