import React from 'react';
import { FilterChip, Menu, MenuItem } from '@cherre-frontend/ui';
import { batchesTableFiltersState } from '../../../recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';

type FilterItemProps = {
  filterType: string;
  filterData: Record<string, string>;
};

export default function FilterItem({
  filterType,
  filterData,
}: FilterItemProps) {
  const [filterState, setFilterState] = useCherreState(
    batchesTableFiltersState
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!filterState) {
    return <></>;
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (value: NonNullable<typeof status>) => () => {
    setFilterState((oldState) => ({ ...oldState, [filterType]: value }));
    handleFilterClose();
  };

  const filterValues = Object.keys(filterData);

  return (
    <>
      <FilterChip
        label={filterState[filterType]}
        onClick={handleFilterClick}
        active={filterState[filterType] !== `Any ${filterType}`}
        onDelete={handleFilterChange(`Any ${filterType}`)}
      />
      <Menu
        id={`${filterType}-filter-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleFilterClose}
        data-testid={`${filterType}-filter-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          style={{ width: '200px', gap: '6px' }}
          onClick={handleFilterChange(`Any ${filterType}`)}
        >
          Any {filterType}
        </MenuItem>
        {filterValues.map((value) => (
          <MenuItem
            key={value}
            style={{ width: '200px', gap: '6px' }}
            onClick={handleFilterChange(filterData[value])}
          >
            {filterData[value]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

FilterItem.displayName = 'FilterItem';
