/**
 * @generated SignedSource<<04ef06ae3fc731518b24b5a69eaebfba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getOpenedBatchesWithMappingsQuery$variables = {
  mapping_set_id: number;
};
export type getOpenedBatchesWithMappingsQuery$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly submission_id: number;
    readonly submission_name: string;
  }>;
};
export type getOpenedBatchesWithMappingsQuery = {
  response: getOpenedBatchesWithMappingsQuery$data;
  variables: getOpenedBatchesWithMappingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mapping_set_id"
  }
],
v1 = {
  "kind": "Literal",
  "name": "is_active",
  "value": {
    "_eq": true
  }
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "submission_name": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_closed",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  (v1/*: any*/),
                  {
                    "fields": [
                      (v1/*: any*/),
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "mapping_set_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "mapping_set_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "properties_mapping_sets"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batches"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getOpenedBatchesWithMappingsQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getOpenedBatchesWithMappingsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7c75745d68c3c6d206d19648fad2183d",
    "id": null,
    "metadata": {},
    "name": "getOpenedBatchesWithMappingsQuery",
    "operationKind": "query",
    "text": "query getOpenedBatchesWithMappingsQuery(\n  $mapping_set_id: Int!\n) {\n  sys_submissions(where: {is_closed: {_eq: false}, property_batches: {property: {is_active: {_eq: true}, properties_mapping_sets: {is_active: {_eq: true}, mapping_set_id: {_eq: $mapping_set_id}}}}}, order_by: {submission_name: asc}) {\n    submission_id\n    submission_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4cb1b4b219e11c487bc9de8a6c724ff";

export default node;
