/**
 * @generated SignedSource<<9b06731d950ab301b40c2b56e46ddacc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type report_metadata_input = {
  query_arguments?: any | null;
  report_params?: any | null;
  report_slug: string;
};
export type recoilGetReportMetadataQuery$variables = {
  params: report_metadata_input;
};
export type recoilGetReportMetadataQuery$data = {
  readonly _sys_get_report_metadata: {
    readonly pagination: boolean;
    readonly tables: ReadonlyArray<{
      readonly columns: ReadonlyArray<{
        readonly accessorKey: string | null;
        readonly aggregation: {
          readonly align: string | null;
          readonly value: any;
        } | null;
        readonly align: string | null;
        readonly columns: ReadonlyArray<{
          readonly accessorKey: string | null;
          readonly aggregation: {
            readonly align: string | null;
            readonly value: any;
          } | null;
          readonly align: string | null;
          readonly columns: ReadonlyArray<{
            readonly accessorKey: string | null;
            readonly aggregation: {
              readonly align: string | null;
              readonly value: any;
            } | null;
            readonly align: string | null;
            readonly columnsField: string | null;
            readonly dynamicColumn: boolean | null;
            readonly fixedSize: number | null;
            readonly format: any | null;
            readonly frozen: string | null;
            readonly header: {
              readonly align: string | null;
              readonly label: string;
            } | null;
            readonly ratioSize: number | null;
            readonly searchable: boolean | null;
            readonly sortable: boolean | null;
            readonly style: any | null;
            readonly type: string;
            readonly valuesField: string | null;
            readonly visible: boolean | null;
          }> | null;
          readonly columnsField: string | null;
          readonly dynamicColumn: boolean | null;
          readonly fixedSize: number | null;
          readonly format: any | null;
          readonly frozen: string | null;
          readonly header: {
            readonly align: string | null;
            readonly label: string;
          } | null;
          readonly ratioSize: number | null;
          readonly searchable: boolean | null;
          readonly sortable: boolean | null;
          readonly style: any | null;
          readonly type: string;
          readonly valuesField: string | null;
          readonly visible: boolean | null;
        }> | null;
        readonly columnsField: string | null;
        readonly dynamicColumn: boolean | null;
        readonly fixedSize: number | null;
        readonly format: any | null;
        readonly frozen: string | null;
        readonly header: {
          readonly align: string | null;
          readonly label: string;
        } | null;
        readonly ratioSize: number | null;
        readonly searchable: boolean | null;
        readonly sortable: boolean | null;
        readonly style: any | null;
        readonly type: string;
        readonly valuesField: string | null;
        readonly visible: boolean | null;
      }>;
      readonly data_source_object_type: string;
      readonly group_values: any | null;
      readonly groupings: {
        readonly aggregations: any;
        readonly keys: ReadonlyArray<string>;
      } | null;
      readonly hide_header: boolean;
      readonly initial_sorting: ReadonlyArray<{
        readonly desc: boolean;
        readonly id: string;
      }> | null;
      readonly table_slug: string;
    }>;
  } | null;
};
export type recoilGetReportMetadataQuery = {
  response: recoilGetReportMetadataQuery$data;
  variables: recoilGetReportMetadataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessorKey",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchable",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "style",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visible",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "align",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedSize",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ratioSize",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dynamicColumn",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuesField",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnsField",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frozen",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "report_table_column_header",
  "kind": "LinkedField",
  "name": "header",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "report_table_column_aggregation",
  "kind": "LinkedField",
  "name": "aggregation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "report_metadata_output",
    "kind": "LinkedField",
    "name": "_sys_get_report_metadata",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pagination",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "report_metadata_table",
        "kind": "LinkedField",
        "name": "tables",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "group_values",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "table_slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hide_header",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data_source_object_type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "report_sorting",
            "kind": "LinkedField",
            "name": "initial_sorting",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "desc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "report_metadata_groupings",
            "kind": "LinkedField",
            "name": "groupings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "keys",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aggregations",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "report_metadata_table_column",
            "kind": "LinkedField",
            "name": "columns",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "report_metadata_table_column",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "report_metadata_table_column",
                    "kind": "LinkedField",
                    "name": "columns",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetReportMetadataQuery",
    "selections": (v17/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetReportMetadataQuery",
    "selections": (v17/*: any*/)
  },
  "params": {
    "cacheID": "f4c9f6db28117ed707c78b4805a4fd9c",
    "id": null,
    "metadata": {},
    "name": "recoilGetReportMetadataQuery",
    "operationKind": "query",
    "text": "query recoilGetReportMetadataQuery(\n  $params: report_metadata_input!\n) {\n  _sys_get_report_metadata(params: $params) {\n    pagination\n    tables {\n      group_values\n      table_slug\n      hide_header\n      data_source_object_type\n      initial_sorting {\n        desc\n        id\n      }\n      groupings {\n        keys\n        aggregations\n      }\n      columns {\n        accessorKey\n        format\n        searchable\n        sortable\n        style\n        type\n        visible\n        align\n        fixedSize\n        ratioSize\n        dynamicColumn\n        valuesField\n        columnsField\n        frozen\n        header {\n          label\n          align\n        }\n        aggregation {\n          value\n          align\n        }\n        columns {\n          accessorKey\n          format\n          searchable\n          sortable\n          style\n          type\n          visible\n          align\n          fixedSize\n          ratioSize\n          dynamicColumn\n          valuesField\n          columnsField\n          frozen\n          header {\n            label\n            align\n          }\n          aggregation {\n            value\n            align\n          }\n          columns {\n            accessorKey\n            format\n            searchable\n            sortable\n            style\n            type\n            visible\n            align\n            fixedSize\n            ratioSize\n            dynamicColumn\n            valuesField\n            columnsField\n            frozen\n            header {\n              label\n              align\n            }\n            aggregation {\n              value\n              align\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba02002005e78096a14071565ba37446";

export default node;
