import {
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  MuiAutocomplete as Autocomplete,
  Box,
  Button,
  Grid,
  SearchIcon,
  TextField,
  styled,
  useTheme,
} from '@cherre-frontend/ui';
import React from 'react';
import {
  Stage,
  dialogStage,
  selectedDatasetIdsState,
  selectedDatasetsState,
} from '../../localState';
import { Dataset, getDatasets } from '../../queries/getDatasets';
import DatasetOption from '../DatasetOption';
import { DialogHeader } from '../DialogHeader';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const StyledLi = styled('li')`
  padding: 0;
`;

type SelectDatasetsProps = {
  numRowsSelected: number;
  onClose: () => void;
};

const SelectDatasets: React.FC<SelectDatasetsProps> = ({
  numRowsSelected,
  onClose,
}) => {
  const theme = useTheme();
  const unifiedIngestEnabled = useFeatureFlag('DSPNewIngestion');

  const onNextTemplates = useCherreEventWithRecoil(
    'user went from select datasets to select dataset templates',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectDatasetTemplate)
  );

  const onNextValidations = useCherreEventWithRecoil(
    'user went from select datasets to select validations',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectValidations)
  );

  const datasets = useCherreValue(getDatasets({}));
  const [selectedDatasetIds, setSelectedDatasetIds] = useCherreState(
    selectedDatasetIdsState
  );

  const selectedDatasets = useCherreValue(selectedDatasetsState);

  const onDatasetChange = (_, value) => {
    const datasetIds = value.map((v) => v.dataset_id);
    setSelectedDatasetIds(datasetIds);
  };

  return (
    <>
      <DialogHeader
        title='Select Datasets'
        subtitle={`${numRowsSelected} Properties Selected`}
      />

      <Box my={3}>
        <Autocomplete
          multiple
          placeholder='search'
          size='small'
          value={selectedDatasets || []}
          options={(datasets as Dataset[]) || []}
          getOptionLabel={(opt) => opt.dataset_label}
          disableCloseOnSelect
          ListboxProps={{
            sx: {
              '& li': {
                padding: 0,
              },
              '& .MuiCheckbox-root': {
                padding: 0,
              },
            },
          }}
          renderOption={(props, opt) => {
            return (
              <StyledLi {...props} key={opt.dataset_id}>
                <Box sx={{ padding: 0 }}>
                  <DatasetOption
                    title={opt.dataset_label}
                    checked={selectedDatasetIds?.includes(opt.dataset_id)}
                  />
                </Box>
              </StyledLi>
            );
          }}
          popupIcon={<SearchIcon style={{ color: theme.palette.grey[500] }} />}
          onChange={onDatasetChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              placeholder='Search'
              size='small'
              InputLabelProps={{ shrink: true }}
            />
          )}
          sx={{
            '> div > div > div > button': {
              transform: 'unset',
            },
            marginBottom: '20px',
          }}
        />
      </Box>

      <Grid item container direction='row' gap={2} justifyContent='flex-end'>
        <Grid item>
          <Button
            size='medium'
            variant='text'
            color='primary'
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            size='medium'
            variant='contained'
            color='primary'
            disabled={selectedDatasetIds && selectedDatasetIds?.length < 1}
            onClick={unifiedIngestEnabled ? onNextTemplates : onNextValidations}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SelectDatasets.displayName = 'SelectDatasets';

export default SelectDatasets;
