/**
 * @generated SignedSource<<23e77c3f80044235a9c9f94aca7272c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type runSubmissionValidationMutation$variables = {
  property_batch_id: number;
};
export type runSubmissionValidationMutation$data = {
  readonly _sys_validation_property_batch_async: string;
};
export type runSubmissionValidationMutation = {
  response: runSubmissionValidationMutation$data;
  variables: runSubmissionValidationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "property_batch_id",
            "variableName": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "kind": "ScalarField",
    "name": "_sys_validation_property_batch_async",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "runSubmissionValidationMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "runSubmissionValidationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e6a2ded5db787aae8573ad340a99928",
    "id": null,
    "metadata": {},
    "name": "runSubmissionValidationMutation",
    "operationKind": "mutation",
    "text": "mutation runSubmissionValidationMutation(\n  $property_batch_id: Int!\n) {\n  _sys_validation_property_batch_async(params: {property_batch_id: $property_batch_id})\n}\n"
  }
};
})();

(node as any).hash = "5be14d40a52fbed69eb1c9df77b2115e";

export default node;
