import React from 'react';
import {
  CloudUploadIcon,
  Button,
  useConfirmationModal,
} from '@cherre-frontend/ui';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useTheme } from '@material-ui/core';
import { useCherreEventWithRecoil } from '@cherre-frontend/data-fetching';
import { useHistory } from 'react-router';

type UploadPropertyButtonProps = {
  confirmFunction: typeof useConfirmationModal.arguments.confirm;
  mode: 'upload' | 'bulk'; // upload => first upload, bulk => following uploads
};

export const dialogTitleUpload = 'Upload Properties';
export const dialogTitleBulk = 'Bulk Upload Properties';

export const buttonLabelUpload = 'Upload Property';
export const buttonLabelBulk = 'Bulk Upload';

export const UploadPropertyButton: React.FC<UploadPropertyButtonProps> = ({
  confirmFunction,
  mode,
}) => {
  const theme = useTheme();
  const { push } = useHistory();

  const isAdminUploadPropertyEnabled = useFeatureFlag('DspAdminUploadProperty');
  const newIngest = useFeatureFlag('DSPNewIngestionAdmin');

  const onUploadClick = useCherreEventWithRecoil(
    'use requested upload property',
    () => async () => {
      let title: string;
      let primaryText: string;

      if (mode === 'upload') {
        title = dialogTitleUpload;
        primaryText = `Upload your master property list (including both 1:1 and many-to-one property mappings) as a XLS or CSV file, including the source-to-target entity mapping from your service providers.`;
      } else {
        title = dialogTitleBulk;
        primaryText = `Bulk upload new properties to your existing master property list (including both 1:1 and many-to-one property mappings) as a XLS or CSV file, including the source-to-target entity mapping from your service providers.`;
      }

      confirmFunction({
        title,
        content: (
          <div style={{ fontFamily: theme.typography.fontFamily }}>
            <p>{primaryText}</p>
            <p>Please include the fields in your upload:</p>
            <ul>
              <li>Source Entity ID</li>
              <li>
                Target Entity ID
                <span style={{ color: theme.palette.error.main }}>&nbsp;*</span>
              </li>
              <li>Source Entity Name</li>
              <li>Target Entity Name</li>
            </ul>
            <p style={{ color: theme.palette.grey[700] }}>
              Fields marked with asterisks (*) are mandatory.
            </p>
          </div>
        ),
        confirmLabel: 'Continue',
        handleConfirm: () => {
          push(`/dsp/upload-property${newIngest ? '-new' : ''}`);
        },
      });
    }
  );

  if (isAdminUploadPropertyEnabled) {
    return (
      <Button
        startIcon={<CloudUploadIcon />}
        onClick={() => onUploadClick()}
        variant='contained'
        color='primary'
        sx={{ marginTop: '12px' }}
      >
        <span>{mode === 'upload' ? buttonLabelUpload : buttonLabelBulk}</span>
      </Button>
    );
  }

  return null;
};
