import { Radio, RadioGroup, Autocomplete } from '@cherre-frontend/ui';
import React from 'react';
import { $providers } from './recoil';
import { $reassignUserDetails } from 'src/products/data-submission-portal/components/ReassignUserModal/recoil';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { constSelector } from 'recoil';

export const PARENT_OPTION_TEST_ID = 'PARENT_OPTION_TEST_ID';
export const OTHER_OPTION_TEST_ID = 'OTHER_OPTION_TEST_ID';
export const PROVIDER_AUTOCOMPLETE_TEST_ID = 'PROVIDER_AUTOCOMPLETE_TEST_ID';

export type NewProviderSelectionProps = {
  userId: number;
  selectedProviderId: number | null;
  onChange: (providerId: number | null) => void;
};

const NewProviderSelection: React.FC<NewProviderSelectionProps> = ({
  userId,
  selectedProviderId,
  onChange,
}) => {
  const { organization } = useCherreValue($reassignUserDetails(userId)) || {};
  return (
    <>
      <RadioGroup
        row
        value={selectedProviderId === null ? 'parent' : 'child'}
        onChange={(_, v) => onChange(v === 'parent' ? null : -1)}
        sx={{ marginBottom: '8px' }}
      >
        <Radio
          label={
            <span className='suspend'>
              Assign User to Parent Organization{' '}
              <b>{organization?.organization_label}</b>
            </span>
          }
          testID={PARENT_OPTION_TEST_ID}
          value={'parent'}
        />
        <Radio
          label={<span className='suspend'>Other Service Provider</span>}
          testID={OTHER_OPTION_TEST_ID}
          value={'child'}
        />
      </RadioGroup>
      <Autocomplete
        placeholder='Service Provider Name'
        selector={(search: string | null) =>
          organization
            ? $providers({
                organization_id: organization.organization_id,
                search,
              })
            : constSelector([])
        }
        getSelectedOption={(option) => option.value === selectedProviderId}
        disabled={selectedProviderId === null}
        onChange={(_, v) => onChange(v ? v.value : -1)}
        testID={PROVIDER_AUTOCOMPLETE_TEST_ID}
      />
    </>
  );
};

export default NewProviderSelection;
