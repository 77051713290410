/**
 * @generated SignedSource<<599cd5af10063f5bfad3f32e26c09548>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _sys_property_create_or_update_input = {
  address?: string | null;
  custom_attributes?: any | null;
  fund?: string | null;
  property_id?: number | null;
  provider?: number | null;
  source_entity_id: string;
  source_entity_name: string;
  target_entity_id: string;
  target_entity_name: string;
  type?: string | null;
};
export type useUpsertPropertyNewMutation$variables = {
  input: _sys_property_create_or_update_input;
};
export type useUpsertPropertyNewMutation$data = {
  readonly _sys_property_create_or_update: {
    readonly property_id: number | null;
  } | null;
};
export type useUpsertPropertyNewMutation = {
  response: useUpsertPropertyNewMutation$data;
  variables: useUpsertPropertyNewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "_sys_property_create_or_update_output",
    "kind": "LinkedField",
    "name": "_sys_property_create_or_update",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpsertPropertyNewMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpsertPropertyNewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "50ac3728b603a078b2a0e926c2603434",
    "id": null,
    "metadata": {},
    "name": "useUpsertPropertyNewMutation",
    "operationKind": "mutation",
    "text": "mutation useUpsertPropertyNewMutation(\n  $input: _sys_property_create_or_update_input!\n) {\n  _sys_property_create_or_update(input: $input) {\n    property_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea629b3e69ca90a69de7b5648cf473ab";

export default node;
