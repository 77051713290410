import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useIsSuspended,
  useCherrePartialState,
} from '@cherre-frontend/data-fetching';
import { disableUserDialogOpenState, selectedUserState } from '../recoil';
import {
  Dialog,
  DialogProps,
  styled,
  Panel,
  Typography,
  Button,
  Grid,
} from '@cherre-frontend/ui';
import { useAppContext } from '@cherre-frontend/core';
import { getProviderOrganization } from '../../../queries/getProviderOrganization';

const Container = styled<React.FC<DialogProps & { width: string }>>(Dialog)`
  & .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: ${(props) => props.width}px;
    max-width: ${(props) => props.width}px;
  }
`;

const DisableUserModal: React.FC = () => {
  const { logger } = useAppContext();

  const isModalOpen = useCherreValue(disableUserDialogOpenState)!;
  const suspended = useIsSuspended();

  const selectedUser = useCherrePartialState(selectedUserState);

  const onClose = useCherreEventWithRecoil(
    'user closed create batch dialog',
    (ctx) => () => {
      ctx.recoil.set(disableUserDialogOpenState, false);
    }
  );

  logger.info('current dialog stage', suspended);

  const organization = useCherreValue(getProviderOrganization());

  const context = useAppContext();

  const toggleDisableState = async () => {
    if (selectedUser[0]?.status) {
      fetch(
        `/api/v1/organizations/${organization}/users/${selectedUser[0]?.user_id}/re-enable`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        context?.showSnackbar({
          type: 'success',
          message: `User: ${selectedUser[0]?.user_first_name} ${selectedUser[0]?.user_last_name} re-enabled`,
        });
        onClose();
      });
    } else {
      fetch(
        `/api/v1/organizations/${organization}/users/${selectedUser[0]?.user_id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        context?.showSnackbar({
          type: 'success',
          message: `User: ${selectedUser[0]?.user_first_name} ${selectedUser[0]?.user_last_name} disabled`,
        });
        onClose();
      });
    }
  };

  return (
    <Container open={Boolean(isModalOpen)} width={'520'} onClose={onClose}>
      <Panel id='DisableUserDialog' config={{ logLevel: false }}>
        <Typography variant='h5' fontWeight='600' sx={{ paddingBottom: '8px' }}>
          {selectedUser[0]?.status ? 'Enable' : 'Disable'} User
        </Typography>{' '}
        <Typography variant='body1' sx={{ paddingBottom: '15px' }}>
          Are you sure you want to{' '}
          {selectedUser[0]?.status ? 'enable' : 'disable'}{' '}
          <strong>
            {selectedUser[0]?.user_first_name} {selectedUser[0]?.user_last_name}
          </strong>
          ?
        </Typography>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          gap='24px'
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button variant='contained' onClick={() => toggleDisableState()}>
            {selectedUser[0]?.status ? 'Enable' : 'Disable'}
          </Button>
        </Grid>
      </Panel>
    </Container>
  );
};

export default DisableUserModal;
