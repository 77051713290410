import {
  graphQLSelector,
  GraphQLReturn,
  NotFoundError,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { SingleFileValidationSummaryRoute } from 'src/products/data-submission-portal/routes';
import { queryFileInfoQuery } from './__generated__/queryFileInfoQuery.graphql';

export const fileInfo = graphQLSelector({
  query: graphql`
    query queryFileInfoQuery($dataset_id: Int!) {
      sys_property_batch_datasets(
        where: {
          property_batch_dataset_id: { _eq: $dataset_id }
          file_id: { _is_null: false }
          is_valid: { _is_null: false }
        }
      ) {
        is_valid
        validated_datetime
        batch_file {
          file_name
          submitter {
            sakura_user {
              first_name
              last_name
            }
          }
        }
        property_batch {
          submission {
            submission_name
          }
        }
        warning_count: property_batch_dataset_validations_aggregate(
          where: { is_valid: { _eq: false }, validation_mode: { _eq: "soft" } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<queryFileInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(SingleFileValidationSummaryRoute.routeParamSelector),
  mapResponse: (rawResponse, variables) => {
    const dataset = rawResponse.sys_property_batch_datasets[0];
    if (!dataset) {
      throw new NotFoundError(
        `dataset with dataset_id ${variables.dataset_id} not found`
      );
    }
    const submission = dataset.property_batch.submission;
    if (!submission) {
      throw new NotFoundError(
        `submission with dataset_id ${variables.dataset_id} not found`
      );
    }
    const file = dataset.batch_file;
    if (!file) {
      throw new NotFoundError(
        `file with dataset_id ${variables.dataset_id} not found`
      );
    }
    const submitter = file.submitter?.sakura_user;
    if (!submitter) {
      throw new NotFoundError(
        `submitter with dataset_id ${variables.dataset_id} not found`
      );
    }
    return {
      fileName: file.file_name,
      submissionBatch: submission.submission_name,
      addedBy: `${submitter.first_name} ${submitter.last_name}`,
      checkedOn: dataset.validated_datetime,
      isValid: dataset.is_valid,
      warningCount: dataset.warning_count.aggregate?.count,
    };
  },
});
