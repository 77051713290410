/**
 * @generated SignedSource<<6571309876f339909e2f3bb0fb0803c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type toggle_reminder_resolved_params = {
  is_resolved?: boolean | null;
  reminder_id?: number | null;
};
export type recoilmarkReminderResolvedMutation$variables = {
  params: toggle_reminder_resolved_params;
};
export type recoilmarkReminderResolvedMutation$data = {
  readonly _sys_toggle_reminder_resolved: {
    readonly reminder_ids: ReadonlyArray<number>;
  };
};
export type recoilmarkReminderResolvedMutation = {
  response: recoilmarkReminderResolvedMutation$data;
  variables: recoilmarkReminderResolvedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "toggle_reminder_resolved_output",
    "kind": "LinkedField",
    "name": "_sys_toggle_reminder_resolved",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reminder_ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilmarkReminderResolvedMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilmarkReminderResolvedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "659f2563741714b3e3533c2bd594fec7",
    "id": null,
    "metadata": {},
    "name": "recoilmarkReminderResolvedMutation",
    "operationKind": "mutation",
    "text": "mutation recoilmarkReminderResolvedMutation(\n  $params: toggle_reminder_resolved_params!\n) {\n  _sys_toggle_reminder_resolved(params: $params) {\n    reminder_ids\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d1f2d25804492ce26f27a6896860283";

export default node;
