import { groupBy, orderBy } from 'lodash';
import { JsonLogic, JsonLogicData } from './types';

export type AggregationFields<T> = Record<keyof T, unknown>;

const getAggregationRows = <T>(
  data: JsonLogicData<T>,
  keyList: (keyof T)[],
  aggregationFields: AggregationFields<T>,
  groupingCode: number,
  jsonLogic: JsonLogic
): T[] => {
  // group by the key list
  const iteratee = (row: T) => keyList.map((key) => row[key]);
  const groups = groupBy(data.tableData, iteratee);

  const aggregationRows = Object.values(groups).map((rows) => {
    // Initializing the aggregation row
    const ret = {
      ...Object.fromEntries(keyList.map((key) => [key, rows[0][key]])),
      grouping_code: groupingCode,
      ...Object.fromEntries(
        Object.entries(aggregationFields).map(([field]) => [field, 0])
      ),
    } as T;

    for (const [field, logic] of Object.entries(aggregationFields)) {
      ret[field] = jsonLogic(logic, { ...data, tableData: rows });
    }

    return ret;
  });
  return aggregationRows;
};

type OrderBy = { id: string; desc: boolean }[];

export const aggregateBy = <T>(
  data: JsonLogicData<T>,
  keys: (keyof T)[],
  aggregationFields: AggregationFields<T>,
  order: OrderBy,
  jsonLogic: JsonLogic
): T[] => {
  const aggregatedData: T[] = data.tableData.map((d) => ({
    ...d,
    grouping_code: 0,
  }));

  // loop through keys and aggregate
  // the grouping code is used to order the aggregation rows.
  // e.g keys = [entity, account] wil create two grouping sets (entity, account) with grouping_code 1 and (entity) with grouping_code 2
  let groupingCode = keys.length;
  for (let i = 0; i < keys.length; i++) {
    const aggregationRows = getAggregationRows(
      data,
      keys.slice(0, i + 1), //aggregates by grouping sets, required for the order by to work
      aggregationFields,
      groupingCode,
      jsonLogic
    );
    aggregatedData.push(...aggregationRows);
    groupingCode--;
  }

  return orderBy(
    aggregatedData,
    [...keys, 'grouping_code'],
    [
      ...keys.map((key) =>
        order.find((o) => o.id === key)?.desc ? 'desc' : 'asc'
      ),
      'asc',
    ]
  );
};
