/**
 * @generated SignedSource<<fb3e7530677ae8d0bb7a0ef278365978>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilSubmissionBatchKPIsQuery$variables = {
  investment_slug: string;
  submission_id?: number | null;
};
export type recoilSubmissionBatchKPIsQuery$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly kpis: {
      readonly aggregate: {
        readonly kpi_properties_total_count: number;
        readonly sum: {
          readonly kpi_datasets_total_count: number | null;
          readonly kpi_datasets_uploaded_count: number | null;
          readonly kpi_package_reports_reviewed_count: number | null;
          readonly kpi_package_reports_total_count: number | null;
          readonly kpi_properties_approved_count: number | null;
          readonly kpi_properties_approved_or_rejected_count: number | null;
          readonly kpi_properties_submitted_count: number | null;
          readonly kpi_validations_failed_count: number | null;
          readonly kpi_validations_warn_count: number | null;
        } | null;
      } | null;
    };
  }>;
};
export type recoilSubmissionBatchKPIsQuery = {
  response: recoilSubmissionBatchKPIsQuery$data;
  variables: recoilSubmissionBatchKPIsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "investment_slug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submission_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": "kpis",
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_neq",
                            "variableName": "investment_slug"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "property_type_slug"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "property_type"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "sys_property_batches_aggregate",
        "kind": "LinkedField",
        "name": "property_batches_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batches_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": "kpi_properties_total_count",
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_property_batches_sum_fields",
                "kind": "LinkedField",
                "name": "sum",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_package_reports_reviewed_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_validations_warn_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_validations_failed_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_datasets_uploaded_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_datasets_total_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_package_reports_total_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_properties_submitted_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_properties_approved_count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kpi_properties_approved_or_rejected_count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilSubmissionBatchKPIsQuery",
    "selections": (v2/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilSubmissionBatchKPIsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "52141fe897fd52c680c68b24fcd63656",
    "id": null,
    "metadata": {},
    "name": "recoilSubmissionBatchKPIsQuery",
    "operationKind": "subscription",
    "text": "subscription recoilSubmissionBatchKPIsQuery(\n  $submission_id: Int\n  $investment_slug: String!\n) {\n  sys_submissions(where: {submission_id: {_eq: $submission_id}}) {\n    kpis: property_batches_aggregate(where: {property: {property_type: {property_type_slug: {_neq: $investment_slug}}}}) {\n      aggregate {\n        kpi_properties_total_count: count\n        sum {\n          kpi_package_reports_reviewed_count\n          kpi_validations_warn_count\n          kpi_validations_failed_count\n          kpi_datasets_uploaded_count\n          kpi_datasets_total_count\n          kpi_package_reports_total_count\n          kpi_properties_submitted_count\n          kpi_properties_approved_count\n          kpi_properties_approved_or_rejected_count\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2aee7cfbcdf953aad60605e6b83152c5";

export default node;
