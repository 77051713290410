import {
  GraphQLReturn,
  atom,
  selector,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { recoilDisableProviderMutation } from './__generated__/recoilDisableProviderMutation.graphql';
import { recoilProviderHasSettingsQuery } from './__generated__/recoilProviderHasSettingsQuery.graphql';
import { getSubmissionBatches } from '../../../submissions/recoil';
import { graphql } from 'react-relay';

export const providerIdToDisableAtom = atom<number>({
  key: 'provider-id-to-disable',
  default: 0,
});

export const openPropertyBatchesFromProvider = selector({
  key: 'open-property-batches-from-provider',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const providerId = get(providerIdToDisableAtom);
      if (!providerId) {
        return null;
      }
      return get(
        getSubmissionBatches({
          is_closed: false,
          provider_id: providerId,
          pageIndex: 0,
          pageSize: 10,
          order_by: [{ submission_name: 'desc' }],
        })
      );
    },
});

export const disableProviderMutation = graphql`
  mutation recoilDisableProviderMutation($provider_id: Int!) {
    _sys_disable_provider(params: { provider_id: $provider_id }) {
      provider_id
      user_ids
      user_ids_to_disable_sakura
      property_ids
      mapping_set_ids
    }
  }
` as GraphQLReturn<recoilDisableProviderMutation>;

export const providerHasSettings = graphQLSelector({
  query: graphql`
    query recoilProviderHasSettingsQuery($contains: jsonb!) {
      sys_providers(
        where: {
          ingestion_config: { _contains: $contains }
          is_active: { _eq: true }
        }
        limit: 1
      ) {
        provider_id
      }
    }
  ` as GraphQLReturn<recoilProviderHasSettingsQuery>,
  mapVariables: () => () => ({
    contains: {
      bulk_approve_submit_properties: true,
    },
  }),
  mapResponse: (resp) => {
    return resp.sys_providers.length > 0;
  },
});
