import React from 'react';
import {
  Grid,
  PageContainer,
  PageContent,
  PageHeader,
  Button,
} from '@cherre-frontend/ui';
import Box from '@material-ui/core/Box';
import { Link, useHistory } from 'react-router-dom';
import { HeaderGrid, ProviderDetailTabs, ProviderDetailTab } from './styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import grey from '@material-ui/core/colors/grey';
import UsersTab from './Panels/UsersTab/UsersTabPanel';
import MappingsTab from './Panels/MappingsTab/MappingsTabPanel';
import PropertiesTab from './Panels/PropertiesTab/PropertiesTabPanel';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { getProvider } from './queries/getProvider';
import { providerDetailsTabState, reassignUserModalState } from './recoil';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';
import ReassignUserModal from '../../components/ReassignUserModal';

export enum ProviderDetailTabValues {
  USERS = 'Users',
  MAPPINGS = 'Mappings',
  PROPERTIES = 'Properties',
}

const ProviderDetailPage: React.FC = () => {
  const [tab, setTab] = useCherreState(providerDetailsTabState);
  const provider = useCherreValue(getProvider());
  const { push } = useHistory();

  return (
    <>
      <HeaderGrid container direction='row' justifyContent='flex-start'>
        <IconButton
          style={{ height: '48px', alignSelf: 'center' }}
          component={Link}
          to='/dsp/providers'
        >
          <ArrowBack style={{ fill: grey[900] }} />
        </IconButton>
        <PageHeader
          title={provider?.provider_name as string}
          subtitle={provider?.provider_type?.name as string}
        >
          <Grid container direction='row' gap={1}>
            {/* <DisableButton variant='outlined' color='primary'>
              <LockIcon style={{ height: '15px', alignSelf: 'center' }} />
              Disable
            </DisableButton> */}
            <FeatureFlagGate flag='DSPAddEditProvider'>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'white' }}
                onClick={() =>
                  push(`/dsp/providers/${provider?.provider_id}/edit`)
                }
              >
                Edit
              </Button>
            </FeatureFlagGate>
          </Grid>
        </PageHeader>
      </HeaderGrid>
      <PageContent style={{ paddingTop: 0 }}>
        <Grid
          container
          direction='column'
          alignItems='center'
          style={{ height: '100%' }}
        >
          <ProviderDetailTabs
            indicatorColor='primary'
            textColor='primary'
            variant='standard'
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
          >
            <ProviderDetailTab
              value={ProviderDetailTabValues.USERS}
              label={ProviderDetailTabValues.USERS}
              style={{ minWidth: 'unset' }}
            />
            <ProviderDetailTab
              value={ProviderDetailTabValues.MAPPINGS}
              label={ProviderDetailTabValues.MAPPINGS}
              style={{ minWidth: 'unset' }}
            />
            <ProviderDetailTab
              value={ProviderDetailTabValues.PROPERTIES}
              label={ProviderDetailTabValues.PROPERTIES}
              style={{ minWidth: 'unset' }}
            />
          </ProviderDetailTabs>
          <Box style={{ flexGrow: 1, width: '100%' }}>
            {tab === ProviderDetailTabValues.USERS && <UsersTab />}
            {tab === ProviderDetailTabValues.MAPPINGS && <MappingsTab />}
            {tab === ProviderDetailTabValues.PROPERTIES && <PropertiesTab />}
          </Box>
        </Grid>
      </PageContent>
      <ReassignUserModal selector={reassignUserModalState} />
    </>
  );
};

const ProviderDetailWrapper: React.FC = () => {
  return (
    <PageContainer id='dsp/provider-detail-page'>
      <ProviderDetailPage />
    </PageContainer>
  );
};

ProviderDetailWrapper.displayName = 'ProviderDetailWrapper';

export default ProviderDetailWrapper;
