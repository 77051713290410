import React from 'react';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { ConfirmationModal, useConfirmationModal } from '@cherre-frontend/ui';
import { SinglePropertyBatchAccordionProps } from '../types';
import { PropertyBatchBaseAccordion } from '../PropertyBatchAccordion.base';
import { getPreparers, getWorkflowAvailableActions } from '../recoil';
import { useTabsConfigReviewer } from './useTabsConfigReviewer';
import { ReviewerAlert } from './ReviewerAlert';
import { getActionFromStatus, getStatusFromAction } from './utils';
import { useWorkflowApplyActionToBatch } from '../hooks/useWorkflowApplyActionToBatch';

export const SinglePropertyBatchReviewerAccordion: React.FC<
  SinglePropertyBatchAccordionProps
> = ({ propertyBatch, investmentPropertyBatch }) => {
  const {
    properties_flattened_union: { properties_roles_users },
  } = propertyBatch;

  const { modalProps, confirm } = useConfirmationModal();

  const hasTSA = !!investmentPropertyBatch?.property_batch_datasets.length;

  const tabConfig = useTabsConfigReviewer(
    propertyBatch,
    confirm,
    !!investmentPropertyBatch,
    hasTSA,
    investmentPropertyBatch?.property_batch_id
  );

  const preparers = useCherreValue(
    getPreparers({
      user_ids: properties_roles_users.map((p) => p.user_id),
    })
  );

  const preparersText = preparers?.sys_users
    ?.map(
      ({ sakura_user }) =>
        `${sakura_user?.first_name} ${sakura_user?.last_name}`
    )
    .join(', ');

  const hasPreparers = !!preparers?.sys_users?.length;

  const workflowAvailableActions =
    investmentPropertyBatch &&
    useCherreValue(
      getWorkflowAvailableActions(investmentPropertyBatch.property_batch_id)
    );

  const status = getStatusFromAction(workflowAvailableActions as string[]);

  const submitProperty =
    investmentPropertyBatch &&
    useWorkflowApplyActionToBatch(
      investmentPropertyBatch.property_batch_id,
      getActionFromStatus(status)
    );

  return (
    <>
      <ConfirmationModal {...modalProps} />
      <PropertyBatchBaseAccordion
        {...tabConfig}
        investmentPropertyBatch={investmentPropertyBatch}
        propertyBatch={propertyBatch}
        alertComponent={
          investmentPropertyBatch && submitProperty ? (
            <ReviewerAlert
              submitStatus={status}
              submitProperty={submitProperty}
              showTSAText={hasTSA}
              isGrouped={false}
            />
          ) : null
        }
        accordionSubtitleComponent={
          hasPreparers ? <>Preparer: {preparersText} • </> : null
        }
      />
    </>
  );
};
