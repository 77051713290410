/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCherreValue } from '@cherre-frontend/data-fetching';

import { Box, Panel } from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import {
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
} from '../../../../recoil/dialog';
import FinancialReviewersForm from './FinancialReviewersForm';
import OperationalReviewersForm from './OperationalReviewersForm';

const ManageUsersReviewerTab: React.FC = () => {
  const selectedFinancialReviewers = useCherreValue(
    selectedFinancialReviewerUsers
  );

  const selectedOperationalReviewers = useCherreValue(
    selectedOperationalReviewerUsers
  );

  const formIsEmpty = useMemo(() => {
    return (
      selectedFinancialReviewers?.reviewer1!.length === 0 &&
      selectedFinancialReviewers?.reviewer2!.length === 0 &&
      selectedFinancialReviewers?.reviewer3!.length === 0 &&
      selectedOperationalReviewers?.reviewer1!.length === 0 &&
      selectedOperationalReviewers?.reviewer2!.length === 0 &&
      selectedOperationalReviewers?.reviewer3!.length === 0
    );
  }, [selectedFinancialReviewers, selectedOperationalReviewers]);

  return (
    <Panel id='manage-users-preparers-tab-container'>
      <Box
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: formIsEmpty ? 'center' : 'flex-start',
          flexDirection: 'column',
          gap: '16px',
          overflow: 'auto',
        }}
      >
        <OperationalReviewersForm />
        <FinancialReviewersForm />
      </Box>
    </Panel>
  );
};

export default ManageUsersReviewerTab;
