import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getSakuraUsersInfoQuery } from './__generated__/getSakuraUsersInfoQuery.graphql';
export const getSakuraUsersInfo = graphQLSelector({
  query: graphql`
    query getSakuraUsersInfoQuery($users: [Int!]!) {
      sakura_user(
        where: { id: { _in: $users } }
        order_by: [{ first_name: asc }, { last_name: asc }]
      ) {
        user_id: id
        first_name
        last_name
      }
    }
  ` as GraphQLReturn<getSakuraUsersInfoQuery>,
  resetCache: false,
  mapResponse: (response) => {
    return response.sakura_user;
  },
});
