import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDeleteMasterChartOfAccountMutation } from './__generated__/useDeleteMasterChartOfAccountMutation.graphql';

export const useDeleteMasterChartOfAccount = (
  master_chart_of_account_id: number,
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useDeleteMasterChartOfAccountMutation(
        $master_chart_of_account_id: Int!
      ) {
        update_sys_organization_master_chart_of_account_values(
          _set: { is_active: false }
          where: {
            master_chart_of_account_id: { _eq: $master_chart_of_account_id }
          }
        ) {
          affected_rows
        }
        update_sys_organization_master_chart_of_accounts(
          _set: { is_active: false }
          where: {
            master_chart_of_account_id: { _eq: $master_chart_of_account_id }
          }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useDeleteMasterChartOfAccountMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        return { master_chart_of_account_id };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
