import {
  GraphQLReturn,
  graphQLSelector,
  Writeable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getUserListQuery } from './__generated__/getUserListQuery.graphql';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';

export type User = Writeable<getUserListQuery['response']['sys_users'][number]>;

export const getUserList = graphQLSelector({
  query: graphql`
    query getUserListQuery($id: Int!, $provider_id: Int!) {
      sys_users(
        where: {
          organization_id: { _eq: $id }
          _or: [
            { provider_id: { _eq: $provider_id } }
            { provider_id: { _is_null: true } }
          ]
          sakura_user: { disabled: { _eq: false } }
          is_active: { _eq: true }
        }
        order_by: [
          { sakura_user: { first_name: asc } }
          { sakura_user: { last_name: asc } }
        ]
      ) {
        user_id
        sakura_user {
          first_name
          last_name
          email
        }
      }
    }
  ` as GraphQLReturn<getUserListQuery>,
  mapVariables:
    (provider_id: number) =>
    ({ get }) => {
      return {
        id: get(getDSPUser()).organization_id,
        provider_id: provider_id,
      };
    },
  mapResponse: (response) => response.sys_users,
});
