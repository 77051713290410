import React from 'react';
import { styled, Grid } from '@cherre-frontend/ui';

const ActionsContainer = styled(Grid)``;

export const DialogActions: React.FC = (props) => {
  return (
    <ActionsContainer container columnSpacing='10px'>
      <Grid item xs />
      {React.Children.map(props.children, (child) => (
        <Grid item xs='auto'>
          {child}
        </Grid>
      ))}
    </ActionsContainer>
  );
};

DialogActions.displayName = 'DialogActions';
