import { DefaultValue, atom, selector } from '@cherre-frontend/data-fetching';
import { requiredValidationSlugs } from './consts';
import { getDatasets } from './queries/getDatasets';
import { $getPropertyDatasets } from './queries/getPropertyDatasets';
import { Property } from '../../pages/provider-detail/Panels/PropertiesTab/queries/getProperties';
import { Property as PropertiesPageProperty } from 'src/products/data-submission-portal/pages/properties/Panels/PropertiesPanel/queries/getProperties';

const PANEL_NAMESPACE =
  'data-submission-portal/provider-detail/assign-datasets-dialog';

export const namespace = (key: string) => `${PANEL_NAMESPACE}/${key}`;

export const assignDatasetsToPropertyIdState = atom<number | null>({
  key: namespace('assign-datasets-to-property-id'),
  default: null,
});

export const selectedDatasetIdsState = atom<number[]>({
  key: namespace('selected-dataset-ids'),
  default: [],
});

export const selectedDatasetsState = selector({
  key: namespace('selected-datasets'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedIds = get(selectedDatasetIdsState);
      const datasets = get(getDatasets({}));
      return datasets
        .filter((dataset) => selectedIds.includes(dataset.dataset_id))
        .sort((a, b) => a.dataset_label.localeCompare(b.dataset_label));
    },
});

const datasetTemplateIdInitialState = selector<Record<number, string>>({
  key: namespace('initial-selected-dataset-template-id'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const propertyId = get(assignDatasetsToPropertyIdState);

      if (propertyId === null) {
        return {};
      }

      // if a single property is selected, get the template id for each dataset
      const datasetIds = get(selectedDatasetIdsState);
      const propertyDatasets = get(
        $getPropertyDatasets({
          dataset_ids: datasetIds,
          property_id: propertyId,
        })
      );
      const keyValue = propertyDatasets.map((propertyDataset) => [
        propertyDataset.dataset_id,
        propertyDataset.template_id,
      ]);
      return Object.fromEntries(keyValue);
    },
});

/**
 * This atom stores the unified ingest template id that is selected for each dataset.
 * { [datasetId]: datasetTemplateId }
 */
export const datasetTemplateIdState = atom<Record<number, string | null>>({
  key: namespace('internal-selected-dataset-template-id'),
  scoped: true,
  default: datasetTemplateIdInitialState,
});

/**
 * This atom stores the validation rule ids that are enabled.
 * Example:
 * {
 *    1: 'soft',
 *    2: 'hard',
 * }
 */
export const enabledValidationsState = atom<{
  tsa: Record<number, 'soft' | 'hard'>;
  standard: Record<number, 'soft' | 'hard'>;
}>({
  key: namespace('enabled-validations'),
  default: {
    tsa: {},
    standard: {},
  },
});

export const enabledBatchValidationsState = atom<
  Record<number, 'soft' | 'hard'>
>({
  key: namespace('enabled-batch-validations'),
  default: {},
});

export enum Stage {
  SelectDatasets = 'SelectDatasets',
  SelectDatasetTemplate = 'SelectDatasetTemplate',
  SelectValidations = 'SelectValidations',
  SelectCrossFileValidations = 'SelectCrossFileValidations',
}

const dialogStage_INTERNAL = atom<Stage>({
  key: namespace('dialog-stage-internal'),
  default: Stage.SelectDatasets,
});

export const dialogStage = selector<Stage>({
  key: namespace('dialog-stage'),
  get: ({ get }) => {
    return get(dialogStage_INTERNAL);
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // stage is being reset
      set(dialogStage_INTERNAL, Stage.SelectDatasets);
      return;
    }

    if (newValue === Stage.SelectDatasets) {
      set(dialogStage_INTERNAL, Stage.SelectDatasets);
      return;
    }

    if (newValue === Stage.SelectDatasetTemplate) {
      set(dialogStage_INTERNAL, Stage.SelectDatasetTemplate);
      return;
    }

    if (newValue === Stage.SelectValidations) {
      const datasets = get(selectedDatasetsState);
      const currentValidations = get(enabledValidationsState);

      if (datasets) {
        const currentValidationRules = datasets
          .map((dataset) => dataset.dataset_validation_rules)
          .flat();

        const mandatoryRules = currentValidationRules.filter(
          (rule) =>
            requiredValidationSlugs.includes(rule.validation_slug) &&
            !Object.prototype.hasOwnProperty.call(
              currentValidations?.standard,
              rule.dataset_validation_rule_id
            )
        );

        const mappedMandatoryValidations = mandatoryRules.map((rule) => {
          return { [rule.dataset_validation_rule_id]: 'hard' };
        });

        const mandatoryValidationsObject = mappedMandatoryValidations.reduce(
          (a, v) => ({ ...a, [Object.keys(v)[0]]: Object.values(v)[0] }),
          {}
        ) as Record<number, 'soft' | 'hard'>;

        set(enabledValidationsState, {
          tsa: {
            ...currentValidations?.tsa,
            ...mandatoryValidationsObject,
          },
          standard: {
            ...currentValidations?.standard,
            ...mandatoryValidationsObject,
          },
        });
      }

      set(dialogStage_INTERNAL, Stage.SelectValidations);
      return;
    }

    if (newValue === Stage.SelectCrossFileValidations) {
      set(dialogStage_INTERNAL, Stage.SelectCrossFileValidations);
      return;
    }
    //unrecognized value
    throw new Error(`${newValue} is not a valid dialog stage`);
  },
});

export const assignDatasetsToPropertyState_INTERNAL = atom<Property | null>({
  key: namespace('assign-datasets-to-property-internal'),
  default: null,
});

export const assignDatasetsToPropertyState = selector<
  Property | PropertiesPageProperty | null
>({
  key: namespace('assign-datasets-to-property'),
  get: ({ get }) => {
    return get(assignDatasetsToPropertyState_INTERNAL);
  },
  set: (
    { set },
    newValue: Property | PropertiesPageProperty | null | DefaultValue
  ) => {
    if (newValue instanceof DefaultValue) {
      set(assignDatasetsToPropertyState_INTERNAL, null);
      // Reset all selections
      set(assignDatasetsToPropertyIdState, null);
      set(selectedDatasetIdsState, []);
      set(enabledValidationsState, {
        tsa: {},
        standard: {},
      });
      set(enabledBatchValidationsState, {});
      return;
    }
    set(assignDatasetsToPropertyIdState, newValue?.property_id ?? null);
    // Set all selections to current property's values
    set(
      selectedDatasetIdsState,
      newValue?.properties_datasets.map((dataset) => dataset.dataset_id) ?? []
    );

    set(enabledValidationsState, {
      tsa:
        newValue?.investment_properties_dataset_validation_rules.reduce(
          (acc, curr) => {
            acc[curr.dataset_validation_rule_id] = curr.validation_mode;
            return acc;
          },
          {}
        ) || {},
      standard:
        newValue?.properties_dataset_validation_rules.reduce((acc, curr) => {
          acc[curr.dataset_validation_rule_id] = curr.validation_mode;
          return acc;
        }, {}) || {},
    });
    set(
      enabledBatchValidationsState,
      newValue?.properties_batch_validation_rules.reduce((acc, curr) => {
        acc[curr.batch_validation_rule_id] = curr.validation_mode;
        return acc;
      }, {}) || {}
    );
    set(assignDatasetsToPropertyState_INTERNAL, newValue);

    return;
  },
});
