import { useCallback, useMemo } from 'react';
import { useForm, useMutation } from '@cherre-frontend/data-fetching';
import { CreateBatchInfo, createBatchSchema } from './schema';
import {
  formatErrorMessage,
  formatDueDate,
  formatSubmissionPeriod,
} from './formatters';
import * as options from './options';

import { useCreateBatch } from './useCreateBatch';

export type UseCreateBatchFormParams = {
  initialState?: Partial<CreateBatchInfo>;
  propertyIds?: (type: number) => number[];
  onCompleted?: Parameters<typeof useMutation>[1]['onCompleted'];
};

export const useCreateBatchForm = (params: UseCreateBatchFormParams = {}) => {
  const [formState, setFormState, result] = useForm(createBatchSchema, {
    submission_interval: 'Monthly',
    fiscal_year_flag: false,
    ...(params.initialState || {}),
  });

  const errorMessage = useMemo(formatErrorMessage(result), [result]);

  const dueDate = useCallback(formatDueDate(formState), [formState]);

  const submissionPeriod = useMemo(formatSubmissionPeriod(formState), [
    formState,
  ]);

  const createBatch = useCreateBatch({
    formResult: result,
    propertyIds: params.propertyIds,
    onCompleted: params.onCompleted,
  });

  return {
    formState,
    setFormState,
    isValid: result.success,
    errorMessage,
    dueDate,
    submissionPeriod,
    options: {
      submissionTypes: options.submissionTypeOptions(),
      submissionPeriod: options.submissionPeriodOptions(
        formState.submission_interval
      ),
      dueDate: options.dueDateOptions(),
    },
    createBatch,
    canCreateBatch:
      result.success &&
      params?.propertyIds &&
      !result.data.submission_types.some((type) => {
        try {
          return !params.propertyIds?.(type).length;
        } catch {
          return true;
        }
      }),
  };
};
