import React, { useMemo } from 'react';
import { Table, useTable, styled, useTheme, Panel } from '@cherre-frontend/ui';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import {
  masterFileDetailPageTableSearch,
  masterFileDetailPageTableState,
  masterFileDetailsHasData,
} from '../recoil';
import { getCOADetail } from '../queries/getCOADetail';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export enum FilterItemMap {
  account_code = 'Account Code',
  account_description = 'Account Description',
}

const MasterFilesDetailTable: React.FC = () => {
  const theme = useTheme();

  const setHasData = useCherreSetState(masterFileDetailsHasData);
  const search = useCherreValue(masterFileDetailPageTableSearch);

  // control table
  const [tableState, setTableState] = useCherreState(
    masterFileDetailPageTableState
  );

  const details = useCherreValue(getCOADetail());
  const data = details?.data;
  const total = details?.total;

  const resultMode: 'no_items_found' | 'no_details' | 'table' = useMemo(() => {
    if (data && data.length > 0) {
      return 'table';
    } else if (search) {
      return 'no_items_found';
    } else {
      return 'no_details';
    }
  }, [data]);

  setHasData(resultMode !== 'no_details');

  // control suspend
  const isSuspended = useIsSuspended();
  if (isSuspended && !tableState) {
    return null;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'account_code',
        header: 'GL Account Code',
        size: 50,
      },
      {
        accessorKey: 'account_name',
        header: 'GL Account Description',
        size: 800,
      },
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    state: {
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.master_chart_of_account_values_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  // When has data displays table. Otherwise, when search is empty, displays NoMappings component. Otherwise, displays NoItemsFound component
  return (
    <div style={{ width: '100%' }}>
      {resultMode === 'table' && (
        <div>
          <Container sx={{ width: '100%' }}>
            <Panel id='masterFileDetailTable' style={{ width: '100%' }}>
              <Table table={table} />
            </Panel>
          </Container>
        </div>
      )}
      {(resultMode === 'no_items_found' || resultMode === 'no_details') && (
        <NoItemsFound title='No Row Found' />
      )}
    </div>
  );
};

export default MasterFilesDetailTable;
