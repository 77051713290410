import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadMasterFileMutation } from './__generated__/useUploadMasterFileMutation.graphql';

export const useUploadMasterFile = (
  input: {
    chart_of_accounts_name: string;
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadMasterFileMutation($input: SysUploadMasterFileInput!) {
        _sys_upload_master_file(input: $input) {
          master_chart_of_account_id
        }
      }
    ` as GraphQLReturn<useUploadMasterFileMutation>,
    {
      trackEvent: false,
      mapVariables: (data: { batchId: string }) => async () => {
        return {
          input: {
            file_reference_source: data.batchId,
            chart_of_accounts_name: input.chart_of_accounts_name,
            file_source: input.file_source,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
