import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { ProvidersRoute } from '../../routes';
import { refine } from '@cherre-frontend/data-fetching';

export const providersTableSearch = ProvidersRoute.searchParamsSelectorFamily({
  key: 'search',
  refine: withDefault(string(), ''),
});

export const providersTableState = ProvidersRoute.searchParamsSelectorFamily({
  key: 'table',
  refine: withDefault(
    object({
      pagination: object({
        pageSize: refine.allowedNumbers([25, 50, 100]),
        pageIndex: number(),
      }),
      sorting: writableArray(object({ id: string(), desc: bool() })),
    }),
    {
      pagination: { pageSize: 25, pageIndex: 0 },
      sorting: [{ id: 'provider_name', desc: false }],
    }
  ),
});
