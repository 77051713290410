/**
 * @generated SignedSource<<3e56504b0360b1b02c8a34637d7a4d4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type order_by = "asc" | "asc_nulls_first" | "asc_nulls_last" | "desc" | "desc_nulls_first" | "desc_nulls_last" | "%future added value";
export type sys_providers_order_by = {
  created_at_datetime?: order_by | null;
  ingestion_config?: order_by | null;
  is_active?: order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_aggregate?: sys_properties_aggregate_order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type?: sys_provider_types_order_by | null;
  provider_type_id?: order_by | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_order_by | null;
  related_data_updated_at?: order_by | null;
  report_config?: order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
  users_aggregate?: sys_users_aggregate_order_by | null;
};
export type sys_mapping_sets_aggregate_order_by = {
  avg?: sys_mapping_sets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_sets_max_order_by | null;
  min?: sys_mapping_sets_min_order_by | null;
  stddev?: sys_mapping_sets_stddev_order_by | null;
  stddev_pop?: sys_mapping_sets_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_sets_stddev_samp_order_by | null;
  sum?: sys_mapping_sets_sum_order_by | null;
  var_pop?: sys_mapping_sets_var_pop_order_by | null;
  var_samp?: sys_mapping_sets_var_samp_order_by | null;
  variance?: sys_mapping_sets_variance_order_by | null;
};
export type sys_mapping_sets_avg_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_stddev_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_sum_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_variance_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_organizations_order_by = {
  batch_files_aggregate?: sys_batch_files_aggregate_order_by | null;
  created_at_datetime?: order_by | null;
  datasets_aggregate?: sys_datasets_aggregate_order_by | null;
  flatfile_team_name?: order_by | null;
  mapping_fields_aggregate?: sys_mapping_fields_aggregate_order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_model?: sys_property_models_order_by | null;
  property_model_id?: order_by | null;
  providers_aggregate?: sys_providers_aggregate_order_by | null;
  sakura_organization?: sakura_organization_order_by | null;
  sakura_users_aggregate?: sakura_user_aggregate_order_by | null;
  settings?: order_by | null;
  submission_types_aggregate?: sys_submission_types_aggregate_order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_aggregate_order_by = {
  avg?: sys_batch_files_avg_order_by | null;
  count?: order_by | null;
  max?: sys_batch_files_max_order_by | null;
  min?: sys_batch_files_min_order_by | null;
  stddev?: sys_batch_files_stddev_order_by | null;
  stddev_pop?: sys_batch_files_stddev_pop_order_by | null;
  stddev_samp?: sys_batch_files_stddev_samp_order_by | null;
  sum?: sys_batch_files_sum_order_by | null;
  var_pop?: sys_batch_files_var_pop_order_by | null;
  var_samp?: sys_batch_files_var_samp_order_by | null;
  variance?: sys_batch_files_variance_order_by | null;
};
export type sys_batch_files_avg_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_stddev_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_sum_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_variance_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_datasets_aggregate_order_by = {
  avg?: sys_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_datasets_max_order_by | null;
  min?: sys_datasets_min_order_by | null;
  stddev?: sys_datasets_stddev_order_by | null;
  stddev_pop?: sys_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_datasets_stddev_samp_order_by | null;
  sum?: sys_datasets_sum_order_by | null;
  var_pop?: sys_datasets_var_pop_order_by | null;
  var_samp?: sys_datasets_var_samp_order_by | null;
  variance?: sys_datasets_variance_order_by | null;
};
export type sys_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_mapping_fields_aggregate_order_by = {
  avg?: sys_mapping_fields_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_fields_max_order_by | null;
  min?: sys_mapping_fields_min_order_by | null;
  stddev?: sys_mapping_fields_stddev_order_by | null;
  stddev_pop?: sys_mapping_fields_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_fields_stddev_samp_order_by | null;
  sum?: sys_mapping_fields_sum_order_by | null;
  var_pop?: sys_mapping_fields_var_pop_order_by | null;
  var_samp?: sys_mapping_fields_var_samp_order_by | null;
  variance?: sys_mapping_fields_variance_order_by | null;
};
export type sys_mapping_fields_avg_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_stddev_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_sum_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_variance_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_account_tree_details_aggregate_order_by = {
  avg?: sys_organization_account_tree_details_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organization_account_tree_details_max_order_by | null;
  min?: sys_organization_account_tree_details_min_order_by | null;
  stddev?: sys_organization_account_tree_details_stddev_order_by | null;
  stddev_pop?: sys_organization_account_tree_details_stddev_pop_order_by | null;
  stddev_samp?: sys_organization_account_tree_details_stddev_samp_order_by | null;
  sum?: sys_organization_account_tree_details_sum_order_by | null;
  var_pop?: sys_organization_account_tree_details_var_pop_order_by | null;
  var_samp?: sys_organization_account_tree_details_var_samp_order_by | null;
  variance?: sys_organization_account_tree_details_variance_order_by | null;
};
export type sys_organization_account_tree_details_avg_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_max_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_min_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_sum_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_variance_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_properties_datasets_aggregate_order_by = {
  avg?: sys_properties_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_datasets_max_order_by | null;
  min?: sys_properties_datasets_min_order_by | null;
  stddev?: sys_properties_datasets_stddev_order_by | null;
  stddev_pop?: sys_properties_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_datasets_stddev_samp_order_by | null;
  sum?: sys_properties_datasets_sum_order_by | null;
  var_pop?: sys_properties_datasets_var_pop_order_by | null;
  var_samp?: sys_properties_datasets_var_samp_order_by | null;
  variance?: sys_properties_datasets_variance_order_by | null;
};
export type sys_properties_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_max_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_min_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_roles_users_aggregate_order_by = {
  avg?: sys_properties_roles_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_roles_users_max_order_by | null;
  min?: sys_properties_roles_users_min_order_by | null;
  stddev?: sys_properties_roles_users_stddev_order_by | null;
  stddev_pop?: sys_properties_roles_users_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_roles_users_stddev_samp_order_by | null;
  sum?: sys_properties_roles_users_sum_order_by | null;
  var_pop?: sys_properties_roles_users_var_pop_order_by | null;
  var_samp?: sys_properties_roles_users_var_samp_order_by | null;
  variance?: sys_properties_roles_users_variance_order_by | null;
};
export type sys_properties_roles_users_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_datasets_aggregate_order_by = {
  avg?: sys_property_batch_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_datasets_max_order_by | null;
  min?: sys_property_batch_datasets_min_order_by | null;
  stddev?: sys_property_batch_datasets_stddev_order_by | null;
  stddev_pop?: sys_property_batch_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_datasets_stddev_samp_order_by | null;
  sum?: sys_property_batch_datasets_sum_order_by | null;
  var_pop?: sys_property_batch_datasets_var_pop_order_by | null;
  var_samp?: sys_property_batch_datasets_var_samp_order_by | null;
  variance?: sys_property_batch_datasets_variance_order_by | null;
};
export type sys_property_batch_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_max_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_min_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_aggregate_order_by = {
  avg?: sys_property_batch_stage_transitions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_stage_transitions_max_order_by | null;
  min?: sys_property_batch_stage_transitions_min_order_by | null;
  stddev?: sys_property_batch_stage_transitions_stddev_order_by | null;
  stddev_pop?: sys_property_batch_stage_transitions_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_stage_transitions_stddev_samp_order_by | null;
  sum?: sys_property_batch_stage_transitions_sum_order_by | null;
  var_pop?: sys_property_batch_stage_transitions_var_pop_order_by | null;
  var_samp?: sys_property_batch_stage_transitions_var_samp_order_by | null;
  variance?: sys_property_batch_stage_transitions_variance_order_by | null;
};
export type sys_property_batch_stage_transitions_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_max_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_min_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batches_aggregate_order_by = {
  avg?: sys_property_batches_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batches_max_order_by | null;
  min?: sys_property_batches_min_order_by | null;
  stddev?: sys_property_batches_stddev_order_by | null;
  stddev_pop?: sys_property_batches_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batches_stddev_samp_order_by | null;
  sum?: sys_property_batches_sum_order_by | null;
  var_pop?: sys_property_batches_var_pop_order_by | null;
  var_samp?: sys_property_batches_var_samp_order_by | null;
  variance?: sys_property_batches_variance_order_by | null;
};
export type sys_property_batches_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_max_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_min_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_models_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  is_active?: order_by | null;
  organizations_aggregate?: sys_organizations_aggregate_order_by | null;
  property_model_id?: order_by | null;
  property_model_slug?: order_by | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_aggregate_order_by = {
  avg?: sys_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organizations_max_order_by | null;
  min?: sys_organizations_min_order_by | null;
  stddev?: sys_organizations_stddev_order_by | null;
  stddev_pop?: sys_organizations_stddev_pop_order_by | null;
  stddev_samp?: sys_organizations_stddev_samp_order_by | null;
  sum?: sys_organizations_sum_order_by | null;
  var_pop?: sys_organizations_var_pop_order_by | null;
  var_samp?: sys_organizations_var_samp_order_by | null;
  variance?: sys_organizations_variance_order_by | null;
};
export type sys_organizations_avg_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_max_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_min_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_stddev_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_sum_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_variance_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_property_models_types_aggregate_order_by = {
  avg?: sys_property_models_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_models_types_max_order_by | null;
  min?: sys_property_models_types_min_order_by | null;
  stddev?: sys_property_models_types_stddev_order_by | null;
  stddev_pop?: sys_property_models_types_stddev_pop_order_by | null;
  stddev_samp?: sys_property_models_types_stddev_samp_order_by | null;
  sum?: sys_property_models_types_sum_order_by | null;
  var_pop?: sys_property_models_types_var_pop_order_by | null;
  var_samp?: sys_property_models_types_var_samp_order_by | null;
  variance?: sys_property_models_types_variance_order_by | null;
};
export type sys_property_models_types_avg_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_max_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_min_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_stddev_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_sum_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_variance_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_providers_aggregate_order_by = {
  avg?: sys_providers_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_max_order_by | null;
  min?: sys_providers_min_order_by | null;
  stddev?: sys_providers_stddev_order_by | null;
  stddev_pop?: sys_providers_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_stddev_samp_order_by | null;
  sum?: sys_providers_sum_order_by | null;
  var_pop?: sys_providers_var_pop_order_by | null;
  var_samp?: sys_providers_var_samp_order_by | null;
  variance?: sys_providers_variance_order_by | null;
};
export type sys_providers_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sakura_organization_order_by = {
  additional_props?: order_by | null;
  api_hasura_role?: order_by | null;
  authz_version_id?: order_by | null;
  create_time?: order_by | null;
  dashboards?: order_by | null;
  description?: order_by | null;
  disabled?: order_by | null;
  domOwner?: order_by | null;
  hasura_access_token?: order_by | null;
  hasura_org_name?: order_by | null;
  hasura_role?: order_by | null;
  id?: order_by | null;
  image_url?: order_by | null;
  last_modified?: order_by | null;
  name?: order_by | null;
  organization_permissions_aggregate?: sakura_organization_permission_aggregate_order_by | null;
  plan_expires_at?: order_by | null;
  plan_type?: order_by | null;
  powerbi_api_url?: order_by | null;
  powerbi_client_id?: order_by | null;
  powerbi_client_secret?: order_by | null;
  powerbi_tenant_id?: order_by | null;
  publish_on_website?: order_by | null;
  salesforce_id?: order_by | null;
  slug?: order_by | null;
  sys_organization?: sys_organizations_order_by | null;
  theme_name?: order_by | null;
  website?: order_by | null;
  website_image_url?: order_by | null;
};
export type sakura_organization_permission_aggregate_order_by = {
  avg?: sakura_organization_permission_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_organization_permission_max_order_by | null;
  min?: sakura_organization_permission_min_order_by | null;
  stddev?: sakura_organization_permission_stddev_order_by | null;
  stddev_pop?: sakura_organization_permission_stddev_pop_order_by | null;
  stddev_samp?: sakura_organization_permission_stddev_samp_order_by | null;
  sum?: sakura_organization_permission_sum_order_by | null;
  var_pop?: sakura_organization_permission_var_pop_order_by | null;
  var_samp?: sakura_organization_permission_var_samp_order_by | null;
  variance?: sakura_organization_permission_variance_order_by | null;
};
export type sakura_organization_permission_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_max_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_min_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_aggregate_order_by = {
  avg?: sakura_user_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_user_max_order_by | null;
  min?: sakura_user_min_order_by | null;
  stddev?: sakura_user_stddev_order_by | null;
  stddev_pop?: sakura_user_stddev_pop_order_by | null;
  stddev_samp?: sakura_user_stddev_samp_order_by | null;
  sum?: sakura_user_sum_order_by | null;
  var_pop?: sakura_user_var_pop_order_by | null;
  var_samp?: sakura_user_var_samp_order_by | null;
  variance?: sakura_user_variance_order_by | null;
};
export type sakura_user_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_max_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_min_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_submission_types_aggregate_order_by = {
  avg?: sys_submission_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submission_types_max_order_by | null;
  min?: sys_submission_types_min_order_by | null;
  stddev?: sys_submission_types_stddev_order_by | null;
  stddev_pop?: sys_submission_types_stddev_pop_order_by | null;
  stddev_samp?: sys_submission_types_stddev_samp_order_by | null;
  sum?: sys_submission_types_sum_order_by | null;
  var_pop?: sys_submission_types_var_pop_order_by | null;
  var_samp?: sys_submission_types_var_samp_order_by | null;
  variance?: sys_submission_types_variance_order_by | null;
};
export type sys_submission_types_avg_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_max_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_min_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_stddev_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_sum_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_variance_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submissions_aggregate_order_by = {
  avg?: sys_submissions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submissions_max_order_by | null;
  min?: sys_submissions_min_order_by | null;
  stddev?: sys_submissions_stddev_order_by | null;
  stddev_pop?: sys_submissions_stddev_pop_order_by | null;
  stddev_samp?: sys_submissions_stddev_samp_order_by | null;
  sum?: sys_submissions_sum_order_by | null;
  var_pop?: sys_submissions_var_pop_order_by | null;
  var_samp?: sys_submissions_var_samp_order_by | null;
  variance?: sys_submissions_variance_order_by | null;
};
export type sys_submissions_avg_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_max_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_min_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_sum_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_variance_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_properties_aggregate_order_by = {
  avg?: sys_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_max_order_by | null;
  min?: sys_properties_min_order_by | null;
  stddev?: sys_properties_stddev_order_by | null;
  stddev_pop?: sys_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_stddev_samp_order_by | null;
  sum?: sys_properties_sum_order_by | null;
  var_pop?: sys_properties_var_pop_order_by | null;
  var_samp?: sys_properties_var_samp_order_by | null;
  variance?: sys_properties_variance_order_by | null;
};
export type sys_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_max_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_min_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_provider_types_order_by = {
  id?: order_by | null;
  name?: order_by | null;
};
export type sys_providers_properties_aggregate_order_by = {
  avg?: sys_providers_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_properties_max_order_by | null;
  min?: sys_providers_properties_min_order_by | null;
  stddev?: sys_providers_properties_stddev_order_by | null;
  stddev_pop?: sys_providers_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_properties_stddev_samp_order_by | null;
  sum?: sys_providers_properties_sum_order_by | null;
  var_pop?: sys_providers_properties_var_pop_order_by | null;
  var_samp?: sys_providers_properties_var_samp_order_by | null;
  variance?: sys_providers_properties_variance_order_by | null;
};
export type sys_providers_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_max_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_min_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_users_aggregate_order_by = {
  avg?: sys_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_users_max_order_by | null;
  min?: sys_users_min_order_by | null;
  stddev?: sys_users_stddev_order_by | null;
  stddev_pop?: sys_users_stddev_pop_order_by | null;
  stddev_samp?: sys_users_stddev_samp_order_by | null;
  sum?: sys_users_sum_order_by | null;
  var_pop?: sys_users_var_pop_order_by | null;
  var_samp?: sys_users_var_samp_order_by | null;
  variance?: sys_users_variance_order_by | null;
};
export type sys_users_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type getProvidersQuery$variables = {
  limit?: number | null;
  offset?: number | null;
  order_by?: ReadonlyArray<sys_providers_order_by> | null;
  search?: string | null;
};
export type getProvidersQuery$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly ingestion_config: any | null;
    readonly is_active: boolean;
    readonly provider_id: number;
    readonly provider_name: string;
    readonly provider_type: {
      readonly name: string;
    } | null;
    readonly related_data_updated_at: string;
    readonly updated_at_datetime: string;
    readonly user_count: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
  }>;
};
export type getProvidersQuery = {
  response: getProvidersQuery$data;
  variables: getProvidersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": 0,
  "kind": "LocalArgument",
  "name": "offset"
},
v2 = {
  "defaultValue": {
    "provider_name": "asc"
  },
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": "%",
  "kind": "LocalArgument",
  "name": "search"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset"
      },
      {
        "kind": "Variable",
        "name": "order_by",
        "variableName": "order_by"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "search"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_name"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_provider_types",
        "kind": "LinkedField",
        "name": "provider_type",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updated_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "related_data_updated_at",
        "storageKey": null
      },
      {
        "alias": "user_count",
        "args": null,
        "concreteType": "sys_users_aggregate",
        "kind": "LinkedField",
        "name": "users_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ingestion_config",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getProvidersQuery",
    "selections": (v4/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "getProvidersQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "19c5db42f0d66173b7b25b42475ec416",
    "id": null,
    "metadata": {},
    "name": "getProvidersQuery",
    "operationKind": "query",
    "text": "query getProvidersQuery(\n  $search: String = \"%\"\n  $order_by: [sys_providers_order_by!] = {provider_name: asc}\n  $limit: Int = 10\n  $offset: Int = 0\n) {\n  sys_providers(where: {provider_name: {_ilike: $search}}, order_by: $order_by, limit: $limit, offset: $offset) {\n    is_active\n    provider_id\n    provider_name\n    provider_type {\n      name\n    }\n    updated_at_datetime\n    related_data_updated_at\n    user_count: users_aggregate {\n      aggregate {\n        count\n      }\n    }\n    ingestion_config\n  }\n}\n"
  }
};
})();

(node as any).hash = "37ac193d9fac73bfeaa5787caa746ce5";

export default node;
