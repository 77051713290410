/**
 * @generated SignedSource<<594e5b762d6aa23fe5554c46ae2b4b27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getUnassignedPropertiesQuery$variables = {};
export type getUnassignedPropertiesQuery$data = {
  readonly sys_properties_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getUnassignedPropertiesQuery = {
  response: getUnassignedPropertiesQuery$data;
  variables: getUnassignedPropertiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_eq": true
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "_not": {
            "property_batches": {}
          },
          "properties_datasets": {
            "is_active": (v0/*: any*/)
          },
          "properties_roles_users": {
            "is_active": (v0/*: any*/),
            "property_role_id": {
              "_in": [
                2,
                3,
                4
              ]
            }
          }
        }
      }
    ],
    "concreteType": "sys_properties_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties_aggregate(where:{\"_not\":{\"property_batches\":{}},\"properties_datasets\":{\"is_active\":{\"_eq\":true}},\"properties_roles_users\":{\"is_active\":{\"_eq\":true},\"property_role_id\":{\"_in\":[2,3,4]}}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getUnassignedPropertiesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getUnassignedPropertiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "79f3d06a73e698e8f7f7778f3022b20e",
    "id": null,
    "metadata": {},
    "name": "getUnassignedPropertiesQuery",
    "operationKind": "query",
    "text": "query getUnassignedPropertiesQuery {\n  sys_properties_aggregate(where: {_not: {property_batches: {}}, properties_roles_users: {is_active: {_eq: true}, property_role_id: {_in: [2, 3, 4]}}, properties_datasets: {is_active: {_eq: true}}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7650a07d7f1c814f28bc8f506227430";

export default node;
