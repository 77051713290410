import React from 'react';
import {
  useTable,
  Table,
  MRT_ColumnDef,
  Typography,
  styled,
  tableBaseStyle,
} from '@cherre-frontend/ui';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { useAppContext } from '@cherre-frontend/core';
import { StatusChip } from 'src/products/data-submission-portal/components/StatusChip';
import { Property, tableSort, tableData } from './recoil';

const columns: MRT_ColumnDef<Property>[] = [
  { accessorKey: 'entity_id', header: 'Source ID', size: 0 },
  { accessorKey: 'property_code', header: 'Target ID', size: 0 },
  { accessorKey: 'property_mapping', header: 'Mapping', size: 0 },
  {
    accessorKey: 'entity_name',
    header: 'Name',
    size: 0,
  },
  {
    accessorKey: 'address',
    header: 'Address',
    size: 0,
  },
  {
    accessorKey: 'fund',
    header: 'Fund',
    size: 0,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    size: 0,
  },
  {
    accessorFn: (row) => {
      return row.properties_roles_users
        .reduce?.((acc, curr) => {
          const { first_name, last_name } = curr.user.sakura_user ?? {};
          acc.push(`${first_name} ${last_name}`);
          return acc;
        }, [] as string[])
        .join(', ');
    },
    header: 'Preparer',
    size: 0,
  },
  {
    accessorKey: 'is_active',
    header: 'Status',
    Cell: ({ row }) => <StatusChip active={row.original.is_active} />,
    size: 130,
    muiTableHeadCellProps: (props) => ({
      align: 'right',
      sx: tableBaseStyle.muiTableHeadCellProps(props).sx,
    }),
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

const EmptyRowsFallback = styled(Typography)`
  display: grid;
  place-items: center;
  padding: 50px;
`;

export const PropertiesTable: React.FC = () => {
  const { logger } = useAppContext();
  const [sorting, setSorting] = useCherreState(tableSort);
  logger.info('sort', sorting);

  const properties = useCherreValue(tableData);
  logger.info('properties', properties);

  const table = useTable({
    data: properties,
    columns,
    state: { sorting },
    enableRowSelection: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    onSortingChange: setSorting,
    getRowId: (row) => `${row.property_id}`,
    skeletonRowCount: 5,
    renderEmptyRowsFallback: () => (
      <EmptyRowsFallback>No Properties found</EmptyRowsFallback>
    ),
  });

  return <Table table={table} />;
};

PropertiesTable.displayName = 'PropertiesTable';
