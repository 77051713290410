import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { MasterFileDetailRoute } from 'src/products/data-submission-portal/routes';
import { getMasterChartOfAccountQuery } from './__generated__/getMasterChartOfAccountQuery.graphql';

export const getMasterChartOfAccount = graphQLSelector({
  query: graphql`
    query getMasterChartOfAccountQuery($id: Int!) {
      sys_organization_master_chart_of_accounts(
        where: { master_chart_of_account_id: { _eq: $id } }
      ) {
        master_chart_of_account_id
        chart_of_accounts_name
        updated_at_datetime
        organization {
          organization_label
        }
      }
    }
  ` as GraphQLReturn<getMasterChartOfAccountQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(MasterFileDetailRoute.routeParamSelector).master_file_id;
      return {
        id,
      };
    },
  mapResponse: (resp) => resp.sys_organization_master_chart_of_accounts[0],
});
