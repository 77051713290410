/**
 * @generated SignedSource<<4ff1786ab79030acc8be3cdccba26baa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSystemFlatfileTokenQuery$variables = {
  slug: string;
};
export type getSystemFlatfileTokenQuery$data = {
  readonly _sys_get_system_flatfile_token: {
    readonly api: string;
    readonly mount: string;
    readonly token: string;
  } | null;
};
export type getSystemFlatfileTokenQuery = {
  response: getSystemFlatfileTokenQuery$data;
  variables: getSystemFlatfileTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "flatfile_token_output",
    "kind": "LinkedField",
    "name": "_sys_get_system_flatfile_token",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "api",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSystemFlatfileTokenQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSystemFlatfileTokenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22bc68cfa67ff59cdc674343105ca7f1",
    "id": null,
    "metadata": {},
    "name": "getSystemFlatfileTokenQuery",
    "operationKind": "query",
    "text": "query getSystemFlatfileTokenQuery(\n  $slug: String!\n) {\n  _sys_get_system_flatfile_token(slug: $slug) {\n    api\n    mount\n    token\n  }\n}\n"
  }
};
})();

(node as any).hash = "dec56cc49225c882bebd1e86d91e3364";

export default node;
