import React, { useMemo, useCallback } from 'react';
import { useCherreValue, useCherreState } from '@cherre-frontend/data-fetching';
import { useCherreEvent } from '@cherre-frontend/core';
import { useHistory } from 'react-router';
import {
  IngestionFlow,
  IngestionFlowActiveStepKey,
  IngestionFlowState,
} from 'src/products/ingestion/components/IngestionFlow/IngestionFlow';
import { ingestFlowState } from './recoil';
import {
  TargetSchemaSlug,
  useUnifiedIngestGetTargetSchema,
} from '../../hooks/useGetTargetSchema';
import { addEditMappingSetFormState } from '../provider-detail/Panels/MappingsTab/recoil';
import { useUploadPropertyNew } from './hooks/useUploadPropertyNew';
import { useSelector } from 'react-redux';

const UploadPropertyPage: React.FC = () => {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();

  const target_schema = useUnifiedIngestGetTargetSchema(
    TargetSchemaSlug.PROPERTY
  );

  const mappingForm = useCherreValue(addEditMappingSetFormState);

  if (!mappingForm) {
    throw new Error('Mapping form is not set');
  }

  const pushOrGoBack = useCallback(() => {
    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      push(`/dsp/properties`);
    }
  }, [location.state?.shouldGoBack]);

  const [flowState, setFlowState] = useCherreState(ingestFlowState);

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);
  if (!ownerName) {
    console.error('Missing ownerName');
    pushOrGoBack();
    return null;
  }

  const onUploadPropertyComplete = useUploadPropertyNew(
    {
      file_source: 'unified',
    },
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Properties uploaded successfully.',
      });
      goBack();
    }
  );

  const onComplete = useCherreEvent(
    'onCompleteUploadPropertyFile',
    async (file_reference_source: string) => {
      onUploadPropertyComplete(`${ownerName}/${file_reference_source}`);
    }
  );

  const state = useMemo(
    () => ({
      fileId: flowState?.fileId ?? undefined,
      signedUrl: flowState?.signedUrl ?? undefined,
      selectedSheet: flowState?.selectedSheet ?? undefined,
      activeStepKey: (flowState?.activeStepKey ??
        'upload') as IngestionFlowActiveStepKey,
      selectedHeaderRows: flowState?.selectedHeaderRows ?? undefined,
      validationResults: flowState?.validationResults ?? undefined,
    }),
    [flowState]
  );

  const setState = useCallback(
    (newState: React.SetStateAction<IngestionFlowState>) => {
      setFlowState((prev) => ({
        ...prev,
        ...(typeof newState === 'function'
          ? newState({
              fileId: prev?.fileId ?? undefined,
              signedUrl: prev?.signedUrl ?? undefined,
              selectedSheet: prev?.selectedSheet ?? undefined,
              activeStepKey: (prev?.activeStepKey ??
                'upload') as IngestionFlowActiveStepKey,
              selectedHeaderRows: prev?.selectedHeaderRows ?? undefined,
              validationResults: prev?.validationResults ?? undefined,
            })
          : newState),
      }));
    },
    []
  );

  return (
    <IngestionFlow
      config={{
        templateId: 'None',
        ownerName,
        datasetName: 'Properties',
        datasetId: '',
        performValidation: true,
        targetSchema:
          (target_schema.data as unknown as {
            label: string;
            name: string;
            mandatory: boolean;
            dataType: string;
          }[]) ?? [],
      }}
      ingestionConfig={undefined}
      onComplete={onComplete}
      onCancel={pushOrGoBack}
      state={state}
      setState={setState}
    />
  );
};

export default UploadPropertyPage;
