import {
  refine,
  atom,
  CheckerReturnType,
} from '@cherre-frontend/data-fetching';

const emailChecker = refine.custom((value) => {
  if (typeof value === 'string' && value !== '') {
    return value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? value
      : null;
  } else {
    return null;
  }
});

const stringChecker = refine.custom((value) => {
  if (typeof value === 'string' && value !== '') {
    return value;
  } else {
    return null;
  }
});

export const newProviderUserNameFormChecker = refine.object({
  first_name: stringChecker,
  last_name: stringChecker,
});

export const newProviderUserEmailFormChecker = refine.object({
  email: emailChecker,
});

export type AddNewProviderUserNameFormState = CheckerReturnType<
  typeof newProviderUserNameFormChecker
>;

export type AddNewProviderUserEmailFormState = CheckerReturnType<
  typeof newProviderUserEmailFormChecker
>;

export const addNewProviderUserNameFormState =
  atom<AddNewProviderUserNameFormState>({
    key: 'add-new-provider-user-name-form-state',
    default: {
      first_name: '',
      last_name: '',
    },
  });

export const addNewProviderUserEmailFormState =
  atom<AddNewProviderUserEmailFormState>({
    key: 'add-new-provider-user-email-form-state',
    default: {
      email: '',
    },
  });
