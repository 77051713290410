import React, { useMemo, useState } from 'react';
import {
  useCherreSetState,
  useCherreStateDebounced,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  DatasetsIcon,
  Fade,
  Grid,
  MRT_RowSelectionState,
  PageContent,
  Panel,
  SearchInput,
  TogglesIcon,
  styled,
} from '@cherre-frontend/ui';
// import AssignPropertiesDialogModal from './components/AssignPropertiesDialog';
import PropertiesDialogWrapper from './components/AssignPropertiesDialog';
import ManageUsersDialog from '../../../../components/dialogs/ManageUsersDialog/ManageUsersDialog';
import PropertiesTable from './components/PropertiesTable';
import {
  propertiesTableSearch,
  assignPropertiesDialogOpenState,
} from './recoil';
import AssignDatasetsDialog from '../../../../components/AssignDatasetsDialog';
import AssignMappingDialog from '../../../../components/AssignMappingDialog';
import {
  assignMappingDialogSubtitle,
  assignMappingDialogTitle,
} from '../../../../components/AssignMappingDialog/localState';
import AssignReviewersDialog from '../../../../components/dialogs/AssignReviewersDialog';
import AssignPreparersDialog from '../../../../components/dialogs/AssignPreparersDialog';
import AssignUsersButton from './components/AssignUsersButton';
import {
  assignDatasetsDialogOpenState,
  assignMappingsDialogOpenState,
} from 'src/products/data-submission-portal/recoil/dialog';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SelectionButton = styled(Button)`
  > span {
    display: flex;
  }
`;

const PropertiesTabPanel: React.FC = () => {
  const [search, , setSearch] = useCherreStateDebounced(propertiesTableSearch);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const hasSelectedRows = useMemo(
    () => Object.keys(rowSelection).length > 0,
    [rowSelection]
  );

  const totalPropertiesSelected = useMemo(() => {
    return Object.keys(rowSelection).length;
  }, [rowSelection]);

  const setAssignPropertiesDialogOpenState = useCherreSetState(
    assignPropertiesDialogOpenState
  );

  const setAssignMappingsDialogOpenState = useCherreSetState(
    assignMappingsDialogOpenState
  );

  const setAssignMappingDialogTitle = useCherreSetState(
    assignMappingDialogTitle
  );

  const setAssignMappingDialogSubtitle = useCherreSetState(
    assignMappingDialogSubtitle
  );

  const setAssignDatasetsDialogOpenState = useCherreSetState(
    assignDatasetsDialogOpenState
  );

  const openAssignMappingDialogModalWithTitles = (
    title: string,
    subtitle: string
  ) => {
    setAssignMappingsDialogOpenState(true);
    setAssignMappingDialogTitle(title);
    setAssignMappingDialogSubtitle(subtitle);
  };

  return (
    <PageContent sx={{ padding: 0, height: '100%' }}>
      <Panel id='assign-preparers-dialog-panel'>
        <AssignPreparersDialog setRowSelection={setRowSelection} />
        <AssignReviewersDialog setRowSelection={setRowSelection} />
      </Panel>
      <Panel id='assign-properties-modal-panel'>
        <PropertiesDialogWrapper />
      </Panel>
      <Panel id='manage-users-dialog-panel'>
        <ManageUsersDialog setRowSelection={setRowSelection} />
      </Panel>
      <AssignMappingDialog
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      <AssignDatasetsDialog
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        gap={1}
      >
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          gap={2}
        >
          <SearchInput
            value={search ?? ''}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Grid item>
            <Fade in={hasSelectedRows}>
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
                justifyItems={'center'}
                gap={1}
              >
                <AssignUsersButton />
                <SelectionButton
                  variant='outlined'
                  color='primary'
                  size='medium'
                  onClick={() =>
                    openAssignMappingDialogModalWithTitles(
                      'Assign COA Mappings',
                      `${totalPropertiesSelected} Properties Selected`
                    )
                  }
                >
                  <TogglesIcon sx={{ height: 16, alignSelf: 'center' }} />
                  Assign Mappings
                </SelectionButton>
                <SelectionButton
                  variant='outlined'
                  color='primary'
                  size='medium'
                  onClick={() => setAssignDatasetsDialogOpenState(true)}
                >
                  <DatasetsIcon sx={{ height: 16, alignSelf: 'center' }} />
                  Assign Datasets
                </SelectionButton>
                {/* <SelectionButton
                  variant='outlined'
                  color='primary'
                  size='medium'
                >
                  Deactivate
                </SelectionButton>
                <SelectionButton
                  variant='outlined'
                  color='primary'
                  size='medium'
                >
                  Remove
                </SelectionButton> */}
              </Grid>
            </Fade>
          </Grid>

          <Grid item ml='auto'>
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={() => setAssignPropertiesDialogOpenState(true)}
            >
              Assign Properties
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PanelStyled id='properties'>
        <PropertiesTable
          search={search}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          openAssignMappingDialogModalWithTitles={
            openAssignMappingDialogModalWithTitles
          }
        />
      </PanelStyled>
    </PageContent>
  );
};

export default PropertiesTabPanel;
