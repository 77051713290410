/**
 * @generated SignedSource<<08644a63059e3ed7a520e3d2406d8379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSubmissionQuery$variables = {
  dataset_id: number;
  submission_id: number;
};
export type getSubmissionQuery$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly property_batches: ReadonlyArray<{
      readonly property_id: number;
    }>;
  }>;
};
export type getSubmissionQuery = {
  response: getSubmissionQuery$data;
  variables: getSubmissionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "dataset_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "dataset_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property_batch_datasets"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSubmissionQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSubmissionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cbebd272229269edda1a17b35468efa",
    "id": null,
    "metadata": {},
    "name": "getSubmissionQuery",
    "operationKind": "query",
    "text": "query getSubmissionQuery(\n  $dataset_id: Int!\n  $submission_id: Int!\n) {\n  sys_submissions(where: {submission_id: {_eq: $submission_id}}, limit: 1) {\n    property_batches(where: {property_batch_datasets: {dataset_id: {_eq: $dataset_id}}}) {\n      property_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7643d13329b8b82cfa2240033d0c3660";

export default node;
