import { useCherreValue } from '@cherre-frontend/data-fetching';
import { DotSeparator, PageHeader } from '@cherre-frontend/ui';
import React from 'react';
import { summaryInfo } from './query';

const Header: React.FC = () => {
  const info = useCherreValue(summaryInfo());

  const propertyName = info?.property_name ? (
    <>{` - ${info.property_name}`}</>
  ) : (
    <>{''}</>
  );
  return (
    <PageHeader
      title='File Validation Summary Report'
      subtitle={
        <>
          <DotSeparator>
            <span className='suspend'>
              {info?.dataset_label || 'Unknown Dataset'}
            </span>
            <>
              <span className='suspend'>
                ID {info?.property_id || 'Unknown'}
              </span>
              {propertyName}
            </>
            <span className='suspend'>
              {info?.submission_name || 'Unknown Submissions'}
            </span>
          </DotSeparator>
        </>
      }
      backLink
    />
  );
};

Header.displayName = 'SingleFileValidationPage/Header';

export default Header;
