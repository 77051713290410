import React from 'react';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { DatasetsIcon, MenuItem } from '@cherre-frontend/ui';
import { Property } from '../queries/getProperties';
import { assignDatasetsToPropertyState } from 'src/products/data-submission-portal/components/AssignDatasetsDialog/localState';
import {
  assignDatasetsDialogOpenState,
  propertiesTableSelectedProvider,
} from 'src/products/data-submission-portal/recoil/dialog';

type ManageDatasetsActionMenuItemProps = {
  property: Property;
};
export const ManageDatasetsActionMenuItem: React.FC<
  ManageDatasetsActionMenuItemProps
> = ({ property }) => {
  const setSelectedProvider = useCherreSetState(
    propertiesTableSelectedProvider
  );

  const setAssignDatasetsToProperty = useCherreSetState(
    assignDatasetsToPropertyState
  );

  const setAssignDatasetsDialogOpenState = useCherreSetState(
    assignDatasetsDialogOpenState
  );

  const onClick = () => {
    setSelectedProvider(property.provider?.provider_id ?? null);
    setAssignDatasetsToProperty(property);
    setAssignDatasetsDialogOpenState(true);
  };

  return (
    <MenuItem onClick={onClick}>
      <DatasetsIcon sx={{ height: 16 }} />
      Manage Datasets
    </MenuItem>
  );
};
