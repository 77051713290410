import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';

const IN_PREPARATION_STAGES = [
  PropertyBatchStage.PENDING.id as number,
  PropertyBatchStage.IN_PREPARATION.id as number,
  PropertyBatchStage.REJECTED.id as number,
];

export const isInPreparation = (stage_id: number) =>
  IN_PREPARATION_STAGES.includes(stage_id);
