import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadMappingNewMutation } from './__generated__/useUploadMappingNewMutation.graphql';

export const useUploadMappingNew = () => {
  return useMutationAsync(
    graphql`
      mutation useUploadMappingNewMutation($input: SysUploadMappingInput!) {
        _sys_upload_mapping(input: $input) {
          mapping_set_id
        }
      }
    ` as GraphQLReturn<useUploadMappingNewMutation>,
    {
      trackEvent: false,
    }
  );
};
