import {
  CachedIcon,
  CheckIcon,
  Chip,
  ChipProps,
  InfoIcon,
  SvgIconComponent,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import { themeV5 } from 'src/const/themev5';

const statusMap = {
  'Not Started': {
    label: 'Not Started',
    bgColor: themeV5.palette.grey[100],
    textColor: themeV5.palette.grey[700],
  },
  Open: {
    label: 'In Progress',
    bgColor: themeV5.accents.info.light,
    textColor: themeV5.palette.info.dark,
    icon: CachedIcon,
  },
  Stuck: {
    label: 'Stuck',
    bgColor: themeV5.accents.warning.main,
    textColor: themeV5.palette.common.white,
    icon: InfoIcon,
  },
  Approved: {
    label: 'Approved',
    bgColor: themeV5.accents.positive.main,
    textColor: themeV5.palette.common.white,
    icon: CheckIcon,
  },
} as const;

export type StatusCode = keyof typeof statusMap;

type SubmissionBatchStatusChipStyledProps = {
  bgColor: string;
  textColor: string;
  icon?: React.ReactElement;
} & ChipProps;

const SubmissionBatchStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'textColor' !== prop && 'bgColor' !== prop,
})<SubmissionBatchStatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type SubmissionBatchStatusChipProps = {
  statusCode: StatusCode;
};

export const SubmissionBatchStatusChip: React.FC<
  SubmissionBatchStatusChipProps
> = ({ statusCode }) => {
  const status = statusMap[statusCode];

  if (!status) {
    throw new Error(`Invalid status code ${statusCode}`);
  }

  const Icon: SvgIconComponent | undefined =
    'icon' in status ? status.icon : undefined;

  return (
    <SubmissionBatchStatusChipStyled
      label={status.label}
      icon={
        Icon ? (
          <Icon style={{ color: status.textColor, width: 12, height: 12 }} />
        ) : undefined
      }
      bgColor={status.bgColor}
      textColor={status.textColor}
    />
  );
};

SubmissionBatchStatusChip.displayName = 'SubmissionBatchStatusChip';
