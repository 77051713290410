/**
 * @generated SignedSource<<1e251cc05e51cf95e284f40c9489cd5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type order_by = "asc" | "asc_nulls_first" | "asc_nulls_last" | "desc" | "desc_nulls_first" | "desc_nulls_last" | "%future added value";
export type sys_mapping_sets_order_by = {
  created_at_datetime?: order_by | null;
  is_active?: order_by | null;
  mapping_field?: sys_mapping_fields_order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  mapping_set_values_aggregate?: sys_mapping_set_values_aggregate_order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_order_by | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_order_by | null;
  provider?: sys_providers_order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_order_by = {
  created_at_datetime?: order_by | null;
  custom_attributes?: order_by | null;
  is_active?: order_by | null;
  is_single_mapping_set?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_aggregate_order_by = {
  avg?: sys_mapping_sets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_sets_max_order_by | null;
  min?: sys_mapping_sets_min_order_by | null;
  stddev?: sys_mapping_sets_stddev_order_by | null;
  stddev_pop?: sys_mapping_sets_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_sets_stddev_samp_order_by | null;
  sum?: sys_mapping_sets_sum_order_by | null;
  var_pop?: sys_mapping_sets_var_pop_order_by | null;
  var_samp?: sys_mapping_sets_var_samp_order_by | null;
  variance?: sys_mapping_sets_variance_order_by | null;
};
export type sys_mapping_sets_avg_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_stddev_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_sum_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_variance_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_organizations_order_by = {
  batch_files_aggregate?: sys_batch_files_aggregate_order_by | null;
  created_at_datetime?: order_by | null;
  datasets_aggregate?: sys_datasets_aggregate_order_by | null;
  flatfile_team_name?: order_by | null;
  mapping_fields_aggregate?: sys_mapping_fields_aggregate_order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_model?: sys_property_models_order_by | null;
  property_model_id?: order_by | null;
  providers_aggregate?: sys_providers_aggregate_order_by | null;
  sakura_organization?: sakura_organization_order_by | null;
  sakura_users_aggregate?: sakura_user_aggregate_order_by | null;
  settings?: order_by | null;
  submission_types_aggregate?: sys_submission_types_aggregate_order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_aggregate_order_by = {
  avg?: sys_batch_files_avg_order_by | null;
  count?: order_by | null;
  max?: sys_batch_files_max_order_by | null;
  min?: sys_batch_files_min_order_by | null;
  stddev?: sys_batch_files_stddev_order_by | null;
  stddev_pop?: sys_batch_files_stddev_pop_order_by | null;
  stddev_samp?: sys_batch_files_stddev_samp_order_by | null;
  sum?: sys_batch_files_sum_order_by | null;
  var_pop?: sys_batch_files_var_pop_order_by | null;
  var_samp?: sys_batch_files_var_samp_order_by | null;
  variance?: sys_batch_files_variance_order_by | null;
};
export type sys_batch_files_avg_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_stddev_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_sum_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_variance_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_datasets_aggregate_order_by = {
  avg?: sys_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_datasets_max_order_by | null;
  min?: sys_datasets_min_order_by | null;
  stddev?: sys_datasets_stddev_order_by | null;
  stddev_pop?: sys_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_datasets_stddev_samp_order_by | null;
  sum?: sys_datasets_sum_order_by | null;
  var_pop?: sys_datasets_var_pop_order_by | null;
  var_samp?: sys_datasets_var_samp_order_by | null;
  variance?: sys_datasets_variance_order_by | null;
};
export type sys_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_mapping_fields_aggregate_order_by = {
  avg?: sys_mapping_fields_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_fields_max_order_by | null;
  min?: sys_mapping_fields_min_order_by | null;
  stddev?: sys_mapping_fields_stddev_order_by | null;
  stddev_pop?: sys_mapping_fields_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_fields_stddev_samp_order_by | null;
  sum?: sys_mapping_fields_sum_order_by | null;
  var_pop?: sys_mapping_fields_var_pop_order_by | null;
  var_samp?: sys_mapping_fields_var_samp_order_by | null;
  variance?: sys_mapping_fields_variance_order_by | null;
};
export type sys_mapping_fields_avg_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_stddev_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_sum_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_variance_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_account_tree_details_aggregate_order_by = {
  avg?: sys_organization_account_tree_details_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organization_account_tree_details_max_order_by | null;
  min?: sys_organization_account_tree_details_min_order_by | null;
  stddev?: sys_organization_account_tree_details_stddev_order_by | null;
  stddev_pop?: sys_organization_account_tree_details_stddev_pop_order_by | null;
  stddev_samp?: sys_organization_account_tree_details_stddev_samp_order_by | null;
  sum?: sys_organization_account_tree_details_sum_order_by | null;
  var_pop?: sys_organization_account_tree_details_var_pop_order_by | null;
  var_samp?: sys_organization_account_tree_details_var_samp_order_by | null;
  variance?: sys_organization_account_tree_details_variance_order_by | null;
};
export type sys_organization_account_tree_details_avg_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_max_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_min_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_sum_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_variance_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_properties_datasets_aggregate_order_by = {
  avg?: sys_properties_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_datasets_max_order_by | null;
  min?: sys_properties_datasets_min_order_by | null;
  stddev?: sys_properties_datasets_stddev_order_by | null;
  stddev_pop?: sys_properties_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_datasets_stddev_samp_order_by | null;
  sum?: sys_properties_datasets_sum_order_by | null;
  var_pop?: sys_properties_datasets_var_pop_order_by | null;
  var_samp?: sys_properties_datasets_var_samp_order_by | null;
  variance?: sys_properties_datasets_variance_order_by | null;
};
export type sys_properties_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_max_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_min_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_roles_users_aggregate_order_by = {
  avg?: sys_properties_roles_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_roles_users_max_order_by | null;
  min?: sys_properties_roles_users_min_order_by | null;
  stddev?: sys_properties_roles_users_stddev_order_by | null;
  stddev_pop?: sys_properties_roles_users_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_roles_users_stddev_samp_order_by | null;
  sum?: sys_properties_roles_users_sum_order_by | null;
  var_pop?: sys_properties_roles_users_var_pop_order_by | null;
  var_samp?: sys_properties_roles_users_var_samp_order_by | null;
  variance?: sys_properties_roles_users_variance_order_by | null;
};
export type sys_properties_roles_users_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_datasets_aggregate_order_by = {
  avg?: sys_property_batch_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_datasets_max_order_by | null;
  min?: sys_property_batch_datasets_min_order_by | null;
  stddev?: sys_property_batch_datasets_stddev_order_by | null;
  stddev_pop?: sys_property_batch_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_datasets_stddev_samp_order_by | null;
  sum?: sys_property_batch_datasets_sum_order_by | null;
  var_pop?: sys_property_batch_datasets_var_pop_order_by | null;
  var_samp?: sys_property_batch_datasets_var_samp_order_by | null;
  variance?: sys_property_batch_datasets_variance_order_by | null;
};
export type sys_property_batch_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_max_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_min_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_aggregate_order_by = {
  avg?: sys_property_batch_stage_transitions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_stage_transitions_max_order_by | null;
  min?: sys_property_batch_stage_transitions_min_order_by | null;
  stddev?: sys_property_batch_stage_transitions_stddev_order_by | null;
  stddev_pop?: sys_property_batch_stage_transitions_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_stage_transitions_stddev_samp_order_by | null;
  sum?: sys_property_batch_stage_transitions_sum_order_by | null;
  var_pop?: sys_property_batch_stage_transitions_var_pop_order_by | null;
  var_samp?: sys_property_batch_stage_transitions_var_samp_order_by | null;
  variance?: sys_property_batch_stage_transitions_variance_order_by | null;
};
export type sys_property_batch_stage_transitions_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_max_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_min_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batches_aggregate_order_by = {
  avg?: sys_property_batches_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batches_max_order_by | null;
  min?: sys_property_batches_min_order_by | null;
  stddev?: sys_property_batches_stddev_order_by | null;
  stddev_pop?: sys_property_batches_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batches_stddev_samp_order_by | null;
  sum?: sys_property_batches_sum_order_by | null;
  var_pop?: sys_property_batches_var_pop_order_by | null;
  var_samp?: sys_property_batches_var_samp_order_by | null;
  variance?: sys_property_batches_variance_order_by | null;
};
export type sys_property_batches_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_max_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_min_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_models_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  is_active?: order_by | null;
  organizations_aggregate?: sys_organizations_aggregate_order_by | null;
  property_model_id?: order_by | null;
  property_model_slug?: order_by | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_aggregate_order_by = {
  avg?: sys_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organizations_max_order_by | null;
  min?: sys_organizations_min_order_by | null;
  stddev?: sys_organizations_stddev_order_by | null;
  stddev_pop?: sys_organizations_stddev_pop_order_by | null;
  stddev_samp?: sys_organizations_stddev_samp_order_by | null;
  sum?: sys_organizations_sum_order_by | null;
  var_pop?: sys_organizations_var_pop_order_by | null;
  var_samp?: sys_organizations_var_samp_order_by | null;
  variance?: sys_organizations_variance_order_by | null;
};
export type sys_organizations_avg_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_max_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_min_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_stddev_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_sum_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_variance_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_property_models_types_aggregate_order_by = {
  avg?: sys_property_models_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_models_types_max_order_by | null;
  min?: sys_property_models_types_min_order_by | null;
  stddev?: sys_property_models_types_stddev_order_by | null;
  stddev_pop?: sys_property_models_types_stddev_pop_order_by | null;
  stddev_samp?: sys_property_models_types_stddev_samp_order_by | null;
  sum?: sys_property_models_types_sum_order_by | null;
  var_pop?: sys_property_models_types_var_pop_order_by | null;
  var_samp?: sys_property_models_types_var_samp_order_by | null;
  variance?: sys_property_models_types_variance_order_by | null;
};
export type sys_property_models_types_avg_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_max_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_min_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_stddev_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_sum_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_variance_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_providers_aggregate_order_by = {
  avg?: sys_providers_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_max_order_by | null;
  min?: sys_providers_min_order_by | null;
  stddev?: sys_providers_stddev_order_by | null;
  stddev_pop?: sys_providers_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_stddev_samp_order_by | null;
  sum?: sys_providers_sum_order_by | null;
  var_pop?: sys_providers_var_pop_order_by | null;
  var_samp?: sys_providers_var_samp_order_by | null;
  variance?: sys_providers_variance_order_by | null;
};
export type sys_providers_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sakura_organization_order_by = {
  additional_props?: order_by | null;
  api_hasura_role?: order_by | null;
  authz_version_id?: order_by | null;
  create_time?: order_by | null;
  dashboards?: order_by | null;
  description?: order_by | null;
  disabled?: order_by | null;
  domOwner?: order_by | null;
  hasura_access_token?: order_by | null;
  hasura_org_name?: order_by | null;
  hasura_role?: order_by | null;
  id?: order_by | null;
  image_url?: order_by | null;
  last_modified?: order_by | null;
  name?: order_by | null;
  organization_permissions_aggregate?: sakura_organization_permission_aggregate_order_by | null;
  plan_expires_at?: order_by | null;
  plan_type?: order_by | null;
  powerbi_api_url?: order_by | null;
  powerbi_client_id?: order_by | null;
  powerbi_client_secret?: order_by | null;
  powerbi_tenant_id?: order_by | null;
  publish_on_website?: order_by | null;
  salesforce_id?: order_by | null;
  slug?: order_by | null;
  sys_organization?: sys_organizations_order_by | null;
  theme_name?: order_by | null;
  website?: order_by | null;
  website_image_url?: order_by | null;
};
export type sakura_organization_permission_aggregate_order_by = {
  avg?: sakura_organization_permission_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_organization_permission_max_order_by | null;
  min?: sakura_organization_permission_min_order_by | null;
  stddev?: sakura_organization_permission_stddev_order_by | null;
  stddev_pop?: sakura_organization_permission_stddev_pop_order_by | null;
  stddev_samp?: sakura_organization_permission_stddev_samp_order_by | null;
  sum?: sakura_organization_permission_sum_order_by | null;
  var_pop?: sakura_organization_permission_var_pop_order_by | null;
  var_samp?: sakura_organization_permission_var_samp_order_by | null;
  variance?: sakura_organization_permission_variance_order_by | null;
};
export type sakura_organization_permission_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_max_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_min_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_aggregate_order_by = {
  avg?: sakura_user_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_user_max_order_by | null;
  min?: sakura_user_min_order_by | null;
  stddev?: sakura_user_stddev_order_by | null;
  stddev_pop?: sakura_user_stddev_pop_order_by | null;
  stddev_samp?: sakura_user_stddev_samp_order_by | null;
  sum?: sakura_user_sum_order_by | null;
  var_pop?: sakura_user_var_pop_order_by | null;
  var_samp?: sakura_user_var_samp_order_by | null;
  variance?: sakura_user_variance_order_by | null;
};
export type sakura_user_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_max_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_min_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_submission_types_aggregate_order_by = {
  avg?: sys_submission_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submission_types_max_order_by | null;
  min?: sys_submission_types_min_order_by | null;
  stddev?: sys_submission_types_stddev_order_by | null;
  stddev_pop?: sys_submission_types_stddev_pop_order_by | null;
  stddev_samp?: sys_submission_types_stddev_samp_order_by | null;
  sum?: sys_submission_types_sum_order_by | null;
  var_pop?: sys_submission_types_var_pop_order_by | null;
  var_samp?: sys_submission_types_var_samp_order_by | null;
  variance?: sys_submission_types_variance_order_by | null;
};
export type sys_submission_types_avg_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_max_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_min_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_stddev_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_sum_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_variance_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submissions_aggregate_order_by = {
  avg?: sys_submissions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submissions_max_order_by | null;
  min?: sys_submissions_min_order_by | null;
  stddev?: sys_submissions_stddev_order_by | null;
  stddev_pop?: sys_submissions_stddev_pop_order_by | null;
  stddev_samp?: sys_submissions_stddev_samp_order_by | null;
  sum?: sys_submissions_sum_order_by | null;
  var_pop?: sys_submissions_var_pop_order_by | null;
  var_samp?: sys_submissions_var_samp_order_by | null;
  variance?: sys_submissions_variance_order_by | null;
};
export type sys_submissions_avg_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_max_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_min_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_sum_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_variance_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_mapping_set_values_aggregate_order_by = {
  avg?: sys_mapping_set_values_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_set_values_max_order_by | null;
  min?: sys_mapping_set_values_min_order_by | null;
  stddev?: sys_mapping_set_values_stddev_order_by | null;
  stddev_pop?: sys_mapping_set_values_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_set_values_stddev_samp_order_by | null;
  sum?: sys_mapping_set_values_sum_order_by | null;
  var_pop?: sys_mapping_set_values_var_pop_order_by | null;
  var_samp?: sys_mapping_set_values_var_samp_order_by | null;
  variance?: sys_mapping_set_values_variance_order_by | null;
};
export type sys_mapping_set_values_avg_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
  source_mapping_code?: order_by | null;
  source_mapping_label?: order_by | null;
  target_mapping_code?: order_by | null;
  target_mapping_label?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_set_values_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
  source_mapping_code?: order_by | null;
  source_mapping_label?: order_by | null;
  target_mapping_code?: order_by | null;
  target_mapping_label?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_set_values_stddev_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_stddev_pop_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_stddev_samp_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_sum_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_var_pop_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_var_samp_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_set_values_variance_order_by = {
  mapping_set_id?: order_by | null;
  mapping_set_value_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_accounts_order_by = {
  chart_of_accounts_description?: order_by | null;
  chart_of_accounts_name?: order_by | null;
  created_at_datetime?: order_by | null;
  is_active?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  organization_master_chart_of_account_values_aggregate?: sys_organization_master_chart_of_account_values_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_aggregate_order_by = {
  avg?: sys_organization_master_chart_of_account_values_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organization_master_chart_of_account_values_max_order_by | null;
  min?: sys_organization_master_chart_of_account_values_min_order_by | null;
  stddev?: sys_organization_master_chart_of_account_values_stddev_order_by | null;
  stddev_pop?: sys_organization_master_chart_of_account_values_stddev_pop_order_by | null;
  stddev_samp?: sys_organization_master_chart_of_account_values_stddev_samp_order_by | null;
  sum?: sys_organization_master_chart_of_account_values_sum_order_by | null;
  var_pop?: sys_organization_master_chart_of_account_values_var_pop_order_by | null;
  var_samp?: sys_organization_master_chart_of_account_values_var_samp_order_by | null;
  variance?: sys_organization_master_chart_of_account_values_variance_order_by | null;
};
export type sys_organization_master_chart_of_account_values_avg_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_max_order_by = {
  account_code?: order_by | null;
  account_name?: order_by | null;
  created_at_datetime?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_min_order_by = {
  account_code?: order_by | null;
  account_name?: order_by | null;
  created_at_datetime?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_stddev_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_stddev_pop_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_stddev_samp_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_sum_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_var_pop_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_var_samp_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_organization_master_chart_of_account_values_variance_order_by = {
  master_chart_of_account_id?: order_by | null;
  master_chart_of_account_values_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_properties_mapping_sets_aggregate_order_by = {
  avg?: sys_properties_mapping_sets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_mapping_sets_max_order_by | null;
  min?: sys_properties_mapping_sets_min_order_by | null;
  stddev?: sys_properties_mapping_sets_stddev_order_by | null;
  stddev_pop?: sys_properties_mapping_sets_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_mapping_sets_stddev_samp_order_by | null;
  sum?: sys_properties_mapping_sets_sum_order_by | null;
  var_pop?: sys_properties_mapping_sets_var_pop_order_by | null;
  var_samp?: sys_properties_mapping_sets_var_samp_order_by | null;
  variance?: sys_properties_mapping_sets_variance_order_by | null;
};
export type sys_properties_mapping_sets_avg_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_mapping_sets_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_sum_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_variance_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_providers_order_by = {
  created_at_datetime?: order_by | null;
  ingestion_config?: order_by | null;
  is_active?: order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_aggregate?: sys_properties_aggregate_order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type?: sys_provider_types_order_by | null;
  provider_type_id?: order_by | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_order_by | null;
  related_data_updated_at?: order_by | null;
  report_config?: order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
  users_aggregate?: sys_users_aggregate_order_by | null;
};
export type sys_properties_aggregate_order_by = {
  avg?: sys_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_max_order_by | null;
  min?: sys_properties_min_order_by | null;
  stddev?: sys_properties_stddev_order_by | null;
  stddev_pop?: sys_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_stddev_samp_order_by | null;
  sum?: sys_properties_sum_order_by | null;
  var_pop?: sys_properties_var_pop_order_by | null;
  var_samp?: sys_properties_var_samp_order_by | null;
  variance?: sys_properties_variance_order_by | null;
};
export type sys_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_max_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_min_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_provider_types_order_by = {
  id?: order_by | null;
  name?: order_by | null;
};
export type sys_providers_properties_aggregate_order_by = {
  avg?: sys_providers_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_properties_max_order_by | null;
  min?: sys_providers_properties_min_order_by | null;
  stddev?: sys_providers_properties_stddev_order_by | null;
  stddev_pop?: sys_providers_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_properties_stddev_samp_order_by | null;
  sum?: sys_providers_properties_sum_order_by | null;
  var_pop?: sys_providers_properties_var_pop_order_by | null;
  var_samp?: sys_providers_properties_var_samp_order_by | null;
  variance?: sys_providers_properties_variance_order_by | null;
};
export type sys_providers_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_max_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_min_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_users_aggregate_order_by = {
  avg?: sys_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_users_max_order_by | null;
  min?: sys_users_min_order_by | null;
  stddev?: sys_users_stddev_order_by | null;
  stddev_pop?: sys_users_stddev_pop_order_by | null;
  stddev_samp?: sys_users_stddev_samp_order_by | null;
  sum?: sys_users_sum_order_by | null;
  var_pop?: sys_users_var_pop_order_by | null;
  var_samp?: sys_users_var_samp_order_by | null;
  variance?: sys_users_variance_order_by | null;
};
export type sys_users_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type getMappingSetsSubscription$variables = {
  id?: number | null;
  limit?: number | null;
  offset?: number | null;
  order_by?: ReadonlyArray<sys_mapping_sets_order_by> | null;
  search?: string | null;
};
export type getMappingSetsSubscription$data = {
  readonly sys_mapping_sets: ReadonlyArray<{
    readonly mapping_field: {
      readonly is_single_mapping_set: boolean;
      readonly mapping_field_description: string;
      readonly mapping_field_name: string;
    };
    readonly mapping_set_description: string;
    readonly mapping_set_id: number;
    readonly mapping_set_values_aggregate: {
      readonly aggregate: {
        readonly max: {
          readonly updated_at_datetime: string | null;
        } | null;
      } | null;
    };
    readonly organization: {
      readonly organization_label: string;
    };
    readonly provider: {
      readonly provider_name: string;
    };
    readonly provider_id: number;
    readonly updated_at_datetime: string;
  }>;
};
export type getMappingSetsSubscription = {
  response: getMappingSetsSubscription$data;
  variables: getMappingSetsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": 25,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": 0,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = {
  "defaultValue": {},
  "kind": "LocalArgument",
  "name": "order_by"
},
v4 = {
  "defaultValue": "%",
  "kind": "LocalArgument",
  "name": "search"
},
v5 = [
  {
    "kind": "Variable",
    "name": "_ilike",
    "variableName": "search"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated_at_datetime",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset"
      },
      {
        "kind": "Variable",
        "name": "order_by",
        "variableName": "order_by"
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "provider_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.0"
              },
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "is_active": {
                    "_eq": true
                  }
                }
              },
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "fields": (v5/*: any*/),
                            "kind": "ObjectValue",
                            "name": "mapping_set_description"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.0"
                      },
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": (v5/*: any*/),
                                "kind": "ObjectValue",
                                "name": "mapping_field_description"
                              },
                              {
                                "fields": (v5/*: any*/),
                                "kind": "ObjectValue",
                                "name": "mapping_field_name"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "mapping_field"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.1"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "_or"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.2"
              }
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_sets",
    "kind": "LinkedField",
    "name": "sys_mapping_sets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_mapping_fields",
        "kind": "LinkedField",
        "name": "mapping_field",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_field_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_field_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_single_mapping_set",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organizations",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization_label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_mapping_set_values_aggregate",
        "kind": "LinkedField",
        "name": "mapping_set_values_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_mapping_set_values_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_mapping_set_values_max_fields",
                "kind": "LinkedField",
                "name": "max",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getMappingSetsSubscription",
    "selections": (v7/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "getMappingSetsSubscription",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "b296239eede46392f11651c0a245bf55",
    "id": null,
    "metadata": {},
    "name": "getMappingSetsSubscription",
    "operationKind": "subscription",
    "text": "subscription getMappingSetsSubscription(\n  $id: Int\n  $search: String = \"%\"\n  $order_by: [sys_mapping_sets_order_by!] = {}\n  $limit: Int = 25\n  $offset: Int = 0\n) {\n  sys_mapping_sets(where: {_and: [{provider_id: {_eq: $id}}, {is_active: {_eq: true}}, {_or: [{mapping_set_description: {_ilike: $search}}, {mapping_field: {mapping_field_description: {_ilike: $search}, mapping_field_name: {_ilike: $search}}}]}]}, order_by: $order_by, limit: $limit, offset: $offset) {\n    mapping_set_id\n    mapping_set_description\n    mapping_field {\n      mapping_field_name\n      mapping_field_description\n      is_single_mapping_set\n    }\n    organization {\n      organization_label\n    }\n    provider_id\n    provider {\n      provider_name\n    }\n    updated_at_datetime\n    mapping_set_values_aggregate {\n      aggregate {\n        max {\n          updated_at_datetime\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ff89c987dbf01b4290ad068d8a790b3";

export default node;
