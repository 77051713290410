import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, FileGroupIcon, useTheme } from '@cherre-frontend/ui';

const NoMappings: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      style={{ marginTop: '80px' }}
    >
      <FileGroupIcon
        style={{
          width: '30px',
          height: 'auto',
          color: theme.palette.grey[400],
        }}
      />
      <Typography variant='h5' style={{ fontWeight: 600 }}>
        No Mappings
      </Typography>
      <Typography variant='body1'>There are no mappings added yet.</Typography>
    </Grid>
  );
};

export default NoMappings;
