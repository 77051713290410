import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getBulkDatasetQuery } from './__generated__/getBulkDatasetQuery.graphql';

export const $getBulkDataset = graphQLSelector({
  query: graphql`
    query getBulkDatasetQuery($dataset_id: Int!, $property_ids: [Int!]) {
      sys_properties_datasets(
        where: {
          dataset_id: { _eq: $dataset_id }
          property_id: { _in: $property_ids }
          is_active: { _eq: true }
        }
        limit: 1
      ) {
        template_id
        dataset {
          dataset_description
          dataset_label
          unified_ingest_schemas
        }
        property {
          provider {
            ingestion_config
            provider_id
          }
        }
      }
    }
  ` as GraphQLReturn<getBulkDatasetQuery>,
  mapVariables:
    ({
      property_ids,
      dataset_id,
    }: {
      property_ids: number[];
      dataset_id: number;
    }) =>
    () => ({
      dataset_id,
      property_ids,
    }),
  mapResponse: (resp) => resp.sys_properties_datasets[0],
});
