/**
 * @generated SignedSource<<409215f281d125bff9d380f85351dc06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getTargetCodeDropdownSubscription$variables = {
  id: number;
};
export type getTargetCodeDropdownSubscription$data = {
  readonly sys_mapping_set_values: ReadonlyArray<{
    readonly label: string;
    readonly value: string | null;
  }>;
};
export type getTargetCodeDropdownSubscription = {
  response: getTargetCodeDropdownSubscription$data;
  variables: getTargetCodeDropdownSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "distinct_on",
    "value": "target_mapping_code"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "mapping_set_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "target_mapping_code",
  "storageKey": null
},
v3 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "target_mapping_label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getTargetCodeDropdownSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_mapping_set_values",
        "kind": "LinkedField",
        "name": "sys_mapping_set_values",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "THROW",
            "path": "sys_mapping_set_values.label"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getTargetCodeDropdownSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_mapping_set_values",
        "kind": "LinkedField",
        "name": "sys_mapping_set_values",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b340aa482bb20817df99cf59b7c2dd1",
    "id": null,
    "metadata": {},
    "name": "getTargetCodeDropdownSubscription",
    "operationKind": "subscription",
    "text": "subscription getTargetCodeDropdownSubscription(\n  $id: Int!\n) {\n  sys_mapping_set_values(distinct_on: target_mapping_code, where: {mapping_set_id: {_eq: $id}}) {\n    label: target_mapping_code\n    value: target_mapping_label\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4dc316c7137d48f874476b2eb104a24";

export default node;
