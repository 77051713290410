import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getMappingFileQuery } from './__generated__/getMappingFileQuery.graphql';

export const getMappingFile = graphQLSelector({
  query: graphql`
    query getMappingFileQuery($where: sys_mapping_set_values_bool_exp = {}) {
      sys_mapping_set_values(where: $where) {
        source_mapping_code
        source_mapping_label
        target_mapping_code
        target_mapping_label
      }
    }
  ` as GraphQLReturn<getMappingFileQuery>,
  mapVariables: (id?: number) => () => {
    return {
      where: {
        mapping_set_id: {
          _eq: id,
        },
      },
    };
  },
  mapResponse: (resp) => {
    return { data: resp.sys_mapping_set_values };
  },
});
