import React from 'react';
import moment from 'moment';
import { PageHeader, PageContainer, styled, Panel } from '@cherre-frontend/ui';

import { useCherreValue } from '@cherre-frontend/data-fetching';
import { getBatchDetail, batchPeriod } from './recoil';
import { PropertiesTable } from './PropertiesTable';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 170px);
`;

export default function BatchDetailPage() {
  const submissionBatch = useCherreValue(getBatchDetail());
  const period = useCherreValue(batchPeriod);

  const propertyCount = submissionBatch?.properties_count.aggregate?.count;

  const approvedCount =
    submissionBatch?.approved_properties_count.aggregate?.count;

  const dueDate = submissionBatch?.due_date;
  const due = dueDate && moment(dueDate).format('MMM Do');

  return (
    <PageContainer id='BatchDetailPage'>
      <PageHeader
        title={submissionBatch?.submission_name ?? ''}
        subtitle={`${submissionBatch?.submission_type?.submission_type_label} • ${approvedCount}/${propertyCount} Approved Properties • ${period} • Due ${due}`}
        backLink
      />
      <PanelStyled id='properties'>
        <PropertiesTable />
      </PanelStyled>
    </PageContainer>
  );
}
