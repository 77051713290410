import React, { useState } from 'react';
import { Dialog } from '../Dialog';
import { DialogHeader } from '../DialogHeader';
import { DialogContent } from '../DialogContent';
import { RecoilState } from 'recoil';
import {
  RelayEnvironmentScope,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import { Grid, Typography, styled, Panel } from '@cherre-frontend/ui';
import { useHasDspCherreAdminRole } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering/hooks';
import UserCard from './UserCard';
import NewProviderSelection from './NewProviderSelection';
import Actions from './Actions';
import Banner from './Banner';

export type ReassignModalProps = {
  selector: RecoilState<number | null | undefined>;
};

const Section = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ReassignUserModal: React.FC<ReassignModalProps> = ({ selector }) => {
  const hasDspCherreAdminRole = useHasDspCherreAdminRole();
  const [userId, setUserId] = useCherreState(selector);
  const [providerId, setProviderId] = useState<number | null>(-1);
  const onClose = () => {
    setProviderId(-1);
    setUserId(null);
  };
  const open = Boolean(userId);
  const [WrapperEnvironment, wrapperEnvironmentProps] = hasDspCherreAdminRole
    ? [RelayEnvironmentScope, { environment: 'cherre_admin' }]
    : [React.Fragment, { environment: '' }];

  return (
    <Dialog open={open} onClose={onClose} width='480px'>
      {userId && (
        <WrapperEnvironment {...wrapperEnvironmentProps}>
          <DialogHeader
            title='Reassign User'
            titleSize='small'
            subtitle={null}
            onClose={onClose}
          >
            Reassign User
          </DialogHeader>
          <DialogContent rowSpacing='20px'>
            <Grid item xs={12}>
              <Section>User:</Section>
            </Grid>
            <Grid item xs={12}>
              <Panel id='reassign-user-modal::user-card'>
                <UserCard userId={userId} />
              </Panel>
            </Grid>
            <Grid item xs={12}>
              <Section>Reassign user to:</Section>
            </Grid>
            <Grid item xs={12}>
              <Panel id='reassign-user-modal::new-provider-selection'>
                <NewProviderSelection
                  userId={userId}
                  selectedProviderId={providerId}
                  onChange={setProviderId}
                />
              </Panel>
            </Grid>
            <Grid item xs={12}>
              <Panel id='reassign-user-modal::banner'>
                <Banner userId={userId} />
              </Panel>
            </Grid>
          </DialogContent>
          <Panel id='reassign-user-modal::actions'>
            <Actions
              userId={userId}
              onClose={onClose}
              selectedProviderId={providerId}
            />
          </Panel>
        </WrapperEnvironment>
      )}
    </Dialog>
  );
};

export default ReassignUserModal;
