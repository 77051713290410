import { graphQLSelector, GraphQLReturn } from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { queriesGetSubmissionsSimilarNamesQuery } from './__generated__/queriesGetSubmissionsSimilarNamesQuery.graphql';
import { queriesGetSubmissionTypesQuery } from './__generated__/queriesGetSubmissionTypesQuery.graphql';

export const getSubmissionWithSimilarNames = graphQLSelector({
  query: graphql`
    query queriesGetSubmissionsSimilarNamesQuery($name: String!) {
      sys_submissions(where: { submission_name: { _ilike: $name } }) {
        submission_name
      }
    }
  ` as GraphQLReturn<queriesGetSubmissionsSimilarNamesQuery>,
  mapVariables: (name: string) => () => ({ name: `%${name}%` }),
  mapResponse: (response) =>
    response.sys_submissions.map((s) => s.submission_name),
});

export const getSubmissionTypes = graphQLSelector({
  query: graphql`
    query queriesGetSubmissionTypesQuery(
      $limit: Int
      $offset: Int
      $order_by: [sys_submission_types_order_by!] = {}
      $where: sys_submission_types_bool_exp = {}
    ) {
      sys_submission_types(
        limit: $limit
        offset: $offset
        order_by: $order_by
        where: $where
      ) {
        submission_type_id
        submission_type_label
        submission_type_slug
      }
    }
  ` as GraphQLReturn<queriesGetSubmissionTypesQuery>,
  mapVariables:
    (input: queriesGetSubmissionTypesQuery['variables'] = {}) =>
    () =>
      input,
  mapResponse: (response) => response.sys_submission_types,
});
