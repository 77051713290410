import React from 'react';
import {
  Typography,
  styled,
  Grid,
  IconButton,
  CloseIcon,
} from '@cherre-frontend/ui';

const HeaderContainer = styled(Grid)``;

export type DialogHeaderProps = {
  title: React.ReactNode;
  titleSize?: 'small' | 'large';
  subtitle: React.ReactNode;
  subtitleSize?: 'small' | 'large';
  onClose: () => void;
};

const Title = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'size',
})<{ size: 'small' | 'large' }>`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  /* Headings/H4 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ size }) => (size === 'small' ? '20px' : '24px')};
  font-style: normal;
  font-weight: ${({ size }) => (size === 'small' ? 600 : 700)};
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Subtitle = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'size',
})<{ size: 'small' | 'large' }>`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  /* Headings/Subtitle 2 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '18px')};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  return (
    <HeaderContainer container>
      <Grid item xs alignContent='center'>
        <Title variant='h4' size={props.titleSize || 'large'}>
          {props.title}
        </Title>
        <Subtitle variant='subtitle2' size={props.subtitleSize || 'large'}>
          {props.subtitle}
        </Subtitle>
      </Grid>
      <Grid item xs='auto'>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </HeaderContainer>
  );
};

DialogHeader.displayName = 'DialogHeader';
