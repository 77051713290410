/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useState } from 'react';
import {
  constSelector,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  CancelIcon,
  IconButton,
  MuiAutocomplete,
  Panel,
  SearchIcon,
  TextField,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { User, getUserList } from '../../queries/getUserList';
import {
  managedPropertyState,
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  selectedPreparerUsers,
  userCheckerType,
} from '../../../../recoil/dialog';
import UserOption from '../../components/UserOption';
import { useWarningBox } from '../../hooks/useWarningBox';
import { useCurrentPreparersAndReviewersArrays } from '../../hooks/useCurrentPreparersAndReviewersArray';
import { useGetProviderId } from '../../hooks/useGetProviderId';

const ManageUsersPreparerTab: React.FC = () => {
  const theme = useTheme();
  const [search, setSearch] = useState('');

  const [selectedUsers, setSelectedUsers] = useCherreState(
    selectedPreparerUsers
  );

  const selectedFinancialUsers = useCherreValue(selectedFinancialReviewerUsers);
  const selectedOperationalUsers = useCherreValue(
    selectedOperationalReviewerUsers
  );
  const managedProperty = useCherreValue(managedPropertyState);

  const selectedUsersIsEmpty = useMemo(() => {
    return selectedUsers!.length === 0;
  }, [selectedUsers]);

  const provider_id = useGetProviderId();

  const userOptions = useCherreValue(
    managedProperty!.property_id ? getUserList(provider_id) : constSelector([])
  );

  const filteredUserOptions = useMemo(() => {
    if (selectedUsers) {
      const selectedUserIds = selectedUsers.map((user) => user.user_id);
      return userOptions?.filter(
        (user) => !selectedUserIds.includes(user.user_id)
      );
    } else {
      return userOptions;
    }
  }, [selectedUsers]);

  const currentPreparersAndReviewersArrays =
    useCurrentPreparersAndReviewersArrays(true);

  const {
    warningBoxState,
    clearWarningBoxState,
    triggerPreparerAlreadyAssigned,
  } = useWarningBox();

  return (
    <Panel id='manage-users-preparers-tab-container'>
      <MuiAutocomplete
        placeholder='Add preparers'
        size='small'
        inputValue={search}
        isOptionEqualToValue={(opt, value) => opt.user_id === value.user_id}
        options={filteredUserOptions as User[]}
        getOptionLabel={(opt) =>
          `${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`
        }
        renderOption={(props, opt) => {
          return (
            <li {...props} key={opt.user_id}>
              <Box>
                <UserOption
                  name={`${opt.sakura_user?.first_name} ${opt.sakura_user?.last_name}`}
                  email={opt.sakura_user?.email as string}
                />
              </Box>
            </li>
          );
        }}
        popupIcon={<SearchIcon style={{ color: theme.palette.grey[500] }} />}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'reset') {
            setSearch('');
          } else {
            setSearch(newInputValue);
          }
        }}
        onChange={(_, value) => {
          clearWarningBoxState();

          if (!value) {
            return;
          }

          const financialReviewers = [
            ...(selectedFinancialUsers?.reviewer1 ?? []),
            ...(selectedFinancialUsers?.reviewer2 ?? []),
            ...(selectedFinancialUsers?.reviewer3 ?? []),
          ];

          const operationalReviewers = [
            ...(selectedOperationalUsers?.reviewer1 ?? []),
            ...(selectedOperationalUsers?.reviewer2 ?? []),
            ...(selectedOperationalUsers?.reviewer3 ?? []),
          ];

          const isAlreadyReviewerLocal = financialReviewers
            .concat(operationalReviewers)
            .some((user) => user.user_id === value.user_id);

          const isAlreadyReviewer =
            currentPreparersAndReviewersArrays.reviewers.some(
              (user) => value.user_id === user.user_id
            );

          if (isAlreadyReviewerLocal || isAlreadyReviewer) {
            triggerPreparerAlreadyAssigned(
              'error',
              `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
              'Reviewer',
              currentPreparersAndReviewersArrays.reviewers.find(
                (user) => user.user_id === value.user_id
              )?.entity_ids ?? []
            );
            return;
          }

          const isAlreadyPreparer =
            currentPreparersAndReviewersArrays.preparers.some(
              (user) => value.user_id === user.user_id
            );

          if (isAlreadyPreparer) {
            triggerPreparerAlreadyAssigned(
              'warning',
              `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
              'Preparer',
              currentPreparersAndReviewersArrays.preparers.find(
                (user) => user.user_id === value.user_id
              )?.entity_ids ?? []
            );
          }

          setSelectedUsers((s) => {
            return s.concat(value as userCheckerType).sort((a, b) => {
              const fullNameA = `${a.sakura_user?.first_name} ${a.sakura_user?.last_name}`;
              const fullNameB = `${b.sakura_user?.first_name} ${b.sakura_user?.last_name}`;
              return fullNameA.localeCompare(fullNameB);
            });
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Search'
            size='small'
          />
        )}
        sx={{
          '> div > div > div > button': {
            transform: 'unset',
          },
          width: '100%',
        }}
      />
      <Box
        style={{
          display: 'flex',
          flexGrow: 1,
          marginTop: '24px',
          justifyContent: selectedUsersIsEmpty ? 'center' : 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '16px',
          // maxHeight:
          //   selectedUsers!.length > 3
          //     ? warningBoxState?.type
          //       ? '166px'
          //       : '163px'
          //     : 'unset',
          height:
            selectedUsers!.length <= 3
              ? warningBoxState?.type
                ? '166px'
                : '163px'
              : 'unset',
          overflow: 'auto',
        }}
      >
        {!selectedUsersIsEmpty ? (
          selectedUsers?.map((user) => (
            <Box
              key={user.user_id}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              <UserOption
                name={`${user.sakura_user?.first_name} ${user.sakura_user?.last_name}`}
                email={user.sakura_user?.email as string}
              />
              <IconButton
                size='small'
                style={{ height: '30px', alignSelf: 'center' }}
                onClick={() => {
                  setSelectedUsers((s) =>
                    s.filter(
                      (selectedUser) => selectedUser.user_id !== user.user_id
                    )
                  );
                }}
              >
                <CancelIcon fontSize='small' />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography variant='body1'>No users assigned</Typography>
        )}
      </Box>
    </Panel>
  );
};

export default ManageUsersPreparerTab;
