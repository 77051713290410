import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import {
  getPropertyBatches,
  submissionOverviewDialogPropertyBatchIdState,
} from '../../../recoil';
import { PreparersTab } from './PreparersTab';
import { ReviewersTab } from './ReviewersTab';

type TabOptions = 'preparers' | 'reviewers';

const Container = styled(Box)`
  padding-top: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

const TabButton = styled(Button)<ButtonProps & { active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey[400]};
  min-width: max-content;
  background: #fff;
  box-shadow: 0 2px 5px 0 #00000033;
  border-radius: 5px;
  height: 100%;
  cursor: pointer;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const TabContainer = styled(Box)`
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

const ContactsTab: React.FC = () => {
  const [tabValue, setTabValue] = React.useState<TabOptions>('preparers');
  const propertyBatches = useCherreValue(getPropertyBatches());

  const submissionId = useCherreValue(
    submissionOverviewDialogPropertyBatchIdState
  );
  const selectedBatch = propertyBatches?.find(
    (batch) => batch.property_batch_id === submissionId
  );

  return (
    <Container>
      <ButtonGroup>
        <TabButton
          active={tabValue === 'preparers'}
          variant={'contained'}
          onClick={() => setTabValue('preparers')}
        >
          Preparers
        </TabButton>

        <TabButton
          active={tabValue === 'reviewers'}
          variant={'contained'}
          onClick={() => setTabValue('reviewers')}
        >
          Reviewers
        </TabButton>
      </ButtonGroup>

      <TabContainer>
        {tabValue === 'preparers' && selectedBatch && (
          <PreparersTab
            batchName={selectedBatch.property_batch.submission.submission_name}
            batchId={selectedBatch.property_batch.property_batch_id}
            entityId={selectedBatch.property_batch.property.entity_id}
          />
        )}
        {tabValue === 'reviewers' && selectedBatch && (
          <ReviewersTab
            batchName={selectedBatch.property_batch.submission.submission_name}
            batchId={selectedBatch.property_batch.property_batch_id}
            entityId={selectedBatch.property_batch.property.entity_id}
          />
        )}
      </TabContainer>
    </Container>
  );
};

export default ContactsTab;
