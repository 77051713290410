/**
 * @generated SignedSource<<da409371ea5d245eafd9b73c6bd70d3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilBatchDetailQuery$variables = {
  submission_id?: number | null;
};
export type recoilBatchDetailQuery$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly approved_properties_count: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
    readonly due_date: string;
    readonly properties_count: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
    readonly reporting_period: string;
    readonly reporting_period_end_date: string;
    readonly reporting_period_start_date: string;
    readonly submission_id: number;
    readonly submission_name: string;
    readonly submission_type: {
      readonly submission_type_label: string;
    };
  }>;
};
export type recoilBatchDetailQuery = {
  response: recoilBatchDetailQuery$data;
  variables: recoilBatchDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_id"
  }
],
v1 = {
  "property": {
    "property_type": {
      "property_type_slug": {
        "_neq": "investment"
      }
    }
  }
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_property_batches_aggregate_fields",
    "kind": "LinkedField",
    "name": "aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reporting_period",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reporting_period_start_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reporting_period_end_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "due_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submission_types",
        "kind": "LinkedField",
        "name": "submission_type",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "approved_properties_count",
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "_and": (v1/*: any*/),
              "is_approved": {
                "_eq": true
              }
            }
          }
        ],
        "concreteType": "sys_property_batches_aggregate",
        "kind": "LinkedField",
        "name": "property_batches_aggregate",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": "property_batches_aggregate(where:{\"_and\":{\"property\":{\"property_type\":{\"property_type_slug\":{\"_neq\":\"investment\"}}}},\"is_approved\":{\"_eq\":true}})"
      },
      {
        "alias": "properties_count",
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": (v1/*: any*/)
          }
        ],
        "concreteType": "sys_property_batches_aggregate",
        "kind": "LinkedField",
        "name": "property_batches_aggregate",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": "property_batches_aggregate(where:{\"property\":{\"property_type\":{\"property_type_slug\":{\"_neq\":\"investment\"}}}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilBatchDetailQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilBatchDetailQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a529b779373d8ae9fe17f1a4f8d07975",
    "id": null,
    "metadata": {},
    "name": "recoilBatchDetailQuery",
    "operationKind": "query",
    "text": "query recoilBatchDetailQuery(\n  $submission_id: Int\n) {\n  sys_submissions(where: {submission_id: {_eq: $submission_id}}) {\n    submission_id\n    submission_name\n    reporting_period\n    reporting_period_start_date\n    reporting_period_end_date\n    due_date\n    submission_type {\n      submission_type_label\n    }\n    approved_properties_count: property_batches_aggregate(where: {is_approved: {_eq: true}, _and: {property: {property_type: {property_type_slug: {_neq: \"investment\"}}}}}) {\n      aggregate {\n        count\n      }\n    }\n    properties_count: property_batches_aggregate(where: {property: {property_type: {property_type_slug: {_neq: \"investment\"}}}}) {\n      aggregate {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "092456e0a73a9f8ef4ba2179788a9bc3";

export default node;
