import { Button, Grid, styled } from '@cherre-frontend/ui';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

export const DisableButton = styled(Button)`
  background-color: white;
  > span {
    display: flex;
  }
`;

export const AddButton = styled(Button)`
  margin-top: 20px;
  font-weight: 600;
  height: 38px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const ProviderDetailTabs = styled(Tabs)`
  align-self: flex-start;
  .MuiTabs-indicator {
    top: 0;
    border-radius: 0;
  }
`;

export const ProviderDetailTab = styled(Tab)`
  min-width: unset;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
