import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDisablePropertyMutation } from './__generated__/useDisablePropertyMutation.graphql';

export const useDisableProperty = (
  property_id: number,
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useDisablePropertyMutation($property_id: Int!) {
        update_sys_properties_roles_users(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }

        update_sys_properties_mapping_sets(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }

        update_sys_properties_datasets(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }

        update_sys_properties_batch_validation_rules(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }

        update_sys_properties_dataset_validation_rules(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }

        update_sys_properties(
          _set: { is_active: false }
          where: { property_id: { _eq: $property_id } }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useDisablePropertyMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        return { property_id };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
