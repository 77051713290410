/**
 * @generated SignedSource<<72b0b08938d5564bd7ff1a35da9969eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getDatasetQuery$variables = {
  dataset_id?: number | null;
};
export type getDatasetQuery$data = {
  readonly sys_datasets: ReadonlyArray<{
    readonly dataset_description: string;
    readonly dataset_label: string;
  }>;
};
export type getDatasetQuery = {
  response: getDatasetQuery$data;
  variables: getDatasetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_datasets",
    "kind": "LinkedField",
    "name": "sys_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_label",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getDatasetQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getDatasetQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3accd0c5be5e08dacd2f676fbf33ca4d",
    "id": null,
    "metadata": {},
    "name": "getDatasetQuery",
    "operationKind": "query",
    "text": "query getDatasetQuery(\n  $dataset_id: Int\n) {\n  sys_datasets(where: {dataset_id: {_eq: $dataset_id}}, limit: 1) {\n    dataset_description\n    dataset_label\n  }\n}\n"
  }
};
})();

(node as any).hash = "c53f19cb3e406e74d92272d19ab2b71d";

export default node;
