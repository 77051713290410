import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';

const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    width: 520px;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    padding: 20px 20px 0px;
  }
  & .MuiDialogContent-root {
    padding: 0 20px 0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & .MuiDialogActions-root {
    padding: 20px;
  }
`;

const Subtitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-size: 12px;
`;

const TextFieldStyled = styled(TextField)`
  width: 100%;
  border-radius: 10px;
`;

export type RejectReportModalProps = {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: (string) => void;
};

const RejectReportModal: React.FC<RejectReportModalProps> = ({
  open,
  handleCancel,
  handleConfirm,
}) => {
  const [comment, setComment] = React.useState<string>('');

  const onConfirm = () => {
    handleConfirm(comment);
    setComment('');
  };

  return (
    <DialogStyled open={open}>
      <DialogTitle fontSize={24}>Reject Report</DialogTitle>
      <DialogContent>
        <Subtitle variant='body2'>
          Please add a comment explaining why you are rejecting the package
          report.
        </Subtitle>

        <TextFieldStyled
          multiline
          minRows={2}
          variant='outlined'
          placeholder='Add comment'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant='contained' disabled={!comment}>
          Reject Report
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

export default RejectReportModal;
