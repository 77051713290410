import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useCreateBatchMutation } from './__generated__/useCreateBatchMutation.graphql';
import { SafeParseReturnType } from 'zod';
import { CreateBatchInfo } from './schema';

type Result = SafeParseReturnType<CreateBatchInfo, CreateBatchInfo>;

export type UseCreateBatchParams = {
  formResult: Result;
  propertyIds?: (type: number) => number[];
  onCompleted?: Parameters<typeof useMutation>[1]['onCompleted'];
};

export const useCreateBatch = ({
  formResult,
  propertyIds,
  onCompleted,
}: UseCreateBatchParams) => {
  return useMutation(
    graphql`
      mutation useCreateBatchMutation($params: create_batch_params!) {
        _sys_create_batch(params: $params) {
          submission_ids
        }
      }
    ` as GraphQLReturn<useCreateBatchMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        if (!formResult.success) {
          throw new Error('Invalid form state');
        }
        const { submission_types, submission_period, ...rest } =
          formResult.data;
        if (!propertyIds) {
          throw new Error('No propertyIds function provided');
        }
        if (submission_types.some((type) => !propertyIds(type).length)) {
          throw new Error('No properties selected');
        }
        return {
          params: {
            ...rest,
            submission_period: submission_period.toISOString(),
            submission_types: submission_types.map((type_id) => ({
              type_id,
              property_ids: propertyIds(type_id),
            })),
            due_date: formResult.data.due_date.valueOf(),
          },
        };
      },
      onCompleted: (value, ctx) => {
        const count = value._sys_create_batch.submission_ids.length;
        ctx.showSnackbar({
          type: 'success',
          message: `${count} new batch${count > 1 ? 'es' : ''} created.`,
        });
        onCompleted?.(value, ctx);
      },
    }
  );
};
