/**
 * @generated SignedSource<<4562c5533199a14af7ef8cc89f77d3bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getDuplicatedChartOfAccountQuery$variables = {
  name?: string | null;
};
export type getDuplicatedChartOfAccountQuery$data = {
  readonly sys_organization_master_chart_of_accounts: ReadonlyArray<{
    readonly master_chart_of_account_id: number;
  }>;
};
export type getDuplicatedChartOfAccountQuery = {
  response: getDuplicatedChartOfAccountQuery$data;
  variables: getDuplicatedChartOfAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "chart_of_accounts_name"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organization_master_chart_of_accounts",
    "kind": "LinkedField",
    "name": "sys_organization_master_chart_of_accounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "master_chart_of_account_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getDuplicatedChartOfAccountQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getDuplicatedChartOfAccountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "50bc5c1beb3e27ed0d9250019ccc2a5c",
    "id": null,
    "metadata": {},
    "name": "getDuplicatedChartOfAccountQuery",
    "operationKind": "query",
    "text": "query getDuplicatedChartOfAccountQuery(\n  $name: String\n) {\n  sys_organization_master_chart_of_accounts(where: {is_active: {_eq: true}, chart_of_accounts_name: {_ilike: $name}}, limit: 1) {\n    master_chart_of_account_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "81ee0ee571a014e050764a54d62a8506";

export default node;
