/**
 * @generated SignedSource<<16dbeb05f27c8b08475dee8f7e92aa1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getAssignProviderOpenedBatchesSubscription$variables = {
  property_ids?: ReadonlyArray<number> | null;
};
export type getAssignProviderOpenedBatchesSubscription$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly property_batches: ReadonlyArray<{
      readonly property: {
        readonly entity_id: string;
      };
    }>;
    readonly submission_id: number;
    readonly submission_name: string;
  }>;
};
export type getAssignProviderOpenedBatchesSubscription = {
  response: getAssignProviderOpenedBatchesSubscription$data;
  variables: getAssignProviderOpenedBatchesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "submission_name": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_closed",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Literal",
                    "name": "is_active",
                    "value": {
                      "_eq": true
                    }
                  },
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_in",
                        "variableName": "property_ids"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "property_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batches"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAssignProviderOpenedBatchesSubscription",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAssignProviderOpenedBatchesSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "399cb5d462d37b9c23fca0e9f6a599ed",
    "id": null,
    "metadata": {},
    "name": "getAssignProviderOpenedBatchesSubscription",
    "operationKind": "subscription",
    "text": "subscription getAssignProviderOpenedBatchesSubscription(\n  $property_ids: [Int!]\n) {\n  sys_submissions(where: {is_closed: {_eq: false}, property_batches: {property: {is_active: {_eq: true}, property_id: {_in: $property_ids}}}}, order_by: {submission_name: asc}) {\n    submission_id\n    submission_name\n    property_batches {\n      property {\n        entity_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9796f68b7cf3a9b1643159813e86c5b7";

export default node;
