import React from 'react';
import { SinglePropertyBatchAccordionProps } from '../types';
import { PropertyBatchBaseAccordion } from '../PropertyBatchAccordion.base';
import { DatasetsTable } from '../../../../../components/DatasetsTable';
import { hasFile } from 'src/products/data-submission-portal/components/DatasetsTable/utils';
import {
  Button,
  FileOkIcon,
  FileFailedIcon,
  useConfirmationModal,
  ConfirmationModal,
  TabsProps,
} from '@cherre-frontend/ui';
import { PackageReportsTable } from '../../../../../components/PackageReportsTable';
import { useAsyncRunSubmissionValidation } from '../runSubmissionValidation';
import {
  useAppContext,
  useCherreEffect,
  useCherreEvent,
} from '@cherre-frontend/core';
import { useWorkflowApplyActionToBatch } from '../hooks/useWorkflowApplyActionToBatch';
import { PropertyBatchPreparerAlerts } from './PropertyBatchAlerts.preparer';
import { usePersonaAwarePush } from 'src/products/data-submission-portal/hooks/usePersonaAwarePush';
import {
  modalSearchState,
  packageReportModalState,
  reportSelectors,
  reportSlugModalState,
} from '../../../recoil';
import { WORKFLOW } from '../consts';

export const SinglePropertyBatchPreparerAccordion: React.FC<
  SinglePropertyBatchAccordionProps
> = ({ propertyBatch, investmentPropertyBatch }) => {
  const { property_batch_datasets: datasets } = propertyBatch;

  const push = usePersonaAwarePush();

  const [tabValue, setTabValue] = React.useState<TabsProps['value']>(0);

  const tabOnChange = (
    event: React.SyntheticEvent,
    newValue: TabsProps['value']
  ) => {
    setTabValue(newValue);
  };

  const runSubmissionValidation = useAsyncRunSubmissionValidation();
  const isEveryDatasetValid = datasets.every((d) => d.is_valid);

  const disablePackageReports =
    propertyBatch.property_batch_datasets.some(
      (d) => !hasFile(d.dataset_status.dataset_status_description)
    ) || !isEveryDatasetValid;

  const ctx = useAppContext();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onClickRunSubmissionValidation = useCherreEvent(
    'clickRunSubmissionValidation',
    async () => {
      setIsSubmitting(true);
      try {
        const { output, errors } = await runSubmissionValidation({
          property_batch_id: propertyBatch.property_batch_id,
        });

        if (errors) {
          console.error(errors);
          ctx.showSnackbar({
            message:
              'Something went wrong running Submission validation. Please try again.',
            type: 'error',
          });
        } else if (output?.ok) {
          ctx.showSnackbar({
            message: 'Submission validation succeeded',
            type: 'success',
          });
        } else {
          ctx.showSnackbar({
            message: 'Submission validation has failed',
            type: 'error',
            buttons: [
              {
                title: 'View Report',
                onClick: () =>
                  push(
                    `/dsp/{{persona}}/batch-validations/${propertyBatch.property_batch_id}`
                  ),
                type: 'primary',
                variant: 'contained',
              },
            ],
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  );

  useCherreEffect(
    'updatePropertyBatchValidation',
    (ctx) => {
      if (propertyBatch.is_valid === null && isEveryDatasetValid) {
        ctx.showSnackbar({
          message: 'Dataset validation is complete',
          type: 'success',
        });
      }
    },
    [isEveryDatasetValid, propertyBatch.is_valid]
  );

  const submitProperty = useWorkflowApplyActionToBatch(
    propertyBatch.property_batch_id,
    WORKFLOW.PREPARER_SUBMIT
  );

  const { modalProps, confirm } = useConfirmationModal();

  const noCrossFileValidationHasError =
    !propertyBatch.property_batch_validations.some(
      (validation) =>
        validation.is_valid === false && validation.validation_mode === 'hard'
    );

  const onClickViewSubmissionValidationReport = () =>
    push(
      `/dsp/{{persona}}/batch-validations/${propertyBatch.property_batch_id}`
    );

  return (
    <>
      <ConfirmationModal {...modalProps} />
      <PropertyBatchBaseAccordion
        tabValue={tabValue}
        tabOnChange={tabOnChange}
        investmentPropertyBatch={investmentPropertyBatch}
        propertyBatch={propertyBatch}
        tabActionsComponent={
          isEveryDatasetValid &&
          propertyBatch.is_valid !== null &&
          noCrossFileValidationHasError && (
            <Button
              onClick={onClickViewSubmissionValidationReport}
              color={propertyBatch.is_valid ? 'positive' : 'error'}
              startIcon={
                propertyBatch.is_valid ? <FileOkIcon /> : <FileFailedIcon />
              }
            >
              Submission Validation Report
            </Button>
          )
        }
        alertComponent={
          <PropertyBatchPreparerAlerts
            onClickSubmitProperty={() => {
              confirm({
                title: 'Submit Property',
                content: `Are you sure you want to submit the property ${
                  propertyBatch.properties_flattened_union.property_name ?? ''
                } - ID ${
                  propertyBatch.properties_flattened_union.property_code
                }?`,
                confirmLabel: 'Yes, Submit',
                cancelLabel: 'Cancel',
                handleConfirm: () => submitProperty(),
              });
            }}
            onClickRunSubmissionValidation={onClickRunSubmissionValidation}
            onClickViewSubmissionValidationReport={
              onClickViewSubmissionValidationReport
            }
            onClickReviewPackageReports={() => setTabValue(1)}
            propertyBatch={propertyBatch}
          />
        }
        tabs={[
          { label: 'Datasets', value: 0 },
          {
            label: 'Package Reports',
            disabled: disablePackageReports || isSubmitting,
            value: 1,
          },
        ]}
        tabPanels={[
          {
            value: 0,
            panel: (
              <DatasetsTable
                property_batch_id={propertyBatch.property_batch_id}
              />
            ),
          },
          {
            value: 1,
            panel: (
              <PackageReportsTable
                disableLastRowBorder
                property_batch_id={propertyBatch.property_batch_id}
                modalState={packageReportModalState}
                modalSearchState={modalSearchState}
                reportSelectors={reportSelectors}
                reportSlugModalState={reportSlugModalState}
              />
            ),
          },
        ]}
      />
    </>
  );
};
