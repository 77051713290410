/**
 * @generated SignedSource<<eab344f03df817b65a35b4dce34fbbbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilGetPackageReportUnresolvedRemindersQuery$variables = {
  package_report_id: number;
  property_batch_id: number;
};
export type recoilGetPackageReportUnresolvedRemindersQuery$data = {
  readonly sys_reminders: ReadonlyArray<{
    readonly created_at_datetime: string;
    readonly is_resolved: boolean;
    readonly reminder_id: number;
    readonly text: string;
    readonly user: {
      readonly sakura_user: {
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
};
export type recoilGetPackageReportUnresolvedRemindersQuery = {
  response: recoilGetPackageReportUnresolvedRemindersQuery$data;
  variables: recoilGetPackageReportUnresolvedRemindersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "package_report_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "created_at_datetime": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_resolved",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "package_report_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "package_report_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_reminders",
    "kind": "LinkedField",
    "name": "sys_reminders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sakura_user",
            "kind": "LinkedField",
            "name": "sakura_user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reminder_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_resolved",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetPackageReportUnresolvedRemindersQuery",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetPackageReportUnresolvedRemindersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "29cba525a9b34b716d2e6a20969013b3",
    "id": null,
    "metadata": {},
    "name": "recoilGetPackageReportUnresolvedRemindersQuery",
    "operationKind": "subscription",
    "text": "subscription recoilGetPackageReportUnresolvedRemindersQuery(\n  $package_report_id: Int!\n  $property_batch_id: Int!\n) {\n  sys_reminders(where: {package_report_id: {_eq: $package_report_id}, property_batch_id: {_eq: $property_batch_id}, is_resolved: {_eq: false}}, order_by: {created_at_datetime: asc}) {\n    user {\n      sakura_user {\n        first_name\n        last_name\n      }\n    }\n    reminder_id\n    is_resolved\n    created_at_datetime\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "642b3a206071a65f5445ee840a4c3c74";

export default node;
