/**
 * @generated SignedSource<<610e913a690da73ec31f4b6cde511ddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCreateIngestFileQuery$variables = {
  id: string;
};
export type useCreateIngestFileQuery$data = {
  readonly _sys_ingestion_ingest_async: {
    readonly errors: any | null;
    readonly output: {
      readonly data: {
        readonly property_batches: {
          readonly found: ReadonlyArray<number> | null;
          readonly not_found: ReadonlyArray<number> | null;
        };
      };
    } | null;
  } | null;
};
export type useCreateIngestFileQuery = {
  response: useCreateIngestFileQuery$data;
  variables: useCreateIngestFileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "_sys_ingestion_ingest_async",
    "kind": "LinkedField",
    "name": "_sys_ingestion_ingest_async",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ingestion_output",
        "kind": "LinkedField",
        "name": "output",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ingestion_output_data",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ingestion_output_data_property_batches",
                "kind": "LinkedField",
                "name": "property_batches",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "found",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "not_found",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateIngestFileQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateIngestFileQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3585ab67a26425c9fa3a46c068d2582b",
    "id": null,
    "metadata": {},
    "name": "useCreateIngestFileQuery",
    "operationKind": "query",
    "text": "query useCreateIngestFileQuery(\n  $id: uuid!\n) {\n  _sys_ingestion_ingest_async(id: $id) {\n    output {\n      data {\n        property_batches {\n          found\n          not_found\n        }\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "10118320823f719cabe11ef8faaf6614";

export default node;
