import { Box, Checkbox, Typography } from '@cherre-frontend/ui';
import React from 'react';

type Props = {
  title: string;
  checked?: boolean;
};

const DatasetOption: React.FC<Props> = ({ title, checked }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Checkbox checked={checked} />
      <Typography variant='body1'>{title}</Typography>
    </Box>
  );
};

export default DatasetOption;
