/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Box, Typography } from '@cherre-frontend/ui';
import React from 'react';
import {
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
} from '../../../recoil/dialog';
import { UserRoleSelect } from './UserRoleSelect';
import { useUserRoleSelect } from '../hooks/useUserRoleSelect';
import { assignReviewersDialogOpenState } from 'src/products/data-submission-portal/recoil/dialog';

type ReviewersSelectionFormProps = {
  reviewerSelectionType: string;
};

const ReviewersSelectionForm: React.FC<ReviewersSelectionFormProps> = ({
  reviewerSelectionType,
}) => {
  const [selectedUsers, setSelectedUsers] = useCherreState(
    reviewerSelectionType === 'Financial'
      ? selectedFinancialReviewerUsers
      : selectedOperationalReviewerUsers
  );

  const [isOpen] = useCherreState(assignReviewersDialogOpenState);

  if (!selectedUsers) {
    return null;
  }

  const { userRoleSelectPropsReviewers, showApproverPlusMessage } =
    useUserRoleSelect(
      selectedUsers,
      setSelectedUsers,
      isOpen,
      reviewerSelectionType
    );

  return (
    <>
      <Typography variant='h6'>{reviewerSelectionType} Submissions</Typography>
      <Box display={'grid'} gap={2}>
        {userRoleSelectPropsReviewers.map((props) => (
          <UserRoleSelect key={props.level} {...props} />
        ))}
        {showApproverPlusMessage && (
          <Typography variant='body2' color='grey.600' align='center'>
            An Approver Plus must be the last reviewer in the chain.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ReviewersSelectionForm;
