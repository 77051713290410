/**
 * @generated SignedSource<<8ec1ea8dd3143464465cdad1677c6ba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilPropertyBatchPackageReportInfoQuery$variables = {
  property_batch_id: number;
};
export type recoilPropertyBatchPackageReportInfoQuery$data = {
  readonly sys_property_batches_by_pk: {
    readonly comments: ReadonlyArray<{
      readonly package_report_id: number;
    }>;
    readonly package_reports: ReadonlyArray<{
      readonly package_report_id: number;
      readonly package_report_label: string;
      readonly report: {
        readonly report_slug: string;
      };
    }> | null;
    readonly property: {
      readonly entity_id: string;
      readonly property_id: number;
    };
    readonly property_batch_stage: {
      readonly property_batch_stage_id: number;
    };
    readonly reminders: ReadonlyArray<{
      readonly package_report_id: number;
    }>;
  } | null;
};
export type recoilPropertyBatchPackageReportInfoQuery = {
  response: recoilPropertyBatchPackageReportInfoQuery$data;
  variables: recoilPropertyBatchPackageReportInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "property_batch_id",
    "variableName": "property_batch_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_property_batch_stages",
  "kind": "LinkedField",
  "name": "property_batch_stage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "property_batch_stage_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "package_report_label": "asc"
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "package_report_label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "package_report_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_reports",
  "kind": "LinkedField",
  "name": "report",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "report_slug",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v5/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_comments",
  "kind": "LinkedField",
  "name": "comments",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "is_resolved": {
          "_eq": false
        }
      }
    }
  ],
  "concreteType": "sys_reminders",
  "kind": "LinkedField",
  "name": "reminders",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": "reminders(where:{\"is_resolved\":{\"_eq\":false}})"
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_properties",
  "kind": "LinkedField",
  "name": "property",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "property_id",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilPropertyBatchPackageReportInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "sys_package_reports",
            "kind": "LinkedField",
            "name": "package_reports",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v6/*: any*/),
                "action": "THROW",
                "path": "sys_property_batches_by_pk.package_reports.report"
              }
            ],
            "storageKey": "package_reports(order_by:{\"package_report_label\":\"asc\"})"
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilPropertyBatchPackageReportInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "sys_package_reports",
            "kind": "LinkedField",
            "name": "package_reports",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": "package_reports(order_by:{\"package_report_label\":\"asc\"})"
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2632a65da2a70259f15d77c2ec42df0e",
    "id": null,
    "metadata": {},
    "name": "recoilPropertyBatchPackageReportInfoQuery",
    "operationKind": "subscription",
    "text": "subscription recoilPropertyBatchPackageReportInfoQuery(\n  $property_batch_id: Int!\n) {\n  sys_property_batches_by_pk(property_batch_id: $property_batch_id) {\n    property_batch_stage {\n      property_batch_stage_id\n    }\n    package_reports(order_by: {package_report_label: asc}) {\n      package_report_label\n      package_report_id\n      report {\n        report_slug\n      }\n    }\n    comments {\n      package_report_id\n    }\n    reminders(where: {is_resolved: {_eq: false}}) {\n      package_report_id\n    }\n    property {\n      entity_id\n      property_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a2e0585ef918cf1c1e6af0b1c562324";

export default node;
