/**
 * @generated SignedSource<<e3c7d4b41bc40c29bd55176f438977dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getCOAMappingsQuery$variables = {
  provider_id: number;
};
export type getCOAMappingsQuery$data = {
  readonly sys_mapping_sets: ReadonlyArray<{
    readonly mapping_field_id: number;
    readonly mapping_set_description: string;
    readonly mapping_set_id: number;
  }>;
};
export type getCOAMappingsQuery = {
  response: getCOAMappingsQuery$data;
  variables: getCOAMappingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "mapping_set_description": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "kind": "Literal",
            "name": "mapping_field",
            "value": {
              "mapping_field_name": {
                "_eq": "gl_account_code"
              }
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "provider_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_sets",
    "kind": "LinkedField",
    "name": "sys_mapping_sets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getCOAMappingsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getCOAMappingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d19cd692cddc2c4a825057fbfb08c393",
    "id": null,
    "metadata": {},
    "name": "getCOAMappingsQuery",
    "operationKind": "query",
    "text": "query getCOAMappingsQuery(\n  $provider_id: Int!\n) {\n  sys_mapping_sets(where: {provider_id: {_eq: $provider_id}, is_active: {_eq: true}, mapping_field: {mapping_field_name: {_eq: \"gl_account_code\"}}}, order_by: {mapping_set_description: asc}) {\n    mapping_set_id\n    mapping_set_description\n    mapping_field_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "43f64a104fd887f5025d7b08e5ede063";

export default node;
