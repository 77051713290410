import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Typography,
  styled,
  CheckIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import {
  Stage,
  assignDatasetsToPropertyState,
  dialogStage,
} from '../localState';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const HeaderContainer = styled(Grid)``;

const ProgressBox = styled<React.FC<GridProps>>(Grid)`
  position: relative;
  height: 20px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  gap: 16px;

  &::before {
    content: '';
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.grey[300]};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
  }
`;

type ProgressStepStatus = 'current' | 'complete' | 'next';

const ProgressCircle = styled<
  React.FC<BoxProps & { status?: ProgressStepStatus }>
>(Box)`
  position: relative;
  padding: 0px;
  border-style: solid;
  height: 20px;
  width: 20px;
  border-radius: 9999px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'current':
        return theme.palette.primary.main;
      case 'complete':
        return theme.palette.positive?.main;
      case 'next':
        return 'white';
      default:
        return theme.palette.primary.main;
    }
  }};
  border-width: 2px;
  border-color: ${({ theme, status }) => {
    switch (status) {
      case 'current':
        return 'white';
      case 'complete':
        return theme.palette.positive?.main;
      case 'next':
        return 'white';
      default:
        return theme.palette.primary.main;
    }
  }};
  outline-width: 2px;
  outline-style: solid;
  outline-color: ${({ theme, status }) => {
    switch (status) {
      case 'current':
        return theme.palette.primary.main;
      case 'complete':
        return theme.palette.positive?.main;
      case 'next':
        return theme.palette.grey[300];
      default:
        return theme.palette.primary.main;
    }
  }};
  z-index: 200;
`;

const Title = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  /* Headings/H4 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Subtitle = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  /* Headings/Subtitle 2 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: white;
  font-size: 16px;
`;

export type DialogHeaderProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
};

export const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  const unifiedIngestEnabled = useFeatureFlag('DSPNewIngestion');

  const assignDatasetsToProperty = useCherreValue(
    assignDatasetsToPropertyState
  );

  const currentStage = useCherreValue(dialogStage);

  // If we're assigning datasets to a single property, override the subtitle to show information about the property
  const subtitle = React.useMemo(() => {
    if (assignDatasetsToProperty) {
      const idAndName = [
        assignDatasetsToProperty.entity_id,
        assignDatasetsToProperty.entity_name,
      ]
        .filter(Boolean)
        .join(' - ');
      return `Entity ID ${idAndName}`;
    } else {
      return props.subtitle;
    }
  }, [assignDatasetsToProperty, props.subtitle]);

  const stages = [
    Stage.SelectDatasets,
    Stage.SelectDatasetTemplate,
    Stage.SelectValidations,
    Stage.SelectCrossFileValidations,
  ].filter((stage) => {
    if (stage === Stage.SelectDatasetTemplate) {
      return unifiedIngestEnabled;
    }

    return true;
  });

  const getStageStatus = React.useCallback<
    (stage: Stage) => ProgressStepStatus
  >(
    (stage) => {
      if (currentStage === stage) {
        return 'current';
      }
      const currentStageIndex = stages.indexOf(
        currentStage ?? Stage.SelectDatasets
      );
      const stageIndex = stages.indexOf(stage);
      if (currentStageIndex > stageIndex) {
        return 'complete';
      } else {
        return 'next';
      }
    },
    [currentStage]
  );

  return (
    <HeaderContainer container>
      <Grid item xs>
        <Title variant='h4'>{props.title}</Title>
        <Subtitle variant='subtitle2'>{subtitle}</Subtitle>
      </Grid>
      <Grid item xs='auto'>
        <ProgressBox>
          {stages.map((stage) => {
            const status = getStageStatus(stage);

            return (
              <ProgressCircle key={stage} status={status}>
                {status === 'complete' ? <StyledCheckIcon /> : null}
              </ProgressCircle>
            );
          })}
        </ProgressBox>
      </Grid>
    </HeaderContainer>
  );
};

DialogHeader.displayName = 'DialogHeader';
