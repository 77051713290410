/**
 * @generated SignedSource<<155cd76480cce521824fe521d0b53f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _sys_property_assign_reviewers_input = {
  property_ids: ReadonlyArray<number>;
  provider_id: number;
  reviewers: ReadonlyArray<_sys_property_assign_reviewers_input_reviewer_user>;
};
export type _sys_property_assign_reviewers_input_reviewer_user = {
  is_active: boolean;
  property_role_id: number;
  submission_type_id: number;
  user_id: number;
};
export type useAssignSingleChainReviewerMutation$variables = {
  input: _sys_property_assign_reviewers_input;
};
export type useAssignSingleChainReviewerMutation$data = {
  readonly _sys_property_assign_reviewers: {
    readonly affected_rows: number | null;
  } | null;
};
export type useAssignSingleChainReviewerMutation = {
  response: useAssignSingleChainReviewerMutation$data;
  variables: useAssignSingleChainReviewerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "_sys_property_assign_reviewers_output",
    "kind": "LinkedField",
    "name": "_sys_property_assign_reviewers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssignSingleChainReviewerMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssignSingleChainReviewerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "52056a3be34a68f13b63aa17ad57d145",
    "id": null,
    "metadata": {},
    "name": "useAssignSingleChainReviewerMutation",
    "operationKind": "mutation",
    "text": "mutation useAssignSingleChainReviewerMutation(\n  $input: _sys_property_assign_reviewers_input!\n) {\n  _sys_property_assign_reviewers(input: $input) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2c7c4238da30625aff84efc455dc89a";

export default node;
