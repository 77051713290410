import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getFlatfileTokenQuery } from './__generated__/getFlatfileTokenQuery.graphql';

export const getFlatfileToken = graphQLSelector({
  query: graphql`
    query getFlatfileTokenQuery($dataset_id: Int!) {
      _sys_get_flatfile_token(dataset_id: $dataset_id) {
        token
        mount
        api
      }
    }
  ` as GraphQLReturn<getFlatfileTokenQuery>,
  mapResponse: (resp) => resp._sys_get_flatfile_token,
});
