import { useAppContext } from '@cherre-frontend/core';
import {
  SetterOrUpdater,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import React, { useMemo } from 'react';
import Table from 'src/products/data-submission-portal/components/PropertiesTable';
import { SelectPropertiesState as state } from '../recoil';
import { groupBy } from 'lodash';

export type PropertiesTableProps = {
  selectedTab: number;
};

export const PropertiesTable: React.FC<PropertiesTableProps> = ({
  selectedTab,
}) => {
  const { logger } = useAppContext();

  const [sorting, onSortingChange] = useCherreState(
    state.tableSort(selectedTab)
  );
  logger.info('sort', sorting);

  const properties = useCherreValue(state.tableData(selectedTab));
  logger.info('properties', properties);

  const [rowSelection, onRowSelectionChange] = useCherreState(
    state.selection(selectedTab)
  );
  logger.info('selected', rowSelection);

  const propertiesGroups = useMemo(
    () =>
      groupBy(
        (properties ?? []).filter((p) => p.parent_property_id),
        'parent_property_id'
      ),
    [properties]
  );

  const onRowSelectionChangeGroup: SetterOrUpdater<state.RowSelection> = (
    value
  ) => {
    const newValue =
      typeof value === 'function' ? value(rowSelection ?? {}) : value;

    const keys = new Set([
      ...Object.keys(rowSelection ?? {}),
      ...Object.keys(newValue),
    ]);

    for (const key of keys) {
      const selected = newValue[key];

      if (selected === rowSelection?.[key]) {
        continue;
      }

      const group = Object.values(propertiesGroups).find((g) =>
        g.some((p) => p.property_id.toString() === key)
      );

      group?.forEach((p) => {
        selected
          ? (newValue[p.property_id] = selected)
          : delete newValue[p.property_id];
      });
    }

    onRowSelectionChange(newValue);
  };

  return (
    <Table
      key={`${selectedTab}`}
      sorting={sorting || []}
      onSortingChange={onSortingChange}
      data={properties}
      rowSelection={rowSelection}
      onRowSelectionChange={onRowSelectionChangeGroup}
      height='40vh'
      muiSelectCheckboxProps={{
        title: 'Select',
      }}
    />
  );
};

PropertiesTable.displayName = 'PropertiesTable';
