/**
 * @generated SignedSource<<560a013ff2bdfd35a0cbea120c71f1dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getMasterChartOfAccountsSubscription$variables = {};
export type getMasterChartOfAccountsSubscription$data = {
  readonly sys_organization_master_chart_of_accounts: ReadonlyArray<{
    readonly chart_of_accounts_name: string;
    readonly master_chart_of_account_id: number;
  }>;
};
export type getMasterChartOfAccountsSubscription = {
  response: getMasterChartOfAccountsSubscription$data;
  variables: getMasterChartOfAccountsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "chart_of_accounts_name": "asc"
        }
      },
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "is_active": {
            "_eq": true
          }
        }
      }
    ],
    "concreteType": "sys_organization_master_chart_of_accounts",
    "kind": "LinkedField",
    "name": "sys_organization_master_chart_of_accounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "master_chart_of_account_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chart_of_accounts_name",
        "storageKey": null
      }
    ],
    "storageKey": "sys_organization_master_chart_of_accounts(order_by:{\"chart_of_accounts_name\":\"asc\"},where:{\"is_active\":{\"_eq\":true}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getMasterChartOfAccountsSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getMasterChartOfAccountsSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b81d11cfca086c1ca8e7e735648a5f69",
    "id": null,
    "metadata": {},
    "name": "getMasterChartOfAccountsSubscription",
    "operationKind": "subscription",
    "text": "subscription getMasterChartOfAccountsSubscription {\n  sys_organization_master_chart_of_accounts(order_by: {chart_of_accounts_name: asc}, where: {is_active: {_eq: true}}) {\n    master_chart_of_account_id\n    chart_of_accounts_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4be2cb99a9439db66292fff5286579d";

export default node;
