import {
  GraphQLReturn,
  graphQLSelector,
  Writeable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyTypeCustomAttributesQuery } from './__generated__/getPropertyTypeCustomAttributesQuery.graphql';

export const getPropertyTypeCustomAttributes = graphQLSelector({
  query: graphql`
    query getPropertyTypeCustomAttributesQuery {
      sys_property_type_custom_attributes {
        custom_attributes
      }
    }
  ` as GraphQLReturn<getPropertyTypeCustomAttributesQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (response) => {
    const propertyTypeCustomAttributes: Writeable<
      { custom_attributes: { slug: string; label: string }[] }[]
    > = response.sys_property_type_custom_attributes ?? [];

    return propertyTypeCustomAttributes
      .filter((pt) => !!pt.custom_attributes?.length)
      .flatMap((pt) => pt.custom_attributes);
  },
});
