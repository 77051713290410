import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDeleteAccountTreeMutation } from './__generated__/useDeleteAccountTreeMutation.graphql';

export const useDeleteAccountTree = (
  account_tree_id: number,
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useDeleteAccountTreeMutation($account_tree_id: Int!) {
        delete_sys_organization_account_tree_details(
          where: { account_tree_id: { _eq: $account_tree_id } }
        ) {
          affected_rows
        }
        delete_sys_organization_account_trees(
          where: { organization_account_tree_id: { _eq: $account_tree_id } }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useDeleteAccountTreeMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        return { account_tree_id };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
