/**
 * @generated SignedSource<<aefcf15557156a50b8b7ef2d62a3cbc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queryGetFilterValuesQuery$variables = {};
export type queryGetFilterValuesQuery$data = {
  readonly providers: ReadonlyArray<{
    readonly provider: {
      readonly label: string;
      readonly value: number;
    };
  }>;
  readonly types: ReadonlyArray<{
    readonly submission_type: {
      readonly label: string;
      readonly value: number;
    };
  }>;
};
export type queryGetFilterValuesQuery = {
  response: queryGetFilterValuesQuery$data;
  variables: queryGetFilterValuesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "is_closed": {
      "_eq": true
    }
  }
},
v1 = [
  {
    "alias": "providers",
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "provider_id"
      },
      (v0/*: any*/)
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": "label",
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          },
          {
            "alias": "value",
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_submissions(distinct_on:\"provider_id\",where:{\"is_closed\":{\"_eq\":true}})"
  },
  {
    "alias": "types",
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "submission_type_id"
      },
      (v0/*: any*/)
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submission_types",
        "kind": "LinkedField",
        "name": "submission_type",
        "plural": false,
        "selections": [
          {
            "alias": "label",
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_label",
            "storageKey": null
          },
          {
            "alias": "value",
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_submissions(distinct_on:\"submission_type_id\",where:{\"is_closed\":{\"_eq\":true}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryGetFilterValuesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "queryGetFilterValuesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87c8087a9a7c1e90577fd92aa1cc5b7d",
    "id": null,
    "metadata": {},
    "name": "queryGetFilterValuesQuery",
    "operationKind": "query",
    "text": "query queryGetFilterValuesQuery {\n  providers: sys_submissions(distinct_on: provider_id, where: {is_closed: {_eq: true}}) {\n    provider {\n      label: provider_name\n      value: provider_id\n    }\n  }\n  types: sys_submissions(distinct_on: submission_type_id, where: {is_closed: {_eq: true}}) {\n    submission_type {\n      label: submission_type_label\n      value: submission_type_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6eb72122cb3c685828351b345a44b850";

export default node;
