import React from 'react';
import BatchList from './components/BatchList';
import { PageContainer, PageHeader, Panel } from '@cherre-frontend/ui';

import {
  ContentContainer,
  HeaderContainer,
  StyledToolbar,
  StyledSearchInput,
} from './styles';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { getAllUnresolvedReminders, searchSelector } from './recoil';
import ReminderAlert from '../../components/ReminderAlert';
import { reminderDrawerState } from 'src/components/RemindersDrawer/recoil';

const SubmissionsPage: React.FC = () => {
  const [value, setValue] = useCherreState(searchSelector);
  const unresolvedReminders = useCherreValue(getAllUnresolvedReminders({}));
  const remindersCount = unresolvedReminders?.length ?? 0;

  const setReminderDrawer = useCherreSetState(reminderDrawerState);

  const onViewRemindersClicked = () => {
    if (!unresolvedReminders || unresolvedReminders.length === 0) {
      return;
    }

    const entityIds = [
      ...new Set(
        unresolvedReminders.map((reminder) => reminder.property.entity_id)
      ),
    ];
    const submissionTypes = [
      ...new Set(
        unresolvedReminders.map(
          (reminder) =>
            reminder.property_batch.submission.submission_type
              .submission_type_label
        )
      ),
    ];
    setReminderDrawer({
      isOpen: true,
      showCompleted: false,
      filterSelections: {
        'Entity ID': entityIds,
        Batch: [],
        Type: submissionTypes,
      },
    });
  };

  return (
    <PageContainer id='DSPSubmissionsPage'>
      <HeaderContainer className='suspend'>
        <PageHeader title='Submission Batches' />
        {remindersCount > 0 && (
          <ReminderAlert
            onViewRemindersClicked={onViewRemindersClicked}
            localStorageKey='submission-batches-banner-hidden'
            subtitle={`There are ${remindersCount} unresolved reminders for this submission cycle.`}
          />
        )}

        <StyledToolbar>
          <StyledSearchInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </StyledToolbar>
      </HeaderContainer>
      <ContentContainer>
        <Panel id='open-batches-list' style={{ flex: 1 }}>
          <BatchList
            title='Open Batches'
            emptyListMessage='No open batches'
            isClosed={false}
            value={value ?? ''}
          />
        </Panel>
        <Panel id='completed-batches-list'>
          <BatchList
            title='Completed Batches'
            emptyListMessage='No closed batches'
            isClosed
            value={value ?? ''}
          />
        </Panel>
      </ContentContainer>
    </PageContainer>
  );
};

SubmissionsPage.displayName = 'SubmissionsPage';

export default SubmissionsPage;
