import { useMemo } from 'react';
import { constSelector, useCherreValue } from '@cherre-frontend/data-fetching';
import { getCurrentPreparersAndReviewers } from '../queries/getCurrentPreparersAndReviewers';
import type { getCurrentPreparersAndReviewersQuery$data } from '../queries/__generated__/getCurrentPreparersAndReviewersQuery.graphql';

type QueryResponse =
  getCurrentPreparersAndReviewersQuery$data['sys_properties_flattened_union'];

export const useCurrentPreparersAndReviewers = (
  shouldLoad: boolean
): QueryResponse[number]['properties_roles_users'] | undefined => {
  const currentPreparersAndReviewers = useCherreValue(
    shouldLoad ? getCurrentPreparersAndReviewers() : constSelector([])
  );

  return useMemo(() => {
    if (!currentPreparersAndReviewers) {
      return currentPreparersAndReviewers;
    }

    return currentPreparersAndReviewers.flatMap(
      ({ properties_roles_users }) => properties_roles_users
    );
  }, [currentPreparersAndReviewers]);
};
