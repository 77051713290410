import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { refine } from '@cherre-frontend/data-fetching';
import { MasterFileDetailRoute } from '../../routes';
import { atom } from 'recoil';

export const masterFileDetailPageTableSearch =
  MasterFileDetailRoute.searchParamsSelectorFamily({
    key: 'master-file-detail-table-search',
    refine: withDefault(string(), ''),
  });

export const masterFileDetailPageTableState =
  MasterFileDetailRoute.searchParamsSelectorFamily({
    key: 'master-file-detail-table-state',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'account_code', desc: false }],
      }
    ),
  });

export const masterFileDetailTableFiltersState =
  MasterFileDetailRoute.searchParamsSelectorFamily({
    key: 'master-file-detail-table-filters',
    refine: withDefault(
      object({
        account_code: withDefault(writableArray(string()), []),
        account_description: withDefault(writableArray(string()), []),
      }),
      {
        account_code: [],
        account_description: [],
      }
    ),
  });

export const masterFileDetailsHasData = atom({
  key: 'mapping-sets-table-has-data',
  default: false,
});
