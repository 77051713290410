import {
  useCherreState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Alert,
  AlertProps,
  AlertTitle,
  Button,
  CancelIcon,
  CheckCircleIcon,
  CloudUploadIcon,
  DotSeparator,
  FilterChip,
  Grid,
  Menu,
  MenuItem,
  SearchInput,
  StyleSelector,
  Typography,
  WarningIcon,
  styled,
  FormControl,
  Tooltip,
  DownloadFileIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import { useJsonLogic } from 'src/products/data-submission-portal/hooks/useJsonLogic';
import { z } from 'zod';
import {
  modalSearchState,
  modalStatusFilterState,
  modalValidationState,
} from '../../recoil';
import { summaryInfo } from '../Header/query';
import { shouldShowStatusFilter } from './utils';
import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';
import { PersonaScope } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { useUploadDatasetPush } from 'src/products/data-submission-portal/hooks/useUploadDatasetPush';
import { useDownloadReportTable } from 'src/products/data-submission-portal/components/Reports/useDownloadTable';
import { ReportViewTypeSelect } from 'src/products/data-submission-portal/components/Reports/ReportViewTypeSelector';

const DownloadButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  min-width: 0px;
  & .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ReplaceButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: #fff;
  }
`;

const alertColorMap: StyleSelector<AlertProps> = ({ severity, theme }) => {
  switch (severity) {
    case 'success':
      return theme.accents.positive.main;
    case 'warning':
      return theme.accents.warning.main;
    case 'error':
      return theme.accents.error.main;
    default:
      throw new Error('Invalid severity');
  }
};

const alertIconMap = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <CancelIcon />,
};

const AlertStyled = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.warning.light};
  font-size: 12px;
  font-weight: 500;
  color: ${alertColorMap};
  & .MuiAlert-icon {
    color: ${alertColorMap};
  }
  & .MuiAlert-message {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  padding: 6px;
  margin-bottom: 20px;
`;

const AlertTitleStyled = styled(AlertTitle)`
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  whitespace: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

const bannerSchema = z.object({
  banner_title: z.string(),
  banner_subtitle: z.string().optional(),
  banner_mode: z.enum(['success', 'warning', 'error']),
});

export function ReportHeader() {
  const validation = useCherreValue(modalValidationState);

  const summary = useCherreValue(summaryInfo());
  const [search, , setSearch] = useCherreStateDebounced(modalSearchState);

  const [status, setStatus] = useCherreState(modalStatusFilterState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleStatusFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusFilterClose = () => {
    setAnchorEl(null);
  };

  const handleStatusFilterChange =
    (value: NonNullable<typeof status>) => () => {
      setStatus(value);
      handleStatusFilterClose();
    };

  const banner = useJsonLogic()(
    validation?.dataset_validation_rule.banner_config,
    validation,
    bannerSchema
  );

  const { uploadDatasetPush } = useUploadDatasetPush();

  const downloadReport = useDownloadReportTable();

  const label = validation?.dataset_validation_rule?.validation_label;

  const filename = `${summary?.submission_name}_${label}.xlsx`;

  return (
    <Grid display='grid' gap={2}>
      <Grid container direction={'row'}>
        <Grid item xs>
          <Typography variant='h3'>
            {validation?.dataset_validation_rule.validation_label}
          </Typography>
          <Typography variant='body1'>
            <DotSeparator>
              <span className='suspend'>
                {summary?.dataset_label || 'Unknown Dataset'}
              </span>
              <span className='suspend'>
                ID {summary?.property_id || 'Unknown'}
              </span>
            </DotSeparator>
            {summary?.property_name && (
              <span className='suspend'>- {summary?.property_name}</span>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Grid display='flex' gap={1} alignItems={'center'}>
            {shouldShowStatusFilter(
              validation?.dataset_validation_rule.report?.report_slug || ''
            ) ? (
              <>
                <FilterChip
                  label={status || ''}
                  onClick={handleStatusFilterClick}
                  active={status !== 'Any Validation Status'}
                  onDelete={handleStatusFilterChange('Any Validation Status')}
                />
                <Menu
                  id='more-items-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleStatusFilterClose}
                  data-testid='more-items-menu'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    style={{ width: '200px', gap: '6px' }}
                    onClick={handleStatusFilterChange('Any Validation Status')}
                  >
                    Any Validation Status
                  </MenuItem>
                  <MenuItem
                    style={{ width: '200px', gap: '6px' }}
                    onClick={handleStatusFilterChange('Passed')}
                  >
                    Passed
                  </MenuItem>
                  <MenuItem
                    style={{ width: '200px', gap: '6px' }}
                    onClick={handleStatusFilterChange('Failed')}
                  >
                    Failed
                  </MenuItem>
                </Menu>
              </>
            ) : null}
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <ReportViewTypeSelect />
            </FormControl>
            <PersonaScope persona='preparer'>
              <ReplaceButton
                onClick={() => {
                  uploadDatasetPush(summary?.property_batch_dataset_id);
                }}
                startIcon={<CloudUploadIcon />}
                color='primary'
                variant='outlined'
                disabled={
                  (summary?.property_batch_stage_id ?? 0) >=
                  PropertyBatchStage.REVIEW_1.id
                }
              >
                Replace File
              </ReplaceButton>
            </PersonaScope>
            <SearchInput
              value={search}
              onChange={(v) => setSearch(v.target.value)}
            />
            <Tooltip title='Download Report'>
              <div>
                <DownloadButton
                  onClick={() =>
                    validation?.dataset_validation_rule.report?.report_slug
                      ? downloadReport(
                          validation?.dataset_validation_rule.report
                            ?.report_slug,
                          filename
                        )
                      : Promise.resolve()
                  }
                  startIcon={<DownloadFileIcon />}
                  variant='outlined'
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {validation && banner ? (
          <AlertStyled
            data-testid='submission-alert'
            severity={banner.banner_mode}
            title={banner.banner_title}
            icon={alertIconMap[banner.banner_mode]}
          >
            <AlertTitleStyled>{banner.banner_title}</AlertTitleStyled>
            {banner.banner_subtitle}
          </AlertStyled>
        ) : null}
      </Grid>
    </Grid>
  );
}
