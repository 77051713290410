import { Box, Typography, styled } from '@cherre-frontend/ui';
import React from 'react';
import UserRow from './UserRow';
import { recoilgetSubmissionUsersQuery$data } from './__generated__/recoilgetSubmissionUsersQuery.graphql';
const Container = styled(Box)`
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
  font-size: 14px;
`;

type ReviewerSectionProps = {
  title: string;
  reviewers: NonNullable<
    NonNullable<
      recoilgetSubmissionUsersQuery$data['sys_property_batches_by_pk']
    >['users_with_access_to_batch']
  >;
};

export const ReviewerSection: React.FC<ReviewerSectionProps> = ({
  title,
  reviewers,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {reviewers.map((reviewer) => (
        <UserRow
          key={reviewer.property_role_user_id}
          email={reviewer.user.sakura_user?.email}
          name={`${reviewer.user.sakura_user?.first_name} ${reviewer.user.sakura_user?.last_name}`}
        />
      ))}
    </Container>
  );
};
