import { useCherreEvent } from '@cherre-frontend/core';
import {
  constSelector,
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Button, PageContainer, PageHeader, styled } from '@cherre-frontend/ui';
import React from 'react';
import { useHistory } from 'react-router';
import { getDataset } from './queries/getDataset';
import { UploadDatasetBulkRoute } from '../../routes';
import { getFlatfileToken } from '../../recoil/getFlatfileToken';
import { useFlatfile } from '../../hooks/useFlatfile';
import { bulkUploadModalState } from '../submission-batch-details/components/BulkUploadModal/recoil';

const FlatfileContainer = styled('div')`
  width: 100%;
  display: flex;
  flex: 1;
  border: 0;
  & iframe {
    width: 100%;
    flex: 1;
    border: 0;
  }
`;

const UploadDatasetBulkPage: React.FC = () => {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();

  const routeParams = useCherreValue(UploadDatasetBulkRoute.routeParamSelector);

  const setBulkUploadModalState = useCherreSetState(bulkUploadModalState);

  const dataset = useCherreValue(getDataset());

  const flatfileToken = useCherreValue(
    routeParams?.dataset_id
      ? getFlatfileToken({
          dataset_id: routeParams?.dataset_id,
        })
      : constSelector(null)
  );

  if (!flatfileToken) {
    goBack();
    return null;
  }

  const pushOrGoBack = () => {
    setBulkUploadModalState(
      (state) =>
        ({
          ...state,
          open: false,
          closedSelectedDataset: null,
        } as const)
    );

    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      if (!routeParams?.dataset_id) {
        return;
      }

      push(`/dsp/preparer/submissions/${routeParams?.submission_id}`);
    }
  };

  const onComplete = useCherreEvent(
    'onCompleteCreateIngestFile',
    async (data: { batchId: string }) => {
      setBulkUploadModalState(
        (state) =>
          ({
            ...state,
            open: true,
            selectedDataset: routeParams?.dataset_id.toString() ?? '',
            uploadedFileId: data.batchId,
            step: 'SELECT_PROPERTIES',
          } as const)
      );

      push(`/dsp/preparer/submissions/${routeParams?.submission_id}`);
    }
  );

  useFlatfile(flatfileToken, '#flatfile', { onComplete, skipReview: true });

  if (!routeParams?.dataset_id || !dataset) {
    return null;
  }

  const cancelUpload = () => {
    pushOrGoBack();
  };

  return (
    <PageContainer id='UploadDatasetBulkPage'>
      <PageHeader title={`Bulk File Uploader: ${dataset.dataset_label}`}>
        <Button color='primary' variant='outlined' onClick={cancelUpload}>
          Cancel Upload
        </Button>
      </PageHeader>
      <FlatfileContainer id='flatfile' key={routeParams.dataset_id} />
    </PageContainer>
  );
};

export default UploadDatasetBulkPage;
