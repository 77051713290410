import {
  Box,
  Button,
  CloseIcon,
  Grid,
  IconButton,
  Typography,
  WarningAltIcon,
  styled,
} from '@cherre-frontend/ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Container = styled(Grid, {
  shouldForwardProp: (propName) => propName !== 'isEmulatedSession',
})<{ isEmulatedSession: boolean }>`
  position: fixed;
  top: ${(props) => (props.isEmulatedSession ? '60px' : '24px')};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  padding: 6px 10px;
  width: 650px;
  border-radius: 10px;
  z-index: 1200;
`;

const Icon = styled(WarningAltIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-size: 20px;
  color: ${({ theme }) => theme.accents.warning.main};
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 800;
  line-height: 16px;
`;

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[200]};
  font-size: 12px;
  line-height: 14px;
`;

const Row = styled(Grid)`
  display: flex;
  align-items: center;
`;

const ViewRemindersButton = styled(Button)`
  font-weight: 500;
`;

const CloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

type ReminderAlertProps = {
  onViewRemindersClicked: () => void;
  subtitle: string;
  localStorageKey: string;
};

const ReminderAlert: React.FC<ReminderAlertProps> = ({
  onViewRemindersClicked,
  subtitle,
  localStorageKey,
}) => {
  const [userHidBanner, setUserHidBanner] = useState(false);

  const { isEmulatedSession = false } = useSelector((state) => ({
    isEmulatedSession: state.user.profile.value.isEmulatedSession,
  }));

  useEffect(() => {
    const reminderBannerClosed = sessionStorage.getItem(localStorageKey);
    setUserHidBanner(!!reminderBannerClosed);
  }, []);

  if (userHidBanner) {
    return null;
  }

  const onReminderBannerClosed = () => {
    setUserHidBanner(true);
    sessionStorage.setItem(localStorageKey, 'true');
  };

  return (
    <Container isEmulatedSession={isEmulatedSession}>
      <Row>
        <Icon color='warning' />
        <Box>
          <Title>Reminders</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Box>
      </Row>

      <Row>
        <ViewRemindersButton onClick={onViewRemindersClicked}>
          View Reminders
        </ViewRemindersButton>
        <CloseButton onClick={onReminderBannerClosed}>
          <CloseIcon />
        </CloseButton>
      </Row>
    </Container>
  );
};

export default ReminderAlert;
