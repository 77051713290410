/**
 * @generated SignedSource<<cffe0ef497c2f83088ec76d89fe24a46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queryUnassignedPropertyCountQuery$variables = {};
export type queryUnassignedPropertyCountQuery$data = {
  readonly sys_properties_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type queryUnassignedPropertyCountQuery = {
  response: queryUnassignedPropertyCountQuery$data;
  variables: queryUnassignedPropertyCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_eq": true
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "_not": {
            "property_batches": {}
          },
          "properties_datasets": {
            "is_active": (v0/*: any*/)
          },
          "properties_roles_users": {
            "is_active": (v0/*: any*/),
            "property_role_id": {
              "_in": [
                2,
                3,
                4
              ]
            }
          }
        }
      }
    ],
    "concreteType": "sys_properties_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties_aggregate(where:{\"_not\":{\"property_batches\":{}},\"properties_datasets\":{\"is_active\":{\"_eq\":true}},\"properties_roles_users\":{\"is_active\":{\"_eq\":true},\"property_role_id\":{\"_in\":[2,3,4]}}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryUnassignedPropertyCountQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "queryUnassignedPropertyCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e6a62778cd4929d6ff07ce94003edd8",
    "id": null,
    "metadata": {},
    "name": "queryUnassignedPropertyCountQuery",
    "operationKind": "query",
    "text": "query queryUnassignedPropertyCountQuery {\n  sys_properties_aggregate(where: {_not: {property_batches: {}}, properties_roles_users: {is_active: {_eq: true}, property_role_id: {_in: [2, 3, 4]}}, properties_datasets: {is_active: {_eq: true}}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c007e2d357eb4834a92a6bc3ee3a3098";

export default node;
