import {
  useCherreValue,
  useMutation,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import React, { useState } from 'react';
import {
  $providerId,
  $providerInfo,
  $providerTypes,
  $existingProvider,
} from './recoil';
import {
  StyledPageContainer,
  StyledPageContent,
  StyledTextField,
} from './styles';
import {
  PageHeader,
  TextField,
  FormEntry,
  RadioGroup,
  Radio,
  Grid,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tooltip,
} from '@cherre-frontend/ui';
import { graphql } from 'react-relay';
import { addEditProviderMutation } from './__generated__/addEditProviderMutation.graphql';
import { useHistory } from 'react-router';
import * as U from './utils';
import { format } from 'date-fns';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { isEqual } from 'lodash';

export const SubmissionAccessModes = [
  {
    id: 'production',
    label: 'Production',
    description:
      'Live mode with all the Data Submission Portal features fully operational and impacting real data, users, and workflows.',
  },
  {
    id: 'submission_test',
    label: 'Submission Test',
    description:
      'In this mode, you can test creating submission batches, prepare submissions, review submissions, and track the end-to-end test workflow without affecting production data or workflows.',
  },
];

const AddEditProvider: React.FC = () => {
  const { push } = useHistory();

  const providerId = useCherreValue($providerId);
  const mode = U.selectMode(providerId);

  const providerInfo = useCherreValue($providerInfo);
  const providerTypes = useCherreValue($providerTypes());

  const oldName = providerInfo?.provider_name ?? '';
  const oldType = providerInfo?.provider_type_id ?? null;
  const oldSettings =
    providerInfo?.ingestion_config ?? ({} as Record<string, any>);

  const [newName, setProviderName] = React.useState(oldName);
  const [newType, setProviderType] = React.useState(oldType);
  const [newSettings, setProviderSettings] = React.useState(oldSettings);

  const nameChanged = oldName !== newName;
  const typeChanged = oldType !== newType;
  const settingsChanged = !isEqual(oldSettings, newSettings);

  const existingProvider = Boolean(useCherreValue($existingProvider(newName)));

  const validName = Boolean(newName) && (!nameChanged || !existingProvider);

  const defaultFormats = {
    date:
      (providerInfo?.ingestion_config?.formats?.date as unknown as string) ??
      'dd/MM/yyyy',
    datetime:
      (providerInfo?.ingestion_config?.formats
        ?.datetime as unknown as string) ?? 'HH:mm:ss dd/MM/yyyy',
    yearmonth:
      (providerInfo?.ingestion_config?.formats
        ?.yearmonth as unknown as string) ?? 'MM/yyyy',
  };

  const [ingestionFormats, setIngestionFormats] =
    React.useState(defaultFormats);

  const formatChanged = React.useMemo(
    () => JSON.stringify(ingestionFormats) !== JSON.stringify(defaultFormats),
    [ingestionFormats, defaultFormats]
  );

  const validFormats = React.useMemo(
    () =>
      Object.values(ingestionFormats).every((f) => {
        try {
          format(new Date(), f);
          return true;
        } catch (e) {
          return false;
        }
      }),
    [ingestionFormats]
  );

  const changed =
    nameChanged || typeChanged || formatChanged || settingsChanged;
  const canSubmit = changed && validName && validFormats;

  const ingestionFormatFF = useFeatureFlag('DSPIngestionFormatsEdit');
  const bulkApproveFF = useFeatureFlag('DSPBulkApproveSettings');
  const providerSubmissionAccessFF = useFeatureFlag('DSPSubmissionTestingMode');

  const [activeTab, setActiveTab] = useState(0);

  const hasBulkApproveSettings =
    providerInfo?.organization?.settings?.bulk_approve_submit_properties;

  const hasProviderSubmissionTestingMode =
    providerInfo?.organization?.settings?.provider_submission_testing_mode;

  const onChangeProviderSubmissionTestingMode =
    (mode: string) =>
    (_event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setProviderSettings((prev) => {
        const filteredList = (
          prev.provider_submission_testing_mode ?? []
        ).filter((m: string) => m !== mode);

        return {
          ...prev,
          provider_submission_testing_mode: checked
            ? [...filteredList, mode]
            : filteredList,
        };
      });
    };

  const cancel = () => {
    push('/dsp/providers');
  };

  const submit = useMutation(
    graphql`
      mutation addEditProviderMutation($params: add_or_edit_provider_params!) {
        _sys_add_or_edit_provider(params: $params) {
          provider_id
          provider_name
          provider_type_id
        }
      }
    ` as GraphQLReturn<addEditProviderMutation>,
    {
      mapVariables: () => async () => {
        if (!canSubmit) {
          throw new Error('user cannot submit request');
        }
        if (!newType) {
          throw new Error('provider type not set');
        }
        if (!newName) {
          throw new Error('provider name not set');
        }
        return {
          params: {
            provider_id: providerId,
            provider_name: newName,
            provider_type_id: newType,
            ingestion_config: ingestionFormatFF
              ? { ...newSettings, formats: ingestionFormats }
              : newSettings,
          },
        };
      },
      onCompleted: (_, ctx) => {
        ctx.showSnackbar({ type: 'success', message: U.successMessage(mode) });
        push('/dsp/providers');
      },
    }
  );
  return (
    <StyledPageContainer id='AddEditProvider'>
      <PageHeader title={U.title(mode, providerInfo)} backLink />
      <StyledPageContent>
        <Grid container rowSpacing='20px'>
          <Grid item xs={12}>
            <Tabs
              muiTabsProps={{
                sx: {
                  '& .MuiTab-root': { padding: '5px 10px', minHeight: '32px' },
                  minHeight: '32px',
                },
              }}
              tabs={[
                { label: 'General', value: 0 },
                {
                  label: 'Data Settings',
                  value: 1,
                  disabled: !ingestionFormatFF,
                },
              ]}
              showBorder={false}
              tabPanels={[]}
              value={activeTab}
              onChange={(_e, value) => {
                setActiveTab(value);
              }}
            />
          </Grid>
          {activeTab === 0 && (
            <>
              <Grid item xs={12}>
                <FormEntry title='Provider Name' required>
                  <StyledTextField
                    placeholder='Enter provider name'
                    size='small'
                    value={newName}
                    onChange={(e) => setProviderName(e.target.value)}
                    state={U.inputState(validName, existingProvider)}
                    helperText={U.helperText(Boolean(existingProvider))}
                    InputProps={U.inputProps(validName, existingProvider)}
                  />
                </FormEntry>
              </Grid>
              <Grid item xs={12}>
                <FormEntry title='Company Type'>
                  <RadioGroup
                    style={{ gap: '5px' }}
                    value={newType}
                    onChange={(_, v) => setProviderType(Number(v))}
                  >
                    {providerTypes?.map((type) => (
                      <Radio
                        key={type.type_id}
                        label={type.name}
                        value={type.type_id}
                      />
                    ))}
                  </RadioGroup>
                </FormEntry>
              </Grid>
              {bulkApproveFF && (
                <Grid item xs={12}>
                  <FormEntry title='Submission Settings'>
                    <Grid
                      container
                      item
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                      xs={12}
                    >
                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            alignItems: 'flex-start',
                          }}
                          disabled={!hasBulkApproveSettings}
                          checked={!!newSettings.bulk_approve_submit_properties}
                          onChange={(_, checked) =>
                            setProviderSettings((prev) => ({
                              ...prev,
                              bulk_approve_submit_properties: checked,
                            }))
                          }
                          control={
                            hasBulkApproveSettings ? (
                              <Checkbox sx={{ marginTop: '-11px' }} />
                            ) : (
                              <Tooltip
                                title='You don’t have this setting enabled for your organization. Please reach out to Cherre to enable it.'
                                placement='bottom'
                              >
                                <span>
                                  <Checkbox
                                    sx={{ marginTop: '-11px' }}
                                    disabled
                                  />
                                </span>
                              </Tooltip>
                            )
                          }
                          label={
                            <>
                              <b>Bulk Approve/Submit Properties</b> - Ability to
                              multi select property submissions as a reviewer
                              and bulk approve or submit them at once. Reviewers
                              are also not required to review property
                              submission package reports individually prior to
                              approving or submitting.
                            </>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </FormEntry>
                </Grid>
              )}
              {providerSubmissionAccessFF && (
                <Grid item xs={12}>
                  <FormEntry title='Provider Submission Access:'>
                    <Grid
                      container
                      item
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                      xs={12}
                    >
                      <FormGroup sx={{ gap: '6px' }}>
                        {SubmissionAccessModes.map((accessMode) => (
                          <FormControlLabel
                            sx={{
                              alignItems: 'flex-start',
                            }}
                            disabled={!hasProviderSubmissionTestingMode}
                            checked={(
                              newSettings.provider_submission_testing_mode ?? []
                            ).includes(accessMode.id)}
                            onChange={onChangeProviderSubmissionTestingMode(
                              accessMode.id
                            )}
                            control={
                              hasProviderSubmissionTestingMode ? (
                                <Checkbox sx={{ marginTop: '-11px' }} />
                              ) : (
                                <Tooltip
                                  title='You don’t have this setting enabled for your organization. Please reach out to Cherre to enable it.'
                                  placement='bottom'
                                >
                                  <span>
                                    <Checkbox
                                      sx={{ marginTop: '-11px' }}
                                      disabled
                                    />
                                  </span>
                                </Tooltip>
                              )
                            }
                            label={
                              <>
                                <b>{accessMode.label}</b> -{' '}
                                {accessMode.description}
                              </>
                            }
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  </FormEntry>
                </Grid>
              )}
            </>
          )}
          {activeTab === 1 && ingestionFormatFF && (
            <Grid item xs={12}>
              <FormEntry title='Ingestion Formats'>
                <Grid
                  container
                  item
                  sx={{
                    display: 'grid',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '10px',
                  }}
                  xs={12}
                >
                  {[
                    ['date', 'Date Format'],
                    ['datetime', 'Date Time Format'],
                    ['yearmonth', 'Year Month Format'],
                  ].map(([type, label]) => {
                    let formattedValue = '';

                    try {
                      formattedValue = format(
                        new Date(),
                        ingestionFormats[type]
                      );
                    } catch (e) {
                      formattedValue = 'Invalid format';
                    }

                    return (
                      <Grid key={type} item xs={12}>
                        <TextField
                          label={label}
                          variant='outlined'
                          size='small'
                          fullWidth
                          value={ingestionFormats[type]}
                          onChange={(e) =>
                            setIngestionFormats({
                              ...ingestionFormats,
                              [type]: e.target.value,
                            })
                          }
                          error={formattedValue === 'Invalid format'}
                          helperText={`Format to: ${formattedValue}`}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormEntry>
            </Grid>
          )}
        </Grid>
      </StyledPageContent>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: '20px',
          gap: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant='text'
          onClick={cancel}
          testID='cancel'
          sx={{
            width: '80px',
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          variant='contained'
          color='primary'
          onClick={submit}
          testID='submit'
          sx={{
            minWidth: '80px',
          }}
        >
          {U.buttonText(mode)}
        </Button>
      </Grid>
    </StyledPageContainer>
  );
};

export default AddEditProvider;
