import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyDatasetQuery } from './__generated__/getPropertyDatasetQuery.graphql';

export const $getPropertyDataset = graphQLSelector({
  query: graphql`
    query getPropertyDatasetQuery($dataset_id: Int!, $property_id: Int!) {
      sys_properties_datasets(
        where: {
          dataset_id: { _eq: $dataset_id }
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
        }
        limit: 1
      ) {
        template_id
      }
    }
  ` as GraphQLReturn<getPropertyDatasetQuery>,
  mapVariables:
    ({
      property_id,
      dataset_id,
    }: {
      property_id: number;
      dataset_id: number;
    }) =>
    () => ({
      dataset_id,
      property_id,
    }),
  mapResponse: (resp) => resp.sys_properties_datasets[0],
});
