import React, { useMemo } from 'react';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import moment from 'moment';
import {
  getFundsFilter,
  getProvidersFilter,
  getReportingPeriodsFilter,
  getSubmissionTypesFilter,
} from '../../../Panels/SubmissionsPanel/query/getSubmissions';
import { StatusCode } from './BatchStatus';
import FilterItem from './FilterItem';
import { Grid } from '@cherre-frontend/ui';

export default function Filters() {
  const providers = useCherreValue(getProvidersFilter({})) ?? [];
  const funds = useCherreValue(getFundsFilter({})) ?? [];
  const submissionTypes = useCherreValue(getSubmissionTypesFilter({})) ?? [];
  const periods = useCherreValue(getReportingPeriodsFilter({})) ?? [];
  const status: StatusCode[] = [
    'Approved',
    'Closed',
    'Not Started',
    'Open',
    'Past Due',
  ];

  const arrayToKeyValue = (array: string[]) =>
    array.reduce((acc, value) => {
      acc[value] = value;
      return acc;
    }, {} as Record<string, string>);

  const filtersData = useMemo(() => {
    return {
      Provider: arrayToKeyValue(providers),
      Fund: arrayToKeyValue(funds.filter((fund) => fund !== null) as string[]),
      Status: arrayToKeyValue(status),
      Interval: {
        Monthly: 'monthly',
        Quarterly: 'quarterly',
      },
      Period: arrayToKeyValue(periods.map((p) => moment(p).format('MMM YYYY'))),
      Type: arrayToKeyValue(submissionTypes),
    };
  }, [providers, funds, submissionTypes, periods, status]);

  const filters = Object.keys(filtersData);

  return (
    <Grid display='flex' gap='10px'>
      {filters.map((filterType) => (
        <FilterItem
          key={filterType}
          filterType={filterType}
          filterData={filtersData[filterType]}
        />
      ))}
    </Grid>
  );
}

Filters.displayName = 'Filters';
