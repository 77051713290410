/**
 * @generated SignedSource<<0435d32a07fca2783705f9fa61169b37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useJsonLogicGetReportConfigQuery$variables = {
  provider_id?: number | null;
};
export type useJsonLogicGetReportConfigQuery$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly report_config: any | null;
  }>;
};
export type useJsonLogicGetReportConfigQuery = {
  response: useJsonLogicGetReportConfigQuery$data;
  variables: useJsonLogicGetReportConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "provider_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "report_config",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useJsonLogicGetReportConfigQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useJsonLogicGetReportConfigQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce887f22a41639f3784af9bed518881f",
    "id": null,
    "metadata": {},
    "name": "useJsonLogicGetReportConfigQuery",
    "operationKind": "query",
    "text": "query useJsonLogicGetReportConfigQuery(\n  $provider_id: Int\n) {\n  sys_providers(where: {provider_id: {_eq: $provider_id}}) {\n    report_config\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c9f1c951c9603e866d5124b715b64bd";

export default node;
