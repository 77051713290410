/**
 * @generated SignedSource<<195a71199a873ec34b547789dfa8cdb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FiltersGetFundTypesQuery$variables = {};
export type FiltersGetFundTypesQuery$data = {
  readonly sys_properties: ReadonlyArray<{
    readonly fund: string | null;
  }>;
};
export type FiltersGetFundTypesQuery = {
  response: FiltersGetFundTypesQuery$data;
  variables: FiltersGetFundTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": [
          "fund"
        ]
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": [
          {
            "fund": "asc"
          }
        ]
      }
    ],
    "concreteType": "sys_properties",
    "kind": "LinkedField",
    "name": "sys_properties",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fund",
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties(distinct_on:[\"fund\"],order_by:[{\"fund\":\"asc\"}])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FiltersGetFundTypesQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FiltersGetFundTypesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9e1d14b21082c7ef93ff1762cbc7ad11",
    "id": null,
    "metadata": {},
    "name": "FiltersGetFundTypesQuery",
    "operationKind": "query",
    "text": "query FiltersGetFundTypesQuery {\n  sys_properties(distinct_on: [fund], order_by: [{fund: asc}]) {\n    fund\n  }\n}\n"
  }
};
})();

(node as any).hash = "44f3e0d033b13f14963b58c8ab75b572";

export default node;
