import { useCherreValue } from '@cherre-frontend/data-fetching';
import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';
import { selectedPreparerUsers, userCheckerType } from '../../../recoil/dialog';
import { useWarningBox } from './useWarningBox';
import { getReviewerUserList } from '../queries/getReviewerUserList';
import { useMemo } from 'react';
import { useCurrentPreparersAndReviewersArrays } from './useCurrentPreparersAndReviewersArray';
import { useGetProviderId } from './useGetProviderId';

export type SelectedUsersType = {
  reviewer1: userCheckerType[];
  reviewer2: userCheckerType[];
  reviewer3: userCheckerType[];
};

const approverPlusRoleIds: (number | undefined | null)[] = [
  PropertyRole.APPROVER_PLUS_1.id,
  PropertyRole.APPROVER_PLUS_2.id,
  PropertyRole.APPROVER_PLUS_3.id,
];

export const useUserRoleSelect = (
  selectedUsers: SelectedUsersType,
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUsersType>>,
  isOpen = false,
  reviewerSelectionType?: string
) => {
  const {
    clearWarningBoxState,
    triggerReviewerCannotBeAssigned,
    triggerReviewerCannotBeAssignedSameEntity,
    triggerMissingReviewerPreviousLevel,
  } = useWarningBox();

  const selectedPreparers = useCherreValue(selectedPreparerUsers);

  const provider_id = useGetProviderId();

  const userOptions = useCherreValue(getReviewerUserList(provider_id));

  const filteredUserOptions: userCheckerType[] = useMemo(() => {
    const allUserIds = Object.keys(selectedUsers).reduce((acc, key) => {
      acc.push(...selectedUsers[key]);
      return acc;
    }, [] as userCheckerType[]);

    const selectedUserIds = allUserIds.map((user) => user.user_id);

    return (
      userOptions?.filter((user) => !selectedUserIds.includes(user.user_id)) ??
      []
    );
  }, [selectedUsers, userOptions]);

  const currentPreparersAndReviewersArrays =
    useCurrentPreparersAndReviewersArrays(isOpen, reviewerSelectionType);

  const showErrorRole = (
    value: userCheckerType,
    set_as: 'Preparer' | 'Reviewer'
  ) => {
    const entities = currentPreparersAndReviewersArrays[
      set_as === 'Reviewer' ? 'reviewers' : 'preparers'
    ].find((user) => value.user_id === user.user_id)?.entity_ids;

    if (entities) {
      triggerReviewerCannotBeAssigned(
        `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
        set_as,
        entities
      );
    } else {
      triggerReviewerCannotBeAssignedSameEntity(
        `${value.sakura_user?.first_name} ${value.sakura_user?.last_name}`,
        set_as
      );
    }
  };

  const setUserRole = (
    value: userCheckerType,
    stage: keyof SelectedUsersType
  ) => {
    setSelectedUsers((s) => {
      return {
        ...s,
        [stage]: [...s[stage], value].sort((a, b) => {
          const fullNameA = `${a.sakura_user?.first_name} ${a.sakura_user?.last_name}`;
          const fullNameB = `${b.sakura_user?.first_name} ${b.sakura_user?.last_name}`;
          return fullNameA.localeCompare(fullNameB);
        }),
      };
    });
    clearWarningBoxState();
  };

  const removeUserRole = (
    user: userCheckerType,
    stage: keyof SelectedUsersType
  ) => {
    setSelectedUsers((s) => {
      return {
        ...s,
        [stage]: s[stage].filter((u) => u.user_id !== user.user_id),
      };
    });
    clearWarningBoxState();
  };

  const updateUserRoleType = (
    user_id: number,
    property_role_id: number,
    stage: keyof SelectedUsersType
  ) => {
    const isApproverPlus = approverPlusRoleIds.includes(property_role_id);
    clearWarningBoxState();
    setSelectedUsers((s) => {
      const stagesKeys = Object.keys(s) as (keyof SelectedUsersType)[];
      const stageLevelIndex = stagesKeys.indexOf(stage);
      const nextStages = stagesKeys.slice(stageLevelIndex + 1);
      const emptyNextStages = nextStages.reduce(
        (acc, nextStage) => ({ ...acc, [nextStage]: [] }),
        {} as typeof s
      );

      const newValue = s[stage].map((u) => {
        if (u.user_id !== user_id) {
          return u;
        }
        return {
          ...u,
          property_role_id,
        };
      });

      return {
        ...s,
        [stage]: newValue,
        ...(isApproverPlus ? emptyNextStages : {}),
      };
    });
  };

  const isValidChange = (
    value: userCheckerType | null,
    stage: keyof SelectedUsersType
  ) => {
    clearWarningBoxState();

    if (!value) {
      return false;
    }

    if (stage !== 'reviewer1') {
      const previousStage =
        stage === 'reviewer2'
          ? selectedUsers?.reviewer1
          : selectedUsers?.reviewer2;

      if (!previousStage || previousStage.length === 0) {
        triggerMissingReviewerPreviousLevel();
        return false;
      }
    }

    const includesPreparerUser =
      currentPreparersAndReviewersArrays.preparers.some(
        (reviewer) => reviewer.user_id === value.user_id
      );

    const currentlySelectedPreparer = selectedPreparers
      ?.map((user) => user.user_id)
      .includes(value.user_id);

    if (includesPreparerUser || currentlySelectedPreparer) {
      showErrorRole(value, 'Preparer');
      return false;
    }

    const includesReviewerUser =
      currentPreparersAndReviewersArrays.reviewers.some(
        (reviewer) => reviewer.user_id === value.user_id
      );

    if (includesReviewerUser) {
      showErrorRole(value, 'Reviewer');
      return false;
    }

    return true;
  };

  const approverPlusLevel = Object.values(selectedUsers).reduce(
    (level: undefined | number, users, index) => {
      const approverPlusUser = users.some((user) =>
        approverPlusRoleIds.includes(user.property_role_id)
      );
      return approverPlusUser ? index + 1 : level;
    },
    undefined
  );

  const stages: (keyof SelectedUsersType)[] = [
    'reviewer1',
    'reviewer2',
    'reviewer3',
  ];
  const filteredStages = stages.slice(0, approverPlusLevel);
  const showApproverPlusMessage = filteredStages.length !== stages.length;

  const userRoleSelectPropsReviewers = filteredStages.map((stage, index) => ({
    options: filteredUserOptions,
    selectedUsers: selectedUsers[stage],
    onChange: (_, value) => {
      if (isValidChange(value, stage)) {
        value && setUserRole(value, stage);
      }
    },
    level: index + 1,
    approverPlusLevel,
    title: `Reviewer Level ${index + 1}`,
    onClickRemoveUser: (user: userCheckerType) => removeUserRole(user, stage),
    onUpdateUserRoleType: (user_id: number, property_role_id: number) =>
      updateUserRoleType(user_id, property_role_id, stage),
  }));

  return { userRoleSelectPropsReviewers, showApproverPlusMessage };
};
