import { createEnum } from './utils';

const PANEL_NAMESPACE =
  'data-submission-portal/batch-management/create-batch-dialog';

export const namespace = (key: string) => `${PANEL_NAMESPACE}/${key}`;

export const submissionIntervalOptions = ['Monthly', 'Quarterly'] as const;
export type SubmissionInterval = (typeof submissionIntervalOptions)[number];
export const submissionIntervals = createEnum(submissionIntervalOptions);

export const stageOptions = ['DefineBatchDetails', 'SelectProperties'] as const;
export type Stage = (typeof stageOptions)[number];
export const stages = createEnum(stageOptions);
