/**
 * @generated SignedSource<<034546e68eaf7ea476f6eae09c609178>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SysUploadMasterFileInput = {
  chart_of_accounts_name: string;
  file_reference_source: string;
  file_source: string;
};
export type useUploadMasterFileNewMutation$variables = {
  input: SysUploadMasterFileInput;
};
export type useUploadMasterFileNewMutation$data = {
  readonly _sys_upload_master_file: {
    readonly master_chart_of_account_id: number;
  } | null;
};
export type useUploadMasterFileNewMutation = {
  response: useUploadMasterFileNewMutation$data;
  variables: useUploadMasterFileNewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SysUploadMasterFileOutput",
    "kind": "LinkedField",
    "name": "_sys_upload_master_file",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "master_chart_of_account_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadMasterFileNewMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadMasterFileNewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2e0cf6ff61e740d8f98ebbcda94edbf0",
    "id": null,
    "metadata": {},
    "name": "useUploadMasterFileNewMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadMasterFileNewMutation(\n  $input: SysUploadMasterFileInput!\n) {\n  _sys_upload_master_file(input: $input) {\n    master_chart_of_account_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7451a95cc86059fee309423c2a740495";

export default node;
