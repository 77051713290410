import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { UploadMappingSetNewRoute } from 'src/products/data-submission-portal/routes';
import { getMappingFieldNewQuery } from './__generated__/getMappingFieldNewQuery.graphql';

export const getMappingFieldNew = graphQLSelector({
  query: graphql`
    query getMappingFieldNewQuery($id: Int) {
      sys_mapping_fields(where: { mapping_field_id: { _eq: $id } }) {
        mapping_field_id
        mapping_field_name
        mapping_field_description
      }
    }
  ` as GraphQLReturn<getMappingFieldNewQuery>,
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(UploadMappingSetNewRoute.routeParamSelector).mapping_field_id,
    }),
  mapResponse: (resp) => resp.sys_mapping_fields[0],
});
