import {
  useCherreEventWithRecoil,
  useCherreResetState,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Dialog,
  DialogProps,
  MRT_RowSelectionState,
  Panel,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import SelectDatasets from './components/Stages/SelectDatasets';
import {
  Stage,
  assignDatasetsToPropertyIdState,
  datasetTemplateIdState,
  dialogStage,
  enabledBatchValidationsState,
  enabledValidationsState,
  selectedDatasetIdsState,
} from './localState';
import SelectValidations from './components/Stages/SelectValidations';
import SelectDatasetTemplate from './components/Stages/SelectDatasetTemplate';
import SelectCrossFileValidations from './components/Stages/SelectCrossFileValidations';
import { assignDatasetsDialogOpenState } from '../../recoil/dialog';

const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    width: 720px;
    max-width: 720px;
  }
`;

type AssignDatasetsDialogProps = {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
};

const AssignDatasetsDialog: React.FC<AssignDatasetsDialogProps> = ({
  rowSelection,
  setRowSelection,
}) => {
  const isModalOpen = useCherreValue(assignDatasetsDialogOpenState);
  const [stage, setStage] = useCherreState(dialogStage);
  const setSelectedDatasetIds = useCherreSetState(selectedDatasetIdsState);
  const resetDatasetTemplaetIdState = useCherreResetState(
    datasetTemplateIdState
  );
  const setEnabledValidations = useCherreSetState(enabledValidationsState);
  const setEnabledBatchValidationsState = useCherreSetState(
    enabledBatchValidationsState
  );
  const setAssignDatasetsToPropertyIdState = useCherreSetState(
    assignDatasetsToPropertyIdState
  );

  const numRowsSelected = useMemo(
    () => Object.keys(rowSelection).length,
    [rowSelection]
  );

  const onClose = useCherreEventWithRecoil(
    'user closed assign datasets dialog',
    (ctx) => () => {
      setStage(Stage.SelectDatasets);
      setSelectedDatasetIds([]);
      setEnabledValidations({
        standard: {},
        tsa: {},
      });
      resetDatasetTemplaetIdState();
      setEnabledBatchValidationsState({});
      setAssignDatasetsToPropertyIdState(null);
      ctx.recoil.set(assignDatasetsDialogOpenState, false);
    }
  );

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Panel id='AssignDatasetsDialog' config={{ logLevel: false }}>
        {stage === Stage.SelectDatasets ? (
          <SelectDatasets numRowsSelected={numRowsSelected} onClose={onClose} />
        ) : null}
        {stage === Stage.SelectDatasetTemplate ? (
          <SelectDatasetTemplate
            numRowsSelected={numRowsSelected}
            onClose={onClose}
          />
        ) : null}
        {stage === Stage.SelectValidations ? (
          <SelectValidations
            numRowsSelected={numRowsSelected}
            onClose={onClose}
          />
        ) : null}
        {stage === Stage.SelectCrossFileValidations ? (
          <SelectCrossFileValidations
            numRowsSelected={numRowsSelected}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            onClose={onClose}
          />
        ) : null}
      </Panel>
    </Container>
  );
};

export default AssignDatasetsDialog;
