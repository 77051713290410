/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherreValueLoadable,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  Panel,
  Typography,
  MuiAutocomplete as Autocomplete,
  FormEntry,
  TextField,
  IconButton,
  CloseIcon,
  styled,
  MenuItem,
} from '@cherre-frontend/ui';
import React, { useMemo, useState } from 'react';
import { constSelector } from 'recoil';
import {
  addEditPropertyDialogState,
  addEditPropertyFormChecker,
  addEditPropertyFormHasChanges,
  propertyExistsSelector,
  sourceErrorSelector,
  multiProviderSourceErrorSelector,
  useEditPropertyForm,
  useResetPropertyForm,
} from '../recoil';
import {
  HeaderGrid,
  Container,
  DialogTitle,
} from '../styles/AddEditPropertyDialogStyles';

import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { getOrganizationPropertyModel } from 'src/products/data-submission-portal/recoil/getOrganizationPropertyModel';
import { getProvidersAutocomplete } from '../queries/getProviders';
import { useUpsertProperty } from '../hooks/useUpsertProperty';
import { getPropertyTypeCustomAttributes } from '../queries/getPropertyTypeCustomAttributes';

const FieldErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const AddEditPropertyDialogModal: React.FC = () => {
  const dialogState = useCherreValue(addEditPropertyDialogState)!;
  const isEdit = dialogState.type.toLowerCase() === 'edit';
  const [form, setForm] = useEditPropertyForm();
  const resetPropertyForm = useResetPropertyForm();
  const [isPropertyCodeFieldTouched, setIsPropertyCodeFieldTouched] =
    useState(false);

  const providers = useCherreValue(getProvidersAutocomplete({}));

  const validateForm = useMemo(() => {
    return addEditPropertyFormChecker(form);
  }, [form]);

  //check if values changed from original
  const hasChanges = useCherreValue(addEditPropertyFormHasChanges);

  // dialog callbacks
  const upsertProperty = useUpsertProperty((value, ctx) => {
    ctx.showSnackbar({
      type: 'success',
      message: `Property successfully ${isEdit ? 'edited' : 'added'}`,
    });
  });

  const onClose = useCherreEventWithRecoil(
    'user closed add property dialog',
    (ctx) => () => {
      ctx.recoil.set(addEditPropertyDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
      resetPropertyForm();
      setIsPropertyCodeFieldTouched(false);
    }
  );

  const propertyTypeCustomAttributes = useCherreValue(
    getPropertyTypeCustomAttributes()
  );

  const organizationPropertyModelLoadable = useCherreValueLoadable(
    getOrganizationPropertyModel()
  );
  const organizationPropertyModel =
    organizationPropertyModelLoadable.state === 'hasValue'
      ? organizationPropertyModelLoadable.contents
      : undefined;

  // control duplicate property_code
  const propertyExists_ = Boolean(useCherreValue(propertyExistsSelector({})));
  const propertyExists = useMemo(() => {
    return (
      !isEdit &&
      propertyExists_ &&
      organizationPropertyModel &&
      !organizationPropertyModel.isManyToOne
    );
  }, [propertyExists_, isEdit]);

  // Control duplicate entity_id
  const multiProviderSubmissionsEnabled = useFeatureFlag(
    'DSPMultiProviderPropertySubmissions'
  );
  const sourceIdValidationSelector = multiProviderSubmissionsEnabled
    ? multiProviderSourceErrorSelector
    : sourceErrorSelector;
  const sourceErrorLoadable = useCherreValueLoadable(
    isPropertyCodeFieldTouched
      ? sourceIdValidationSelector
      : constSelector(null)
  );
  const sourceError =
    sourceErrorLoadable.state === 'hasValue'
      ? sourceErrorLoadable.contents
      : undefined;

  const onSubmit = useCherreEventWithRecoil(
    'user continue add mapping set dialog',
    (ctx) => () => {
      upsertProperty();
      resetPropertyForm();
      setIsPropertyCodeFieldTouched(false);
      ctx.recoil.set(addEditPropertyDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
    }
  );

  return (
    <Container open={Boolean(dialogState.isOpen)} onClose={onClose}>
      <Panel id='AddEditPropertyDialog'>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs data-testid='dialog-title-property'>
            <DialogTitle variant='h4'>
              {isEdit ? 'Edit Property' : 'Add Property'}
            </DialogTitle>
          </Grid>
          <Grid item xs='auto'>
            <IconButton onClick={onClose} size='small'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </HeaderGrid>
        <Typography variant='body2' sx={{ paddingBottom: '16px' }}>
          Fields marked with asterisks (*) are mandatory.
        </Typography>
        <Grid
          container
          direction='row'
          spacing={2}
          style={{ marginBottom: '20px' }}
        >
          <Grid item xs={6}>
            <FormEntry title='Source Entity ID'>
              <TextField
                id='entity_id'
                data-testid='entity_id'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Source Entity ID'
                value={form?.entity_id}
                onChange={(e) => {
                  setForm({
                    entity_id: e.target.value,
                  });
                }}
              />
              {sourceError?.field === 'entity_id' && (
                <FieldErrorMessage variant='body2'>
                  {sourceError.message}
                </FieldErrorMessage>
              )}
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry required title='Target Entity ID'>
              <TextField
                id='property_code'
                data-testid='property_code'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Target Entity ID'
                value={form?.property_code}
                disabled={isEdit}
                onBlur={() => setIsPropertyCodeFieldTouched(true)}
                sx={{ backgroundColor: isEdit ? '#f5f5f5' : undefined }}
                onChange={(e) => {
                  setForm({
                    property_code: e.target.value,
                  });
                }}
              />
              {propertyExists && (
                <FieldErrorMessage variant='body2'>
                  Target Entity ID already exists in Properties table
                </FieldErrorMessage>
              )}
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Source Entity Name'>
              <TextField
                id='entity_name'
                data-testid='entity_name'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Source Entity Name'
                value={form?.entity_name}
                onChange={(e) => {
                  setForm({
                    entity_name: e.target.value,
                  });
                }}
              />
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Target Entity Name'>
              <TextField
                id='property_name'
                data-testid='property_name'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Target Entity Name'
                value={form?.property_name}
                onChange={(e) => {
                  setForm({
                    property_name: e.target.value,
                  });
                }}
              />
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Address'>
              <TextField
                id='address'
                data-testid='address'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Address'
                value={form?.address}
                onChange={(e) => {
                  setForm({
                    address: e.target.value,
                  });
                }}
              />
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Provider'>
              <Autocomplete
                data-testid='provider'
                options={providers ?? []}
                getOptionLabel={(option) => option.provider_name}
                style={{ width: '100%' }}
                size='small'
                defaultValue={providers?.find(
                  (v) => v.provider_id === form?.provider_id
                )}
                onChange={(_, value) =>
                  setForm({
                    provider_id: value?.provider_id,
                  })
                }
                renderOption={(props, option) => {
                  return (
                    <MenuItem {...props} key={option.provider_id}>
                      {' '}
                      {option.provider_name}{' '}
                    </MenuItem>
                  );
                }}
                disabled={isEdit}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant='outlined'
                      size='small'
                      style={{
                        width: '100%',
                        backgroundColor: isEdit ? '#f5f5f5' : undefined,
                      }}
                      placeholder='Select'
                      role='textbox'
                    />
                  );
                }}
              />
              {sourceError?.field === 'provider_id' && (
                <FieldErrorMessage variant='body2'>
                  {sourceError.message}
                </FieldErrorMessage>
              )}
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Fund'>
              <TextField
                id='fund'
                data-testid='fund'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Fund'
                value={form?.fund}
                onChange={(e) => {
                  setForm({
                    fund: e.target.value,
                  });
                }}
              />
            </FormEntry>
          </Grid>
          <Grid item xs={6}>
            <FormEntry title='Property Type'>
              <TextField
                id='type'
                data-testid='type'
                role='textbox'
                variant='outlined'
                size='small'
                required
                placeholder='Property Type'
                value={form?.type}
                onChange={(e) => {
                  setForm({
                    type: e.target.value,
                  });
                }}
              />
            </FormEntry>
          </Grid>
          {propertyTypeCustomAttributes?.map((attr) => (
            <Grid item xs={6} key={attr.slug}>
              <FormEntry title={attr.label}>
                <TextField
                  id={attr.slug}
                  data-testid={attr.slug}
                  role='textbox'
                  variant='outlined'
                  size='small'
                  required
                  placeholder={attr.label}
                  value={form?.custom_attributes[attr.slug]}
                  onChange={(e) => {
                    setForm({
                      custom_attributes: {
                        ...(form?.custom_attributes ?? {}),
                        [attr.slug]: e.target.value,
                      },
                    });
                  }}
                />
              </FormEntry>
            </Grid>
          ))}
        </Grid>
        <Grid container direction='row' justifyContent='flex-end' spacing={1}>
          <Grid item>
            <Button
              variant='outlined'
              style={{
                padding: '6px 8px',
                width: '100px',
              }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              style={{
                padding: '6px 8px',
                width: '100px',
              }}
              disabled={
                validateForm.type === 'failure' ||
                !hasChanges ||
                !!sourceError ||
                !!propertyExists ||
                sourceErrorLoadable.state === 'loading'
              }
              onClick={() => onSubmit()}
              testID='confirm-property-button'
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};

export default AddEditPropertyDialogModal;
