/**
 * @generated SignedSource<<eca09e33dac130dccf0949e09616e9a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddEditMappingDialogAddMutation$variables = {
  custom_attributes?: any | null;
  mapping_set_id: number;
  organization_id: number;
  source_code: string;
  source_description?: string | null;
  target_code?: string | null;
  target_description?: string | null;
};
export type AddEditMappingDialogAddMutation$data = {
  readonly insert_sys_mapping_set_values_one: {
    readonly mapping_set_value_id: number;
  } | null;
};
export type AddEditMappingDialogAddMutation = {
  response: AddEditMappingDialogAddMutation$data;
  variables: AddEditMappingDialogAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "custom_attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mapping_set_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source_code"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source_description"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "target_code"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "target_description"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "custom_attributes",
            "variableName": "custom_attributes"
          },
          {
            "kind": "Variable",
            "name": "mapping_set_id",
            "variableName": "mapping_set_id"
          },
          {
            "kind": "Variable",
            "name": "organization_id",
            "variableName": "organization_id"
          },
          {
            "kind": "Variable",
            "name": "source_mapping_code",
            "variableName": "source_code"
          },
          {
            "kind": "Variable",
            "name": "source_mapping_label",
            "variableName": "source_description"
          },
          {
            "kind": "Variable",
            "name": "target_mapping_code",
            "variableName": "target_code"
          },
          {
            "kind": "Variable",
            "name": "target_mapping_label",
            "variableName": "target_description"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      }
    ],
    "concreteType": "sys_mapping_set_values",
    "kind": "LinkedField",
    "name": "insert_sys_mapping_set_values_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_value_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddEditMappingDialogAddMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddEditMappingDialogAddMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "073d81e74cb2a47767a99b7700563627",
    "id": null,
    "metadata": {},
    "name": "AddEditMappingDialogAddMutation",
    "operationKind": "mutation",
    "text": "mutation AddEditMappingDialogAddMutation(\n  $mapping_set_id: Int!\n  $organization_id: Int!\n  $source_code: String!\n  $source_description: String\n  $target_code: String\n  $target_description: String\n  $custom_attributes: jsonb\n) {\n  insert_sys_mapping_set_values_one(object: {mapping_set_id: $mapping_set_id, organization_id: $organization_id, source_mapping_code: $source_code, source_mapping_label: $source_description, target_mapping_code: $target_code, target_mapping_label: $target_description, custom_attributes: $custom_attributes}) {\n    mapping_set_value_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5741a03f90700cac4070acdff38241cd";

export default node;
