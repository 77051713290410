/**
 * @generated SignedSource<<449aafbf3946e28075e250c8d4aba0bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActionsReassignUserMutation$variables = {
  organizationId: number;
  providerId?: number | null;
  userId: number;
};
export type ActionsReassignUserMutation$data = {
  readonly _sys_reassign_user: {
    readonly ok: boolean;
  };
};
export type ActionsReassignUserMutation = {
  response: ActionsReassignUserMutation$data;
  variables: ActionsReassignUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "organization_id",
            "variableName": "organizationId"
          },
          {
            "kind": "Variable",
            "name": "provider_id",
            "variableName": "providerId"
          },
          {
            "kind": "Variable",
            "name": "user_id",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "reassign_user_output",
    "kind": "LinkedField",
    "name": "_sys_reassign_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionsReassignUserMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActionsReassignUserMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "13748a2af976cfe5ccb9e0ba78d161cd",
    "id": null,
    "metadata": {},
    "name": "ActionsReassignUserMutation",
    "operationKind": "mutation",
    "text": "mutation ActionsReassignUserMutation(\n  $userId: Int!\n  $organizationId: Int!\n  $providerId: Int\n) {\n  _sys_reassign_user(params: {user_id: $userId, organization_id: $organizationId, provider_id: $providerId}) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "35cf932bf547cf694f13b513de8db220";

export default node;
