import {
  BellIcon,
  CompletedIcon,
  CompletedWhiteIcon,
  PackageReportCommentIcon,
  ReplaceIcon,
  Tooltip,
  Typography,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useMemo } from 'react';
import MultiColorProgressBar, { Reading } from '../MultiColorProgressBar';

import { grey } from '@material-ui/core/colors';
import { getSubmissionPeriod } from 'src/products/data-submission-portal/utils/getSubmissionPeriod';
import { SubmissionBatchItem } from '../../recoil';
import {
  BatchContainer,
  CommentContainer,
  ContentContainer,
  DatesContainer,
  DueDateSpan,
  ProgressContainer,
  StyledChip,
} from './styles';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export type BatchCardProps = {
  submissionBatch: SubmissionBatchItem;
};

const BatchCard: React.FC<BatchCardProps> = ({ submissionBatch }) => {
  const {
    batchNumbers,
    isBatchDue,
    propertiesLength,
    dueDate,
    period,
    readings,
  } = useMemo(() => {
    const propertiesLength =
      submissionBatch.property_batches_aggregate.aggregate?.count ?? 0;

    const batchNumbers = {
      submitted: submissionBatch.submitted.aggregate?.count ?? 0,
      inProgress: submissionBatch.in_progress.aggregate?.count ?? 0,
    };

    const dueDate = moment(submissionBatch.due_date);

    const period = getSubmissionPeriod(
      submissionBatch.reporting_period_start_date,
      submissionBatch.reporting_period_end_date
    );

    const notSubmittedProperties =
      submissionBatch.not_started.aggregate?.count ?? 0;

    const readings: Reading[] = [];

    if (propertiesLength !== 0) {
      readings.push({
        value: (batchNumbers.submitted * 100) / propertiesLength,
        color: '#43A047',
        tooltipLabel: `${batchNumbers.submitted} Submitted`,
      });
      readings.push({
        value: (batchNumbers.inProgress * 100) / propertiesLength,
        color: '#E88819',
        tooltipLabel: `${batchNumbers.inProgress} In Progress`,
      });

      if (notSubmittedProperties > 0) {
        readings.push({
          value: (notSubmittedProperties * 100) / propertiesLength,
          color: '#E4E4E4',
          tooltipLabel: `${notSubmittedProperties} Not Started`,
        });
      }
    } else {
      readings.push({
        value: 100,
        color: '#E4E4E4',
        tooltipLabel: 'No Properties started',
      });
    }

    const isBatchDue =
      !submissionBatch.is_closed &&
      moment(dueDate).isBefore(moment(new Date()));

    return {
      batchNumbers,
      propertiesLength,
      isBatchDue,
      readings,
      dueDate,
      period,
    };
  }, [submissionBatch]);

  const iconTooltipLabel = submissionBatch.is_closed
    ? 'Closed'
    : isBatchDue
    ? 'Past Due'
    : 'In Progress';
  const BatchIcon = isBatchDue ? BellIcon : ReplaceIcon;

  const submissionHasComments = submissionBatch.property_batches.some(
    (propertyBatch) => {
      const hasComments = propertyBatch.comments_count?.aggregate?.count
        ? propertyBatch.comments_count.aggregate?.count > 0
        : false;
      const hasReminders = propertyBatch.reminders_count?.aggregate?.count
        ? propertyBatch.reminders_count.aggregate?.count > 0
        : false;
      return hasComments || hasReminders;
    }
  );

  const remindersFF = useFeatureFlag('DSPReminders');

  return (
    <BatchContainer>
      <ContentContainer>
        <Tooltip title={iconTooltipLabel}>
          {submissionBatch.is_closed ? (
            <CompletedIcon fontSize='medium' />
          ) : (
            <BatchIcon fontSize='medium' />
          )}
        </Tooltip>

        <DatesContainer>
          <Typography variant='h6'>
            {submissionBatch.submission_name}
          </Typography>
          <Typography variant='body2' color={grey[600]}>
            {submissionBatch.submission_type.submission_type_label} •{' '}
            {propertiesLength} Propert{propertiesLength === 1 ? 'y' : 'ies'} •{' '}
            {period} Period •{' '}
            <DueDateSpan isDue={isBatchDue}>
              Due {dueDate.format('MMM Do')}
            </DueDateSpan>
          </Typography>
        </DatesContainer>
      </ContentContainer>
      <ProgressContainer>
        {!submissionBatch.is_closed ? (
          <Typography variant='body2' color={grey[600]}>
            {batchNumbers.submitted} Submitted • {batchNumbers.inProgress} In
            Progress
          </Typography>
        ) : (
          <></>
        )}

        {submissionBatch.is_closed ? (
          <StyledChip
            icon={<CompletedWhiteIcon fontSize='small' />}
            label='Approved'
          />
        ) : (
          <MultiColorProgressBar readings={readings} />
        )}
      </ProgressContainer>

      <CommentContainer>
        <Tooltip
          title={
            submissionHasComments
              ? `Comments ${remindersFF ? 'or reminders ' : ''}available`
              : `No comments ${remindersFF ? 'or reminders' : ''}`
          }
        >
          <PackageReportCommentIcon
            color={submissionHasComments ? 'primary' : 'disabled'}
          />
        </Tooltip>
      </CommentContainer>
    </BatchContainer>
  );
};

BatchCard.displayName = 'BatchCard';

export default BatchCard;

export const BatchCardSkeleton: React.FC = () => {
  return (
    <BatchContainer data-testid='batch-card-skeleton'>
      <ContentContainer>
        <span style={{ width: 24, height: 24 }} className='suspend' />
        <DatesContainer>
          <h6
            style={{ width: 200, height: 20, margin: 0 }}
            className='suspend'
          />
          <p
            style={{ width: 350, minHeight: 13, margin: 0, marginTop: 10 }}
            className='suspend'
          />
        </DatesContainer>
      </ContentContainer>
      <ProgressContainer>
        <p style={{ width: 295, height: 12, margin: 0 }} className='suspend' />
      </ProgressContainer>
    </BatchContainer>
  );
};

BatchCardSkeleton.displayName = 'BatchCardSkeleton';
