import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherreSetState,
  useMutation,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { Button, Grid, Panel, Typography } from '@cherre-frontend/ui';
import { deleteMappingDialogState } from '../recoil';
import { HeaderGrid, Container } from '../styles';
import { DeleteMappingDialogMutation } from './__generated__/DeleteMappingDialogMutation.graphql';

const DeleteMappingDialogModal: React.FC = () => {
  const dialogState = useCherreValue(deleteMappingDialogState);

  const setDeleteMappingDialogState = useCherreSetState(
    deleteMappingDialogState
  );

  const deleteMappingSetValueMutation = useMutation(
    graphql`
      mutation DeleteMappingDialogMutation($id: Int!) {
        delete_sys_mapping_set_values(
          where: { mapping_set_value_id: { _eq: $id } }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<DeleteMappingDialogMutation>,
    {
      trackEvent: false,
      mapVariables: (id: number) => async () => {
        return {
          id,
        };
      },
      onCompleted: (result, ctx) => {
        ctx.showSnackbar({
          type: 'success',
          message: 'Mapping Deleted',
        });
        ctx.recoil.set(deleteMappingDialogState, {
          mappingSetValueId: 0,
          isOpen: false,
        });
      },
      onError: (result, ctx) => {
        console.log('error result', result);
        console.log('error ctx', ctx);
      },
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed delete dialog',
    (ctx) => () => {
      ctx.recoil.set(deleteMappingDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
    }
  );

  const onDelete = () => {
    deleteMappingSetValueMutation(dialogState?.mappingSetValueId as number);
  };

  return (
    <Container open={Boolean(dialogState?.isOpen)} onClose={onClose}>
      <Panel id='DeleteMappingDialog' config={{ logLevel: false }}>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          style={{ marginBottom: '8px' }}
        >
          <Typography variant='h5' style={{ fontWeight: 600 }}>
            Delete Mapping
          </Typography>
        </HeaderGrid>
        <Typography variant='body1'>
          Are you sure you want to delete this mapping?
        </Typography>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          gap={2}
          style={{ marginTop: '16px' }}
        >
          <Grid item>
            <Button
              variant='text'
              style={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                setDeleteMappingDialogState((s) => ({ ...s, isOpen: false }));
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              style={{ width: '90px', padding: '6px 8px' }}
              onClick={onDelete}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};

export default DeleteMappingDialogModal;
