import {
  GraphQLReturn,
  graphQLSelector,
  useCherreEventWithRecoil,
} from '@cherre-frontend/data-fetching';
import { useDownloadTableQuery } from './__generated__/useDownloadTableQuery.graphql';
import { graphql } from 'react-relay';
import { useReportContext } from './ReportContext';
import XLSX from 'xlsx';
import { Column, getReportMetadata } from './recoil';
import { useJsonLogic } from '../../hooks/useJsonLogic';
import { capitalize } from 'lodash';
import { aggregateBy } from './aggregateBy';
import { isNumber } from './utils';
import { useReportViewTypeSelector } from './ReportViewTypeSelector';

const dataSelector = graphQLSelector({
  query: graphql`
    query useDownloadTableQuery($params: report_table_data_input!) {
      _sys_get_report_table_data(params: $params) {
        data
      }
    }
  ` as GraphQLReturn<useDownloadTableQuery>,
  mapResponse: (resp) => resp._sys_get_report_table_data.data,
});

export const useDownloadReportTable = () => {
  const { getArgs, getWhere, getParams } = useReportContext();
  const [reportViewType] = useReportViewTypeSelector();

  const jsonLogic = useJsonLogic();

  return useCherreEventWithRecoil(
    'download report',
    (ctx) => async (report_slug: string, fileName: string) => {
      const report_params = getParams?.({
        report_slug,
        report_view_type: reportViewType ?? undefined,
      });
      const metadata = await ctx.recoil.getPromise(
        getReportMetadata({ params: { report_slug, report_params } })
      );

      async function generateSheetJsonFromTable(
        tableMetadata: (typeof metadata.tables)[number]
      ) {
        const table_slug = tableMetadata.table_slug;

        const query_arguments = getArgs(report_slug, tableMetadata);
        const where = getWhere(report_slug, tableMetadata);
        const rawData = await ctx.recoil.getPromise(
          dataSelector({
            params: {
              query_arguments,
              where,
              report_slug,
              table_slug,
            },
          })
        );

        const withGroupings = tableMetadata.groupings
          ? aggregateBy(
              { query_arguments, where, report_params, tableData: rawData },
              tableMetadata.groupings.keys,
              tableMetadata.groupings.aggregations,
              [],
              jsonLogic
            )
          : rawData;

        const data = withGroupings.map((row) => {
          const jsonLogicData = { rowData: row, tableData: rawData };

          const reducer = (acc: any, column: Column) => {
            if (column.columns) {
              return column.columns.reduce(reducer, acc);
            }
            const { header, accessorKey, type } = column;
            let value = row[accessorKey!];
            if (column.format && !isNumber(type)) {
              value = jsonLogic(column.format, jsonLogicData);
            }
            return { ...acc, [header!.label]: value };
          };

          return (
            tableMetadata.columns
              // TODO: Add multi-columns support
              .filter((c) => !c.dynamicColumn)
              .reduce(reducer, {})
          );
        });

        const aggJsonLogicData = { tableData: rawData };

        const columnsWithAggregation = tableMetadata.columns.filter(
          (column) => column.aggregation
        );

        const aggregationValues = columnsWithAggregation.reduce(
          (acc, column) => {
            const { aggregation, accessorKey } = column;

            if (accessorKey && aggregation) {
              acc[accessorKey] = jsonLogic(aggregation.value, aggJsonLogicData);
            }

            return acc;
          },
          {}
        );

        const formattedAggregations = columnsWithAggregation.reduce(
          (acc, column) => {
            const { header, accessorKey, type } = column;

            const formatJsonLogicData = { rowData: aggregationValues };

            let formattedAggValue = aggregationValues[accessorKey!];
            if (column.format && !isNumber(type)) {
              formattedAggValue = jsonLogic(column.format, formatJsonLogicData);
            }

            return { ...acc, [header!.label]: formattedAggValue };
          },
          {}
        );

        const hasAggragations = Object.keys(formattedAggregations).length > 0;
        return hasAggragations ? [...data, formattedAggregations] : data;
      }

      type TableData = {
        name: string;
        data: Record<string, string>[];
        metadata: (typeof metadata.tables)[number];
      };

      const promises = metadata.tables.map((table, index) => {
        // Excel sheet names have a 31 character limit.
        // If the table slug is longer than that, we'll use the index as the sheet name.
        const worksheetName =
          table.table_slug?.length > 30
            ? `Sheet ${index + 1}`
            : table.table_slug.split('_').map(capitalize).join(' ');

        return generateSheetJsonFromTable(table).then(
          (data) =>
            ({
              name: worksheetName,
              data,
              metadata: table,
            } as TableData)
        );
      });

      const tables = await Promise.all(promises);

      const workbook = XLSX.utils.book_new();

      tables
        // Join tables with hide_header = true to the previous table
        .reduce((acc, table) => {
          if (!table.metadata.hide_header) {
            acc.push(table);
          } else {
            const prevTableData = acc[acc.length - 1].data;
            acc[acc.length - 1].data = [...prevTableData, ...table.data];
          }
          return acc;
        }, [] as Pick<TableData, 'name' | 'data'>[])
        // Add worksheet to workbook
        .forEach((table) => {
          const worksheet = XLSX.utils.json_to_sheet(table.data);
          XLSX.utils.book_append_sheet(workbook, worksheet, table.name);
        });

      XLSX.writeFile(workbook, fileName);
    }
  );
};
