import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { getMasterChartOfAccountsSubscription } from './__generated__/getMasterChartOfAccountsSubscription.graphql';
import { graphql } from 'react-relay';

export const getMasterChartOfAccounts = graphQLSelector({
  query: graphql`
    subscription getMasterChartOfAccountsSubscription {
      sys_organization_master_chart_of_accounts(
        order_by: { chart_of_accounts_name: asc }
        where: { is_active: { _eq: true } }
      ) {
        master_chart_of_account_id
        chart_of_accounts_name
      }
    }
  ` as GraphQLReturn<getMasterChartOfAccountsSubscription>,
  mapResponse: (response) => response.sys_organization_master_chart_of_accounts,
});
