/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useState } from 'react';
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  Typography,
  FormEntry,
  RadioGroup,
  Radio,
  Box,
  MRT_RowSelectionState,
  Dialog,
  styled,
} from '@cherre-frontend/ui';
import {
  selectedPreparerUsers,
  selectedReviewersUsers,
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  selectedPropertiesToAssignContainsManyToOneProperty,
} from '../../recoil/dialog';
import { DialogPanel, HeaderGrid } from './styles';
import SingleChainForm from './components/SingleChainForm';
import DifferentChainsForm from './components/DifferentChainsForm';
import { useAssignSingleChainReviewers } from '../../pages/provider-detail/Panels/PropertiesTab/mutations/useAssignSingleChainReviewer';
import {
  assignReviewersDialogOpenState,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';
import { useCheckApproverPresent } from './hooks/useCheckApproverPresent';

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    border-radius: 10px;
    box-shadow: none;
    min-width: 520px;
  }
`;

type Props = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
};

const AssignReviewersDialog: React.FC<Props> = ({ setRowSelection }) => {
  const assignReviewersMutation =
    useAssignSingleChainReviewers(setRowSelection);
  const [selectedUsers, setSelectedUsers] = useCherreState(
    selectedReviewersUsers
  );
  const [selectedOperationalUsers, setSelectedOperationalUsers] =
    useCherreState(selectedOperationalReviewerUsers);

  const [selectedFinancialUsers, setSelectedFinancialUsers] = useCherreState(
    selectedFinancialReviewerUsers
  );
  const selectedProperties = useCherreValue(selectedPropertiesToAssign);
  const shouldDisplayManyToOneMessage = useCherreValue(
    selectedPropertiesToAssignContainsManyToOneProperty
  );
  const [chainType, setChainType] = useState('single-chain');

  const [isOpen, setAssignReviewersDialogOpenState] = useCherreState(
    assignReviewersDialogOpenState
  );

  const selectedCategoryUsers = useMemo(() => {
    return chainType === 'different-chains'
      ? [selectedOperationalUsers, selectedFinancialUsers]
      : [selectedUsers];
  }, [
    selectedUsers,
    selectedOperationalUsers,
    selectedFinancialUsers,
    chainType,
  ]);

  const { clearWarningBoxState, check: checkApproverPresent } =
    useCheckApproverPresent(selectedCategoryUsers);

  const onClose = useCherreEventWithRecoil(
    'user closed assign reviewers dialog',
    (ctx) => () => {
      ctx.recoil.set(assignReviewersDialogOpenState, false);
      ctx.recoil.set(selectedPreparerUsers, []);
      clearWarningBoxState();
    }
  );

  const selectedUsersIsEmpty = useMemo(() => {
    if (chainType === 'different-chains') {
      return (
        selectedOperationalUsers?.reviewer1.length === 0 &&
        selectedOperationalUsers?.reviewer2.length === 0 &&
        selectedOperationalUsers?.reviewer3.length === 0 &&
        selectedFinancialUsers?.reviewer1.length === 0 &&
        selectedFinancialUsers?.reviewer2.length === 0 &&
        selectedFinancialUsers?.reviewer3.length === 0
      );
    } else {
      return (
        selectedUsers?.reviewer1.length === 0 &&
        selectedUsers?.reviewer2.length === 0 &&
        selectedUsers?.reviewer3.length === 0
      );
    }
  }, [
    selectedUsers,
    selectedOperationalUsers,
    selectedFinancialUsers,
    chainType,
  ]);

  return (
    <DialogPanel
      id='AssignReviewersDialog'
      config={{ logLevel: false }}
      style={{ display: 'flex', flexGrow: 1 }}
    >
      <Container open={Boolean(isOpen)} onClose={onClose}>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{ padding: '20px 20px 0px 20px' }}
        >
          <Typography variant='h5' sx={{ fontWeight: 600 }}>
            Assign Reviewers
          </Typography>
        </HeaderGrid>
        {selectedProperties && (
          <Typography
            variant='body1'
            sx={{ marginBottom: '8px', padding: '0px 20px 0px 20px' }}
          >
            {selectedProperties.length}{' '}
            {selectedProperties.length === 1 ? 'property' : 'properties'}{' '}
            selected
          </Typography>
        )}
        {shouldDisplayManyToOneMessage && (
          <Typography variant='body2' margin='0 20px 20px 20px'>
            Note: Assigning a reviewer to a M:1 property group will assign that
            reviewer to all properties within the associated group(s).
          </Typography>
        )}
        <Box
          sx={{
            height: chainType === 'different-chains' ? '595px' : 'unset',
            overflow: 'auto',
            padding: '0px 20px 0px 20px',
          }}
        >
          <Typography variant='body1' fontWeight='bold'>
            Set Dataset Chain of Review
          </Typography>
          <Typography variant='body2'>
            Selected properties contain both financial and operational datasets.
            You may set the same chain of approval for financial and operational
            data or customize them by submission type.
          </Typography>
          <FormEntry title=''>
            <RadioGroup
              row
              defaultValue='single-chain'
              value={chainType}
              sx={{
                marginBottom: '12px',
              }}
              onChange={(e) => {
                setChainType(e.target.value);
                setSelectedUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                setSelectedOperationalUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                setSelectedFinancialUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                clearWarningBoxState();
              }}
            >
              <Radio value='single-chain' label='Single Chain' />
              <Radio value='different-chains' label='Different Chains' />
            </RadioGroup>
          </FormEntry>

          {chainType === 'single-chain' && <SingleChainForm />}
          {chainType === 'different-chains' && <DifferentChainsForm />}
        </Box>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          gap={2}
          sx={{ marginTop: '16px', padding: '0px 20px 20px 20px' }}
        >
          <Grid item>
            <Button
              variant='text'
              sx={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                setAssignReviewersDialogOpenState(false);
                setSelectedUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                setSelectedOperationalUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                setSelectedFinancialUsers({
                  reviewer1: [],
                  reviewer2: [],
                  reviewer3: [],
                });
                clearWarningBoxState();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              sx={{ width: '90px', padding: '6px 8px' }}
              onClick={() => {
                if (checkApproverPresent()) {
                  assignReviewersMutation();
                }
              }}
              disabled={selectedUsersIsEmpty}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Container>
    </DialogPanel>
  );
};

export default AssignReviewersDialog;
