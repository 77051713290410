/**
 * @generated SignedSource<<5d2dfd3b47cb91fe7aa0d61e68f59154>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyDatasetQuery$variables = {
  dataset_id: number;
  property_id: number;
};
export type getPropertyDatasetQuery$data = {
  readonly sys_properties_datasets: ReadonlyArray<{
    readonly template_id: string | null;
  }>;
};
export type getPropertyDatasetQuery = {
  response: getPropertyDatasetQuery$data;
  variables: getPropertyDatasetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset_id"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_datasets",
    "kind": "LinkedField",
    "name": "sys_properties_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyDatasetQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertyDatasetQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "052f0cb25c59e0ed60e7d050b6114b7f",
    "id": null,
    "metadata": {},
    "name": "getPropertyDatasetQuery",
    "operationKind": "query",
    "text": "query getPropertyDatasetQuery(\n  $dataset_id: Int!\n  $property_id: Int!\n) {\n  sys_properties_datasets(where: {dataset_id: {_eq: $dataset_id}, property_id: {_eq: $property_id}, is_active: {_eq: true}}, limit: 1) {\n    template_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "332f10157bfe70c88e33139028323182";

export default node;
