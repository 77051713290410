import {
  useCherreEventWithRecoil,
  useCherreSetState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  FormEntry,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  styled,
  Typography,
  IconButton,
  CloseIcon,
  TextField,
  useTheme,
  Panel,
} from '@cherre-frontend/ui';
import React, { useState } from 'react';
import { getDuplicatedChartOfAccount } from '../../queries/getDuplicatedChartOfAccount';
import {
  MasterFileTypes,
  uploadMasterFileDialogOpenState,
  uploadMasterFileDialogStep,
  UploadMasterFileDialogStepValues,
  uploadMasterFileFormState,
} from '../../recoil';
import { getDuplicatedAccountTree } from '../../queries/getDuplicatedAccountTree';

const SelectStyled = styled<React.FC<SelectProps>>(Select)`
  height: 37px;
`;

const UploadMasterFileDialogStep1: React.FC = () => {
  const theme = useTheme();
  const [Filename, setFileName] = useState('');
  const [formState, , setFormState] = useCherreStateDebounced(
    uploadMasterFileFormState
  );

  const handleFocusOut = () => {
    setFormState((prevState) => ({ ...prevState, name: Filename }));
  };

  const setDialogStep = useCherreSetState(uploadMasterFileDialogStep);

  const onClose = useCherreEventWithRecoil(
    'user closed upload master files dialog',
    (ctx) => () => {
      ctx.recoil.set(uploadMasterFileDialogOpenState, false);
      ctx.recoil.set(uploadMasterFileFormState, { name: '', fileType: '' });
      ctx.recoil.set(
        uploadMasterFileDialogStep,
        UploadMasterFileDialogStepValues.STEP_1
      );
    }
  );

  const isDuplicateMasterFile = useCherreValue(getDuplicatedChartOfAccount());
  const isDuplicateAccountGrouping = useCherreValue(getDuplicatedAccountTree());

  return (
    <>
      <Panel disableParent={false} id='upload-master-file-dialog-step-1'>
        <Grid container>
          <Grid item xs>
            <Typography variant='h4'>{'Add Master Files'}</Typography>
          </Grid>
          <Grid item xs='auto'>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          my={3}
          sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <FormEntry title='Name'>
            <TextField
              defaultValue={formState?.name}
              placeholder='Enter file name'
              onBlur={handleFocusOut}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              size='small'
            />
          </FormEntry>
          <FormEntry title='File Type'>
            <SelectStyled
              value={formState?.fileType ?? ''}
              onChange={(e) =>
                setFormState((prevState) => ({
                  ...prevState,
                  fileType: e.target.value as string,
                }))
              }
              displayEmpty
              renderValue={(value) =>
                value !== '' ? (
                  value
                ) : (
                  <Typography sx={{ color: theme.palette.grey[500] }}>
                    Select
                  </Typography>
                )
              }
            >
              <MenuItem value={''} disabled>
                Select
              </MenuItem>
              <MenuItem
                key={'master-chart-of-accounts-option'}
                value={MasterFileTypes.MASTER_COA}
              >
                {MasterFileTypes.MASTER_COA}
              </MenuItem>
              <MenuItem
                key={'account_groupings-option'}
                value={MasterFileTypes.ACCOUNT_GROUPINGS}
              >
                {MasterFileTypes.ACCOUNT_GROUPINGS}
              </MenuItem>
            </SelectStyled>
            {isDuplicateMasterFile &&
              formState?.fileType === MasterFileTypes.MASTER_COA && (
                <Typography
                  variant='body2'
                  sx={{ color: theme.palette.error.main }}
                >
                  {`A Master Chart of Accounts “${formState?.name}” already exists for this organization.`}
                </Typography>
              )}
            {isDuplicateAccountGrouping &&
              formState?.fileType === MasterFileTypes.ACCOUNT_GROUPINGS && (
                <Typography
                  variant='body2'
                  sx={{ color: theme.palette.error.main }}
                >
                  {`An Account Grouping “${formState?.name}” already exists for this organization.`}
                </Typography>
              )}
          </FormEntry>
        </Box>

        <Grid container direction='row' justifyContent='flex-start'>
          <Grid item>
            <Button
              size='medium'
              variant='contained'
              color='primary'
              disabled={
                formState?.fileType === '' ||
                formState?.name === '' ||
                (isDuplicateMasterFile &&
                  formState?.fileType === MasterFileTypes.MASTER_COA) ||
                (isDuplicateAccountGrouping &&
                  formState?.fileType === MasterFileTypes.ACCOUNT_GROUPINGS)
              }
              onClick={() => {
                setDialogStep(UploadMasterFileDialogStepValues.STEP_2);
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
};

export default UploadMasterFileDialogStep1;
