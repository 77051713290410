import {
  bool,
  number,
  string,
  object,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import {
  dialogStage,
  newBatchInfo,
} from '../Panels/CreateBatchDialog/recoil/localState';
import { refine } from '@cherre-frontend/data-fetching';

export const createBatchDialogOpenState =
  BatchManagementRoute.searchParamsSelectorFamily({
    key: 'create-modal',
    refine: withDefault(bool(), false),
    dependants: [dialogStage, newBatchInfo],
  });

export const reopenBatchesDialogOpenState =
  BatchManagementRoute.searchParamsSelectorFamily({
    key: 'reopen-modal',
    refine: withDefault(bool(), false),
  });

export const batchManagementTableSearch =
  BatchManagementRoute.searchParamsSelectorFamily({
    key: 'search',
    refine: withDefault(string(), ''),
  });

export const batchManagementTableState =
  BatchManagementRoute.searchParamsSelectorFamily({
    key: 'table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'updated_at_datetime', desc: true }],
      }
    ),
  });

export const batchesTableFiltersState =
  BatchManagementRoute.searchParamsSelectorFamily({
    key: 'batch-table-filters',
    refine: withDefault(
      object({
        Provider: withDefault(string(), ''),
        Fund: withDefault(string(), ''),
        Status: withDefault(string(), ''),
        Interval: withDefault(string(), ''),
        Period: withDefault(string(), ''),
        Type: withDefault(string(), ''),
      }),
      {
        Provider: 'Any Provider',
        Fund: 'Any Fund',
        Status: 'Any Status',
        Interval: 'Any Interval',
        Period: 'Any Period',
        Type: 'Any Type',
      }
    ),
  });
