import React from 'react';
import { Chip, ChipProps, styled } from '@cherre-frontend/ui';

type LightStatusChipStyledProps = {
  active: boolean;
} & ChipProps;

const LightStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'active' !== prop,
})<LightStatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  background-color: ${({ active, theme }) =>
    active ? theme.accents.positive.light : theme.palette.grey[100]};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
    color: ${({ active, theme }) =>
      active ? theme.accents.positive.main : theme.palette.grey[700]};
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type LightStatusChipProps = {
  active: boolean;
};

export const LightStatusChip: React.FC<LightStatusChipProps> = ({ active }) => {
  return (
    <LightStatusChipStyled
      label={active ? 'Active' : 'Inactive'}
      active={active}
    />
  );
};

LightStatusChip.displayName = 'LightStatusChip';
