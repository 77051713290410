import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getMappingFieldsSubscription } from './__generated__/getMappingFieldsSubscription.graphql';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';

export const getMappingFields = graphQLSelector({
  query: graphql`
    subscription getMappingFieldsSubscription($provider_id: Int!) {
      sys_mapping_fields(order_by: { mapping_field_description: asc }) {
        mapping_field_id
        mapping_field_name
        mapping_field_description
        is_single_mapping_set
        custom_attributes
        mapping_sets(
          where: {
            provider_id: { _eq: $provider_id }
            is_active: { _eq: true }
          }
        ) {
          mapping_set_id
          mapping_set_description
        }
      }
    }
  ` as GraphQLReturn<getMappingFieldsSubscription>,
  mapResponse: (response) => response.sys_mapping_fields,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        provider_id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
      };
    },
});
