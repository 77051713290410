import React from 'react';
import { BatchCardSkeleton } from '.';

type BatchCardSkeletonProps = {
  listSize: number;
};

const BatchListSkeleton: React.FC<BatchCardSkeletonProps> = ({ listSize }) => {
  return (
    <>
      {new Array(listSize).fill(null).map((_, index) => (
        <BatchCardSkeleton key={index} />
      ))}
    </>
  );
};

BatchListSkeleton.displayName = 'BatchListSkeleton';

export default BatchListSkeleton;
