/**
 * @generated SignedSource<<73cbc85fd33ef3393480ee12bba22341>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDisablePropertyMutation$variables = {
  property_id: number;
};
export type useDisablePropertyMutation$data = {
  readonly update_sys_properties: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_batch_validation_rules: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_dataset_validation_rules: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_datasets: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_mapping_sets: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_roles_users: {
    readonly affected_rows: number;
  } | null;
};
export type useDisablePropertyMutation = {
  response: useDisablePropertyMutation$data;
  variables: useDisablePropertyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "_set",
    "value": {
      "is_active": false
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "property_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_roles_users_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_roles_users",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_mapping_sets_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_mapping_sets",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_datasets_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_datasets",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_batch_validation_rules_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_batch_validation_rules",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_dataset_validation_rules_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_dataset_validation_rules",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDisablePropertyMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDisablePropertyMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f2bdeb45b27c9e92712265d92b7e3686",
    "id": null,
    "metadata": {},
    "name": "useDisablePropertyMutation",
    "operationKind": "mutation",
    "text": "mutation useDisablePropertyMutation(\n  $property_id: Int!\n) {\n  update_sys_properties_roles_users(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n  update_sys_properties_mapping_sets(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n  update_sys_properties_datasets(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n  update_sys_properties_batch_validation_rules(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n  update_sys_properties_dataset_validation_rules(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n  update_sys_properties(_set: {is_active: false}, where: {property_id: {_eq: $property_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5bc6abfad1fa9579af553cba38daee0";

export default node;
