import { WORKFLOW } from '../consts';

export enum SubmitStatus {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  SUBMIT = 'SUBMIT',
}

const getStatusForAction = (action: string) => {
  switch (action) {
    case WORKFLOW.REVIEWER_APPROVE:
      return SubmitStatus.APPROVE;
    case WORKFLOW.REVIEWER_SUBMIT:
      return SubmitStatus.SUBMIT;
    case WORKFLOW.REVIEWER_REJECT:
      return SubmitStatus.REJECT;
    default:
      return undefined; // Return undefined if no match
  }
};

export const getStatusFromAction = (actions: string[] | undefined) => {
  if (!actions) {
    return ''; // Default to empty string if no actions are provided
  }

  // Iterate over the array and return the first non-empty value
  for (const action of actions) {
    const status = getStatusForAction(action);
    if (status) {
      return status;
    }
  }
  return ''; // Default to empty string if no valid status is found
};

export const getActionFromStatus = (status: string) => {
  switch (status) {
    case SubmitStatus.APPROVE:
      return WORKFLOW.REVIEWER_APPROVE;
    case SubmitStatus.SUBMIT:
      return WORKFLOW.REVIEWER_SUBMIT;
    case SubmitStatus.REJECT:
      return WORKFLOW.REVIEWER_REJECT;
    default:
      return '';
  }
};
