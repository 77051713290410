import {
  CheckerReturnType,
  atom,
  refine,
  noWait,
  useCherreSetState,
  selector,
  useCherreStateDebounced,
  useCherreValue,
  useCherreSetPartialState,
} from '@cherre-frontend/data-fetching';
import { getPropertyByCode } from '../queries/getPropertyByCode';
import { getSourceExistWithLinkProperties } from '../queries/getSourceExistWithLinkProperties';

const stringChecker = refine.custom((value) => {
  if (typeof value === 'string' && value !== '') {
    return value;
  } else {
    return null;
  }
});

export const addEditPropertyFormChecker = refine.object({
  property_id: refine.nullable(refine.number()),
  property_code: stringChecker,
  provider_id: refine.nullable(refine.number()),
  address: refine.nullable(refine.string()),
  type: refine.nullable(refine.string()),
  fund: refine.nullable(refine.string()),
  property_name: refine.nullable(refine.string()),
  entity_id: refine.nullable(refine.string()),
  entity_name: refine.nullable(refine.string()),
  is_active: refine.bool(),
  custom_attributes: refine.dict(refine.string()),
});

export type addEditPropertyFormType = CheckerReturnType<
  typeof addEditPropertyFormChecker
>;

const defaultAddEditPropertyForm: addEditPropertyFormType = {
  property_id: null,
  property_code: '',
  provider_id: null,
  address: '',
  type: '',
  fund: '',
  property_name: '',
  entity_id: '',
  entity_name: '',
  is_active: true,
  custom_attributes: {},
};

const addEditPropertySourceExistForm: {
  isValid: boolean;
  error: string | null;
} = {
  isValid: true,
  error: null,
};

export const addEditPropertyFormState = atom<addEditPropertyFormType>({
  key: 'add-edit-property-form',
  default: defaultAddEditPropertyForm,
});

export const addEditPropertySourceExistFormState = atom<{
  isValid: boolean;
  error: string | null;
}>({
  key: 'add-edit-property-source-form',
  default: addEditPropertySourceExistForm,
});

const originalAddEditPropertyFormState = atom<addEditPropertyFormType>({
  key: 'original-add-edit-property-form',
  default: defaultAddEditPropertyForm,
});

const addEditPropertyFormPropertyCode = atom<string>({
  key: 'add-edit-property-form-property-code',
  default: '',
});

export const useResetPropertyForm = () => {
  const setAddEditPropertyForm = useCherreSetState(addEditPropertyFormState);
  const setOriginalAddEditPropertyForm = useCherreSetState(
    originalAddEditPropertyFormState
  );
  const setAddEditPropertyFormPropertyCode = useCherreSetState(
    addEditPropertyFormPropertyCode
  );

  return () => {
    setAddEditPropertyForm(defaultAddEditPropertyForm);
    setOriginalAddEditPropertyForm(defaultAddEditPropertyForm);
    setAddEditPropertyFormPropertyCode(
      defaultAddEditPropertyForm.property_code
    );
  };
};

export const useInitEditPropertyForm = () => {
  const setAddEditPropertyForm = useCherreSetState(addEditPropertyFormState);
  const setOriginalAddEditPropertyForm = useCherreSetState(
    originalAddEditPropertyFormState
  );
  const [, , setAddEditPropertyFormPropertyCode] = useCherreStateDebounced(
    addEditPropertyFormPropertyCode
  );
  return (property: addEditPropertyFormType) => {
    setAddEditPropertyForm(property);
    setOriginalAddEditPropertyForm(property);
    setAddEditPropertyFormPropertyCode(property.property_code);
  };
};

export const useEditPropertyForm = () => {
  const value = useCherreValue(addEditPropertyFormState);
  const setForm = useCherreSetPartialState(addEditPropertyFormState);
  const [, , setDebounced] = useCherreStateDebounced(
    addEditPropertyFormPropertyCode
  );
  const setter = (property: Partial<addEditPropertyFormType>) => {
    setForm(property);
    if (property.property_code) {
      setDebounced(property.property_code);
    }
  };
  return [value, setter] as const;
};

export const addEditPropertyFormHasChanges = selector({
  key: 'add-edit-property-form-has-changes',
  get: ({ get }) => {
    const form = get(addEditPropertyFormState);
    const formOriginal = get(originalAddEditPropertyFormState);
    return (
      form &&
      formOriginal &&
      Object.keys(form).some((key) => form[key] !== formOriginal[key])
    );
  },
});

export const propertyExistsSelector = selector({
  key: 'add-edit-property-form-property-exists',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const propertyCode = get(addEditPropertyFormPropertyCode);
      if (!propertyCode) {
        return false;
      }
      const loadable = get(noWait(getPropertyByCode(propertyCode)));
      const value = loadable.valueMaybe();
      return value && value.length > 0;
    },
});

export const sourceErrorSelector = selector({
  key: 'add-edit-property-source-error',
  scoped: true,
  get:
    () =>
    async ({ get }) => {
      const form = get(addEditPropertyFormState);
      if (!form || !form.property_code) {
        return null;
      }

      const dataExist = get(
        getSourceExistWithLinkProperties({
          entity_id: form.entity_id as string,
          property_id: form.property_id ? [form.property_id] : [],
        })
      );

      if (dataExist.length === 0) {
        return null;
      }

      const message = `Source ID already mapped to Target ID ${dataExist
        .map((i) => i.property_code)
        .join(', ')}. A source code cannot be mapped to multiple targets.`;
      return { field: 'entity_id', message };
    },
});

export const multiProviderSourceErrorSelector = selector({
  key: 'add-edit-property-multi-provider-source-error',
  scoped: true,
  get:
    () =>
    async ({ get }) => {
      const form = get(addEditPropertyFormState);
      if (!form || !form.property_code) {
        return null;
      }

      const dataExist = get(
        getSourceExistWithLinkProperties({
          entity_id: form.entity_id as string,
          property_id: form.property_id ? [form.property_id] : [],
        })
      );

      const value = dataExist.find(({ provider, property_code }) =>
        form?.provider_id
          ? provider?.provider_id === form.provider_id
          : provider === null && property_code === form.property_code
      );

      if (!value) {
        return null;
      }

      const message = value.provider
        ? `Source ID already mapped to Target ID ${value.property_code} for provider ${value.provider.provider_name}. Please select another Provider to proceed.`
        : `Unassigned Source ID mapped to Target ID ${value.property_code} already exists.`;

      return { field: value.provider ? 'provider_id' : 'entity_id', message };
    },
});
