/**
 * @generated SignedSource<<ed3dbd45ecd65c95777c03913cf2a72a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getOpenedBatchesWithChartOfAccountsSubscription$variables = {
  master_chart_of_account_id: number;
};
export type getOpenedBatchesWithChartOfAccountsSubscription$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly submission_id: number;
    readonly submission_name: string;
  }>;
};
export type getOpenedBatchesWithChartOfAccountsSubscription = {
  response: getOpenedBatchesWithChartOfAccountsSubscription$data;
  variables: getOpenedBatchesWithChartOfAccountsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "master_chart_of_account_id"
  }
],
v1 = {
  "kind": "Literal",
  "name": "is_active",
  "value": {
    "_eq": true
  }
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "submission_name": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_closed",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  (v1/*: any*/),
                  {
                    "fields": [
                      (v1/*: any*/),
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_eq",
                                "variableName": "master_chart_of_account_id"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "master_chart_of_account_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "mapping_set"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "properties_mapping_sets"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batches"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getOpenedBatchesWithChartOfAccountsSubscription",
    "selections": (v2/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getOpenedBatchesWithChartOfAccountsSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e128540ea9d3b56f9a4b5eccc7ce4ca6",
    "id": null,
    "metadata": {},
    "name": "getOpenedBatchesWithChartOfAccountsSubscription",
    "operationKind": "subscription",
    "text": "subscription getOpenedBatchesWithChartOfAccountsSubscription(\n  $master_chart_of_account_id: Int!\n) {\n  sys_submissions(where: {is_closed: {_eq: false}, property_batches: {property: {is_active: {_eq: true}, properties_mapping_sets: {is_active: {_eq: true}, mapping_set: {master_chart_of_account_id: {_eq: $master_chart_of_account_id}}}}}}, order_by: {submission_name: asc}) {\n    submission_id\n    submission_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a9ac0bed4dab67b6fb93601eb9af554";

export default node;
