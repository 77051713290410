import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadMappingMutation } from './__generated__/useUploadMappingMutation.graphql';

export const useUploadMapping = (
  input: {
    provider_id: number;
    mapping_field_id: number;
    mapping_set_description: string;
    master_chart_of_account_id: number;
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadMappingMutation($input: SysUploadMappingInput!) {
        _sys_upload_mapping(input: $input) {
          mapping_set_id
        }
      }
    ` as GraphQLReturn<useUploadMappingMutation>,
    {
      trackEvent: false,
      mapVariables: (data: { batchId: string }) => async () => {
        return {
          input: {
            file_reference_source: data.batchId,
            file_source: input.file_source,
            provider_id: input.provider_id,
            mapping_field_id: input.mapping_field_id,
            mapping_set_description: input.mapping_set_description,
            master_chart_of_account_id: input.master_chart_of_account_id,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
