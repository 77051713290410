import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { uploadMasterFileFormState } from '../recoil';
import { getDuplicatedChartOfAccountQuery } from './__generated__/getDuplicatedChartOfAccountQuery.graphql';

export const getDuplicatedChartOfAccount = graphQLSelector({
  query: graphql`
    query getDuplicatedChartOfAccountQuery($name: String) {
      sys_organization_master_chart_of_accounts(
        where: {
          is_active: { _eq: true }
          chart_of_accounts_name: { _ilike: $name }
        }
        limit: 1
      ) {
        master_chart_of_account_id
      }
    }
  ` as GraphQLReturn<getDuplicatedChartOfAccountQuery>,
  mapResponse: (response) => {
    return response.sys_organization_master_chart_of_accounts.length > 0;
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        name: get(uploadMasterFileFormState).name,
      };
    },
});
