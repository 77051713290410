import { MasterFilesRoute } from '../../routes';
import {
  bool,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { atom } from '@cherre-frontend/data-fetching';

export enum UploadMasterFileDialogStepValues {
  STEP_1 = 'Step_1',
  STEP_2 = 'Step_2',
}

export enum MasterFileTypes {
  MASTER_COA = 'Master Chart of Accounts',
  ACCOUNT_GROUPINGS = 'Account Groupings',
}

export const uploadMasterFileDialogOpenState = atom<boolean>({
  key: 'upload-master-file-dialog-open-state',
  default: false,
});

export const uploadMasterFileDialogStep =
  atom<UploadMasterFileDialogStepValues>({
    key: 'upload-master-file-dialog-stage',
    default: UploadMasterFileDialogStepValues.STEP_1,
  });

export const uploadMasterFileFormState = atom<{
  name: string;
  fileType: string;
}>({
  key: 'upload-master-file-form-state',
  default: { name: '', fileType: '' },
});

export const masterFilesTableSearch =
  MasterFilesRoute.searchParamsSelectorFamily({
    key: 'master-files-table-search',
    refine: withDefault(string(), ''),
  });

export const masterFilesTableState =
  MasterFilesRoute.searchParamsSelectorFamily({
    key: 'master-files-table',
    refine: withDefault(
      object({
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        sorting: [{ id: 'name', desc: false }],
      }
    ),
  });

export const masterFileToDelete = atom<number>({
  key: 'master-file-to-delete',
  default: 0,
});

export const accountGroupingToDelete = atom<number>({
  key: 'account-grouping-to-delete',
  default: 0,
});

export const currentMasterFiles = atom<{ name: string; fileType: string }[]>({
  key: 'current-master-files',
  default: [],
});

export const isTableEmpty = atom<boolean>({
  key: 'is-master-file-table-empty',
  default: true,
});
