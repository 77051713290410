/**
 * @generated SignedSource<<8078a41b2e5140be38615983daa6c22d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type review_package_report_input = {
  approve: boolean;
  comment?: string | null;
  package_report_id: number;
  property_batch_id: number;
};
export type queriesReviewPackageReportMutation$variables = {
  params: review_package_report_input;
};
export type queriesReviewPackageReportMutation$data = {
  readonly _sys_review_package_report: {
    readonly is_reviewed: boolean;
  };
};
export type queriesReviewPackageReportMutation = {
  response: queriesReviewPackageReportMutation$data;
  variables: queriesReviewPackageReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "review_package_report_output",
    "kind": "LinkedField",
    "name": "_sys_review_package_report",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_reviewed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queriesReviewPackageReportMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queriesReviewPackageReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3ed8687990c91ea4c0c8d6eb02de25f3",
    "id": null,
    "metadata": {},
    "name": "queriesReviewPackageReportMutation",
    "operationKind": "mutation",
    "text": "mutation queriesReviewPackageReportMutation(\n  $params: review_package_report_input!\n) {\n  _sys_review_package_report(params: $params) {\n    is_reviewed\n  }\n}\n"
  }
};
})();

(node as any).hash = "abcb909b5a80add5274632917279e9cb";

export default node;
