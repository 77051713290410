import {
  useCherreValue,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  IconButton,
  ReportFileIcon,
  Table,
  Tooltip,
  useTable,
  PackageReportCommentIcon,
  useTheme,
  // eslint-disable-next-line prettier/prettier
  type MRT_ColumnDef,
} from '@cherre-frontend/ui';
import { ChipStyled } from '../components/ChipStyled';
import { ReportNameCell } from '../components/ReportNameCell';
import { getLabel, useCheckReportHasComments } from '../utils';
import React, { useMemo } from 'react';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  actionsColumnStyle,
  disableColumnGrow,
  muiTableBodyCellProps,
  muiTableHeadCellProps,
  reportIconButtonStyle,
  reportIconStyle,
} from '../styles';
import {
  $propertyBatchesPackageReportInfo,
  $propertyBatchPackageReportInfo,
  ModalState,
} from '../recoil';
import { useClaims } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { groupBy } from 'lodash';
import { packageReportSidebarState } from '../PackageReportSidebar/recoil';

export interface PackageReportsCombinedTableProps {
  property_batch_ids: number[];
  investmentPropertyBatchId: number;
  disableLastRowBorder?: boolean;
  modalState: ModalState;
}

type TableRow = {
  id: number;
  name: string;
  label: ReturnType<typeof getLabel>;
  numberOfProperties: number;
  reportSlug: string;
};

export const PackageReportsCombinedTable: React.FC<
  PackageReportsCombinedTableProps
> = ({
  property_batch_ids,
  investmentPropertyBatchId,
  disableLastRowBorder,
  modalState,
}) => {
  const infoProviderProperties = useCherreValue(
    $propertyBatchesPackageReportInfo(property_batch_ids)
  );

  const info = useCherreValue(
    $propertyBatchPackageReportInfo(investmentPropertyBatchId)
  );

  const checkReportHasComments = useCheckReportHasComments(
    investmentPropertyBatchId,
    info
  );

  const setPackageReportModal = useCherreSetState(modalState);
  const setPackageReportSidebar = useCherreSetState(packageReportSidebarState);

  const remindersFF = useFeatureFlag('DSPReminders');

  const isReviewer = useClaims('reviewer');

  const theme = useTheme();

  const reports: TableRow[] = useMemo(() => {
    const packageReports = infoProviderProperties?.sys_property_batches.flatMap(
      (propertyBatch) => {
        return propertyBatch.package_reports ?? [];
      }
    );

    return Object.values(groupBy(packageReports, 'package_report_id')).map(
      (grouped) => ({
        id: grouped[0].package_report_id,
        name: grouped[0].package_report_label + ' - Combined',
        numberOfProperties: grouped.length,
        label: getLabel(isReviewer, true, false, theme),
        reportSlug: grouped[0].report.report_slug,
      })
    );
  }, [infoProviderProperties]);

  const columns = useMemo<MRT_ColumnDef<TableRow>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Report Name',
        size: 150,
        enableSorting: true,
        Cell: ({ renderedCellValue }) => {
          const reviewed = true;
          return (
            <ReportNameCell
              renderedCellValue={renderedCellValue}
              reviewed={reviewed ?? false}
            />
          );
        },
      },
      {
        accessorKey: 'numberOfProperties',
        header: '# of Properties',
        size: 170,
        enableSorting: true,
        ...disableColumnGrow,
      },
      {
        header: 'Status',
        accessorKey: 'label',
        Cell: ({ row }) => {
          const label = row.original.label;

          return (
            <ChipStyled
              size='small'
              title={label.text}
              label={label.text}
              htmlColor={label.color}
              background={label.background}
            />
          );
        },
        size: 150,
        ...disableColumnGrow,
      },
      {
        id: 'actions',
        header: '',
        size: 80,
        ...disableColumnGrow,
        Cell: ({ row }) => {
          const reportHasComments = checkReportHasComments(row.original.id);

          return (
            <Box style={actionsColumnStyle}>
              <Tooltip
                title={
                  reportHasComments
                    ? `Comments ${remindersFF ? 'or reminders ' : ''}available`
                    : `No comments ${remindersFF ? 'or reminders' : ''}`
                }
              >
                <IconButton
                  style={reportIconButtonStyle}
                  size='small'
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    setPackageReportSidebar({
                      [row.original.reportSlug]: {
                        sidebarOpen: true,
                        comment: '',
                        reminder: '',
                        showCompletedReminders: false,
                        tab: 0,
                      },
                    });
                    setPackageReportModal({
                      property_id: info?.property.property_id,
                      property_batch_id: investmentPropertyBatchId,
                      report_slug: row.original.reportSlug,
                    });
                  }}
                >
                  <PackageReportCommentIcon
                    color={reportHasComments ? 'primary' : 'disabled'}
                    style={reportIconStyle}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='View Detail'>
                <IconButton
                  style={reportIconButtonStyle}
                  size='small'
                  color='primary'
                  onClick={() => {
                    setPackageReportSidebar({});
                    setPackageReportModal({
                      property_id: info?.property.property_id,
                      property_batch_id: investmentPropertyBatchId,
                      report_slug: row.original.reportSlug,
                    });
                  }}
                >
                  <ReportFileIcon style={reportIconStyle} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [checkReportHasComments, remindersFF]
  );

  const table = useTable({
    data: reports,
    columns,
    layoutMode: 'grid',
    manualSorting: false,
    enableRowSelection: false,
    enableSelectAll: false,
    enableSorting: true,
    muiTableBodyRowProps: disableLastRowBorder
      ? {
          sx: {
            ':last-child td': {
              borderBottom: 'none',
            },
          },
        }
      : {},
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    getRowId: (originalRow) => {
      return originalRow.id.toString();
    },
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
  });

  return <Table table={table} />;
};
