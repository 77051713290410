import { useStateDebounced } from '@cherre-frontend/data-fetching';
import { useEffect } from 'react';

export function useScrollPosition() {
  const [, debouncedScroll, setScroll] = useStateDebounced(0, {
    noLocalValueUpdate: true,
  });

  useEffect(() => {
    const scrollable = document.getElementById('app-content');

    const handleScroll = () => {
      setScroll(scrollable?.scrollTop || 0);
    };

    scrollable?.addEventListener('scroll', handleScroll);

    return () => scrollable?.removeEventListener('scroll', handleScroll);
  }, []);

  return [
    debouncedScroll,
    (scrollTo: number) => {
      const scrollable = document.getElementById('app-content');
      scrollable?.scrollTo(0, scrollTo);
    },
  ] as const;
}
