import {
  QueryResult,
  useCherrePartialState,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  ActionsMenu,
  EditIcon,
  EmulateUserIcon,
  Grid,
  InviteIcon,
  LockIcon,
  LockOpenIcon,
  MRT_ColumnDef,
  MenuItem,
  Table,
  styled,
  useTable,
  useTheme,
  SwitchAccountIcon,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AvatarAndName } from 'src/products/customerAdmin/pages/Teams/components/AvatarAndName';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { getProviderOrganization } from '../../../queries/getProviderOrganization';
import {
  getProviderUsers,
  getProviderUsersTotal,
} from '../../../queries/getProviderUsers';
import {
  disableUserDialogOpenState,
  providerUsersTableState,
  selectedUserState,
  sendEmailDialogOpenState,
} from '../recoil';
import { NoUsersAdded } from './NoUsersAdded';
import constants from 'src/const';
import { LightStatusChip } from 'src/products/data-submission-portal/components/StatusChip/LightStatusChip';
import { reassignUserModalState } from '../../../recoil';
export type UserStatusTableProps = {
  search?: string;
};
export type ParamsProps = {
  provider_id: string;
};

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export const UsersPanelTable: React.FC<UserStatusTableProps> = ({ search }) => {
  const theme = useTheme();

  const [tableState, setTableState] = useCherreState(providerUsersTableState);

  const data = useCherreValue(getProviderUsers());
  const total = useCherreValue(getProviderUsersTotal());

  const { provider_id } = useParams<ParamsProps>();

  const setDisableUserModalState = useCherreSetState(
    disableUserDialogOpenState
  );

  const setSendEmailModalState = useCherreSetState(sendEmailDialogOpenState);

  const setReassignUserId = useCherreSetState(reassignUserModalState);

  const [, setSelectedUser] = useCherrePartialState(selectedUserState);

  const organization = useCherreValue(getProviderOrganization());

  const history = useHistory();

  const handleLinkClick = (user_id: string) => {
    history.push(`/dsp/providers/${provider_id}/user/${user_id}/edit-user`);
  };

  const rowActionOptions = [
    {
      name: 'edit-info',
      label: () => {
        return <span>Edit Info</span>;
      },
      icon: () => {
        return <EditIcon />;
      },
      onClick: (row) => handleLinkClick(row.id),
    },
    {
      name: 'reassign-user',
      label: () => {
        return <span>Reassign User</span>;
      },
      icon: () => {
        return <SwitchAccountIcon />;
      },
      onClick: (row) => setReassignUserId(row.original.user_id),
    },
    {
      name: 'emulate-user',
      label: () => {
        return <span>Emulate User</span>;
      },
      icon: () => {
        return <EmulateUserIcon />;
      },
      onClick: (row) => {
        sessionStorage.setItem(constants.IS_EMULATED_SESSION_KEY, '1');
        window.location.href = `/api/v1/organizations/${organization}/users/${row.id}/emulate`;
      },
    },
    {
      name: 'Disable/Enable',
      label: (row) => {
        return row.original.disabled ? (
          <span>Enable</span>
        ) : (
          <span>Disable</span>
        );
      },
      icon: (row) => {
        return row.original.disabled ? <LockOpenIcon /> : <LockIcon />;
      },
      onClick: (row) => {
        setSelectedUser({
          user_id: row.id,
          user_first_name: row.original.first_name || '',
          user_last_name: row.original.last_name || '',
          status: row.original.disabled,
          user_email: row.original.email,
        });
        setDisableUserModalState(true);
      },
    },
    {
      name: 'resend-invitation',
      label: () => {
        return <span>Resend Invitation</span>;
      },
      icon: () => {
        return <InviteIcon />;
      },
      onClick: (row) => {
        setSelectedUser({
          user_id: row.id,
          user_first_name: row.original.first_name || '',
          user_last_name: row.original.last_name || '',
        });
        setSendEmailModalState(true);
      },
    },
  ];

  const columns: MRT_ColumnDef<QueryResult<typeof getProviderUsers>[number]>[] =
    useMemo(
      () => [
        {
          accessorKey: 'first_name',
          header: 'Name',
          maxSize: 760,
          size: 500,
          minSize: 20,
          sortDescFirst: false,
          sortable: true,
          mask: '',
          accessorFn: (row) => {
            return (
              <Grid container direction='row' alignItems='baseline'>
                <AvatarAndName
                  name={`${row.first_name || ''} ${row.last_name || ''}`}
                />
              </Grid>
            );
          },
        },
        {
          accessorKey: 'email',
          header: 'Email',
          size: 220,
          accessorFn: (row) => {
            return row.email;
          },
        },
        {
          accessorKey: 'disabled',
          header: 'Status',
          size: 120,
          accessorFn: (row) => {
            return <LightStatusChip active={row.disabled ? false : true} />;
          },
        },
        {
          accessorKey: 'last_seen.last_login_at',
          header: 'Last Active',
          filterVariant: 'date',
          size: 100,
          accessorFn: (row) =>
            row.last_seen?.last_login_at
              ? moment(row.last_seen?.last_login_at).fromNow()
              : '',
        },
      ],
      []
    );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    muiTableBodyRowProps: { hover: true, sx: { background: 'white' } },
    enableRowActions: true,
    enableRowSelection: false,
    enableSelectAll: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    enableMultiSort: true,
    state: {
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.user_id?.toString(),
    rowCount: total?.aggregate?.count || 0,
    muiTableProps: {
      sx: {
        '& .MuiTableRow-root td': {
          padding: '10px',
          background: '#fff',
        },
        '& .MuiTableRow-root:hover td': {
          background: '#F5F5F5',
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    renderRowActions: ({ row }) => (
      <Grid container justifyContent={'flex-end'}>
        <ActionsMenu>
          {rowActionOptions.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => option.onClick(row)}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: theme.palette.grey[500],
                  marginRight: '10px',
                },
              }}
            >
              {option.icon(row)} {option.label(row)}
            </MenuItem>
          ))}
        </ActionsMenu>
      </Grid>
    ),
  });

  if (data && data.length === 0) {
    return search ? <NoItemsFound title='No Users Found' /> : <NoUsersAdded />;
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

UsersPanelTable.displayName = 'UsersPanelTable';
