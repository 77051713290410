import {
  graphQLSelector,
  selector,
  GraphQLReturn,
  NotFoundError,
  selectorFamily,
  noWait,
} from '@cherre-frontend/data-fetching';
import { AddProviderRoute, EditProviderRoute } from '../../routes';
import { waitForAny } from 'recoil';
import { graphql } from 'react-relay';
import { recoilGetProviderInfoQuery } from './__generated__/recoilGetProviderInfoQuery.graphql';
import { recoilGetProviderTypesQuery } from './__generated__/recoilGetProviderTypesQuery.graphql';
import { recoilGetExistingProviderQuery } from './__generated__/recoilGetExistingProviderQuery.graphql';

export const $providerId = selector({
  key: 'add-edit-provider-provider-id',
  get: ({ get }) => {
    const valuesLoadable = get(
      waitForAny([
        AddProviderRoute.routeParamSelector,
        EditProviderRoute.routeParamSelector,
      ])
    );
    const resolved = valuesLoadable.find((v) => v.state === 'hasValue');
    if (!resolved) {
      throw new Error('no matching route');
    }
    return resolved.getValue().provider_id;
  },
});

const _$providerInfo = graphQLSelector({
  query: graphql`
    query recoilGetProviderInfoQuery($provider_id: Int!) {
      sys_providers(where: { provider_id: { _eq: $provider_id } }) {
        provider_name
        provider_id
        provider_type_id
        ingestion_config
        organization {
          settings
        }
      }
    }
  ` as GraphQLReturn<recoilGetProviderInfoQuery>,
  mapResponse: (rawResponse, variables) => {
    const provider = rawResponse.sys_providers[0];
    if (!!variables.provider_id && !provider) {
      throw new NotFoundError(
        `Provider with id ${variables.provider_id} not found`
      );
    }
    return provider;
  },
});

export const $providerInfo = selector({
  key: 'add-edit-provider-provider-info',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const provider_id = get($providerId);
      if (!provider_id) {
        return undefined;
      }
      return get(_$providerInfo({ provider_id }));
    },
});

export const $providerTypes = graphQLSelector({
  query: graphql`
    query recoilGetProviderTypesQuery {
      sys_provider_types {
        type_id: id
        name
      }
    }
  ` as GraphQLReturn<recoilGetProviderTypesQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (rawResponse) => rawResponse.sys_provider_types,
});

const _$existingProvider = graphQLSelector({
  resetCache: false,
  query: graphql`
    query recoilGetExistingProviderQuery(
      $name: String!
      $provider_ids: [Int!]
    ) {
      sys_providers(
        where: {
          provider_name: { _eq: $name }
          provider_id: { _nin: $provider_ids }
        }
      ) {
        provider_name
        provider_id
        provider_type_id
      }
    }
  ` as GraphQLReturn<recoilGetExistingProviderQuery>,
  mapVariables:
    (name: string) =>
    ({ get }) => {
      const provider_ids: number[] = [];
      const provider_id = get($providerId);
      if (provider_id) {
        provider_ids.push(provider_id);
      }
      return { name, provider_ids };
    },
  mapResponse: (rawResponse) => Boolean(rawResponse.sys_providers[0]),
});

export const $existingProvider = selectorFamily({
  key: 'add-edit-provider-existing-provider',
  scoped: true,
  get:
    (name: string | null | undefined) =>
    ({ get }) => {
      if (!name) {
        return false;
      }
      const loadable = get(noWait(_$existingProvider(name)));
      return loadable.valueMaybe() ?? false;
    },
});
