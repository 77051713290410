/**
 * @generated SignedSource<<fc3c4697e1b1f899c21f8d9eb6056f83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type create_batch_params = {
  due_date: number;
  fiscal_year_flag: boolean;
  provider_id: number;
  submission_interval: string;
  submission_period: string;
  submission_types: ReadonlyArray<create_batch_submission_type>;
};
export type create_batch_submission_type = {
  property_ids: ReadonlyArray<number>;
  type_id: number;
};
export type useCreateBatchMutation$variables = {
  params: create_batch_params;
};
export type useCreateBatchMutation$data = {
  readonly _sys_create_batch: {
    readonly submission_ids: ReadonlyArray<number>;
  };
};
export type useCreateBatchMutation = {
  response: useCreateBatchMutation$data;
  variables: useCreateBatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "create_batch_output",
    "kind": "LinkedField",
    "name": "_sys_create_batch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateBatchMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateBatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d87bb4a83a762a3a123ab97fd4e26625",
    "id": null,
    "metadata": {},
    "name": "useCreateBatchMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateBatchMutation(\n  $params: create_batch_params!\n) {\n  _sys_create_batch(params: $params) {\n    submission_ids\n  }\n}\n"
  }
};
})();

(node as any).hash = "81f8fdd9a1980b47d863ca2fa8ce6a73";

export default node;
