import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { MasterFileDetailRoute } from 'src/products/data-submission-portal/routes';
import {
  masterFileDetailPageTableSearch,
  masterFileDetailPageTableState,
  masterFileDetailTableFiltersState,
} from '../recoil';
import { getCOADetailCodesQuery } from './__generated__/getCOADetailCodesQuery.graphql';
import { getCOADetailLastestUpdateQuery } from './__generated__/getCOADetailLastestUpdateQuery.graphql';
import { getCOADetailNamesQuery } from './__generated__/getCOADetailNamesQuery.graphql';
import {
  getCOADetailQuery,
  sys_organization_master_chart_of_account_values_bool_exp,
} from './__generated__/getCOADetailQuery.graphql';

export const getCOADetail = graphQLSelector({
  query: graphql`
    query getCOADetailQuery(
      $where: sys_organization_master_chart_of_account_values_bool_exp = {}
      $limit: Int = 25
      $offset: Int = 0
    ) {
      sys_organization_master_chart_of_account_values(
        where: $where
        limit: $limit
        offset: $offset
      ) {
        master_chart_of_account_values_id
        account_code
        account_name
      }
      total: sys_organization_master_chart_of_account_values_aggregate(
        where: $where
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getCOADetailQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(MasterFileDetailRoute.routeParamSelector).master_file_id;
      const tableState = get(masterFileDetailPageTableState);

      // search input
      const hasSearch = (get(masterFileDetailPageTableSearch) ?? '').length > 0;
      const search = `%${get(masterFileDetailPageTableSearch) ?? ''}%`;
      const searchClause: sys_organization_master_chart_of_account_values_bool_exp =
        !hasSearch
          ? {}
          : {
              _or: [
                {
                  _and: [{ account_name: { _ilike: search } }],
                },
                {
                  _and: [{ account_code: { _ilike: search } }],
                },
              ],
            };

      // filters
      const filterClause: sys_organization_master_chart_of_account_values_bool_exp[] =
        [];
      const { account_code, account_description } = get(
        masterFileDetailTableFiltersState
      );
      if (account_code.length > 0) {
        filterClause.push({
          account_code: { _in: account_code },
        });
      }
      if (account_description.length > 0) {
        filterClause.push({
          account_name: { _in: account_description },
        });
      }

      return {
        where: {
          _and: [
            { _and: filterClause },
            { master_chart_of_account_id: { _eq: id } },
            searchClause,
          ],
        },
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
      };
    },
  mapResponse: (resp) => {
    return {
      data: resp.sys_organization_master_chart_of_account_values,
      total: resp.total,
    };
  },
});

export const getCodesList = graphQLSelector({
  query: graphql`
    query getCOADetailCodesQuery(
      $where: sys_organization_master_chart_of_account_values_bool_exp = {}
    ) {
      sys_organization_master_chart_of_account_values(
        distinct_on: account_code
        where: $where
        order_by: { account_code: asc }
      ) {
        account_code
      }
    }
  ` as GraphQLReturn<getCOADetailCodesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(MasterFileDetailRoute.routeParamSelector).master_file_id;

      return {
        where: { master_chart_of_account_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_master_chart_of_account_values.map(
      (value) => value.account_code
    );
  },
});

export const getNamesList = graphQLSelector({
  query: graphql`
    query getCOADetailNamesQuery(
      $where: sys_organization_master_chart_of_account_values_bool_exp = {}
    ) {
      sys_organization_master_chart_of_account_values(
        distinct_on: account_name
        where: $where
        order_by: { account_name: asc }
      ) {
        account_name
      }
    }
  ` as GraphQLReturn<getCOADetailNamesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(MasterFileDetailRoute.routeParamSelector).master_file_id;

      return {
        where: { master_chart_of_account_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_master_chart_of_account_values.map(
      (value) => value.account_name
    );
  },
});

export const getCOALatestUpdate = graphQLSelector({
  query: graphql`
    query getCOADetailLastestUpdateQuery(
      $where: sys_organization_master_chart_of_account_values_bool_exp = {}
    ) {
      sys_organization_master_chart_of_account_values(
        where: $where
        limit: 1
        order_by: { updated_at_datetime: desc }
      ) {
        updated_at_datetime
      }
    }
  ` as GraphQLReturn<getCOADetailLastestUpdateQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(MasterFileDetailRoute.routeParamSelector).master_file_id;

      return {
        where: { master_chart_of_account_id: { _eq: id } },
      };
    },
  mapResponse: (resp) => {
    return resp.sys_organization_master_chart_of_account_values[0]
      .updated_at_datetime;
  },
});
