/**
 * @generated SignedSource<<722ae045c47b14ff2ef1ba548d6def7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queriesGetPropertyBatchDatasetIdQuery$variables = {
  dataset_id: number;
  property_id: number;
  submission_id: number;
};
export type queriesGetPropertyBatchDatasetIdQuery$data = {
  readonly sys_property_batch_datasets: ReadonlyArray<{
    readonly dataset: {
      readonly dataset_slug: string;
    } | null;
    readonly property_batch: {
      readonly property: {
        readonly property_code: string;
      };
      readonly submission: {
        readonly submission_name: string;
      };
    };
    readonly property_batch_dataset_id: number;
  }>;
};
export type queriesGetPropertyBatchDatasetIdQuery = {
  response: queriesGetPropertyBatchDatasetIdQuery$data;
  variables: queriesGetPropertyBatchDatasetIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dataset_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submission_id"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "dataset_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "dataset_id"
              },
              {
                "kind": "Literal",
                "name": "is_tsa_enabled",
                "value": {
                  "_eq": true
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "property_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property_id"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "submission_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_datasets",
    "kind": "LinkedField",
    "name": "sys_property_batch_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submissions",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets",
        "kind": "LinkedField",
        "name": "dataset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queriesGetPropertyBatchDatasetIdQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queriesGetPropertyBatchDatasetIdQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "461cbd1ed28ab806eadf523c8a18697f",
    "id": null,
    "metadata": {},
    "name": "queriesGetPropertyBatchDatasetIdQuery",
    "operationKind": "query",
    "text": "query queriesGetPropertyBatchDatasetIdQuery(\n  $submission_id: Int!\n  $property_id: Int!\n  $dataset_id: Int!\n) {\n  sys_property_batch_datasets(where: {property_batch: {submission_id: {_eq: $submission_id}, property_id: {_eq: $property_id}}, dataset: {dataset_id: {_eq: $dataset_id}, is_tsa_enabled: {_eq: true}}}, limit: 1) {\n    property_batch_dataset_id\n    property_batch {\n      property {\n        property_code\n      }\n      submission {\n        submission_name\n      }\n    }\n    dataset {\n      dataset_slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f14fb13502efb65babb49da257157894";

export default node;
