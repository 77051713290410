import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { addEditOptionsExludedPropertiesQuery } from './__generated__/addEditOptionsExludedPropertiesQuery.graphql';
import { MappingDetailRoute } from 'src/products/data-submission-portal/routes';

export const getExcludedPropertiesOptions = graphQLSelector({
  query: graphql`
    query addEditOptionsExludedPropertiesQuery($id: Int) {
      sys_mapping_sets(where: { mapping_set_id: { _eq: $id } }) {
        provider {
          properties(where: { is_active: { _eq: true } }) {
            entity_id
            entity_name
            property_code
            property_name
          }
        }
      }
    }
  ` as GraphQLReturn<addEditOptionsExludedPropertiesQuery>,
  mapResponse: (resp) => {
    return resp.sys_mapping_sets[0]?.provider.properties || [];
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        id: get(MappingDetailRoute.routeParamSelector).mapping_set_id,
      };
    },
});
