import React, { useState, useMemo } from 'react';
import {
  Box,
  PageContainer,
  PageHeader,
  PageContent,
  styled,
  Panel,
  Grid,
  SearchInput,
  MRT_RowSelectionState,
  Fade,
  Button,
  useConfirmationModal,
  ConfirmationModal,
  TogglesIcon,
  DatasetsIcon,
  WarningIcon,
  Alert,
  AlertTitle,
  ProviderGroupCircleIcon,
} from '@cherre-frontend/ui';
import { PropertiesPanel } from './Panels/PropertiesPanel/PropertiesPanel';
import {
  useCherreStateDebounced,
  useCherreValue,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import { Filters } from './Panels/PropertiesPanel/components/Filters';
import {
  propertiesTableHasData,
  propertiesPageTableSearch,
} from './Panels/PropertiesPanel/recoil';
import { UploadPropertyButton } from './Panels/PropertiesPanel/components/UploadPropertyButton';
import { AddPropertyButton } from './Panels/PropertiesPanel/components/AddPropertyButton';
import AssignUsersButton from './Panels/PropertiesPanel/components/AssignUsersButton';
import AssignPreparersDialog from '../../components/dialogs/AssignPreparersDialog';
import AssignReviewersDialog from '../../components/dialogs/AssignReviewersDialog';
import ManageUsersDialog from '../../components/dialogs/ManageUsersDialog/ManageUsersDialog';
import AssignMappingDialog from '../../components/AssignMappingDialog';
import AssignDatasetsDialog from '../../components/AssignDatasetsDialog';
import { useOpenAssignMapping } from './Panels/PropertiesPanel/hooks/useOpenAssignMapping';
import {
  assignDatasetsDialogOpenState,
  assignProviderDialogOpenState,
  propertiesTableSelectedProvider,
} from '../../recoil/dialog';
import AssignProviderDialog from './Panels/PropertiesPanel/components/AssignProviderDialog';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SelectionButton = styled(Button)`
  > span {
    display: flex;
  }
`;

export const WarningBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.brown.light};
  color: ${({ theme }) => theme.accents.brown.main};
  .MuiAlert-message {
    padding: 0px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

export const AlertIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.accents.brown.main};
`;

const PropertiesPage: React.FC = () => {
  const [search, , setSearch] = useCherreStateDebounced(
    propertiesPageTableSearch
  );

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const openAssignMappingDialogModalWithTitles = useOpenAssignMapping();

  const setAssignProviderDialogOpenState = useCherreSetState(
    assignProviderDialogOpenState
  );

  const setAssignDatasetsDialogOpenState = useCherreSetState(
    assignDatasetsDialogOpenState
  );

  const hasSelectedRows = useMemo(
    () => Object.keys(rowSelection).length > 0,
    [rowSelection]
  );

  const totalPropertiesSelected = useMemo(() => {
    return Object.keys(rowSelection).length;
  }, [rowSelection]);

  const { modalProps, confirm } = useConfirmationModal();

  const tableHasData = useCherreValue(propertiesTableHasData);

  const selectedProviders = useCherreValue(propertiesTableSelectedProvider);

  const hasOneProviderSelected = !!selectedProviders;

  return (
    <>
      <ConfirmationModal {...modalProps} />
      <PageHeader title='Properties'>
        {tableHasData && (
          <Grid
            container
            direction='row'
            justifyContent={'flex-end'}
            spacing={2}
            width={'auto'}
          >
            <Grid item>
              <AddPropertyButton />
            </Grid>
            <Grid item>
              <UploadPropertyButton mode='bulk' confirmFunction={confirm} />
            </Grid>
          </Grid>
        )}
      </PageHeader>
      <PageContent>
        <Panel id='assign-preparers-dialog-panel-properties'>
          <AssignPreparersDialog setRowSelection={setRowSelection} />
          <AssignReviewersDialog setRowSelection={setRowSelection} />
        </Panel>
        <Panel id='manage-users-dialog-panel-properties'>
          <ManageUsersDialog setRowSelection={setRowSelection} />
        </Panel>
        <Panel id='assign-mapping-properties-page-modal'>
          <AssignMappingDialog
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </Panel>
        <Panel id='assign-datasets-properties-page-modal'>
          <AssignDatasetsDialog
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </Panel>
        <Panel id='assign-provider-properties-page-modal'>
          <AssignProviderDialog
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </Panel>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid
            container
            item
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            gap='10px'
            style={{
              width: 'unset',
            }}
          >
            <SearchInput
              value={search ?? ''}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Filters hasSelectedRows={hasSelectedRows} />
            <Grid item sx={{ display: hasSelectedRows ? 'flex' : 'none' }}>
              <Fade in={hasSelectedRows}>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justifyItems={'center'}
                  gap={1}
                >
                  <SelectionButton
                    variant='outlined'
                    color='primary'
                    size='medium'
                    onClick={() => setAssignProviderDialogOpenState(true)}
                  >
                    <ProviderGroupCircleIcon
                      sx={{
                        height: '20px',
                        width: '20px',
                        marginTop: '2px',
                        marginRight: '2px',
                      }}
                    />
                    Assign to Provider
                  </SelectionButton>
                  <AssignUsersButton disabled={!hasOneProviderSelected} />

                  <SelectionButton
                    variant='outlined'
                    color='primary'
                    size='medium'
                    onClick={() =>
                      openAssignMappingDialogModalWithTitles(
                        'Assign COA Mappings',
                        `${totalPropertiesSelected} Properties Selected`
                      )
                    }
                    disabled={!hasOneProviderSelected}
                  >
                    <TogglesIcon sx={{ height: 16, alignSelf: 'center' }} />
                    Assign Mappings
                  </SelectionButton>
                  <SelectionButton
                    variant='outlined'
                    color='primary'
                    size='medium'
                    onClick={() => setAssignDatasetsDialogOpenState(true)}
                  >
                    <DatasetsIcon sx={{ height: 16, alignSelf: 'center' }} />
                    Assign Datasets
                  </SelectionButton>
                </Grid>
              </Fade>
            </Grid>
          </Grid>
          {!hasOneProviderSelected && hasSelectedRows && (
            <Box
              sx={{ width: '100%', marginTop: '12px', marginBottom: '-12px' }}
            >
              <WarningBanner severity='warning' icon={<AlertIcon />}>
                <AlertTitle>Attention!</AlertTitle>
                Properties from different providers cannot be assigned users or
                mappings. Select properties from a single provider to access
                those options.
              </WarningBanner>
            </Box>
          )}
        </Grid>
        <PanelStyled id='properties-page-properties'>
          <PropertiesPanel
            search={search}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </PanelStyled>
      </PageContent>
    </>
  );
};

const PropertiesPageWrapper: React.FC = () => {
  return (
    <PageContainer id='PropertiesPage'>
      <PropertiesPage />
    </PageContainer>
  );
};

PropertiesPageWrapper.displayName = 'PropertiesPageWrapper';

export default PropertiesPageWrapper;
