import { string, withDefault } from '@recoiljs/refine';
import { SingleFileValidationSummaryRoute } from '../../routes';
import {
  ReportTableStateRefine,
  createReportTableStateSelectors,
} from '../../components/Reports';
import {
  refine,
  graphQLSelector,
  GraphQLReturn,
  QueryResult,
  selector,
  DefaultValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { recoilValidationsInfoQuery } from './__generated__/recoilValidationsInfoQuery.graphql';
import { Report } from '../../components/Reports/types';

export type RawValidation =
  recoilValidationsInfoQuery['response']['sys_property_batch_dataset_validations'][0];

export const validationsInfo = graphQLSelector({
  query: graphql`
    query recoilValidationsInfoQuery($dataset_id: Int!) {
      sys_property_batch_dataset_validations(
        where: {
          property_batch_dataset: {
            property_batch_dataset_id: { _eq: $dataset_id }
          }
        }
        order_by: { dataset_validation_rule: { validation_label: asc } }
      ) {
        is_valid
        validation_mode
        property_batch_dataset_id
        property_batch_dataset_validation_id
        dataset_validation_rule {
          validation_slug
          validation_label
          validation_description
          banner_config
          report {
            report_slug
          }
        }
        property_batch_dataset {
          property_batch {
            property {
              entity_id
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilValidationsInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(SingleFileValidationSummaryRoute.routeParamSelector),
  mapResponse: (rawResponse) =>
    rawResponse.sys_property_batch_dataset_validations,
});

export type ValidationInfo = QueryResult<typeof validationsInfo>[number];

export const reportsState = selector<{
  reports: Report[];
  property_batch_dataset_id: number;
}>({
  key: 'single-file-validations/reports',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const validations = get(validationsInfo());
      const reports = validations
        .filter((v) => !!v.dataset_validation_rule.report)
        .map((v) => ({
          label: v.dataset_validation_rule.validation_label,
          slug: v.dataset_validation_rule.report!.report_slug,
          disabled: false,
        }));
      return {
        reports,
        property_batch_dataset_id: validations[0].property_batch_dataset_id,
      };
    },
});

export const modalValidationSlugState =
  SingleFileValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'modal',
    refine: withDefault(string(), ''),
  });

export const modalValidationState = selector({
  key: 'single-file-validations/modal-validation-state',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedValidationSlug = get(modalValidationSlugState);
      if (!selectedValidationSlug) {
        return undefined;
      }
      const validations = get(validationsInfo());
      return validations.find(
        (v) =>
          v.dataset_validation_rule.validation_slug === selectedValidationSlug
      );
    },
});

export const modalReportSlugState = selector({
  key: 'single-file-validations/modal-report-state',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedValidation = get(modalValidationState);
      if (!selectedValidation) {
        return '';
      }
      return (
        selectedValidation.dataset_validation_rule.report?.report_slug || ''
      );
    },
  set:
    () =>
    ({ set, get }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(modalValidationSlugState, '');
        return;
      }

      const validations = get(validationsInfo());
      const selectedReport = validations.find(
        (v) => v.dataset_validation_rule.report?.report_slug === newValue
      );
      if (!selectedReport) {
        set(modalValidationSlugState, '');
        return;
      }

      set(
        modalValidationSlugState,
        selectedReport.dataset_validation_rule.validation_slug
      );
    },
});

export const modalSearchState =
  SingleFileValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'search',
    refine: withDefault(string(), ''),
  });

export const modalStatusFilterState =
  SingleFileValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'status',
    refine: withDefault(
      refine.stringLiterals([
        'Any Validation Status',
        'Failed',
        'Passed',
      ] as const),
      'Any Validation Status'
    ),
  });

export const modalTablesState =
  SingleFileValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'tables',
    refine: ReportTableStateRefine,
  });

export const reportSelectors = createReportTableStateSelectors(
  modalReportSlugState,
  modalTablesState,
  modalSearchState
);
