/**
 * @generated SignedSource<<f1cc889471acda3a633948d7ec3a3aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getProviderUsersTotalQuery$variables = {
  provider_id?: number | null;
  search?: string | null;
};
export type getProviderUsersTotalQuery$data = {
  readonly total: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getProviderUsersTotalQuery = {
  response: getProviderUsersTotalQuery$data;
  variables: getProviderUsersTotalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "provider_id"
},
v1 = {
  "defaultValue": "%",
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_ilike",
    "variableName": "search"
  }
],
v3 = [
  {
    "alias": "total",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "fields": (v2/*: any*/),
                            "kind": "ObjectValue",
                            "name": "first_name"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.0"
                      },
                      {
                        "fields": [
                          {
                            "fields": (v2/*: any*/),
                            "kind": "ObjectValue",
                            "name": "last_name"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.1"
                      },
                      {
                        "fields": [
                          {
                            "fields": (v2/*: any*/),
                            "kind": "ObjectValue",
                            "name": "email"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.2"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "_or"
                  }
                ],
                "kind": "ObjectValue",
                "name": "sakura_user"
              }
            ],
            "kind": "ObjectValue",
            "name": "_and"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "provider_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_users_aggregate",
    "kind": "LinkedField",
    "name": "sys_users_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_users_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getProviderUsersTotalQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "getProviderUsersTotalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "66cf2fe6786efb8c1b52f262a4c12a9a",
    "id": null,
    "metadata": {},
    "name": "getProviderUsersTotalQuery",
    "operationKind": "query",
    "text": "query getProviderUsersTotalQuery(\n  $search: String = \"%\"\n  $provider_id: Int\n) {\n  total: sys_users_aggregate(where: {provider_id: {_eq: $provider_id}, _and: {sakura_user: {_or: [{first_name: {_ilike: $search}}, {last_name: {_ilike: $search}}, {email: {_ilike: $search}}]}}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7706e78dea808fffd452115830658e7c";

export default node;
