import React from 'react';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Typography } from '@cherre-frontend/ui';
import { selectedFinancialReviewerUsers } from '../../../../recoil/dialog';
import { UserRoleSelect } from '../../components/UserRoleSelect';
import { useUserRoleSelect } from '../../hooks/useUserRoleSelect';

const FinancialReviewersForm: React.FC = () => {
  const [selectedFinancialReviewers, setSelectedFinancialReviewers] =
    useCherreState(selectedFinancialReviewerUsers);

  if (!selectedFinancialReviewers) {
    return null;
  }

  const { userRoleSelectPropsReviewers, showApproverPlusMessage } =
    useUserRoleSelect(
      selectedFinancialReviewers,
      setSelectedFinancialReviewers,
      true,
      'Financial'
    );

  return (
    <>
      <Typography variant='h6'>Financial Submissions</Typography>
      {userRoleSelectPropsReviewers.map((props) => (
        <UserRoleSelect key={props.level} {...props} />
      ))}
      {showApproverPlusMessage && (
        <Typography variant='body2' color='grey.600' align='center'>
          An Approver Plus must be the last reviewer in the chain.
        </Typography>
      )}
    </>
  );
};

export default FinancialReviewersForm;
