/**
 * @generated SignedSource<<5ad26f000f9012299fae6232e8d35969>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type create_reminder_params = {
  author_id?: number | null;
  organization_id?: number | null;
  package_report_id?: number | null;
  property_batch_id?: number | null;
  property_id?: number | null;
  submission_id?: number | null;
  text?: string | null;
};
export type recoilCreateReminderMutation$variables = {
  params: create_reminder_params;
};
export type recoilCreateReminderMutation$data = {
  readonly _sys_create_reminder: {
    readonly reminder_ids: ReadonlyArray<number>;
  };
};
export type recoilCreateReminderMutation = {
  response: recoilCreateReminderMutation$data;
  variables: recoilCreateReminderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "create_reminder_output",
    "kind": "LinkedField",
    "name": "_sys_create_reminder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reminder_ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilCreateReminderMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilCreateReminderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ad8098d53d5ddf5e9041c2ba414da53",
    "id": null,
    "metadata": {},
    "name": "recoilCreateReminderMutation",
    "operationKind": "mutation",
    "text": "mutation recoilCreateReminderMutation(\n  $params: create_reminder_params!\n) {\n  _sys_create_reminder(params: $params) {\n    reminder_ids\n  }\n}\n"
  }
};
})();

(node as any).hash = "340378c218afae37b34ebc1ef1dbb1bc";

export default node;
