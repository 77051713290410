import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { uploadMasterFileFormState } from '../recoil';
import { getDuplicatedAccountTreeQuery } from './__generated__/getDuplicatedAccountTreeQuery.graphql';

export const getDuplicatedAccountTree = graphQLSelector({
  query: graphql`
    query getDuplicatedAccountTreeQuery($name: String) {
      sys_organization_account_trees(
        where: { account_tree_name: { _ilike: $name } }
        limit: 1
      ) {
        organization_account_tree_id
      }
    }
  ` as GraphQLReturn<getDuplicatedAccountTreeQuery>,
  mapResponse: (response) => {
    return response.sys_organization_account_trees.length > 0;
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        name: get(uploadMasterFileFormState).name,
      };
    },
});
