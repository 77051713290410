import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getSubmissionQuery } from './__generated__/getSubmissionQuery.graphql';

export const $getSubmission = graphQLSelector({
  query: graphql`
    query getSubmissionQuery($dataset_id: Int!, $submission_id: Int!) {
      sys_submissions(
        where: { submission_id: { _eq: $submission_id } }
        limit: 1
      ) {
        property_batches(
          where: {
            property_batch_datasets: { dataset_id: { _eq: $dataset_id } }
          }
        ) {
          property_id
        }
      }
    }
  ` as GraphQLReturn<getSubmissionQuery>,
  mapVariables:
    ({
      dataset_id,
      submission_id,
    }: {
      dataset_id: number;
      submission_id: number;
    }) =>
    () => ({
      dataset_id,
      submission_id,
    }),
  mapResponse: (resp) => resp.sys_submissions[0].property_batches,
});
