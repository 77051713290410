/**
 * @generated SignedSource<<7c8e61a339d20c97a3452f75f49dd82b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getAllPropertiesTotalQuery$variables = {
  provider_id: number;
  search?: string | null;
};
export type getAllPropertiesTotalQuery$data = {
  readonly total: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getAllPropertiesTotalQuery = {
  response: getAllPropertiesTotalQuery$data;
  variables: getAllPropertiesTotalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  },
  {
    "defaultValue": "%",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_ilike",
    "variableName": "search"
  }
],
v2 = {
  "_is_null": false
},
v3 = [
  {
    "alias": "total",
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_neq",
                                "variableName": "provider_id"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "provider_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.0"
                      },
                      {
                        "kind": "Literal",
                        "name": "_or.1",
                        "value": {
                          "provider_id": {
                            "_is_null": true
                          }
                        }
                      }
                    ],
                    "kind": "ListValue",
                    "name": "_or"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.0"
              },
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "items": [
                              {
                                "fields": [
                                  {
                                    "fields": (v1/*: any*/),
                                    "kind": "ObjectValue",
                                    "name": "property_name"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "_and.0"
                              },
                              {
                                "kind": "Literal",
                                "name": "_and.1",
                                "value": {
                                  "property_name": (v2/*: any*/)
                                }
                              }
                            ],
                            "kind": "ListValue",
                            "name": "_and"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.0"
                      },
                      {
                        "fields": [
                          {
                            "fields": (v1/*: any*/),
                            "kind": "ObjectValue",
                            "name": "entity_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.1"
                      },
                      {
                        "fields": [
                          {
                            "items": [
                              {
                                "fields": [
                                  {
                                    "fields": (v1/*: any*/),
                                    "kind": "ObjectValue",
                                    "name": "address"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "_and.0"
                              },
                              {
                                "kind": "Literal",
                                "name": "_and.1",
                                "value": {
                                  "address": (v2/*: any*/)
                                }
                              }
                            ],
                            "kind": "ListValue",
                            "name": "_and"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.2"
                      },
                      {
                        "fields": [
                          {
                            "items": [
                              {
                                "fields": [
                                  {
                                    "fields": (v1/*: any*/),
                                    "kind": "ObjectValue",
                                    "name": "fund"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "_and.0"
                              },
                              {
                                "kind": "Literal",
                                "name": "_and.1",
                                "value": {
                                  "fund": (v2/*: any*/)
                                }
                              }
                            ],
                            "kind": "ListValue",
                            "name": "_and"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.3"
                      },
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "_like",
                                    "variableName": "search"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "provider_name"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "provider"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.4"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "_or"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.1"
              }
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_flattened_union_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAllPropertiesTotalQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAllPropertiesTotalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "61531ba56c57e63058d8d10b2c928560",
    "id": null,
    "metadata": {},
    "name": "getAllPropertiesTotalQuery",
    "operationKind": "query",
    "text": "query getAllPropertiesTotalQuery(\n  $provider_id: Int!\n  $search: String = \"%\"\n) {\n  total: sys_properties_flattened_union_aggregate(where: {_and: [{_or: [{provider_id: {_neq: $provider_id}}, {provider_id: {_is_null: true}}]}, {_or: [{_and: [{property_name: {_ilike: $search}}, {property_name: {_is_null: false}}]}, {entity_id: {_ilike: $search}}, {_and: [{address: {_ilike: $search}}, {address: {_is_null: false}}]}, {_and: [{fund: {_ilike: $search}}, {fund: {_is_null: false}}]}, {provider: {provider_name: {_like: $search}}}]}]}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bc934a20144a910f6fedd7beaa85590";

export default node;
