import React from 'react';
import {
  CloseIcon,
  CheckIcon,
  useTheme,
  RefreshIcon,
} from '@cherre-frontend/ui';
import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';

export const usePropertyStatus = (role_status: string | null) => {
  const theme = useTheme();

  switch (role_status) {
    case 'Approved':
      return {
        label: PropertyBatchStage.APPROVED.label,
        icon: <CheckIcon />,
        color: {
          text: theme.palette.grey[50],
          bg: theme.accents.positive.main,
        },
      };
    case 'Rejected':
      return {
        label: PropertyBatchStage.REJECTED.label,
        icon: <CloseIcon />,
        color: {
          text: theme.palette.grey[50],
          bg: theme.accents.error.main,
        },
      };
    case 'In Preparation':
      return {
        label: PropertyBatchStage.IN_PREPARATION.label,
        icon: <RefreshIcon />,
        color: {
          text: theme.accents.info.main,
          bg: theme.accents.info.light,
        },
      };
    case 'Pending':
      return {
        label: PropertyBatchStage.PENDING.label,
        icon: undefined,
        color: {
          text: theme.accents.deals.main,
          bg: theme.accents.warning.light,
        },
      };
    case 'Submitted':
      return {
        label: 'Submitted',
        icon: <CheckIcon />,
        color: {
          text: theme.palette.grey[50],
          bg: theme.accents.positive.main,
        },
      };
    default:
      throw new Error(`Unknown role status: ${role_status}`);
  }
};
export const usePropertyGroupStatus = (role_status: (string | null)[]) => {
  const uniqueStatus = new Set(role_status);
  // if every source property has the same status, that is the group status
  if (uniqueStatus.size === 1) {
    return usePropertyStatus(role_status[0]);
  }
  return usePropertyStatus('Pending');
};
