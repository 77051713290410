/**
 * @generated SignedSource<<50eb23cc2ed499e53b31e82fb906c27a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyOpenedBatchesQuery$variables = {
  property_id: number;
};
export type getPropertyOpenedBatchesQuery$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly submission_id: number;
    readonly submission_name: string;
  }>;
};
export type getPropertyOpenedBatchesQuery = {
  response: getPropertyOpenedBatchesQuery$data;
  variables: getPropertyOpenedBatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "submission_name": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_closed",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Literal",
                    "name": "is_active",
                    "value": {
                      "_eq": true
                    }
                  },
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "property_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "property_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batches"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyOpenedBatchesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertyOpenedBatchesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98fc69cc7ddd76a1aaa918510f871080",
    "id": null,
    "metadata": {},
    "name": "getPropertyOpenedBatchesQuery",
    "operationKind": "query",
    "text": "query getPropertyOpenedBatchesQuery(\n  $property_id: Int!\n) {\n  sys_submissions(where: {is_closed: {_eq: false}, property_batches: {property: {is_active: {_eq: true}, property_id: {_eq: $property_id}}}}, order_by: {submission_name: asc}) {\n    submission_id\n    submission_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "7af364a41725e2f4f461f36500b2fa03";

export default node;
