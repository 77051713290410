/**
 * @generated SignedSource<<fbb7aeb61571c3a003b75ab5647abdf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyBatchDatasetIsApproverPlusQuery$variables = {
  approver_plus_slugs: ReadonlyArray<string>;
  property_batch_dataset_id: number;
  submission_type_id: number;
};
export type getPropertyBatchDatasetIsApproverPlusQuery$data = {
  readonly sys_property_batch_datasets: ReadonlyArray<{
    readonly property_batch_dataset_id: number;
  }>;
};
export type getPropertyBatchDatasetIsApproverPlusQuery = {
  response: getPropertyBatchDatasetIsApproverPlusQuery$data;
  variables: getPropertyBatchDatasetIsApproverPlusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "approver_plus_slugs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_batch_dataset_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submission_type_id"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_in",
                                "variableName": "approver_plus_slugs"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "property_role_slug"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "property_role"
                      },
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "submission_type_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "submission_type_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "properties_roles_users"
                  }
                ],
                "kind": "ObjectValue",
                "name": "property"
              },
              {
                "kind": "Literal",
                "name": "submission",
                "value": {
                  "is_closed": {
                    "_neq": true
                  }
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_datasets",
    "kind": "LinkedField",
    "name": "sys_property_batch_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_dataset_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyBatchDatasetIsApproverPlusQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "getPropertyBatchDatasetIsApproverPlusQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7d663b182a0cfea4a44ef100dd50aca6",
    "id": null,
    "metadata": {},
    "name": "getPropertyBatchDatasetIsApproverPlusQuery",
    "operationKind": "query",
    "text": "query getPropertyBatchDatasetIsApproverPlusQuery(\n  $property_batch_dataset_id: Int!\n  $submission_type_id: Int!\n  $approver_plus_slugs: [String!]!\n) {\n  sys_property_batch_datasets(where: {property_batch_dataset_id: {_eq: $property_batch_dataset_id}, property_batch: {submission: {is_closed: {_neq: true}}, property: {properties_roles_users: {property_role: {property_role_slug: {_in: $approver_plus_slugs}}, submission_type_id: {_eq: $submission_type_id}}}}}) {\n    property_batch_dataset_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5c15b9c56f3501e87417da6c2279613";

export default node;
