/**
 * @generated SignedSource<<2f74449ffe3a2d16f7a95aba68eb55ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getSubmissionsProvidersFilterSubscription$variables = {};
export type getSubmissionsProvidersFilterSubscription$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly provider_name: string;
  }>;
};
export type getSubmissionsProvidersFilterSubscription = {
  response: getSubmissionsProvidersFilterSubscription$data;
  variables: getSubmissionsProvidersFilterSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "provider_name"
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "provider_name": "asc"
        }
      },
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "submissions": {}
        }
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_name",
        "storageKey": null
      }
    ],
    "storageKey": "sys_providers(distinct_on:\"provider_name\",order_by:{\"provider_name\":\"asc\"},where:{\"submissions\":{}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSubmissionsProvidersFilterSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSubmissionsProvidersFilterSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "99709d789a173999c66a17b5a58982ec",
    "id": null,
    "metadata": {},
    "name": "getSubmissionsProvidersFilterSubscription",
    "operationKind": "subscription",
    "text": "subscription getSubmissionsProvidersFilterSubscription {\n  sys_providers(distinct_on: provider_name, order_by: {provider_name: asc}, where: {submissions: {}}) {\n    provider_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "78186b0edbcd0a323c197d605098b023";

export default node;
