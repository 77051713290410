/**
 * @generated SignedSource<<87f92fba97469535b986d30126a58a73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getUserListQuery$variables = {
  id: number;
  provider_id: number;
};
export type getUserListQuery$data = {
  readonly sys_users: ReadonlyArray<{
    readonly sakura_user: {
      readonly email: string;
      readonly first_name: string | null;
      readonly last_name: string | null;
    } | null;
    readonly user_id: number;
  }>;
};
export type getUserListQuery = {
  response: getUserListQuery$data;
  variables: getUserListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": [
          {
            "sakura_user": {
              "first_name": "asc"
            }
          },
          {
            "sakura_user": {
              "last_name": "asc"
            }
          }
        ]
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "provider_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "provider_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_or.0"
              },
              {
                "kind": "Literal",
                "name": "_or.1",
                "value": {
                  "provider_id": {
                    "_is_null": true
                  }
                }
              }
            ],
            "kind": "ListValue",
            "name": "_or"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "organization_id"
          },
          {
            "kind": "Literal",
            "name": "sakura_user",
            "value": {
              "disabled": {
                "_eq": false
              }
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_users",
    "kind": "LinkedField",
    "name": "sys_users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sakura_user",
        "kind": "LinkedField",
        "name": "sakura_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getUserListQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getUserListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d1aeda9c2328bf27cb33fb329d2f4e70",
    "id": null,
    "metadata": {},
    "name": "getUserListQuery",
    "operationKind": "query",
    "text": "query getUserListQuery(\n  $id: Int!\n  $provider_id: Int!\n) {\n  sys_users(where: {organization_id: {_eq: $id}, _or: [{provider_id: {_eq: $provider_id}}, {provider_id: {_is_null: true}}], sakura_user: {disabled: {_eq: false}}, is_active: {_eq: true}}, order_by: [{sakura_user: {first_name: asc}}, {sakura_user: {last_name: asc}}]) {\n    user_id\n    sakura_user {\n      first_name\n      last_name\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4670840979e69e3d73052d29045f9bde";

export default node;
