import React from 'react';
import { Tooltip, Checkbox } from '@cherre-frontend/ui';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';

import {
  canCardBeSelectedState,
  selectedCardState,
} from '../../PropertyBatches/SubmissionBulkActions/recoil';

export const BulkActionsCheckbox: React.FC<{ id: number }> = ({ id }) => {
  const [selected, setSelected] = useCherreState(selectedCardState(id));

  const checkboxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelected(checked);
  };

  const canCardBeSelected = useCherreValue(canCardBeSelectedState(id));

  return (
    <Tooltip
      title={
        !canCardBeSelected
          ? 'This property submission is not eligible for selection.'
          : ''
      }
    >
      <span>
        <Checkbox
          checked={selected}
          onClick={(e) => e.stopPropagation()}
          onChange={checkboxHandler}
          disabled={!canCardBeSelected}
          sx={{ padding: 0 }}
        />
      </span>
    </Tooltip>
  );
};
