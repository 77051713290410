/**
 * @generated SignedSource<<115607c5a694aafd87612b3a2e053d15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type querySummaryInfoQuery$variables = {
  dataset_id: number;
};
export type querySummaryInfoQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly property: {
      readonly property_id: number;
      readonly property_name: string | null;
    };
    readonly property_batch_datasets: ReadonlyArray<{
      readonly dataset: {
        readonly dataset_label: string;
      } | null;
      readonly property_batch_dataset_id: number;
    }>;
    readonly property_batch_stage_id: number;
    readonly submission: {
      readonly submission_name: string;
    };
    readonly submission_id: number;
  }>;
};
export type querySummaryInfoQuery = {
  response: querySummaryInfoQuery$data;
  variables: querySummaryInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_eq",
        "variableName": "dataset_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "property_batch_dataset_id"
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "property_batch_datasets"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_stage_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "sys_property_batch_datasets",
        "kind": "LinkedField",
        "name": "property_batch_datasets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_batch_dataset_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_datasets",
            "kind": "LinkedField",
            "name": "dataset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataset_label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "querySummaryInfoQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "querySummaryInfoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e134ba8bd6b276bbfb2caad33ea02807",
    "id": null,
    "metadata": {},
    "name": "querySummaryInfoQuery",
    "operationKind": "query",
    "text": "query querySummaryInfoQuery(\n  $dataset_id: Int!\n) {\n  sys_property_batches(where: {property_batch_datasets: {property_batch_dataset_id: {_eq: $dataset_id}}}) {\n    property_batch_stage_id\n    submission_id\n    submission {\n      submission_name\n    }\n    property {\n      property_id\n      property_name\n    }\n    property_batch_datasets(where: {property_batch_dataset_id: {_eq: $dataset_id}}) {\n      property_batch_dataset_id\n      dataset {\n        dataset_label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5605e98dcf5a64a860eb5d91374a8bb0";

export default node;
