export const WORKFLOW = {
  PREPARER_SUBMIT: 'preparer_submit',
  REVIEWER_SUBMIT: 'reviewer_submit',
  REVIEWER_APPROVE: 'reviewer_approve',
  REVIEWER_REJECT: 'reviewer_reject',
  BULK_REVIEWER_SUBMIT: 'reviewer_bulk_submit',
  BULK_REVIEWER_APPROVE: 'reviewer_bulk_approve',
  // non apply actions
  REVIEWER_IS_READY_FOR_COMBINED_TOGGLE:
    'reviewer_is_ready_for_combined_toggle',
};
