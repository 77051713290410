import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilGetManyToOneChildPropertiesQuery } from './__generated__/recoilGetManyToOneChildPropertiesQuery.graphql';
import { recoilCanAssignToProviderQuery } from './__generated__/recoilCanAssignToProviderQuery.graphql';
import { selectedPropertiesToAssign } from 'src/products/data-submission-portal/recoil/dialog';

const query = graphql`
  query recoilGetManyToOneChildPropertiesQuery(
    $propertyIds: [Int!]!
    $ignorePropertyIds: [Int!]!
  ) {
    sys_properties_flattened_union(
      where: {
        parent_property_id: { _in: $propertyIds }
        property_id: { _nin: $ignorePropertyIds }
      }
    ) {
      property_code
      property_id
      property_name
      organization_id
      property_mapping
      parent_property_id
      entity_id
      provider {
        provider_id
        provider_name
      }
    }
  }
` as GraphQLReturn<recoilGetManyToOneChildPropertiesQuery>;

export const getManyToOneChildPropertiesWithParams = graphQLSelector({
  query,
  mapResponse: (response) => response.sys_properties_flattened_union,
});

export const getManyToOneChildProperties = graphQLSelector({
  query,
  mapVariables:
    () =>
    ({ get }) => {
      const selectedProperties = get(selectedPropertiesToAssign);
      const propertyIds =
        (selectedProperties
          ?.map((p) => p.parent_property_id)
          .filter(Boolean) as number[]) ?? [];

      return {
        propertyIds,
        ignorePropertyIds: selectedProperties?.map((p) => p.property_id) ?? [],
      };
    },
  mapResponse: (response) => response.sys_properties_flattened_union,
});

export const canAssignToProviderQuery = graphQLSelector({
  query: graphql`
    query recoilCanAssignToProviderQuery(
      $where: sys_properties_flattened_union_bool_exp!
    ) {
      sys_properties_flattened_union(where: $where) {
        property_id
      }
    }
  ` as GraphQLReturn<recoilCanAssignToProviderQuery>,
  mapVariables:
    ({
      properties,
      provider_id,
    }: {
      properties: { entity_id: string; property_code: string }[];
      provider_id: number;
    }) =>
    () => ({
      where: {
        provider_id: { _eq: provider_id },
        _or: properties.map((p) => ({
          entity_id: { _eq: p.entity_id },
          property_code: { _eq: p.property_code },
        })),
      },
    }),
  mapResponse: (response) =>
    response.sys_properties_flattened_union.length === 0,
});
