import { SafeParseReturnType } from 'zod';
import moment, { Moment, unitOfTime } from 'moment';
import { CreateBatchInfo } from './schema';

type Result = SafeParseReturnType<CreateBatchInfo, CreateBatchInfo>;

export const intervalToUnitOfTime = (
  interval: string
): unitOfTime.DurationConstructor => {
  switch (interval) {
    case 'Monthly':
    case 'monthly':
      return 'month';
    case 'Quarterly':
    case 'quarterly':
      return 'quarter';
    default:
      throw new Error(`Invalid interval ${interval}`);
  }
};

export const formatErrorMessage = (result: Result) => () => {
  if (result.success) {
    return '';
  }
  let error = 'Please select the following required fields: ';
  error += result.error.issues.map((i) => i.message).join(', ');
  return error;
};

export const formatDueDate =
  ({
    submission_interval,
    due_date,
    submission_period,
  }: Partial<CreateBatchInfo>) =>
  (format = 'MMMM D, YYYY') => {
    if (
      !submission_interval ||
      typeof due_date !== 'number' ||
      !submission_period
    ) {
      return '';
    }
    const d = moment(submission_period)
      .endOf(intervalToUnitOfTime(submission_interval))
      .add(due_date, 'days');
    return d.format(format);
  };

export const formatMonthlyPeriod = (period: Moment) => {
  return period.format('MMMM YYYY');
};

export const formatQuarterlyPeriod = (period: Moment) => {
  return `Q${period.format('Q YYYY')}`;
};

export const formatSubmissionPeriod =
  ({ submission_interval, submission_period }: Partial<CreateBatchInfo>) =>
  () => {
    if (!submission_interval || !submission_period) {
      return '';
    }
    switch (submission_interval) {
      case 'Monthly':
        return formatMonthlyPeriod(submission_period);
      case 'Quarterly':
        return formatQuarterlyPeriod(submission_period);
      default:
        throw new Error(`invalid submission interval ${submission_interval}`);
    }
  };

export const formatDueDateOption = (dayOffset: number) => {
  if (dayOffset === 0) {
    return 'Last Day';
  }
  const isPositive = dayOffset > 0;
  const value = Math.abs(dayOffset);
  const isPlural = value > 1;
  return `${isPositive ? '+' : '-'}${value} day${isPlural ? 's' : ''}`;
};

export type Provider = {
  provider_id: number;
  provider_name: string;
};

export const formatEndPeriod = (info: CreateBatchInfo) => {
  return moment(info.submission_period)
    .add(1, 'day')
    .endOf(intervalToUnitOfTime(info.submission_interval))
    .format('YYYY-MM-DD');
};
