import { Dialog, Grid, Typography, styled } from '@cherre-frontend/ui';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
    width: 500px;
  }
`;

export const DialogTitle = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  /* Headings/H4 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;
