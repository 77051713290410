import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { getProvidersQuery } from './__generated__/getProvidersQuery.graphql';
import { providersTableSearch, providersTableState } from '../recoil';
import { getProvidersTotalQuery } from './__generated__/getProvidersTotalQuery.graphql';
import { MRT_SortingState } from 'material-react-table';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

export const getProvidersTotal = graphQLSelector({
  query: graphql`
    query getProvidersTotalQuery($search: String = "%") {
      total: sys_providers_aggregate(
        where: { provider_name: { _ilike: $search } }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getProvidersTotalQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(providersTableSearch);

      return {
        search: `%${search ?? ''}%`,
      };
    },
  mapResponse: (resp) => resp.total,
});

export const getProviders = graphQLSelector({
  query: graphql`
    query getProvidersQuery(
      $search: String = "%"
      $order_by: [sys_providers_order_by!] = { provider_name: asc }
      $limit: Int = 10
      $offset: Int = 0
    ) {
      sys_providers(
        where: { provider_name: { _ilike: $search } }
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        is_active
        provider_id
        provider_name
        provider_type {
          name
        }
        updated_at_datetime
        related_data_updated_at
        user_count: users_aggregate {
          aggregate {
            count
          }
        }
        ingestion_config
      }
    }
  ` as GraphQLReturn<getProvidersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(providersTableSearch);
      const tableState = get(providersTableState);

      return {
        search: `%${search ?? ''}%`,
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
      };
    },
  mapResponse: (resp) => {
    return resp.sys_providers;
  },
});
