import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getAssignProviderOpenedBatchesSubscription } from './__generated__/getAssignProviderOpenedBatchesSubscription.graphql';

export const getAssignProviderOpenedBatches = graphQLSelector({
  query: graphql`
    subscription getAssignProviderOpenedBatchesSubscription(
      $property_ids: [Int!]
    ) {
      sys_submissions(
        where: {
          is_closed: { _eq: false }
          property_batches: {
            property: {
              is_active: { _eq: true }
              property_id: { _in: $property_ids }
            }
          }
        }
        order_by: { submission_name: asc }
      ) {
        submission_id
        submission_name
        property_batches {
          property {
            entity_id
          }
        }
      }
    }
  ` as GraphQLReturn<getAssignProviderOpenedBatchesSubscription>,
  mapResponse: (response) => response.sys_submissions,
  mapVariables: (property_ids: number[]) => () => ({ property_ids }),
});
