import React, { useEffect, useMemo } from 'react';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { useConfirmationModal } from '@cherre-frontend/ui';
import { lastUploadedPropertyBatchDatasetAtom } from '../../../recoil';
import { getSubmissionBatch } from '../recoil';
import { useUploadDatasetPush } from 'src/products/data-submission-portal/hooks/useUploadDatasetPush';

export const useMissingEntityModal = () => {
  const submissionBatch = useCherreValue(getSubmissionBatch());

  const { uploadDatasetPush } = useUploadDatasetPush();

  const { modalProps, confirm: showMissingEntityModal } =
    useConfirmationModal();

  const [
    lastUploadedPropertyBatchDataset,
    setLastUploadedPropertyBatchDataset,
  ] = useCherreState(lastUploadedPropertyBatchDatasetAtom);

  const lastUploadedBatchAndDataset = useMemo(() => {
    if (
      !lastUploadedPropertyBatchDataset ||
      !submissionBatch?.property_batches
    ) {
      return;
    }

    const propertyBatch = submissionBatch.property_batches.find(
      (p) =>
        p.property_batch_id ===
        lastUploadedPropertyBatchDataset.property_batch_id
    );

    const dataset = propertyBatch?.property_batch_datasets.find(
      (d) =>
        d.dataset?.dataset_id === lastUploadedPropertyBatchDataset.dataset_id
    );

    return {
      propertyBatch,
      dataset,
    };
  }, [lastUploadedPropertyBatchDataset, submissionBatch?.property_batches]);

  useEffect(() => {
    if (lastUploadedBatchAndDataset) {
      showMissingEntityModal({
        title: 'Uploaded Dataset Does Not Match Entity ID',
        content: (
          <span>
            The {lastUploadedBatchAndDataset?.dataset?.dataset?.dataset_label}{' '}
            file you uploaded does not contain data for{' '}
            <strong>
              Entity ID{' '}
              {
                lastUploadedBatchAndDataset.propertyBatch
                  ?.properties_flattened_union.entity_id
              }
              .
            </strong>{' '}
            Please review and replace your submission file.
          </span>
        ),
        confirmLabel: 'Replace File',
        cancelLabel: 'Cancel',
        handleConfirm: () =>
          uploadDatasetPush(
            lastUploadedBatchAndDataset.dataset?.property_batch_dataset_id
          ),
      });

      setTimeout(() => {
        setLastUploadedPropertyBatchDataset(null);
      }, 100);
    }
  }, [lastUploadedBatchAndDataset]);

  return modalProps;
};
