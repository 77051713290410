import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getCOAMappingsQuery } from './__generated__/getCOAMappingsQuery.graphql';

export const getCOAMappings = graphQLSelector({
  query: graphql`
    query getCOAMappingsQuery($provider_id: Int!) {
      sys_mapping_sets(
        where: {
          provider_id: { _eq: $provider_id }
          is_active: { _eq: true }
          mapping_field: { mapping_field_name: { _eq: "gl_account_code" } }
        }
        order_by: { mapping_set_description: asc }
      ) {
        mapping_set_id
        mapping_set_description
        mapping_field_id
      }
    }
  ` as GraphQLReturn<getCOAMappingsQuery>,
  mapVariables: (provider_id: number) => () => {
    return { provider_id };
  },
  mapResponse: (resp) => {
    return resp.sys_mapping_sets;
  },
});
