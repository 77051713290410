import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import {
  providerUsersTableSearch,
  providerUsersTableState,
} from '../Panels/UsersTab/recoil';
import { MRT_SortingState } from 'material-react-table';
import { getProviderUsersQuery } from './__generated__/getProviderUsersQuery.graphql';
import { getProviderUsersTotalQuery } from './__generated__/getProviderUsersTotalQuery.graphql';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }
    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

export const getProviderUsers = graphQLSelector({
  query: graphql`
    subscription getProviderUsersQuery(
      $provider_id: Int
      $search: String = "%"
      $order_by: [sakura_user_order_by!] = [{ first_name: asc }]
      $limit: Int = 10
      $offset: Int = 0
    ) {
      sakura_user(
        where: {
          _or: [
            { first_name: { _ilike: $search } }
            { last_name: { _ilike: $search } }
            { email: { _ilike: $search } }
          ]
          sys_user: { provider_id: { _eq: $provider_id } }
        }
        limit: $limit
        offset: $offset
        order_by: $order_by
      ) {
        user_id: id
        email
        first_name
        last_name
        disabled
        last_seen {
          last_login_at
        }
        sys_user {
          is_active
          organization_id
          provider_id
          created_at_datetime
          updated_at_datetime
        }
      }
    }
  ` as GraphQLReturn<getProviderUsersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(providerUsersTableSearch);
      const tableState = get(providerUsersTableState);
      const provider_id = get(
        ProviderDetailsRoute.routeParamSelector
      ).provider_id;
      return {
        provider_id,
        search: `%${search ?? ''}%`,
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
      };
    },
  mapResponse: (resp) => {
    return resp.sakura_user;
  },
});

export const getProviderUsersTotal = graphQLSelector({
  query: graphql`
    query getProviderUsersTotalQuery($search: String = "%", $provider_id: Int) {
      total: sys_users_aggregate(
        where: {
          provider_id: { _eq: $provider_id }
          _and: {
            sakura_user: {
              _or: [
                { first_name: { _ilike: $search } }
                { last_name: { _ilike: $search } }
                { email: { _ilike: $search } }
              ]
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getProviderUsersTotalQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(providerUsersTableSearch);
      const provider_id = get(
        ProviderDetailsRoute.routeParamSelector
      ).provider_id;
      return {
        search: `%${search ?? ''}%`,
        provider_id,
      };
    },
  mapResponse: (resp) => resp.total,
});
