/**
 * @generated SignedSource<<ccc1691a925e4b39e809e72bf0937673>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetManyToOneChildPropertiesQuery$variables = {
  ignorePropertyIds: ReadonlyArray<number>;
  propertyIds: ReadonlyArray<number>;
};
export type recoilGetManyToOneChildPropertiesQuery$data = {
  readonly sys_properties_flattened_union: ReadonlyArray<{
    readonly entity_id: string;
    readonly organization_id: number;
    readonly parent_property_id: number | null;
    readonly property_code: string;
    readonly property_id: number;
    readonly property_mapping: string | null;
    readonly property_name: string | null;
    readonly provider: {
      readonly provider_id: number;
      readonly provider_name: string;
    } | null;
  }>;
};
export type recoilGetManyToOneChildPropertiesQuery = {
  response: recoilGetManyToOneChildPropertiesQuery$data;
  variables: recoilGetManyToOneChildPropertiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ignorePropertyIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "propertyIds"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "propertyIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "parent_property_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_nin",
                "variableName": "ignorePropertyIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_mapping",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parent_property_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entity_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetManyToOneChildPropertiesQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilGetManyToOneChildPropertiesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b179f304869aaae4ab6554614ba7d0a7",
    "id": null,
    "metadata": {},
    "name": "recoilGetManyToOneChildPropertiesQuery",
    "operationKind": "query",
    "text": "query recoilGetManyToOneChildPropertiesQuery(\n  $propertyIds: [Int!]!\n  $ignorePropertyIds: [Int!]!\n) {\n  sys_properties_flattened_union(where: {parent_property_id: {_in: $propertyIds}, property_id: {_nin: $ignorePropertyIds}}) {\n    property_code\n    property_id\n    property_name\n    organization_id\n    property_mapping\n    parent_property_id\n    entity_id\n    provider {\n      provider_id\n      provider_name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "379ac816c04839146ee8ed98048396f7";

export default node;
