export enum MappingTypes {
  GL_ACCOUNT_CODE = 'gl_account_code',
  SEGMENT = 'segment_mapping',
  EXCLUDED_PROPERTY = 'excluded_property',
}

export const isSourcedFromMasterCOA = (type: MappingTypes) =>
  type === MappingTypes.GL_ACCOUNT_CODE;

export const isExcludedPropertyType = (type: MappingTypes) =>
  type === MappingTypes.EXCLUDED_PROPERTY;
