import {
  graphQLSelector,
  Writeable,
  GraphQLReturn,
  atom,
  selector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import {
  recoilGetPropertiesQuery,
  sys_properties_flattened_union_bool_exp,
} from './__generated__/recoilGetPropertiesQuery.graphql';
import { MRT_SortingState } from '@cherre-frontend/ui';
import { namespace } from '../recoil';
import { BatchDetailRoute } from 'src/products/data-submission-portal/routes';

export const mrtToHasuraSorting = (sorting: MRT_SortingState) => {
  return sorting.map((sort) => {
    return { [sort.id]: sort.desc ? ('desc' as const) : ('asc' as const) };
  });
};

export type Property = Writeable<
  recoilGetPropertiesQuery['response']['sys_properties_flattened_union'][number]
>;

export const getProperties = graphQLSelector({
  query: graphql`
    query recoilGetPropertiesQuery(
      $order_by: [sys_properties_flattened_union_order_by!] = {}
      $where: sys_properties_flattened_union_bool_exp = {}
    ) {
      sys_properties_flattened_union(order_by: $order_by, where: $where) {
        entity_id
        property_name
        address
        property_id
        fund
        type
        is_active
        property_code
        property_mapping
        properties_roles_users(
          where: { property_role: { property_role_set: { _eq: "preparer" } } }
        ) {
          user {
            sakura_user {
              first_name
              last_name
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetPropertiesQuery>,
  mapResponse: (response) => response.sys_properties_flattened_union,
});

export const tableSort = atom<MRT_SortingState>({
  key: namespace('table-sort'),
  default: [{ id: 'entity_id', desc: false }],
});

export const tableData = selector<Property[]>({
  key: namespace('table-data'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const sort = get(tableSort);
      const { submission_id } = get(BatchDetailRoute.routeParamSelector);

      const where: NonNullable<sys_properties_flattened_union_bool_exp> = {
        property_batches: {
          submission_id: { _eq: submission_id },
        },
      };

      return get(
        getProperties({
          where,
          order_by: mrtToHasuraSorting(sort),
        })
      );
    },
});
