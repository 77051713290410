/**
 * @generated SignedSource<<bb5785d2361b6fc35f59000f48f06c95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignPropertiesDialogMutation$variables = {
  property_ids: ReadonlyArray<number>;
  provider_id: number;
};
export type AssignPropertiesDialogMutation$data = {
  readonly _sys_property_assign_provider: {
    readonly result: boolean;
  } | null;
};
export type AssignPropertiesDialogMutation = {
  response: AssignPropertiesDialogMutation$data;
  variables: AssignPropertiesDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "property_ids",
            "variableName": "property_ids"
          },
          {
            "kind": "Variable",
            "name": "provider_id",
            "variableName": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "arg1"
      }
    ],
    "concreteType": "_sys_property_assign_provider_output",
    "kind": "LinkedField",
    "name": "_sys_property_assign_provider",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignPropertiesDialogMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignPropertiesDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e409f8a443f17ede7de2b0404075b46",
    "id": null,
    "metadata": {},
    "name": "AssignPropertiesDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AssignPropertiesDialogMutation(\n  $property_ids: [Int!]!\n  $provider_id: Int!\n) {\n  _sys_property_assign_provider(arg1: {property_ids: $property_ids, provider_id: $provider_id}) {\n    result\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a1c832cd5638018e58001fa53f7a5b6";

export default node;
