import {
  Box,
  BoxProps,
  Button,
  Grid,
  Typography,
  TypographyProps,
  styled,
  IngestTemplateCard,
  Select,
  FormEntry,
  MenuItem,
  SelectProps,
  Done,
  ReportOkIcon,
  CircularProgress,
} from '@cherre-frontend/ui';
import React, { useMemo, useState } from 'react';
import { DialogHeader } from '../DialogHeader';
import {
  GraphQLReturn,
  graphQLSelector,
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  Stage,
  assignDatasetsToPropertyIdState,
  datasetTemplateIdState,
  dialogStage,
  selectedDatasetsState,
} from '../../localState';
import { graphql } from 'react-relay';
import { useSelector } from 'react-redux';
import { SelectDatasetTemplateQuery } from './__generated__/SelectDatasetTemplateQuery.graphql';
import pluralize from 'pluralize';

const SelectStyled = styled<React.FC<SelectProps>>(Select)`
  height: 34px;
`;

const Placeholder = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const DatasetButtonBox = styled<React.FC<BoxProps & { active?: boolean }>>(Box)`
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey[600]};
  background-color: ${({ theme, active }) =>
    active ? theme.palette.grey[100] : 'white'};
  padding: 6px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const InstructionText = styled<React.FC<TypographyProps>>(Typography)`
  font-size: 14px;
`;

type SelectDatasetTemplateProps = {
  numRowsSelected: number;
  onClose: () => void;
};

export const $unifiedIngestGetTranformTemplatesQuery = graphQLSelector({
  query: graphql`
    query SelectDatasetTemplateQuery(
      $params: unified_ingest_transform_templates_get_params!
    ) {
      unified_ingest_transform_templates_get(params: $params) {
        source_systems {
          id
          name
          templates {
            id
            name
          }
        }
      }
    }
  ` as GraphQLReturn<SelectDatasetTemplateQuery>,
  mapResponse: (resp) => {
    const sourceSystems = Object.values(
      resp.unified_ingest_transform_templates_get.source_systems
    );
    return sourceSystems
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        ...s,
        templates: Object.values(s.templates).sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }));
  },
});

type DatasetReportStructuredType = {
  type: 'structured';
  sourceSystem?: never;
  reportTemplate?: never;
};

type DatasetReportSemiStructuredType = {
  type: 'semi-structured';
  sourceSystem?: string;
  reportTemplate?: string;
};

type DatasetReportType =
  | DatasetReportStructuredType
  | DatasetReportSemiStructuredType;

const SelectDatasetTemplate: React.FC<SelectDatasetTemplateProps> = ({
  numRowsSelected,
  onClose,
}) => {
  const datasets = useCherreValue(selectedDatasetsState);

  const [datasetTemplateIds, setDatasetTemplateId] = useCherreState(
    datasetTemplateIdState
  );

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);

  if (!ownerName) {
    throw new Error('Owner name is not defined');
  }

  const templates = useCherreValue(
    $unifiedIngestGetTranformTemplatesQuery({
      params: { owner_name: ownerName },
    })
  );

  const [openDatasetId, setOpenDatasetId] = React.useState<number>(
    (datasets?.[0].dataset_id as number) ?? null
  );

  const datasetsTemplatesDefault = useMemo(() => {
    return Object.fromEntries(
      Object.entries(datasetTemplateIds ?? {}).map(
        ([datasetId, templateId]) => [
          datasetId,
          templateId === null
            ? ({ type: 'structured' } as DatasetReportStructuredType)
            : ({
                type: 'semi-structured',
                sourceSystem: templates
                  ?.flatMap((t) =>
                    t.templates.map((tt) => ({ id: t.id, templateId: tt.id }))
                  )
                  .find((t) => t.templateId === templateId)?.id,
                reportTemplate: templateId,
              } as DatasetReportSemiStructuredType),
        ]
      )
    );
  }, [templates, datasetTemplateIds]);

  const [datasetTemplateTypeIds, setDatasetTemplateTypeIds] = useState<
    Record<number, DatasetReportType>
  >(datasetsTemplatesDefault);

  const assignDatasetsToPropertyId = useCherreValue(
    assignDatasetsToPropertyIdState
  );

  const onNext = useCherreEventWithRecoil(
    'user went from select dataset templates to select validations',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectValidations)
  );

  const onPrev = useCherreEventWithRecoil(
    'user went from select dataset templates to select datasets',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectDatasets)
  );

  const isDatasetTemplateValid = (datasetId: number) => {
    return (
      datasetTemplateTypeIds[datasetId]?.type === 'structured' ||
      (datasetTemplateTypeIds[datasetId]?.type === 'semi-structured' &&
        datasetTemplateTypeIds[datasetId]?.sourceSystem &&
        datasetTemplateTypeIds[datasetId]?.reportTemplate)
    );
  };

  const nextDisabled = datasets?.some(
    (d) => !isDatasetTemplateValid(d.dataset_id)
  );

  const isSuspended = useIsSuspended();

  return (
    <Box sx={{ minHeight: '440px', display: 'flex', flexDirection: 'column' }}>
      <DialogHeader
        title='Select Templates'
        subtitle={`${
          assignDatasetsToPropertyId ? 1 : numRowsSelected
        } Properties • ${datasets?.length} ${pluralize(
          'Dataset',
          datasets?.length
        )}`}
      />

      <InstructionText marginTop={3}>
        Select the expected template the provider will use for each dataset.
      </InstructionText>

      <Box my={3} sx={{ flexGrow: 1 }}>
        <Grid container direction='row' alignItems='flex-start' gap={2}>
          <Box flex={2}>
            {datasets?.map((dataset) => (
              <DatasetButtonBox
                key={dataset.dataset_id}
                active={dataset.dataset_id === openDatasetId}
                onClick={() => setOpenDatasetId(dataset.dataset_id)}
              >
                <Typography
                  variant='body2'
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {isDatasetTemplateValid(dataset.dataset_id) ? (
                    <Done sx={{ marginRight: '4px', color: 'green' }} />
                  ) : null}
                  {dataset.dataset_label}
                </Typography>
              </DatasetButtonBox>
            ))}
          </Box>

          <Grid
            container
            display='grid'
            gap={'12px'}
            gridTemplateColumns='1fr 1fr'
            flex={3}
          >
            {isSuspended ? (
              <Box
                display={'flex'}
                justifyContent={'center'}
                gridColumn='span 2'
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <IngestTemplateCard
                  title={'Structured'}
                  description='Consistent format, fixed schema, predictable columns.'
                  icon={<ReportOkIcon color='primary' />}
                  selected={
                    datasetTemplateTypeIds[openDatasetId]?.type === 'structured'
                  }
                  onClick={() => {
                    setDatasetTemplateTypeIds((prevState) => ({
                      ...prevState,
                      [openDatasetId]: { type: 'structured' },
                    }));
                    setDatasetTemplateId((prevState) => ({
                      ...prevState,
                      [openDatasetId]: null,
                    }));
                  }}
                />
                <IngestTemplateCard
                  title={'Semi-Structured'}
                  description='Flexible format, irregular schema, inconsistent or variable columns.'
                  icon={<ReportOkIcon color='primary' />}
                  selected={
                    datasetTemplateTypeIds[openDatasetId]?.type ===
                    'semi-structured'
                  }
                  onClick={() => {
                    setDatasetTemplateTypeIds((prevState) => ({
                      ...prevState,
                      [openDatasetId]: { type: 'semi-structured' },
                    }));
                  }}
                />
                {datasetTemplateTypeIds[openDatasetId]?.type ===
                  'semi-structured' && (
                  <Box
                    gridColumn='span 2'
                    display='flex'
                    flexDirection='column'
                    gap='10px'
                  >
                    <FormEntry title='Select source system:'>
                      <SelectStyled
                        value={
                          datasetTemplateTypeIds[openDatasetId]?.sourceSystem ??
                          ''
                        }
                        displayEmpty
                        onChange={(e) => {
                          setDatasetTemplateTypeIds((prevState) => ({
                            ...prevState,
                            [openDatasetId]: {
                              type: 'semi-structured',
                              sourceSystem: e.target.value as string,
                            },
                          }));
                        }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 346 } } }}
                        renderValue={(value) => {
                          const template = templates?.find(
                            (template) => template.id === value
                          )?.name;

                          if (!template) {
                            return <Placeholder>Select</Placeholder>;
                          }

                          return template;
                        }}
                      >
                        {templates?.map((t) => (
                          <MenuItem key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        ))}
                      </SelectStyled>
                    </FormEntry>
                    <FormEntry title='Select report template:'>
                      <SelectStyled
                        disabled={
                          !datasetTemplateTypeIds[openDatasetId].sourceSystem
                        }
                        value={
                          datasetTemplateTypeIds[openDatasetId]
                            ?.reportTemplate ?? ''
                        }
                        displayEmpty
                        onChange={(e) => {
                          setDatasetTemplateTypeIds((prevState) => ({
                            ...prevState,
                            [openDatasetId]: {
                              type: 'semi-structured',
                              sourceSystem:
                                prevState[openDatasetId].sourceSystem,
                              reportTemplate: e.target.value as string,
                            },
                          }));
                          setDatasetTemplateId((prevState) => ({
                            ...prevState,
                            [openDatasetId]: e.target.value as string,
                          }));
                        }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 346 } } }}
                        sx={{
                          '&.MuiInputBase-root.Mui-disabled': {
                            backgroundColor: (theme) => theme.palette.grey[100],
                          },
                        }}
                        renderValue={(value) => {
                          const template = templates
                            ?.flatMap((t) => t.templates)
                            ?.find((template) => template.id === value)?.name;

                          if (!template) {
                            return <Placeholder>Select</Placeholder>;
                          }

                          return template;
                        }}
                      >
                        {templates
                          ?.find(
                            (t) =>
                              datasetTemplateTypeIds[openDatasetId]
                                ?.sourceSystem === t.id
                          )
                          ?.templates?.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </SelectStyled>
                    </FormEntry>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='nowrap'
      >
        <Grid item>
          <Button
            size='medium'
            variant='outlined'
            color='primary'
            onClick={onPrev}
            sx={{
              border: 'none',
            }}
          >
            Prev
          </Button>
        </Grid>

        <>
          <Grid
            item
            container
            direction='row'
            gap={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <Button
                size='medium'
                variant='text'
                color='primary'
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size='medium'
                variant='contained'
                color='primary'
                disabled={nextDisabled}
                onClick={onNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

SelectDatasetTemplate.displayName = 'SelectDatasetTemplate';

export default SelectDatasetTemplate;
