/**
 * @generated SignedSource<<03a7cfb12dd5dcfc77ec803da4013b95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyByCodeQuery$variables = {
  property_code: string;
};
export type getPropertyByCodeQuery$data = {
  readonly sys_properties: ReadonlyArray<{
    readonly property_id: number;
  }>;
};
export type getPropertyByCodeQuery = {
  response: getPropertyByCodeQuery$data;
  variables: getPropertyByCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_code"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "property_code"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_code"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties",
    "kind": "LinkedField",
    "name": "sys_properties",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyByCodeQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertyByCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67fd6312155b605f8bbd0c02cebe8609",
    "id": null,
    "metadata": {},
    "name": "getPropertyByCodeQuery",
    "operationKind": "query",
    "text": "query getPropertyByCodeQuery(\n  $property_code: String!\n) {\n  sys_properties(where: {property_code: {_ilike: $property_code}, is_active: {_eq: true}}, limit: 1) {\n    property_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dd424d56f12f3049831bd07a290d88f";

export default node;
