import { Column, TableConfig } from './recoil';

const getTotalFixedSize = (columns: Column[]): number => {
  return columns.reduce((acc, curr) => {
    if (curr.columns) {
      return acc + getTotalFixedSize(curr.columns);
    }
    if (curr.fixedSize) {
      return acc + curr.fixedSize;
    }
    return acc;
  }, 0);
};

const getBaseRatioSize = (columns: Column[]): number => {
  return (
    columns.reduce((acc, curr) => {
      if (curr.columns) {
        if (!acc) {
          return getBaseRatioSize(curr.columns);
        }
        return Math.min(acc, getBaseRatioSize(curr.columns) || acc);
      }
      if (curr.ratioSize) {
        if (!acc) {
          return curr.ratioSize;
        }
        return Math.min(acc, curr.ratioSize);
      }
      return acc;
    }, null as number | null) || 1
  );
};

const getTotalRatioSize = (columns: Column[]): number => {
  const baseRatioSize = getBaseRatioSize(columns);
  return columns.reduce((acc, curr) => {
    if (curr.columns) {
      return acc + getTotalRatioSize(curr.columns);
    }
    if (curr.ratioSize) {
      return acc + curr.ratioSize;
    }
    if (!curr.fixedSize) {
      return acc + baseRatioSize;
    }
    return acc;
  }, 0);
};

const MIN_COLUMN_SIZE = 150; //the minimum width a column can have

export const sizeCalculator = (columns: Column[], width: number) => {
  const totalFixedSize = getTotalFixedSize(columns);
  const baseRatioSize = getBaseRatioSize(columns);
  const totalRatioSize = getTotalRatioSize(columns);

  const normalizedTotalRatioSize = totalRatioSize / baseRatioSize;

  const remainingWidth = width - totalFixedSize;

  const remainingWidthPerColumn = remainingWidth / normalizedTotalRatioSize;
  const widthPerColumn = Math.max(remainingWidthPerColumn, MIN_COLUMN_SIZE);

  return (column: Column) => {
    if (column.columns) {
      const columnFixedSize = getTotalFixedSize(column.columns);
      const columnRatioSize = getTotalRatioSize(column.columns);
      const normalizedColumnRatioSize = columnRatioSize / baseRatioSize;
      return columnFixedSize + widthPerColumn * normalizedColumnRatioSize;
    }
    if (column.fixedSize || column.fixedSize === 0) {
      return column.fixedSize;
    }
    const normalizedRatioSize = column.ratioSize
      ? column.ratioSize / baseRatioSize
      : 1;
    return widthPerColumn * normalizedRatioSize;
  };
};

const totalMinimumSize = (columns: Column[]): number => {
  const calculateMinimumSize = sizeCalculator(columns, 0);
  return columns.reduce((acc, curr) => {
    return acc + calculateMinimumSize(curr);
  }, 0);
};

export const biggestMinimumSize = (tables: TableConfig[]): number => {
  return tables.reduce((acc, curr) => {
    return Math.max(acc, totalMinimumSize(curr.columns));
  }, 0);
};

export const isNumber = (type: string) => {
  return ['Int', 'numeric', 'bigint', 'float8'].includes(type);
};
