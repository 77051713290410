/**
 * @generated SignedSource<<d90a78844be10e6ec2e2e55552f036bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilPropertyBatchesPackageReportInfoQuery$variables = {
  property_batch_ids?: ReadonlyArray<number> | null;
};
export type recoilPropertyBatchesPackageReportInfoQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly package_reports: ReadonlyArray<{
      readonly package_report_id: number;
      readonly package_report_label: string;
      readonly report: {
        readonly report_slug: string;
      };
    }> | null;
  }>;
};
export type recoilPropertyBatchesPackageReportInfoQuery = {
  response: recoilPropertyBatchesPackageReportInfoQuery$data;
  variables: recoilPropertyBatchesPackageReportInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_ids"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "property_batch_ids"
          }
        ],
        "kind": "ObjectValue",
        "name": "property_batch_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "package_report_label": "asc"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "package_report_label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "package_report_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_reports",
  "kind": "LinkedField",
  "name": "report",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "report_slug",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilPropertyBatchesPackageReportInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "sys_package_reports",
            "kind": "LinkedField",
            "name": "package_reports",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v5/*: any*/),
                "action": "THROW",
                "path": "sys_property_batches.package_reports.report"
              }
            ],
            "storageKey": "package_reports(order_by:{\"package_report_label\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilPropertyBatchesPackageReportInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "sys_package_reports",
            "kind": "LinkedField",
            "name": "package_reports",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": "package_reports(order_by:{\"package_report_label\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea5c8eab4ca9336fdac314604d77c02f",
    "id": null,
    "metadata": {},
    "name": "recoilPropertyBatchesPackageReportInfoQuery",
    "operationKind": "subscription",
    "text": "subscription recoilPropertyBatchesPackageReportInfoQuery(\n  $property_batch_ids: [Int!]\n) {\n  sys_property_batches(where: {property_batch_id: {_in: $property_batch_ids}}) {\n    package_reports(order_by: {package_report_label: asc}) {\n      package_report_label\n      package_report_id\n      report {\n        report_slug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "520a7ecb5f6dc775ba7438c074f91b11";

export default node;
