import { useCherreEventWithRecoil } from '@cherre-frontend/data-fetching';
import {
  Autocomplete,
  Button,
  Checkbox,
  DatePickerInput,
  FormControlLabel,
  FormEntry,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from '@cherre-frontend/ui';

import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogHeader,
} from '../../../../components';
import { createBatchDialogOpenState } from '../../recoil';
import { SubmissionInterval, stages, submissionIntervals } from './consts';
import { DefineBatchDetailsState, dialogStage, newBatchInfo } from './recoil';
import * as TEST_IDS from './testIDs';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useCreateBatchForm } from 'src/products/data-submission-portal/hooks/useCreateBatchForm';
import moment, { Moment } from 'moment';
import { intervalToUnitOfTime } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/formatters';

const calculateIntialDueDate = (
  submission_period: Moment = moment(),
  submission_interval: 'Monthly' | 'Quarterly' = 'Monthly'
) => {
  if (submission_interval === 'Quarterly') {
    return moment(submission_period).endOf('quarter').isBefore(moment())
      ? moment().endOf('quarter').startOf('day')
      : moment(submission_period).endOf('quarter').startOf('day');
  }

  return moment(submission_period).isBefore(moment())
    ? moment().endOf('month').startOf('day')
    : moment(submission_period).endOf('month').startOf('day');
};

const calculatePeriodEnd = (
  submission_period: Moment,
  submission_interval: 'Monthly' | 'Quarterly'
) => {
  return submission_period
    .clone()
    .endOf(intervalToUnitOfTime(submission_interval))
    .startOf('day');
};

const calculateDueDateRelativeToPeriodEnd = (
  submission_period: Moment,
  submission_interval: 'Monthly' | 'Quarterly',
  date: Moment
) => {
  return date
    .clone()
    .startOf('day')
    .diff(calculatePeriodEnd(submission_period, submission_interval), 'days');
};

const calculateMaxDate = (
  submission_period?: Moment,
  submission_interval?: 'Monthly' | 'Quarterly'
) => {
  let date =
    submission_interval === 'Quarterly'
      ? moment(submission_period).endOf('quarter')
      : moment(submission_period);

  if (date.isBefore(moment())) {
    date = moment().endOf('month');
  }

  return date.add(1, 'month').endOf('month');
};

const DefineBatchDetails: React.FC = () => {
  const { errorMessage, formState, isValid, options, setFormState } =
    useCreateBatchForm();

  const onNext = useCherreEventWithRecoil(
    'user went to second part of create new batch dialog',
    (ctx) => () => {
      ctx.recoil.set(newBatchInfo, formState);
      ctx.recoil.set(dialogStage, stages.SelectProperties);
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed create batch dialog',
    (ctx) => () => {
      ctx.recoil.set(createBatchDialogOpenState, false);
    }
  );

  const fiscalYearFeatureFlag = useFeatureFlag('DSPFiscalYearCheckbox');

  // Do we really need a max date?
  const datePickerMaxDate = calculateMaxDate(
    formState.submission_period,
    formState.submission_interval
  );

  const onChangeDatePicker = (value: unknown) => {
    if (!formState.submission_interval || !formState.submission_period) {
      throw new Error('Submission interval and period are required');
    }

    if (!value) {
      setFormState({ due_date: undefined });
      return;
    }

    setFormState({
      due_date: calculateDueDateRelativeToPeriodEnd(
        formState.submission_period,
        formState.submission_interval,
        value as Moment
      ),
    });
  };

  const datePickerValue =
    formState.submission_period &&
    formState.submission_interval &&
    formState.due_date !== undefined
      ? calculatePeriodEnd(
          formState.submission_period,
          formState.submission_interval
        ).add(formState.due_date, 'days')
      : undefined;

  return (
    <>
      <DialogHeader
        title='Create New Batch'
        subtitle='Add details to define your submission batch'
        onClose={onClose}
      />
      <DialogContent rowSpacing='30px'>
        <Grid item xs={12}>
          <FormEntry title='Provider'>
            <Autocomplete
              testID={TEST_IDS.PROVIDER_INPUT}
              placeholder='Select'
              selector={DefineBatchDetailsState.searchProvidersByName}
              onChange={(_, v) => setFormState({ provider_id: v?.provider_id })}
              getSelectedOption={(opt) =>
                opt.provider_id === formState.provider_id
              }
              getOptionLabel={(opt) => opt.provider_name}
            />
          </FormEntry>
        </Grid>
        <Grid item xs={12}>
          <FormEntry title='Submission Type'>
            <Autocomplete
              testID={TEST_IDS.SUBMISSION_TYPE_INPUT}
              multiple
              placeholder='Select'
              selector={options.submissionTypes}
              getOptionLabel={(option) => option.label}
              onChange={(_, opts) =>
                setFormState({ submission_types: opts.map((o) => o.value) })
              }
              getSelectedOption={(opt) =>
                formState.submission_types?.includes(opt.value) ?? false
              }
            />
          </FormEntry>
        </Grid>
        <Grid item xs={fiscalYearFeatureFlag ? 6 : 12}>
          <FormEntry title='Submission Interval'>
            <RadioGroup
              row
              value={formState.submission_interval}
              onChange={(_, v) =>
                setFormState({
                  submission_interval: v as SubmissionInterval,
                  submission_period: undefined,
                  due_date: undefined,
                })
              }
            >
              <Radio
                testID={TEST_IDS.MONTHLY_RADIO_BUTTON}
                label={submissionIntervals.Monthly}
              />
              <Radio
                testID={TEST_IDS.QUARTERLY_RADIO_BUTTON}
                label={submissionIntervals.Quarterly}
              />
            </RadioGroup>
          </FormEntry>
        </Grid>
        {fiscalYearFeatureFlag && (
          <Grid item xs={6}>
            <FormEntry title='&nbsp;'>
              <FormControlLabel
                sx={{ margin: 0, display: 'flex', justifyContent: 'flex-end' }}
                control={
                  <Checkbox
                    value={formState.fiscal_year_flag}
                    onChange={(_, v) => {
                      setFormState({ fiscal_year_flag: v });
                    }}
                    sx={{
                      padding: '0px',
                      paddingRight: '6px',
                    }}
                  />
                }
                label='New Fiscal Year Submission'
              />
            </FormEntry>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormEntry title='Submission Period'>
            <Autocomplete
              testID={TEST_IDS.SUBMISSION_PERIOD_INPUT}
              placeholder='Select'
              selector={options.submissionPeriod}
              onChange={(_, v) => {
                if (!formState.submission_interval) {
                  return;
                }

                setFormState({
                  submission_period: v?.value,
                  due_date: undefined,
                });
              }}
              getSelectedOption={({ value }) =>
                value.isSame(formState.submission_period || null)
              }
              getOptionLabel={(opt) => opt.label}
              helperText='The period for which you are expecting to receive data from your providers.'
            />
          </FormEntry>
        </Grid>
        <Grid item xs={6}>
          <FormEntry title='Due Date'>
            <DatePickerInput
              testID={TEST_IDS.DUE_DATE_INPUT}
              key={formState.submission_period?.valueOf()}
              placeholder='Select Date'
              disabled={!formState.submission_period}
              disablePast
              maxDate={datePickerMaxDate}
              onChange={onChangeDatePicker}
              onOpen={() => {
                if (
                  !formState.submission_interval ||
                  !formState.submission_period
                ) {
                  throw new Error(
                    'Submission interval and period are required'
                  );
                }

                if (formState.due_date) {
                  return;
                }

                setFormState({
                  due_date: calculateDueDateRelativeToPeriodEnd(
                    formState.submission_period,
                    formState.submission_interval,
                    calculateIntialDueDate(
                      formState.submission_period,
                      formState.submission_interval
                    )
                  ),
                });
              }}
              value={datePickerValue}
              slotProps={{
                field: {
                  clearable: true,
                },
              }}
            />
          </FormEntry>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={errorMessage}>
          <div>
            <Button
              testID={TEST_IDS.NEXT_BUTTON}
              variant='contained'
              color='primary'
              disabled={!isValid}
              onClick={onNext}
            >
              Next
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </>
  );
};

DefineBatchDetails.displayName = 'DefineBatchDetails';

export default DefineBatchDetails;
