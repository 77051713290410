/**
 * @generated SignedSource<<dd41234030367e235e26e8b8f0c4f88e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetWorkflowAvailableActionsQuery$variables = {
  property_batch_id: number;
};
export type recoilGetWorkflowAvailableActionsQuery$data = {
  readonly _sys_workflow_get_available_actions_for_batch: {
    readonly actions: ReadonlyArray<string | null>;
  };
};
export type recoilGetWorkflowAvailableActionsQuery = {
  response: recoilGetWorkflowAvailableActionsQuery$data;
  variables: recoilGetWorkflowAvailableActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "property_batch_id",
            "variableName": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "_sys_workflow_get_available_actions_for_batch_output",
    "kind": "LinkedField",
    "name": "_sys_workflow_get_available_actions_for_batch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetWorkflowAvailableActionsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetWorkflowAvailableActionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "598d235bbc034fab2285c0a5cf6c3c4d",
    "id": null,
    "metadata": {},
    "name": "recoilGetWorkflowAvailableActionsQuery",
    "operationKind": "query",
    "text": "query recoilGetWorkflowAvailableActionsQuery(\n  $property_batch_id: Int!\n) {\n  _sys_workflow_get_available_actions_for_batch(params: {property_batch_id: $property_batch_id}) {\n    actions\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6579436118e63da7c50697e53cc31d0";

export default node;
