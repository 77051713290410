import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadPropertyMutation } from './__generated__/useUploadPropertyMutation.graphql';

export const useUploadProperty = (
  input: {
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadPropertyMutation(
        $input: _sys_property_bulk_upload_input!
      ) {
        _sys_property_bulk_upload(input: $input) {
          added_property_codes
          ignored_property_codes
          duplicated_count
        }
      }
    ` as GraphQLReturn<useUploadPropertyMutation>,
    {
      trackEvent: false,
      mapVariables: (data: { batchId: string }) => async () => {
        return {
          input: {
            file_reference_source: data.batchId,
            file_source: input.file_source,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
