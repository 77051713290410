import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import { getProviderOrganizationQuery } from './__generated__/getProviderOrganizationQuery.graphql';

export const getProviderOrganization = graphQLSelector({
  query: graphql`
    query getProviderOrganizationQuery($id: Int) {
      sys_providers(limit: 1, where: { provider_id: { _eq: $id } }) {
        organization_id
      }
    }
  ` as GraphQLReturn<getProviderOrganizationQuery>,
  mapResponse: (resp) => {
    return resp.sys_providers[0].organization_id;
  },
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
    }),
});
