import {
  FileFailedIcon,
  FileLoadingIcon,
  FileOkIcon,
  SvgIconComponent,
  UploadFileIcon,
} from '@cherre-frontend/ui';
import { DatasetStatus } from '../../__generated__/constants';
import { ValidationStatusChipCodes } from '../ValidationStatusChip';

const STATUSES_WITHOUT_FILE = [
  DatasetStatus.PENDING.label,
  DatasetStatus.UPLOADING.label,
  DatasetStatus.UPLOAD_FAILED.label,
] as string[];

export function hasFile(validation: string) {
  return !STATUSES_WITHOUT_FILE.includes(validation);
}

export function needsUpload(status: ValidationStatusChipCodes) {
  return (
    status === ValidationStatusChipCodes.pending ||
    status === ValidationStatusChipCodes.upload_error ||
    status === ValidationStatusChipCodes.validation_error ||
    status === ValidationStatusChipCodes.no_records
  );
}

export const getDatasetValidationStatusCode = (
  datasetStatus,
  isValid,
  warningCount,
  rowCount
): ValidationStatusChipCodes | undefined => {
  switch (datasetStatus) {
    case DatasetStatus.PENDING.label:
      return ValidationStatusChipCodes.pending;
    case DatasetStatus.UPLOADING.label:
      return ValidationStatusChipCodes.uploading;
    case DatasetStatus.UPLOADED.label:
      return ValidationStatusChipCodes.uploaded;
    case DatasetStatus.VALIDATING.label:
      return ValidationStatusChipCodes.validating;
    case DatasetStatus.VALIDATED.label: {
      if (rowCount === 0) {
        return ValidationStatusChipCodes.no_records;
      }

      return isValid
        ? warningCount === 0
          ? ValidationStatusChipCodes.validation_passed
          : ValidationStatusChipCodes.validation_warning
        : ValidationStatusChipCodes.validation_failed;
    }
    case DatasetStatus.VALIDATED_FAILED.label:
      return ValidationStatusChipCodes.validation_failed;
    case DatasetStatus.UPLOAD_FAILED.label:
      return ValidationStatusChipCodes.upload_error;
    case DatasetStatus.VALIDATION_FAILED.label:
      return ValidationStatusChipCodes.validation_error;
    default:
      return ValidationStatusChipCodes.pending;
  }
};

export const fileIconMap = new Map<ValidationStatusChipCodes, SvgIconComponent>(
  [
    [ValidationStatusChipCodes.pending, UploadFileIcon],
    [ValidationStatusChipCodes.uploading, FileLoadingIcon],
    [ValidationStatusChipCodes.uploaded, FileLoadingIcon],
    [ValidationStatusChipCodes.validating, FileLoadingIcon],
    [ValidationStatusChipCodes.validation_passed, FileOkIcon],
    [ValidationStatusChipCodes.validation_warning, FileOkIcon],
    [ValidationStatusChipCodes.validation_failed, FileFailedIcon],
    [ValidationStatusChipCodes.upload_error, FileFailedIcon],
    [ValidationStatusChipCodes.validation_error, FileFailedIcon],
    [ValidationStatusChipCodes.no_records, FileFailedIcon],
  ]
);
