import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { MRT_RowSelectionState } from '@cherre-frontend/ui';
import { graphql } from 'react-relay';
import {
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  selectedPropertiesToAssignContainsManyToOneProperty,
  selectedReviewersUsers,
  userCheckerType,
} from '../../../../../recoil/dialog';
import {
  useAssignSingleChainReviewerMutation,
  useAssignSingleChainReviewerMutation$variables,
} from './__generated__/useAssignSingleChainReviewerMutation.graphql';
import { useWarningBox } from '../../../../../components/dialogs/hooks/useWarningBox';
import { SelectedUsersType } from '../../../../../components/dialogs/hooks/useUserRoleSelect';
import { getSubmissionTypes } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/queries';
import {
  assignReviewersDialogOpenState,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';
import { useGetProviderId } from '../../../../../components/dialogs/hooks/useGetProviderId';

export const transformReviewers = (
  reviewers: userCheckerType[],
  submission_type_id: number
) => {
  return reviewers
    .map((user) => ({
      user_id: user.user_id,
      property_role_id: user.property_role_id,
      submission_type_id,
      is_active: true,
    }))
    .filter(
      (u) => !!u.property_role_id
    ) as useAssignSingleChainReviewerMutation$variables['input']['reviewers'];
};

export const getSubmissionTypeReviewers = (
  submission_type_id: number,
  usersByStage?: SelectedUsersType
) => {
  if (!usersByStage) {
    return [];
  }
  const users = Object.values(usersByStage).flat();
  return transformReviewers(users, submission_type_id);
};

export enum SubmissionTypeSlugs {
  FINANCIAL = 'financial',
  OPERATIONAL = 'operational',
}

export const useAssignSingleChainReviewers = (
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>
) => {
  const { clearWarningBoxState } = useWarningBox();
  const provider_id = useGetProviderId();

  return useMutation(
    graphql`
      mutation useAssignSingleChainReviewerMutation(
        $input: _sys_property_assign_reviewers_input!
      ) {
        _sys_property_assign_reviewers(input: $input) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useAssignSingleChainReviewerMutation>,
    {
      mapVariables:
        () =>
        async ({ getPromise }) => {
          const selectedProperties = await getPromise(
            selectedPropertiesToAssign
          );

          const selectedFinancialReviewers = await getPromise(
            selectedFinancialReviewerUsers
          );

          const selectedOperationalReviewers = await getPromise(
            selectedOperationalReviewerUsers
          );

          const submissionTypes = await getPromise(getSubmissionTypes());

          const financialTypeId =
            submissionTypes.find(
              (submissionType) =>
                submissionType.submission_type_slug ===
                SubmissionTypeSlugs.FINANCIAL
            )?.submission_type_id ?? 0;

          const operationalTypeId =
            submissionTypes.find(
              (submissionType) =>
                submissionType.submission_type_slug ===
                SubmissionTypeSlugs.OPERATIONAL
            )?.submission_type_id ?? 0;

          const propertyIds = selectedProperties.map(
            ({ property_id }) => property_id
          );

          const newReviewersArray = [
            ...getSubmissionTypeReviewers(
              operationalTypeId,
              selectedOperationalReviewers
            ),
            ...getSubmissionTypeReviewers(
              financialTypeId,
              selectedFinancialReviewers
            ),
          ];

          return {
            input: {
              property_ids: propertyIds,
              reviewers: newReviewersArray,
              provider_id: provider_id,
            },
          };
        },
      onCompleted: async (result, ctx) => {
        const selectedProperties = await ctx.recoil.getPromise(
          selectedPropertiesToAssign
        );
        const shouldDisplayManyToOneMessage = await ctx.recoil.getPromise(
          selectedPropertiesToAssignContainsManyToOneProperty
        );

        const manyToOneMessage = 'Reviewers were successfully assigned.';
        const oneToOneMessage = `Reviewers have been assigned to ${
          selectedProperties?.length ?? 0
        } properties.`;
        ctx.showSnackbar({
          type: 'success',
          message: shouldDisplayManyToOneMessage
            ? manyToOneMessage
            : oneToOneMessage,
        });
        ctx.recoil.set(assignReviewersDialogOpenState, false);
        ctx.recoil.set(selectedReviewersUsers, {
          reviewer1: [],
          reviewer2: [],
          reviewer3: [],
        });
        ctx.recoil.set(selectedFinancialReviewerUsers, {
          reviewer1: [],
          reviewer2: [],
          reviewer3: [],
        });
        ctx.recoil.set(selectedOperationalReviewerUsers, {
          reviewer1: [],
          reviewer2: [],
          reviewer3: [],
        });
        clearWarningBoxState();
        setRowSelection({});
      },
    }
  );
};
