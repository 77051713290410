import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  Panel as BasePanel,
  CancelIcon,
  CardKPI,
  CertificateIcon,
  CycleTimeIcon,
  DocumentStackIcon,
  Grid,
  PropertiesIcon,
  ResetIcon,
  UploadFileIcon,
  WarningIcon,
  styled,
  useTheme,
} from '@cherre-frontend/ui';
import React from 'react';
import {
  getAVGPropertiesCycleTime,
  getTotalDatasets,
  getTotalFailedBatchValidations,
  getTotalFailedDatasetValidations,
  getTotalFinancialDatasets,
  getTotalFinancialUploadedDatasets,
  getTotalOperationalDatasets,
  getTotalOperationalUploadedDatasets,
  getTotalPastDueProperties,
  getTotalPropertySubmissions,
  getTotalRejectedProperties,
  getTotalStuckProperties,
  getTotalSubmittedProperties,
  getTotalUploadedDatasets,
} from './recoil';

const Panel = styled(BasePanel)`
  height: 100%;
`;

const PropertySubmissions = () => {
  const totalPropertySubmissions = useCherreValue(
    getTotalPropertySubmissions()
  );
  const theme = useTheme();

  return (
    <CardKPI
      label={'Property Submissions'}
      value={totalPropertySubmissions ?? 0}
      icon={<PropertiesIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const UploadedDatasets = () => {
  const totalUploadedDatasets = useCherreValue(getTotalUploadedDatasets());
  const totalDatasets = useCherreValue(getTotalDatasets());
  const percentage =
    totalDatasets && totalUploadedDatasets
      ? ((totalUploadedDatasets / totalDatasets) * 100)
          .toFixed(1)
          .replace(/\.0$/, '')
      : 0;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Total Uploaded Datasets'}
      value={totalUploadedDatasets ?? 0}
      valueDetail={`of ${totalDatasets} (${percentage}%)`}
      icon={<DocumentStackIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const FinancialDatasets = () => {
  const totalFinancialUploadedDatasets = useCherreValue(
    getTotalFinancialUploadedDatasets()
  );
  const totalFinancialDatasets = useCherreValue(getTotalFinancialDatasets());
  const theme = useTheme();

  return (
    <CardKPI
      label={'Financial Datasets'}
      value={totalFinancialUploadedDatasets ?? 0}
      valueDetail={`of ${totalFinancialDatasets}`}
      icon={<UploadFileIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const OperationalDatasets = () => {
  const totalOperationalUploadedDatasets = useCherreValue(
    getTotalOperationalUploadedDatasets()
  );
  const totalOperationalDatasets = useCherreValue(
    getTotalOperationalDatasets()
  );
  const theme = useTheme();

  return (
    <CardKPI
      label={'Operational Datasets'}
      value={totalOperationalUploadedDatasets ?? 0}
      valueDetail={`of ${totalOperationalDatasets}`}
      icon={<UploadFileIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const FailedValidations = () => {
  const theme = useTheme();
  const totalFailedBatchValidations = useCherreValue(
    getTotalFailedBatchValidations()
  );
  const totalFailedDatasetValidations = useCherreValue(
    getTotalFailedDatasetValidations()
  );
  const total =
    (totalFailedBatchValidations ?? 0) + (totalFailedDatasetValidations ?? 0);

  return (
    <CardKPI
      label={'Failed Validations'}
      value={total}
      icon={<CancelIcon />}
      valueColor={theme.palette.common.black}
      labelColor={theme.palette.error.main}
    />
  );
};

const PastDueProperties = () => {
  const theme = useTheme();
  const totalPastDueProperties = useCherreValue(getTotalPastDueProperties());
  const totalProperties = useCherreValue(getTotalPropertySubmissions());
  const percentage =
    totalPastDueProperties && totalProperties
      ? ((totalPastDueProperties / totalProperties) * 100)
          .toFixed(1)
          .replace(/\.0$/, '')
      : 0;
  return (
    <CardKPI
      label={'Past Due Properties'}
      value={totalPastDueProperties ?? 0}
      valueDetail={`(${percentage}%)`}
      icon={<PropertiesIcon />}
      valueColor={theme.palette.common.black}
      labelColor={theme.palette.error.main}
    />
  );
};

const RejectedProperties = () => {
  const theme = useTheme();
  const totalRejectedProperties = useCherreValue(getTotalRejectedProperties());
  const totalProperties = useCherreValue(getTotalPropertySubmissions());
  const percentage =
    totalRejectedProperties && totalProperties
      ? ((totalRejectedProperties / totalProperties) * 100)
          .toFixed(1)
          .replace(/\.0$/, '')
      : 0;

  return (
    <CardKPI
      label={'Rejected Properties'}
      value={totalRejectedProperties ?? 0}
      valueDetail={`(${percentage}%)`}
      icon={<ResetIcon />}
      valueColor={theme.palette.common.black}
      labelColor={theme.palette.error.main}
    />
  );
};

const ApprovedProperties = () => {
  const theme = useTheme();
  const totalApprovedProperties = useCherreValue(getTotalSubmittedProperties());
  const totalProperties = useCherreValue(getTotalPropertySubmissions());
  const percentage =
    totalApprovedProperties && totalProperties
      ? ((totalApprovedProperties / totalProperties) * 100)
          .toFixed(1)
          .replace(/\.0$/, '')
      : 0;

  return (
    <CardKPI
      label={'Approved Properties'}
      value={totalApprovedProperties ?? 0}
      valueDetail={`(${percentage}%)`}
      icon={<CertificateIcon />}
      valueColor={theme.palette.common.black}
      labelColor={theme.palette.positive?.main}
    />
  );
};

const StuckProperties = () => {
  const theme = useTheme();
  const totalStuckProperties = useCherreValue(getTotalStuckProperties());
  const totalProperties = useCherreValue(getTotalPropertySubmissions());
  const percentage =
    totalStuckProperties && totalProperties
      ? ((totalStuckProperties / totalProperties) * 100)
          .toFixed(1)
          .replace(/\.0$/, '')
      : 0;

  return (
    <CardKPI
      label={'Stuck Properties'}
      value={totalStuckProperties ?? 0}
      valueDetail={`(${percentage}%)`}
      icon={<WarningIcon />}
      valueColor={theme.palette.common.black}
      labelColor={theme.accents.deals.main}
    />
  );
};

const AVGPropertiesCycleTime = () => {
  const theme = useTheme();
  const cycleTime = useCherreValue(getAVGPropertiesCycleTime());
  return (
    <CardKPI
      label={'AVG Property Cycle Time'}
      value={cycleTime?.toFixed(1) ?? 0}
      valueDetail={`days`}
      icon={<CycleTimeIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const MonitorSubmissionsKPIs: React.FC = () => {
  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='property-submissions-kpi'>
          <PropertySubmissions />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='total-uploaded-datasets-kpi'>
          <UploadedDatasets />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='financial-datasets-kpi'>
          <FinancialDatasets />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='operational-datasets-kpi'>
          <OperationalDatasets />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='failed-validations-kpi'>
          <FailedValidations />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='property-cycle-time-kpi'>
          <AVGPropertiesCycleTime />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='stuck-properties-kpi'>
          <StuckProperties />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='rejected-properties-kpi'>
          <RejectedProperties />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='past-due-properties-kpi'>
          <PastDueProperties />
        </Panel>
      </Grid>
      <Grid item xs={10} sm={3} md={2}>
        <Panel id='approved-properties-kpi'>
          <ApprovedProperties />
        </Panel>
      </Grid>
    </Grid>
  );
};

MonitorSubmissionsKPIs.displayName = 'MonitorSubmissionsKPIs';

export default MonitorSubmissionsKPIs;
