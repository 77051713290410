import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useRenewBatchesMutation } from './__generated__/useRenewBatchesMutation.graphql';

export type UseRenewBatchesParams = {
  batches: number[];
  onCompleted?: Parameters<typeof useMutation>[1]['onCompleted'];
};

export const useRenewBatches = (params: UseRenewBatchesParams) => {
  return useMutation(
    graphql`
      mutation useRenewBatchesMutation($params: renew_batches_params!) {
        _sys_renew_batches(params: $params) {
          submission_ids
        }
      }
    ` as GraphQLReturn<useRenewBatchesMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => ({
        params: { submission_ids: params.batches },
      }),
      onCompleted: (value, ctx) => {
        const count = params.batches.length;
        ctx.showSnackbar({
          type: 'success',
          message: `${count} new batch${count > 1 ? 'es' : ''} created.`,
        });
        params.onCompleted?.(value, ctx);
      },
    }
  );
};
