/**
 * @generated SignedSource<<ea0606781b8a86b750644c8a0a3ed411>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queryBatchInfoQuery$variables = {
  property_batch_id: number;
};
export type queryBatchInfoQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly is_valid: boolean | null;
    readonly submission: {
      readonly submission_name: string;
      readonly user: {
        readonly sakura_user: {
          readonly first_name: string | null;
          readonly last_name: string | null;
        } | null;
      };
    };
    readonly validated_at_datetime: string | null;
    readonly warning_count: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
  }>;
};
export type queryBatchInfoQuery = {
  response: queryBatchInfoQuery$data;
  variables: queryBatchInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_valid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validated_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sakura_user",
                "kind": "LinkedField",
                "name": "sakura_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "warning_count",
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "is_valid": {
                "_eq": false
              },
              "validation_mode": {
                "_eq": "soft"
              }
            }
          }
        ],
        "concreteType": "sys_property_batch_validations_aggregate",
        "kind": "LinkedField",
        "name": "property_batch_validations_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batch_validations_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "property_batch_validations_aggregate(where:{\"is_valid\":{\"_eq\":false},\"validation_mode\":{\"_eq\":\"soft\"}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryBatchInfoQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryBatchInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6f92a262812aa48862ae770357173f2",
    "id": null,
    "metadata": {},
    "name": "queryBatchInfoQuery",
    "operationKind": "query",
    "text": "query queryBatchInfoQuery(\n  $property_batch_id: Int!\n) {\n  sys_property_batches(where: {property_batch_id: {_eq: $property_batch_id}}) {\n    is_valid\n    validated_at_datetime\n    submission {\n      submission_name\n      user {\n        sakura_user {\n          first_name\n          last_name\n        }\n      }\n    }\n    warning_count: property_batch_validations_aggregate(where: {is_valid: {_eq: false}, validation_mode: {_eq: \"soft\"}}) {\n      aggregate {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bad2fd27c69e3cd5ec0673a86e6aaf50";

export default node;
