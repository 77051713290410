import { atom, refine } from '@cherre-frontend/data-fetching';
import { ProviderDetailsRoute } from '../../routes';

export const providerDetailsTabState = atom<string>({
  key: 'provider-details-tab-state',
  default: 'Users',
});

export const reassignUserModalState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'reassign-user-id',
    refine: refine.withDefault(refine.nullable(refine.number()), null),
  });
