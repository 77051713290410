/**
 * @generated SignedSource<<53b534747ed381a4c7024c0a87128631>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getClickCardDataDatasetsByIdsQuery$variables = {
  dataset_ids?: ReadonlyArray<number> | null;
};
export type getClickCardDataDatasetsByIdsQuery$data = {
  readonly sys_datasets: ReadonlyArray<{
    readonly dataset_description: string;
  }>;
};
export type getClickCardDataDatasetsByIdsQuery = {
  response: getClickCardDataDatasetsByIdsQuery$data;
  variables: getClickCardDataDatasetsByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "dataset_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_datasets",
    "kind": "LinkedField",
    "name": "sys_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getClickCardDataDatasetsByIdsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getClickCardDataDatasetsByIdsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "952172e089f71ab8f322126678f76014",
    "id": null,
    "metadata": {},
    "name": "getClickCardDataDatasetsByIdsQuery",
    "operationKind": "query",
    "text": "query getClickCardDataDatasetsByIdsQuery(\n  $dataset_ids: [Int!]\n) {\n  sys_datasets(where: {dataset_id: {_in: $dataset_ids}}) {\n    dataset_description\n  }\n}\n"
  }
};
})();

(node as any).hash = "9244a6fa78db359da1f9ae21036e267f";

export default node;
