import React, { useMemo } from 'react';
import {
  Table,
  useTable,
  MRT_ColumnDef,
  MoreVertIcon,
  IconButton,
  styled,
  MRT_RowSelectionState,
  Grid,
} from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { getSubmissions, getSubmissionsTotal } from './query/getSubmissions';
import { ProviderChip } from './components/ProviderChip';
import { BatchStatusChip, statusCodeFromStr } from './components/BatchStatus';
import moment from 'moment';
import { QueryResult } from '@cherre-frontend/data-fetching/src/recoil/graphQLSelector';
import { batchManagementTableState } from '../../recoil';
import { NoBatches } from './components/NoBatches';
import { NoItemsFound } from '../../../../components/NoBatchesFound';
import { useAppContext } from '@cherre-frontend/core';
import { primaryColor } from 'src/const/theme';
import { getSubmissionPeriod } from 'src/products/data-submission-portal/utils/getSubmissionPeriod';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export type SubmissionsPanelProps = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  rowSelection: MRT_RowSelectionState;
  search?: string;
};

export const SubmissionsPanel: React.FC<SubmissionsPanelProps> = ({
  setRowSelection,
  rowSelection,
  search,
}) => {
  const { logger } = useAppContext();
  const [tableState, setTableState] = useCherreState(batchManagementTableState);

  const data = useCherreValue(getSubmissions());
  const total = useCherreValue(getSubmissionsTotal());

  const columns: MRT_ColumnDef<QueryResult<typeof getSubmissions>[number]>[] =
    useMemo(
      () => [
        {
          accessorKey: 'submission_name',
          header: 'Batch Name',
          size: 300,
          Cell: ({ renderedCellValue, row }) => {
            return (
              <Link
                style={{ color: primaryColor.main }}
                to={`/dsp/batch-detail/${row.original.submission_id}`}
              >
                {renderedCellValue}
              </Link>
            );
          },
        },
        {
          accessorKey: 'submission_type.submission_type_label',
          header: 'Type',
          size: 40,
        },
        {
          accessorKey: 'provider.provider_name',
          header: 'Provider',
          size: 200,
          Cell: ({ row }) => {
            return <ProviderChip label={row.original.provider.provider_name} />;
          },
        },
        {
          accessorFn: (row) =>
            row.approved_properties
              ? `${row.approved_properties.aggregate?.count} of ${row.properties.aggregate?.count}`
              : ``,
          id: 'property_batches_approved_aggregate.count',
          header: 'Approved Properties',
          size: 0,
          maxSize: 40,
          Cell: ({ renderedCellValue }) => {
            return (
              <span style={{ color: primaryColor.main }}>
                {renderedCellValue}
              </span>
            );
          },
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        {
          accessorKey: 'submission_status_description',
          header: 'Status',
          size: 0,
          maxSize: 40,
          Cell: ({ row }) => (
            <BatchStatusChip
              statusCode={
                statusCodeFromStr(row.original.submission_status_description) ??
                'Not Started'
              }
            />
          ),
        },
        {
          accessorKey: 'reporting_period_start_date',
          header: 'Period',
          size: 0,
          maxSize: 40,
          Cell: ({ row }) => {
            return (
              <>
                {getSubmissionPeriod(
                  row.original.reporting_period_start_date,
                  row.original.reporting_period_end_date
                )}
              </>
            );
          },
        },
        {
          accessorKey: 'due_date',
          header: 'Due Date',
          size: 0,
          maxSize: 40,
          accessorFn: (row) => moment(row.due_date).format('DD/MM/YYYY'),
        },
        {
          accessorKey: 'updated_at_datetime',
          header: 'Last Updated',
          size: 0,
          maxSize: 40,
          accessorFn: (row) =>
            moment(row.updated_at_datetime).format('DD/MM/YYYY'),
        },
      ],
      []
    );

  const table = useTable({
    data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    renderRowActions: () => (
      <Grid container justifyContent={'flex-end'}>
        <IconButton onClick={() => logger.info('more')}>
          <MoreVertIcon />
        </IconButton>
      </Grid>
    ),
    state: {
      rowSelection,
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.submission_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  if (data && data.length === 0) {
    return search ? <NoItemsFound title='No Batches Found' /> : <NoBatches />;
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

SubmissionsPanel.displayName = 'SubmissionsPanel';
