import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
  dict,
  nullable,
  CheckerReturnType,
} from '@recoiljs/refine';
import { MappingDetailRoute } from '../../../routes';
import { refine } from '@cherre-frontend/data-fetching';

const mappingSetValuesTableFiltersRefiner = withDefault(
  object({
    source_code_filter: nullable(
      object({
        label: string(),
        value: string(),
      })
    ),
    source_label_filter: string(),
    target_code_filter: nullable(
      object({
        label: string(),
        value: string(),
      })
    ),
    target_label_filter: string(),
    custom_attribute_filters: dict(string()),
  }),
  {
    source_code_filter: null,
    source_label_filter: '',
    target_code_filter: null,
    target_label_filter: '',
    custom_attribute_filters: {},
  }
);

export type MappingSetValueFilters = CheckerReturnType<
  typeof mappingSetValuesTableFiltersRefiner
>;

export const mappingSetValuesTableFilters =
  MappingDetailRoute.searchParamsSelectorFamily({
    key: 'search',
    refine: mappingSetValuesTableFiltersRefiner,
  });

export const mappingDetailsTableState =
  MappingDetailRoute.searchParamsSelectorFamily({
    key: 'table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'source_mapping_code', desc: false }],
      }
    ),
  });
