/**
 * @generated SignedSource<<42e5c65be24bc454296e483c35ec9699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FiltersGetTypesTypesQuery$variables = {};
export type FiltersGetTypesTypesQuery$data = {
  readonly sys_properties: ReadonlyArray<{
    readonly type: string | null;
  }>;
};
export type FiltersGetTypesTypesQuery = {
  response: FiltersGetTypesTypesQuery$data;
  variables: FiltersGetTypesTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": [
          "type"
        ]
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": [
          {
            "type": "asc"
          }
        ]
      }
    ],
    "concreteType": "sys_properties",
    "kind": "LinkedField",
    "name": "sys_properties",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties(distinct_on:[\"type\"],order_by:[{\"type\":\"asc\"}])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FiltersGetTypesTypesQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FiltersGetTypesTypesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "75570500447e438cd5d2b26580d08ea0",
    "id": null,
    "metadata": {},
    "name": "FiltersGetTypesTypesQuery",
    "operationKind": "query",
    "text": "query FiltersGetTypesTypesQuery {\n  sys_properties(distinct_on: [type], order_by: [{type: asc}]) {\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e0fd9ba0e335a704c8f3fc1636fddb7";

export default node;
