import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Grid } from '@cherre-frontend/ui';
import React from 'react';
import { graphql } from 'react-relay';
import FilterItem from './FilterItem';
import { FiltersGetProviderQuery } from './__generated__/FiltersGetProviderQuery.graphql';
import { FiltersGetFundTypesQuery } from './__generated__/FiltersGetFundTypesQuery.graphql';
import { FiltersGetTypesTypesQuery } from './__generated__/FiltersGetTypesTypesQuery.graphql';

type Props = {
  hasSelectedRows: boolean;
};

export const getFilterProviders = graphQLSelector({
  query: graphql`
    query FiltersGetProviderQuery {
      sys_providers {
        provider_id
        provider_name
      }
    }
  ` as GraphQLReturn<FiltersGetProviderQuery>,
  mapVariables: () => () => {
    return {};
  },
  mapResponse: (resp) => {
    return resp.sys_providers;
  },
});

export const getFilterFunds = graphQLSelector({
  query: graphql`
    query FiltersGetFundTypesQuery {
      sys_properties(distinct_on: [fund], order_by: [{ fund: asc }]) {
        fund
      }
    }
  ` as GraphQLReturn<FiltersGetFundTypesQuery>,
  mapVariables: () => () => {
    return {};
  },
  mapResponse: (resp) => {
    return resp.sys_properties;
  },
});

export const getFilterTypes = graphQLSelector({
  query: graphql`
    query FiltersGetTypesTypesQuery {
      sys_properties(distinct_on: [type], order_by: [{ type: asc }]) {
        type
      }
    }
  ` as GraphQLReturn<FiltersGetTypesTypesQuery>,
  mapVariables: () => () => {
    return {};
  },
  mapResponse: (resp) => {
    return resp.sys_properties;
  },
});

export const Filters: React.FC<Props> = ({ hasSelectedRows }) => {
  const providers = useCherreValue(getFilterProviders());
  const funds = useCherreValue(getFilterFunds());
  const types = useCherreValue(getFilterTypes());

  const filtersData = {
    Type: types
      ? Object.fromEntries(
          types.filter((x) => x.type !== null).map((x) => [x.type, x.type])
        )
      : {},

    Status: {
      true: 'Active',
      false: 'Inactive',
    },

    Fund: funds
      ? Object.fromEntries(
          funds.filter((x) => x.fund !== null).map((x) => [x.fund, x.fund])
        )
      : {},

    Provider: providers
      ? Object.fromEntries(
          providers.map((provider) => [
            provider.provider_id.toString(),
            provider.provider_name,
          ])
        )
      : {},
  };

  if (!filtersData) {
    return <></>;
  }

  const filters = Object.keys(filtersData);

  return (
    <Grid display={hasSelectedRows ? 'none' : 'flex'} gap='10px'>
      {filters.map((filterType) => (
        <FilterItem
          key={filterType}
          filterType={filterType}
          filterData={filtersData[filterType]}
        />
      ))}
    </Grid>
  );
};

Filters.displayName = 'Filters';
