import React, { useMemo } from 'react';
import FlatfileSystemUpload from '../../components/FlatFileSystemUpload';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useHistory } from 'react-router';
import { useUploadMasterFile } from './hooks/useUploadMasterFile';
import {
  MasterFileTypes,
  uploadMasterFileDialogOpenState,
  uploadMasterFileDialogStep,
  UploadMasterFileDialogStepValues,
  uploadMasterFileFormState,
} from '../master-files/recoil';

type FileTypeData = {
  schemaSlug: string;
  onComplete: (data: { batchId: string }) => void;
};

const UploadMasterFilePage: React.FC = () => {
  const { goBack } = useHistory();

  const masterFileForm = useCherreValue(uploadMasterFileFormState);

  const onMasterCoaComplete = useUploadMasterFile(
    {
      chart_of_accounts_name: masterFileForm?.name ?? '',
      file_source: 'flatfile',
    },
    (value, ctx) => {
      ctx.recoil.set(
        uploadMasterFileDialogStep,
        UploadMasterFileDialogStepValues.STEP_1
      );
      ctx.recoil.set(uploadMasterFileDialogOpenState, false);
      ctx.recoil.set(uploadMasterFileFormState, { name: '', fileType: '' });
      ctx.showSnackbar({
        type: 'success',
        message: 'Master Chart of Accounts successfully uploaded',
      });
      goBack();
    }
  );

  const flatFileData: FileTypeData = useMemo(() => {
    //We can add more file types here
    switch (masterFileForm?.fileType) {
      case MasterFileTypes.MASTER_COA:
        return { schemaSlug: 'masterCoa', onComplete: onMasterCoaComplete };
      default:
        return { schemaSlug: '', onComplete: () => {} };
    }
  }, [masterFileForm]);

  return (
    <FlatfileSystemUpload
      schemaSlug={flatFileData.schemaSlug}
      title={`File Uploader: ${masterFileForm?.fileType}`}
      onComplete={flatFileData.onComplete}
      onCancel={() => {
        goBack();
      }}
    />
  );
};

export default UploadMasterFilePage;
