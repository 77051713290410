import {
  GraphQLReturn,
  graphQLSelector,
  selector,
  useMutation,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { DefaultValue, atom, selectorFamily, waitForAny } from 'recoil';
import { submissionOverviewDialogSubmissionIdState } from 'src/products/data-submission-portal/pages/monitor-submissions/recoil';
import { SubmissionRoute } from 'src/products/data-submission-portal/routes';
import { recoilCreateCommentMutation } from './__generated__/recoilCreateCommentMutation.graphql';
import { recoilCreateReminderMutation } from './__generated__/recoilCreateReminderMutation.graphql';
import { recoilGetPackageReportCommentsQuery } from './__generated__/recoilGetPackageReportCommentsQuery.graphql';
import { recoilGetPackageReportResolvedRemindersQuery } from './__generated__/recoilGetPackageReportResolvedRemindersQuery.graphql';
import {
  recoilGetPackageReportUnresolvedRemindersQuery,
  recoilGetPackageReportUnresolvedRemindersQuery$data,
} from './__generated__/recoilGetPackageReportUnresolvedRemindersQuery.graphql';
import { recoilUseMarkPackageReportRemindersResolvedMutation } from './__generated__/recoilUseMarkPackageReportRemindersResolvedMutation.graphql';
import { recoilmarkReminderResolvedMutation } from './__generated__/recoilmarkReminderResolvedMutation.graphql';

export type Reminder =
  recoilGetPackageReportUnresolvedRemindersQuery$data['sys_reminders'][0];

export const packageReportSidebarCreateCommentMutation = graphql`
  mutation recoilCreateCommentMutation(
    $author_id: Int
    $property_batch_id: Int
    $package_report_id: Int
    $text: String
    $comment_type_id: Int
  ) {
    insert_sys_comments(
      objects: {
        author_id: $author_id
        property_batch_id: $property_batch_id
        package_report_id: $package_report_id
        text: $text
        comment_type_id: $comment_type_id
      }
    ) {
      affected_rows
      returning {
        author_id
        comment_id
        comment_type_id
        text
        created_at_datetime
      }
    }
  }
` as GraphQLReturn<recoilCreateCommentMutation>;

export const getPackageReportComments = graphQLSelector({
  query: graphql`
    subscription recoilGetPackageReportCommentsQuery(
      $package_report_id: Int!
      $property_batch_id: Int!
    ) {
      sys_comments(
        where: {
          package_report_id: { _eq: $package_report_id }
          property_batch_id: { _eq: $property_batch_id }
        }
      ) {
        user {
          sakura_user {
            first_name
            last_name
          }
        }
        comment_id
        comment_type_id
        created_at_datetime
        text
      }
    }
  ` as GraphQLReturn<recoilGetPackageReportCommentsQuery>,
  mapVariables:
    ({
      package_report_id,
      property_batch_id,
    }: {
      package_report_id: number;
      property_batch_id: number;
    }) =>
    () => ({
      package_report_id,
      property_batch_id,
    }),
  mapResponse: (response) => response.sys_comments,
});

export const packageReportSidebarCreateReminderMutation = graphql`
  mutation recoilCreateReminderMutation($params: create_reminder_params!) {
    _sys_create_reminder(params: $params) {
      reminder_ids
    }
  }
` as GraphQLReturn<recoilCreateReminderMutation>;

export const getPackageReportResolvedReminders = graphQLSelector({
  query: graphql`
    subscription recoilGetPackageReportResolvedRemindersQuery(
      $package_report_id: Int!
      $property_batch_id: Int!
    ) {
      sys_reminders(
        where: {
          package_report_id: { _eq: $package_report_id }
          property_batch_id: { _eq: $property_batch_id }
          is_resolved: { _eq: true }
        }
        order_by: { created_at_datetime: asc }
      ) {
        user {
          sakura_user {
            first_name
            last_name
          }
        }
        reminder_id
        is_resolved
        created_at_datetime
        text
      }
    }
  ` as GraphQLReturn<recoilGetPackageReportResolvedRemindersQuery>,
  mapVariables:
    ({
      package_report_id,
      property_batch_id,
    }: {
      package_report_id: number;
      property_batch_id: number;
    }) =>
    () => ({
      package_report_id,
      property_batch_id,
    }),
  mapResponse: (response) => response.sys_reminders,
});

export const getPackageReportUnresolvedReminders = graphQLSelector({
  query: graphql`
    subscription recoilGetPackageReportUnresolvedRemindersQuery(
      $package_report_id: Int!
      $property_batch_id: Int!
    ) {
      sys_reminders(
        where: {
          package_report_id: { _eq: $package_report_id }
          property_batch_id: { _eq: $property_batch_id }
          is_resolved: { _eq: false }
        }
        order_by: { created_at_datetime: asc }
      ) {
        user {
          sakura_user {
            first_name
            last_name
          }
        }
        reminder_id
        is_resolved
        created_at_datetime
        text
      }
    }
  ` as GraphQLReturn<recoilGetPackageReportUnresolvedRemindersQuery>,
  mapVariables:
    ({
      package_report_id,
      property_batch_id,
    }: {
      package_report_id: number;
      property_batch_id: number;
    }) =>
    () => ({
      package_report_id,
      property_batch_id,
    }),
  mapResponse: (response) => response.sys_reminders,
});

export const markReminderResolvedMutation = graphql`
  mutation recoilmarkReminderResolvedMutation(
    $params: toggle_reminder_resolved_params!
  ) {
    _sys_toggle_reminder_resolved(params: $params) {
      reminder_ids
    }
  }
` as GraphQLReturn<recoilmarkReminderResolvedMutation>;

export const packageReportSidebarState = atom<{
  [key: string]: {
    sidebarOpen: boolean;
    showCompletedReminders: boolean;
    comment: string;
    reminder: string;
    tab: number;
  };
}>({
  key: 'submission-batch-details/package-report-sidebars',
  default: {},
});

export const packageReportSidebarOpenState = selectorFamily<
  boolean,
  string | undefined
>({
  key: 'submission-batch-details/package-report-sidebar-open',
  get:
    (packageReportSlug?: string) =>
    ({ get }) => {
      if (!packageReportSlug) {
        return false;
      }
      return (
        get(packageReportSidebarState)[packageReportSlug]?.sidebarOpen ?? false
      );
    },
  set:
    (packageReportSlug?: string) =>
    ({ get, set }, newValue) => {
      if (!packageReportSlug) {
        throw new Error('packageReportSlug is required');
      }

      const current = get(packageReportSidebarState);
      set(packageReportSidebarState, {
        ...current,
        [packageReportSlug]: {
          ...current[packageReportSlug],
          sidebarOpen: newValue instanceof DefaultValue ? false : newValue,
        },
      });
    },
});

export const packageReportSidebarTabState = selectorFamily<
  number,
  string | undefined
>({
  key: 'submission-batch-details/package-report-sidebar-tab',
  get:
    (packageReportSlug?: string) =>
    ({ get }) => {
      if (!packageReportSlug) {
        return 0;
      }
      return get(packageReportSidebarState)[packageReportSlug]?.tab ?? 0;
    },
  set:
    (packageReportSlug?: string) =>
    ({ get, set }, newValue) => {
      if (!packageReportSlug) {
        throw new Error('packageReportSlug is required');
      }

      const current = get(packageReportSidebarState);
      set(packageReportSidebarState, {
        ...current,
        [packageReportSlug]: {
          ...current[packageReportSlug],
          tab: newValue instanceof DefaultValue ? 0 : newValue,
        },
      });
    },
});

export const packageReportSidebarCommentState = selectorFamily<
  string,
  string | undefined
>({
  key: 'submission-batch-details/package-report-sidebar-comment',
  get:
    (packageReportSlug?: string) =>
    ({ get }) => {
      if (!packageReportSlug) {
        return '';
      }
      return get(packageReportSidebarState)[packageReportSlug]?.comment ?? '';
    },
  set:
    (packageReportSlug?: string) =>
    ({ get, set }, newValue) => {
      if (!packageReportSlug) {
        throw new Error('packageReportSlug is required');
      }

      const current = get(packageReportSidebarState);
      set(packageReportSidebarState, {
        ...current,
        [packageReportSlug]: {
          ...current[packageReportSlug],
          comment: newValue instanceof DefaultValue ? '' : newValue,
        },
      });
    },
});

export const packageReportSidebarReminderState = selectorFamily<
  string,
  string | undefined
>({
  key: 'submission-batch-details/package-report-sidebar-comment',
  get:
    (packageReportSlug?: string) =>
    ({ get }) => {
      if (!packageReportSlug) {
        return '';
      }
      return get(packageReportSidebarState)[packageReportSlug]?.reminder ?? '';
    },
  set:
    (packageReportSlug?: string) =>
    ({ get, set }, newValue) => {
      if (!packageReportSlug) {
        throw new Error('packageReportSlug is required');
      }

      const current = get(packageReportSidebarState);
      set(packageReportSidebarState, {
        ...current,
        [packageReportSlug]: {
          ...current[packageReportSlug],
          reminder: newValue instanceof DefaultValue ? '' : newValue,
        },
      });
    },
});

export const packageReportSidebarShowCompletedState = selectorFamily<
  boolean,
  string | undefined
>({
  key: 'submission-batch-details/package-report-sidebar-open',
  get:
    (packageReportSlug?: string) =>
    ({ get }) => {
      if (!packageReportSlug) {
        return false;
      }
      return (
        get(packageReportSidebarState)[packageReportSlug]
          ?.showCompletedReminders ?? false
      );
    },
  set:
    (packageReportSlug?: string) =>
    ({ get, set }, newValue) => {
      if (!packageReportSlug) {
        throw new Error('packageReportSlug is required');
      }

      const current = get(packageReportSidebarState);
      set(packageReportSidebarState, {
        ...current,
        [packageReportSlug]: {
          ...current[packageReportSlug],
          showCompletedReminders:
            newValue instanceof DefaultValue ? false : newValue,
        },
      });
    },
});

export const useMarkPackageReportRemindersReviewedMutation = () => {
  return useMutation(
    graphql`
      mutation recoilUseMarkPackageReportRemindersResolvedMutation(
        $params: create_reminders_reviewed_params!
      ) {
        _sys_create_reminders_reviewed(params: $params) {
          reminders_reviewed_ids
        }
      }
    ` as GraphQLReturn<recoilUseMarkPackageReportRemindersResolvedMutation>,
    {
      mapVariables: (reminders: Reminder[]) => async () => {
        const reminder_ids = reminders.map((reminder) => reminder.reminder_id);

        return {
          params: {
            reminder_ids,
          },
        };
      },
    }
  );
};

export const $submissionId = selector({
  key: 'package-report-modal-submission-id',
  get: ({ get }) => {
    const valuesLoadable = get(
      waitForAny([
        SubmissionRoute.routeParamSelector,
        submissionOverviewDialogSubmissionIdState,
      ])
    );
    const resolved = valuesLoadable.find((v) => v.state === 'hasValue');

    if (!resolved) {
      throw new Error('no matching route');
    }

    const value = resolved.getValue();

    if (!value) {
      throw new Error('no value');
    }

    if (typeof value === 'number') {
      return value;
    }

    return value.submission_id;
  },
});
