/**
 * @generated SignedSource<<b68973f495d6dea895657bcc15afa292>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilGetUnresolvedRemindersSubscription$variables = {
  entity_ids?: ReadonlyArray<string> | null;
  submission_type_id: number;
};
export type recoilGetUnresolvedRemindersSubscription$data = {
  readonly sys_reminders: ReadonlyArray<{
    readonly package_report: {
      readonly package_reports_datasets: ReadonlyArray<{
        readonly dataset_id: number;
      }>;
    };
    readonly property: {
      readonly entity_id: string;
    };
    readonly property_batch_id: number;
    readonly reminder_id: number;
    readonly submission: {
      readonly submission_id: number;
      readonly submission_type: {
        readonly submission_type_id: number;
        readonly submission_type_label: string;
      };
    };
    readonly text: string;
  }>;
};
export type recoilGetUnresolvedRemindersSubscription = {
  response: recoilGetUnresolvedRemindersSubscription$data;
  variables: recoilGetUnresolvedRemindersSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity_ids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submission_type_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_resolved",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_in",
                    "variableName": "entity_ids"
                  }
                ],
                "kind": "ObjectValue",
                "name": "entity_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "submission_type_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "submission_type_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "submission_type"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_reminders",
    "kind": "LinkedField",
    "name": "sys_reminders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reminder_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submission_types",
            "kind": "LinkedField",
            "name": "submission_type",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_type_label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_type_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_package_reports",
        "kind": "LinkedField",
        "name": "package_report",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_package_reports_datasets",
            "kind": "LinkedField",
            "name": "package_reports_datasets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataset_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetUnresolvedRemindersSubscription",
    "selections": (v2/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilGetUnresolvedRemindersSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "609f5a39239ff883b8febe9c36b51c67",
    "id": null,
    "metadata": {},
    "name": "recoilGetUnresolvedRemindersSubscription",
    "operationKind": "subscription",
    "text": "subscription recoilGetUnresolvedRemindersSubscription(\n  $submission_type_id: Int!\n  $entity_ids: [String!]\n) {\n  sys_reminders(where: {submission: {submission_type: {submission_type_id: {_eq: $submission_type_id}}}, property: {entity_id: {_in: $entity_ids}}, is_resolved: {_eq: false}}) {\n    property_batch_id\n    reminder_id\n    text\n    submission {\n      submission_id\n      submission_type {\n        submission_type_label\n        submission_type_id\n      }\n    }\n    property {\n      entity_id\n    }\n    package_report {\n      package_reports_datasets {\n        dataset_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc3e8915f9b5ea6e2db29a022b88ed53";

export default node;
