import {
  graphQLSelector,
  GraphQLReturn,
  NotFoundError,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { querySummaryInfoQuery } from './__generated__/querySummaryInfoQuery.graphql';
import { SingleFileValidationSummaryRoute } from 'src/products/data-submission-portal/routes';

export const summaryInfo = graphQLSelector({
  query: graphql`
    query querySummaryInfoQuery($dataset_id: Int!) {
      sys_property_batches(
        where: {
          property_batch_datasets: {
            property_batch_dataset_id: { _eq: $dataset_id }
          }
        }
      ) {
        property_batch_stage_id
        submission_id
        submission {
          submission_name
        }
        property {
          property_id
          property_name
        }
        property_batch_datasets(
          where: { property_batch_dataset_id: { _eq: $dataset_id } }
        ) {
          property_batch_dataset_id
          dataset {
            dataset_label
          }
        }
      }
    }
  ` as GraphQLReturn<querySummaryInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(SingleFileValidationSummaryRoute.routeParamSelector),
  mapResponse: (rawResponse, variables) => {
    const batch = rawResponse.sys_property_batches[0];
    if (!batch) {
      throw new NotFoundError(
        `batch with property_batch_dataset_id ${variables.dataset_id} not found`
      );
    }
    const property = batch.property;
    if (!property) {
      throw new NotFoundError(
        `property with property_batch_dataset_id ${variables.dataset_id} not found`
      );
    }
    const dataset = batch.property_batch_datasets[0]?.dataset;
    if (!dataset) {
      throw new NotFoundError(
        `dataset with property_batch_dataset_id ${variables.dataset_id} not found`
      );
    }
    return {
      submission_id: batch.submission_id,
      submission_name: batch.submission.submission_name,
      property_id: property.property_id,
      property_name: property.property_name,
      dataset_label: dataset.dataset_label,
      property_batch_stage_id: batch.property_batch_stage_id,
      property_batch_dataset_id:
        batch.property_batch_datasets[0].property_batch_dataset_id,
    };
  },
});
