/**
 * @generated SignedSource<<2a51ac93fa7670ae2a01c7487564eaa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilGetAllUnresolvedRemindersCountSubscription$variables = {};
export type recoilGetAllUnresolvedRemindersCountSubscription$data = {
  readonly sys_reminders: ReadonlyArray<{
    readonly property: {
      readonly entity_id: string;
    };
    readonly property_batch: {
      readonly submission: {
        readonly submission_type: {
          readonly submission_type_label: string;
        };
      };
    };
  }>;
};
export type recoilGetAllUnresolvedRemindersCountSubscription = {
  response: recoilGetAllUnresolvedRemindersCountSubscription$data;
  variables: recoilGetAllUnresolvedRemindersCountSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "is_resolved": {
            "_eq": false
          },
          "submission": {
            "is_closed": {
              "_eq": true
            }
          }
        }
      }
    ],
    "concreteType": "sys_reminders",
    "kind": "LinkedField",
    "name": "sys_reminders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submissions",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_submission_types",
                "kind": "LinkedField",
                "name": "submission_type",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submission_type_label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_reminders(where:{\"is_resolved\":{\"_eq\":false},\"submission\":{\"is_closed\":{\"_eq\":true}}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetAllUnresolvedRemindersCountSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "recoilGetAllUnresolvedRemindersCountSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "31a7d137d907dc6e82d9be58dd7df7d8",
    "id": null,
    "metadata": {},
    "name": "recoilGetAllUnresolvedRemindersCountSubscription",
    "operationKind": "subscription",
    "text": "subscription recoilGetAllUnresolvedRemindersCountSubscription {\n  sys_reminders(where: {is_resolved: {_eq: false}, submission: {is_closed: {_eq: true}}}) {\n    property {\n      entity_id\n    }\n    property_batch {\n      submission {\n        submission_type {\n          submission_type_label\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "926c37a30633a0c2a3f45bbdedf5bf40";

export default node;
