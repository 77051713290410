/**
 * @generated SignedSource<<57935dc4089955e66741e09e1c7739c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilCanReassignUserQuery$variables = {
  id: number;
};
export type recoilCanReassignUserQuery$data = {
  readonly _sys_can_reassign_user: {
    readonly entities: ReadonlyArray<{
      readonly entity_id: string;
      readonly submissions: ReadonlyArray<{
        readonly submission_id: number;
        readonly submission_name: string;
      }>;
    }>;
  } | null;
};
export type recoilCanReassignUserQuery = {
  response: recoilCanReassignUserQuery$data;
  variables: recoilCanReassignUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "user_id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "can_reassign_user_output",
    "kind": "LinkedField",
    "name": "_sys_can_reassign_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "can_reassign_user_entity",
        "kind": "LinkedField",
        "name": "entities",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "can_reassign_user_submission",
            "kind": "LinkedField",
            "name": "submissions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilCanReassignUserQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilCanReassignUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "578e58c6a12cbe5c9166deaf6c43c334",
    "id": null,
    "metadata": {},
    "name": "recoilCanReassignUserQuery",
    "operationKind": "query",
    "text": "query recoilCanReassignUserQuery(\n  $id: Int!\n) {\n  _sys_can_reassign_user(params: {user_id: $id}) {\n    entities {\n      entity_id\n      submissions {\n        submission_id\n        submission_name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "16fa91e0095097cfbee5c4dc12988930";

export default node;
