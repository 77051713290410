/**
 * @generated SignedSource<<e21114deceb054b77ef894b12883323e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetExistingProviderQuery$variables = {
  name: string;
  provider_ids?: ReadonlyArray<number> | null;
};
export type recoilGetExistingProviderQuery$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly provider_id: number;
    readonly provider_name: string;
    readonly provider_type_id: number;
  }>;
};
export type recoilGetExistingProviderQuery = {
  response: recoilGetExistingProviderQuery$data;
  variables: recoilGetExistingProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_nin",
                "variableName": "provider_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_name"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_type_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetExistingProviderQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetExistingProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3608425d2b756331bbcda64871e5bf53",
    "id": null,
    "metadata": {},
    "name": "recoilGetExistingProviderQuery",
    "operationKind": "query",
    "text": "query recoilGetExistingProviderQuery(\n  $name: String!\n  $provider_ids: [Int!]\n) {\n  sys_providers(where: {provider_name: {_eq: $name}, provider_id: {_nin: $provider_ids}}) {\n    provider_name\n    provider_id\n    provider_type_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "80744201bde9d3e829ee5e8074472048";

export default node;
