import {
  useCherreState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  SearchInput,
  PageContainer,
  PageContent,
  Panel,
  MultiFilter,
} from '@cherre-frontend/ui';
import React from 'react';
import AccountGroupingDetailHeader from './components/Header';
import AccountGroupingDetailTable from './components/AccountGroupingDetailTable';
import {
  accountGroupingDetailPageTableSearch,
  accountGroupingDetailsHasData,
  accountGroupingDetailTableFiltersState,
} from './recoil';
import {
  getCodeDescriptionList,
  getGlCodesList,
  getGroupCodesList,
  getNestsList,
  getTotalIntoCodesList,
  getTypesList,
} from './queries/getAccountTreeDetail';

const AccountGroupingDetailPage: React.FC = () => {
  const hasData = useCherreValue(accountGroupingDetailsHasData);

  const [filters, setFilters] = useCherreState(
    accountGroupingDetailTableFiltersState
  );
  const [search, , setSearch] = useCherreStateDebounced(
    accountGroupingDetailPageTableSearch
  );
  const types = useCherreValue(getTypesList());
  const groupCodes = useCherreValue(getGroupCodesList());
  const groupDesc = useCherreValue(getCodeDescriptionList());
  const glCodes = useCherreValue(getGlCodesList());
  const totalIntoCodes = useCherreValue(getTotalIntoCodesList());
  const nestList = useCherreValue(getNestsList());

  return (
    <>
      <AccountGroupingDetailHeader />
      <PageContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Panel id='masterFileDetailSearch'>
            <SearchInput
              defaultValue={search ?? ''}
              sx={{ width: '200px' }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Panel>
          {hasData && (
            <>
              <MultiFilter
                options={
                  types?.map((code) => ({ label: code, value: code })) ?? []
                }
                selected={filters?.account_group_record_type ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    account_group_record_type: selected,
                  }))
                }
                label='Account Group Record Type'
              />
              <MultiFilter
                options={
                  groupCodes
                    ? groupCodes
                        .filter((value) => value !== null)
                        .map((name) => ({
                          label: name as string,
                          value: name as string,
                        }))
                    : []
                }
                selected={filters?.account_group_code ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    account_group_code: selected,
                  }))
                }
                label='Account Group Code'
              />
              <MultiFilter
                options={
                  groupDesc?.map((name) => ({ label: name, value: name })) ?? []
                }
                selected={filters?.account_group_description ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    account_group_description: selected,
                  }))
                }
                label='Account Group Description'
              />
              <MultiFilter
                options={
                  glCodes
                    ? glCodes
                        .filter((value) => value !== null)
                        .map((name) => ({
                          label: name as string,
                          value: name as string,
                        }))
                    : []
                }
                selected={filters?.gl_account_code ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    gl_account_code: selected,
                  }))
                }
                label='GL Account Code'
              />
              <MultiFilter
                options={
                  totalIntoCodes
                    ? totalIntoCodes
                        .filter((value) => value !== null)
                        .map((name) => ({
                          label: name as string,
                          value: name as string,
                        }))
                    : []
                }
                selected={filters?.total_into_code ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    total_into_code: selected,
                  }))
                }
                label='Total Into Code'
              />
              <MultiFilter
                options={
                  nestList?.map((name) => ({
                    label: name.toString(),
                    value: name.toString(),
                  })) ?? []
                }
                selected={filters?.nest ?? []}
                onChange={(selected: string[]) =>
                  setFilters((prevState) => ({
                    ...prevState,
                    nest: selected,
                  }))
                }
                label='Nest'
              />
            </>
          )}
        </Box>

        <Panel id='accountGroupingDetails'>
          <AccountGroupingDetailTable />
        </Panel>
      </PageContent>
    </>
  );
};

const AccountGroupingDetailWrapper: React.FC = () => {
  return (
    <PageContainer id='AccountGroupingDetailsPage'>
      <AccountGroupingDetailPage />
    </PageContainer>
  );
};

AccountGroupingDetailWrapper.displayName = 'AccountGroupingDetailWrapper';

export default AccountGroupingDetailWrapper;
