/**
 * @generated SignedSource<<297b84f3deaff50230bfc636d9ffe173>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getOrganizationProvidersQuery$variables = {
  org_id: number;
};
export type getOrganizationProvidersQuery$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly provider_id: number;
    readonly provider_name: string;
  }>;
};
export type getOrganizationProvidersQuery = {
  response: getOrganizationProvidersQuery$data;
  variables: getOrganizationProvidersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "org_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "provider_name": "asc"
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "org_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "organization_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getOrganizationProvidersQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getOrganizationProvidersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a6978ca1c3089f4a46b4b82f3be58ed7",
    "id": null,
    "metadata": {},
    "name": "getOrganizationProvidersQuery",
    "operationKind": "query",
    "text": "query getOrganizationProvidersQuery(\n  $org_id: Int!\n) {\n  sys_providers(where: {organization_id: {_eq: $org_id}}, order_by: {provider_name: asc}) {\n    provider_id\n    provider_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "4adb6738f2fb265a833e4a41e22c3d0b";

export default node;
