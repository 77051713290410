/**
 * @generated SignedSource<<992726be713cd386bee11d1a84535f83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type report_table_data_aggregate_input = {
  query_arguments?: any | null;
  report_params?: any | null;
  report_slug: string;
  search?: string | null;
  table_slug: string;
  where?: any | null;
};
export type ReportTableAggQuery$variables = {
  params: report_table_data_aggregate_input;
};
export type ReportTableAggQuery$data = {
  readonly _sys_get_report_table_data_aggregate: {
    readonly count: number;
  };
};
export type ReportTableAggQuery = {
  response: ReportTableAggQuery$data;
  variables: ReportTableAggQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "report_table_data_aggregate_output",
    "kind": "LinkedField",
    "name": "_sys_get_report_table_data_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportTableAggQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportTableAggQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1482cd7123024812ba20d0a2932e593e",
    "id": null,
    "metadata": {},
    "name": "ReportTableAggQuery",
    "operationKind": "query",
    "text": "query ReportTableAggQuery(\n  $params: report_table_data_aggregate_input!\n) {\n  _sys_get_report_table_data_aggregate(params: $params) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "92f80221d891032e78828056e2e26265";

export default node;
