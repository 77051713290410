/**
 * @generated SignedSource<<0d2ecad7120e86893c8cac1de9b0822c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getSubmissionsSubmissionTypesFilterSubscription$variables = {};
export type getSubmissionsSubmissionTypesFilterSubscription$data = {
  readonly sys_submission_types: ReadonlyArray<{
    readonly submission_type_label: string;
  }>;
};
export type getSubmissionsSubmissionTypesFilterSubscription = {
  response: getSubmissionsSubmissionTypesFilterSubscription$data;
  variables: getSubmissionsSubmissionTypesFilterSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "submission_type_label"
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "submission_type_label": "asc"
        }
      }
    ],
    "concreteType": "sys_submission_types",
    "kind": "LinkedField",
    "name": "sys_submission_types",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_type_label",
        "storageKey": null
      }
    ],
    "storageKey": "sys_submission_types(distinct_on:\"submission_type_label\",order_by:{\"submission_type_label\":\"asc\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSubmissionsSubmissionTypesFilterSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSubmissionsSubmissionTypesFilterSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4701a443780779cc4aaf58ba5e80ec09",
    "id": null,
    "metadata": {},
    "name": "getSubmissionsSubmissionTypesFilterSubscription",
    "operationKind": "subscription",
    "text": "subscription getSubmissionsSubmissionTypesFilterSubscription {\n  sys_submission_types(distinct_on: submission_type_label, order_by: {submission_type_label: asc}) {\n    submission_type_label\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfee2d9e930d52caac02b89f30ac968f";

export default node;
