import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyOpenedBatchesQuery } from './__generated__/getPropertyOpenedBatchesQuery.graphql';

export const getPropertyOpenedBatches = graphQLSelector({
  query: graphql`
    query getPropertyOpenedBatchesQuery($property_id: Int!) {
      sys_submissions(
        where: {
          is_closed: { _eq: false }
          property_batches: {
            property: {
              is_active: { _eq: true }
              property_id: { _eq: $property_id }
            }
          }
        }
        order_by: { submission_name: asc }
      ) {
        submission_id
        submission_name
      }
    }
  ` as GraphQLReturn<getPropertyOpenedBatchesQuery>,
  mapResponse: (response) => response.sys_submissions,
  mapVariables: (property_id: number) => () => ({ property_id }),
});
