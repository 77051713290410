import {
  Box,
  Checkbox,
  Chip,
  ChipProps,
  Grid,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
  TypographyProps,
  styled,
  FormLabel,
  FormLabelProps,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { requiredValidationSlugs } from '../consts';

const ValidationTitle = styled<React.FC<TypographyProps>>(Typography)`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
`;

const ValidationDescription = styled<React.FC<TypographyProps>>(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
`;

const StyledRadio = styled<React.FC<RadioProps<string>>>(Radio)`
  padding: 0px;
`;

const HardCheckLabel = styled<React.FC<FormLabelProps>>(FormLabel)`
  color: 'black';
`;

const RequiredChip = styled<React.FC<ChipProps>>(Chip)`
  height: 18px;
  .MuiChip-label {
    font-size: 10px;
  }
`;

type ValidationRuleSelectionProps = {
  title: string;
  description: string;
  id: number;
  slug: string;
  enabled: boolean;
  value: 'soft' | 'hard';
  onEnabledChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidationModeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ValidationRuleSelection: React.FC<ValidationRuleSelectionProps> = ({
  title,
  description,
  slug,
  value,
  enabled,
  onEnabledChange,
  onValidationModeChange,
}) => {
  const required = useMemo<boolean>(() => {
    return requiredValidationSlugs.includes(slug);
  }, [title]);

  return (
    <Grid container direction='row' flexWrap='nowrap' gap={2}>
      <Grid item sx={{ width: '24px' }}>
        {!required && (
          <Checkbox
            checked={enabled}
            value={enabled}
            onChange={onEnabledChange}
            sx={{ padding: 0 }}
          />
        )}
      </Grid>

      <Grid item flex={64}>
        <ValidationTitle>
          {title}{' '}
          {required ? <RequiredChip label='Required' size='small' /> : null}
        </ValidationTitle>
        <ValidationDescription>{description}</ValidationDescription>
        {required ? (
          <HardCheckLabel>Hard check</HardCheckLabel>
        ) : (
          <Box>
            {enabled ? (
              <RadioGroup row value={value} onChange={onValidationModeChange}>
                <StyledRadio value='soft' label='Soft check' />
                <StyledRadio value='hard' label='Hard check' />
              </RadioGroup>
            ) : null}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

ValidationRuleSelection.displayName = 'ValidationRuleSelection';

export default ValidationRuleSelection;
