import {
  GraphQLReturn,
  graphQLSelector,
  useMutationAsync,
  useRecoilScope,
} from '@cherre-frontend/data-fetching';
import { useCallback } from 'react';
import { graphql } from 'react-relay';
import { useIsMounted } from '@cherre-frontend/core';
import { useRecoilCallback } from 'recoil';
import { runSubmissionValidationMutation } from './__generated__/runSubmissionValidationMutation.graphql';
import { runSubmissionValidationQuery } from './__generated__/runSubmissionValidationQuery.graphql';

const runSubmissionValidationQuery = graphQLSelector({
  query: graphql`
    query runSubmissionValidationQuery($id: uuid!) {
      _sys_validation_property_batch_async(id: $id) {
        output {
          ok
        }
        errors
      }
    }
  ` as GraphQLReturn<runSubmissionValidationQuery>,
});

export const useAsyncRunSubmissionValidation = () => {
  const isMounted = useIsMounted();
  const scope = useRecoilScope();

  const mutation = useMutationAsync(
    graphql`
      mutation runSubmissionValidationMutation($property_batch_id: Int!) {
        _sys_validation_property_batch_async(
          params: { property_batch_id: $property_batch_id }
        )
      }
    ` as GraphQLReturn<runSubmissionValidationMutation>
  );

  const getDataFromId = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const release = snapshot.retain();
        try {
          return snapshot.getPromise(
            runSubmissionValidationQuery({ id })(scope)
          );
        } finally {
          release();
        }
      }
  );

  const wrapper = useCallback(
    async (param: runSubmissionValidationMutation['variables']) => {
      const { _sys_validation_property_batch_async: id } = await mutation({
        ...param,
      });

      return new Promise<
        NonNullable<
          runSubmissionValidationQuery['response']['_sys_validation_property_batch_async']
        >
      >((resolve, _reject) => {
        const refresh = async () => {
          const data = await getDataFromId(id);
          if (
            data._sys_validation_property_batch_async?.output ||
            data._sys_validation_property_batch_async?.errors
          ) {
            resolve(data._sys_validation_property_batch_async);
          } else {
            isMounted() && setTimeout(refresh, 1000);
          }
        };
        refresh();
      });
    },
    [mutation, isMounted, getDataFromId]
  );

  return wrapper;
};
