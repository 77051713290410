import { useCherreValue } from '@cherre-frontend/data-fetching';
import React from 'react';
import { $canReassignUser } from '../recoil';
import {
  Typography,
  styled,
  Alert,
  WarningIcon,
  Link as MuiLink,
} from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';

export const CAUTION_TEXT =
  'Note: User will be removed from all existing property and role assignments at their current organization or service provider.';

export const ALERT_TEXT =
  'This user is the sole user assigned to the workflow levels for the open submission batches below. Please close the batches or assign at least one user to the workflow levels.';

export type BannerProps = {
  userId: number;
};

const Note = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 17.5px; /* 145.833% */
  letter-spacing: 0.12px;
`;

const AlertStyled = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.error.light};
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.accents.error.main};
  & .MuiAlert-icon {
    color: ${({ theme }) => theme.accents.error.main};
  }
  & .MuiAlert-message {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  padding: 6px;
  margin-bottom: 20px;
`;

const EntityList = styled('div')`
  max-height: 125px;
  overflow-y: auto;
`;

const Entity = styled('div')`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

const Banner: React.FC<BannerProps> = ({ userId }) => {
  const openBatches = useCherreValue($canReassignUser(userId));
  const hasOpenBatches = Boolean(openBatches?.length);
  if (!hasOpenBatches) {
    return (
      <Note>
        <span className='suspend'>{CAUTION_TEXT}</span>
      </Note>
    );
  }
  return (
    <>
      <AlertStyled severity='error' icon={<WarningIcon />}>
        {ALERT_TEXT}
      </AlertStyled>
      <EntityList>
        {openBatches?.map((o) => (
          <Entity key={o.entity_id}>
            Entity ID {o.entity_id}
            <ul>
              {o.submissions.map((s) => (
                <li key={s.submission_id}>
                  <MuiLink
                    component={(props) => <Link {...props} target='_blank' />}
                    underline='hover'
                    to={`/dsp/view_admin/submissions/${s.submission_id}`}
                  >
                    {s.submission_name}
                  </MuiLink>
                </li>
              ))}
            </ul>
          </Entity>
        ))}
      </EntityList>
    </>
  );
};

export default Banner;
