/**
 * @generated SignedSource<<6cbb2a86c7f4780fdce284dac1d68f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getFlatfileTokenQuery$variables = {
  dataset_id: number;
};
export type getFlatfileTokenQuery$data = {
  readonly _sys_get_flatfile_token: {
    readonly api: string;
    readonly mount: string;
    readonly token: string;
  } | null;
};
export type getFlatfileTokenQuery = {
  response: getFlatfileTokenQuery$data;
  variables: getFlatfileTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dataset_id",
        "variableName": "dataset_id"
      }
    ],
    "concreteType": "flatfile_token_output",
    "kind": "LinkedField",
    "name": "_sys_get_flatfile_token",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "api",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getFlatfileTokenQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getFlatfileTokenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6d8b8e1eb9125d0c65a4a75b68dd2c6",
    "id": null,
    "metadata": {},
    "name": "getFlatfileTokenQuery",
    "operationKind": "query",
    "text": "query getFlatfileTokenQuery(\n  $dataset_id: Int!\n) {\n  _sys_get_flatfile_token(dataset_id: $dataset_id) {\n    token\n    mount\n    api\n  }\n}\n"
  }
};
})();

(node as any).hash = "22a9aae517aeed14e6d0964c3d1770d1";

export default node;
