/**
 * @generated SignedSource<<92705cbe1a145c432162317fd130976d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type recoilCreateCommentMutation$variables = {
  author_id?: number | null;
  comment_type_id?: number | null;
  package_report_id?: number | null;
  property_batch_id?: number | null;
  text?: string | null;
};
export type recoilCreateCommentMutation$data = {
  readonly insert_sys_comments: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly author_id: number;
      readonly comment_id: number;
      readonly comment_type_id: number;
      readonly created_at_datetime: string;
      readonly text: string;
    }>;
  } | null;
};
export type recoilCreateCommentMutation = {
  response: recoilCreateCommentMutation$data;
  variables: recoilCreateCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "author_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment_type_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "package_report_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_batch_id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "author_id",
            "variableName": "author_id"
          },
          {
            "kind": "Variable",
            "name": "comment_type_id",
            "variableName": "comment_type_id"
          },
          {
            "kind": "Variable",
            "name": "package_report_id",
            "variableName": "package_report_id"
          },
          {
            "kind": "Variable",
            "name": "property_batch_id",
            "variableName": "property_batch_id"
          },
          {
            "kind": "Variable",
            "name": "text",
            "variableName": "text"
          }
        ],
        "kind": "ObjectValue",
        "name": "objects"
      }
    ],
    "concreteType": "sys_comments_mutation_response",
    "kind": "LinkedField",
    "name": "insert_sys_comments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_comments",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment_type_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at_datetime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilCreateCommentMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilCreateCommentMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "9be23162fe584be21507aa171aac79e2",
    "id": null,
    "metadata": {},
    "name": "recoilCreateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation recoilCreateCommentMutation(\n  $author_id: Int\n  $property_batch_id: Int\n  $package_report_id: Int\n  $text: String\n  $comment_type_id: Int\n) {\n  insert_sys_comments(objects: {author_id: $author_id, property_batch_id: $property_batch_id, package_report_id: $package_report_id, text: $text, comment_type_id: $comment_type_id}) {\n    affected_rows\n    returning {\n      author_id\n      comment_id\n      comment_type_id\n      text\n      created_at_datetime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "491a977a1c990c1e73489a72663be2b8";

export default node;
