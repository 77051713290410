import React from 'react';
import { Grid, Typography, styled } from '@cherre-frontend/ui';

const Container = styled(Grid)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

type NoContentMessageProps = {
  message: string;
};

const NoContentMessage: React.FC<NoContentMessageProps> = ({ message }) => {
  return (
    <Container>
      <Typography>{message}</Typography>
    </Container>
  );
};

export default NoContentMessage;
