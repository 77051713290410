/**
 * @generated SignedSource<<b8e5e5186ae7661aadb5b3f9a889a0f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilgetSubmissionUsersQuery$variables = {
  property_batch_id?: number | null;
};
export type recoilgetSubmissionUsersQuery$data = {
  readonly sys_property_batches_by_pk: {
    readonly users_with_access_to_batch: ReadonlyArray<{
      readonly property_role_id: number;
      readonly property_role_user_id: number;
      readonly user: {
        readonly sakura_user: {
          readonly email: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
        } | null;
      };
    }> | null;
  } | null;
};
export type recoilgetSubmissionUsersQuery = {
  response: recoilgetSubmissionUsersQuery$data;
  variables: recoilgetSubmissionUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 719,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "property_batch_id",
        "variableName": "property_batch_id"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order_by",
            "value": {
              "user": {
                "sakura_user": {
                  "first_name": "asc",
                  "last_name": "asc"
                }
              }
            }
          }
        ],
        "concreteType": "sys_properties_roles_users",
        "kind": "LinkedField",
        "name": "users_with_access_to_batch",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_role_user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_role_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sakura_user",
                "kind": "LinkedField",
                "name": "sakura_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "users_with_access_to_batch(order_by:{\"user\":{\"sakura_user\":{\"first_name\":\"asc\",\"last_name\":\"asc\"}}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilgetSubmissionUsersQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilgetSubmissionUsersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d00622ac448d414f8bcdd4850c3826f9",
    "id": null,
    "metadata": {},
    "name": "recoilgetSubmissionUsersQuery",
    "operationKind": "query",
    "text": "query recoilgetSubmissionUsersQuery(\n  $property_batch_id: Int = 719\n) {\n  sys_property_batches_by_pk(property_batch_id: $property_batch_id) {\n    users_with_access_to_batch(order_by: {user: {sakura_user: {first_name: asc, last_name: asc}}}) {\n      property_role_user_id\n      property_role_id\n      user {\n        sakura_user {\n          email\n          first_name\n          last_name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69ae4d85d3b9d4d6663d8f038419d4dc";

export default node;
