import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDeleteMappingSetMutation } from './__generated__/useDeleteMappingSetMutation.graphql';

export const useDeleteMappingSet = (
  mapping_set_id: number,
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useDeleteMappingSetMutation($mapping_set_id: Int!) {
        update_sys_properties_mapping_sets(
          _set: { is_active: false }
          where: { mapping_set_id: { _eq: $mapping_set_id } }
        ) {
          affected_rows
        }
        update_sys_mapping_sets(
          _set: { is_active: false }
          where: { mapping_set_id: { _eq: $mapping_set_id } }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<useDeleteMappingSetMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        return { mapping_set_id };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
