import React from 'react';
import { Panel } from '@cherre-frontend/ui';
import {
  PackageReportsTable as Table,
  PackageReportsTableProps as TableProps,
} from './PackageReportsTable';
import {
  PackageReportsCombinedTable as CombinedTable,
  PackageReportsCombinedTableProps as CombinedTableProps,
} from './PackageReportsCombinedTable';
import PackageReportModal, {
  PackageReportModalProps as ModalProps,
} from './PackageReportsModal';

export type PackageReportsTableProps = TableProps & ModalProps;

export const PackageReportsTable: React.FC<PackageReportsTableProps> = (
  props
) => {
  return (
    <>
      <Panel id={`PackageReportsTable::${props.property_batch_id}`}>
        <Table {...props} />
      </Panel>
      <Panel id={`PackageReportsModal::${props.property_batch_id}`}>
        <PackageReportModal {...props} />
      </Panel>
    </>
  );
};

export type PackageReportsCombinedTableProps = CombinedTableProps &
  Pick<
    ModalProps,
    'modalSearchState' | 'reportSelectors' | 'reportSlugModalState'
  >;

export const PackageReportsCombinedTable: React.FC<
  PackageReportsCombinedTableProps
> = (props) => {
  return (
    <>
      <Panel id={`PackageReportsTable::${props.property_batch_ids.join('-')}`}>
        <CombinedTable {...props} />
      </Panel>
      <Panel id={`PackageReportsModal::${props.investmentPropertyBatchId}`}>
        <PackageReportModal
          {...props}
          property_batch_id={props.investmentPropertyBatchId}
        />
      </Panel>
    </>
  );
};
