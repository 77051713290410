import { constSelector, selector } from '@cherre-frontend/data-fetching';
import moment, { Moment } from 'moment';
import {
  formatMonthlyPeriod,
  formatQuarterlyPeriod,
  formatDueDateOption,
} from './formatters';
import { getSubmissionTypes } from './queries';

type Option<T> = { value: T; label: string };

export type SubmissionTypeOption = Option<number>;

export type SubmissionPeriodOption = Option<Moment>;

export const submissionTypeOptions = () =>
  selector<SubmissionTypeOption[]>({
    key: 'submissionTypeOptionsSelector',
    scoped: true,
    get:
      () =>
      ({ get }) =>
        get(getSubmissionTypes({})).map((st) => ({
          value: st.submission_type_id,
          label: st.submission_type_label,
        })),
  });

const startDateMonthly = () => moment().utc().startOf('month');

const monthlyOptions = () =>
  Array(26)
    .fill(null)
    .map((_, index) => {
      const value = startDateMonthly().subtract(index - 1, 'month');
      const label = formatMonthlyPeriod(value);
      return { value, label };
    });

const startDateQuarterly = () => moment().startOf('quarter');
const quarterlyOptions = () =>
  Array(10)
    .fill(null)
    .map((_, index) => {
      const value = startDateQuarterly().subtract(index - 1, 'quarter');
      const label = formatQuarterlyPeriod(value);
      return { value, label };
    });

export const submissionPeriodOptions = (
  submissionInterval: 'Monthly' | 'Quarterly' | undefined
) => {
  switch (submissionInterval) {
    case undefined:
      return constSelector([]);
    case 'Monthly':
      return constSelector(monthlyOptions());
    case 'Quarterly':
      return constSelector(quarterlyOptions());
    default:
      throw new Error(`interval ${submissionInterval} is not supported`);
  }
};

export type DueDateOption = Option<number>;

export const dueDateOptions = () =>
  constSelector<DueDateOption[]>(
    Array(15)
      .fill(null)
      .map((_, index) => {
        const value = index - 7;
        const label = formatDueDateOption(value);
        return { value, label };
      })
  );
