import { recoilGetPreparersQuery } from './__generated__/recoilGetPreparersQuery.graphql';
import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { recoilGetWorkflowAvailableActionsQuery } from './__generated__/recoilGetWorkflowAvailableActionsQuery.graphql';

export const getPreparers = graphQLSelector({
  query: graphql`
    query recoilGetPreparersQuery($user_ids: [Int!]) {
      sys_users(where: { user_id: { _in: $user_ids } }) {
        user_id
        sakura_user {
          first_name
          last_name
        }
      }
    }
  ` as GraphQLReturn<recoilGetPreparersQuery>,
});

export const getWorkflowAvailableActions = graphQLSelector({
  polling: true,
  query: graphql`
    query recoilGetWorkflowAvailableActionsQuery($property_batch_id: Int!) {
      _sys_workflow_get_available_actions_for_batch(
        params: { property_batch_id: $property_batch_id }
      ) {
        actions
      }
    }
  ` as GraphQLReturn<recoilGetWorkflowAvailableActionsQuery>,
  mapVariables: (property_batch_id: number) => () => ({ property_batch_id }),
  mapResponse: (response) =>
    response._sys_workflow_get_available_actions_for_batch?.actions,
});
