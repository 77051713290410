/**
 * @generated SignedSource<<a6b43d0bd6ec207a994dbda278c77ec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getSubmissionsFundsFilterSubscription$variables = {};
export type getSubmissionsFundsFilterSubscription$data = {
  readonly sys_properties: ReadonlyArray<{
    readonly fund: string | null;
  }>;
};
export type getSubmissionsFundsFilterSubscription = {
  response: getSubmissionsFundsFilterSubscription$data;
  variables: getSubmissionsFundsFilterSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "fund"
      },
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "fund": "asc"
        }
      },
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "fund": {
            "_is_null": false
          },
          "property_batches": {}
        }
      }
    ],
    "concreteType": "sys_properties",
    "kind": "LinkedField",
    "name": "sys_properties",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fund",
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties(distinct_on:\"fund\",order_by:{\"fund\":\"asc\"},where:{\"fund\":{\"_is_null\":false},\"property_batches\":{}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSubmissionsFundsFilterSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSubmissionsFundsFilterSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1970e5ee661d9359f3c36161f68cc296",
    "id": null,
    "metadata": {},
    "name": "getSubmissionsFundsFilterSubscription",
    "operationKind": "subscription",
    "text": "subscription getSubmissionsFundsFilterSubscription {\n  sys_properties(distinct_on: fund, order_by: {fund: asc}, where: {property_batches: {}, fund: {_is_null: false}}) {\n    fund\n  }\n}\n"
  }
};
})();

(node as any).hash = "75d13bcd624ca7eddec2a71bd11f61d5";

export default node;
