/**
 * @generated SignedSource<<caa474ceef1e99c1d1193f205166acf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _sys_property_assign_preparers_input = {
  property_ids: ReadonlyArray<number>;
  provider_id: number;
  user_ids: ReadonlyArray<number>;
};
export type AssignPreparersDialogMutation$variables = {
  input: _sys_property_assign_preparers_input;
};
export type AssignPreparersDialogMutation$data = {
  readonly _sys_property_assign_preparers: {
    readonly affected_rows: number | null;
  } | null;
};
export type AssignPreparersDialogMutation = {
  response: AssignPreparersDialogMutation$data;
  variables: AssignPreparersDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "_sys_property_assign_preparers_output",
    "kind": "LinkedField",
    "name": "_sys_property_assign_preparers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignPreparersDialogMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignPreparersDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "68b6aee750422f87a102bb4533d22f32",
    "id": null,
    "metadata": {},
    "name": "AssignPreparersDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AssignPreparersDialogMutation(\n  $input: _sys_property_assign_preparers_input!\n) {\n  _sys_property_assign_preparers(input: $input) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5e7898cd5905c5c0fb08c3db4561b8e";

export default node;
