import React from 'react';
import { graphql } from 'react-relay';
import { Button } from '@cherre-frontend/ui';
import {
  useCherreValue,
  useMutation,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { DialogActions } from '../../DialogActions';
import { $canReassignUser, $reassignUserDetails } from '../recoil';
import { ActionsReassignUserMutation } from './__generated__/ActionsReassignUserMutation.graphql';

export type ActionsProps = {
  userId: number;
  onClose: () => void;
  selectedProviderId: number | null;
};

const Actions: React.FC<ActionsProps> = ({
  userId,
  onClose,
  selectedProviderId,
}) => {
  const userDetails = useCherreValue($reassignUserDetails(userId));
  const currentProviderId = userDetails?.sys_user?.provider?.provider_id;
  const openBatches = useCherreValue($canReassignUser(userId));
  const hasOpenBatches = Boolean(openBatches?.length);
  const invalidProvider =
    selectedProviderId !== null &&
    [-1, currentProviderId].includes(selectedProviderId);
  const disabled = hasOpenBatches || invalidProvider;

  const reassign = useMutation(
    graphql`
      mutation ActionsReassignUserMutation(
        $userId: Int!
        $organizationId: Int!
        $providerId: Int
      ) {
        _sys_reassign_user(
          params: {
            user_id: $userId
            organization_id: $organizationId
            provider_id: $providerId
          }
        ) {
          ok
        }
      }
    ` as GraphQLReturn<ActionsReassignUserMutation>,
    {
      mapVariables: () => async () => ({
        userId,
        organizationId: userDetails!.organization.organization_id,
        providerId: selectedProviderId,
      }),
      onCompleted: ({ _sys_reassign_user: { ok } }, ctx) => {
        if (ok) {
          ctx.showSnackbar({
            type: 'success',
            message: 'User reassigned successfully!',
          });
          onClose();
        } else {
          ctx.showSnackbar({
            type: 'error',
            message: 'Failed to reassign user',
          });
        }
      },
      onError: (_, ctx) => {
        ctx.showSnackbar({
          type: 'error',
          message: 'Failed to reassign user',
        });
      },
    }
  );

  return (
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        variant='contained'
        color='primary'
        disabled={disabled}
        onClick={reassign}
      >
        Save
      </Button>
    </DialogActions>
  );
};

export default Actions;
