/**
 * @generated SignedSource<<f3ab45def6250e530dbefb220d6fb948>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilSubmissionSubscription$variables = {
  submission_id?: number | null;
};
export type recoilSubmissionSubscription$data = {
  readonly sys_submissions: ReadonlyArray<{
    readonly due_date: string;
    readonly property_batches: ReadonlyArray<{
      readonly property: {
        readonly entity_id: string;
        readonly properties_datasets: ReadonlyArray<{
          readonly dataset: {
            readonly dataset_id: number;
            readonly dataset_label: string;
          };
        }>;
        readonly property_id: number;
        readonly reminders_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null;
        };
      };
      readonly property_batch_datasets: ReadonlyArray<{
        readonly dataset_id: number | null;
        readonly is_valid: boolean | null;
      }>;
      readonly property_batch_id: number;
    }>;
    readonly property_batches_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
    readonly reporting_period_end_date: string;
    readonly submission_id: number;
    readonly submission_name: string;
    readonly submission_type: {
      readonly datasets: ReadonlyArray<{
        readonly dataset_id: number;
        readonly dataset_label: string;
      }>;
      readonly submission_type_id: number;
      readonly submission_type_label: string;
    };
  }>;
};
export type recoilSubmissionSubscription = {
  response: recoilSubmissionSubscription$data;
  variables: recoilSubmissionSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submission_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataset_id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataset_label",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "submission_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "submission_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_submissions",
    "kind": "LinkedField",
    "name": "sys_submissions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reporting_period_end_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "due_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submission_types",
        "kind": "LinkedField",
        "name": "submission_type",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_type_label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_datasets",
            "kind": "LinkedField",
            "name": "datasets",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batches",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_batch_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batch_datasets",
            "kind": "LinkedField",
            "name": "property_batch_datasets",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_valid",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_properties_datasets",
                "kind": "LinkedField",
                "name": "properties_datasets",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sys_datasets",
                    "kind": "LinkedField",
                    "name": "dataset",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "is_resolved": {
                        "_eq": false
                      }
                    }
                  }
                ],
                "concreteType": "sys_reminders_aggregate",
                "kind": "LinkedField",
                "name": "reminders_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sys_reminders_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "reminders_aggregate(where:{\"is_resolved\":{\"_eq\":false}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "property": {
                "property_type": {
                  "property_type_slug": {
                    "_neq": "investment"
                  }
                }
              }
            }
          }
        ],
        "concreteType": "sys_property_batches_aggregate",
        "kind": "LinkedField",
        "name": "property_batches_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batches_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": "property_batches_aggregate(where:{\"property\":{\"property_type\":{\"property_type_slug\":{\"_neq\":\"investment\"}}}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilSubmissionSubscription",
    "selections": (v4/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilSubmissionSubscription",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b4b7cada8870914626da8ed2cf03f821",
    "id": null,
    "metadata": {},
    "name": "recoilSubmissionSubscription",
    "operationKind": "subscription",
    "text": "subscription recoilSubmissionSubscription(\n  $submission_id: Int\n) {\n  sys_submissions(where: {submission_id: {_eq: $submission_id}}) {\n    submission_id\n    submission_name\n    reporting_period_end_date\n    due_date\n    submission_type {\n      submission_type_id\n      submission_type_label\n      datasets {\n        dataset_id\n        dataset_label\n      }\n    }\n    property_batches {\n      property_batch_id\n      property_batch_datasets {\n        dataset_id\n        is_valid\n      }\n      property {\n        property_id\n        entity_id\n        properties_datasets {\n          dataset {\n            dataset_id\n            dataset_label\n          }\n        }\n        reminders_aggregate(where: {is_resolved: {_eq: false}}) {\n          aggregate {\n            count\n          }\n        }\n      }\n    }\n    property_batches_aggregate(where: {property: {property_type: {property_type_slug: {_neq: \"investment\"}}}}) {\n      aggregate {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "045c7252968874ebde0c12f259c08bd9";

export default node;
