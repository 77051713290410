import { Box, Chip, Typography, styled } from '@cherre-frontend/ui';
import moment from 'moment';
import React from 'react';
import { recoilGetPackageReportCommentsQuery$data } from './__generated__/recoilGetPackageReportCommentsQuery.graphql';

const Name = styled(Typography)`
  font-weight: bold;
`;

const CommentBody = styled(Typography)`
  white-space: pre-line;
  word-break: break-word;
`;

const Date = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const ChipStyled = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-size: 10px;
  height: 18px;
`;

interface CommentProps {
  comment?: recoilGetPackageReportCommentsQuery$data['sys_comments'][0];
}

export const Comment: React.FC<CommentProps> = ({ comment }) => {
  return (
    <Box>
      <Name variant='body1'>
        {comment?.user.sakura_user?.first_name}{' '}
        {comment?.user.sakura_user?.last_name}
        {comment?.comment_type_id === 2 && (
          <ChipStyled
            label='REJECTED'
            variant='outlined'
            color='primary'
            size='small'
          />
        )}
      </Name>
      <CommentBody variant='body1'>{comment?.text}</CommentBody>
      <Date variant='body2'>
        {moment(comment?.created_at_datetime).format('MMM DD, YYYY')} at{' '}
        {moment(comment?.created_at_datetime).format('h:mm A')}
      </Date>
    </Box>
  );
};
