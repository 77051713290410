import {
  CloseIcon,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Panel,
  Paper,
  Tab,
  MuiTabs as Tabs,
  styled,
} from '@cherre-frontend/ui';

import {
  CherreState,
  useCherreSetState,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import React from 'react';
import { Report } from './Report';
import { ReportContextType, reportContext } from './ReportContext';
import * as T from './types';

const TabsStyled = styled(Tabs)`
  .MuiTabs-indicator {
    top: 0;
    border-radius: 0px;
    height: 3px;
  }
  .MuiButtonBase-root {
    text-transform: none;
  }
`;

const PaperStyled = styled(Paper)`
  width: 100vw;
  // max-width: 100vw;
  height: calc(100vh - 120px);
  // max-height: calc(100vh - 120px);
  grid-template-rows: auto 1fr;
  border-radius: 10px;
  margin-top: 100px;
`;

const DialogContentStyled = styled(DialogContent, {
  shouldForwardProp: (prop) => !['sidebarOpen'].includes(prop.toString()),
})<{
  sidebarOpen?: boolean | undefined;
}>`
  padding: 20px 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  & > .report-container {
    height: 100%;
    display: grid;
    overflow: hidden;
    & > div {
      height: 100%;
      display: grid;
      overflow: hidden;
      grid-template-columns: ${(props) =>
        props.sidebarOpen ? '6fr 2fr' : '1fr'};
    }
  }
`;

export type ReportModalProps = ReportContextType & {
  children: React.ReactNode;
  reportSlugState: CherreState<string>;
  reports: T.Report[];
  sidebarComponent?: React.ReactNode;
  sidebarOpen?: boolean;
};

export const ReportsModal: React.FC<ReportModalProps> = ({
  reports,
  reportSlugState,
  children,
  sidebarComponent,
  sidebarOpen,
  ...contextValue
}) => {
  const [selectedSlug, setSelectedSlug] = useCherreState(reportSlugState);
  const selectedReport = reports.find((r) => r.slug === selectedSlug);
  const setSearchState = useCherreSetState(contextValue.selectors.searchState);

  return (
    <Dialog
      open={selectedReport ? true : false}
      PaperComponent={PaperStyled}
      maxWidth={'xl'}
    >
      <Grid
        container
        display={'grid'}
        gridTemplateColumns={'1fr auto'}
        alignItems={'center'}
      >
        <TabsStyled
          value={selectedReport?.slug ?? false}
          onChange={(_, newValue) => {
            setSelectedSlug(newValue);
            setSearchState('');
          }}
          variant='scrollable'
          scrollButtons='auto'
          TabIndicatorProps={{
            color: 'primary',
          }}
        >
          {reports.map((r) => (
            <Tab
              key={r.slug}
              value={r.slug}
              label={r.label}
              disabled={r.disabled}
              role='tab'
            />
          ))}
        </TabsStyled>
        <Grid item>
          <IconButton onClick={() => setSelectedSlug('')}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContentStyled sidebarOpen={sidebarOpen}>
        <reportContext.Provider value={contextValue}>
          {selectedReport && (
            <>
              <Panel id={`report-header-${selectedReport.slug}`}>
                {children}
              </Panel>
              <Panel
                id={`report-tables-${selectedReport.slug}`}
                className='report-container'
              >
                <Report key={selectedReport.slug} slug={selectedReport.slug} />
                <React.Fragment key={`${selectedReport.slug}-sidebar`}>
                  {sidebarOpen && sidebarComponent ? sidebarComponent : null}
                </React.Fragment>
              </Panel>
            </>
          )}
        </reportContext.Provider>
      </DialogContentStyled>
    </Dialog>
  );
};
