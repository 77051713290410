import React from 'react';
import FlatfileSystemUpload from '../../components/FlatFileSystemUpload';
import { getMappingField } from './queries/getMappingField';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useHistory } from 'react-router';
import { useUploadMapping } from './hooks/useUploadMapping';
import { addEditMappingSetFormState } from '../provider-detail/Panels/MappingsTab/recoil';
import { getMappingFieldQuery } from './queries/__generated__/getMappingFieldQuery.graphql';

const getSchemaSlug = (
  mappingField?: getMappingFieldQuery['response']['sys_mapping_fields'][0]
) => {
  if (mappingField?.mapping_field_name === 'excluded_property') {
    return 'excludedProperty';
  }

  const hasCustomAttributes = mappingField?.custom_attributes;
  if (hasCustomAttributes) {
    return `${mappingField?.organization.organization_slug}_${mappingField?.mapping_field_name}`;
  }

  return 'mapping';
};

const UploadMappingSetPage: React.FC = () => {
  const { goBack } = useHistory();
  const mappingField = useCherreValue(getMappingField());
  const mappingForm = useCherreValue(addEditMappingSetFormState);

  if (!mappingForm) {
    throw new Error('Mapping form is not set');
  }
  const onComplete = useUploadMapping(
    { ...mappingForm, file_source: 'flatfile' },
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Mapping set uploaded successfully',
      });
      goBack();
    }
  );

  return (
    <FlatfileSystemUpload
      schemaSlug={getSchemaSlug(mappingField)}
      title={`File Uploader: ${mappingField?.mapping_field_description}`}
      onComplete={onComplete}
      onCancel={() => {
        goBack();
      }}
    />
  );
};

export default UploadMappingSetPage;
