/**
 * @generated SignedSource<<5487e336bcbec626070f5845dfc362a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getProviderQuery$variables = {
  id?: number | null;
};
export type getProviderQuery$data = {
  readonly sys_providers: ReadonlyArray<{
    readonly provider_id: number;
    readonly provider_name: string;
    readonly provider_type: {
      readonly name: string;
    } | null;
  }>;
};
export type getProviderQuery = {
  response: getProviderQuery$data;
  variables: getProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers",
    "kind": "LinkedField",
    "name": "sys_providers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_provider_types",
        "kind": "LinkedField",
        "name": "provider_type",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getProviderQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7cc107288cb88ed4f170d6db0e8c0b19",
    "id": null,
    "metadata": {},
    "name": "getProviderQuery",
    "operationKind": "query",
    "text": "query getProviderQuery(\n  $id: Int\n) {\n  sys_providers(limit: 1, where: {provider_id: {_eq: $id}}) {\n    provider_id\n    provider_name\n    provider_type {\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8910d9866d1bd8f3ee3acf48fa1d4fc";

export default node;
