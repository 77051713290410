/**
 * @generated SignedSource<<3cef39966e44b9c3ba78ea86df15e06b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getOrganizationPropertyModelQuery$variables = {};
export type getOrganizationPropertyModelQuery$data = {
  readonly sys_organizations: ReadonlyArray<{
    readonly property_model: {
      readonly property_model_id: number;
      readonly property_model_slug: string;
    };
  }>;
};
export type getOrganizationPropertyModelQuery = {
  response: getOrganizationPropertyModelQuery$data;
  variables: getOrganizationPropertyModelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_organizations",
    "kind": "LinkedField",
    "name": "sys_organizations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_models",
        "kind": "LinkedField",
        "name": "property_model",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_model_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_model_slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getOrganizationPropertyModelQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getOrganizationPropertyModelQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "08625083aa3cd6efb5f40c4ddd505373",
    "id": null,
    "metadata": {},
    "name": "getOrganizationPropertyModelQuery",
    "operationKind": "query",
    "text": "query getOrganizationPropertyModelQuery {\n  sys_organizations {\n    property_model {\n      property_model_id\n      property_model_slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e7d357e2ae6235c01584f10db6b30a2";

export default node;
