import React from 'react';
import {
  Grid,
  PropertiesIcon,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';

const NoAvailableProperties: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <PropertiesIcon
        style={{
          width: '36px',
          height: 'auto',
          color: theme.palette.grey[400],
        }}
      />
      <Typography variant='h5' style={{ fontWeight: 600 }}>
        No Properties
      </Typography>
      <Typography variant='body1'>
        There are no properties available to be assigned.
      </Typography>
    </Grid>
  );
};

export default NoAvailableProperties;
