import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValueLoadable,
  useMutationAsync,
  useRecoilScope,
} from '@cherre-frontend/data-fetching';
import { useCallback } from 'react';
import { graphql } from 'react-relay';
import { useCreateIngestFileAsyncMutation } from './__generated__/useCreateIngestFileAsyncMutation.graphql';
import { useRecoilCallback } from 'recoil';
import { useCreateIngestFileProgressSubscription } from './__generated__/useCreateIngestFileProgressSubscription.graphql';
import {
  useCreateIngestFileQuery,
  useCreateIngestFileQuery$data,
} from './__generated__/useCreateIngestFileQuery.graphql';
import { useIsMounted } from '@cherre-frontend/core';

const queryUseCreateIngestFile = graphQLSelector({
  query: graphql`
    query useCreateIngestFileQuery($id: uuid!) {
      _sys_ingestion_ingest_async(id: $id) {
        output {
          data {
            property_batches {
              found
              not_found
            }
          }
        }
        errors
      }
    }
  ` as GraphQLReturn<useCreateIngestFileQuery>,
});

export const useAsyncCreateIngestFile = () => {
  const isMounted = useIsMounted();

  const mutation = useMutationAsync(
    graphql`
      mutation useCreateIngestFileAsyncMutation($params: ingestion_params!) {
        _sys_ingestion_ingest_async(params: $params)
      }
    ` as GraphQLReturn<useCreateIngestFileAsyncMutation>
  );

  const scope = useRecoilScope();

  const getDataFromId = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const release = snapshot.retain();
        try {
          return snapshot.getPromise(queryUseCreateIngestFile({ id })(scope));
        } finally {
          release();
        }
      }
  );

  const wrapper = useCallback(
    async (param: useCreateIngestFileAsyncMutation['variables']) => {
      const { _sys_ingestion_ingest_async: id } = await mutation({ ...param });

      return new Promise<
        useCreateIngestFileQuery$data['_sys_ingestion_ingest_async']
      >((resolve, _reject) => {
        const refresh = async () => {
          const data = await getDataFromId(id);
          if (
            data._sys_ingestion_ingest_async?.output?.data ||
            data._sys_ingestion_ingest_async?.errors
          ) {
            resolve(data._sys_ingestion_ingest_async);
          } else {
            isMounted() && setTimeout(refresh, 1000);
          }
        };
        refresh();
      });
    },
    [mutation, isMounted, getDataFromId]
  );

  return wrapper;
};

export const subscriptionUseCreateIngestFileProgress = graphQLSelector({
  mapResponse: (response) => response.sys_batch_files,
  query: graphql`
    subscription useCreateIngestFileProgressSubscription(
      $file_reference_souce: String!
    ) {
      sys_batch_files(
        where: { file_reference_souce: { _eq: $file_reference_souce } }
      ) {
        processed_row_count
        row_count
      }
    }
  ` as GraphQLReturn<useCreateIngestFileProgressSubscription>,
});

export const useCreateIngestFileProgress = (
  param: useCreateIngestFileProgressSubscription['variables']
) => {
  const value = useCherreValueLoadable(
    subscriptionUseCreateIngestFileProgress(param)
  );

  const progress = value.valueMaybe();

  return progress?.[0];
};
