import {
  constSelector,
  GraphQLReturn,
  useCherreEventWithRecoil,
  useCherreSetState,
  useCherreStateDebounced,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  CloseIcon,
  AlertTitle,
  Alert,
  Dialog,
  DialogProps,
  WarningIcon,
  Grid,
  IconButton,
  MRT_RowSelectionState,
  PageHeader,
  Panel,
  PanelProps,
  SearchInput,
  styled,
  useTheme,
} from '@cherre-frontend/ui';
import React, { useMemo, useState } from 'react';
import { HeaderGrid } from '../../../styles';
import {
  assignPropertiesConfirmationDialogOpenState,
  assignPropertiesDialogOpenState,
  assignPropertiesTableSearch,
} from '../recoil';
import { selectedProviderToBeAssigned } from 'src/products/data-submission-portal/recoil/dialog';
import AssignPropertiesTable from './AssignPropertiesTable';
import { getProvider } from '../../../queries/getProvider';
import { graphql } from 'react-relay';
import { AssignPropertiesDialogMutation } from './__generated__/AssignPropertiesDialogMutation.graphql';
import AssignConfirmationDialogModal from './AssignConfirmationDialog';
import {
  getAllProperties,
  getAllPropertiesTotal,
} from '../queries/getAllProperties';
import { canAssignToProviderQuery } from 'src/products/data-submission-portal/pages/properties/Panels/PropertiesPanel/components/AssignProviderDialog/recoil';

const Container = styled<React.FC<DialogProps & { width: string }>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 0 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
  }
`;

const StyledPanel = styled<React.FC<PanelProps>>(Panel)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const WarningBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.brown.light};
  color: ${({ theme }) => theme.accents.brown.main};
  margin-bottom: 20px;
  .MuiAlert-message {
    padding: 0px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
`;

export const WarningBannerIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.accents.brown.main};
`;

const AssignPropertiesDialogModal: React.FC = () => {
  const theme = useTheme();

  const isModalOpen = useCherreValue(assignPropertiesDialogOpenState);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const provider = useCherreValue(getProvider());
  const total = useCherreValue(getAllPropertiesTotal());

  const setAssignConfirmationDialogOpenState = useCherreSetState(
    assignPropertiesConfirmationDialogOpenState
  );

  const [search, , setSearch] = useCherreStateDebounced(
    assignPropertiesTableSearch
  );

  const selectedPropertyIds = useMemo(
    () => Object.keys(rowSelection).map((id) => parseInt(id)),
    [rowSelection]
  );

  const onCloseConfirmation = useCherreEventWithRecoil(
    'user closed assign properties confirmation dialog',
    (ctx) => () => {
      ctx.recoil.set(assignPropertiesConfirmationDialogOpenState, false);
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed assign properties dialog',
    (ctx) => () => {
      setSearch('');
      setRowSelection({});
      onCloseConfirmation();
      ctx.recoil.set(assignPropertiesDialogOpenState, false);
    }
  );

  const properties = useCherreValue(getAllProperties());
  const selectedProperties =
    properties?.filter((p) => selectedPropertyIds.includes(p.property_id)) ??
    [];

  const canAssignToProvider = useCherreValue(
    !isModalOpen || !selectedProperties || !provider?.provider_id
      ? constSelector(false)
      : canAssignToProviderQuery({
          properties: selectedProperties.map((p) => ({
            property_code: p.property_code ?? '',
            entity_id: p.entity_id ?? '',
          })),
          provider_id: provider.provider_id,
        })
  );

  const assignProperties = useMutation(
    graphql`
      mutation AssignPropertiesDialogMutation(
        $property_ids: [Int!]!
        $provider_id: Int!
      ) {
        _sys_property_assign_provider(
          arg1: { property_ids: $property_ids, provider_id: $provider_id }
        ) {
          result
        }
      }
    ` as GraphQLReturn<AssignPropertiesDialogMutation>,
    {
      trackEvent: false,
      onCompleted: (result, ctx) => {
        setRowSelection({});
        ctx.recoil.set(assignPropertiesDialogOpenState, false);
        ctx.recoil.set(assignPropertiesConfirmationDialogOpenState, false);
        ctx.recoil.set(selectedProviderToBeAssigned, 0);
        ctx.showSnackbar({
          type: 'success',
          message: 'Properties successfully assigned!',
        });
      },
      onError: (result, ctx) => {
        ctx.logger.error('AssignPropertiesDialogMutation failed', result);
        ctx.showSnackbar({
          type: 'error',
          message: `Property assignment failed.`,
        });
      },
    }
  );

  const onAssignPropertiesClicked = () => {
    setAssignConfirmationDialogOpenState(true);
  };

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose} width='100%'>
      <Panel id='AssignPropertiesConfirmationDialog'>
        <AssignConfirmationDialogModal
          onSubmit={assignProperties}
          onClose={onCloseConfirmation}
          selectedPropertyIds={selectedPropertyIds}
        />
      </Panel>

      <StyledPanel id='AssignPropertiesDialog' config={{ logLevel: false }}>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <PageHeader
            title='Assign Properties'
            subtitle={`Assign Properties to ${
              provider?.provider_name as string
            }`}
          >
            <Grid container direction='row' gap={1}>
              <IconButton
                style={{ height: '48px', alignSelf: 'center' }}
                onClick={onClose}
              >
                <CloseIcon style={{ fill: theme.palette.grey[700] }} />
              </IconButton>
            </Grid>
          </PageHeader>
        </HeaderGrid>

        <WarningBanner
          icon={<WarningBannerIcon />}
          title='Properties Excluded from Assignment'
          severity='warning'
        >
          <AlertTitle>Properties Excluded from Assignment</AlertTitle>
          Properties that are part of open submission batches are unavailable
          for assignment. Please close those batches to assign these properties.
        </WarningBanner>

        {!canAssignToProvider && (
          <Alert
            title='Properties Excluded from Assignment'
            severity='error'
            sx={{
              backgroundColor: (theme) => theme.accents.error.light,
              color: (theme) => theme.accents.error.main,
              marginBottom: '20px',
            }}
          >
            This service provider has an existing source ID corresponding to the
            selected target ID. Update the Source ID before assigning this
            record to this Provider
          </Alert>
        )}

        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <SearchInput
            value={search ?? ''}
            onChange={(e) => setSearch(e.target.value)}
          />
          {total?.aggregate?.count && total?.aggregate?.count > 0 ? (
            <Button
              size='medium'
              variant='contained'
              color='primary'
              disabled={
                selectedPropertyIds.length === 0 || !canAssignToProvider
              }
              onClick={onAssignPropertiesClicked}
            >
              Assign
            </Button>
          ) : null}
        </Grid>

        <Grid
          overflow='auto'
          flexDirection='column'
          container
          item
          flexGrow={1}
        >
          <AssignPropertiesTable
            search={search}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </Grid>
      </StyledPanel>
    </Container>
  );
};

const PropertiesDialogWrapper: React.FC = () => {
  if (!useCherreValue(assignPropertiesDialogOpenState)) {
    return null;
  }
  return <AssignPropertiesDialogModal />;
};

export default PropertiesDialogWrapper;
