import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReportTable } from './ReportTable';
import { getReportMetadata, Column, TableConfig } from './recoil';
import {
  GraphQLReturn,
  graphQLSelector,
  selectorFamily,
  useCherreValue,
  CherreSwrController,
  waitForAll,
} from '@cherre-frontend/data-fetching';
import {
  useOnReportOpen,
  usePagination,
  useReportContext,
  useReportParams,
} from './ReportContext';
import {
  TablePagination,
  useTheme,
  styled,
  Typography,
  Panel,
} from '@cherre-frontend/ui';
import { ReportTableAggQuery } from './__generated__/ReportTableAggQuery.graphql';
import { SerializableParam } from 'recoil';
import { graphql } from 'react-relay';
import { sizeCalculator } from './utils';

export type ReportProps = {
  slug: string;
};

const reportTableAggSelector = graphQLSelector({
  query: graphql`
    query ReportTableAggQuery($params: report_table_data_aggregate_input!) {
      _sys_get_report_table_data_aggregate(params: $params) {
        count
      }
    }
  ` as GraphQLReturn<ReportTableAggQuery>,
  mapResponse: (resp) => resp._sys_get_report_table_data_aggregate.count,
});

type ReportAggSelectorParams = {
  readonly pagination: boolean;
  readonly slug: string;
  readonly search: string;
  readonly report_params?: Record<string, SerializableParam>;
  readonly tables: ReadonlyArray<{
    readonly slug: string;
    readonly query_arguments?: Record<string, SerializableParam>;
    readonly where?: Record<string, SerializableParam>;
  }>;
};

type ReportAggSelectorReturn = {
  total: number;
  tables: Record<string, number>;
};

const reportAggSelectorSWRController = new CherreSwrController<
  ReportAggSelectorReturn,
  ReportAggSelectorParams
>();

const reportAggSelector = selectorFamily<
  ReportAggSelectorReturn,
  ReportAggSelectorParams
>({
  key: 'DSP/Reports/reportAggSelector',
  scoped: true,
  get:
    (params, scope) =>
    ({ get }) => {
      const allCounts = get(
        waitForAll(
          params.tables.map((table) =>
            reportTableAggSelector({
              params: {
                report_slug: params.slug,
                table_slug: table.slug,
                query_arguments: table.query_arguments,
                where: table.where,
                search: params.search,
                report_params: params.report_params,
              },
            })
          )
        )
      );
      const total = allCounts.reduce((acc, cur) => acc + cur, 0);
      const tables = Object.fromEntries(
        params.tables.map((t, i) => [t.slug, allCounts[i]])
      );
      const result = { total, tables };
      reportAggSelectorSWRController.setNodeSet(
        reportAggSelector(params)(scope),
        result
      );
      return result;
    },
});

reportAggSelectorSWRController.setScopedNodeFamily(reportAggSelector);
reportAggSelectorSWRController.setSwrPredicate(
  (oldV, newV) => oldV.slug !== newV.slug
);

const reportAggSelectorSWR = reportAggSelectorSWRController.scopedFamilyStub();

const EmptyRowsFallback = styled(Typography)`
  display: grid;
  place-items: center;
  padding: 50px;
`;

type ColumnSizingState = Record<string, number>;

type ReportSizingState = Record<string, ColumnSizingState>;

const getTotalSize = (columnSizing: ColumnSizingState): number => {
  return Object.values(columnSizing).reduce((acc, curr) => acc + curr, 0);
};

const getInitialColumnSizing = (
  columns: Column[],
  width: number
): ColumnSizingState => {
  const calculateSize = sizeCalculator(columns, width);
  return columns.reduce((acc, curr) => {
    if (curr.columns) {
      return {
        ...acc,
        ...getInitialColumnSizing(curr.columns, calculateSize(curr)),
      };
    }
    if (!curr.accessorKey) {
      return acc;
    }
    return {
      ...acc,
      [curr.accessorKey]: calculateSize(curr),
    };
  }, {});
};

const getInitialReportColumnSizing = (
  config: [slug: string, columns: Column[]][],
  width: number
): ReportSizingState => {
  const maxWidth = Math.max(
    ...config.map((c) => getInitialColumnSizing(c[1], width)).map(getTotalSize)
  );
  return Object.fromEntries(
    config.map(([slug, columns]) => [
      slug,
      getInitialColumnSizing(columns, maxWidth),
    ])
  );
};

const getMinimalReportColumnSizing = (config: TableConfig[]) =>
  getInitialReportColumnSizing(
    config.map((c) => [c.table_slug, c.columns]),
    0
  );

const updateColumnSizing =
  (newWidth: number, minimalColumnSizing: ColumnSizingState) =>
  (columnSizing: ColumnSizingState): ColumnSizingState => {
    const oldWidth = Object.values(columnSizing).reduce(
      (acc, curr) => acc + curr,
      0
    );
    return Object.fromEntries(
      Object.entries(columnSizing).map(([key, value]) => {
        return [
          key,
          Math.max(
            value * (newWidth / oldWidth),
            minimalColumnSizing[key] ?? 0
          ),
        ];
      })
    );
  };

const updateTableColumnSizing =
  (
    table_slug: string,
    newColumnSizing: ColumnSizingState,
    minimalColumnSizing: ReportSizingState,
    minimalWidth: number
  ) =>
  (reportColumnSizing: ReportSizingState): ReportSizingState => {
    const newWidth = getTotalSize(newColumnSizing);
    const maxWidth = Math.max(
      ...Object.entries(reportColumnSizing)
        .map(([k, v]) =>
          k === table_slug
            ? newColumnSizing
            : updateColumnSizing(newWidth, minimalColumnSizing[k])(v)
        )
        .map(getTotalSize)
    );
    if (maxWidth < minimalWidth) {
      return reportColumnSizing;
    }
    return Object.fromEntries(
      Object.entries(reportColumnSizing).map(([key, value]) => {
        return [
          key,
          updateColumnSizing(
            maxWidth,
            minimalColumnSizing[key]
          )(key === table_slug ? newColumnSizing : value),
        ];
      })
    );
  };

const getContainerWidth = (containerRef: React.RefObject<HTMLDivElement>) => {
  const rect = containerRef.current?.getBoundingClientRect();
  const containerHeight = rect?.height || 0;
  const scrollHeight = containerRef.current?.scrollHeight || 0;

  const hasScroll = scrollHeight > containerHeight;
  return rect?.width ? (hasScroll ? rect.width - 10 : rect.width) : undefined;
};

export const Report: React.FC<ReportProps> = ({ slug }) => {
  const ctx = useReportContext();
  const report_params = useReportParams(slug);
  const metadata = useCherreValue(
    getReportMetadata({
      params: {
        report_slug: slug,
        report_params,
        query_arguments: ctx.getArgs(slug, {
          data_source_object_type: 'query',
        }),
      },
    })
  );
  useOnReportOpen(slug);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [pagination, setPagination] = usePagination();
  const search = useCherreValue(ctx.selectors.searchState);
  const totalCount = useCherreValue(
    reportAggSelectorSWR({
      pagination: Boolean(metadata?.pagination),
      slug,
      search: search || '',
      report_params,
      tables: (metadata?.tables || []).map((t) => ({
        slug: t.table_slug,
        query_arguments: ctx.getArgs(slug, t),
        where: ctx.getWhere(slug, t),
      })),
    })
  );

  const [tableDynamicColumns, setTableDynamicColumns] = useState<
    Record<string, Column[] | null>
  >({});

  const [columnSizing, setColumnSizing] = useState<Record<
    string,
    Record<string, number>
  > | null>(null);

  useEffect(() => {
    if (!metadata) {
      return;
    }
    setTableDynamicColumns(
      Object.fromEntries(metadata.tables.map((t) => [t.table_slug, null]))
    );
  }, [metadata]);

  // initiates column sizing after dynamic columns are set
  useEffect(() => {
    if (!metadata) {
      return;
    }
    const values = Object.values(tableDynamicColumns);
    if (!!values.length && values.every((v) => v !== null)) {
      let lastContainerWidth = 0;
      const updateSize = () => {
        const containerWidth = getContainerWidth(containerRef);
        if (containerWidth && containerWidth !== lastContainerWidth) {
          lastContainerWidth = containerWidth;
          setColumnSizing(
            getInitialReportColumnSizing(
              metadata.tables.map((t) => [
                t.table_slug,
                [
                  ...t.columns.filter((c) => !c.dynamicColumn),
                  ...(tableDynamicColumns[t.table_slug] ?? []),
                ],
              ]),
              containerWidth - 2
            )
          );
        }
      };
      updateSize();
      window.addEventListener('resize', updateSize);
      const intervalHandle = setInterval(updateSize, 100);
      return () => {
        window.removeEventListener('resize', updateSize);
        clearInterval(intervalHandle);
      };
    }
  }, [tableDynamicColumns, metadata]);

  const minimumSizing = useMemo(
    () => (metadata ? getMinimalReportColumnSizing(metadata.tables) : null),
    [metadata?.tables]
  );
  const onSizingChange =
    (table_slug: string) =>
    (updater: (old: ColumnSizingState) => ColumnSizingState) => {
      if (!minimumSizing) {
        return;
      }
      setColumnSizing((old) => {
        if (!old) {
          return old;
        }
        const containerWidth = getContainerWidth(containerRef);
        if (!containerWidth) {
          return old;
        }
        return updateTableColumnSizing(
          table_slug,
          updater(old[table_slug]),
          minimumSizing,
          containerWidth - 2
        )(old);
      });
    };
  if (!metadata) {
    return null;
  }
  if (totalCount?.total === 0) {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr auto',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <EmptyRowsFallback>No errors found</EmptyRowsFallback>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          border: `1px solid ${theme.palette.grey[400]}`,
          display: 'grid',
          alignContent: 'flex-start',
        }}
        ref={containerRef}
      >
        {metadata.tables.map((t) => {
          const id = `report-table-${t.table_slug}-${btoa(
            JSON.stringify(t.group_values)
          )}`;

          return (
            <Panel key={id} id={id}>
              <ReportTable
                key={t.table_slug}
                report_slug={slug}
                pagination={
                  metadata.pagination
                    ? {
                        pageIndex: totalCount
                          ? Math.min(
                              pagination.pageIndex,
                              Math.floor(
                                totalCount.tables[t.table_slug] /
                                  pagination.pageSize
                              )
                            )
                          : pagination.pageIndex,
                        pageSize: totalCount
                          ? Math.round(
                              (pagination.pageSize *
                                totalCount.tables[t.table_slug]) /
                                totalCount.total
                            )
                          : 0,
                      }
                    : undefined
                }
                table_config={t}
                columnSizing={columnSizing?.[t.table_slug]}
                onAddDynamicColumns={(columns) => {
                  if (
                    tableDynamicColumns[t.table_slug]?.length ===
                      columns.length &&
                    tableDynamicColumns[t.table_slug]?.every(
                      (v, i) => v.accessorKey === columns[i].accessorKey
                    )
                  ) {
                    return;
                  }

                  setTableDynamicColumns((old) => ({
                    ...old,
                    [t.table_slug]: columns,
                  }));
                }}
                onSizingChange={onSizingChange(t.table_slug)}
              />
            </Panel>
          );
        })}
      </div>
      {metadata.pagination ? (
        <TablePagination
          component={'div'}
          count={totalCount?.total || 0}
          page={pagination.pageIndex}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onRowsPerPageChange={(e) =>
            setPagination((o) => ({
              ...o,
              pageSize: Number.parseInt(e.target.value),
            }))
          }
          sx={{
            borderBottom: 'none',
          }}
          onPageChange={(_, pageIndex) =>
            setPagination((o) => ({ ...o, pageIndex }))
          }
        />
      ) : null}
    </div>
  );
};
