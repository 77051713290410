import { Chip, styled } from '@cherre-frontend/ui';

export const ProviderChip = styled(Chip)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  background-color: ${({ theme }) => theme.accents.info.light};
`;
