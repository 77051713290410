/**
 * @generated SignedSource<<057fb2171ecc9f8a345622869360aada>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getReportTemplateDataQuery$variables = {
  dataset_id: number;
};
export type getReportTemplateDataQuery$data = {
  readonly sys_datasets: ReadonlyArray<{
    readonly dataset_slug: string;
    readonly unified_ingest_schemas: any | null;
  }>;
};
export type getReportTemplateDataQuery = {
  response: getReportTemplateDataQuery$data;
  variables: getReportTemplateDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_datasets",
    "kind": "LinkedField",
    "name": "sys_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unified_ingest_schemas",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getReportTemplateDataQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getReportTemplateDataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a603c6ef1e2c181a7c63ec8ee1477df",
    "id": null,
    "metadata": {},
    "name": "getReportTemplateDataQuery",
    "operationKind": "query",
    "text": "query getReportTemplateDataQuery(\n  $dataset_id: Int!\n) {\n  sys_datasets(where: {dataset_id: {_eq: $dataset_id}}) {\n    unified_ingest_schemas\n    dataset_slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7e37af8967813ce176993bf91be59f2";

export default node;
