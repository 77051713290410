/**
 * @generated SignedSource<<0afb88c5d93f3a75292cd3c5532b5a66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getBatchValidationRulesQuery$variables = {
  datasets: ReadonlyArray<number>;
};
export type getBatchValidationRulesQuery$data = {
  readonly sys_batch_validation_rules: ReadonlyArray<{
    readonly batch_validation_rule_id: number;
    readonly datasets_batch_validation_rules: ReadonlyArray<{
      readonly dataset: {
        readonly dataset_label: string;
      };
      readonly dataset_id: number;
    }>;
    readonly validation_description: string | null;
    readonly validation_label: string;
    readonly validation_slug: string;
  }>;
};
export type getBatchValidationRulesQuery = {
  response: getBatchValidationRulesQuery$data;
  variables: getBatchValidationRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "datasets"
  }
],
v1 = {
  "_eq": true
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "validation_label": "asc"
        }
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "datasets_batch_validation_rules",
            "value": {
              "is_active": (v1/*: any*/)
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_nin",
                            "variableName": "datasets"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "dataset_id"
                      },
                      {
                        "kind": "Literal",
                        "name": "is_active",
                        "value": (v1/*: any*/)
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "filter"
                  },
                  {
                    "kind": "Literal",
                    "name": "predicate",
                    "value": {
                      "_eq": 0
                    }
                  }
                ],
                "kind": "ObjectValue",
                "name": "count"
              }
            ],
            "kind": "ObjectValue",
            "name": "datasets_batch_validation_rules_aggregate"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_batch_validation_rules",
    "kind": "LinkedField",
    "name": "sys_batch_validation_rules",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "batch_validation_rule_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validation_label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validation_slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validation_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets_batch_validation_rules",
        "kind": "LinkedField",
        "name": "datasets_batch_validation_rules",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_datasets",
            "kind": "LinkedField",
            "name": "dataset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataset_label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getBatchValidationRulesQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getBatchValidationRulesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2019bb9c88102783b56f4c9a6ea9f3f6",
    "id": null,
    "metadata": {},
    "name": "getBatchValidationRulesQuery",
    "operationKind": "query",
    "text": "query getBatchValidationRulesQuery(\n  $datasets: [Int!]!\n) {\n  sys_batch_validation_rules(where: {datasets_batch_validation_rules: {is_active: {_eq: true}}, datasets_batch_validation_rules_aggregate: {count: {predicate: {_eq: 0}, filter: {dataset_id: {_nin: $datasets}, is_active: {_eq: true}}}}}, order_by: {validation_label: asc}) {\n    batch_validation_rule_id\n    validation_label\n    validation_slug\n    validation_description\n    datasets_batch_validation_rules {\n      dataset_id\n      dataset {\n        dataset_label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42d239903dfae1613b26e235dcdaf152";

export default node;
