import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreStateDebounced,
  useCherreValue,
  useCherreState,
  useIsSuspended,
  waitForAll,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  MuiTabs,
  Typography,
  styled,
  SearchInput,
  Panel,
  Tab as MuiTab,
} from '@cherre-frontend/ui';
import { useAppContext } from '@cherre-frontend/core';
import { PropertiesTable, Tab, ExcludedPropertiesBanner } from './components';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '../../../../components';
import {
  SelectPropertiesState as state,
  dialogStage,
  newBatchInfo,
} from './recoil';
import { stages } from './consts';
import { createBatchDialogOpenState } from '../../recoil';
import * as TEST_IDS from './testIDs';
import { useCreateBatchForm } from 'src/products/data-submission-portal/hooks/useCreateBatchForm';

const InformationBar = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  b {
    font-weight: 700;
  }
`;

const TabsContainer = styled(MuiTabs)`
  border-bottom: 1px solid #9e9e9e;
  height: 44px;
  min-height: 44px;
`;

const SelectProperties: React.FC = () => {
  const { logger } = useAppContext();

  const suspended = useIsSuspended();

  const initialState = useCherreValue(newBatchInfo) ?? undefined;

  const subTypes = initialState?.submission_types ?? [];

  const properties = useCherreValue(waitForAll(subTypes.map(state.selection)));

  const getProperties = (type: number) => {
    const idx = subTypes.indexOf(type);
    if (idx === -1) {
      throw new Error(`Submission type with ID ${type} not found`);
    }
    const props = properties?.[idx];
    if (!props) {
      throw new Error('Property selection for submission type not found');
    }
    return Object.entries(props)
      .filter(([, v]) => v)
      .map(([k]) => Number(k));
  };

  const { dueDate, submissionPeriod, options, createBatch, canCreateBatch } =
    useCreateBatchForm({
      initialState,
      propertyIds: getProperties,
      onCompleted: (_, ctx) =>
        ctx.recoil.set(createBatchDialogOpenState, false),
    });
  const submissionTypes = useCherreValue(options.submissionTypes);

  const [selectedTab, setSelectedTab] = useCherreState(state.selectedTab);

  if (!selectedTab) {
    return null;
  }

  const batchName = useCherreValue(state.generatedBatchName(selectedTab));
  logger.info('generated batch name', batchName);

  const provider = useCherreValue(state.currentProvider);
  logger.info('provider', provider);

  const [search, debouncedSearch, setSearch] = useCherreStateDebounced(
    state.tableSearch(selectedTab)
  );
  logger.info('search', search, debouncedSearch);

  const onBack = useCherreEventWithRecoil(
    'user went back to first part of create new batch dialog',
    (ctx) => () => {
      ctx.recoil.set(dialogStage, stages.DefineBatchDetails);
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed create batch dialog',
    (ctx) => () => {
      ctx.recoil.set(createBatchDialogOpenState, false);
    }
  );

  return (
    <>
      <DialogHeader
        title='Create New Batch'
        subtitle='Select properties that will be included in this batch'
        onClose={onClose}
      />
      <DialogContent rowSpacing='20px'>
        <ExcludedPropertiesBanner />
        <Grid item xs={12}>
          <InformationBar data-testid={TEST_IDS.BATCH_INFO} className='suspend'>
            <b>Batch Name:</b> {batchName} {'•'} <b>Period:</b>{' '}
            {submissionPeriod} {'•'} <b>Due Date:</b> {dueDate('LL')}
          </InformationBar>
        </Grid>
        <Grid item xs={12}>
          <TabsContainer
            value={suspended ? false : selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            {initialState?.submission_types?.map((t) => (
              <Tab
                key={t}
                value={t}
                label={
                  submissionTypes?.find((st) => st.value === t)?.label || ''
                }
              />
            ))}
            <MuiTab value={0} label='' disabled />
          </TabsContainer>
        </Grid>
        <Grid item xs={12}>
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel id='PropertiesTable'>
            <PropertiesTable selectedTab={selectedTab} />
          </Panel>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          testID={TEST_IDS.BACK_BUTTON}
          variant='outlined'
          color='primary'
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          testID={TEST_IDS.CREATE_BATCH_BUTTON}
          variant='contained'
          color='primary'
          disabled={!canCreateBatch}
          onClick={createBatch}
        >
          Create Batch
          {(initialState?.submission_types?.length ?? 0) > 1 ? 'es' : ''}
        </Button>
      </DialogActions>
    </>
  );
};

SelectProperties.displayName = 'SelectProperties';

export default SelectProperties;
