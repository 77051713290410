import { atom } from '@cherre-frontend/data-fetching';
import { Property as PropertiesPageProperty } from 'src/products/data-submission-portal/pages/properties/Panels/PropertiesPanel/queries/getProperties';
import { Property } from '../../pages/provider-detail/Panels/PropertiesTab/queries/getProperties';

export const mappingIdToAssignState = atom<number | null>({
  key: 'mapping-id-to-assign',
  default: null,
});

export const assignMappingDialogTitle = atom<string>({
  key: 'assign-mapping-dialog-title',
  default: 'Assign COA Mappings',
});

export const assignMappingDialogSubtitle = atom<string | null>({
  key: 'assign-mapping-dialog-subtitle',
  default: null,
});

export const assignMappingToPropertyState = atom<
  Property | PropertiesPageProperty | null
>({
  key: 'assign-mapping-to-property',
  default: null,
});
