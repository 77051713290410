import React from 'react';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import {
  Grid,
  PropertiesIcon,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { AddButton } from '../../../styles';
import { assignPropertiesDialogOpenState } from '../recoil';

const NoProperties: React.FC = () => {
  const theme = useTheme();

  const setAssignPropertiesDialogOpenState = useCherreSetState(
    assignPropertiesDialogOpenState
  );

  return (
    <Grid container direction='column' alignItems='center'>
      <PropertiesIcon
        style={{
          width: '36px',
          height: 'auto',
          color: theme.palette.grey[400],
        }}
      />
      <Typography variant='h5' style={{ fontWeight: 600 }}>
        No Properties Associated
      </Typography>
      <Typography variant='body1'>
        There are no properties associated with this provider.
      </Typography>
      <AddButton
        variant='contained'
        onClick={() => setAssignPropertiesDialogOpenState(true)}
      >
        Assign Properties
      </AddButton>
    </Grid>
  );
};

export default NoProperties;
