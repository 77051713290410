import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getUnassignedPropertiesQuery } from './__generated__/getUnassignedPropertiesQuery.graphql';

export const getUnassignedProperties = graphQLSelector({
  query: graphql`
    query getUnassignedPropertiesQuery {
      sys_properties_aggregate(
        where: {
          _not: { property_batches: {} }
          properties_roles_users: {
            is_active: { _eq: true }
            property_role_id: { _in: [2, 3, 4] }
          }
          properties_datasets: { is_active: { _eq: true } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getUnassignedPropertiesQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (response) =>
    response.sys_properties_aggregate.aggregate?.count ?? 0,
});
