import { Button } from '@cherre-frontend/ui';
import React from 'react';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { useCherreEvent } from '@cherre-frontend/core';
import { addEditPropertyDialogState, useResetPropertyForm } from '../recoil';

export const AddPropertyButton: React.FC = () => {
  const isAdminUploadPropertyEnabled = useFeatureFlag('DspAdminUploadProperty');
  const setAddEditDialogState = useCherreSetState(addEditPropertyDialogState);
  const resetAddEditPropertyForm = useResetPropertyForm();

  const onAddPropertyClick = useCherreEvent('add property click', () => {
    resetAddEditPropertyForm();
    setAddEditDialogState({
      isOpen: true,
      type: 'Add',
    });
  });

  if (isAdminUploadPropertyEnabled) {
    return (
      <Button
        onClick={onAddPropertyClick}
        variant='contained'
        color='primary'
        sx={{ marginTop: '12px' }}
      >
        <span>Add Property</span>
      </Button>
    );
  }

  return null;
};
