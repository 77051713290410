import { Grid, Typography, useTheme } from '@cherre-frontend/ui';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import React from 'react';
import { AddUserButton } from './AddUserButton';

export type ProviderUsersPanelProps = {
  search?: string;
};

export const NoUsersAdded: React.FC<ProviderUsersPanelProps> = () => {
  const theme = useTheme();

  return (
    <Grid container direction='column' alignItems='center'>
      <SupervisedUserCircleIcon
        style={{
          width: '36px',
          height: 'auto',
          color: theme.palette.grey[400],
        }}
      />
      <Typography variant='h5' style={{ fontWeight: 600 }}>
        No Users
      </Typography>
      <Typography variant='body1'>There are no users added yet.</Typography>
      <AddUserButton />
    </Grid>
  );
};

NoUsersAdded.displayName = 'NoUsers';
