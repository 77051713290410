import {
  GetCherreValue,
  GraphQLReturn,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import {
  getSubmissionsSubscription,
  sys_submissions_bool_exp,
} from './__generated__/getSubmissionsSubscription.graphql';
import { getSubmissionsTotalSubscription } from './__generated__/getSubmissionsTotalSubscription.graphql';
import { graphql } from 'react-relay';
import {
  batchManagementTableSearch,
  batchManagementTableState,
  batchesTableFiltersState,
} from '../../../recoil';
import { MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { getSubmissionsReportingPeriodsFilterSubscription } from './__generated__/getSubmissionsReportingPeriodsFilterSubscription.graphql';
import { getSubmissionsProvidersFilterSubscription } from './__generated__/getSubmissionsProvidersFilterSubscription.graphql';
import { getSubmissionsFundsFilterSubscription } from './__generated__/getSubmissionsFundsFilterSubscription.graphql';
import { getSubmissionsSubmissionTypesFilterSubscription } from './__generated__/getSubmissionsSubmissionTypesFilterSubscription.graphql';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

const buildSubmissionsWhereClause = (
  get: GetCherreValue
): sys_submissions_bool_exp => {
  const clauses: sys_submissions_bool_exp[] = [];

  //filter based on search text
  const searchText = get(batchManagementTableSearch);
  if (searchText) {
    clauses.push({
      _or: [
        { submission_name: { _ilike: `%${searchText}%` } },
        {
          submission_type: {
            submission_type_label: { _ilike: `%${searchText}%` },
          },
        },
        { submission_status_description: { _ilike: `%${searchText}%` } },
        { provider: { provider_name: { _ilike: `%${searchText}%` } } },
      ],
    });
  }

  //filter based on dropdowns
  const { Provider, Status, Type, Period, Fund, Interval } = get(
    batchesTableFiltersState
  );

  if (Provider !== 'Any Provider') {
    clauses.push({
      provider: { provider_name: { _ilike: `${Provider}` } },
    });
  }

  if (Fund !== 'Any Fund') {
    clauses.push({
      property_batches: { property: { fund: { _ilike: `${Fund}` } } },
    });
  }

  if (Type !== 'Any Type') {
    clauses.push({
      submission_type: {
        submission_type_label: { _ilike: `${Type}` },
      },
    });
  }

  if (Interval !== 'Any Interval') {
    clauses.push({
      reporting_period: { _ilike: `${Interval}` },
    });
  }

  if (Period !== 'Any Period') {
    clauses.push({
      reporting_period_start_date: { _eq: moment(Period).format('YYYY-MM-DD') },
    });
  }

  if (Status !== 'Any Status') {
    clauses.push({
      submission_status_description: { _ilike: `${Status}` },
    });
  }

  return { _and: clauses };
};

export const getSubmissionsTotal = graphQLSelector({
  query: graphql`
    subscription getSubmissionsTotalSubscription(
      $where_clause: sys_submissions_bool_exp!
    ) {
      total: sys_submissions_aggregate(where: $where_clause) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getSubmissionsTotalSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        where_clause: buildSubmissionsWhereClause(get),
      };
    },
  mapResponse: (resp) => resp.total,
});

export const getSubmissions = graphQLSelector({
  query: graphql`
    subscription getSubmissionsSubscription(
      $where_clause: sys_submissions_bool_exp!
      $order_by: [sys_submissions_order_by!] = {}
      $limit: Int = 10
      $offset: Int = 0
    ) {
      sys_submissions(
        where: $where_clause
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        submission_status_description
        due_date
        reporting_period_start_date
        reporting_period_end_date
        owner_id
        provider {
          provider_id
          provider_name
        }
        submission_id
        submission_type {
          submission_type_id
          submission_type_label
        }
        submission_name
        closed_at_datetime
        created_at_datetime
        updated_at_datetime

        approved_properties: property_batches_aggregate(
          where: {
            is_approved: { _eq: true }
            _and: {
              property: {
                property_type: { property_type_slug: { _neq: "investment" } }
              }
            }
          }
        ) {
          aggregate {
            count
          }
        }

        properties: property_batches_aggregate(
          where: {
            property: {
              property_type: { property_type_slug: { _neq: "investment" } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        property_batches {
          property {
            fund
          }
        }
      }
    }
  ` as GraphQLReturn<getSubmissionsSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const tableState = get(batchManagementTableState);

      return {
        where_clause: buildSubmissionsWhereClause(get),
        limit: tableState?.pagination.pageSize ?? 10,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
      };
    },
  mapResponse: (resp) => resp.sys_submissions,
});

export const getProvidersFilter = graphQLSelector({
  query: graphql`
    subscription getSubmissionsProvidersFilterSubscription {
      sys_providers(
        distinct_on: provider_name
        order_by: { provider_name: asc }
        where: { submissions: {} }
      ) {
        provider_name
      }
    }
  ` as GraphQLReturn<getSubmissionsProvidersFilterSubscription>,
  mapResponse: (resp) => resp.sys_providers.map((p) => p.provider_name),
});

export const getFundsFilter = graphQLSelector({
  query: graphql`
    subscription getSubmissionsFundsFilterSubscription {
      sys_properties(
        distinct_on: fund
        order_by: { fund: asc }
        where: { property_batches: {}, fund: { _is_null: false } }
      ) {
        fund
      }
    }
  ` as GraphQLReturn<getSubmissionsFundsFilterSubscription>,
  mapResponse: (resp) =>
    resp.sys_properties.map((f) => f.fund).filter((f) => f !== null),
});

export const getSubmissionTypesFilter = graphQLSelector({
  query: graphql`
    subscription getSubmissionsSubmissionTypesFilterSubscription {
      sys_submission_types(
        distinct_on: submission_type_label
        order_by: { submission_type_label: asc }
      ) {
        submission_type_label
      }
    }
  ` as GraphQLReturn<getSubmissionsSubmissionTypesFilterSubscription>,
  mapResponse: (resp) =>
    resp.sys_submission_types.map((t) => t.submission_type_label),
});

export const getReportingPeriodsFilter = graphQLSelector({
  query: graphql`
    subscription getSubmissionsReportingPeriodsFilterSubscription {
      sys_submissions(
        distinct_on: reporting_period_start_date
        order_by: { reporting_period_start_date: desc }
      ) {
        reporting_period_start_date
      }
    }
  ` as GraphQLReturn<getSubmissionsReportingPeriodsFilterSubscription>,
  mapResponse: (resp) =>
    resp.sys_submissions.map((s) => s.reporting_period_start_date),
});
