/**
 * @generated SignedSource<<3f62baa309ab1339005f3942c4a1aa47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type order_by = "asc" | "asc_nulls_first" | "asc_nulls_last" | "desc" | "desc_nulls_first" | "desc_nulls_last" | "%future added value";
export type sakura_organization_permission_select_column = "id" | "name" | "organization_id" | "%future added value";
export type sakura_user_permission_select_column = "id" | "name" | "user_id" | "%future added value";
export type sakura_user_select_column = "attributes" | "auth0_synced" | "create_time" | "dashboards" | "defaultPowerBiReportId" | "disabled" | "email" | "email_ci" | "first_name" | "id" | "last_modified" | "last_name" | "mongo_id" | "organization_id" | "role" | "%future added value";
export type sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_and_arguments_columns = "auth0_synced" | "disabled" | "%future added value";
export type sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_or_arguments_columns = "auth0_synced" | "disabled" | "%future added value";
export type sys_batch_files_select_column = "created_at_datetime" | "dataset_id" | "file_id" | "file_name" | "file_reference_souce" | "file_source" | "organization_id" | "processed_row_count" | "row_count" | "submitter_id" | "updated_at_datetime" | "%future added value";
export type sys_comments_select_column = "author_id" | "comment_id" | "comment_type_id" | "created_at_datetime" | "package_report_id" | "property_batch_id" | "text" | "%future added value";
export type sys_dataset_validation_rules_select_column = "banner_config" | "created_at_datetime" | "dataset_id" | "dataset_validation_rule_id" | "feature_flag" | "is_active" | "organization_id" | "report_id" | "updated_at_datetime" | "validation_description" | "validation_label" | "validation_params" | "validation_slug" | "validation_type" | "%future added value";
export type sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column = "batch_validation_rule_id" | "dataset_batch_validation_rule_id" | "dataset_id" | "is_active" | "organization_id" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_datasets_select_column = "created_at_datetime" | "dataset_description" | "dataset_id" | "dataset_label" | "dataset_object" | "dataset_slug" | "feature_flag" | "flatfile_embed_id" | "flatfile_private_key" | "is_tsa_enabled" | "managed_by" | "organization_id" | "submission_type_id" | "tsa_flatfile_embed_id" | "tsa_flatfile_private_key" | "unified_ingest_schemas" | "updated_at_datetime" | "%future added value";
export type sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_tsa_enabled" | "%future added value";
export type sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_tsa_enabled" | "%future added value";
export type sys_mapping_fields_select_column = "created_at_datetime" | "custom_attributes" | "is_active" | "is_single_mapping_set" | "mapping_field_description" | "mapping_field_id" | "mapping_field_name" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "is_single_mapping_set" | "%future added value";
export type sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "is_single_mapping_set" | "%future added value";
export type sys_mapping_set_values_select_column = "created_at_datetime" | "custom_attributes" | "is_active" | "mapping_set_id" | "mapping_set_value_id" | "organization_id" | "source_mapping_code" | "source_mapping_label" | "target_mapping_code" | "target_mapping_label" | "updated_at_datetime" | "%future added value";
export type sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_sets_select_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_description" | "mapping_set_id" | "master_chart_of_account_id" | "organization_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_notification_recipients_select_column = "failed_count" | "last_failed_attempt_at_datetime" | "notification_id" | "sent_at_datetime" | "user_id" | "%future added value";
export type sys_notifications_select_column = "created_at_datetime" | "notification_detail_data" | "notification_group_data" | "notification_id" | "notification_source_data" | "notification_type_id" | "%future added value";
export type sys_organization_account_tree_details_select_column = "account_code_description" | "account_group_code" | "account_tree_id" | "account_type" | "chart_of_account_id" | "created_at_datetime" | "gl_account_code" | "indent" | "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "nest_level" | "order_index" | "organization_id" | "parent_chart_of_account_id" | "total_into_code" | "tree_path" | "updated_at_datetime" | "%future added value";
export type sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_and_arguments_columns = "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "%future added value";
export type sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_or_arguments_columns = "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column = "account_code" | "account_hold" | "account_name" | "created_at_datetime" | "is_active" | "master_chart_of_account_id" | "master_chart_of_account_values_id" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and_arguments_columns = "account_hold" | "is_active" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or_arguments_columns = "account_hold" | "is_active" | "%future added value";
export type sys_organizations_select_column = "created_at_datetime" | "flatfile_team_name" | "organization_description" | "organization_id" | "organization_label" | "organization_slug" | "property_model_id" | "settings" | "updated_at_datetime" | "%future added value";
export type sys_package_reports_datasets_select_column = "dataset_id" | "package_report_dataset_id" | "package_report_id" | "%future added value";
export type sys_properties_batch_validation_rules_select_column = "batch_validation_rule_id" | "created_at_datetime" | "is_active" | "organization_id" | "property_batch_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column = "created_at_datetime" | "dataset_validation_rule_id" | "is_active" | "organization_id" | "property_dataset_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_datasets_select_column = "dataset_id" | "is_active" | "organization_id" | "property_dataset_id" | "property_id" | "template_id" | "%future added value";
export type sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_mapping_sets_select_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_id" | "organization_id" | "property_id" | "property_mapping_set_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_roles_users_select_column = "created_at_datetime" | "is_active" | "organization_id" | "property_id" | "property_role_id" | "property_role_user_id" | "provider_id" | "submission_type_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_select_column = "address" | "created_at_datetime" | "custom_attributes" | "entity_id" | "entity_name" | "fund" | "is_active" | "is_sold" | "organization_id" | "property_code" | "property_id" | "property_name" | "property_type_id" | "provider_id" | "sold_date" | "type" | "updated_at_datetime" | "%future added value";
export type sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "is_sold" | "%future added value";
export type sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "is_sold" | "%future added value";
export type sys_property_batch_dataset_validations_select_column = "dataset_validation_rule_id" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_dataset_validation_id" | "validated_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_datasets_select_column = "approved_datetime" | "created_at_datetime" | "dataset_id" | "dataset_row_count" | "dataset_status_id" | "file_id" | "is_approved" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_id" | "updated_at_datetime" | "validated_datetime" | "%future added value";
export type sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batch_package_reports_select_column = "is_reviewed" | "organization_id" | "package_report_id" | "property_batch_id" | "property_batch_package_report_id" | "property_batch_stage_id" | "reviewed_datetime" | "user_id" | "%future added value";
export type sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_and_arguments_columns = "is_reviewed" | "%future added value";
export type sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_or_arguments_columns = "is_reviewed" | "%future added value";
export type sys_property_batch_role_statuses_select_column = "property_batch_stage_id" | "property_role_id" | "%future added value";
export type sys_property_batch_stage_transitions_select_column = "action" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_batch_stage_transition_id" | "transition_datetime" | "user_id" | "%future added value";
export type sys_property_batch_validations_select_column = "created_at_datetime" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_validation_id" | "property_batch_validation_rule_id" | "updated_at_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_and_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_or_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batches_approved_select_column = "approved_at_datetime" | "created_at_datetime" | "is_approved" | "is_valid" | "property_batch_id" | "property_batch_stage_id" | "property_id" | "submission_id" | "updated_at_datetime" | "validated_at_datetime" | "%future added value";
export type sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_select_column = "approved_at_datetime" | "created_at_datetime" | "is_approved" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_id" | "submission_id" | "updated_at_datetime" | "validated_at_datetime" | "%future added value";
export type sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_models_types_select_column = "approved_property_batch_stage_id" | "created_at_datetime" | "inactivated_at_datetime" | "initial_property_batch_stage_id" | "is_active" | "property_model_id" | "property_model_type_id" | "property_type_id" | "updated_at_datetime" | "%future added value";
export type sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_property_relationships_select_column = "created_at_datetime" | "inactivated_at_datetime" | "is_active" | "organization_id" | "property_id_from" | "property_id_to" | "property_relationship_id" | "updated_at_datetime" | "%future added value";
export type sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_providers_properties_select_column = "created_at_datetime" | "inactivated_at_datetime" | "is_active" | "organization_id" | "property_id" | "provider_id" | "provider_property_id" | "updated_at_datetime" | "%future added value";
export type sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_providers_select_column = "created_at_datetime" | "ingestion_config" | "is_active" | "organization_id" | "provider_id" | "provider_name" | "provider_type_id" | "related_data_updated_at" | "report_config" | "updated_at_datetime" | "%future added value";
export type sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_reminders_reviewed_select_column = "organization_id" | "reminder_id" | "reminders_reviewed_id" | "reviewed_at_datetime" | "user_id" | "%future added value";
export type sys_reminders_select_column = "author_id" | "created_at_datetime" | "is_resolved" | "organization_id" | "package_report_id" | "property_batch_id" | "property_id" | "reminder_id" | "submission_id" | "text" | "%future added value";
export type sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_and_arguments_columns = "is_resolved" | "%future added value";
export type sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_or_arguments_columns = "is_resolved" | "%future added value";
export type sys_submission_types_select_column = "created_at_datetime" | "feature_flag" | "organization_id" | "submission_type_description" | "submission_type_id" | "submission_type_label" | "submission_type_slug" | "updated_at_datetime" | "%future added value";
export type sys_submissions_select_column = "closed_at_datetime" | "created_at_datetime" | "due_date" | "fiscal_year_flag" | "is_closed" | "organization_id" | "owner_id" | "provider_id" | "reporting_period" | "reporting_period_end_date" | "reporting_period_start_date" | "submission_id" | "submission_name" | "submission_type_id" | "updated_at_datetime" | "workflow_mode_id" | "%future added value";
export type sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_and_arguments_columns = "fiscal_year_flag" | "is_closed" | "%future added value";
export type sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_or_arguments_columns = "fiscal_year_flag" | "is_closed" | "%future added value";
export type sys_users_select_column = "created_at_datetime" | "is_active" | "organization_id" | "provider_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_users_select_column_sys_users_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_users_select_column_sys_users_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_property_batches_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_bool_exp> | null;
  _not?: sys_property_batches_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_bool_exp> | null;
  approved_at_datetime?: timestamptz_comparison_exp | null;
  check_regular_user_permission?: Boolean_comparison_exp | null;
  comments?: sys_comments_bool_exp | null;
  comments_aggregate?: sys_comments_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  cycle_time?: numeric_comparison_exp | null;
  investment_is_ready_for_bulk_approve?: Boolean_comparison_exp | null;
  investment_is_ready_for_bulk_submit?: Boolean_comparison_exp | null;
  investment_is_ready_for_submit?: Boolean_comparison_exp | null;
  is_all_children_in_allowed_stage_for_user?: Boolean_comparison_exp | null;
  is_all_children_ready_for_submit?: Boolean_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_in_last_review_stage?: Boolean_comparison_exp | null;
  is_ready_for_bulk_submit?: Boolean_comparison_exp | null;
  is_ready_for_reject?: Boolean_comparison_exp | null;
  is_ready_for_submit?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  kpi_datasets_total_count?: Int_comparison_exp | null;
  kpi_datasets_uploaded_count?: Int_comparison_exp | null;
  kpi_package_reports_reviewed_count?: Int_comparison_exp | null;
  kpi_package_reports_total_count?: Int_comparison_exp | null;
  kpi_properties_approved_count?: Int_comparison_exp | null;
  kpi_properties_approved_or_rejected_count?: Int_comparison_exp | null;
  kpi_properties_submitted_count?: Int_comparison_exp | null;
  kpi_validations_failed_count?: Int_comparison_exp | null;
  kpi_validations_warn_count?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_reports?: sys_package_reports_bool_exp | null;
  properties_flattened_union?: sys_properties_flattened_union_bool_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batch_validations?: sys_property_batch_validations_bool_exp | null;
  property_batch_validations_aggregate?: sys_property_batch_validations_aggregate_bool_exp | null;
  property_batches_monitoring?: sys_property_batches_monitoring_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  reminders?: sys_reminders_bool_exp | null;
  reminders_aggregate?: sys_reminders_aggregate_bool_exp | null;
  role_status?: String_comparison_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  users_with_access_to_batch?: sys_properties_roles_users_bool_exp | null;
  users_with_access_to_batch_count?: Int_comparison_exp | null;
  validated_at_datetime?: timestamptz_comparison_exp | null;
};
export type timestamptz_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: ReadonlyArray<boolean> | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: ReadonlyArray<boolean> | null;
};
export type sys_comments_bool_exp = {
  _and?: ReadonlyArray<sys_comments_bool_exp> | null;
  _not?: sys_comments_bool_exp | null;
  _or?: ReadonlyArray<sys_comments_bool_exp> | null;
  author_id?: Int_comparison_exp | null;
  comment_id?: Int_comparison_exp | null;
  comment_type?: sys_comment_types_bool_exp | null;
  comment_type_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: sys_users_bool_exp | null;
};
export type Int_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_comment_types_bool_exp = {
  _and?: ReadonlyArray<sys_comment_types_bool_exp> | null;
  _not?: sys_comment_types_bool_exp | null;
  _or?: ReadonlyArray<sys_comment_types_bool_exp> | null;
  comment_type_id?: Int_comparison_exp | null;
  comment_type_slug?: String_comparison_exp | null;
};
export type String_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: ReadonlyArray<string> | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: ReadonlyArray<string> | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
};
export type sys_package_reports_bool_exp = {
  _and?: ReadonlyArray<sys_package_reports_bool_exp> | null;
  _not?: sys_package_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_package_reports_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_params?: jsonb_comparison_exp | null;
  package_report_description?: String_comparison_exp | null;
  package_report_id?: Int_comparison_exp | null;
  package_report_label?: String_comparison_exp | null;
  package_report_slug?: String_comparison_exp | null;
  package_reports_datasets?: sys_package_reports_datasets_bool_exp | null;
  package_reports_datasets_aggregate?: sys_package_reports_datasets_aggregate_bool_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: ReadonlyArray<string> | null;
  _has_keys_any?: ReadonlyArray<string> | null;
  _in?: ReadonlyArray<any> | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: ReadonlyArray<any> | null;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null;
};
export type sys_package_reports_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_package_reports_datasets_bool_exp> | null;
  _not?: sys_package_reports_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_package_reports_datasets_bool_exp> | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_dataset_id?: Int_comparison_exp | null;
  package_report_id?: Int_comparison_exp | null;
};
export type sys_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_datasets_bool_exp> | null;
  _not?: sys_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_datasets_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_description?: String_comparison_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_label?: String_comparison_exp | null;
  dataset_object?: jsonb_comparison_exp | null;
  dataset_slug?: String_comparison_exp | null;
  dataset_validation_rules?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rules_aggregate?: sys_dataset_validation_rules_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  flatfile_embed_id?: String_comparison_exp | null;
  flatfile_private_key?: String_comparison_exp | null;
  is_tsa_enabled?: Boolean_comparison_exp | null;
  managed_by?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_reports_datasets?: sys_package_reports_datasets_bool_exp | null;
  package_reports_datasets_aggregate?: sys_package_reports_datasets_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  tsa_flatfile_embed_id?: String_comparison_exp | null;
  tsa_flatfile_private_key?: String_comparison_exp | null;
  unified_ingest_schemas?: jsonb_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_batch_files_bool_exp = {
  _and?: ReadonlyArray<sys_batch_files_bool_exp> | null;
  _not?: sys_batch_files_bool_exp | null;
  _or?: ReadonlyArray<sys_batch_files_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  file_id?: Int_comparison_exp | null;
  file_name?: String_comparison_exp | null;
  file_reference_souce?: String_comparison_exp | null;
  file_source?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  processed_row_count?: Int_comparison_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_datasets_by_dataset_id_file_id?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_by_dataset_id_file_id_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  row_count?: Int_comparison_exp | null;
  submitter?: sys_users_bool_exp | null;
  submitter_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organizations_bool_exp = {
  _and?: ReadonlyArray<sys_organizations_bool_exp> | null;
  _not?: sys_organizations_bool_exp | null;
  _or?: ReadonlyArray<sys_organizations_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets?: sys_datasets_bool_exp | null;
  datasets_aggregate?: sys_datasets_aggregate_bool_exp | null;
  flatfile_team_name?: String_comparison_exp | null;
  mapping_fields?: sys_mapping_fields_bool_exp | null;
  mapping_fields_aggregate?: sys_mapping_fields_aggregate_bool_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_description?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_label?: String_comparison_exp | null;
  organization_slug?: String_comparison_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_model?: sys_property_models_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  providers?: sys_providers_bool_exp | null;
  providers_aggregate?: sys_providers_aggregate_bool_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sakura_users?: sakura_user_bool_exp | null;
  sakura_users_aggregate?: sakura_user_aggregate_bool_exp | null;
  settings?: jsonb_comparison_exp | null;
  submission_types?: sys_submission_types_bool_exp | null;
  submission_types_aggregate?: sys_submission_types_aggregate_bool_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamp_comparison_exp | null;
};
export type sys_batch_files_aggregate_bool_exp = {
  count?: sys_batch_files_aggregate_bool_exp_count | null;
};
export type sys_batch_files_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_batch_files_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_batch_files_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_datasets_aggregate_bool_exp = {
  bool_and?: sys_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_datasets_aggregate_bool_exp_count | null;
};
export type sys_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_mapping_fields_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_fields_bool_exp> | null;
  _not?: sys_mapping_fields_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_fields_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_single_mapping_set?: Boolean_comparison_exp | null;
  mapping_field_description?: String_comparison_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_field_name?: String_comparison_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_mapping_sets_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_sets_bool_exp> | null;
  _not?: sys_mapping_sets_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_sets_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_field?: sys_mapping_fields_bool_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_set_description?: String_comparison_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  mapping_set_values?: sys_mapping_set_values_bool_exp | null;
  mapping_set_values_aggregate?: sys_mapping_set_values_aggregate_bool_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_mapping_set_values_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_set_values_bool_exp> | null;
  _not?: sys_mapping_set_values_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_set_values_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_set?: sys_mapping_sets_bool_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  mapping_set_value_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  source_mapping_code?: String_comparison_exp | null;
  source_mapping_label?: String_comparison_exp | null;
  target_mapping_code?: String_comparison_exp | null;
  target_mapping_label?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_mapping_set_values_aggregate_bool_exp = {
  bool_and?: sys_mapping_set_values_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_set_values_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_set_values_aggregate_bool_exp_count | null;
};
export type sys_mapping_set_values_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_set_values_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_set_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_set_values_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organization_master_chart_of_accounts_bool_exp = {
  _and?: ReadonlyArray<sys_organization_master_chart_of_accounts_bool_exp> | null;
  _not?: sys_organization_master_chart_of_accounts_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_master_chart_of_accounts_bool_exp> | null;
  chart_of_accounts_description?: String_comparison_exp | null;
  chart_of_accounts_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_account_values?: sys_organization_master_chart_of_account_values_bool_exp | null;
  organization_master_chart_of_account_values_aggregate?: sys_organization_master_chart_of_account_values_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_master_chart_of_account_values_bool_exp = {
  _and?: ReadonlyArray<sys_organization_master_chart_of_account_values_bool_exp> | null;
  _not?: sys_organization_master_chart_of_account_values_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_master_chart_of_account_values_bool_exp> | null;
  account_code?: String_comparison_exp | null;
  account_hold?: Boolean_comparison_exp | null;
  account_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  master_chart_of_account_values_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp = {
  bool_and?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or | null;
  count?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_count | null;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and = {
  arguments: sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or = {
  arguments: sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organization_master_chart_of_account_values_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_mapping_sets_bool_exp = {
  _and?: ReadonlyArray<sys_properties_mapping_sets_bool_exp> | null;
  _not?: sys_properties_mapping_sets_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_mapping_sets_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_set?: sys_mapping_sets_bool_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  property_mapping_set_id?: Int_comparison_exp | null;
  provider_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamp_comparison_exp | null;
};
export type sys_properties_bool_exp = {
  _and?: ReadonlyArray<sys_properties_bool_exp> | null;
  _not?: sys_properties_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_bool_exp> | null;
  address?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  entity_id?: String_comparison_exp | null;
  entity_name?: String_comparison_exp | null;
  from_property_relationships?: sys_property_relationships_bool_exp | null;
  from_property_relationships_aggregate?: sys_property_relationships_aggregate_bool_exp | null;
  fund?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_sold?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_code?: String_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  property_name?: String_comparison_exp | null;
  property_type?: sys_property_types_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  providers_properties?: sys_providers_properties_bool_exp | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_bool_exp | null;
  reminders?: sys_reminders_bool_exp | null;
  reminders_aggregate?: sys_reminders_aggregate_bool_exp | null;
  sold_date?: date_comparison_exp | null;
  to_property_relationships?: sys_property_relationships_bool_exp | null;
  to_property_relationships_aggregate?: sys_property_relationships_aggregate_bool_exp | null;
  type?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_relationships_bool_exp = {
  _and?: ReadonlyArray<sys_property_relationships_bool_exp> | null;
  _not?: sys_property_relationships_bool_exp | null;
  _or?: ReadonlyArray<sys_property_relationships_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  from_property?: sys_properties_bool_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_flattened_union?: sys_properties_flattened_union_bool_exp | null;
  property_id_from?: Int_comparison_exp | null;
  property_id_to?: Int_comparison_exp | null;
  property_relationship_id?: Int_comparison_exp | null;
  to_property?: sys_properties_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_properties_flattened_union_bool_exp = {
  _and?: ReadonlyArray<sys_properties_flattened_union_bool_exp> | null;
  _not?: sys_properties_flattened_union_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_flattened_union_bool_exp> | null;
  address?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  datasets_count?: numeric_comparison_exp | null;
  datasets_property_id?: Int_comparison_exp | null;
  entity_id?: String_comparison_exp | null;
  entity_name?: String_comparison_exp | null;
  fund?: String_comparison_exp | null;
  investment_properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  investment_properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_ready_for_submission?: Boolean_comparison_exp | null;
  is_sold?: Boolean_comparison_exp | null;
  mappings_count?: numeric_comparison_exp | null;
  mappings_property_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  parent_property?: sys_properties_bool_exp | null;
  parent_property_id?: Int_comparison_exp | null;
  parent_property_type_id?: Int_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_code?: String_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  property_mapping?: String_comparison_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_models?: sys_property_models_bool_exp | null;
  property_name?: String_comparison_exp | null;
  property_type_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  sold_date?: date_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  users_count?: numeric_comparison_exp | null;
  users_property_id?: Int_comparison_exp | null;
};
export type numeric_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_properties_dataset_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_properties_dataset_validation_rules_bool_exp> | null;
  _not?: sys_properties_dataset_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_dataset_validation_rules_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_validation_rule?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_dataset_validation_rule_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
};
export type sys_dataset_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_dataset_validation_rules_bool_exp> | null;
  _not?: sys_dataset_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_dataset_validation_rules_bool_exp> | null;
  banner_config?: jsonb_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  feature_flag?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_bool_exp | null;
  property_batch_dataset_validations_aggregate?: sys_property_batch_dataset_validations_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_description?: String_comparison_exp | null;
  validation_label?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
  validation_slug?: String_comparison_exp | null;
  validation_type?: String_comparison_exp | null;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_dataset_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_dataset_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_dataset_validations_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_dataset_validations_bool_exp> | null;
  _not?: sys_property_batch_dataset_validations_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_dataset_validations_bool_exp> | null;
  dataset_validation_rule?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch_dataset?: sys_property_batch_datasets_bool_exp | null;
  property_batch_dataset_id?: Int_comparison_exp | null;
  property_batch_dataset_validation_id?: Int_comparison_exp | null;
  validated_datetime?: timestamp_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
};
export type sys_property_batch_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_datasets_bool_exp> | null;
  _not?: sys_property_batch_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_datasets_bool_exp> | null;
  approved_datetime?: timestamptz_comparison_exp | null;
  batch_file?: sys_batch_files_bool_exp | null;
  batch_file_by_dataset_id_file_id?: sys_batch_files_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_row_count?: Int_comparison_exp | null;
  dataset_status?: sys_dataset_statuses_bool_exp | null;
  dataset_status_id?: Int_comparison_exp | null;
  file_id?: Int_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_dataset_id?: Int_comparison_exp | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_bool_exp | null;
  property_batch_dataset_validations_aggregate?: sys_property_batch_dataset_validations_aggregate_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validated_datetime?: timestamptz_comparison_exp | null;
};
export type sys_dataset_statuses_bool_exp = {
  _and?: ReadonlyArray<sys_dataset_statuses_bool_exp> | null;
  _not?: sys_dataset_statuses_bool_exp | null;
  _or?: ReadonlyArray<sys_dataset_statuses_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_status_description?: String_comparison_exp | null;
  dataset_status_id?: Int_comparison_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batch_datasets_aggregate_bool_exp = {
  bool_and?: sys_property_batch_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_datasets_aggregate_bool_exp_count | null;
};
export type sys_property_batch_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp = {
  bool_and?: sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_dataset_validations_aggregate_bool_exp_count | null;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_dataset_validations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type timestamp_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type sys_reports_bool_exp = {
  _and?: ReadonlyArray<sys_reports_bool_exp> | null;
  _not?: sys_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_reports_bool_exp> | null;
  organization_id?: Int_comparison_exp | null;
  report_config?: jsonb_comparison_exp | null;
  report_id?: Int_comparison_exp | null;
  report_slug?: String_comparison_exp | null;
};
export type sys_properties_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_properties_batch_validation_rules_bool_exp> | null;
  _not?: sys_properties_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_batch_validation_rules_bool_exp> | null;
  batch_validation_rule?: sys_batch_validation_rules_bool_exp | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch_validation_rule_id?: Int_comparison_exp | null;
  property_batch_validations?: sys_property_batch_validations_bool_exp | null;
  property_batch_validations_aggregate?: sys_property_batch_validations_aggregate_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
};
export type sys_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_batch_validation_rules_bool_exp> | null;
  _not?: sys_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_batch_validation_rules_bool_exp> | null;
  banner_config?: jsonb_comparison_exp | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets_batch_validation_rules?: sys_datasets_batch_validation_rules_bool_exp | null;
  datasets_batch_validation_rules_aggregate?: sys_datasets_batch_validation_rules_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_params?: jsonb_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_description?: String_comparison_exp | null;
  validation_label?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
  validation_slug?: String_comparison_exp | null;
  validation_type?: String_comparison_exp | null;
};
export type sys_datasets_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_datasets_batch_validation_rules_bool_exp> | null;
  _not?: sys_datasets_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_datasets_batch_validation_rules_bool_exp> | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_batch_validation_rule_id?: Int_comparison_exp | null;
  dataset_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_datasets_batch_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_datasets_batch_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_batch_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_batch_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_validations_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_validations_bool_exp> | null;
  _not?: sys_property_batch_validations_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_validations_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_batch_validation_rule?: sys_properties_batch_validation_rules_bool_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_validation_id?: Int_comparison_exp | null;
  property_batch_validation_rule_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
};
export type sys_property_batch_validations_aggregate_bool_exp = {
  bool_and?: sys_property_batch_validations_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_validations_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_validations_aggregate_bool_exp_count | null;
};
export type sys_property_batch_validations_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_validations_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_validations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_validations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_properties_datasets_bool_exp> | null;
  _not?: sys_properties_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_datasets_bool_exp> | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_dataset_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  template_id?: String_comparison_exp | null;
};
export type sys_properties_datasets_aggregate_bool_exp = {
  bool_and?: sys_properties_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_datasets_aggregate_bool_exp_count | null;
};
export type sys_properties_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_mapping_sets_aggregate_bool_exp = {
  bool_and?: sys_properties_mapping_sets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_mapping_sets_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_mapping_sets_aggregate_bool_exp_count | null;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_mapping_sets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_roles_users_bool_exp = {
  _and?: ReadonlyArray<sys_properties_roles_users_bool_exp> | null;
  _not?: sys_properties_roles_users_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_roles_users_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  property_role?: sys_property_roles_bool_exp | null;
  property_role_id?: Int_comparison_exp | null;
  property_role_user_id?: Int_comparison_exp | null;
  provider_id?: Int_comparison_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_property_roles_bool_exp = {
  _and?: ReadonlyArray<sys_property_roles_bool_exp> | null;
  _not?: sys_property_roles_bool_exp | null;
  _or?: ReadonlyArray<sys_property_roles_bool_exp> | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_role_status?: sys_property_batch_role_statuses_bool_exp | null;
  property_batch_role_status_aggregate?: sys_property_batch_role_statuses_aggregate_bool_exp | null;
  property_role_id?: Int_comparison_exp | null;
  property_role_label?: String_comparison_exp | null;
  property_role_set?: String_comparison_exp | null;
  property_role_slug?: String_comparison_exp | null;
};
export type sys_properties_roles_users_aggregate_bool_exp = {
  bool_and?: sys_properties_roles_users_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_roles_users_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_roles_users_aggregate_bool_exp_count | null;
};
export type sys_properties_roles_users_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_roles_users_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_roles_users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_roles_users_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_role_statuses_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_role_statuses_bool_exp> | null;
  _not?: sys_property_batch_role_statuses_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_role_statuses_bool_exp> | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_role_id?: Int_comparison_exp | null;
};
export type sys_property_batch_role_statuses_aggregate_bool_exp = {
  count?: sys_property_batch_role_statuses_aggregate_bool_exp_count | null;
};
export type sys_property_batch_role_statuses_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_role_statuses_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_role_statuses_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submission_types_bool_exp = {
  _and?: ReadonlyArray<sys_submission_types_bool_exp> | null;
  _not?: sys_submission_types_bool_exp | null;
  _or?: ReadonlyArray<sys_submission_types_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets?: sys_datasets_bool_exp | null;
  datasets_aggregate?: sys_datasets_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  submission_type_description?: String_comparison_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  submission_type_label?: String_comparison_exp | null;
  submission_type_slug?: String_comparison_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_submissions_bool_exp = {
  _and?: ReadonlyArray<sys_submissions_bool_exp> | null;
  _not?: sys_submissions_bool_exp | null;
  _or?: ReadonlyArray<sys_submissions_bool_exp> | null;
  closed_at_datetime?: timestamptz_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  due_date?: date_comparison_exp | null;
  fiscal_year_flag?: Boolean_comparison_exp | null;
  is_closed?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  owner_id?: Int_comparison_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_batches_approved?: sys_property_batches_approved_bool_exp | null;
  property_batches_approved_aggregate?: sys_property_batches_approved_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  reporting_period?: String_comparison_exp | null;
  reporting_period_end_date?: date_comparison_exp | null;
  reporting_period_start_date?: date_comparison_exp | null;
  submission_id?: Int_comparison_exp | null;
  submission_name?: String_comparison_exp | null;
  submission_status_description?: String_comparison_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  workflow_mode_id?: Int_comparison_exp | null;
};
export type date_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type sys_property_batches_aggregate_bool_exp = {
  bool_and?: sys_property_batches_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batches_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batches_aggregate_bool_exp_count | null;
};
export type sys_property_batches_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batches_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batches_approved_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_approved_bool_exp> | null;
  _not?: sys_property_batches_approved_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_approved_bool_exp> | null;
  approved_at_datetime?: timestamptz_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batches_approved_aggregate_bool_exp = {
  bool_and?: sys_property_batches_approved_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batches_approved_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batches_approved_aggregate_bool_exp_count | null;
};
export type sys_property_batches_approved_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_approved_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_approved_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batches_approved_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_providers_bool_exp = {
  _and?: ReadonlyArray<sys_providers_bool_exp> | null;
  _not?: sys_providers_bool_exp | null;
  _or?: ReadonlyArray<sys_providers_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  ingestion_config?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties?: sys_properties_bool_exp | null;
  properties_aggregate?: sys_properties_aggregate_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  provider_name?: String_comparison_exp | null;
  provider_type?: sys_provider_types_bool_exp | null;
  provider_type_id?: Int_comparison_exp | null;
  providers_properties?: sys_providers_properties_bool_exp | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_bool_exp | null;
  related_data_updated_at?: timestamptz_comparison_exp | null;
  report_config?: jsonb_comparison_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  users?: sys_users_bool_exp | null;
  users_aggregate?: sys_users_aggregate_bool_exp | null;
};
export type sys_mapping_sets_aggregate_bool_exp = {
  bool_and?: sys_mapping_sets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_sets_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_sets_aggregate_bool_exp_count | null;
};
export type sys_mapping_sets_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_sets_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_sets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_sets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_aggregate_bool_exp = {
  bool_and?: sys_properties_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_aggregate_bool_exp_count | null;
};
export type sys_properties_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_provider_types_bool_exp = {
  _and?: ReadonlyArray<sys_provider_types_bool_exp> | null;
  _not?: sys_provider_types_bool_exp | null;
  _or?: ReadonlyArray<sys_provider_types_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
};
export type sys_providers_properties_bool_exp = {
  _and?: ReadonlyArray<sys_providers_properties_bool_exp> | null;
  _not?: sys_providers_properties_bool_exp | null;
  _or?: ReadonlyArray<sys_providers_properties_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  provider_property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_providers_properties_aggregate_bool_exp = {
  bool_and?: sys_providers_properties_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_providers_properties_aggregate_bool_exp_bool_or | null;
  count?: sys_providers_properties_aggregate_bool_exp_count | null;
};
export type sys_providers_properties_aggregate_bool_exp_bool_and = {
  arguments: sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_properties_aggregate_bool_exp_bool_or = {
  arguments: sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_properties_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_providers_properties_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp = {
  bool_and?: sys_submissions_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_submissions_aggregate_bool_exp_bool_or | null;
  count?: sys_submissions_aggregate_bool_exp_count | null;
};
export type sys_submissions_aggregate_bool_exp_bool_and = {
  arguments: sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp_bool_or = {
  arguments: sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_submissions_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_users_bool_exp = {
  _and?: ReadonlyArray<sys_users_bool_exp> | null;
  _not?: sys_users_bool_exp | null;
  _or?: ReadonlyArray<sys_users_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  notification_recipients?: sys_notification_recipients_bool_exp | null;
  notification_recipients_aggregate?: sys_notification_recipients_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  sakura_user?: sakura_user_bool_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sakura_user_bool_exp | null;
  user_id?: Int_comparison_exp | null;
  users_last_seen?: sakura_users_last_seen_bool_exp | null;
};
export type sys_notification_recipients_bool_exp = {
  _and?: ReadonlyArray<sys_notification_recipients_bool_exp> | null;
  _not?: sys_notification_recipients_bool_exp | null;
  _or?: ReadonlyArray<sys_notification_recipients_bool_exp> | null;
  failed_count?: Int_comparison_exp | null;
  last_failed_attempt_at_datetime?: timestamptz_comparison_exp | null;
  notification?: sys_notifications_bool_exp | null;
  notification_id?: Int_comparison_exp | null;
  sent_at_datetime?: timestamptz_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_notifications_bool_exp = {
  _and?: ReadonlyArray<sys_notifications_bool_exp> | null;
  _not?: sys_notifications_bool_exp | null;
  _or?: ReadonlyArray<sys_notifications_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  notification_detail_data?: jsonb_comparison_exp | null;
  notification_group_data?: jsonb_comparison_exp | null;
  notification_id?: Int_comparison_exp | null;
  notification_recipients?: sys_notification_recipients_bool_exp | null;
  notification_recipients_aggregate?: sys_notification_recipients_aggregate_bool_exp | null;
  notification_source_data?: jsonb_comparison_exp | null;
  notification_type?: sys_notification_types_bool_exp | null;
  notification_type_id?: Int_comparison_exp | null;
};
export type sys_notification_recipients_aggregate_bool_exp = {
  count?: sys_notification_recipients_aggregate_bool_exp_count | null;
};
export type sys_notification_recipients_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_notification_recipients_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_notification_recipients_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_notification_types_bool_exp = {
  _and?: ReadonlyArray<sys_notification_types_bool_exp> | null;
  _not?: sys_notification_types_bool_exp | null;
  _or?: ReadonlyArray<sys_notification_types_bool_exp> | null;
  notification_type_id?: Int_comparison_exp | null;
  notification_type_slug?: String_comparison_exp | null;
  notifications?: sys_notifications_bool_exp | null;
  notifications_aggregate?: sys_notifications_aggregate_bool_exp | null;
  priority?: Int_comparison_exp | null;
};
export type sys_notifications_aggregate_bool_exp = {
  count?: sys_notifications_aggregate_bool_exp_count | null;
};
export type sys_notifications_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_notifications_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_notifications_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_stage_transitions_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_stage_transitions_bool_exp> | null;
  _not?: sys_property_batch_stage_transitions_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_stage_transitions_bool_exp> | null;
  action?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transition_id?: Int_comparison_exp | null;
  transition_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_property_batch_stages_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_stages_bool_exp> | null;
  _not?: sys_property_batch_stages_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_stages_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  property_batch_stage_description?: String_comparison_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batch_package_reports_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_package_reports_bool_exp> | null;
  _not?: sys_property_batch_package_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_package_reports_bool_exp> | null;
  is_reviewed?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_package_report_id?: Int_comparison_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  reviewed_datetime?: timestamptz_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_property_batch_package_reports_aggregate_bool_exp = {
  bool_and?: sys_property_batch_package_reports_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_package_reports_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_package_reports_aggregate_bool_exp_count | null;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_package_reports_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_stage_transitions_aggregate_bool_exp = {
  count?: sys_property_batch_stage_transitions_aggregate_bool_exp_count | null;
};
export type sys_property_batch_stage_transitions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_stage_transitions_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_stage_transitions_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_bool_exp = {
  _and?: ReadonlyArray<sakura_user_bool_exp> | null;
  _not?: sakura_user_bool_exp | null;
  _or?: ReadonlyArray<sakura_user_bool_exp> | null;
  attributes?: json_comparison_exp | null;
  auth0_synced?: Boolean_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: jsonb_comparison_exp | null;
  defaultPowerBiReportId?: String_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  email_ci?: citext_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  last_seen?: sakura_users_last_seen_bool_exp | null;
  mongo_id?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sakura_user_permissions?: sakura_user_permission_bool_exp | null;
  sakura_user_permissions_aggregate?: sakura_user_permission_aggregate_bool_exp | null;
  sys_user?: sys_users_bool_exp | null;
};
export type json_comparison_exp = {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: ReadonlyArray<any> | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: ReadonlyArray<any> | null;
};
export type citext_comparison_exp = {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _ilike?: any | null;
  _in?: ReadonlyArray<any> | null;
  _iregex?: any | null;
  _is_null?: boolean | null;
  _like?: any | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nilike?: any | null;
  _nin?: ReadonlyArray<any> | null;
  _niregex?: any | null;
  _nlike?: any | null;
  _nregex?: any | null;
  _nsimilar?: any | null;
  _regex?: any | null;
  _similar?: any | null;
};
export type sakura_users_last_seen_bool_exp = {
  _and?: ReadonlyArray<sakura_users_last_seen_bool_exp> | null;
  _not?: sakura_users_last_seen_bool_exp | null;
  _or?: ReadonlyArray<sakura_users_last_seen_bool_exp> | null;
  attributes?: json_comparison_exp | null;
  auth0_synced?: Boolean_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: jsonb_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  email_ci?: citext_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  last_login_at?: timestamptz_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  last_seen?: timestamptz_comparison_exp | null;
  mongo_id?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
};
export type sakura_organization_bool_exp = {
  _and?: ReadonlyArray<sakura_organization_bool_exp> | null;
  _not?: sakura_organization_bool_exp | null;
  _or?: ReadonlyArray<sakura_organization_bool_exp> | null;
  additional_props?: jsonb_comparison_exp | null;
  api_hasura_role?: String_comparison_exp | null;
  authz_version_id?: Int_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: json_comparison_exp | null;
  description?: String_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  domOwner?: String_comparison_exp | null;
  hasura_access_token?: String_comparison_exp | null;
  hasura_org_name?: String_comparison_exp | null;
  hasura_role?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  image_url?: String_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization_permissions?: sakura_organization_permission_bool_exp | null;
  organization_permissions_aggregate?: sakura_organization_permission_aggregate_bool_exp | null;
  plan_expires_at?: timestamptz_comparison_exp | null;
  plan_type?: String_comparison_exp | null;
  powerbi_api_url?: String_comparison_exp | null;
  powerbi_client_id?: String_comparison_exp | null;
  powerbi_client_secret?: String_comparison_exp | null;
  powerbi_tenant_id?: String_comparison_exp | null;
  publish_on_website?: Boolean_comparison_exp | null;
  salesforce_id?: String_comparison_exp | null;
  slug?: String_comparison_exp | null;
  sys_organization?: sys_organizations_bool_exp | null;
  theme_name?: String_comparison_exp | null;
  website?: String_comparison_exp | null;
  website_image_url?: String_comparison_exp | null;
};
export type sakura_organization_permission_bool_exp = {
  _and?: ReadonlyArray<sakura_organization_permission_bool_exp> | null;
  _not?: sakura_organization_permission_bool_exp | null;
  _or?: ReadonlyArray<sakura_organization_permission_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sys_organization?: sys_organizations_bool_exp | null;
};
export type sakura_organization_permission_aggregate_bool_exp = {
  count?: sakura_organization_permission_aggregate_bool_exp_count | null;
};
export type sakura_organization_permission_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_organization_permission_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_organization_permission_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_permission_bool_exp = {
  _and?: ReadonlyArray<sakura_user_permission_bool_exp> | null;
  _not?: sakura_user_permission_bool_exp | null;
  _or?: ReadonlyArray<sakura_user_permission_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sakura_user_permission_aggregate_bool_exp = {
  count?: sakura_user_permission_aggregate_bool_exp_count | null;
};
export type sakura_user_permission_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_user_permission_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_user_permission_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_users_aggregate_bool_exp = {
  bool_and?: sys_users_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_users_aggregate_bool_exp_bool_or | null;
  count?: sys_users_aggregate_bool_exp_count | null;
};
export type sys_users_aggregate_bool_exp_bool_and = {
  arguments: sys_users_select_column_sys_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_users_aggregate_bool_exp_bool_or = {
  arguments: sys_users_select_column_sys_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_users_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_models_bool_exp = {
  _and?: ReadonlyArray<sys_property_models_bool_exp> | null;
  _not?: sys_property_models_bool_exp | null;
  _or?: ReadonlyArray<sys_property_models_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organizations?: sys_organizations_bool_exp | null;
  organizations_aggregate?: sys_organizations_aggregate_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_model_slug?: String_comparison_exp | null;
  property_models_types?: sys_property_models_types_bool_exp | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organizations_aggregate_bool_exp = {
  count?: sys_organizations_aggregate_bool_exp_count | null;
};
export type sys_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organizations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organizations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_models_types_bool_exp = {
  _and?: ReadonlyArray<sys_property_models_types_bool_exp> | null;
  _not?: sys_property_models_types_bool_exp | null;
  _or?: ReadonlyArray<sys_property_models_types_bool_exp> | null;
  approved_property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  approved_property_batch_stage_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  initial_property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  initial_property_batch_stage_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  property_model?: sys_property_models_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_model_type_id?: Int_comparison_exp | null;
  property_type?: sys_property_types_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_types_bool_exp = {
  _and?: ReadonlyArray<sys_property_types_bool_exp> | null;
  _not?: sys_property_types_bool_exp | null;
  _or?: ReadonlyArray<sys_property_types_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  properties?: sys_properties_bool_exp | null;
  properties_aggregate?: sys_properties_aggregate_bool_exp | null;
  property_models_types?: sys_property_models_types_bool_exp | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  property_type_slug?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_models_types_aggregate_bool_exp = {
  bool_and?: sys_property_models_types_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_models_types_aggregate_bool_exp_bool_or | null;
  count?: sys_property_models_types_aggregate_bool_exp_count | null;
};
export type sys_property_models_types_aggregate_bool_exp_bool_and = {
  arguments: sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_models_types_aggregate_bool_exp_bool_or = {
  arguments: sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_models_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_models_types_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp = {
  bool_and?: sys_property_relationships_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_relationships_aggregate_bool_exp_bool_or | null;
  count?: sys_property_relationships_aggregate_bool_exp_count | null;
};
export type sys_property_relationships_aggregate_bool_exp_bool_and = {
  arguments: sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp_bool_or = {
  arguments: sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_relationships_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_reminders_bool_exp = {
  _and?: ReadonlyArray<sys_reminders_bool_exp> | null;
  _not?: sys_reminders_bool_exp | null;
  _or?: ReadonlyArray<sys_reminders_bool_exp> | null;
  author_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_resolved?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  reminder_id?: Int_comparison_exp | null;
  reminders_reviewed?: sys_reminders_reviewed_bool_exp | null;
  reminders_reviewed_aggregate?: sys_reminders_reviewed_aggregate_bool_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: sys_users_bool_exp | null;
};
export type sys_reminders_reviewed_bool_exp = {
  _and?: ReadonlyArray<sys_reminders_reviewed_bool_exp> | null;
  _not?: sys_reminders_reviewed_bool_exp | null;
  _or?: ReadonlyArray<sys_reminders_reviewed_bool_exp> | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  reminder?: sys_reminders_bool_exp | null;
  reminder_id?: Int_comparison_exp | null;
  reminders_reviewed_id?: Int_comparison_exp | null;
  reviewed_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_reminders_reviewed_aggregate_bool_exp = {
  count?: sys_reminders_reviewed_aggregate_bool_exp_count | null;
};
export type sys_reminders_reviewed_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_reminders_reviewed_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_reminders_reviewed_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp = {
  bool_and?: sys_reminders_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_reminders_aggregate_bool_exp_bool_or | null;
  count?: sys_reminders_aggregate_bool_exp_count | null;
};
export type sys_reminders_aggregate_bool_exp_bool_and = {
  arguments: sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp_bool_or = {
  arguments: sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_reminders_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp = {
  bool_and?: sys_mapping_fields_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_fields_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_fields_aggregate_bool_exp_count | null;
};
export type sys_mapping_fields_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_fields_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organization_account_tree_details_bool_exp = {
  _and?: ReadonlyArray<sys_organization_account_tree_details_bool_exp> | null;
  _not?: sys_organization_account_tree_details_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_account_tree_details_bool_exp> | null;
  account_code_description?: String_comparison_exp | null;
  account_group_code?: String_comparison_exp | null;
  account_tree_id?: Int_comparison_exp | null;
  account_type?: String_comparison_exp | null;
  chart_of_account_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  gl_account_code?: String_comparison_exp | null;
  indent?: Int_comparison_exp | null;
  is_contiguous?: Boolean_comparison_exp | null;
  is_hidden?: Boolean_comparison_exp | null;
  is_negate?: Boolean_comparison_exp | null;
  is_normal_balance?: Boolean_comparison_exp | null;
  nest_level?: Int_comparison_exp | null;
  order_index?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_account_tree?: sys_organization_account_trees_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_chart_of_account?: sys_organization_account_tree_details_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  parent_chart_of_account_id?: Int_comparison_exp | null;
  total_into_code?: String_comparison_exp | null;
  tree_path?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_account_trees_bool_exp = {
  _and?: ReadonlyArray<sys_organization_account_trees_bool_exp> | null;
  _not?: sys_organization_account_trees_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_account_trees_bool_exp> | null;
  account_tree_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_account_tree_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_account_tree_details_aggregate_bool_exp = {
  bool_and?: sys_organization_account_tree_details_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_organization_account_tree_details_aggregate_bool_exp_bool_or | null;
  count?: sys_organization_account_tree_details_aggregate_bool_exp_count | null;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_bool_and = {
  arguments: sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_bool_or = {
  arguments: sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organization_account_tree_details_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_providers_aggregate_bool_exp = {
  bool_and?: sys_providers_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_providers_aggregate_bool_exp_bool_or | null;
  count?: sys_providers_aggregate_bool_exp_count | null;
};
export type sys_providers_aggregate_bool_exp_bool_and = {
  arguments: sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_aggregate_bool_exp_bool_or = {
  arguments: sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_providers_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_aggregate_bool_exp = {
  bool_and?: sakura_user_aggregate_bool_exp_bool_and | null;
  bool_or?: sakura_user_aggregate_bool_exp_bool_or | null;
  count?: sakura_user_aggregate_bool_exp_count | null;
};
export type sakura_user_aggregate_bool_exp_bool_and = {
  arguments: sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sakura_user_aggregate_bool_exp_bool_or = {
  arguments: sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sakura_user_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_user_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submission_types_aggregate_bool_exp = {
  count?: sys_submission_types_aggregate_bool_exp_count | null;
};
export type sys_submission_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_submission_types_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_submission_types_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_dataset_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_dataset_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_dataset_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_dataset_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_package_reports_datasets_aggregate_bool_exp = {
  count?: sys_package_reports_datasets_aggregate_bool_exp_count | null;
};
export type sys_package_reports_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_package_reports_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_package_reports_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_comments_aggregate_bool_exp = {
  count?: sys_comments_aggregate_bool_exp_count | null;
};
export type sys_comments_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_comments_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_comments_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batches_monitoring_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_monitoring_bool_exp> | null;
  _not?: sys_property_batches_monitoring_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_monitoring_bool_exp> | null;
  batch_status?: String_comparison_exp | null;
  batch_validations_failed?: Int_comparison_exp | null;
  batch_validations_total?: Int_comparison_exp | null;
  dataset_validations_failed?: Int_comparison_exp | null;
  dataset_validations_total?: Int_comparison_exp | null;
  datasets_total?: Int_comparison_exp | null;
  datasets_uploaded?: Int_comparison_exp | null;
  datasets_uploaded_ratio?: float8_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  submission_status?: String_comparison_exp | null;
  validations_failed?: Int_comparison_exp | null;
  validations_total?: Int_comparison_exp | null;
};
export type float8_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_property_batches_monitoring_order_by = {
  batch_status?: order_by | null;
  batch_validations_failed?: order_by | null;
  batch_validations_total?: order_by | null;
  dataset_validations_failed?: order_by | null;
  dataset_validations_total?: order_by | null;
  datasets_total?: order_by | null;
  datasets_uploaded?: order_by | null;
  datasets_uploaded_ratio?: order_by | null;
  organization_id?: order_by | null;
  property_batch?: sys_property_batches_order_by | null;
  property_batch_id?: order_by | null;
  submission_status?: order_by | null;
  validations_failed?: order_by | null;
  validations_total?: order_by | null;
};
export type sys_property_batches_order_by = {
  approved_at_datetime?: order_by | null;
  check_regular_user_permission?: order_by | null;
  comments_aggregate?: sys_comments_aggregate_order_by | null;
  created_at_datetime?: order_by | null;
  cycle_time?: order_by | null;
  investment_is_ready_for_bulk_approve?: order_by | null;
  investment_is_ready_for_bulk_submit?: order_by | null;
  investment_is_ready_for_submit?: order_by | null;
  is_all_children_in_allowed_stage_for_user?: order_by | null;
  is_all_children_ready_for_submit?: order_by | null;
  is_approved?: order_by | null;
  is_in_last_review_stage?: order_by | null;
  is_ready_for_bulk_submit?: order_by | null;
  is_ready_for_reject?: order_by | null;
  is_ready_for_submit?: order_by | null;
  is_valid?: order_by | null;
  kpi_datasets_total_count?: order_by | null;
  kpi_datasets_uploaded_count?: order_by | null;
  kpi_package_reports_reviewed_count?: order_by | null;
  kpi_package_reports_total_count?: order_by | null;
  kpi_properties_approved_count?: order_by | null;
  kpi_properties_approved_or_rejected_count?: order_by | null;
  kpi_properties_submitted_count?: order_by | null;
  kpi_validations_failed_count?: order_by | null;
  kpi_validations_warn_count?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  package_reports_aggregate?: sys_package_reports_aggregate_order_by | null;
  properties_flattened_union?: sys_properties_flattened_union_order_by | null;
  property?: sys_properties_order_by | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_order_by | null;
  property_batch_stage?: sys_property_batch_stages_order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  property_batch_validations_aggregate?: sys_property_batch_validations_aggregate_order_by | null;
  property_batches_monitoring?: sys_property_batches_monitoring_order_by | null;
  property_id?: order_by | null;
  reminders_aggregate?: sys_reminders_aggregate_order_by | null;
  role_status?: order_by | null;
  submission?: sys_submissions_order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  users_with_access_to_batch_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  users_with_access_to_batch_count?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_comments_aggregate_order_by = {
  avg?: sys_comments_avg_order_by | null;
  count?: order_by | null;
  max?: sys_comments_max_order_by | null;
  min?: sys_comments_min_order_by | null;
  stddev?: sys_comments_stddev_order_by | null;
  stddev_pop?: sys_comments_stddev_pop_order_by | null;
  stddev_samp?: sys_comments_stddev_samp_order_by | null;
  sum?: sys_comments_sum_order_by | null;
  var_pop?: sys_comments_var_pop_order_by | null;
  var_samp?: sys_comments_var_samp_order_by | null;
  variance?: sys_comments_variance_order_by | null;
};
export type sys_comments_avg_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_max_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  created_at_datetime?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  text?: order_by | null;
};
export type sys_comments_min_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  created_at_datetime?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  text?: order_by | null;
};
export type sys_comments_stddev_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_stddev_pop_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_stddev_samp_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_sum_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_var_pop_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_var_samp_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_comments_variance_order_by = {
  author_id?: order_by | null;
  comment_id?: order_by | null;
  comment_type_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_organizations_order_by = {
  batch_files_aggregate?: sys_batch_files_aggregate_order_by | null;
  created_at_datetime?: order_by | null;
  datasets_aggregate?: sys_datasets_aggregate_order_by | null;
  flatfile_team_name?: order_by | null;
  mapping_fields_aggregate?: sys_mapping_fields_aggregate_order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_model?: sys_property_models_order_by | null;
  property_model_id?: order_by | null;
  providers_aggregate?: sys_providers_aggregate_order_by | null;
  sakura_organization?: sakura_organization_order_by | null;
  sakura_users_aggregate?: sakura_user_aggregate_order_by | null;
  settings?: order_by | null;
  submission_types_aggregate?: sys_submission_types_aggregate_order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_aggregate_order_by = {
  avg?: sys_batch_files_avg_order_by | null;
  count?: order_by | null;
  max?: sys_batch_files_max_order_by | null;
  min?: sys_batch_files_min_order_by | null;
  stddev?: sys_batch_files_stddev_order_by | null;
  stddev_pop?: sys_batch_files_stddev_pop_order_by | null;
  stddev_samp?: sys_batch_files_stddev_samp_order_by | null;
  sum?: sys_batch_files_sum_order_by | null;
  var_pop?: sys_batch_files_var_pop_order_by | null;
  var_samp?: sys_batch_files_var_samp_order_by | null;
  variance?: sys_batch_files_variance_order_by | null;
};
export type sys_batch_files_avg_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  file_name?: order_by | null;
  file_reference_souce?: order_by | null;
  file_source?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_batch_files_stddev_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_sum_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_pop_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_var_samp_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_batch_files_variance_order_by = {
  dataset_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  processed_row_count?: order_by | null;
  row_count?: order_by | null;
  submitter_id?: order_by | null;
};
export type sys_datasets_aggregate_order_by = {
  avg?: sys_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_datasets_max_order_by | null;
  min?: sys_datasets_min_order_by | null;
  stddev?: sys_datasets_stddev_order_by | null;
  stddev_pop?: sys_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_datasets_stddev_samp_order_by | null;
  sum?: sys_datasets_sum_order_by | null;
  var_pop?: sys_datasets_var_pop_order_by | null;
  var_samp?: sys_datasets_var_samp_order_by | null;
  variance?: sys_datasets_variance_order_by | null;
};
export type sys_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_description?: order_by | null;
  dataset_id?: order_by | null;
  dataset_label?: order_by | null;
  dataset_slug?: order_by | null;
  feature_flag?: order_by | null;
  flatfile_embed_id?: order_by | null;
  flatfile_private_key?: order_by | null;
  managed_by?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
  tsa_flatfile_embed_id?: order_by | null;
  tsa_flatfile_private_key?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_mapping_fields_aggregate_order_by = {
  avg?: sys_mapping_fields_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_fields_max_order_by | null;
  min?: sys_mapping_fields_min_order_by | null;
  stddev?: sys_mapping_fields_stddev_order_by | null;
  stddev_pop?: sys_mapping_fields_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_fields_stddev_samp_order_by | null;
  sum?: sys_mapping_fields_sum_order_by | null;
  var_pop?: sys_mapping_fields_var_pop_order_by | null;
  var_samp?: sys_mapping_fields_var_samp_order_by | null;
  variance?: sys_mapping_fields_variance_order_by | null;
};
export type sys_mapping_fields_avg_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_description?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_field_name?: order_by | null;
  organization_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_fields_stddev_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_sum_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_fields_variance_order_by = {
  mapping_field_id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_mapping_sets_aggregate_order_by = {
  avg?: sys_mapping_sets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_mapping_sets_max_order_by | null;
  min?: sys_mapping_sets_min_order_by | null;
  stddev?: sys_mapping_sets_stddev_order_by | null;
  stddev_pop?: sys_mapping_sets_stddev_pop_order_by | null;
  stddev_samp?: sys_mapping_sets_stddev_samp_order_by | null;
  sum?: sys_mapping_sets_sum_order_by | null;
  var_pop?: sys_mapping_sets_var_pop_order_by | null;
  var_samp?: sys_mapping_sets_var_samp_order_by | null;
  variance?: sys_mapping_sets_variance_order_by | null;
};
export type sys_mapping_sets_avg_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_description?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_mapping_sets_stddev_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_sum_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_mapping_sets_variance_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  master_chart_of_account_id?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_organization_account_tree_details_aggregate_order_by = {
  avg?: sys_organization_account_tree_details_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organization_account_tree_details_max_order_by | null;
  min?: sys_organization_account_tree_details_min_order_by | null;
  stddev?: sys_organization_account_tree_details_stddev_order_by | null;
  stddev_pop?: sys_organization_account_tree_details_stddev_pop_order_by | null;
  stddev_samp?: sys_organization_account_tree_details_stddev_samp_order_by | null;
  sum?: sys_organization_account_tree_details_sum_order_by | null;
  var_pop?: sys_organization_account_tree_details_var_pop_order_by | null;
  var_samp?: sys_organization_account_tree_details_var_samp_order_by | null;
  variance?: sys_organization_account_tree_details_variance_order_by | null;
};
export type sys_organization_account_tree_details_avg_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_max_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_min_order_by = {
  account_code_description?: order_by | null;
  account_group_code?: order_by | null;
  account_tree_id?: order_by | null;
  account_type?: order_by | null;
  chart_of_account_id?: order_by | null;
  created_at_datetime?: order_by | null;
  gl_account_code?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
  total_into_code?: order_by | null;
  tree_path?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_stddev_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_sum_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_pop_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_var_samp_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_organization_account_tree_details_variance_order_by = {
  account_tree_id?: order_by | null;
  chart_of_account_id?: order_by | null;
  indent?: order_by | null;
  nest_level?: order_by | null;
  order_index?: order_by | null;
  organization_id?: order_by | null;
  parent_chart_of_account_id?: order_by | null;
};
export type sys_properties_datasets_aggregate_order_by = {
  avg?: sys_properties_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_datasets_max_order_by | null;
  min?: sys_properties_datasets_min_order_by | null;
  stddev?: sys_properties_datasets_stddev_order_by | null;
  stddev_pop?: sys_properties_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_datasets_stddev_samp_order_by | null;
  sum?: sys_properties_datasets_sum_order_by | null;
  var_pop?: sys_properties_datasets_var_pop_order_by | null;
  var_samp?: sys_properties_datasets_var_samp_order_by | null;
  variance?: sys_properties_datasets_variance_order_by | null;
};
export type sys_properties_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_max_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_min_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
  template_id?: order_by | null;
};
export type sys_properties_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_roles_users_aggregate_order_by = {
  avg?: sys_properties_roles_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_roles_users_max_order_by | null;
  min?: sys_properties_roles_users_min_order_by | null;
  stddev?: sys_properties_roles_users_stddev_order_by | null;
  stddev_pop?: sys_properties_roles_users_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_roles_users_stddev_samp_order_by | null;
  sum?: sys_properties_roles_users_sum_order_by | null;
  var_pop?: sys_properties_roles_users_var_pop_order_by | null;
  var_samp?: sys_properties_roles_users_var_samp_order_by | null;
  variance?: sys_properties_roles_users_variance_order_by | null;
};
export type sys_properties_roles_users_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_properties_roles_users_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_role_id?: order_by | null;
  property_role_user_id?: order_by | null;
  provider_id?: order_by | null;
  submission_type_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_datasets_aggregate_order_by = {
  avg?: sys_property_batch_datasets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_datasets_max_order_by | null;
  min?: sys_property_batch_datasets_min_order_by | null;
  stddev?: sys_property_batch_datasets_stddev_order_by | null;
  stddev_pop?: sys_property_batch_datasets_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_datasets_stddev_samp_order_by | null;
  sum?: sys_property_batch_datasets_sum_order_by | null;
  var_pop?: sys_property_batch_datasets_var_pop_order_by | null;
  var_samp?: sys_property_batch_datasets_var_samp_order_by | null;
  variance?: sys_property_batch_datasets_variance_order_by | null;
};
export type sys_property_batch_datasets_avg_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_max_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_min_order_by = {
  approved_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_datetime?: order_by | null;
};
export type sys_property_batch_datasets_stddev_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_stddev_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_sum_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_pop_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_var_samp_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_datasets_variance_order_by = {
  dataset_id?: order_by | null;
  dataset_row_count?: order_by | null;
  dataset_status_id?: order_by | null;
  file_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_dataset_id?: order_by | null;
  property_batch_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_aggregate_order_by = {
  avg?: sys_property_batch_stage_transitions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_stage_transitions_max_order_by | null;
  min?: sys_property_batch_stage_transitions_min_order_by | null;
  stddev?: sys_property_batch_stage_transitions_stddev_order_by | null;
  stddev_pop?: sys_property_batch_stage_transitions_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_stage_transitions_stddev_samp_order_by | null;
  sum?: sys_property_batch_stage_transitions_sum_order_by | null;
  var_pop?: sys_property_batch_stage_transitions_var_pop_order_by | null;
  var_samp?: sys_property_batch_stage_transitions_var_samp_order_by | null;
  variance?: sys_property_batch_stage_transitions_variance_order_by | null;
};
export type sys_property_batch_stage_transitions_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_max_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_min_order_by = {
  action?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  transition_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stage_transitions_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transition_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batches_aggregate_order_by = {
  avg?: sys_property_batches_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batches_max_order_by | null;
  min?: sys_property_batches_min_order_by | null;
  stddev?: sys_property_batches_stddev_order_by | null;
  stddev_pop?: sys_property_batches_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batches_stddev_samp_order_by | null;
  sum?: sys_property_batches_sum_order_by | null;
  var_pop?: sys_property_batches_var_pop_order_by | null;
  var_samp?: sys_property_batches_var_samp_order_by | null;
  variance?: sys_property_batches_variance_order_by | null;
};
export type sys_property_batches_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_max_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_min_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_models_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  is_active?: order_by | null;
  organizations_aggregate?: sys_organizations_aggregate_order_by | null;
  property_model_id?: order_by | null;
  property_model_slug?: order_by | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_aggregate_order_by = {
  avg?: sys_organizations_avg_order_by | null;
  count?: order_by | null;
  max?: sys_organizations_max_order_by | null;
  min?: sys_organizations_min_order_by | null;
  stddev?: sys_organizations_stddev_order_by | null;
  stddev_pop?: sys_organizations_stddev_pop_order_by | null;
  stddev_samp?: sys_organizations_stddev_samp_order_by | null;
  sum?: sys_organizations_sum_order_by | null;
  var_pop?: sys_organizations_var_pop_order_by | null;
  var_samp?: sys_organizations_var_samp_order_by | null;
  variance?: sys_organizations_variance_order_by | null;
};
export type sys_organizations_avg_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_max_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_min_order_by = {
  created_at_datetime?: order_by | null;
  flatfile_team_name?: order_by | null;
  organization_description?: order_by | null;
  organization_id?: order_by | null;
  organization_label?: order_by | null;
  organization_slug?: order_by | null;
  property_model_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_organizations_stddev_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_sum_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_pop_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_var_samp_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_organizations_variance_order_by = {
  organization_id?: order_by | null;
  property_model_id?: order_by | null;
};
export type sys_property_models_types_aggregate_order_by = {
  avg?: sys_property_models_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_models_types_max_order_by | null;
  min?: sys_property_models_types_min_order_by | null;
  stddev?: sys_property_models_types_stddev_order_by | null;
  stddev_pop?: sys_property_models_types_stddev_pop_order_by | null;
  stddev_samp?: sys_property_models_types_stddev_samp_order_by | null;
  sum?: sys_property_models_types_sum_order_by | null;
  var_pop?: sys_property_models_types_var_pop_order_by | null;
  var_samp?: sys_property_models_types_var_samp_order_by | null;
  variance?: sys_property_models_types_variance_order_by | null;
};
export type sys_property_models_types_avg_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_max_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_min_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_models_types_stddev_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_stddev_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_sum_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_pop_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_var_samp_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_property_models_types_variance_order_by = {
  approved_property_batch_stage_id?: order_by | null;
  initial_property_batch_stage_id?: order_by | null;
  property_model_id?: order_by | null;
  property_model_type_id?: order_by | null;
  property_type_id?: order_by | null;
};
export type sys_providers_aggregate_order_by = {
  avg?: sys_providers_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_max_order_by | null;
  min?: sys_providers_min_order_by | null;
  stddev?: sys_providers_stddev_order_by | null;
  stddev_pop?: sys_providers_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_stddev_samp_order_by | null;
  sum?: sys_providers_sum_order_by | null;
  var_pop?: sys_providers_var_pop_order_by | null;
  var_samp?: sys_providers_var_samp_order_by | null;
  variance?: sys_providers_variance_order_by | null;
};
export type sys_providers_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type_id?: order_by | null;
  related_data_updated_at?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sys_providers_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  provider_type_id?: order_by | null;
};
export type sakura_organization_order_by = {
  additional_props?: order_by | null;
  api_hasura_role?: order_by | null;
  authz_version_id?: order_by | null;
  create_time?: order_by | null;
  dashboards?: order_by | null;
  description?: order_by | null;
  disabled?: order_by | null;
  domOwner?: order_by | null;
  hasura_access_token?: order_by | null;
  hasura_org_name?: order_by | null;
  hasura_role?: order_by | null;
  id?: order_by | null;
  image_url?: order_by | null;
  last_modified?: order_by | null;
  name?: order_by | null;
  organization_permissions_aggregate?: sakura_organization_permission_aggregate_order_by | null;
  plan_expires_at?: order_by | null;
  plan_type?: order_by | null;
  powerbi_api_url?: order_by | null;
  powerbi_client_id?: order_by | null;
  powerbi_client_secret?: order_by | null;
  powerbi_tenant_id?: order_by | null;
  publish_on_website?: order_by | null;
  salesforce_id?: order_by | null;
  slug?: order_by | null;
  sys_organization?: sys_organizations_order_by | null;
  theme_name?: order_by | null;
  website?: order_by | null;
  website_image_url?: order_by | null;
};
export type sakura_organization_permission_aggregate_order_by = {
  avg?: sakura_organization_permission_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_organization_permission_max_order_by | null;
  min?: sakura_organization_permission_min_order_by | null;
  stddev?: sakura_organization_permission_stddev_order_by | null;
  stddev_pop?: sakura_organization_permission_stddev_pop_order_by | null;
  stddev_samp?: sakura_organization_permission_stddev_samp_order_by | null;
  sum?: sakura_organization_permission_sum_order_by | null;
  var_pop?: sakura_organization_permission_var_pop_order_by | null;
  var_samp?: sakura_organization_permission_var_samp_order_by | null;
  variance?: sakura_organization_permission_variance_order_by | null;
};
export type sakura_organization_permission_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_max_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_min_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_organization_permission_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_aggregate_order_by = {
  avg?: sakura_user_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_user_max_order_by | null;
  min?: sakura_user_min_order_by | null;
  stddev?: sakura_user_stddev_order_by | null;
  stddev_pop?: sakura_user_stddev_pop_order_by | null;
  stddev_samp?: sakura_user_stddev_samp_order_by | null;
  sum?: sakura_user_sum_order_by | null;
  var_pop?: sakura_user_var_pop_order_by | null;
  var_samp?: sakura_user_var_samp_order_by | null;
  variance?: sakura_user_variance_order_by | null;
};
export type sakura_user_avg_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_max_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_min_order_by = {
  create_time?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_stddev_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_stddev_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_sum_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_pop_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_var_samp_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sakura_user_variance_order_by = {
  id?: order_by | null;
  organization_id?: order_by | null;
};
export type sys_submission_types_aggregate_order_by = {
  avg?: sys_submission_types_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submission_types_max_order_by | null;
  min?: sys_submission_types_min_order_by | null;
  stddev?: sys_submission_types_stddev_order_by | null;
  stddev_pop?: sys_submission_types_stddev_pop_order_by | null;
  stddev_samp?: sys_submission_types_stddev_samp_order_by | null;
  sum?: sys_submission_types_sum_order_by | null;
  var_pop?: sys_submission_types_var_pop_order_by | null;
  var_samp?: sys_submission_types_var_samp_order_by | null;
  variance?: sys_submission_types_variance_order_by | null;
};
export type sys_submission_types_avg_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_max_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_min_order_by = {
  created_at_datetime?: order_by | null;
  feature_flag?: order_by | null;
  organization_id?: order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_submission_types_stddev_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_stddev_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_sum_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_pop_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_var_samp_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submission_types_variance_order_by = {
  organization_id?: order_by | null;
  submission_type_id?: order_by | null;
};
export type sys_submissions_aggregate_order_by = {
  avg?: sys_submissions_avg_order_by | null;
  count?: order_by | null;
  max?: sys_submissions_max_order_by | null;
  min?: sys_submissions_min_order_by | null;
  stddev?: sys_submissions_stddev_order_by | null;
  stddev_pop?: sys_submissions_stddev_pop_order_by | null;
  stddev_samp?: sys_submissions_stddev_samp_order_by | null;
  sum?: sys_submissions_sum_order_by | null;
  var_pop?: sys_submissions_var_pop_order_by | null;
  var_samp?: sys_submissions_var_samp_order_by | null;
  variance?: sys_submissions_variance_order_by | null;
};
export type sys_submissions_avg_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_max_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_min_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_stddev_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_sum_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_pop_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_var_samp_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_submissions_variance_order_by = {
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  provider_id?: order_by | null;
  submission_id?: order_by | null;
  submission_type_id?: order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_package_reports_aggregate_order_by = {
  avg?: sys_package_reports_avg_order_by | null;
  count?: order_by | null;
  max?: sys_package_reports_max_order_by | null;
  min?: sys_package_reports_min_order_by | null;
  stddev?: sys_package_reports_stddev_order_by | null;
  stddev_pop?: sys_package_reports_stddev_pop_order_by | null;
  stddev_samp?: sys_package_reports_stddev_samp_order_by | null;
  sum?: sys_package_reports_sum_order_by | null;
  var_pop?: sys_package_reports_var_pop_order_by | null;
  var_samp?: sys_package_reports_var_samp_order_by | null;
  variance?: sys_package_reports_variance_order_by | null;
};
export type sys_package_reports_avg_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  package_report_description?: order_by | null;
  package_report_id?: order_by | null;
  package_report_label?: order_by | null;
  package_report_slug?: order_by | null;
  report_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_package_reports_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  package_report_description?: order_by | null;
  package_report_id?: order_by | null;
  package_report_label?: order_by | null;
  package_report_slug?: order_by | null;
  report_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_package_reports_stddev_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_stddev_pop_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_stddev_samp_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_sum_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_var_pop_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_var_samp_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_package_reports_variance_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  report_id?: order_by | null;
};
export type sys_properties_flattened_union_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  custom_attributes?: order_by | null;
  datasets_count?: order_by | null;
  datasets_property_id?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  investment_properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_order_by | null;
  is_active?: order_by | null;
  is_ready_for_submission?: order_by | null;
  is_sold?: order_by | null;
  mappings_count?: order_by | null;
  mappings_property_id?: order_by | null;
  organization_id?: order_by | null;
  parent_property?: sys_properties_order_by | null;
  parent_property_id?: order_by | null;
  parent_property_type_id?: order_by | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_order_by | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_order_by | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_order_by | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_mapping?: order_by | null;
  property_model_id?: order_by | null;
  property_models?: sys_property_models_order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider?: sys_providers_order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
  users_count?: order_by | null;
  users_property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_aggregate_order_by = {
  avg?: sys_properties_dataset_validation_rules_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_dataset_validation_rules_max_order_by | null;
  min?: sys_properties_dataset_validation_rules_min_order_by | null;
  stddev?: sys_properties_dataset_validation_rules_stddev_order_by | null;
  stddev_pop?: sys_properties_dataset_validation_rules_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_dataset_validation_rules_stddev_samp_order_by | null;
  sum?: sys_properties_dataset_validation_rules_sum_order_by | null;
  var_pop?: sys_properties_dataset_validation_rules_var_pop_order_by | null;
  var_samp?: sys_properties_dataset_validation_rules_var_samp_order_by | null;
  variance?: sys_properties_dataset_validation_rules_variance_order_by | null;
};
export type sys_properties_dataset_validation_rules_avg_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_max_order_by = {
  created_at_datetime?: order_by | null;
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_properties_dataset_validation_rules_min_order_by = {
  created_at_datetime?: order_by | null;
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_properties_dataset_validation_rules_stddev_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_stddev_pop_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_stddev_samp_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_sum_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_var_pop_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_var_samp_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_dataset_validation_rules_variance_order_by = {
  dataset_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_dataset_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  custom_attributes?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  from_property_relationships_aggregate?: sys_property_relationships_aggregate_order_by | null;
  fund?: order_by | null;
  is_active?: order_by | null;
  is_sold?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_order_by | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_order_by | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_order_by | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type?: sys_property_types_order_by | null;
  property_type_id?: order_by | null;
  provider?: sys_providers_order_by | null;
  provider_id?: order_by | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_order_by | null;
  reminders_aggregate?: sys_reminders_aggregate_order_by | null;
  sold_date?: order_by | null;
  to_property_relationships_aggregate?: sys_property_relationships_aggregate_order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_relationships_aggregate_order_by = {
  avg?: sys_property_relationships_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_relationships_max_order_by | null;
  min?: sys_property_relationships_min_order_by | null;
  stddev?: sys_property_relationships_stddev_order_by | null;
  stddev_pop?: sys_property_relationships_stddev_pop_order_by | null;
  stddev_samp?: sys_property_relationships_stddev_samp_order_by | null;
  sum?: sys_property_relationships_sum_order_by | null;
  var_pop?: sys_property_relationships_var_pop_order_by | null;
  var_samp?: sys_property_relationships_var_samp_order_by | null;
  variance?: sys_property_relationships_variance_order_by | null;
};
export type sys_property_relationships_avg_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_max_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_relationships_min_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_relationships_stddev_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_sum_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_property_relationships_variance_order_by = {
  organization_id?: order_by | null;
  property_id_from?: order_by | null;
  property_id_to?: order_by | null;
  property_relationship_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_aggregate_order_by = {
  avg?: sys_properties_batch_validation_rules_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_batch_validation_rules_max_order_by | null;
  min?: sys_properties_batch_validation_rules_min_order_by | null;
  stddev?: sys_properties_batch_validation_rules_stddev_order_by | null;
  stddev_pop?: sys_properties_batch_validation_rules_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_batch_validation_rules_stddev_samp_order_by | null;
  sum?: sys_properties_batch_validation_rules_sum_order_by | null;
  var_pop?: sys_properties_batch_validation_rules_var_pop_order_by | null;
  var_samp?: sys_properties_batch_validation_rules_var_samp_order_by | null;
  variance?: sys_properties_batch_validation_rules_variance_order_by | null;
};
export type sys_properties_batch_validation_rules_avg_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_max_order_by = {
  batch_validation_rule_id?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_properties_batch_validation_rules_min_order_by = {
  batch_validation_rule_id?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_properties_batch_validation_rules_stddev_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_stddev_pop_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_stddev_samp_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_sum_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_var_pop_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_var_samp_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_batch_validation_rules_variance_order_by = {
  batch_validation_rule_id?: order_by | null;
  organization_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  property_id?: order_by | null;
};
export type sys_properties_mapping_sets_aggregate_order_by = {
  avg?: sys_properties_mapping_sets_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_mapping_sets_max_order_by | null;
  min?: sys_properties_mapping_sets_min_order_by | null;
  stddev?: sys_properties_mapping_sets_stddev_order_by | null;
  stddev_pop?: sys_properties_mapping_sets_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_mapping_sets_stddev_samp_order_by | null;
  sum?: sys_properties_mapping_sets_sum_order_by | null;
  var_pop?: sys_properties_mapping_sets_var_pop_order_by | null;
  var_samp?: sys_properties_mapping_sets_var_samp_order_by | null;
  variance?: sys_properties_mapping_sets_variance_order_by | null;
};
export type sys_properties_mapping_sets_avg_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_max_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_mapping_sets_min_order_by = {
  created_at_datetime?: order_by | null;
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_stddev_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_sum_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_var_pop_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_var_samp_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_mapping_sets_variance_order_by = {
  mapping_field_id?: order_by | null;
  mapping_set_id?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_mapping_set_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_property_types_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  is_active?: order_by | null;
  properties_aggregate?: sys_properties_aggregate_order_by | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_order_by | null;
  property_type_id?: order_by | null;
  property_type_slug?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_aggregate_order_by = {
  avg?: sys_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_properties_max_order_by | null;
  min?: sys_properties_min_order_by | null;
  stddev?: sys_properties_stddev_order_by | null;
  stddev_pop?: sys_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_properties_stddev_samp_order_by | null;
  sum?: sys_properties_sum_order_by | null;
  var_pop?: sys_properties_var_pop_order_by | null;
  var_samp?: sys_properties_var_samp_order_by | null;
  variance?: sys_properties_variance_order_by | null;
};
export type sys_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_max_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_min_order_by = {
  address?: order_by | null;
  created_at_datetime?: order_by | null;
  entity_id?: order_by | null;
  entity_name?: order_by | null;
  fund?: order_by | null;
  organization_id?: order_by | null;
  property_code?: order_by | null;
  property_id?: order_by | null;
  property_name?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
  sold_date?: order_by | null;
  type?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  property_type_id?: order_by | null;
  provider_id?: order_by | null;
};
export type sys_providers_order_by = {
  created_at_datetime?: order_by | null;
  ingestion_config?: order_by | null;
  is_active?: order_by | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_aggregate?: sys_properties_aggregate_order_by | null;
  provider_id?: order_by | null;
  provider_name?: order_by | null;
  provider_type?: sys_provider_types_order_by | null;
  provider_type_id?: order_by | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_order_by | null;
  related_data_updated_at?: order_by | null;
  report_config?: order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
  users_aggregate?: sys_users_aggregate_order_by | null;
};
export type sys_provider_types_order_by = {
  id?: order_by | null;
  name?: order_by | null;
};
export type sys_providers_properties_aggregate_order_by = {
  avg?: sys_providers_properties_avg_order_by | null;
  count?: order_by | null;
  max?: sys_providers_properties_max_order_by | null;
  min?: sys_providers_properties_min_order_by | null;
  stddev?: sys_providers_properties_stddev_order_by | null;
  stddev_pop?: sys_providers_properties_stddev_pop_order_by | null;
  stddev_samp?: sys_providers_properties_stddev_samp_order_by | null;
  sum?: sys_providers_properties_sum_order_by | null;
  var_pop?: sys_providers_properties_var_pop_order_by | null;
  var_samp?: sys_providers_properties_var_samp_order_by | null;
  variance?: sys_providers_properties_variance_order_by | null;
};
export type sys_providers_properties_avg_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_max_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_min_order_by = {
  created_at_datetime?: order_by | null;
  inactivated_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_providers_properties_stddev_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_sum_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_pop_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_var_samp_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_providers_properties_variance_order_by = {
  organization_id?: order_by | null;
  property_id?: order_by | null;
  provider_id?: order_by | null;
  provider_property_id?: order_by | null;
};
export type sys_users_aggregate_order_by = {
  avg?: sys_users_avg_order_by | null;
  count?: order_by | null;
  max?: sys_users_max_order_by | null;
  min?: sys_users_min_order_by | null;
  stddev?: sys_users_stddev_order_by | null;
  stddev_pop?: sys_users_stddev_pop_order_by | null;
  stddev_samp?: sys_users_stddev_samp_order_by | null;
  sum?: sys_users_sum_order_by | null;
  var_pop?: sys_users_var_pop_order_by | null;
  var_samp?: sys_users_var_samp_order_by | null;
  variance?: sys_users_variance_order_by | null;
};
export type sys_users_avg_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_stddev_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_sum_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_pop_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_var_samp_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_users_variance_order_by = {
  organization_id?: order_by | null;
  provider_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_reminders_aggregate_order_by = {
  avg?: sys_reminders_avg_order_by | null;
  count?: order_by | null;
  max?: sys_reminders_max_order_by | null;
  min?: sys_reminders_min_order_by | null;
  stddev?: sys_reminders_stddev_order_by | null;
  stddev_pop?: sys_reminders_stddev_pop_order_by | null;
  stddev_samp?: sys_reminders_stddev_samp_order_by | null;
  sum?: sys_reminders_sum_order_by | null;
  var_pop?: sys_reminders_var_pop_order_by | null;
  var_samp?: sys_reminders_var_samp_order_by | null;
  variance?: sys_reminders_variance_order_by | null;
};
export type sys_reminders_avg_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_max_order_by = {
  author_id?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
  text?: order_by | null;
};
export type sys_reminders_min_order_by = {
  author_id?: order_by | null;
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
  text?: order_by | null;
};
export type sys_reminders_stddev_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_stddev_pop_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_stddev_samp_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_sum_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_var_pop_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_var_samp_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_reminders_variance_order_by = {
  author_id?: order_by | null;
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_id?: order_by | null;
  reminder_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batch_package_reports_aggregate_order_by = {
  avg?: sys_property_batch_package_reports_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_package_reports_max_order_by | null;
  min?: sys_property_batch_package_reports_min_order_by | null;
  stddev?: sys_property_batch_package_reports_stddev_order_by | null;
  stddev_pop?: sys_property_batch_package_reports_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_package_reports_stddev_samp_order_by | null;
  sum?: sys_property_batch_package_reports_sum_order_by | null;
  var_pop?: sys_property_batch_package_reports_var_pop_order_by | null;
  var_samp?: sys_property_batch_package_reports_var_samp_order_by | null;
  variance?: sys_property_batch_package_reports_variance_order_by | null;
};
export type sys_property_batch_package_reports_avg_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_max_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  reviewed_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_min_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  reviewed_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_stddev_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_stddev_pop_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_stddev_samp_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_sum_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_var_pop_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_var_samp_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_package_reports_variance_order_by = {
  organization_id?: order_by | null;
  package_report_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_package_report_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_property_batch_stages_order_by = {
  created_at_datetime?: order_by | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_order_by | null;
  property_batch_stage_description?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_property_batch_validations_aggregate_order_by = {
  avg?: sys_property_batch_validations_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batch_validations_max_order_by | null;
  min?: sys_property_batch_validations_min_order_by | null;
  stddev?: sys_property_batch_validations_stddev_order_by | null;
  stddev_pop?: sys_property_batch_validations_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batch_validations_stddev_samp_order_by | null;
  sum?: sys_property_batch_validations_sum_order_by | null;
  var_pop?: sys_property_batch_validations_var_pop_order_by | null;
  var_samp?: sys_property_batch_validations_var_samp_order_by | null;
  variance?: sys_property_batch_validations_variance_order_by | null;
};
export type sys_property_batch_validations_avg_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_max_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_property_batch_validations_min_order_by = {
  created_at_datetime?: order_by | null;
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validation_mode?: order_by | null;
};
export type sys_property_batch_validations_stddev_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_stddev_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_stddev_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_sum_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_var_pop_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_var_samp_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_property_batch_validations_variance_order_by = {
  organization_id?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_validation_id?: order_by | null;
  property_batch_validation_rule_id?: order_by | null;
};
export type sys_submissions_order_by = {
  closed_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  due_date?: order_by | null;
  fiscal_year_flag?: order_by | null;
  is_closed?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  owner_id?: order_by | null;
  property_batches_aggregate?: sys_property_batches_aggregate_order_by | null;
  property_batches_approved_aggregate?: sys_property_batches_approved_aggregate_order_by | null;
  provider?: sys_providers_order_by | null;
  provider_id?: order_by | null;
  reporting_period?: order_by | null;
  reporting_period_end_date?: order_by | null;
  reporting_period_start_date?: order_by | null;
  submission_id?: order_by | null;
  submission_name?: order_by | null;
  submission_status_description?: order_by | null;
  submission_type?: sys_submission_types_order_by | null;
  submission_type_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  user?: sys_users_order_by | null;
  workflow_mode_id?: order_by | null;
};
export type sys_property_batches_approved_aggregate_order_by = {
  avg?: sys_property_batches_approved_avg_order_by | null;
  count?: order_by | null;
  max?: sys_property_batches_approved_max_order_by | null;
  min?: sys_property_batches_approved_min_order_by | null;
  stddev?: sys_property_batches_approved_stddev_order_by | null;
  stddev_pop?: sys_property_batches_approved_stddev_pop_order_by | null;
  stddev_samp?: sys_property_batches_approved_stddev_samp_order_by | null;
  sum?: sys_property_batches_approved_sum_order_by | null;
  var_pop?: sys_property_batches_approved_var_pop_order_by | null;
  var_samp?: sys_property_batches_approved_var_samp_order_by | null;
  variance?: sys_property_batches_approved_variance_order_by | null;
};
export type sys_property_batches_approved_avg_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_max_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_approved_min_order_by = {
  approved_at_datetime?: order_by | null;
  created_at_datetime?: order_by | null;
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
  updated_at_datetime?: order_by | null;
  validated_at_datetime?: order_by | null;
};
export type sys_property_batches_approved_stddev_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_stddev_pop_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_stddev_samp_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_sum_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_var_pop_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_var_samp_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_property_batches_approved_variance_order_by = {
  property_batch_id?: order_by | null;
  property_batch_stage_id?: order_by | null;
  property_id?: order_by | null;
  submission_id?: order_by | null;
};
export type sys_submission_types_order_by = {
  created_at_datetime?: order_by | null;
  datasets_aggregate?: sys_datasets_aggregate_order_by | null;
  feature_flag?: order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  submission_type_description?: order_by | null;
  submission_type_id?: order_by | null;
  submission_type_label?: order_by | null;
  submission_type_slug?: order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
};
export type sys_users_order_by = {
  batch_files_aggregate?: sys_batch_files_aggregate_order_by | null;
  created_at_datetime?: order_by | null;
  is_active?: order_by | null;
  notification_recipients_aggregate?: sys_notification_recipients_aggregate_order_by | null;
  organization?: sys_organizations_order_by | null;
  organization_id?: order_by | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_order_by | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_order_by | null;
  provider?: sys_providers_order_by | null;
  provider_id?: order_by | null;
  sakura_user?: sakura_user_order_by | null;
  submissions_aggregate?: sys_submissions_aggregate_order_by | null;
  updated_at_datetime?: order_by | null;
  user?: sakura_user_order_by | null;
  user_id?: order_by | null;
  users_last_seen?: sakura_users_last_seen_order_by | null;
};
export type sys_notification_recipients_aggregate_order_by = {
  avg?: sys_notification_recipients_avg_order_by | null;
  count?: order_by | null;
  max?: sys_notification_recipients_max_order_by | null;
  min?: sys_notification_recipients_min_order_by | null;
  stddev?: sys_notification_recipients_stddev_order_by | null;
  stddev_pop?: sys_notification_recipients_stddev_pop_order_by | null;
  stddev_samp?: sys_notification_recipients_stddev_samp_order_by | null;
  sum?: sys_notification_recipients_sum_order_by | null;
  var_pop?: sys_notification_recipients_var_pop_order_by | null;
  var_samp?: sys_notification_recipients_var_samp_order_by | null;
  variance?: sys_notification_recipients_variance_order_by | null;
};
export type sys_notification_recipients_avg_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_max_order_by = {
  failed_count?: order_by | null;
  last_failed_attempt_at_datetime?: order_by | null;
  notification_id?: order_by | null;
  sent_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_min_order_by = {
  failed_count?: order_by | null;
  last_failed_attempt_at_datetime?: order_by | null;
  notification_id?: order_by | null;
  sent_at_datetime?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_stddev_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_stddev_pop_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_stddev_samp_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_sum_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_var_pop_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_var_samp_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sys_notification_recipients_variance_order_by = {
  failed_count?: order_by | null;
  notification_id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_order_by = {
  attributes?: order_by | null;
  auth0_synced?: order_by | null;
  create_time?: order_by | null;
  dashboards?: order_by | null;
  defaultPowerBiReportId?: order_by | null;
  disabled?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  last_seen?: sakura_users_last_seen_order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
  sakura_organization?: sakura_organization_order_by | null;
  sakura_user_permissions_aggregate?: sakura_user_permission_aggregate_order_by | null;
  sys_user?: sys_users_order_by | null;
};
export type sakura_users_last_seen_order_by = {
  attributes?: order_by | null;
  auth0_synced?: order_by | null;
  create_time?: order_by | null;
  dashboards?: order_by | null;
  disabled?: order_by | null;
  email?: order_by | null;
  email_ci?: order_by | null;
  first_name?: order_by | null;
  id?: order_by | null;
  last_login_at?: order_by | null;
  last_modified?: order_by | null;
  last_name?: order_by | null;
  last_seen?: order_by | null;
  mongo_id?: order_by | null;
  organization_id?: order_by | null;
  role?: order_by | null;
};
export type sakura_user_permission_aggregate_order_by = {
  avg?: sakura_user_permission_avg_order_by | null;
  count?: order_by | null;
  max?: sakura_user_permission_max_order_by | null;
  min?: sakura_user_permission_min_order_by | null;
  stddev?: sakura_user_permission_stddev_order_by | null;
  stddev_pop?: sakura_user_permission_stddev_pop_order_by | null;
  stddev_samp?: sakura_user_permission_stddev_samp_order_by | null;
  sum?: sakura_user_permission_sum_order_by | null;
  var_pop?: sakura_user_permission_var_pop_order_by | null;
  var_samp?: sakura_user_permission_var_samp_order_by | null;
  variance?: sakura_user_permission_variance_order_by | null;
};
export type sakura_user_permission_avg_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_max_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_min_order_by = {
  id?: order_by | null;
  name?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_stddev_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_stddev_pop_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_stddev_samp_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_sum_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_var_pop_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_var_samp_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type sakura_user_permission_variance_order_by = {
  id?: order_by | null;
  user_id?: order_by | null;
};
export type recoilPropertyBatchesQuery$variables = {
  limit?: number | null;
  offset?: number | null;
  order_by?: ReadonlyArray<sys_property_batches_monitoring_order_by> | null;
  where_clause: sys_property_batches_bool_exp;
};
export type recoilPropertyBatchesQuery$data = {
  readonly sys_property_batches_monitoring: ReadonlyArray<{
    readonly batch_status: string | null;
    readonly datasets_total: number | null;
    readonly datasets_uploaded: number | null;
    readonly datasets_uploaded_ratio: number | null;
    readonly property_batch: {
      readonly is_valid: boolean | null;
      readonly last_stage: ReadonlyArray<{
        readonly property_batch_stage_id: number;
      }>;
      readonly properties_flattened_union: {
        readonly entity_id: string;
        readonly property_mapping: string | null;
      } | null;
      readonly property: {
        readonly address: string | null;
        readonly entity_id: string;
        readonly fund: string | null;
        readonly property_code: string;
        readonly property_id: number;
        readonly property_name: string | null;
      };
      readonly property_batch_id: number;
      readonly property_batch_stage_id: number;
      readonly reviewedPackageReports: {
        readonly aggregate: {
          readonly count: number;
        } | null;
      };
      readonly submission: {
        readonly due_date: string;
        readonly provider: {
          readonly provider_id: number;
          readonly provider_name: string;
        };
        readonly reporting_period: string;
        readonly reporting_period_end_date: string;
        readonly reporting_period_start_date: string;
        readonly submission_id: number;
        readonly submission_name: string;
        readonly submission_status_description: string | null;
        readonly submission_type: {
          readonly submission_type_label: string;
        };
      };
      readonly updated_at_datetime: string | null;
      readonly users_with_access_to_batch_count: number | null;
    };
    readonly property_batch_id: number;
    readonly submission_status: string | null;
    readonly validations_failed: number | null;
    readonly validations_total: number | null;
  }>;
};
export type recoilPropertyBatchesQuery = {
  response: recoilPropertyBatchesQuery$data;
  variables: recoilPropertyBatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": 0,
  "kind": "LocalArgument",
  "name": "offset"
},
v2 = {
  "defaultValue": {},
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where_clause"
},
v4 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "property_batch",
        "variableName": "where_clause"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_batch_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datasets_total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datasets_uploaded",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datasets_uploaded_ratio",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validations_total",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validations_failed",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submission_status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "batch_status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_batch_stage_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entity_id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_property_batches",
  "kind": "LinkedField",
  "name": "property_batch",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_valid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated_at_datetime",
      "storageKey": null
    },
    (v5/*: any*/),
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "users_with_access_to_batch_count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "sys_properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "property_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "property_code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "property_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fund",
          "storageKey": null
        },
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "sys_properties_flattened_union",
      "kind": "LinkedField",
      "name": "properties_flattened_union",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "property_mapping",
          "storageKey": null
        },
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "last_stage",
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "transition_datetime": "desc"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "property_batch_stage_id": {
              "_neq": 40
            }
          }
        }
      ],
      "concreteType": "sys_property_batch_stage_transitions",
      "kind": "LinkedField",
      "name": "property_batch_stage_transitions",
      "plural": true,
      "selections": [
        (v13/*: any*/)
      ],
      "storageKey": "property_batch_stage_transitions(limit:1,order_by:{\"transition_datetime\":\"desc\"},where:{\"property_batch_stage_id\":{\"_neq\":40}})"
    },
    {
      "alias": "reviewedPackageReports",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "is_reviewed": {
              "_eq": true
            },
            "property_batch": {
              "property_batch_stage_id": {
                "_eq": 20
              }
            }
          }
        }
      ],
      "concreteType": "sys_property_batch_package_reports_aggregate",
      "kind": "LinkedField",
      "name": "property_batch_package_reports_aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "sys_property_batch_package_reports_aggregate_fields",
          "kind": "LinkedField",
          "name": "aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "property_batch_package_reports_aggregate(where:{\"is_reviewed\":{\"_eq\":true},\"property_batch\":{\"property_batch_stage_id\":{\"_eq\":20}}})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "sys_submissions",
      "kind": "LinkedField",
      "name": "submission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submission_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "sys_submission_types",
          "kind": "LinkedField",
          "name": "submission_type",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submission_type_label",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "sys_providers",
          "kind": "LinkedField",
          "name": "provider",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "provider_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "provider_name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "due_date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reporting_period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reporting_period_start_date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reporting_period_end_date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submission_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submission_status_description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilPropertyBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "sys_property_batches_monitoring",
        "kind": "LinkedField",
        "name": "sys_property_batches_monitoring",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v5/*: any*/),
            "action": "THROW",
            "path": "sys_property_batches_monitoring.property_batch_id"
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "RequiredField",
            "field": (v15/*: any*/),
            "action": "THROW",
            "path": "sys_property_batches_monitoring.property_batch"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilPropertyBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "sys_property_batches_monitoring",
        "kind": "LinkedField",
        "name": "sys_property_batches_monitoring",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7e76f8c45eb9e12a278cbce0f3a68ad",
    "id": null,
    "metadata": {},
    "name": "recoilPropertyBatchesQuery",
    "operationKind": "query",
    "text": "query recoilPropertyBatchesQuery(\n  $where_clause: sys_property_batches_bool_exp!\n  $limit: Int = 10\n  $offset: Int = 0\n  $order_by: [sys_property_batches_monitoring_order_by!] = {}\n) {\n  sys_property_batches_monitoring(where: {property_batch: $where_clause}, limit: $limit, offset: $offset, order_by: $order_by) {\n    property_batch_id\n    datasets_total\n    datasets_uploaded\n    datasets_uploaded_ratio\n    validations_total\n    validations_failed\n    submission_status\n    batch_status\n    property_batch {\n      is_valid\n      updated_at_datetime\n      property_batch_id\n      property_batch_stage_id\n      users_with_access_to_batch_count\n      property {\n        property_id\n        address\n        property_code\n        property_name\n        fund\n        entity_id\n      }\n      properties_flattened_union {\n        property_mapping\n        entity_id\n      }\n      last_stage: property_batch_stage_transitions(limit: 1, order_by: {transition_datetime: desc}, where: {property_batch_stage_id: {_neq: 40}}) {\n        property_batch_stage_id\n      }\n      reviewedPackageReports: property_batch_package_reports_aggregate(where: {is_reviewed: {_eq: true}, property_batch: {property_batch_stage_id: {_eq: 20}}}) {\n        aggregate {\n          count\n        }\n      }\n      submission {\n        submission_id\n        submission_type {\n          submission_type_label\n        }\n        provider {\n          provider_id\n          provider_name\n        }\n        due_date\n        reporting_period\n        reporting_period_start_date\n        reporting_period_end_date\n        submission_name\n        submission_status_description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ee94e15260d5fa0c35b862c4c0d3145";

export default node;
