import React, { useEffect, useMemo } from 'react';
import {
  MenuItem,
  Select,
  Box,
  InputBase,
  ListSubheader,
  styled,
  CheckIcon,
  Typography,
  Tooltip,
  InfoIcon,
} from '@cherre-frontend/ui';
import { SvgIconProps } from '@material-ui/core';
import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    color: theme.palette.grey[700],
    paddingLeft: 5,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: '8px',
  paddingRight: '32px',
  '&.Mui-selected': {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  paddingLeft: '8px',
  paddingBottom: '8px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 'unset',
  color: theme.palette.grey[500],
}));

const StyledMenuItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const StyledCheckIcon = styled(CheckIcon, {
  shouldForwardProp: (propName) => propName !== 'currentValue',
})<{ isVisible: boolean } & SvgIconProps>`
  height: 20px;
  width: 20px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  height: '14px',
  width: '14px',
  color: theme.palette.grey[400],
}));

const getUserTypeOptions = (
  level: number,
  approverPlusLevel: number | undefined
) => {
  const isApproverPlusOptionDisabled =
    typeof approverPlusLevel === 'number' && level < approverPlusLevel;
  return [
    {
      property_role_id: [
        PropertyRole.REVIEWER_1.id,
        PropertyRole.REVIEWER_2.id,
        PropertyRole.REVIEWER_3.id,
      ][level - 1],
      label: 'Approver',
      tooltip: 'A user authorized to review and approve submissions.',
    },
    {
      property_role_id: [
        PropertyRole.APPROVER_PLUS_1.id,
        PropertyRole.APPROVER_PLUS_2.id,
        PropertyRole.APPROVER_PLUS_3.id,
      ][level - 1],
      label: 'Approver Plus',
      tooltip: isApproverPlusOptionDisabled
        ? 'An Approver Plus must be the last reviewer in the chain.'
        : 'A user authorized to review and approve submissions and to submit topside adjustments to the Trial Balance dataset.',
      disabled: isApproverPlusOptionDisabled,
    },
    {
      property_role_id: [
        PropertyRole.VIEW_ONLY_1.id,
        PropertyRole.VIEW_ONLY_2.id,
        PropertyRole.VIEW_ONLY_3.id,
      ][level - 1],
      label: 'Viewer',
      tooltip:
        'A user authorized with view-only access allowing them to monitor the Review and Approval workflow.',
    },
  ];
};

export type UserTypeSelectionProps = {
  user_id: number;
  property_role_id?: number;
  level: number;
  approverPlusLevel: number | undefined;
  onChange: (user_id: number, property_role_id: number) => void;
};

export const UserTypeSelection: React.FC<UserTypeSelectionProps> = ({
  user_id,
  property_role_id,
  level,
  approverPlusLevel,
  onChange,
}) => {
  const options = useMemo(
    () => getUserTypeOptions(level, approverPlusLevel),
    [level, approverPlusLevel]
  );

  const currentValue = useMemo(() => {
    const selected = options.find(
      (option) => option.property_role_id === property_role_id
    );

    return selected?.property_role_id ?? options[0].property_role_id;
  }, [property_role_id, options]);

  useEffect(() => {
    if (currentValue !== property_role_id) {
      onChange(user_id, currentValue);
    }
  }, []);

  return (
    <Box alignSelf='center'>
      <Select
        variant='standard'
        input={<BootstrapInput />}
        value={currentValue}
        onChange={(e) => {
          onChange(user_id, Number(e.target.value));
        }}
        renderValue={(property_role_id) =>
          options.find((option) => option.property_role_id === property_role_id)
            ?.label
        }
      >
        <StyledListSubheader>ROLE</StyledListSubheader>
        {options.map((option) => (
          <StyledMenuItem
            key={option.property_role_id}
            value={option.property_role_id}
            disabled={option.disabled}
          >
            <StyledMenuItemContainer>
              <StyledCheckIcon
                color='primary'
                isVisible={currentValue === option.property_role_id}
              />
              <Typography>{option.label}</Typography>
              <Tooltip
                title={option.tooltip}
                sx={{ pointerEvents: 'visible' }}
                onClick={(e) => option.disabled && e.stopPropagation()}
              >
                <StyledInfoIcon />
              </Tooltip>
            </StyledMenuItemContainer>
          </StyledMenuItem>
        ))}
      </Select>
    </Box>
  );
};
