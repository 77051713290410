import React from 'react';
import FlatfileSystemUpload from '../../components/FlatFileSystemUpload';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useHistory } from 'react-router';
import { useUploadProperty } from './hooks/useUploadProperty';
import { addEditMappingSetFormState } from '../provider-detail/Panels/MappingsTab/recoil';

const UploadMappingSetPage: React.FC = () => {
  const { goBack } = useHistory();
  const mappingForm = useCherreValue(addEditMappingSetFormState);

  if (!mappingForm) {
    throw new Error('Mapping form is not set');
  }
  const onComplete = useUploadProperty(
    { file_source: 'flatfile' },
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Properties uploaded successfully.',
      });
      goBack();
    }
  );

  return (
    <FlatfileSystemUpload
      schemaSlug='property'
      title={`Upload Properties`}
      onComplete={onComplete}
      onCancel={() => {
        goBack();
      }}
    />
  );
};

export default UploadMappingSetPage;
