import { graphQLSelector, GraphQLReturn } from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { BatchValidationSummaryRoute } from 'src/products/data-submission-portal/routes';
import { queryBatchSummaryInfoQuery } from './__generated__/queryBatchSummaryInfoQuery.graphql';

export const batchSummaryInfo = graphQLSelector({
  query: graphql`
    query queryBatchSummaryInfoQuery($property_batch_id: Int!) {
      sys_property_batches(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        submission_id
        submission {
          submission_name
          due_date
        }
        property {
          property_name
          property_id
        }
      }
    }
  ` as GraphQLReturn<queryBatchSummaryInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(BatchValidationSummaryRoute.routeParamSelector),
  mapResponse: (rawResponse) => rawResponse.sys_property_batches[0],
});
