import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getAccountTreeFileQuery } from './__generated__/getAccountTreeFileQuery.graphql';

export const getAccountTreeFile = graphQLSelector({
  query: graphql`
    query getAccountTreeFileQuery(
      $where: sys_organization_account_tree_details_bool_exp = {}
    ) {
      sys_organization_account_tree_details(where: $where) {
        account_tree_id
        account_type
        account_group_code
        account_code_description
        gl_account_code
        is_normal_balance
        is_contiguous
        total_into_code
        indent
        is_hidden
        is_negate
        nest_level
        order_index
      }
    }
  ` as GraphQLReturn<getAccountTreeFileQuery>,
  mapVariables: (id?: number) => () => {
    return {
      where: {
        account_tree_id: { _eq: id },
      },
    };
  },
  mapResponse: (resp) => {
    return {
      data: resp.sys_organization_account_tree_details,
    };
  },
});
