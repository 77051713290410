import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getSourceExistWithLinkPropertiesQuery } from './__generated__/getSourceExistWithLinkPropertiesQuery.graphql';

export const getSourceExistWithLinkProperties = graphQLSelector({
  query: graphql`
    query getSourceExistWithLinkPropertiesQuery(
      $entity_id: String!
      $property_id: [Int!]
    ) {
      sys_properties_flattened_union(
        where: {
          entity_id: { _eq: $entity_id }
          mappings_property_id: { _nin: $property_id }
          is_active: { _eq: true }
        }
      ) {
        entity_id
        mappings_property_id
        property_code
        provider {
          provider_id
          provider_name
        }
      }
    }
  ` as GraphQLReturn<getSourceExistWithLinkPropertiesQuery>,
  mapVariables: (parms: { entity_id: string; property_id: number[] }) => () => {
    return {
      entity_id: parms.entity_id,
      property_id: parms.property_id,
    };
  },
  mapResponse: (response) => response.sys_properties_flattened_union,
});
