import moment from 'moment';
import { BatchDetailRoute } from '../../routes';
import {
  GraphQLReturn,
  NotFoundError,
  graphQLSelector,
  selector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import { recoilBatchDetailQuery } from './__generated__/recoilBatchDetailQuery.graphql';

export const namespace = (key: string) => `batch-detail/${key}`;

export const getBatchDetail = graphQLSelector({
  mapVariables:
    () =>
    ({ get }) => {
      return get(BatchDetailRoute.routeParamSelector);
    },
  mapResponse: (response) => {
    const submission = response.sys_submissions?.[0];

    if (!submission) {
      throw new NotFoundError('Submission Not Found');
    }

    return submission;
  },
  query: graphql`
    query recoilBatchDetailQuery($submission_id: Int) {
      sys_submissions(where: { submission_id: { _eq: $submission_id } }) {
        submission_id
        submission_name
        reporting_period
        reporting_period_start_date
        reporting_period_end_date
        due_date
        submission_type {
          submission_type_label
        }
        approved_properties_count: property_batches_aggregate(
          where: {
            is_approved: { _eq: true }
            _and: {
              property: {
                property_type: { property_type_slug: { _neq: "investment" } }
              }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        properties_count: property_batches_aggregate(
          where: {
            property: {
              property_type: { property_type_slug: { _neq: "investment" } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<recoilBatchDetailQuery>,
});

export const batchPeriod = selector({
  key: namespace('batch-period'),
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const batchDetail = get(getBatchDetail());
      const isQuarterly = batchDetail.reporting_period === 'quarterly';

      const startMoment = moment(batchDetail.reporting_period_start_date);

      if (isQuarterly) {
        return `Q${startMoment.format('Q YYYY')}`;
      }

      return startMoment.format('MMMM YYYY');
    },
});
