import { useCherrePartialState } from '@cherre-frontend/data-fetching';
import React from 'react';
import { addEditMappingFormState } from '../recoil';
import { DialogTextField, NameField } from '../styles';
import {
  isExcludedPropertyType,
  isSourcedFromMasterCOA,
  MappingTypes,
} from '../types';

export type AddEditTargetLabelInputProps = {
  mappingType: MappingTypes;
};

const AddEditTargetLabelInput: React.FC<AddEditTargetLabelInputProps> = ({
  mappingType,
}) => {
  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingFormState
  );
  if (
    isSourcedFromMasterCOA(mappingType) ||
    isExcludedPropertyType(mappingType)
  ) {
    return (
      <NameField
        variant='outlined'
        size='small'
        label='Target Label'
        defaultValue={mappingForm?.target_description || ' '}
        value={mappingForm?.target_description || ' '}
        disabled
      />
    );
  }
  return (
    <DialogTextField
      variant='outlined'
      size='small'
      label='Target Label'
      defaultValue={mappingForm?.target_description}
      onChange={(e) => setMappingForm({ target_description: e.target.value })}
    />
  );
};

export default AddEditTargetLabelInput;
