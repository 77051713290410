import React from 'react';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import {
  MenuItem,
  PersonIcon,
  MRT_RowSelectionState,
} from '@cherre-frontend/ui';
import { Property } from '../queries/getProperties';
import {
  managedPropertyState,
  propertiesTableSelectedProvider,
} from 'src/products/data-submission-portal/recoil/dialog';

type ManageUsersActionMenuItemProps = {
  property: Property;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
};
export const ManageUsersActionMenuItem: React.FC<
  ManageUsersActionMenuItemProps
> = ({ property, setRowSelection }) => {
  const setManagedPropertyState = useCherreSetState(managedPropertyState);
  const setSelectedProvider = useCherreSetState(
    propertiesTableSelectedProvider
  );

  const onClick = () => {
    setRowSelection({ [property.property_id ?? 0]: true });
    setSelectedProvider(property.provider?.provider_id ?? null);
    setManagedPropertyState({
      entity_id: property.entity_id,
      property_id: property.property_id,
      property_name: property.property_name,
      organization_id: property.organization_id,
      property_mapping: property.property_mapping,
    });
  };

  return (
    <MenuItem onClick={onClick}>
      <PersonIcon sx={{ height: 16 }} />
      Manage Users
    </MenuItem>
  );
};
