/**
 * @generated SignedSource<<5cddc069c587921cd18079975b08e546>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queryFileInfoQuery$variables = {
  dataset_id: number;
};
export type queryFileInfoQuery$data = {
  readonly sys_property_batch_datasets: ReadonlyArray<{
    readonly batch_file: {
      readonly file_name: string;
      readonly submitter: {
        readonly sakura_user: {
          readonly first_name: string | null;
          readonly last_name: string | null;
        } | null;
      };
    } | null;
    readonly is_valid: boolean | null;
    readonly property_batch: {
      readonly submission: {
        readonly submission_name: string;
      };
    };
    readonly validated_datetime: string | null;
    readonly warning_count: {
      readonly aggregate: {
        readonly count: number;
      } | null;
    };
  }>;
};
export type queryFileInfoQuery = {
  response: queryFileInfoQuery$data;
  variables: queryFileInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  }
],
v1 = {
  "_is_null": false
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "file_id",
            "value": (v1/*: any*/)
          },
          {
            "kind": "Literal",
            "name": "is_valid",
            "value": (v1/*: any*/)
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_datasets",
    "kind": "LinkedField",
    "name": "sys_property_batch_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_valid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validated_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_batch_files",
        "kind": "LinkedField",
        "name": "batch_file",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "file_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users",
            "kind": "LinkedField",
            "name": "submitter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sakura_user",
                "kind": "LinkedField",
                "name": "sakura_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submissions",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "warning_count",
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "is_valid": {
                "_eq": false
              },
              "validation_mode": {
                "_eq": "soft"
              }
            }
          }
        ],
        "concreteType": "sys_property_batch_dataset_validations_aggregate",
        "kind": "LinkedField",
        "name": "property_batch_dataset_validations_aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batch_dataset_validations_aggregate_fields",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "property_batch_dataset_validations_aggregate(where:{\"is_valid\":{\"_eq\":false},\"validation_mode\":{\"_eq\":\"soft\"}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryFileInfoQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryFileInfoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8492e72257a92fd63f72f25ae2698fdf",
    "id": null,
    "metadata": {},
    "name": "queryFileInfoQuery",
    "operationKind": "query",
    "text": "query queryFileInfoQuery(\n  $dataset_id: Int!\n) {\n  sys_property_batch_datasets(where: {property_batch_dataset_id: {_eq: $dataset_id}, file_id: {_is_null: false}, is_valid: {_is_null: false}}) {\n    is_valid\n    validated_datetime\n    batch_file {\n      file_name\n      submitter {\n        sakura_user {\n          first_name\n          last_name\n        }\n      }\n    }\n    property_batch {\n      submission {\n        submission_name\n      }\n    }\n    warning_count: property_batch_dataset_validations_aggregate(where: {is_valid: {_eq: false}, validation_mode: {_eq: \"soft\"}}) {\n      aggregate {\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "772e3b43a0b5b18d0ec30cc644dbd759";

export default node;
