import { useContext } from 'react';
import { ForbiddenError, useRecoilScope } from '@cherre-frontend/data-fetching';
import {
  PersonaScopeContext,
  PersonasType,
  PropertyScopeContext,
  SubmissionScopeContext,
  personasList,
} from './context';
import { DSPHasuraJWT } from 'src/products/shell/DSPRelayEnvironment/jwt';
import { getPropertiesRole } from './recoil';
import { getUser } from '../../recoil/getUser';
import { useRecoilValue } from 'recoil';
import { useAppContext } from '@cherre-frontend/core';
import { triggerSubscription } from '@cherre-frontend/data-fetching/';
import { usePermission } from 'src/hooks';
import { PropertyRole } from '../../__generated__/constants';

const useErrorHandler = (shouldThrow: boolean) => {
  const { logger } = useAppContext();

  return (msg: string) => {
    if (shouldThrow) {
      throw new ForbiddenError(msg);
    }

    logger.info(msg);
    return null;
  };
};

/**
 * Returns the persona of the user, based on the PropertyScope and PersonaScope above in the tree.
 * If there are no PropertyScope above in the tree, returns if the user is a reviewer or preparer in any property.
 * If there is a PersonaScope above in the tree, returns if the user has that specific role for the propertyId defined in the nearest PropertyScope (or any property, if propertyId is not defined).
 * @param shouldThrow If true, throws a ForbiddenError if the user does not have the role. If false, returns null instead of throwing an error.
 */
export function usePersona(shouldThrow?: true): PersonasType;
export function usePersona(shouldThrow: false): PersonasType | null;
export function usePersona(shouldThrow = true) {
  const submissionId = useContext(SubmissionScopeContext);
  const propertyId = useContext(PropertyScopeContext);
  const persona = useContext(PersonaScopeContext);

  triggerSubscription(getUser);
  const user = useRecoilValue(getUser);
  const scope = useRecoilScope();
  triggerSubscription(
    getPropertiesRole({
      userId: user.id,
      submissionId: submissionId,
    })(scope)
  );

  const handleError = useErrorHandler(shouldThrow);

  const properties = useRecoilValue(
    getPropertiesRole({
      userId: user.id,
      submissionId: submissionId,
    })(scope)
  );

  const inheritedRoles = useRecoilValue(DSPHasuraJWT.rolesAtom)?.filter(
    (role) => personasList.includes(role as PersonasType)
  );

  // All admin users have the view_admin role
  const isDSPAdmin = inheritedRoles.some((r) => r === 'view_admin');

  // Return the view_admin if the user is an admin and the persona context is view_admin
  if (persona === 'view_admin' && isDSPAdmin) {
    return persona;
  }

  if (!inheritedRoles || inheritedRoles.length === 0) {
    return handleError(`The user has no inherited roles`);
  }

  if (!properties || properties.length === 0) {
    return handleError(`The user has no properties associated`);
  }

  if (!propertyId && !persona) {
    if (submissionId) {
      const roleSet = properties[0].property_role.property_role_set;

      if (!inheritedRoles.includes(roleSet)) {
        return handleError(`The user does not have the role ${roleSet}`);
      }

      return roleSet;
    }
    if (inheritedRoles.length > 1) {
      return handleError(
        `The user has multiple inherited roles, use PersonaScope or PropertyScope to specify which one to use`
      );
    }
    return inheritedRoles[0];
  }

  if (propertyId && !persona) {
    const property = properties?.find((p) => p.property_id === propertyId);

    if (!property) {
      return handleError(
        `The property ${propertyId} is not associated with the user`
      );
    }

    if (!inheritedRoles.includes(property.property_role.property_role_set)) {
      return handleError(
        `The user does not have the role ${property.property_role.property_role_set}`
      );
    }

    return property.property_role.property_role_set;
  }

  if (persona && !inheritedRoles.includes(persona)) {
    return handleError(`The user does not have the role ${persona}`);
  }

  if (!propertyId) {
    return persona;
  }

  const property = properties.find((p) => p.property_id === propertyId);

  if (!property) {
    return handleError(
      `The property ${propertyId} is not associated with the user`
    );
  }

  if (property.property_role.property_role_set === persona) {
    return persona;
  }

  return handleError(
    `The user does not have the persona ${persona} for property ${propertyId}`
  );
}

export function useClaims(persona: PersonasType) {
  const hasDSPPermission = usePermission(['DSPAdmin', 'DSPRegular']);

  if (!hasDSPPermission) {
    return false;
  }

  const personaScopeValue = useContext(PersonaScopeContext);
  if (personaScopeValue) {
    return usePersona(false) === persona;
  }
  const inheritedRoles = useRecoilValue(DSPHasuraJWT.rolesAtom)?.filter(
    (role) => personasList.includes(role as PersonasType)
  );

  return inheritedRoles?.includes(persona);
}

export function useHasDspCherreAdminRole(): boolean {
  const roles = useRecoilValue(DSPHasuraJWT.rolesAtom);
  return (roles || []).includes('dsp-cherre-admin');
}

export function useIsApproverPlus(shouldThrow = true) {
  const handleError = useErrorHandler(shouldThrow);
  const isReviewer = useClaims('reviewer');
  const propertyId = useContext(PropertyScopeContext);

  if (!propertyId) {
    handleError('There is no parent PropertyScope');
    return false;
  }

  if (!isReviewer) {
    handleError('The user is not a reviewer for the property ' + propertyId);
    return false;
  }

  const scope = useRecoilScope();
  const user = useRecoilValue(getUser);

  const properties = useRecoilValue(
    getPropertiesRole({
      userId: user.id,
      submissionId: null,
      propertyIds: [propertyId],
    })(scope)
  );

  const approverPlus1Label = PropertyRole.APPROVER_PLUS_1.label;

  return properties.some((p) =>
    p.property_role.property_role_slug.includes(
      approverPlus1Label.substring(0, approverPlus1Label.length - 2)
    )
  );
}
