import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyByCodeQuery } from './__generated__/getPropertyByCodeQuery.graphql';

export const getPropertyByCode = graphQLSelector({
  query: graphql`
    query getPropertyByCodeQuery($property_code: String!) {
      sys_properties(
        where: {
          property_code: { _ilike: $property_code }
          is_active: { _eq: true }
        }
        limit: 1
      ) {
        property_id
      }
    }
  ` as GraphQLReturn<getPropertyByCodeQuery>,
  mapVariables: (property_code: string) => () => ({
    property_code,
  }),
  mapResponse: (response) => response.sys_properties,
});
