/**
 * @generated SignedSource<<af8398728cf3ea6fe6234c344c4737a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteMasterChartOfAccountMutation$variables = {
  master_chart_of_account_id: number;
};
export type useDeleteMasterChartOfAccountMutation$data = {
  readonly update_sys_organization_master_chart_of_account_values: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_organization_master_chart_of_accounts: {
    readonly affected_rows: number;
  } | null;
};
export type useDeleteMasterChartOfAccountMutation = {
  response: useDeleteMasterChartOfAccountMutation$data;
  variables: useDeleteMasterChartOfAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "master_chart_of_account_id"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "_set",
    "value": {
      "is_active": false
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "master_chart_of_account_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "master_chart_of_account_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_organization_master_chart_of_account_values_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_organization_master_chart_of_account_values",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_organization_master_chart_of_accounts_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_organization_master_chart_of_accounts",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteMasterChartOfAccountMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteMasterChartOfAccountMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "dece6881548bcc94e2cd50a1902cfa00",
    "id": null,
    "metadata": {},
    "name": "useDeleteMasterChartOfAccountMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteMasterChartOfAccountMutation(\n  $master_chart_of_account_id: Int!\n) {\n  update_sys_organization_master_chart_of_account_values(_set: {is_active: false}, where: {master_chart_of_account_id: {_eq: $master_chart_of_account_id}}) {\n    affected_rows\n  }\n  update_sys_organization_master_chart_of_accounts(_set: {is_active: false}, where: {master_chart_of_account_id: {_eq: $master_chart_of_account_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "157ea642141e854220f864083c8d81fd";

export default node;
