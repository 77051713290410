/**
 * @generated SignedSource<<1946816eae2483b073f8a28c97f80fcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getProvidersTotalQuery$variables = {
  search?: string | null;
};
export type getProvidersTotalQuery$data = {
  readonly total: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getProvidersTotalQuery = {
  response: getProvidersTotalQuery$data;
  variables: getProvidersTotalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "%",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": "total",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_ilike",
                "variableName": "search"
              }
            ],
            "kind": "ObjectValue",
            "name": "provider_name"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_providers_aggregate",
    "kind": "LinkedField",
    "name": "sys_providers_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getProvidersTotalQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getProvidersTotalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04f362ff7102a66d0f7159b7da64ed94",
    "id": null,
    "metadata": {},
    "name": "getProvidersTotalQuery",
    "operationKind": "query",
    "text": "query getProvidersTotalQuery(\n  $search: String = \"%\"\n) {\n  total: sys_providers_aggregate(where: {provider_name: {_ilike: $search}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74b2cc6a7ddec51bb9282f589768b0d1";

export default node;
