import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useWorkflowApplyActionToSubmissionMutation } from './__generated__/useWorkflowApplyActionToSubmissionMutation.graphql';
import {
  selectedCardsState,
  getSubmissionBulkActions,
} from '../SubmissionBulkActions/recoil';

export const useWorkflowApplyActionToSubmission = (submission_id: number) => {
  const mutation = useMutation(
    graphql`
      mutation useWorkflowApplyActionToSubmissionMutation(
        $submission_id: Int!
        $property_batch_ids: [Int!]!
        $action: String!
      ) {
        _sys_workflow_apply_bulk_action_to_submission(
          params: {
            submission_id: $submission_id
            action: $action
            property_batch_ids: $property_batch_ids
          }
        ) {
          results {
            message
            error
            property_batch_id
          }
        }
      }
    ` as GraphQLReturn<useWorkflowApplyActionToSubmissionMutation>,
    {
      mapVariables:
        ({
          action,
          property_batch_ids,
        }: {
          action: string;
          property_batch_ids: number[];
        }) =>
        async () => ({ submission_id, action, property_batch_ids }),
      onCompleted: (data, ctx) => {
        const results =
          data._sys_workflow_apply_bulk_action_to_submission.results.filter(
            (result) => result
          );

        ctx.recoil.set(selectedCardsState, []);
        ctx.recoil.refresh(getSubmissionBulkActions());

        const errors = results.filter((result) => result.error);
        if (errors.length) {
          ctx.logger.error(
            `Action failed for some property batches: ${JSON.stringify(
              errors
            )}. submission_id: ${submission_id}`
          );

          ctx.showSnackbar({
            message: 'The operation failed for some property submissions.',
            type: 'error',
          });
          return;
        }

        const approvedProperties = results.filter(
          (result) => !result.error
        ).length;

        ctx.showSnackbar({
          message: `${approvedProperties} property submissions have been approved.`,
          type: 'success',
        });
      },
    }
  );

  return (action: string, propertyBatchIds: number[]) => {
    mutation({ action, property_batch_ids: propertyBatchIds });
  };
};
