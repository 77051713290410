/**
 * @generated SignedSource<<928a54ce371581af3c3899eaf2b1bcae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SysUploadMappingInput = {
  file_reference_source: string;
  file_source: string;
  mapping_field_id: number;
  mapping_set_description: string;
  master_chart_of_account_id: number;
  provider_id: number;
};
export type useUploadMappingMutation$variables = {
  input: SysUploadMappingInput;
};
export type useUploadMappingMutation$data = {
  readonly _sys_upload_mapping: {
    readonly mapping_set_id: number;
  } | null;
};
export type useUploadMappingMutation = {
  response: useUploadMappingMutation$data;
  variables: useUploadMappingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SysUploadMappingOutput",
    "kind": "LinkedField",
    "name": "_sys_upload_mapping",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadMappingMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadMappingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "efdf54267e0f5a2907e339ff06120f1b",
    "id": null,
    "metadata": {},
    "name": "useUploadMappingMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadMappingMutation(\n  $input: SysUploadMappingInput!\n) {\n  _sys_upload_mapping(input: $input) {\n    mapping_set_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "846860e05e939698d50bbfe5989be475";

export default node;
