import {
  constSelector,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  CloseIcon,
  IconButton,
  Link,
  Panel,
  Popover,
  Typography,
  popoverClasses,
  styled,
} from '@cherre-frontend/ui';
import React, { ReactNode, useMemo, useState } from 'react';
import { getClickCardSourceDataMappingsByIds } from '../pages/provider-detail/Panels/PropertiesTab/queries/getClickCardData';
import { $unifiedIngestGetTranformTemplatesQuery } from './AssignDatasetsDialog/components/Stages/SelectDatasetTemplate';
import { useSelector } from 'react-redux';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[50]};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  grid-area: title;
`;

const CloseIconButton = styled(IconButton)`
  color: #9e9e9e;
  grid-area: close;
`;

const TooltipContainer = styled('div')`
  display: grid;
  grid-template-areas: 'title close' 'list list';
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const TooltipList = styled('div')`
  grid-area: list;
  display: grid;
  grid-template-columns: auto 300px;
  gap: 10px;
`;

type CardSourceTooltipProps = {
  template_id: string;
  id: number;
};

const CardSourceTooltip: React.FC<CardSourceTooltipProps> = ({
  template_id,
  id,
}) => {
  const result = useCherreValue(
    getClickCardSourceDataMappingsByIds({
      mapping: template_id,
      property_id: id,
    })
  );

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);

  const ingestionTemplates = useCherreValue(
    ownerName
      ? $unifiedIngestGetTranformTemplatesQuery({
          params: { owner_name: ownerName },
        })
      : constSelector([])
  );

  const source = useMemo(() => {
    if (!result) {
      return null;
    }

    const item = ingestionTemplates
      ?.flatMap((t) =>
        t.templates.map((tt) => ({
          source: t.name,
          template: tt.name,
          templateId: tt.id,
        }))
      )
      .find((t) => t.templateId === result[0]);

    if (!item) {
      return null;
    }

    return `${item.source} - ${item.template}`;
  }, [result, ingestionTemplates]);

  return source ? (
    <Typography sx={{ fontSize: '11px' }}>Source: {source}</Typography>
  ) : null;
};

type ClickCardTooltipProps = {
  title: string;
  data: string[] | (() => string[]);
  onClose: () => void;
  icon: ReactNode;
  id?: number;
};

const ClickCardTooltip: React.FC<ClickCardTooltipProps> = ({
  data,
  onClose,
  title,
  icon,
  id,
}) => {
  const sortedData = typeof data === 'function' ? [...data()] : [...data];
  return (
    <TooltipContainer>
      <Title>{title}</Title>
      <CloseIconButton size='small' onClick={onClose}>
        <CloseIcon />
      </CloseIconButton>

      <Panel id={`tooltip-list-${id}`} disableParent={false}>
        <TooltipList>
          {sortedData.sort().map((item) => (
            <>
              {icon}
              <Box>
                <Typography>{item}</Typography>
                {id ? <CardSourceTooltip template_id={item} id={id} /> : null}
              </Box>
            </>
          ))}
        </TooltipList>
      </Panel>
    </TooltipContainer>
  );
};

export type ClickCardCellProps = {
  title: string;
  link_text: ReactNode;
  icon: ReactNode;
  data: any;
  data_accessor?: string;
  id?: number;
  dataLength?: number;
};

const CustomPopover = styled(Popover)`
  .${popoverClasses.paper} {
    border-radius: 10px;
    padding: 10px;
    background: var(--gray-900, #212121);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    color: white;
  }
`;

export const ClickCard: React.FC<ClickCardCellProps> = ({
  title,
  link_text,
  icon,
  data,
  id,
  dataLength,
}) => {
  const suspended = useIsSuspended();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  if (suspended) {
    return null;
  }

  // or dataLength is defined and gt 0 or data is not a function and data.length is defined and gt 0
  const hasData = dataLength ? dataLength > 0 : data.length > 0;

  return (
    <>
      <CustomPopover
        open={open && hasData}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Panel id={`tooltip-${id}`} disableParent={false}>
          <ClickCardTooltip
            title={title}
            data={data}
            onClose={handleClose}
            icon={icon}
            id={id}
          />
        </Panel>
      </CustomPopover>

      <Link href='#' padding='10px' underline='none' onClick={handleClick}>
        {link_text}
      </Link>
    </>
  );
};

ClickCard.displayName = 'ClickCard';
