/**
 * @generated SignedSource<<58632378a04d6ea30fa3829863a4419b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getBulkDatasetQuery$variables = {
  dataset_id: number;
  property_ids?: ReadonlyArray<number> | null;
};
export type getBulkDatasetQuery$data = {
  readonly sys_properties_datasets: ReadonlyArray<{
    readonly dataset: {
      readonly dataset_description: string;
      readonly dataset_label: string;
      readonly unified_ingest_schemas: any | null;
    };
    readonly property: {
      readonly provider: {
        readonly ingestion_config: any | null;
        readonly provider_id: number;
      } | null;
    };
    readonly template_id: string | null;
  }>;
};
export type getBulkDatasetQuery = {
  response: getBulkDatasetQuery$data;
  variables: getBulkDatasetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataset_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "dataset_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset_id"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "property_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_datasets",
    "kind": "LinkedField",
    "name": "sys_properties_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets",
        "kind": "LinkedField",
        "name": "dataset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unified_ingest_schemas",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_providers",
            "kind": "LinkedField",
            "name": "provider",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ingestion_config",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getBulkDatasetQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getBulkDatasetQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d5a1e80b18feedb311b08574ba952d85",
    "id": null,
    "metadata": {},
    "name": "getBulkDatasetQuery",
    "operationKind": "query",
    "text": "query getBulkDatasetQuery(\n  $dataset_id: Int!\n  $property_ids: [Int!]\n) {\n  sys_properties_datasets(where: {dataset_id: {_eq: $dataset_id}, property_id: {_in: $property_ids}, is_active: {_eq: true}}, limit: 1) {\n    template_id\n    dataset {\n      dataset_description\n      dataset_label\n      unified_ingest_schemas\n    }\n    property {\n      provider {\n        ingestion_config\n        provider_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40aec4601548605d03cf112bcfc9bbb8";

export default node;
