import React from 'react';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import { MultiFilter as OgFilter } from '@cherre-frontend/ui';
import { Filter } from '../../../consts';
import { $filterOption } from '../recoil';
import { monitorSubmissionsFilterState } from '../../../recoil';

export const MultiFilter: React.FC<{ label: Filter }> = ({ label }) => {
  const options = useCherreValue($filterOption(label));
  const [state, setState] = useCherreState(
    monitorSubmissionsFilterState(label)
  );
  const _state = Array.isArray(state) ? state : state ? [state] : [];
  return (
    <OgFilter
      label={label}
      options={options || []}
      selected={_state}
      onChange={(newValue) => setState(newValue as string[] | number[])}
    />
  );
};
