import React from 'react';

import {
  Chip,
  ChipProps,
  styled,
  SvgIconComponent,
  CloseIcon,
  InfoIcon,
  CheckIcon,
  RefreshIcon,
} from '@cherre-frontend/ui';
import { themeV5 } from 'src/const/themev5';

export enum ValidationStatusChipCodes {
  pending = 1,
  uploading,
  uploaded,
  upload_error,
  validating,
  validation_passed,
  validation_failed,
  validation_warning,
  validation_error,
  no_records,
}

export type ValidationStatusChipConfig = {
  icon?: SvgIconComponent | undefined;
  label: string;
  bgColor: string;
  textColor: string;
  title?: string;
};

export const validationStatusConfigMap = new Map<
  ValidationStatusChipCodes,
  ValidationStatusChipConfig
>([
  [
    ValidationStatusChipCodes.pending,
    {
      label: 'Pending',
      bgColor: themeV5.palette.grey[700],
      textColor: themeV5.palette.common.white,
    },
  ],
  [
    ValidationStatusChipCodes.uploading,
    {
      label: 'Uploading...',
      bgColor: themeV5.palette.common.white,
      textColor: themeV5.palette.common.black,
      icon: RefreshIcon,
    },
  ],
  [
    ValidationStatusChipCodes.uploaded,
    {
      label: 'Uploaded',
      bgColor: themeV5.palette.common.white,
      textColor: themeV5.palette.common.black,
    },
  ],
  [
    ValidationStatusChipCodes.upload_error,
    {
      label: 'Upload error',
      bgColor: themeV5.accents.error.main,
      textColor: themeV5.palette.common.white,
      icon: CloseIcon,
    },
  ],
  [
    ValidationStatusChipCodes.validating,
    {
      label: 'Running...',
      bgColor: themeV5.palette.common.white,
      textColor: themeV5.palette.common.black,
      icon: RefreshIcon,
    },
  ],
  [
    ValidationStatusChipCodes.validation_passed,
    {
      label: 'Passed',
      bgColor: themeV5.accents.positive.main,
      textColor: themeV5.palette.common.white,
      icon: CheckIcon,
      title: 'Validations Passed',
    },
  ],
  [
    ValidationStatusChipCodes.validation_failed,
    {
      label: 'Failed',
      bgColor: themeV5.accents.error.main,
      textColor: themeV5.palette.common.white,
      icon: CloseIcon,
      title: 'Review Validation Failures',
    },
  ],
  [
    ValidationStatusChipCodes.validation_warning,
    {
      label: 'Warning',
      bgColor: themeV5.accents.warning.main,
      textColor: themeV5.palette.common.white,
      icon: InfoIcon,
      title: 'Review Validation Warnings',
    },
  ],
  [
    ValidationStatusChipCodes.validation_error,
    {
      label: 'Validation error',
      bgColor: themeV5.accents.error.main,
      textColor: themeV5.palette.common.white,
      icon: CloseIcon,
    },
  ],
  [
    ValidationStatusChipCodes.no_records,
    {
      label: 'No records',
      bgColor: themeV5.accents.error.main,
      textColor: themeV5.palette.common.white,
      icon: CloseIcon,
    },
  ],
]);

export type StatusCode = keyof typeof validationStatusConfigMap;

type StatusChipStyledProps = {
  bgColor: string;
  textColor: string;
  icon?: React.ReactElement;
} & ChipProps;

const StatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'textColor' !== prop && 'bgColor' !== prop,
})<StatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type ValidationStatusChipProps = {
  statusCode: ValidationStatusChipCodes;
};

const ValidationStatusChip: React.FC<ValidationStatusChipProps> = ({
  statusCode,
}) => {
  const status = validationStatusConfigMap.get(statusCode);

  if (!status) {
    throw new Error(`Invalid status code ${statusCode}`);
  }

  const Icon: SvgIconComponent | undefined =
    'icon' in status ? status.icon : undefined;

  return (
    <StatusChipStyled
      label={status.label}
      icon={
        Icon ? (
          <Icon style={{ color: status.textColor, width: 12, height: 12 }} />
        ) : undefined
      }
      bgColor={status.bgColor}
      textColor={status.textColor}
      title={status.title}
    />
  );
};

ValidationStatusChip.displayName = 'ValidationStatusChip';

export default ValidationStatusChip;
