import React, { useEffect } from 'react';
import { Box, Typography, WarningIcon, CancelIcon } from '@cherre-frontend/ui';
import { themeV5 } from 'src/const/themev5';

type Props = {
  type?: 'error' | 'warning' | null;
  header?: React.ReactNode | null;
  subtitle?: React.ReactNode | null;
  noSubtitle?: boolean;
};

const WarningBox: React.FC<Props> = ({
  type,
  header,
  subtitle,
  noSubtitle,
}) => {
  const color =
    type === 'error' ? themeV5.accents.error.main : themeV5.accents.brown.main;

  const isWarning = type === 'warning';

  if (!type || !header || (!subtitle && !noSubtitle)) {
    return null;
  }

  useEffect(() => {
    if (type && header && (subtitle || noSubtitle)) {
      document.getElementById('dialog-warning-box')?.scrollIntoView();
    }
  }, [type, header, subtitle, noSubtitle]);

  return (
    <Box
      id='dialog-warning-box'
      style={{
        width: '100%',
        backgroundColor: themeV5.accents.warning.light,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        padding: '4px',
        borderRadius: '10px',
        marginBottom: '12px',
      }}
    >
      {isWarning ? (
        <WarningIcon fontSize='medium' style={{ color }} />
      ) : (
        <CancelIcon fontSize='medium' style={{ color }} />
      )}
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body1' style={{ fontWeight: 700, color }}>
          {header}
        </Typography>
        <Typography variant='body2' style={{ color }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default WarningBox;
