import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getInvestimentsPropertiesQuery } from './__generated__/getInvestimentsPropertiesQuery.graphql';

export const getInvestimentsProperties = graphQLSelector({
  query: graphql`
    query getInvestimentsPropertiesQuery($property_ids: [Int!]!) {
      sys_property_relationships(
        where: { property_id_from: { _in: $property_ids } }
        distinct_on: property_id_to
      ) {
        property_id_to
      }
    }
  ` as GraphQLReturn<getInvestimentsPropertiesQuery>,
  mapResponse: (response) =>
    response.sys_property_relationships.map((r) => r.property_id_to),
});
