import React, { useState, useCallback, useMemo } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ClickAwayListener,
  ArrowDropDownIcon,
  Box,
  useTheme,
  Checkbox,
  Typography,
  Tooltip,
  useConfirmationModal,
  ConfirmationModal,
} from '@cherre-frontend/ui';
import { useCherreValue, useCherreState } from '@cherre-frontend/data-fetching';
import {
  selectedCardsState,
  getSubmissionBulkActions,
  allCardsInPageSelectedState,
  allCardsSelectedState,
  selectableCardsInPageState,
} from './recoil';
import { useSubmissionBulkActionsEnabled } from '../hooks/useSubmissionBulkActionsEnabled';
import { useWorkflowApplyActionToSubmission } from '../hooks/useWorkflowApplyActionToSubmission';
import { WORKFLOW } from '../../PropertyBatchAccordion/consts';
import { uniq } from 'lodash';

interface SubmissionBulkActionsProps {
  submissionId?: number;
}

const ConfirmModalContent = ({
  propertiesSelected,
  operation,
}: {
  propertiesSelected: number;
  operation: string;
}) => {
  return (
    <>
      You're about to {operation}{' '}
      <b>
        {propertiesSelected} property submission
        {propertiesSelected !== 1 ? 's' : ''}
      </b>{' '}
      at once. Please note that some of these submissions may not have been
      reviewed individually.
      <br />
      <br />
      Are you sure you want to proceed?
    </>
  );
};

export const SubmissionBulkActions = ({
  submissionId,
}: SubmissionBulkActionsProps) => {
  const theme = useTheme();

  const { modalProps, confirm } = useConfirmationModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCards, setSelectedCards] = useCherreState(selectedCardsState);
  const open = Boolean(anchorEl);

  const bulkActions = useCherreValue(getSubmissionBulkActions());

  const submissionBulkActionsEnabled = useSubmissionBulkActionsEnabled();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyAction = useWorkflowApplyActionToSubmission(submissionId ?? 0);

  const allCardsInPageSelected = useCherreValue(allCardsInPageSelectedState);
  const allCardsSelected = useCherreValue(allCardsSelectedState);

  const selectableCardsInPage = useCherreValue(selectableCardsInPageState);

  const handleActionClick = useCallback(() => {
    const actionsToApply =
      bulkActions?.map((action) => ({
        slug: action?.slug,
        ids: action?.property_batch_ids.filter((id) =>
          selectedCards?.includes(id)
        ),
      })) ?? [];

    const apply = () => {
      actionsToApply.forEach((action) => {
        if (action.slug) {
          applyAction(action.slug, action.ids ?? []);
        }
      });
    };

    const hasSubmit = bulkActions?.some(
      (action) => action?.slug === WORKFLOW.BULK_REVIEWER_SUBMIT
    );

    if (hasSubmit) {
      confirm({
        title: 'Submit Properties',
        content: (
          <ConfirmModalContent
            propertiesSelected={selectedCards?.length ?? 0}
            operation='submit'
          />
        ),
        confirmLabel: 'Proceed',
        cancelLabel: 'Cancel',
        handleConfirm: apply,
      });
      return;
    }

    const hasApprove = bulkActions?.some(
      (action) => action?.slug === WORKFLOW.BULK_REVIEWER_APPROVE
    );

    if (hasApprove) {
      confirm({
        title: 'Approve Properties',
        content: (
          <ConfirmModalContent
            propertiesSelected={selectedCards?.length ?? 0}
            operation='approve'
          />
        ),
        confirmLabel: 'Proceed',
        cancelLabel: 'Cancel',
        handleConfirm: apply,
      });
      return;
    }

    apply();
    handleClose();
  }, [applyAction, confirm, selectedCards]);

  const onChangeSelectAllCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectedCards(
      checked
        ? uniq([...(selectedCards ?? []), ...(selectableCardsInPage ?? [])])
        : []
    );
  };

  const actionName = useMemo(() => {
    if (!bulkActions?.length) {
      return;
    }

    if (bulkActions.length === 1) {
      return bulkActions[0]?.name;
    }

    return bulkActions
      .map((action) => action?.name)
      .filter(Boolean)
      .join(' / ');
  }, [bulkActions]);

  if (!submissionBulkActionsEnabled) {
    return null;
  }

  return (
    <>
      <ConfirmationModal {...modalProps} />
      <ClickAwayListener onClickAway={handleClose}>
        <Box gridColumn={1}>
          <Button
            disabled={!selectableCardsInPage?.length}
            sx={{
              background: theme.palette.background.paper,
              '& > span': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={handleClick}
          >
            <Tooltip title={'Select all property submissions on this page.'}>
              <Checkbox
                sx={{ padding: 0 }}
                checked={allCardsInPageSelected}
                indeterminate={
                  (selectedCards?.length ?? 0) > 0 && !allCardsSelected
                }
                disabled={!selectableCardsInPage?.length}
                onChange={onChangeSelectAllCheckbox}
                onClick={(e) => e.stopPropagation()}
              />
            </Tooltip>
            <ArrowDropDownIcon />
            <Typography textTransform='none' color='grey.900' variant='body1'>
              {(selectedCards?.length ?? 0) > 0 &&
                `${selectedCards?.length} selected`}
            </Typography>
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              disabled={selectedCards?.length === 0}
              onClick={handleActionClick}
            >
              {actionName}
            </MenuItem>
          </Menu>
        </Box>
      </ClickAwayListener>
    </>
  );
};
