/**
 * @generated SignedSource<<972b11d1665d8fa984992401d2d59de6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getClickCardDataSourceMappingSetsByIdsQuery$variables = {
  mapping: string;
  property_id: number;
};
export type getClickCardDataSourceMappingSetsByIdsQuery$data = {
  readonly sys_properties_datasets: ReadonlyArray<{
    readonly dataset: {
      readonly dataset_description: string;
    };
    readonly template_id: string | null;
  }>;
};
export type getClickCardDataSourceMappingSetsByIdsQuery = {
  response: getClickCardDataSourceMappingSetsByIdsQuery$data;
  variables: getClickCardDataSourceMappingSetsByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mapping"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "mapping"
                  }
                ],
                "kind": "ObjectValue",
                "name": "dataset_description"
              }
            ],
            "kind": "ObjectValue",
            "name": "dataset"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_id"
          },
          {
            "kind": "Literal",
            "name": "template_id",
            "value": {
              "_gt": "0"
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_datasets",
    "kind": "LinkedField",
    "name": "sys_properties_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets",
        "kind": "LinkedField",
        "name": "dataset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getClickCardDataSourceMappingSetsByIdsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getClickCardDataSourceMappingSetsByIdsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5fe9a2cbfc147ba230bfefd9c615dfd8",
    "id": null,
    "metadata": {},
    "name": "getClickCardDataSourceMappingSetsByIdsQuery",
    "operationKind": "query",
    "text": "query getClickCardDataSourceMappingSetsByIdsQuery(\n  $mapping: String!\n  $property_id: Int!\n) {\n  sys_properties_datasets(where: {property_id: {_eq: $property_id}, template_id: {_gt: \"0\"}, is_active: {_eq: true}, dataset: {dataset_description: {_eq: $mapping}}}) {\n    template_id\n    dataset {\n      dataset_description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2256ce25e741ac0dd61c5ecdd5fe3f41";

export default node;
