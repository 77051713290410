import React, { useEffect } from 'react';
import { PropertyBatchAccordionProps } from '../PropertyBatchAccordion/types';
import {
  useCherreState,
  useCherreSetState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  PersonaScope,
  PersonasType,
  useClaims,
} from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import {
  getSubmissionBatch,
  batchDetailPropertiesState,
  scrollState,
  Submission,
  expandedCardsState,
} from './recoil';
import {
  Accordion,
  Divider,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  ConfirmationModal,
  Box,
  ExpandMoreIcon,
  ExpandLessIcon,
  useTheme,
  Typography,
} from '@cherre-frontend/ui';
import { AccordionsWithPropertyBatchesGrouped, Accordions } from './accordions';
import { TablePagination, Panel, PropertySubmissions } from './styles';
import { useRecoilState } from 'recoil';
import { NoItemsFound } from '../../../../components/NoBatchesFound';
import { useScrollPosition } from 'src/products/data-submission-portal/hooks/useScrollPosition';
import { useDisabledContext } from '@cherre-frontend/core';
import { useMissingEntityModal } from './hooks/useMissingEntityModal';
import { PropertyBatchViewAccordion } from '../PropertyBatchAccordion/PropertyBatchAccordion.view';
import { PropertyBatchPreparerAccordion } from '../PropertyBatchAccordion/PropertyBatchAccordion.preparer';
import { PropertyBatchReviewerAccordion } from '../PropertyBatchAccordion/PropertyBatchAccordion.reviewer';
import { SubmissionBulkActions } from './SubmissionBulkActions';
import { SelectAllAlert } from './SubmissionBulkActions/SelectAllAlert';

interface PropertyBatchesContentProps {
  numberOfProperties: number;
  submissionBatch?: Submission;
  getAccordions: (submissionBatch?: Submission) => JSX.Element;
}

type PropertyBatchesProps = Pick<
  PropertyBatchesContentProps,
  'numberOfProperties'
>;

function Content({
  numberOfProperties,
  submissionBatch,
  getAccordions,
}: PropertyBatchesContentProps) {
  const [tableState, setTableState] = useRecoilState(
    batchDetailPropertiesState
  );
  const [storedScroll, setScrollState] = useCherreState(scrollState);

  const propertiesByStage =
    submissionBatch?.property_batches_aggregate.aggregate?.count ??
    numberOfProperties;

  const [currentScroll, scrollTo] = useScrollPosition();

  const [isDisabled] = useDisabledContext();

  useEffect(() => {
    if (!isDisabled) {
      scrollTo(storedScroll ?? 0);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (currentScroll) {
      setScrollState(currentScroll);
    }
  }, [currentScroll, isDisabled]);

  const modalProps = useMissingEntityModal();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTableState((s) => ({
      ...s,
      pagination: {
        pageIndex: newPage,
        pageSize: s.pagination.pageSize,
      },
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTableState((s) => ({
      ...s,
      pagination: {
        pageIndex: 0,
        pageSize: parseInt(event.target.value, 10),
      },
    }));
  };

  if (useIsSuspended()) {
    const { pageIndex, pageSize } = tableState.pagination;
    const notShown = propertiesByStage - pageIndex * pageSize;

    return (
      <>
        {new Array(Math.min(notShown, pageSize)).fill(null).map((_, index) => (
          <Accordion
            key={index}
            summary={
              <Grid container direction='column' gap='5px'>
                <span style={{ width: 80 }} className='suspend' />
                <span style={{ width: 250 }} className='suspend' />
              </Grid>
            }
          />
        ))}
      </>
    );
  }

  if (propertiesByStage === 0) {
    return <NoItemsFound title='No Items Found' />;
  }

  return (
    <>
      <ConfirmationModal {...modalProps} />
      {getAccordions(submissionBatch)}
      <TablePagination
        data-testid='property-pagination'
        component='div'
        count={propertiesByStage}
        page={tableState.pagination.pageIndex}
        onPageChange={handleChangePage}
        rowsPerPage={tableState.pagination.pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

const stageValues = [
  'Pending',
  'In Preparation',
  'Submitted',
  'Rejected',
  'Approved',
];

const PersonaAccordions: {
  persona: PersonasType;
  Component: React.FC<PropertyBatchAccordionProps>;
  groupPropertyBatches: boolean;
}[] = [
  {
    persona: 'view_only',
    Component: PropertyBatchViewAccordion,
    groupPropertyBatches: true,
  },
  {
    persona: 'view_admin',
    Component: PropertyBatchViewAccordion,
    groupPropertyBatches: true,
  },
  {
    persona: 'preparer',
    Component: PropertyBatchPreparerAccordion,
    groupPropertyBatches: false,
  },
  {
    persona: 'reviewer',
    Component: PropertyBatchReviewerAccordion,
    groupPropertyBatches: true,
  },
];
export function PropertyBatches({ numberOfProperties }: PropertyBatchesProps) {
  const isPreparer = useClaims('preparer');

  const isViewOnly = useClaims('view_only');
  const isViewAdmin = useClaims('view_admin');

  const [tableState, setTableState] = useRecoilState(
    batchDetailPropertiesState
  );

  const submissionBatch = useCherreValue(getSubmissionBatch());

  const handleChange = (event: SelectChangeEvent) => {
    setTableState((s) => ({
      ...s,
      stage: event.target.value,
    }));
  };

  useEffect(() => {
    if (!tableState.sortBy) {
      setTableState({
        pagination: { pageIndex: 0, pageSize: 10 },
        stage: '',
        sortBy: isPreparer ? 'entity_id' : 'property_code',
        sortDirection: 'asc',
      });
    }
  }, []);

  const handleChangeSorting = (event: SelectChangeEvent) => {
    const sortDirection = event.target.value;
    setTableState((s) => ({
      ...s,
      sortBy: isPreparer ? 'entity_id' : 'property_code',
      sortDirection,
    }));
  };

  const setCardsExpanded = useCherreSetState(expandedCardsState);

  const handleExpandAll = () => {
    const property_batches = submissionBatch?.property_batch_ids.map(
      (property_batch) => property_batch.property_batch_id
    );

    setCardsExpanded(property_batches ?? []);
  };

  const handleCollapseAll = () => {
    setCardsExpanded([]);
  };

  const theme = useTheme();

  return (
    <>
      <PropertySubmissions className='suspend'>
        <Typography variant='h5'>
          {numberOfProperties} Property Submissions
        </Typography>
        {!isViewOnly && !isViewAdmin && (
          <>
            <SelectAllAlert />
            <SubmissionBulkActions
              submissionId={submissionBatch?.submission_id}
            />
          </>
        )}
        <Box
          gridColumn={2}
          id='property-options'
          justifyContent='flex-end'
          display='flex'
          alignItems='center'
          gap={2}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Button onClick={handleExpandAll} startIcon={<ExpandMoreIcon />}>
              Expand All
            </Button>
          </FormControl>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{
              borderRightWidth: '1px',
              borderColor: theme.palette.primary.main,
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Button onClick={handleCollapseAll} startIcon={<ExpandLessIcon />}>
              Collapse All
            </Button>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Select
              data-testid='property-sorting-select'
              labelId='property-batch-sorting-select'
              value={tableState.sortDirection}
              onChange={handleChangeSorting}
            >
              <MenuItem disabled value='disabled' sx={{ fontSize: 'small' }}>
                SORT BY
              </MenuItem>
              <MenuItem value='asc'>
                {isPreparer ? 'Entity ID' : 'Property ID'} - Ascending
              </MenuItem>
              <MenuItem value='desc'>
                {isPreparer ? 'Entity ID' : 'Property ID'} - Descending
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <InputLabel id='property-batch-stage-label'>Stage</InputLabel>
            <Select
              data-testid='property-stage-select'
              labelId='property-batch-stage-label'
              value={tableState.stage}
              label='Stage'
              onChange={handleChange}
            >
              <MenuItem value='any-status'>Any Status</MenuItem>
              {stageValues.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </PropertySubmissions>
      <Panel id='property-batches'>
        {PersonaAccordions.map(
          ({ persona, Component, groupPropertyBatches }) => (
            <PersonaScope key={persona} persona={persona}>
              <Content
                getAccordions={(submission) =>
                  groupPropertyBatches ? (
                    <AccordionsWithPropertyBatchesGrouped
                      Component={Component}
                      submission={submission}
                      sortDirection={tableState.sortDirection}
                      sortBy={tableState.sortBy}
                    />
                  ) : (
                    <Accordions Component={Component} submission={submission} />
                  )
                }
                numberOfProperties={numberOfProperties}
                submissionBatch={submissionBatch}
              />
            </PersonaScope>
          )
        )}
      </Panel>
    </>
  );
}
