import React from 'react';
import { PageContent, Panel, Grid, SearchInput } from '@cherre-frontend/ui';
import MappingsTable from './components/MappingsTable';
import {
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { mappingSetsTableHasData, mappingSetsTableSearch } from './recoil';
import UploadMappingButton from './components/UploadMappingButton';

const MappingsTabPanel: React.FC = () => {
  const [search, , setSearch] = useCherreStateDebounced(mappingSetsTableSearch);
  const hasData = useCherreValue(mappingSetsTableHasData);

  return (
    <PageContent style={{ height: '100%' }}>
      {hasData && (
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap={10}
        >
          <Grid item>
            <SearchInput
              value={search ?? ''}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item>
            <UploadMappingButton />
          </Grid>
        </Grid>
      )}
      <Panel id='mappings'>
        <MappingsTable search={search} />
      </Panel>
    </PageContent>
  );
};

export default MappingsTabPanel;
