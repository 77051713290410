/**
 * @generated SignedSource<<508bbfae359d8d58911aaf896f7ee714>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportHeaderGetPropertyInfoQuery$variables = {
  property_batch_id: number;
};
export type ReportHeaderGetPropertyInfoQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly properties_flattened_union: {
      readonly entity_id: string;
      readonly parent_property_id: number | null;
      readonly property_id: number;
      readonly property_models: {
        readonly property_model_slug: string;
      } | null;
      readonly property_name: string | null;
    } | null;
    readonly property: {
      readonly property_code: string;
      readonly property_id: number;
      readonly property_name: string | null;
    };
    readonly property_batch_stage_id: number;
    readonly submission: {
      readonly reporting_period_end_date: string;
      readonly submission_name: string;
      readonly user: {
        readonly sakura_user: {
          readonly first_name: string | null;
          readonly last_name: string | null;
        } | null;
      };
    };
  }>;
};
export type ReportHeaderGetPropertyInfoQuery = {
  response: ReportHeaderGetPropertyInfoQuery$data;
  variables: ReportHeaderGetPropertyInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_stage_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_flattened_union",
        "kind": "LinkedField",
        "name": "properties_flattened_union",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parent_property_id",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entity_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_models",
            "kind": "LinkedField",
            "name": "property_models",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "property_model_slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_code",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reporting_period_end_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sakura_user",
                "kind": "LinkedField",
                "name": "sakura_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportHeaderGetPropertyInfoQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportHeaderGetPropertyInfoQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0890d8af47eaeedfdedf6e5075539b1f",
    "id": null,
    "metadata": {},
    "name": "ReportHeaderGetPropertyInfoQuery",
    "operationKind": "query",
    "text": "query ReportHeaderGetPropertyInfoQuery(\n  $property_batch_id: Int!\n) {\n  sys_property_batches(where: {property_batch_id: {_eq: $property_batch_id}}) {\n    property_batch_stage_id\n    properties_flattened_union {\n      parent_property_id\n      property_id\n      property_name\n      entity_id\n      property_models {\n        property_model_slug\n      }\n    }\n    property {\n      property_name\n      property_code\n      property_id\n    }\n    submission {\n      submission_name\n      reporting_period_end_date\n      user {\n        sakura_user {\n          first_name\n          last_name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f334ca1439ee076f4bcf3cd573170851";

export default node;
