import {
  serialize,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Button, DeleteIcon, useConfirmationModal } from '@cherre-frontend/ui';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import { useDeleteMasterChartOfAccount } from '../../hooks/useDeleteMasterChartOfAccount';
import { getOpenedBatchesWithChartOfAccounts } from '../../queries/getOpenedBatchesWithChartOfAccounts';
import { masterFileToDelete } from '../../recoil';

export const dialogTitleCloseBatches = 'Close Batches';
export const dialogTitleDeleteMasterFile = 'Delete Master File';

type DeleteMasterFileButtonProps = {
  masterFileID: number;
  confirmFunction: typeof useConfirmationModal.arguments.confirm;
};

const DeleteMasterFileButton: React.FC<DeleteMasterFileButtonProps> = ({
  masterFileID,
  confirmFunction,
}) => {
  const [masterFileToDeleteID, setMasterFileToDeleteID] =
    useCherreState(masterFileToDelete);
  const openedBatches = useCherreValue(
    getOpenedBatchesWithChartOfAccounts(masterFileToDeleteID || 0)
  );
  const hasOpenedBatches = openedBatches && openedBatches.length > 0;
  const deleteMappingSet = useDeleteMasterChartOfAccount(
    masterFileToDeleteID || 0,
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Master File Deleted',
      });
      setMasterFileToDeleteID(0);
    }
  );

  useEffect(() => {
    if (!masterFileToDeleteID) {
      // nothing to do
      return;
    }

    if (hasOpenedBatches) {
      confirmFunction({
        title: dialogTitleCloseBatches,
        content: (
          <div>
            <p>
              You must first close the following submission batches before it
              can be deleted:
            </p>
            <ul>
              {openedBatches.map((batch) => (
                <li key={batch.submission_id}>
                  <Link
                    target='_blank'
                    to={`${
                      BatchManagementRoute.routeDescriptor.path
                    }?${serialize({ search: batch.submission_name })}`}
                  >
                    {batch.submission_name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ),
        confirmLabel: 'OK',
        handleConfirm: () => setMasterFileToDeleteID(0),
        hideCancel: true,
      });
    } else {
      confirmFunction({
        title: dialogTitleDeleteMasterFile,
        content: (
          <div>
            <p>
              Deleting this Master Chart of Accounts file will remove it from
              all associated mappings and de-activate any properties that
              contain those mapping assignments
            </p>
          </div>
        ),
        confirmLabel: 'Delete',
        handleConfirm: () => {
          deleteMappingSet();
        },
        handleCancel: () => setMasterFileToDeleteID(0),
      });
    }
  }, [openedBatches]);

  return (
    <Button
      style={{ minWidth: 'unset' }}
      onClick={() => setMasterFileToDeleteID(masterFileID)}
    >
      <DeleteIcon
        sx={{
          display: 'flex',
        }}
        color='primary'
      />
    </Button>
  );
};

export default DeleteMasterFileButton;
