import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getBatchValidationRulesQuery } from './__generated__/getBatchValidationRulesQuery.graphql';

export const getBatchValidationRules = graphQLSelector({
  query: graphql`
    query getBatchValidationRulesQuery($datasets: [Int!]!) {
      sys_batch_validation_rules(
        where: {
          datasets_batch_validation_rules: { is_active: { _eq: true } }
          datasets_batch_validation_rules_aggregate: {
            count: {
              predicate: { _eq: 0 }
              filter: {
                dataset_id: { _nin: $datasets }
                is_active: { _eq: true }
              }
            }
          }
        }
        order_by: { validation_label: asc }
      ) {
        batch_validation_rule_id
        validation_label
        validation_slug
        validation_description
        datasets_batch_validation_rules {
          dataset_id
          dataset {
            dataset_label
          }
        }
      }
    }
  ` as GraphQLReturn<getBatchValidationRulesQuery>,
  mapResponse: (resp) => {
    return resp.sys_batch_validation_rules;
  },
});
