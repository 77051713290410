import { graphQLSelector, GraphQLReturn } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getProvidersAutocompleteQuery } from './__generated__/getProvidersAutocompleteQuery.graphql';

export const getProvidersAutocomplete = graphQLSelector({
  query: graphql`
    query getProvidersAutocompleteQuery {
      sys_providers(order_by: { provider_name: asc }) {
        provider_id
        provider_name
      }
    }
  ` as GraphQLReturn<getProvidersAutocompleteQuery>,
  mapResponse: (resp) => resp.sys_providers,
});
