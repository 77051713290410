/**
 * @generated SignedSource<<64529570ffbc6b132ff7702b06538695>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetFilterOptionsQuery$variables = {};
export type recoilGetFilterOptionsQuery$data = {
  readonly batch: ReadonlyArray<{
    readonly submission_id: number;
    readonly submission_name: string;
  }>;
  readonly fund: ReadonlyArray<{
    readonly fund: string | null;
  }>;
  readonly provider: ReadonlyArray<{
    readonly provider_id: number;
    readonly provider_name: string;
  }>;
  readonly stage: ReadonlyArray<{
    readonly property_batch_stage_description: string;
    readonly property_batch_stage_id: number;
  }>;
  readonly type: ReadonlyArray<{
    readonly submission_type: {
      readonly submission_type_id: number;
      readonly submission_type_label: string;
    };
  }>;
};
export type recoilGetFilterOptionsQuery = {
  response: recoilGetFilterOptionsQuery$data;
  variables: recoilGetFilterOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "is_closed": {
      "_eq": false
    }
  }
},
v1 = [
  {
    "kind": "Literal",
    "name": "distinct_on",
    "value": "submission_type_id"
  },
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_submission_types",
  "kind": "LinkedField",
  "name": "submission_type",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submission_type_label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submission_type_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "is_active": {
      "_eq": true
    }
  }
},
v4 = {
  "alias": "fund",
  "args": [
    {
      "kind": "Literal",
      "name": "distinct_on",
      "value": "fund"
    },
    (v3/*: any*/)
  ],
  "concreteType": "sys_properties",
  "kind": "LinkedField",
  "name": "sys_properties",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fund",
      "storageKey": null
    }
  ],
  "storageKey": "sys_properties(distinct_on:\"fund\",where:{\"is_active\":{\"_eq\":true}})"
},
v5 = {
  "alias": "provider",
  "args": [
    {
      "kind": "Literal",
      "name": "distinct_on",
      "value": "provider_id"
    },
    (v3/*: any*/)
  ],
  "concreteType": "sys_providers",
  "kind": "LinkedField",
  "name": "sys_providers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider_id",
      "storageKey": null
    }
  ],
  "storageKey": "sys_providers(distinct_on:\"provider_id\",where:{\"is_active\":{\"_eq\":true}})"
},
v6 = {
  "alias": "batch",
  "args": [
    {
      "kind": "Literal",
      "name": "distinct_on",
      "value": "submission_id"
    },
    (v0/*: any*/)
  ],
  "concreteType": "sys_submissions",
  "kind": "LinkedField",
  "name": "sys_submissions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submission_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submission_id",
      "storageKey": null
    }
  ],
  "storageKey": "sys_submissions(distinct_on:\"submission_id\",where:{\"is_closed\":{\"_eq\":false}})"
},
v7 = {
  "alias": "stage",
  "args": [
    {
      "kind": "Literal",
      "name": "distinct_on",
      "value": "property_batch_stage_id"
    }
  ],
  "concreteType": "sys_property_batch_stages",
  "kind": "LinkedField",
  "name": "sys_property_batch_stages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "property_batch_stage_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "property_batch_stage_description",
      "storageKey": null
    }
  ],
  "storageKey": "sys_property_batch_stages(distinct_on:\"property_batch_stage_id\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetFilterOptionsQuery",
    "selections": [
      {
        "alias": "type",
        "args": (v1/*: any*/),
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "sys_submissions",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "THROW",
            "path": "type.submission_type"
          }
        ],
        "storageKey": "sys_submissions(distinct_on:\"submission_type_id\",where:{\"is_closed\":{\"_eq\":false}})"
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "recoilGetFilterOptionsQuery",
    "selections": [
      {
        "alias": "type",
        "args": (v1/*: any*/),
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "sys_submissions",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": "sys_submissions(distinct_on:\"submission_type_id\",where:{\"is_closed\":{\"_eq\":false}})"
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fda193da3d9d0b451cf298cfda04d273",
    "id": null,
    "metadata": {},
    "name": "recoilGetFilterOptionsQuery",
    "operationKind": "query",
    "text": "query recoilGetFilterOptionsQuery {\n  type: sys_submissions(distinct_on: submission_type_id, where: {is_closed: {_eq: false}}) {\n    submission_type {\n      submission_type_label\n      submission_type_id\n    }\n  }\n  fund: sys_properties(distinct_on: fund, where: {is_active: {_eq: true}}) {\n    fund\n  }\n  provider: sys_providers(distinct_on: provider_id, where: {is_active: {_eq: true}}) {\n    provider_name\n    provider_id\n  }\n  batch: sys_submissions(distinct_on: submission_id, where: {is_closed: {_eq: false}}) {\n    submission_name\n    submission_id\n  }\n  stage: sys_property_batch_stages(distinct_on: property_batch_stage_id) {\n    property_batch_stage_id\n    property_batch_stage_description\n  }\n}\n"
  }
};
})();

(node as any).hash = "968a83aeb119c554dd6977ba623f75ff";

export default node;
