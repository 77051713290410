import { Container, Tooltip } from '@cherre-frontend/ui';
import React, { Fragment } from 'react';

export type Reading = { value: number; color: string; tooltipLabel: string };

type MultiColorProgressBarProps = {
  readings: Reading[];
};

const MultiColorProgressBar: React.FC<MultiColorProgressBarProps> = ({
  readings,
}) => {
  return (
    <Container
      style={{
        height: 8,
        display: 'flex',
        padding: 0,
        flex: 1,
        maxWidth: '120px',
        justifyContent: 'flex-end',
        margin: 0,
      }}
    >
      {readings.map((item, i) => {
        if (item.value > 0) {
          return (
            <Tooltip key={i} title={item.tooltipLabel}>
              <div
                style={{ backgroundColor: item.color, width: item.value + '%' }}
              />
            </Tooltip>
          );
        } else {
          return <Fragment key={i} />;
        }
      })}
    </Container>
  );
};

export default MultiColorProgressBar;
