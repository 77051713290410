/**
 * @generated SignedSource<<47d60072f964c1cdbb51bb9d0976f199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSakuraUsersInfoQuery$variables = {
  users: ReadonlyArray<number>;
};
export type getSakuraUsersInfoQuery$data = {
  readonly sakura_user: ReadonlyArray<{
    readonly first_name: string | null;
    readonly last_name: string | null;
    readonly user_id: number;
  }>;
};
export type getSakuraUsersInfoQuery = {
  response: getSakuraUsersInfoQuery$data;
  variables: getSakuraUsersInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "users"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": [
          {
            "first_name": "asc"
          },
          {
            "last_name": "asc"
          }
        ]
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "users"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sakura_user",
    "kind": "LinkedField",
    "name": "sakura_user",
    "plural": true,
    "selections": [
      {
        "alias": "user_id",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSakuraUsersInfoQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSakuraUsersInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b7d8d458e556f61de5007f7659428ed4",
    "id": null,
    "metadata": {},
    "name": "getSakuraUsersInfoQuery",
    "operationKind": "query",
    "text": "query getSakuraUsersInfoQuery(\n  $users: [Int!]!\n) {\n  sakura_user(where: {id: {_in: $users}}, order_by: [{first_name: asc}, {last_name: asc}]) {\n    user_id: id\n    first_name\n    last_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c1a99896bc3edcc893f4e09fb10906d";

export default node;
