import React from 'react';
import { Writeable } from '@cherre-frontend/data-fetching';
import { SubmissionBatchItem } from '../PropertyBatches/recoil';

export type PropertyBatch = Writeable<
  SubmissionBatchItem['property_batches'][number]
>;

export type InvestmentPropertyBatch =
  SubmissionBatchItem['investment_property_batches'][number];

export interface SinglePropertyBatchAccordionProps {
  propertyBatch: PropertyBatch;
  investmentPropertyBatch?: InvestmentPropertyBatch;
}

export interface GroupedPropertyBatchAccordionProps {
  propertyBatches: PropertyBatch[];
  investmentPropertyBatch: InvestmentPropertyBatch;
}

export type PropertyBatchAccordionProps =
  | SinglePropertyBatchAccordionProps
  | GroupedPropertyBatchAccordionProps;

export function isPropertyBatchGroup<
  S extends { propertyBatch: PropertyBatch },
  G extends { propertyBatches: PropertyBatch[] }
>(props: S | G): props is G {
  return 'propertyBatches' in props;
}

export const singleOrGrouped =
  <
    S extends { propertyBatch: PropertyBatch },
    G extends { propertyBatches: PropertyBatch[] }
  >(
    Single: React.FC<S>,
    Grouped: React.FC<G>
  ): React.FC<S | G> =>
  (props) => {
    if (isPropertyBatchGroup(props)) {
      return <Grouped {...props} />;
    } else {
      return <Single {...props} />;
    }
  };
