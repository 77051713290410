/**
 * @generated SignedSource<<b7531987af2e99b514dcffd6c5d236ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddEditMappingDialogEditMutation$variables = {
  custom_attributes?: any | null;
  id: number;
  source_code: string;
  source_description?: string | null;
  target_code?: string | null;
  target_description?: string | null;
};
export type AddEditMappingDialogEditMutation$data = {
  readonly update_sys_mapping_set_values_by_pk: {
    readonly mapping_set_value_id: number;
  } | null;
};
export type AddEditMappingDialogEditMutation = {
  response: AddEditMappingDialogEditMutation$data;
  variables: AddEditMappingDialogEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "custom_attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source_code"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source_description"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "target_code"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "target_description"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "custom_attributes",
            "variableName": "custom_attributes"
          },
          {
            "kind": "Variable",
            "name": "source_mapping_code",
            "variableName": "source_code"
          },
          {
            "kind": "Variable",
            "name": "source_mapping_label",
            "variableName": "source_description"
          },
          {
            "kind": "Variable",
            "name": "target_mapping_code",
            "variableName": "target_code"
          },
          {
            "kind": "Variable",
            "name": "target_mapping_label",
            "variableName": "target_description"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "mapping_set_value_id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "sys_mapping_set_values",
    "kind": "LinkedField",
    "name": "update_sys_mapping_set_values_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_value_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddEditMappingDialogEditMutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddEditMappingDialogEditMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "90550ee038a4bd524783f78042285672",
    "id": null,
    "metadata": {},
    "name": "AddEditMappingDialogEditMutation",
    "operationKind": "mutation",
    "text": "mutation AddEditMappingDialogEditMutation(\n  $id: Int!\n  $source_code: String!\n  $source_description: String\n  $target_code: String\n  $target_description: String\n  $custom_attributes: jsonb\n) {\n  update_sys_mapping_set_values_by_pk(pk_columns: {mapping_set_value_id: $id}, _set: {source_mapping_code: $source_code, source_mapping_label: $source_description, target_mapping_code: $target_code, target_mapping_label: $target_description, custom_attributes: $custom_attributes}) {\n    mapping_set_value_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "23e35bd7ddea7d614592876b0f6343df";

export default node;
