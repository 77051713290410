import {
  useCherreEventWithRecoil,
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  CloseIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import { useHistory } from 'react-router';
import {
  uploadMasterFileDialogOpenState,
  uploadMasterFileFormState,
  uploadMasterFileDialogStep,
  UploadMasterFileDialogStepValues,
  MasterFileTypes,
} from '../../recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const masterFileRequirements = {
  [MasterFileTypes.MASTER_COA]: ['GL Account', 'GL Account Description'],
  [MasterFileTypes.ACCOUNT_GROUPINGS]: [
    'Account Group Record Type',
    'Account Group Code Description',
    'Nest',
  ],
};

const UploadMasterFileDialogStep2: React.FC = () => {
  const newIngest = useFeatureFlag('DSPNewIngestionAdmin');
  const history = useHistory();
  const formState = useCherreValue(uploadMasterFileFormState);
  const setUploadMasterFileDialogOpenState = useCherreSetState(
    uploadMasterFileDialogOpenState
  );
  const setUploadMasterFileDialogStep = useCherreSetState(
    uploadMasterFileDialogStep
  );

  const onClose = useCherreEventWithRecoil(
    'user closed upload master files dialog',
    (ctx) => () => {
      ctx.recoil.set(uploadMasterFileDialogOpenState, false);
      ctx.recoil.set(uploadMasterFileFormState, { name: '', fileType: '' });
      ctx.recoil.set(
        uploadMasterFileDialogStep,
        UploadMasterFileDialogStepValues.STEP_1
      );
    }
  );

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Typography variant='h4' sx={{ paddingBottom: '12px' }}>
            {`Upload ${formState?.fileType}`}
          </Typography>
          <Typography variant='body1'>
            {
              'Upload your master files (e.g. Master Chart of Accounts, Account Groupings, and more) in Excel or CSV format to configure your setup of the Data Submission Portal.'
            }
          </Typography>
        </Grid>
        <Grid item xs='auto'>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box my={3}>
        <Typography variant='body1'>
          Please include the following fields in your file:
        </Typography>
        <ul>
          {formState &&
            masterFileRequirements[formState.fileType].map((name) => (
              <li>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography>{name} </Typography>
                  <Typography style={{ color: 'red', marginLeft: '2px' }}>
                    *
                  </Typography>
                </Box>
              </li>
            ))}
        </ul>
      </Box>

      <Grid container direction='row' justifyContent='flex-start'>
        <Grid item>
          <Button
            size='medium'
            variant='contained'
            color='primary'
            disabled={false}
            onClick={() => {
              setUploadMasterFileDialogOpenState(false);
              setUploadMasterFileDialogStep(
                UploadMasterFileDialogStepValues.STEP_1
              );
              history.push(
                formState?.fileType === MasterFileTypes.ACCOUNT_GROUPINGS
                  ? '/dsp/account-groupings/upload'
                  : `/dsp/master-files${newIngest ? '-new' : ''}/upload`
              );
            }}
          >
            Upload File
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadMasterFileDialogStep2;
