import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getSystemFlatfileTokenQuery } from './__generated__/getSystemFlatfileTokenQuery.graphql';

export const getSystemFlatfileToken = graphQLSelector({
  query: graphql`
    query getSystemFlatfileTokenQuery($slug: String!) {
      _sys_get_system_flatfile_token(slug: $slug) {
        api
        mount
        token
      }
    }
  ` as GraphQLReturn<getSystemFlatfileTokenQuery>,
  mapResponse: (resp) => resp._sys_get_system_flatfile_token,
});
