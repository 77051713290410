import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUpsertPropertyNewMutation } from './__generated__/useUpsertPropertyNewMutation.graphql';
import { addEditPropertyFormState } from '../recoil';

export const useUpsertProperty = (
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUpsertPropertyNewMutation(
        $input: _sys_property_create_or_update_input!
      ) {
        _sys_property_create_or_update(input: $input) {
          property_id
        }
      }
    ` as GraphQLReturn<useUpsertPropertyNewMutation>,
    {
      trackEvent: false,
      mapVariables:
        () =>
        async ({ getPromise }) => {
          const form = await getPromise(addEditPropertyFormState);

          const upsertParam = {
            address: form.address || '',
            fund: form.fund || '',
            provider: form.provider_id,
            source_entity_id: form?.entity_id || '',
            source_entity_name: form?.entity_name || '',
            target_entity_id: form?.property_code || '',
            target_entity_name: form?.property_name || '',
            type: form.type || '',
            property_id: form.property_id,
            custom_attributes: form.custom_attributes || {},
          };
          if (!upsertParam.property_id) {
            delete upsertParam.property_id;
          }
          return {
            input: upsertParam,
          };
        },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
