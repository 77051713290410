import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useWorkflowApplyActionToBatchMutation } from './__generated__/useWorkflowApplyActionToBatchMutation.graphql';

export const useWorkflowApplyActionToBatch = (
  property_batch_id: number,
  action: string
) => {
  return useMutation(
    graphql`
      mutation useWorkflowApplyActionToBatchMutation(
        $property_batch_id: Int!
        $action: String!
      ) {
        _sys_workflow_apply_action_to_batch(
          params: { property_batch_id: $property_batch_id, action: $action }
        ) {
          message
        }
      }
    ` as GraphQLReturn<useWorkflowApplyActionToBatchMutation>,
    {
      mapVariables: () => async () => ({ property_batch_id, action }),
      onCompleted: (data, ctx) => {
        ctx.showSnackbar({
          message: data._sys_workflow_apply_action_to_batch.message,
          type: 'success',
        });
      },
    }
  );
};
