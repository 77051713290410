import { useAppContext } from '@cherre-frontend/core';
import {
  GraphQLReturn,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  CheckIcon,
  CloseIcon,
  Container,
  FormEntry,
  Grid,
  InputAdornment,
  PageContainer,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import IconButton from '@material-ui/core/IconButton';
import grey from '@material-ui/core/colors/grey';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'react-relay';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getProviderOrganization } from '../provider-detail/queries/getProviderOrganization';
import { addProviderUserMutation } from './__generated__/addProviderUserMutation.graphql';
import {
  addNewProviderUserEmailFormState,
  addNewProviderUserNameFormState,
  newProviderUserEmailFormChecker,
  newProviderUserNameFormChecker,
} from './recoil';
import { HeaderGrid } from './styles';
import { formatter } from './utils';
import { useTheme } from '@material-ui/core';

export type ParamsProps = {
  provider_id: string;
};

export const ContentContainer = styled('div')`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 20px;
  padding: 30px;
  .MuiFormControl-root {
    padding-bottom: 30px;
  }
  .MuiButtonBase-root.MuiButton-contained {
    padding-left: 25px;
    padding-right: 25px;
  }

  .MuiButtonBase-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.grey[50]};
    background-color: rgb(223, 36, 103);
    opacity: 50%;
  }
`;

export const ProviderDetailPage: React.FC = () => {
  const theme = useTheme();
  const { provider_id } = useParams<ParamsProps>();

  const organization = useCherreValue(getProviderOrganization());

  const { logger } = useAppContext();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  logger.info('new batch partial info', formData);

  const checkResult = useMemo(() => {
    const nameChecker = newProviderUserNameFormChecker(formData);
    const emailChecker = newProviderUserEmailFormChecker(formData);
    return { nameChecker, emailChecker };
  }, [formData]);
  logger.info('validation result', checkResult);

  const tooltipInformation = useMemo(
    () =>
      formatter.tooltipMessage(
        checkResult.nameChecker.type === 'success'
          ? checkResult.emailChecker
          : checkResult.nameChecker
      ),
    [checkResult]
  );
  logger.info('tooltip information', tooltipInformation);

  const endpoint = `/api/v1/organizations/${organization}/users/`;
  const checkUserEmailExistsEndpoint = `/api/v1/organizations/users/email-exists`;

  let postedUser: any = { providerId: provider_id };

  const addProviderSysUser = useMutation(
    graphql`
      mutation addProviderUserMutation(
        $organization_id: Int!
        $provider_id: Int!
        $user_id: Int!
      ) {
        insert_sys_users(
          objects: {
            organization_id: $organization_id
            provider_id: $provider_id
            user_id: $user_id
          }
        ) {
          affected_rows
          returning {
            is_active
            organization_id
            provider_id
            user_id
            created_at_datetime
            updated_at_datetime
          }
        }
      }
    ` as GraphQLReturn<addProviderUserMutation>,
    {
      trackEvent: false,
      onCompleted: (result, ctx) => {
        ctx.showSnackbar({
          type: 'success',
          message: 'New Internal user added',
        });
        ctx.recoil.set(addNewProviderUserNameFormState, {
          first_name: '',
          last_name: '',
        });
        ctx.recoil.set(addNewProviderUserEmailFormState, {
          email: '',
        });
      },
      onError: (result, ctx) => {
        ctx.showSnackbar({
          type: 'error',
          message: `Problem adding user`,
        });
      },
    }
  );

  const addBaseUser = async (formData) => {
    const userData = {
      firstName: formData.first_name,
      lastName: formData.last_name,
      email: formData.email,
      role: 'dsp_regular',
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const json = await response.json();
    return json;
  };

  const checkUserEmailExits = async (email: string): Promise<boolean> => {
    const response = await fetch(
      `${checkUserEmailExistsEndpoint}?email=${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const json = await response.json();
    return json.exists;
  };

  const history = useHistory();

  const addUser = async () => {
    const base = await addBaseUser(formData);
    postedUser = { ...postedUser, ...base };
    await addProviderSysUser({
      provider_id: postedUser.providerId,
      user_id: postedUser.id,
      organization_id: postedUser.organizationId,
    });
  };

  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const [emailExistsErrorMessage, setEmailExistsErrorMessage] = useState('');

  const [inputAdornment, setInputAdornment] = useState<
    'unset' | 'valid' | 'error'
  >('unset');

  const handleCheckEmail = async (value: string) => {
    const emailExists = await checkUserEmailExits(value);
    setEmailAlreadyExists(emailExists);
    setInputAdornment(emailExists ? 'error' : 'valid');
    if (emailExists) {
      setEmailExistsErrorMessage('This email is already registered');
    } else {
      setEmailExistsErrorMessage('');
    }
  };

  const onSubmit = () => {
    addUser();
    history.push(`/dsp/providers/${provider_id}`);
  };

  useEffect(() => {
    if (checkResult.emailChecker.type === 'success') {
      handleCheckEmail(formData.email);
    } else if (
      checkResult.emailChecker.type === 'failure' &&
      formData.email !== ''
    ) {
      setInputAdornment('error');
    }
  }, [formData.email]);

  return (
    <PageContainer id='dsp/provider-detail-page/add-user'>
      <Container sx={{ width: '55%', margin: '0 auto 0 auto' }}>
        <HeaderGrid container direction='row' justifyContent='flex-start'>
          <IconButton
            style={{ height: '48px', alignSelf: 'center' }}
            component={Link}
            to={`/dsp/providers/${provider_id}`}
          >
            <ArrowBack style={{ fill: grey[900] }} />
          </IconButton>
          <h1>Add User</h1>
        </HeaderGrid>
        <ContentContainer>
          <Typography variant='body2' sx={{ paddingBottom: '30px' }}>
            Required fields marked with asterisk (*)
          </Typography>
          <Grid item sx={{ width: '63%', minWidth: '480px' }}>
            <FormEntry required title='First Name'>
              <TextField
                id='first-name'
                variant='outlined'
                size='small'
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    first_name: e.target.value,
                  });
                }}
              />
            </FormEntry>
            <FormEntry required title='Last Name'>
              <TextField
                id='last-name'
                variant='outlined'
                size='small'
                required
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    last_name: e.target.value,
                  });
                }}
              />
            </FormEntry>
            <FormEntry required title='Email'>
              <TextField
                id='email'
                variant='outlined'
                size='small'
                required
                sx={{
                  '.MuiFormHelperText-root': {
                    color: `#E53935 !important`,
                    margin: '9px 0 0 0',
                    fontFamily: { ...theme.typography.body2 },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: (() => {
                        switch (inputAdornment) {
                          case 'valid':
                            return `#43A047 !important`;
                          case 'error':
                            return `#E53935 !important`;
                          case 'unset':
                            return '';
                          default:
                            return '';
                        }
                      })(),
                    },
                    '&:hover fieldset, &:focus fieldset': {
                      borderColor: (() => {
                        switch (inputAdornment) {
                          case 'valid':
                            return `#43A047 !important`;
                          case 'error':
                            return `#E53935 !important`;
                          case 'unset':
                            return '';
                          default:
                            return '';
                        }
                      })(),
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {inputAdornment === 'error' && <CloseIcon />}
                      {inputAdornment === 'valid' && <CheckIcon />}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  if (e.target.value === '') {
                    setInputAdornment('unset');
                  }
                }}
                helperText={emailExistsErrorMessage}
              />
            </FormEntry>
          </Grid>
          <Tooltip title={tooltipInformation}>
            <div>
              <Button
                variant='contained'
                color='primary'
                disabled={
                  checkResult.nameChecker.type === 'failure' ||
                  checkResult.emailChecker.type === 'failure' ||
                  emailAlreadyExists
                }
                onClick={onSubmit}
              >
                Add
              </Button>
            </div>
          </Tooltip>
        </ContentContainer>
      </Container>
    </PageContainer>
  );
};

export default ProviderDetailPage;
