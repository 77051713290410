import React from 'react';
import { ReportIcon, styled } from '@cherre-frontend/ui';

const NoPropertiesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const NoPropertiesHeader = styled('h3')`
  font-size: 20px;
  margin: 6px 0;
  line-height: normal;
`;

const NoPropertiesContent = styled('p')`
  margin: 0;
  padding-bottom: 20px;
`;

export const NoProperties: React.FC = () => {
  return (
    <NoPropertiesContainer>
      <ReportIcon fontSize='large' color='disabled' />
      <NoPropertiesHeader>No Properties Yet</NoPropertiesHeader>
      <NoPropertiesContent>
        Upload your master property list below. Both 1:1 and many-to-one
        property mappings are supported.
      </NoPropertiesContent>
    </NoPropertiesContainer>
  );
};

NoProperties.displayName = 'NoBatches';
