import {
  useCherreState,
  useCherreValue,
  useIsSuspended,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  CheckCircleIcon,
  Grid,
  Link as MuiLink,
  Skeleton,
  TextField,
  Tooltip,
  styled,
} from '@cherre-frontend/ui';
import { Typography } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useClaims } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { $packageReports, ModalState } from '../recoil';
import NoContentMessage from './NoContentMessage';
import Reminder from './Reminder';
import {
  $submissionId,
  Reminder as ReminderType,
  getPackageReportResolvedReminders,
  getPackageReportUnresolvedReminders,
  packageReportSidebarCreateReminderMutation,
  packageReportSidebarReminderState,
  packageReportSidebarShowCompletedState,
  useMarkPackageReportRemindersReviewedMutation,
} from './recoil';

const Container = styled(Box)`
  width: 100%;
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ReminderList = styled(Box)`
  overflow-y: auto;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(1)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const ActiveRemindersCount = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

const ShowCompletedButton = styled(Button)`
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey[500] : theme.palette.primary.main};
`;

const SubmitButton = styled(CheckCircleIcon)`
  cursor: pointer;
  align-self: flex-end;
  font-size: 18px;
`;

export type RemindersTabProps = {
  modalState: ModalState;
};

export const RemindersTab: React.FC<RemindersTabProps> = ({ modalState }) => {
  const isAdmin = useClaims('view_admin');
  const isReviewer = useClaims('reviewer');

  const endRef = useRef<HTMLDivElement | null>(null);
  const textFieldRef = useRef<HTMLDivElement | null>(null);

  const isSuspended = useIsSuspended();

  const modalStateInfo = useCherreValue(modalState);

  const [showCompleted, setShowCompleted] = useCherreState(
    packageReportSidebarShowCompletedState(modalStateInfo?.report_slug)
  );

  const submissionId = useCherreValue($submissionId);

  const packageReports = useCherreValue(
    $packageReports(modalStateInfo?.property_batch_id)
  );

  const report = packageReports?.find(
    (r) => r.slug === modalStateInfo?.report_slug
  );

  const resolvedReminders = useCherreValue(
    getPackageReportResolvedReminders({
      package_report_id: report!.id,
      property_batch_id: modalStateInfo!.property_batch_id,
    })
  );

  const unresolvedReminders = useCherreValue(
    getPackageReportUnresolvedReminders({
      package_report_id: report!.id,
      property_batch_id: modalStateInfo!.property_batch_id,
    })
  );

  const markRemindersReviewed = useMarkPackageReportRemindersReviewedMutation();

  const [reminder, setReminder] = useCherreState(
    packageReportSidebarReminderState(modalStateInfo?.report_slug)
  );

  const createReminderMutation = useMutation(
    packageReportSidebarCreateReminderMutation,
    {
      mapVariables:
        () =>
        async ({ getPromise }) => {
          if (!report) {
            throw new Error('no report');
          }

          return {
            params: {
              text: reminder,
              author_id: (await getPromise(getDSPUser())).user_id,
              package_report_id: report.id,
              property_batch_id: modalStateInfo?.property_batch_id,
              property_id: modalStateInfo?.property_id,
              submission_id: submissionId,
            },
          };
        },
      onCompleted: (_, ctx) => {
        setReminder('');
        ctx.showSnackbar({
          message: 'Reminder has been added',
          type: 'success',
        });
      },
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Reminder failed to post. Please try again.',
          type: 'error',
        });
      },
    }
  );

  const onSubmit = () => {
    if (!reminder) {
      return;
    }

    createReminderMutation();
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSubmit();
    }
  };

  const [userScrolledToEnd, setUserScrolledToEnd] =
    React.useState<boolean>(false);

  const handleOnScroll = (e) => {
    const scrollDifference = e.target.scrollHeight - e.target.scrollTop;
    const bottom = scrollDifference - 20 < e.target.clientHeight;
    setUserScrolledToEnd(bottom);
  };

  useEffect(() => {
    endRef.current?.scrollIntoView(false);
  }, [endRef.current]);

  useEffect(() => {
    if (userScrolledToEnd) {
      endRef.current?.scrollIntoView(false);
    }
  }, [resolvedReminders, unresolvedReminders]);

  useEffect(() => {
    if (!textFieldRef.current || !endRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (userScrolledToEnd) {
        endRef.current?.scrollIntoView(false);
      }
    });
    resizeObserver.observe(textFieldRef.current);

    return () => resizeObserver.disconnect();
  }, [endRef.current, userScrolledToEnd]);

  useEffect(() => {
    if (!endRef.current) {
      return;
    }

    endRef.current?.scrollIntoView(false);

    const resizeObserver = new ResizeObserver(() => {
      endRef.current?.scrollIntoView(false);
    });
    resizeObserver.observe(endRef.current);

    return () => resizeObserver.disconnect();
  }, [endRef.current]);

  useEffect(() => {
    markRemindersReviewed(unresolvedReminders as ReminderType[]);
  }, [unresolvedReminders]);

  const activeReminderLabel = React.useMemo(() => {
    const resolvedCount = resolvedReminders?.length || 0;
    const unresolvedCount = unresolvedReminders?.length || 0;
    if (showCompleted) {
      const totalReminders = resolvedCount + unresolvedCount;
      return `${totalReminders} Reminder${totalReminders === 1 ? '' : 's'}`;
    }

    return `${
      unresolvedReminders && unresolvedReminders?.length === 0
        ? 'No'
        : unresolvedCount
    } Active Reminder${unresolvedCount === 1 ? '' : 's'}`;
  }, [unresolvedReminders, resolvedReminders, showCompleted]);

  if (isSuspended) {
    return (
      <Container>
        {Array(3)
          .fill(null)
          .map((_, idx) => {
            return (
              <div key={idx} style={{ display: 'grid', gap: 4 }}>
                <Skeleton variant='rectangular' width={'100%'} height={36} />
                <Skeleton variant='rectangular' width={'60%'} height={16} />
              </div>
            );
          })}
      </Container>
    );
  }

  const closeReminder = () => {
    if (!resolvedReminders) {
      return;
    }
    if (resolvedReminders.length - 1 === 0) {
      setShowCompleted(false);
    }
  };

  return (
    <Container>
      <Grid display='flex' alignItems='center' justifyContent='space-between'>
        <ActiveRemindersCount>{activeReminderLabel}</ActiveRemindersCount>
        {resolvedReminders && resolvedReminders.length > 0 ? (
          <ShowCompletedButton
            as={MuiLink}
            disabled={!resolvedReminders?.length}
            onClick={() => {
              if (resolvedReminders && resolvedReminders.length) {
                setShowCompleted(!showCompleted);
              }
            }}
          >
            {showCompleted && resolvedReminders && resolvedReminders.length > 0
              ? 'Hide'
              : 'Show all'}
          </ShowCompletedButton>
        ) : null}
      </Grid>
      <ReminderList onScroll={handleOnScroll}>
        {unresolvedReminders?.length || resolvedReminders?.length ? (
          <>
            {unresolvedReminders?.map((reminder, idx) => {
              return (
                <Reminder
                  key={reminder.reminder_id}
                  reminder={reminder}
                  closeReminder={closeReminder}
                  showBottomBorder={
                    idx !== unresolvedReminders.length - 1 || showCompleted
                  }
                />
              );
            })}
            {showCompleted && resolvedReminders?.length
              ? resolvedReminders?.map((reminder, idx) => {
                  return (
                    <Reminder
                      key={reminder.reminder_id}
                      reminder={reminder}
                      closeReminder={closeReminder}
                      showBottomBorder={idx !== resolvedReminders.length - 1}
                    />
                  );
                })
              : null}
          </>
        ) : (
          <NoContentMessage body='No reminders yet' />
        )}
        <div ref={endRef} />
      </ReminderList>

      {isAdmin || isReviewer ? (
        <TextField
          ref={textFieldRef}
          onKeyDown={onEnterPress}
          multiline
          minRows={2}
          placeholder={'Add your reminder'}
          value={reminder || ''}
          onChange={(e) => setReminder(e.target.value)}
          autoFocus
          InputProps={{
            sx: {
              padding: 1,
            },
            endAdornment: (
              <Tooltip title={reminder ? 'Send Now' : ''}>
                <SubmitButton
                  color={reminder ? 'primary' : 'disabled'}
                  onClick={onSubmit}
                />
              </Tooltip>
            ),
          }}
        />
      ) : null}
    </Container>
  );
};
