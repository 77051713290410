import {
  GraphQLReturn,
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Button,
  Grid,
  MRT_RowSelectionState,
  Typography,
  TypographyProps,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { graphql } from 'react-relay';
import {
  Stage,
  assignDatasetsToPropertyIdState,
  dialogStage,
  enabledBatchValidationsState,
  enabledValidationsState,
  selectedDatasetIdsState,
  selectedDatasetsState,
  datasetTemplateIdState,
} from '../../localState';
import { getBatchValidationRules } from '../../queries/getBatchValidationRules';
import { DialogHeader } from '../DialogHeader';
import ValidationRuleSelection from '../ValidationRuleSelection';
import {
  SelectCrossFileValidationsMutation,
  sys_properties_batch_validation_rules_insert_input,
  sys_properties_dataset_validation_rules_insert_input,
  sys_properties_datasets_insert_input,
} from './__generated__/SelectCrossFileValidationsMutation.graphql';
import { getInvestimentsProperties } from '../../queries/getInvestimentsProperties';

const ValidationGroupTitle = styled<React.FC<TypographyProps>>(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
`;

type SelectCrossFileValidationsProps = {
  numRowsSelected: number;
  rowSelection: MRT_RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  onClose: () => void;
};

const SelectCrossFileValidations: React.FC<SelectCrossFileValidationsProps> = ({
  numRowsSelected,
  rowSelection,
  setRowSelection,
  onClose,
}) => {
  const datasets = useCherreValue(selectedDatasetsState);
  const datasetTemplates = useCherreValue(datasetTemplateIdState);
  const datasetIds = useCherreValue(selectedDatasetIdsState);
  const enabledValidations = useCherreValue(enabledValidationsState);
  const [enabledBatchValidations, setEnabledBatchValidations] = useCherreState(
    enabledBatchValidationsState
  );
  const assignDatasetsToPropertyId = useCherreValue(
    assignDatasetsToPropertyIdState
  );

  const batchValidations = useCherreValue(
    getBatchValidationRules({
      datasets: datasetIds as number[],
    })
  );

  const batchValidationGroups = useMemo(() => {
    if (!batchValidations) {
      return {};
    }

    const groups = {};
    batchValidations.forEach((validationRule) => {
      // find the combined name of the datasets
      const ruleGroupTitle = validationRule.datasets_batch_validation_rules
        .map((datasetRule) => {
          return datasetRule.dataset.dataset_label;
        })
        .join(' X ')
        .toUpperCase();

      if (Object.keys(groups).includes(ruleGroupTitle)) {
        groups[ruleGroupTitle].push(validationRule);
      } else {
        groups[ruleGroupTitle] = [validationRule];
      }
    });

    return groups;
  }, [batchValidations]);

  const onPrev = useCherreEventWithRecoil(
    'user went from select cross file validations to select validations',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectValidations)
  );

  const onEnabledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (e.target.checked) {
      setEnabledBatchValidations({
        ...enabledBatchValidations,
        [id]: 'soft',
      });
    } else {
      const currentValidations = { ...enabledBatchValidations };
      delete currentValidations[id];
      setEnabledBatchValidations(currentValidations);
    }
  };

  const onValidationModeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setEnabledBatchValidations({
      ...enabledBatchValidations,
      [id]: e.target.value as 'soft' | 'hard',
    });
  };

  const onSubmit = useMutation(
    graphql`
      mutation SelectCrossFileValidationsMutation(
        $property_ids: [Int!]!
        $datasetValidationObjects: [sys_properties_dataset_validation_rules_insert_input!]!
        $batchValidationObjects: [sys_properties_batch_validation_rules_insert_input!]!
        $datasetsObjects: [sys_properties_datasets_insert_input!]!
        $investimentsPropertiesIds: [Int!]!
        $investimentsDatasetValidationObjects: [sys_properties_dataset_validation_rules_insert_input!]!
      ) {
        update_sys_properties_dataset_validation_rules(
          where: {
            _or: [
              { property_id: { _in: $property_ids } }
              { property_id: { _in: $investimentsPropertiesIds } }
            ]
          }
          _set: { is_active: false }
        ) {
          affected_rows
        }

        update_sys_properties_batch_validation_rules(
          where: { property_id: { _in: $property_ids } }
          _set: { is_active: false }
        ) {
          affected_rows
        }

        update_sys_properties_datasets(
          where: {
            _or: [
              { property_id: { _in: $property_ids } }
              { property_id: { _in: $investimentsPropertiesIds } }
            ]
          }
          _set: { is_active: false }
        ) {
          affected_rows
        }

        insert_sys_properties_datasets(objects: $datasetsObjects) {
          affected_rows
        }

        insert_sys_properties_batch_validation_rules(
          objects: $batchValidationObjects
        ) {
          affected_rows
        }

        insert_sys_properties_dataset_validation_rules(
          objects: $datasetValidationObjects
        ) {
          affected_rows
        }

        investiments_validations: insert_sys_properties_dataset_validation_rules(
          objects: $investimentsDatasetValidationObjects
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<SelectCrossFileValidationsMutation>,
    {
      trackEvent: false,
      mapVariables:
        () =>
        async ({ getPromise }) => {
          const selectedPropertyIds = (
            assignDatasetsToPropertyId
              ? [assignDatasetsToPropertyId]
              : Object.keys(rowSelection)
          ).map((id) => parseInt(id));

          const investimentsPropertiesIds = await getPromise(
            getInvestimentsProperties({
              property_ids: selectedPropertyIds,
            })
          );

          const datasetValidationObjects: sys_properties_dataset_validation_rules_insert_input[] =
            [];

          const investimentsDatasetValidationObjects: sys_properties_dataset_validation_rules_insert_input[] =
            [];

          const batchValidationObjects: sys_properties_batch_validation_rules_insert_input[] =
            [];

          const datasetsObjects: sys_properties_datasets_insert_input[] = [];

          if (!enabledValidations || !enabledBatchValidations || !datasetIds) {
            throw new Error('Missing required data');
          }

          investimentsPropertiesIds.forEach((propertyId) => {
            Object.keys(enabledValidations.tsa).forEach((validationId) => {
              investimentsDatasetValidationObjects.push({
                property_id: propertyId,
                dataset_validation_rule_id: parseInt(validationId),
                validation_mode: enabledValidations.tsa[validationId],
              });
            });

            const propertyDatasets = datasetIds.map((datasetId) => ({
              property_id: propertyId,
              dataset_id: datasetId,
              template_id: datasetTemplates?.[datasetId],
            }));

            datasetsObjects.push(...propertyDatasets);
          });

          selectedPropertyIds.forEach((propertyId) => {
            Object.keys(enabledValidations.standard).forEach((validationId) => {
              datasetValidationObjects.push({
                property_id: propertyId,
                dataset_validation_rule_id: parseInt(validationId),
                validation_mode: enabledValidations.standard[validationId],
              });
            });

            Object.keys(enabledBatchValidations).forEach((validationId) => {
              batchValidationObjects.push({
                property_id: propertyId,
                batch_validation_rule_id: parseInt(validationId),
                validation_mode: enabledBatchValidations[validationId],
              });
            });

            const propertyDatasets = datasetIds.map((datasetId) => ({
              property_id: propertyId,
              dataset_id: datasetId,
              template_id: datasetTemplates?.[datasetId],
            }));

            datasetsObjects.push(...propertyDatasets);
          });

          return {
            property_ids: selectedPropertyIds,
            datasetValidationObjects,
            batchValidationObjects,
            datasetsObjects,
            investimentsPropertiesIds,
            investimentsDatasetValidationObjects,
          };
        },
      onCompleted: (result, ctx) => {
        onClose();
        ctx.showSnackbar({
          type: 'success',
          message: `${datasets?.length} Datasets Assigned to ${
            assignDatasetsToPropertyId ? '1' : numRowsSelected
          } Properties`,
        });
        setRowSelection({});
      },
      onError: (result, ctx) => {
        console.log('error result', result);
        console.log('error ctx', ctx);
      },
    }
  );

  return (
    <>
      <DialogHeader
        title='Select Cross-Datasets Validations'
        subtitle={`${numRowsSelected} Properties Selected • ${
          datasets?.length
        } Datasets • ${
          Object.keys(enabledValidations || {}).length
        } Validations`}
      />
      <Typography style={{ paddingTop: '24px' }}>
        Select cross-dataset validations and hard or soft check specifications
        before selecting Done.
      </Typography>
      <Box my={3}>
        <Grid container gap={3}>
          {Object.keys(batchValidationGroups).length ? (
            Object.keys(batchValidationGroups)?.map((groupTitle) => (
              <Grid item>
                <ValidationGroupTitle variant='h6'>
                  {groupTitle}
                </ValidationGroupTitle>

                <Grid container gap={1}>
                  {batchValidationGroups?.[groupTitle]?.map((rule) => (
                    <ValidationRuleSelection
                      id={rule.batch_validation_rule_id}
                      title={rule.validation_label}
                      description={rule.validation_description || ''}
                      slug={rule.validation_slug}
                      onEnabledChange={(e) => {
                        onEnabledChange(e, rule.batch_validation_rule_id);
                      }}
                      onValidationModeChange={(e) => {
                        onValidationModeChange(
                          e,
                          rule.batch_validation_rule_id
                        );
                      }}
                      enabled={
                        !!enabledBatchValidations &&
                        Object.keys(enabledBatchValidations).includes(
                          rule.batch_validation_rule_id.toString()
                        )
                      }
                      value={
                        enabledBatchValidations?.[
                          rule.batch_validation_rule_id
                        ] || 'soft'
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant='body1'>
              No Cross-Dataset Validations Available
            </Typography>
          )}
        </Grid>
      </Box>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='nowrap'
      >
        <Grid item>
          <Button
            size='medium'
            variant='outlined'
            color='primary'
            onClick={onPrev}
            sx={{
              border: 'none',
            }}
          >
            Prev
          </Button>
        </Grid>

        <>
          <Grid
            item
            container
            direction='row'
            gap={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <Button
                size='medium'
                variant='outlined'
                color='primary'
                onClick={onClose}
                sx={{
                  border: 'none',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size='medium'
                variant='contained'
                color='primary'
                //   disabled={!mappingIdToAssign}
                onClick={onSubmit}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </>
      </Grid>
    </>
  );
};

SelectCrossFileValidations.displayName = 'SelectCrossFileValidations';

export default SelectCrossFileValidations;
