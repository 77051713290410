import { graphQLSelector, GraphQLReturn } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { getOrganizationProvidersQuery } from './__generated__/getOrganizationProvidersQuery.graphql';

export const getOrganizationProviders = graphQLSelector({
  query: graphql`
    query getOrganizationProvidersQuery($org_id: Int!) {
      sys_providers(
        where: { organization_id: { _eq: $org_id } }
        order_by: { provider_name: asc }
      ) {
        provider_id
        provider_name
      }
    }
  ` as GraphQLReturn<getOrganizationProvidersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const org_id = get(getDSPUser()).organization_id;
      return { org_id };
    },
  mapResponse: (resp) => resp.sys_providers,
});
