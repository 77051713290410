/**
 * @generated SignedSource<<093db5a9ac25dc284f1ae15de847e42a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getMasterChartOfAccountsListSubscription$variables = {
  search?: string | null;
};
export type getMasterChartOfAccountsListSubscription$data = {
  readonly sys_organization_master_chart_of_accounts: ReadonlyArray<{
    readonly chart_of_accounts_description: string | null;
    readonly chart_of_accounts_name: string;
    readonly master_chart_of_account_id: number;
    readonly organization: {
      readonly organization_label: string;
    };
    readonly updated_at_datetime: string;
  }>;
};
export type getMasterChartOfAccountsListSubscription = {
  response: getMasterChartOfAccountsListSubscription$data;
  variables: getMasterChartOfAccountsListSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "%",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_ilike",
                        "variableName": "search"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "chart_of_accounts_name"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.0"
              },
              {
                "kind": "Literal",
                "name": "_and.1",
                "value": {
                  "is_active": {
                    "_eq": true
                  }
                }
              }
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organization_master_chart_of_accounts",
    "kind": "LinkedField",
    "name": "sys_organization_master_chart_of_accounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "master_chart_of_account_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chart_of_accounts_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chart_of_accounts_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updated_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organizations",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization_label",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getMasterChartOfAccountsListSubscription",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getMasterChartOfAccountsListSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "12a15e7a8d0493dcbe5d3e5ce8a1e8a8",
    "id": null,
    "metadata": {},
    "name": "getMasterChartOfAccountsListSubscription",
    "operationKind": "subscription",
    "text": "subscription getMasterChartOfAccountsListSubscription(\n  $search: String = \"%\"\n) {\n  sys_organization_master_chart_of_accounts(where: {_and: [{chart_of_accounts_name: {_ilike: $search}}, {is_active: {_eq: true}}]}) {\n    master_chart_of_account_id\n    chart_of_accounts_name\n    chart_of_accounts_description\n    updated_at_datetime\n    organization {\n      organization_label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84b54ab43057d1b4b8e234060076c175";

export default node;
