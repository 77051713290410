import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { masterFilesTableSearch } from '../recoil';
import { getMasterChartOfAccountsListSubscription } from './__generated__/getMasterChartOfAccountsListSubscription.graphql';

export const getMasterChartOfAccountsList = graphQLSelector({
  query: graphql`
    subscription getMasterChartOfAccountsListSubscription(
      $search: String = "%"
    ) {
      sys_organization_master_chart_of_accounts(
        where: {
          _and: [
            { chart_of_accounts_name: { _ilike: $search } }
            { is_active: { _eq: true } }
          ]
        }
      ) {
        master_chart_of_account_id
        chart_of_accounts_name
        chart_of_accounts_description
        updated_at_datetime
        organization {
          organization_label
        }
      }
    }
  ` as GraphQLReturn<getMasterChartOfAccountsListSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(masterFilesTableSearch);
      return {
        search: `%${search ?? ''}%`,
      };
    },
  mapResponse: (resp) => resp.sys_organization_master_chart_of_accounts,
});
