import {
  serialize,
  useCherreEventWithRecoil,
} from '@cherre-frontend/data-fetching';
import { LockIcon, MenuItem, useConfirmationModal } from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';
import React from 'react';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import { getPropertyOpenedBatches } from '../queries/getPropertyOpenedBatches';
import { useDisableProperty } from '../hooks/useDisableProperty';

export const dialogTitleCloseBatches = 'Close Batches';
export const dialogTitleDisableProperty = 'Disable Property';

type DisablePropertyActionMenuItemProps = {
  propertyId: number;
  propertyCode: string;
  confirmFunction: typeof useConfirmationModal.arguments.confirm;
};

export const DisablePropertyActionMenuItem: React.FC<
  DisablePropertyActionMenuItemProps
> = ({ propertyId, propertyCode, confirmFunction }) => {
  const disableProperty = useDisableProperty(propertyId, (value, ctx) => {
    ctx.showSnackbar({
      type: 'success',
      message: 'Property successfully disabled',
    });
  });

  const onDisableClick = useCherreEventWithRecoil(
    'use requested disable property',
    (ctx) => async () => {
      // check openend batches
      const batches = await ctx.recoil.getPromise(
        getPropertyOpenedBatches(propertyId)
      );
      if (batches.length > 0) {
        // if opened batches, show close batches dialog
        confirmFunction({
          title: dialogTitleCloseBatches,
          content: (
            <div>
              <p>
                You must first close the following submission batches that
                include Entity ID {propertyCode}:
              </p>
              <ul>
                {batches.map((batch) => (
                  <li key={batch.submission_id}>
                    <Link
                      target='_blank'
                      to={`${
                        BatchManagementRoute.routeDescriptor.path
                      }?${serialize({ search: batch.submission_name })}`}
                    >
                      {batch.submission_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ),
          confirmLabel: 'OK',
          hideCancel: true,
        });
      } else {
        // if no opened batches, show confirmation dialog
        confirmFunction({
          title: dialogTitleDisableProperty,
          content: (
            <div>
              <p>
                Disabled property will be de-activated and unavailable for use
                in submission batches. All user, mapping, dataset, and
                validation assignments to the property will be removed.
              </p>
            </div>
          ),
          confirmLabel: 'Disable',
          handleConfirm: () => {
            disableProperty();
          },
        });
      }
    }
  );
  return (
    <MenuItem key='Disable' onClick={onDisableClick}>
      <LockIcon sx={{ height: 16 }} /> Disable
    </MenuItem>
  );
};

DisablePropertyActionMenuItem.displayName = 'DisablePropertyActionMenuItem';
