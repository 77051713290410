import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';
import { graphql } from 'react-relay';
import { recoilgetSubmissionUsersQuery } from './__generated__/recoilgetSubmissionUsersQuery.graphql';

export const getSubmissionUsers = graphQLSelector({
  query: graphql`
    query recoilgetSubmissionUsersQuery($property_batch_id: Int = 719) {
      sys_property_batches_by_pk(property_batch_id: $property_batch_id) {
        users_with_access_to_batch(
          order_by: {
            user: { sakura_user: { first_name: asc, last_name: asc } }
          }
        ) {
          property_role_user_id
          property_role_id
          user {
            sakura_user {
              email
              first_name
              last_name
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilgetSubmissionUsersQuery>,
  mapVariables: (propertyBatchId?: number) => () => {
    if (!propertyBatchId) {
      throw new Error('propertyBatchId is required');
    }
    return {
      property_batch_id: propertyBatchId,
    };
  },
  mapResponse: (response) => {
    const users =
      response?.sys_property_batches_by_pk?.users_with_access_to_batch;
    if (!users) {
      return null;
    }
    return {
      preparers: users.filter(
        (user) => user.property_role_id === PropertyRole.PREPARER.id
      ),
      reviewers_1: users.filter(
        (user) =>
          user.property_role_id === PropertyRole.REVIEWER_1.id ||
          user.property_role_id === PropertyRole.APPROVER_PLUS_1.id
      ),
      reviewers_2: users.filter(
        (user) =>
          user.property_role_id === PropertyRole.REVIEWER_2.id ||
          user.property_role_id === PropertyRole.APPROVER_PLUS_2.id
      ),
      reviewers_3: users.filter(
        (user) =>
          user.property_role_id === PropertyRole.REVIEWER_3.id ||
          user.property_role_id === PropertyRole.APPROVER_PLUS_3.id
      ),
    };
  },
});
