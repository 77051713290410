import { styled, Container, Chip, Box } from '@cherre-frontend/ui';

type DueDateSpanProps = {
  isDue?: boolean;
};

export const BatchContainer = styled(Container)`
  display: flex;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  max-width: 100% !important;
  padding-right: 0 !important;
  padding-left: 10px !important;
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    cursor: pointer;
    transition: all 0.5s;
  }
`;

export const ContentContainer = styled(Container)`
  display: flex;
  gap: 10px;
  padding: 0;
  flex: 3;
  align-items: center;
  padding: 0 !important;
`;
export const DatesContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`;

export const ProgressContainer = styled(Container)`
  display: flex;
  padding: 0;
  align-items: center;
  flex: 2;
  gap: 10px;
  justify-content: flex-end;
`;

export const StyledChip = styled(Chip)`
  background: #43a047;
  color: #fff;
`;

export const DueDateSpan = styled('span')<DueDateSpanProps>`
  color: ${({ isDue }) => (isDue ? '#FF5703' : 'inherit')};
`;

export const CommentContainer = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
