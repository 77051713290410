import { MRT_SortingState } from 'material-react-table';
import { PropertyBatchStage } from '../../__generated__/constants';

export const getStageLabelFromId = (property_batch_stage_id: number) => {
  switch (property_batch_stage_id) {
    case PropertyBatchStage.REVIEW_1.id:
      return 'L1 Reviewer';
    case PropertyBatchStage.REVIEW_2.id:
      return 'L2 Reviewer';
    case PropertyBatchStage.REVIEW_3.id:
      return 'L3 Reviewer';
    case PropertyBatchStage.APPROVED.id:
      return 'Approved';
    default:
      return 'Preparer';
  }
};

export const mrtToHasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const nested = sort.id.split('.');
      const ret = {};
      let pointer = ret;

      nested.map((k, i) => {
        pointer = pointer[k] =
          i === nested.length - 1 ? (sort.desc ? 'desc' : 'asc') : {};
      });

      return ret;
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function checkFilterValueIsEmpty(value) {
  return Array.isArray(value) ? value.length === 0 : !value;
}
