/**
 * @generated SignedSource<<5ae1ea85615705d72e134911f0d7b859>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteAccountTreeMutation$variables = {
  account_tree_id: number;
};
export type useDeleteAccountTreeMutation$data = {
  readonly delete_sys_organization_account_tree_details: {
    readonly affected_rows: number;
  } | null;
  readonly delete_sys_organization_account_trees: {
    readonly affected_rows: number;
  } | null;
};
export type useDeleteAccountTreeMutation = {
  response: useDeleteAccountTreeMutation$data;
  variables: useDeleteAccountTreeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account_tree_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "account_tree_id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "account_tree_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organization_account_tree_details_mutation_response",
    "kind": "LinkedField",
    "name": "delete_sys_organization_account_tree_details",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "organization_account_tree_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organization_account_trees_mutation_response",
    "kind": "LinkedField",
    "name": "delete_sys_organization_account_trees",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteAccountTreeMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteAccountTreeMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4e83a89a22728adf6fc77cfda3b79bab",
    "id": null,
    "metadata": {},
    "name": "useDeleteAccountTreeMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteAccountTreeMutation(\n  $account_tree_id: Int!\n) {\n  delete_sys_organization_account_tree_details(where: {account_tree_id: {_eq: $account_tree_id}}) {\n    affected_rows\n  }\n  delete_sys_organization_account_trees(where: {organization_account_tree_id: {_eq: $account_tree_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c4777ae9477d84383aee7c1fcf351d6";

export default node;
