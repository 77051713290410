import { MRT_SortingState } from 'material-react-table';

export const createEnum = <T extends ReadonlyArray<string>>(
  options: T
): { [K in T[number]]: K } => {
  return Object.fromEntries(options.map((v) => [v, v])) as any;
};

export const mrtToHasuraSorting = (sorting: MRT_SortingState) => {
  return sorting.map((sort) => {
    if (sort.id === 'properties_datasets_aggregate.aggregate.count') {
      return {
        properties_datasets_aggregate: {
          count: sort.desc ? ('desc' as const) : ('asc' as const),
        },
      };
    }
    return { [sort.id]: sort.desc ? ('desc' as const) : ('asc' as const) };
  });
};
