import {
  CheckerReturnType,
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
  writableDict,
} from '@recoiljs/refine';
import {
  DefaultValue,
  GraphQLReturn,
  NotFoundError,
  RecoilState,
  Writeable,
  graphQLSelector,
  refine,
  selectorFamily,
  selector,
  CherreState,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilGetReportMetadataQuery } from './__generated__/recoilGetReportMetadataQuery.graphql';

type RawTableConfig = Writeable<
  NonNullable<
    recoilGetReportMetadataQuery['response']['_sys_get_report_metadata']
  >['tables'][0]
>;

export type Column = Omit<RawTableConfig['columns'][0], 'columns'> & {
  columns?: Column[] | null;
};

export type TableConfig = Omit<RawTableConfig, 'columns'> & {
  columns: Column[];
};

export const getReportMetadata = graphQLSelector({
  query: graphql`
    query recoilGetReportMetadataQuery($params: report_metadata_input!) {
      _sys_get_report_metadata(params: $params) {
        pagination
        tables {
          group_values
          table_slug
          hide_header
          data_source_object_type
          initial_sorting {
            desc
            id
          }
          groupings {
            keys
            aggregations
          }
          columns {
            accessorKey
            format
            searchable
            sortable
            style
            type
            visible
            align
            fixedSize
            ratioSize
            dynamicColumn
            valuesField
            columnsField
            frozen
            header {
              label
              align
            }
            aggregation {
              value
              align
            }
            columns {
              accessorKey
              format
              searchable
              sortable
              style
              type
              visible
              align
              fixedSize
              ratioSize
              dynamicColumn
              valuesField
              columnsField
              frozen
              header {
                label
                align
              }
              aggregation {
                value
                align
              }
              columns {
                accessorKey
                format
                searchable
                sortable
                style
                type
                visible
                align
                fixedSize
                ratioSize
                dynamicColumn
                valuesField
                columnsField
                frozen
                header {
                  label
                  align
                }
                aggregation {
                  value
                  align
                }
              }
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetReportMetadataQuery>,
  mapResponse: (rawResponse, { params: { report_slug } }) => {
    const metadata = rawResponse._sys_get_report_metadata;
    if (!metadata) {
      throw new NotFoundError(`report for slug ${report_slug} not found`);
    }
    return metadata;
  },
  swr: false,
  resetCache: false,
});

export const ReportTableStateRefine = withDefault(
  object({
    pagination: withDefault(
      object({
        pageSize: refine.allowedNumbers([25, 50, 100]),
        pageIndex: number(),
      }),
      { pageSize: 25, pageIndex: 0 }
    ),
    sorting: writableDict(
      withDefault(writableArray(object({ id: string(), desc: bool() })), [])
    ),
  }),
  {
    pagination: { pageSize: 25, pageIndex: 0 },
    sorting: {},
  }
);

export type ReportTableStateParams = {
  report_slug: string;
  table_slug: string;
};

export const createReportTableStateSelectors = (
  routeModalStateSelector: CherreState<string>,
  routeTableStateSelector: RecoilState<
    CheckerReturnType<typeof ReportTableStateRefine>
  >,
  searchState: RecoilState<string>
) => {
  const key = `${routeModalStateSelector.key}/${routeTableStateSelector.key}/${searchState.key}`;

  const paginationState = selector({
    key: `report-table-state-pagination-selector/${key}`,
    get: ({ get }) =>
      get(routeTableStateSelector).pagination || {
        pageSize: 25,
        pageIndex: 0,
      },
    set: ({ set }, newValue) => {
      return set(routeTableStateSelector, (old) => {
        if (newValue instanceof DefaultValue) {
          return {
            ...old,
            pagination: { pageSize: 25, pageIndex: 0 },
          };
        }
        return { ...old, pagination: newValue };
      });
    },
  });

  const sortingState = selectorFamily({
    key: `report-table-state-sorting-selector/${key}`,
    scoped: true,
    get:
      ({ table_slug, initial_sorting }: TableConfig) =>
      ({ get }) => {
        const state = get(routeTableStateSelector);

        if (!state.sorting[table_slug]) {
          return initial_sorting ?? [];
        }

        return state.sorting[table_slug];
      },
    set:
      ({ table_slug }: TableConfig) =>
      () =>
      ({ set }, newValue) => {
        return set(routeTableStateSelector, (old) => {
          if (newValue instanceof DefaultValue) {
            return {
              ...old,
              w: Object.fromEntries(
                Object.entries(old.sorting).filter(([k]) => k !== table_slug)
              ),
            };
          }
          return {
            ...old,
            sorting: { ...old.sorting, [table_slug]: newValue },
          };
        });
      },
  });

  return { searchState, paginationState, sortingState };
};
