import {
  DefaultValue,
  atom,
  hasDependants,
  selector,
} from '@cherre-frontend/data-fetching';
import { Stage, namespace, stages } from '../consts';
import { CreateBatchInfo } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/schema';

export const newBatchInfo = atom<Partial<CreateBatchInfo> | null>({
  key: namespace('new-batch-info'),
  default: null,
  effects: [hasDependants()],
});

const dialogStage_INTERNAL = atom<Stage>({
  key: namespace('dialog-stage-internal'),
  default: stages.DefineBatchDetails,
});

export const dialogStage = selector<Stage>({
  key: namespace('dialog-stage'),
  get: ({ get }) => {
    const internalState = get(dialogStage_INTERNAL);
    if (internalState === stages.DefineBatchDetails) {
      return stages.DefineBatchDetails;
    }
    //if we are in property selection, we need to verify the required data is valid
    if (internalState === stages.SelectProperties) {
      const data = get(newBatchInfo);
      if (!data) {
        //if data is invalid we send the user back to the first stage
        return stages.DefineBatchDetails;
      }
      //if everything checks out user can select properties
      return stages.SelectProperties;
    }
    //unrecognized internal state
    throw new Error(`${internalState} is not a valid modal stage`);
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      //stage is being reset
      set(dialogStage_INTERNAL, stages.DefineBatchDetails);
      return;
    }
    if (newValue === stages.DefineBatchDetails) {
      //if user wants to navigate to batch details he can do it freely
      set(dialogStage_INTERNAL, stages.DefineBatchDetails);
      return;
    }
    if (newValue === stages.SelectProperties) {
      //if user wants to select properties we need to verify that the required data is valid
      const data = get(newBatchInfo);
      if (!data) {
        //if data is invalid we force user to go to batch details
        set(dialogStage_INTERNAL, stages.DefineBatchDetails);
        return;
      }
      //if everything checks out user can select properties
      set(dialogStage_INTERNAL, stages.SelectProperties);
      return;
    }
    //unrecognized value
    throw new Error(`${newValue} is not a valid dialog stage`);
  },
});
