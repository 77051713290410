import React, { useMemo, useState, useRef } from 'react';
import {
  Grid,
  Fade,
  PageContainer,
  PageContent,
  PageHeader,
  SearchInput,
  Button,
  MRT_RowSelectionState,
  Panel,
  styled,
  Alert,
  WarningIcon,
  AlertTitle,
  useConfirmationModal,
  ConfirmationModal,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@cherre-frontend/ui';
import {
  GraphQLReturn,
  useCherreSetState,
  useCherreStateDebounced,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  batchManagementTableSearch,
  createBatchDialogOpenState,
  reopenBatchesDialogOpenState,
} from './recoil';
import { SubmissionsPanel } from './Panels/SubmissionsPanel/SubmissionsPanel';
import CreateBatchDialog from './Panels/CreateBatchDialog';
import { getUnassignedProperties } from './recoil/getUnassignedProperties';
import { useHistory } from 'react-router';
import { graphql } from 'react-relay';
import { batchManagementCloseBatchesMutation } from './__generated__/batchManagementCloseBatchesMutation.graphql';
import Filters from './Panels/SubmissionsPanel/components/Filters';
import ReopenBatchesDialog from './Panels/ReopenBatchesDialog';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CheckBoxLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const WarningBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.brown.light};
  color: ${({ theme }) => theme.accents.brown.main};
  .MuiAlert-message {
    padding: 0px;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

export const Icon = styled(WarningIcon)`
  color: ${({ theme }) => theme.accents.brown.main};
`;

const getUnnassignedPropertiesSubtitle = (count: number) => {
  if (count === 1) {
    return `1 property was excluded from recent submission batches. Make sure to include it in timely submissions.`;
  }
  return `${count} properties were excluded from recent submission batches. Make sure to include them in timely submissions.`;
};

export default function BatchManagementPage() {
  const [search, , setSearch] = useCherreStateDebounced(
    batchManagementTableSearch
  );

  const setCreateBatchDialogOpenState = useCherreSetState(
    createBatchDialogOpenState
  );

  const setReopenBatchesDialogOpenState = useCherreSetState(
    reopenBatchesDialogOpenState
  );

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const selectedBatches = useMemo(
    () => Object.keys(rowSelection).map((i) => Number(i)),
    [rowSelection]
  );

  const hasSelectedRows = useMemo(
    () => selectedBatches.length > 0,
    [selectedBatches]
  );

  const unassignedProperties = useCherreValue(getUnassignedProperties());

  const { push } = useHistory();

  const { confirm, modalProps } = useConfirmationModal();

  const shouldRenewBatches = useRef(false);

  const closeBatchesMutation = useMutation(
    graphql`
      mutation batchManagementCloseBatchesMutation(
        $closed_at_datetime: timestamptz!
        $submission_ids: [Int!]!
      ) {
        update_sys_submissions(
          where: { submission_id: { _in: $submission_ids } }
          _set: { is_closed: true, closed_at_datetime: $closed_at_datetime }
        ) {
          affected_rows
        }
      }
    ` as GraphQLReturn<batchManagementCloseBatchesMutation>,
    {
      mapVariables: () => async () => ({
        closed_at_datetime: new Date().toISOString(),
        submission_ids: selectedBatches,
      }),
      onCompleted: (response, ctx) => {
        const count = response.update_sys_submissions?.affected_rows ?? 0;
        const plural = count > 1;
        ctx.showSnackbar({
          type: 'success',
          message: `${count} ${plural ? 'batches were' : 'batch was'} closed.`,
        });
        setRowSelection({});
        if (shouldRenewBatches.current) {
          setReopenBatchesDialogOpenState(true);
        }
      },
    }
  );

  const onCloseBatches = () => {
    if (hasSelectedRows) {
      const plural = selectedBatches.length > 1;
      confirm({
        title: `Close ${plural ? 'Batches' : 'Batch'}`,
        content: (
          <>
            {`Are you sure you want to close ${plural ? 'these' : 'this'} ${
              selectedBatches.length
            } ${plural ? 'batches' : 'batch'}?`}
            <br />
            {`Users will no longer be able to submit data through ${
              plural ? 'these batches' : 'this batch'
            }.`}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(_, checked) =>
                    (shouldRenewBatches.current = checked)
                  }
                />
              }
              label={
                <CheckBoxLabel>
                  Create new batches for the next period based on selections.
                </CheckBoxLabel>
              }
            />
          </>
        ),
        confirmLabel: 'Close',
        cancelLabel: 'Cancel',
        handleConfirm: () => {
          closeBatchesMutation();
        },
      });
    }
  };

  return (
    <PageContainer id='BatchManagementPage'>
      <ConfirmationModal {...modalProps} />
      <CreateBatchDialog />
      <ReopenBatchesDialog />
      <PageHeader
        title='Submission Batch Management'
        subtitle='Create submission batches to define the submission cycles for data sent by your providers'
      >
        <Button
          variant='outlined'
          color='primary'
          onClick={() => setReopenBatchesDialogOpenState(true)}
        >
          Reopen Batches
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setCreateBatchDialogOpenState(true)}
        >
          Create Batch
        </Button>
      </PageHeader>
      <PageContent>
        <Grid
          container
          direction='row'
          alignItems='center'
          gap='10px'
          justifyContent='space-between'
        >
          <Grid container direction='row' gap='10px' width='fit-content'>
            <SearchInput
              value={search ?? ''}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Filters />
          </Grid>
          <Grid item>
            <Fade in={hasSelectedRows}>
              <div>
                <Button
                  variant='outlined'
                  color='primary'
                  size='medium'
                  onClick={onCloseBatches}
                >
                  Close Selected Batches
                </Button>
              </div>
            </Fade>
          </Grid>

          {unassignedProperties ? (
            <Grid item xs={12}>
              <WarningBanner
                severity='warning'
                icon={<Icon />}
                action={
                  <Button
                    variant='outlined'
                    onClick={() => push('/dsp/review-properties')}
                  >
                    Review Properties
                  </Button>
                }
              >
                <AlertTitle>Attention!</AlertTitle>
                {getUnnassignedPropertiesSubtitle(unassignedProperties)}
              </WarningBanner>
            </Grid>
          ) : null}
        </Grid>
        <PanelStyled id='submissions'>
          <SubmissionsPanel
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            search={search}
          />
        </PanelStyled>
      </PageContent>
    </PageContainer>
  );
}

BatchManagementPage.displayName = 'BatchManagementPage';
