import {
  useCherreState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Alert,
  AlertProps,
  AlertTitle,
  CancelIcon,
  CheckCircleIcon,
  DotSeparator,
  FilterChip,
  Grid,
  Menu,
  MenuItem,
  SearchInput,
  StyleSelector,
  Typography,
  WarningIcon,
  styled,
  FormControl,
  Tooltip,
  Button,
  DownloadFileIcon,
} from '@cherre-frontend/ui';
import React from 'react';
import { useJsonLogic } from 'src/products/data-submission-portal/hooks/useJsonLogic';
import { z } from 'zod';
import {
  batchValidationModalReportSlugState,
  batchValidationModalSearchState,
  batchValidationModalStatusFilterState,
  modalBatchValidationState,
} from '../../recoil';
import { batchSummaryInfo } from '../Header/query';
import { useDownloadReportTable } from 'src/products/data-submission-portal/components/Reports/useDownloadTable';
import { ReportViewTypeSelect } from 'src/products/data-submission-portal/components/Reports/ReportViewTypeSelector';

const DownloadButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 5px;
  min-width: 0px;
  & .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  &:active {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const alertColorMap: StyleSelector<AlertProps> = ({ severity, theme }) => {
  switch (severity) {
    case 'success':
      return theme.accents.positive.main;
    case 'warning':
      return theme.accents.warning.main;
    case 'error':
      return theme.accents.error.main;
    default:
      throw new Error('Invalid severity');
  }
};

const alertIconMap = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <CancelIcon />,
};

const AlertStyled = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.warning.light};
  font-size: 12px;
  font-weight: 500;
  color: ${alertColorMap};
  & .MuiAlert-icon {
    color: ${alertColorMap};
  }
  & .MuiAlert-message {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  padding: 6px;
  margin-bottom: 20px;
`;

const AlertTitleStyled = styled(AlertTitle)`
  font-size: 14px;
  font-weight: 700;
  color: inherit;
  whitespace: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

const bannerSchema = z.object({
  banner_title: z.string(),
  banner_subtitle: z.string().optional(),
  banner_mode: z.enum(['success', 'warning', 'error']),
});

export function BatchValidationReportHeader() {
  const validation = useCherreValue(modalBatchValidationState);

  const selectedReportSlug = useCherreValue(
    batchValidationModalReportSlugState
  );

  const summary = useCherreValue(batchSummaryInfo());
  const [search, , setSearch] = useCherreStateDebounced(
    batchValidationModalSearchState
  );

  const [status, setStatus] = useCherreState(
    batchValidationModalStatusFilterState
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleStatusFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusFilterClose = () => {
    setAnchorEl(null);
  };

  const handleStatusFilterChange =
    (value: NonNullable<typeof status>) => () => {
      setStatus(value);
      handleStatusFilterClose();
    };

  const banner = useJsonLogic()(
    validation?.properties_batch_validation_rule?.batch_validation_rule
      .banner_config,
    validation,
    bannerSchema
  );

  const downloadReport = useDownloadReportTable();

  const label =
    validation?.properties_batch_validation_rule?.batch_validation_rule
      .validation_label;

  const filename = `${summary?.submission.submission_name}_${label}.xlsx`;

  return (
    <Grid display='grid' gap={2}>
      <Grid container direction={'row'}>
        <Grid item xs>
          <Typography variant='h3'>{label}</Typography>
          <Typography variant='body1'>
            <DotSeparator>
              <span className='suspend'>
                {summary?.submission.submission_name || 'Unknown Submission'}
              </span>
            </DotSeparator>
          </Typography>
        </Grid>
        <Grid item>
          <Grid display='flex' gap={1} alignItems={'center'}>
            <FilterChip
              label={status || ''}
              onClick={handleStatusFilterClick}
              active={status !== 'Any Status'}
              onDelete={handleStatusFilterChange('Any Status')}
            />
            <Menu
              id='more-items-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleStatusFilterClose}
              data-testid='more-items-menu'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                style={{ width: '200px', gap: '6px' }}
                onClick={handleStatusFilterChange('Any Status')}
              >
                Any Status
              </MenuItem>
              <MenuItem
                style={{ width: '200px', gap: '6px' }}
                onClick={handleStatusFilterChange('Passed')}
              >
                Passed
              </MenuItem>
              <MenuItem
                style={{ width: '200px', gap: '6px' }}
                onClick={handleStatusFilterChange('Failed')}
              >
                Failed
              </MenuItem>
            </Menu>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <ReportViewTypeSelect />
            </FormControl>
            <SearchInput
              value={search}
              onChange={(v) => setSearch(v.target.value)}
            />
            <Tooltip title='Download Report'>
              <div>
                <DownloadButton
                  onClick={() =>
                    selectedReportSlug
                      ? downloadReport(selectedReportSlug, filename)
                      : Promise.resolve()
                  }
                  startIcon={<DownloadFileIcon />}
                  variant='outlined'
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {validation && banner ? (
          <AlertStyled
            data-testid='submission-alert'
            severity={banner.banner_mode}
            title={banner.banner_title}
            icon={alertIconMap[banner.banner_mode]}
          >
            <AlertTitleStyled>{banner.banner_title}</AlertTitleStyled>
            {banner.banner_subtitle}
          </AlertStyled>
        ) : null}
      </Grid>
    </Grid>
  );
}
