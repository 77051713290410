import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUploadMasterFileNewMutation } from './__generated__/useUploadMasterFileNewMutation.graphql';

export const useUploadMasterFileNew = (
  input: {
    chart_of_accounts_name: string;
    file_source: string;
  },
  onMutationCompleted?: Parameters<typeof useMutation>[1]['onCompleted']
) => {
  return useMutation(
    graphql`
      mutation useUploadMasterFileNewMutation(
        $input: SysUploadMasterFileInput!
      ) {
        _sys_upload_master_file(input: $input) {
          master_chart_of_account_id
        }
      }
    ` as GraphQLReturn<useUploadMasterFileNewMutation>,
    {
      trackEvent: false,
      mapVariables: (file_reference_source: string) => async () => {
        return {
          input: {
            file_reference_source: file_reference_source,
            chart_of_accounts_name: input.chart_of_accounts_name,
            file_source: input.file_source,
          },
        };
      },
      onCompleted: (value, ctx) => onMutationCompleted?.(value, ctx),
    }
  );
};
