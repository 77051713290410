import {
  constSelector,
  GraphQLReturn,
  serialize,
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  FormEntry,
  Grid,
  MRT_RowSelectionState,
  MenuItem,
  Panel,
  Select,
  SelectProps,
  styled,
  Typography,
  WarningIcon,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  CircleIcon,
} from '@cherre-frontend/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  assignProviderDialogOpenState,
  selectedPropertiesToAssign,
  selectedProviderToBeAssigned,
  selectedPropertiesToAssignContainsManyToOneProperty,
} from 'src/products/data-submission-portal/recoil/dialog';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import { WarningBanner } from '../../../..';
import { getAssignProviderOpenedBatches } from '../../queries/getAssignProviderOpenedBatches';
import { getOrganizationProviders } from '../../queries/getOrganizationProviders';
import { AssignProviderDialogHeader } from './AssignProviderDialogHeader';
import { AssignProviderDialogMutation } from './__generated__/AssignProviderDialogMutation.graphql';
import { M1PropertyGroupDialog } from './M1PropertyGroupDialog';
import { canAssignToProviderQuery } from './recoil';

const Container = styled<React.FC<DialogProps>>(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: none;
    min-width: 627px;
  }
`;

const SelectStyled = styled<React.FC<SelectProps>>(Select)`
  height: 40px;
`;

type AssignProviderDialogProps = {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: (rowSelection: MRT_RowSelectionState) => void;
};

const AssignProviderDialog: React.FC<AssignProviderDialogProps> = ({
  rowSelection,
  setRowSelection,
}) => {
  const theme = useTheme();
  const isModalOpen = useCherreValue(assignProviderDialogOpenState);
  const selectedProperties = useCherreValue(selectedPropertiesToAssign);
  const containManyToOneProperties = useCherreValue(
    selectedPropertiesToAssignContainsManyToOneProperty
  );
  const [modalStage, setModalStage] = useState('selectM1');
  useEffect(() => {
    setModalStage(containManyToOneProperties ? 'selectM1' : 'selectProvider');
  }, [containManyToOneProperties]);

  const [selectedProvider, setSelectedProvider] = useCherreState(
    selectedProviderToBeAssigned
  );

  const [hasProviders, currentProviders] = useMemo(() => {
    const providers =
      selectedProperties
        ?.filter((property) => property.provider !== null)
        .map((property) => property.provider?.provider_name) ?? [];
    return [providers?.length > 0, [...new Set(providers)]];
  }, [selectedProperties]);

  const providerData = useCherreValue(
    isModalOpen ? getOrganizationProviders() : constSelector([])
  );

  const openedBatches = useCherreValue(
    isModalOpen
      ? getAssignProviderOpenedBatches(
          selectedProperties?.map((property) => property.property_id) ?? []
        )
      : constSelector([])
  );

  const openedBatchesList = useMemo(() => {
    if (openedBatches && isModalOpen) {
      const selectedEntityIds = selectedProperties?.map(
        (property) => property.entity_id
      );
      const batchEntityIds = [
        ...new Set(
          openedBatches.flatMap((batch) =>
            batch.property_batches.map(
              (propertyBatch) => propertyBatch.property.entity_id
            )
          )
        ),
      ].filter((batchEntity) => selectedEntityIds?.includes(batchEntity));

      const entityIdBatchList = batchEntityIds.map((batchEntityId) => {
        const batches = openedBatches.filter((batch) =>
          batch.property_batches
            .map((propertyBatch) => propertyBatch.property.entity_id)
            .includes(batchEntityId)
        );
        return {
          entity_id: batchEntityId,
          batches,
        };
      });
      return entityIdBatchList;
    } else {
      return [];
    }
  }, [openedBatches, isModalOpen]);

  const canAssignToProvider = useCherreValue(
    !selectedProperties?.length || !selectedProvider
      ? constSelector(false)
      : canAssignToProviderQuery({
          properties: selectedProperties.map((p) => ({
            property_code: p.property_code ?? '',
            entity_id: p.entity_id ?? '',
          })),
          provider_id: selectedProvider,
        })
  );

  const assignProvider = useMutation(
    graphql`
      mutation AssignProviderDialogMutation(
        $property_ids: [Int!]!
        $provider_id: Int!
      ) {
        _sys_property_assign_provider(
          arg1: { property_ids: $property_ids, provider_id: $provider_id }
        ) {
          result
        }
      }
    ` as GraphQLReturn<AssignProviderDialogMutation>,
    {
      trackEvent: false,
      mapVariables: () => async () => {
        const selectedPropertyIds = Object.keys(rowSelection);
        return {
          property_ids: selectedPropertyIds.map((id) => parseInt(id)),
          provider_id: selectedProvider ?? 0,
        };
      },
      onCompleted: (result, ctx) => {
        setRowSelection({});
        ctx.recoil.set(assignProviderDialogOpenState, false);
        ctx.recoil.set(selectedProviderToBeAssigned, 0);
        ctx.showSnackbar({
          type: 'success',
          message: 'Properties successfully assigned!',
        });
      },
      onError: (result, ctx) => {
        ctx.logger.error('AssignProviderDialogMutation failed', result);
        ctx.showSnackbar({
          type: 'error',
          message: `Provider assignment failed.`,
        });
      },
    }
  );

  const onClose = useCherreEventWithRecoil(
    'user closed assign provider dialog',
    (ctx) => () => {
      ctx.recoil.set(assignProviderDialogOpenState, false);
      ctx.recoil.set(selectedProviderToBeAssigned, 0);
      setModalStage('selectM1');
    }
  );

  return (
    <Container open={Boolean(isModalOpen)} onClose={onClose}>
      <Panel id='AssignProvidersDialog' config={{ logLevel: false }}>
        {modalStage === 'selectM1' ? (
          <M1PropertyGroupDialog
            onClose={onClose}
            onContinue={() => {
              setModalStage('selectProvider');
            }}
          />
        ) : (
          <>
            <AssignProviderDialogHeader
              title={'Assign Properties'}
              subtitle={`${selectedProperties?.length} ${
                selectedProperties?.length === 1 ? 'Property' : 'Properties'
              } Selected`}
              onClose={onClose}
            />
            {hasProviders && (
              <Box mt={3}>
                <Typography>Currently assigned to</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                  {currentProviders.join(', ')}
                </Typography>
              </Box>
            )}
            <Box my={3}>
              <FormEntry title='Select the new provider'>
                <SelectStyled
                  value={selectedProvider}
                  onChange={(e) =>
                    setSelectedProvider(e.target.value as number)
                  }
                  MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
                >
                  <MenuItem value={0} disabled>
                    Service Provider Name
                  </MenuItem>
                  {providerData
                    ?.filter(
                      (provider) =>
                        !currentProviders.includes(provider.provider_name)
                    )
                    .map((provider) => (
                      <MenuItem
                        key={`provider-${provider.provider_id}-${provider.provider_name}`}
                        value={provider.provider_id}
                      >
                        {provider.provider_name}
                      </MenuItem>
                    ))}
                </SelectStyled>
              </FormEntry>
            </Box>
            {openedBatchesList.length > 0 &&
              selectedProvider !== 0 &&
              selectedProvider !== undefined && (
                <Box>
                  <WarningBanner
                    severity='error'
                    icon={
                      <WarningIcon sx={{ color: theme.palette.error.main }} />
                    }
                    sx={{
                      backgroundColor: theme.palette.error.light,
                      borderRadius: '8px',
                      marginBottom: 3,
                    }}
                  >
                    <Typography sx={{ color: theme.palette.error.main }}>
                      You must first close the following submission batches
                      before assigning a new provider for these Entity IDs:
                    </Typography>
                  </WarningBanner>
                  {openedBatchesList.map((entityId) => {
                    return (
                      <Box>
                        <Typography>Entity ID {entityId.entity_id}</Typography>
                        <List sx={{ paddingTop: '0px' }}>
                          {entityId.batches.map((batch) => (
                            <ListItem key={batch.submission_id}>
                              <ListItemIcon
                                sx={{
                                  minWidth: '12px',
                                }}
                              >
                                <CircleIcon
                                  sx={{
                                    height: '4px',
                                    width: '4px',
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              </ListItemIcon>
                              <Link
                                target='_blank'
                                to={`${
                                  BatchManagementRoute.routeDescriptor.path
                                }?${serialize({
                                  search: batch.submission_name,
                                })}`}
                              >
                                {batch.submission_name}
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    );
                  })}
                </Box>
              )}
            {(openedBatchesList.length === 0 ||
              selectedProvider === 0 ||
              selectedProvider === undefined) && (
              <Box sx={{ marginBottom: 3 }}>
                {!canAssignToProvider && !!selectedProvider && (
                  <WarningBanner
                    severity='error'
                    icon={
                      <WarningIcon sx={{ color: theme.palette.error.main }} />
                    }
                    sx={{
                      backgroundColor: theme.palette.error.light,
                      borderRadius: '8px',
                      marginBottom: 3,
                    }}
                  >
                    <Typography sx={{ color: theme.palette.error.main }}>
                      This service provider has an existing source ID
                      corresponding to the selected target ID. Update the Source
                      ID before assigning this record to this Provider
                    </Typography>
                  </WarningBanner>
                )}
                <Typography sx={{ fontStyle: 'italic', fontSize: '12px' }}>
                  Note: All users and mappings currently assigned to these
                  properties will be removed. Dataset and validation assignments
                  will be retained.
                </Typography>
              </Box>
            )}
            <Grid container direction='row' gap={2} justifyContent='flex-end'>
              <Grid item>
                <Button
                  size='medium'
                  variant='outlined'
                  color='primary'
                  onClick={onClose}
                  sx={{
                    border: 'none',
                    minWidth: '73px',
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size='medium'
                  variant='contained'
                  color='primary'
                  disabled={
                    selectedProvider === 0 ||
                    openedBatchesList.length > 0 ||
                    !canAssignToProvider
                  }
                  onClick={() => assignProvider()}
                  sx={{
                    minWidth: '80px',
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Panel>
    </Container>
  );
};

export default AssignProviderDialog;
