import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  assignPropertiesTableSearch,
  assignPropertiesTableState,
} from '../recoil';
import { MRT_SortingState } from 'material-react-table';
import { getAllPropertiesQuery } from './__generated__/getAllPropertiesQuery.graphql';
import { getAllPropertiesTotalQuery } from './__generated__/getAllPropertiesTotalQuery.graphql';
import { getAllPropertiesUsedValuesQuery } from './__generated__/getAllPropertiesUsedValuesQuery.graphql';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import { SubmissionTypeSlugs } from '../mutations/useAssignSingleChainReviewer';
import { getSubmissionTypes } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/queries';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

export const getAllProperties = graphQLSelector({
  query: graphql`
    query getAllPropertiesQuery(
      $provider_id: Int!
      $search: String = "%"
      $order_by: [sys_properties_flattened_union_order_by!] = {}
      $limit: Int = 10
      $offset: Int = 0
      $financial_type_id: Int = 1
      $operational_type_id: Int = 2
    ) {
      sys_properties_flattened_union(
        where: {
          _and: [
            { is_active: { _eq: true } }
            {
              _or: [
                { provider_id: { _neq: $provider_id } }
                { provider_id: { _is_null: true } }
              ]
            }
            {
              _or: [
                {
                  _and: [
                    { property_name: { _ilike: $search } }
                    { property_name: { _is_null: false } }
                  ]
                }
                { entity_id: { _ilike: $search } }
                {
                  _and: [
                    { address: { _ilike: $search } }
                    { address: { _is_null: false } }
                  ]
                }
                {
                  _and: [
                    { fund: { _ilike: $search } }
                    { fund: { _is_null: false } }
                  ]
                }
                { provider: { provider_name: { _like: $search } } }
              ]
            }
          ]
        }
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        entity_id
        property_id
        parent_property_id
        address
        is_active
        property_name
        fund
        type
        property_code
        property_mapping
        provider {
          provider_name
        }
        mapping_sets_count: properties_mapping_sets_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        coa_mappings: properties_mapping_sets(
          where: {
            is_active: { _eq: true }
            mapping_set: {
              mapping_field: { mapping_field_name: { _eq: "gl_account_code" } }
            }
          }
        ) {
          mapping_set_id
        }
        mapping_set_ids: properties_mapping_sets(
          where: { is_active: { _eq: true } }
        ) {
          mapping_set_id
        }
        properties_datasets(where: { is_active: { _eq: true } }) {
          dataset_id
        }
        users_count: properties_roles_users_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        users_preparers: properties_roles_users(
          where: {
            _and: { is_active: { _eq: true }, property_role_id: { _eq: 1 } }
          }
        ) {
          user_id
        }
        users_financial_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role_id: { _in: [2, 3, 4] }
              submission_type: {
                submission_type_id: { _eq: $financial_type_id }
              }
            }
          }
        ) {
          user_id
        }
        users_operational_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role_id: { _in: [2, 3, 4] }
              submission_type: {
                submission_type_id: { _eq: $operational_type_id }
              }
            }
          }
        ) {
          user_id
        }
        datasets_count: properties_datasets_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        total_opened: property_batches_aggregate(
          where: { submission: { is_closed: { _eq: false } } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<getAllPropertiesQuery>,
  resetCache: true,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(assignPropertiesTableSearch);
      const tableState = get(assignPropertiesTableState);
      const submissionTypes = get(getSubmissionTypes());

      const financialType = submissionTypes.find(
        (submissionType) =>
          submissionType.submission_type_slug === SubmissionTypeSlugs.FINANCIAL
      );
      const operationalType = submissionTypes.find(
        (submissionType) =>
          submissionType.submission_type_slug ===
          SubmissionTypeSlugs.OPERATIONAL
      );

      return {
        provider_id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
        search: `%${search ?? ''}%`,
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
        operational_type_id: operationalType?.submission_type_id,
        financial_type_id: financialType?.submission_type_id,
      };
    },
  mapResponse: (resp) => {
    return resp.sys_properties_flattened_union;
  },
});

export const getAllPropertiesTotal = graphQLSelector({
  query: graphql`
    query getAllPropertiesTotalQuery(
      $provider_id: Int!
      $search: String = "%"
    ) {
      total: sys_properties_flattened_union_aggregate(
        where: {
          _and: [
            {
              _or: [
                { provider_id: { _neq: $provider_id } }
                { provider_id: { _is_null: true } }
              ]
            }
            {
              _or: [
                {
                  _and: [
                    { property_name: { _ilike: $search } }
                    { property_name: { _is_null: false } }
                  ]
                }
                { entity_id: { _ilike: $search } }
                {
                  _and: [
                    { address: { _ilike: $search } }
                    { address: { _is_null: false } }
                  ]
                }
                {
                  _and: [
                    { fund: { _ilike: $search } }
                    { fund: { _is_null: false } }
                  ]
                }
                { provider: { provider_name: { _like: $search } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getAllPropertiesTotalQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const search = get(assignPropertiesTableSearch);

      return {
        provider_id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
        search: `%${search ?? ''}%`,
      };
    },
  mapResponse: (resp) => {
    return resp.total;
  },
});

export const getAllPropertiesUsedValues = graphQLSelector({
  query: graphql`
    query getAllPropertiesUsedValuesQuery {
      fund_is_used: sys_properties_flattened_union_aggregate(
        where: { fund: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      property_name_is_used: sys_properties_flattened_union_aggregate(
        where: { property_name: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      address_is_used: sys_properties_flattened_union_aggregate(
        where: { address: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      type_is_used: sys_properties_flattened_union_aggregate(
        where: { type: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getAllPropertiesUsedValuesQuery>,
  mapVariables: () => () => {
    return {};
  },
  mapResponse: (resp) => {
    return {
      fund_is_used: !!resp.fund_is_used.aggregate?.count,
      property_name_is_used: !!resp.property_name_is_used.aggregate?.count,
      type_is_used: !!resp.type_is_used.aggregate?.count,
      address_is_used: !!resp.address_is_used.aggregate?.count,
    };
  },
});
