import {
  serialize,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Button, DeleteIcon, useConfirmationModal } from '@cherre-frontend/ui';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import { useDeleteMappingSet } from '../hooks/useDeleteMappingSet';
import { getOpenedBatchesWithMappings } from '../queries/getOpenedBatchesWithMappings';
import { mappingSetToDelete } from '../recoil';

export const dialogTitleCloseBatches = 'Close Batches';
export const dialogTitleDeleteMapping = 'Delete Mapping';

type DeleteMappingSetButtonProps = {
  mappingSetID: number;
  confirmFunction: typeof useConfirmationModal.arguments.confirm;
};

const DeleteMappingSetButton: React.FC<DeleteMappingSetButtonProps> = ({
  mappingSetID,
  confirmFunction,
}) => {
  const [mappingSetToDeleteID, setMappingSetToDeleteID] =
    useCherreState(mappingSetToDelete);
  const openedBatches = useCherreValue(
    getOpenedBatchesWithMappings(mappingSetToDeleteID || 0)
  );
  const hasOpenedBatches = openedBatches && openedBatches.length > 0;
  const deleteMappingSet = useDeleteMappingSet(
    mappingSetToDeleteID || 0,
    (value, ctx) => {
      ctx.showSnackbar({
        type: 'success',
        message: 'Mapping deleted',
      });
      setMappingSetToDeleteID(0);
    }
  );

  useEffect(() => {
    if (!mappingSetToDeleteID) {
      // nothing to do
      return;
    }

    if (hasOpenedBatches) {
      confirmFunction({
        title: dialogTitleCloseBatches,
        content: (
          <div>
            <p>
              You must first close the following submission batches before it
              can be deleted:
            </p>
            <ul>
              {openedBatches.map((batch) => (
                <li key={batch.submission_id}>
                  <Link
                    target='_blank'
                    to={`${
                      BatchManagementRoute.routeDescriptor.path
                    }?${serialize({ search: batch.submission_name })}`}
                  >
                    {batch.submission_name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ),
        confirmLabel: 'OK',
        handleConfirm: () => setMappingSetToDeleteID(0),
        hideCancel: true,
      });
    } else {
      confirmFunction({
        title: dialogTitleDeleteMapping,
        content: (
          <div>
            <p>
              Deleting this mapping will remove it from all properties it is
              assigned to and de-activate them until a new mapping is assigned.
            </p>
          </div>
        ),
        confirmLabel: 'Delete',
        handleConfirm: () => {
          deleteMappingSet();
        },
        handleCancel: () => setMappingSetToDeleteID(0),
      });
    }
  }, [openedBatches]);

  return (
    <Button
      style={{ minWidth: 'unset' }}
      onClick={() => setMappingSetToDeleteID(mappingSetID)}
    >
      <DeleteIcon
        sx={{
          display: 'flex',
        }}
        color='primary'
      />
    </Button>
  );
};

export default DeleteMappingSetButton;
