/**
 * @generated SignedSource<<012a69673924c2eb515b9ef9c13a8cbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilGetUserPropertyRoleStageIdsQuery$variables = {
  property_batch_id: number;
  user_id: number;
};
export type recoilGetUserPropertyRoleStageIdsQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly have_investment_access: {
      readonly property_batches: ReadonlyArray<{
        readonly property: {
          readonly properties_roles_users: ReadonlyArray<{
            readonly property_role: {
              readonly property_batch_role_status: ReadonlyArray<{
                readonly property_batch_stage_id: number | null;
              }>;
            };
          }>;
        };
      }>;
    };
    readonly property: {
      readonly properties_roles_users: ReadonlyArray<{
        readonly property_role: {
          readonly property_batch_role_status: ReadonlyArray<{
            readonly property_batch_stage_id: number | null;
          }>;
        };
      }>;
    };
  }>;
};
export type recoilGetUserPropertyRoleStageIdsQuery = {
  response: recoilGetUserPropertyRoleStageIdsQuery$data;
  variables: recoilGetUserPropertyRoleStageIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_batch_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_id"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_eq",
        "variableName": "property_batch_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "property_batch_id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_properties",
  "kind": "LinkedField",
  "name": "property",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "is_active",
              "value": {
                "_eq": true
              }
            },
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_eq",
                  "variableName": "user_id"
                }
              ],
              "kind": "ObjectValue",
              "name": "user_id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "sys_properties_roles_users",
      "kind": "LinkedField",
      "name": "properties_roles_users",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "sys_property_roles",
          "kind": "LinkedField",
          "name": "property_role",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "sys_property_batch_role_statuses",
              "kind": "LinkedField",
              "name": "property_batch_role_status",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "property_batch_stage_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": (v2/*: any*/),
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": "have_investment_access",
        "args": null,
        "concreteType": "sys_submissions",
        "kind": "LinkedField",
        "name": "submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "property_type",
                        "value": {
                          "property_type_slug": {
                            "_eq": "investment"
                          }
                        }
                      },
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": (v2/*: any*/),
                                "kind": "ObjectValue",
                                "name": "property_batches"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "from_property"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "to_property_relationships"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "property"
                  }
                ],
                "kind": "ObjectValue",
                "name": "where"
              }
            ],
            "concreteType": "sys_property_batches",
            "kind": "LinkedField",
            "name": "property_batches",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetUserPropertyRoleStageIdsQuery",
    "selections": (v4/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "recoilGetUserPropertyRoleStageIdsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8a4c131e4e4c1c45e9d247b7d1e16ae7",
    "id": null,
    "metadata": {},
    "name": "recoilGetUserPropertyRoleStageIdsQuery",
    "operationKind": "query",
    "text": "query recoilGetUserPropertyRoleStageIdsQuery(\n  $user_id: Int!\n  $property_batch_id: Int!\n) {\n  sys_property_batches(where: {property_batch_id: {_eq: $property_batch_id}}) {\n    property {\n      properties_roles_users(where: {user_id: {_eq: $user_id}, is_active: {_eq: true}}) {\n        property_role {\n          property_batch_role_status {\n            property_batch_stage_id\n          }\n        }\n      }\n    }\n    have_investment_access: submission {\n      property_batches(where: {property: {property_type: {property_type_slug: {_eq: \"investment\"}}, to_property_relationships: {from_property: {property_batches: {property_batch_id: {_eq: $property_batch_id}}}}}}) {\n        property {\n          properties_roles_users(where: {user_id: {_eq: $user_id}, is_active: {_eq: true}}) {\n            property_role {\n              property_batch_role_status {\n                property_batch_stage_id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e20a1309ca4510edb7891edc249d7d0d";

export default node;
