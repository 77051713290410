import {
  Dialog,
  Grid,
  Typography,
  styled,
  Panel,
  TextField,
  IconButton,
} from '@cherre-frontend/ui';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
  }
`;

export const TableContainer = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export const LabelText = styled(Typography)`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: -8px;
`;

export const NameField = styled(TextField)`
  width: 300px;
  .MuiInputBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    color: black;
  }
  input.Mui-disabled {
    -webkit-text-fill-color: unset;
  }
  label.Mui-disabled {
    color: black;
  }
`;

export const DialogTextField = styled(TextField)`
  width: 300px;
`;

export const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FilterTextField = styled(TextField)`
  width: 240px;
  .MuiInputBase-root {
    padding-right: 8px;
  }
`;

export const ClearButton = styled(IconButton)`
  padding: 4px;
`;
