import React from 'react';

import { Button, Box, AlertColor } from '@cherre-frontend/ui';
import { AlertWithCta } from '../AlertWithCta';
import { SubmitStatus } from './utils';

const alertMap = new Map<
  SubmitStatus | '',
  {
    title: string;
    getText: (condition: boolean) => string;
    severity: AlertColor;
    ctaText: (condition: boolean) => string;
  }
>([
  [
    SubmitStatus.APPROVE,
    {
      title: 'Approve Property Group',
      getText: (displayTSAText: boolean) =>
        displayTSAText
          ? 'Approve this property group or click on a package report to upload any topside adjustments that may be needed.'
          : 'Approve this property group or toggle to the combined view to continue reviewing.',
      severity: 'success',
      ctaText: (isGrouped: boolean) =>
        isGrouped ? 'Approve Property Group' : 'Approve Property',
    },
  ],
]);

export const ReviewerAlert: React.FC<{
  showTSAText: boolean;
  submitStatus: SubmitStatus | '';
  submitProperty: () => void;
  isGrouped: boolean;
}> = ({ showTSAText, submitStatus, submitProperty, isGrouped = false }) => {
  const alertProps = alertMap.get(submitStatus);

  if (!alertProps) {
    return null;
  }

  return (
    <Box data-testid='reviewer-cta' mb={1}>
      <AlertWithCta
        testId={`group-property-batch-reviewer-alert-${status.toLowerCase()}`}
        severity={alertProps.severity}
        title={alertProps.title}
        cta={
          <Button
            variant={'contained'}
            color='primary'
            onClick={submitProperty}
          >
            {alertProps.ctaText(isGrouped)}
          </Button>
        }
      >
        {alertProps.getText(showTSAText)}
      </AlertWithCta>
    </Box>
  );
};
