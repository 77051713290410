import { useCherrePartialState } from '@cherre-frontend/data-fetching';
import React from 'react';
import { addEditMappingFormState, getTargetDropdowns } from '../recoil';
import { DialogTextField } from '../styles';
import {
  isExcludedPropertyType,
  isSourcedFromMasterCOA,
  MappingTypes,
} from '../types';
import { Autocomplete } from '@cherre-frontend/ui';
import { getExcludedPropertiesOptions } from '../queries/addEditOptions';

export type AddEditTargetCodeInputProps = {
  mappingType: MappingTypes;
};

const AddEditTargetCodeInput: React.FC<AddEditTargetCodeInputProps> = ({
  mappingType,
}) => {
  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingFormState
  );
  if (isExcludedPropertyType(mappingType)) {
    return (
      <Autocomplete
        label='Target Code'
        getSelectedOption={(o) => mappingForm?.target_code === o.property_code}
        selector={getExcludedPropertiesOptions()}
        getOptionLabel={(option) => option.property_code}
        style={{ width: '300px' }}
        size='small'
        onChange={(_, value) =>
          setMappingForm({
            target_code: value?.property_code ?? '',
            target_description: value?.property_name ?? '',
          })
        }
      />
    );
  }
  if (isSourcedFromMasterCOA(mappingType)) {
    return (
      <Autocomplete
        label='Target Code'
        getSelectedOption={(o) => mappingForm?.target_code === o.label}
        selector={getTargetDropdowns(mappingType)}
        getOptionLabel={(option) => option.label}
        style={{ width: '300px' }}
        size='small'
        onChange={(_, value) =>
          setMappingForm({
            target_code: value?.label ?? '',
            target_description: value?.value ?? '',
          })
        }
      />
    );
  }
  return (
    <DialogTextField
      variant='outlined'
      size='small'
      label='Target Code'
      value={mappingForm?.target_code}
      onChange={(e) => {
        setMappingForm({ target_code: e.target.value });
      }}
    />
  );
};

export default AddEditTargetCodeInput;
