import React from 'react';
import { styled, Grid } from '@cherre-frontend/ui';

const ContentContainer = styled(Grid)`
  padding-top: ${(props) => props.rowSpacing};
  padding-bottom: ${(props) => props.rowSpacing};
`;

export type DialogContentProps = {
  rowSpacing: string;
  minHeight?: string;
};

export const DialogContent: React.FC<DialogContentProps> = (props) => {
  return (
    <ContentContainer
      container
      rowSpacing={props.rowSpacing}
      columnSpacing='10px'
      sx={{ minHeight: props.minHeight }}
    >
      {props.children}
    </ContentContainer>
  );
};

DialogContent.displayName = 'DialogContent';
