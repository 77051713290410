import React, { useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  Table,
  useTable,
  MRT_ColumnDef,
  styled,
  MappingFileIcon,
  useTheme,
  InfoIcon,
  Grid,
  Tooltip,
  ConfirmationModal,
  useConfirmationModal,
  Box,
  Button,
  DownloadFileIcon,
} from '@cherre-frontend/ui';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { getMappingSets, getMappingSetsTotal } from '../queries/getMappingSets';
import { QueryResult } from '@cherre-frontend/data-fetching/src/recoil/graphQLSelector';
import { mappingSetsTableHasData, mappingSetsTableState } from '../recoil';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import NoMappings from './NoMappings';
import { BooleanStatusChip } from 'src/products/data-submission-portal/components/BooleanStatusChip';
import AddEditMappingSetDialogModal from './AddEditMappingSetDialog';
import UploadMappingButton from './UploadMappingButton';
import DeleteMappingSetButton from './DeleteMappingSetButton';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useDownloadMappingFile } from 'src/products/data-submission-portal/hooks/useDownloadMapping';
import { snakeCase } from 'lodash';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

const StyledMappingFileIcon = styled(MappingFileIcon)`
  width: 20px;
  height: auto;
  color: #43a047;
`;

type MappingsTableProps = {
  search: string | undefined;
};

const MappingsTable: React.FC<MappingsTableProps> = ({ search }) => {
  const theme = useTheme();

  // control table
  const [tableState, setTableState] = useCherreState(mappingSetsTableState);
  const data = useCherreValue(getMappingSets());
  const total = useCherreValue(getMappingSetsTotal());

  const downloadMappingFile = useDownloadMappingFile();

  const resultMode: 'no_mappings' | 'no_items_found' | 'table' = useMemo(() => {
    if (data && data.length > 0) {
      return 'table';
    } else if (search) {
      return 'no_items_found';
    } else {
      return 'no_mappings';
    }
  }, [data, search]);

  // control search / upload button visibility
  const setHasData = useCherreSetState(mappingSetsTableHasData);
  setHasData(resultMode !== 'no_mappings');

  // control delete mapping
  const isAdminUploadMappingEnabled = useFeatureFlag('DspAdminUploadMapping');
  const { modalProps, confirm } = useConfirmationModal();

  // control suspend
  const isSuspended = useIsSuspended();
  if (isSuspended && !tableState) {
    return null;
  }

  const columns: MRT_ColumnDef<QueryResult<typeof getMappingSets>[number]>[] =
    useMemo(
      () => [
        {
          accessorKey: 'mapping_set_description',
          header: 'Name',
          size: 350,
          Cell: ({ renderedCellValue, row }) => {
            return (
              <Link
                to={`/dsp/providers/${row.original.provider_id}/mapping/${row.original.mapping_set_id}`}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <StyledMappingFileIcon />
                {renderedCellValue}
              </Link>
            );
          },
        },
        {
          accessorKey: 'mapping_field.mapping_field_name',
          header: 'Mapping Type',
          size: 200,
          accessorFn: (row) => {
            return <span>{row.mapping_field.mapping_field_description}</span>;
          },
        },
        {
          accessorKey: 'mapping_field.is_single_mapping_set',
          header: '',
          Header: (
            <span style={{ whiteSpace: 'nowrap' }}>
              Auto assigned{' '}
              <Tooltip title='When Auto assigned mappings are set to YES, they are automatically associated with all properties. If set to NO, mappings of this type must be assigned to each property manually. Auto assigned mappings allow one Mapping per Type per Provider.'>
                <InfoIcon style={{ width: 16, height: 16 }} />
              </Tooltip>
            </span>
          ),
          size: 200,
          Cell: ({ renderedCellValue }) => (
            <BooleanStatusChip value={renderedCellValue as boolean} />
          ),
        },
        {
          accessorKey: 'updated_at_datetime',
          header: 'Last Updated',
          size: 0,
          maxSize: 40,
          accessorFn: (row) => {
            const last_values_update =
              row.mapping_set_values_aggregate.aggregate?.max
                ?.updated_at_datetime;
            const last_mapping_update = row.updated_at_datetime;
            const value =
              last_values_update && last_values_update > last_mapping_update
                ? last_values_update
                : last_mapping_update;
            return moment(value).format('MMM D, YYYY');
          },
        },
        {
          accessorKey: 'mapping_set_id',
          header: ' ',
          size: 0,
          maxSize: 20,
          Cell: ({ renderedCellValue, row }) => (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
              <Button style={{ minWidth: 'unset', padding: '6px 10px' }}>
                <Tooltip title='Download this file as an Excel spreadsheet.'>
                  <DownloadFileIcon
                    sx={{ height: '20px', width: '20px', display: 'flex' }}
                    onClick={() =>
                      downloadMappingFile(
                        row.original.mapping_set_id,
                        row.original.provider.provider_name,
                        row.original.organization.organization_label,
                        row.original.mapping_field.mapping_field_description,
                        `${snakeCase(
                          row.original.provider.provider_name
                        )}_${snakeCase(
                          row.original.mapping_field.mapping_field_description
                        )}.xlsx`
                      )
                    }
                  />
                </Tooltip>
              </Button>
              <DeleteMappingSetButton
                mappingSetID={Number(renderedCellValue)}
                confirmFunction={confirm}
              />
            </Box>
          ),
        },
      ],
      []
    );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    state: {
      ...tableState,
      columnVisibility: {
        mapping_set_id: isAdminUploadMappingEnabled ?? false,
      },
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.mapping_set_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  // When has data displays table. Otherwise, when search is empty, displays NoMappings component. Otherwise, displays NoItemsFound component
  return (
    <div>
      <AddEditMappingSetDialogModal confirmFunction={confirm} />
      <ConfirmationModal {...modalProps} />
      {resultMode === 'table' && (
        <div>
          <Container>
            <Table table={table} />
          </Container>
        </div>
      )}
      {resultMode === 'no_items_found' && (
        <NoItemsFound title='No Mappings Found' />
      )}
      {resultMode === 'no_mappings' && (
        <div>
          <NoMappings />
          <Grid container direction='column' alignItems='center'>
            <UploadMappingButton />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default MappingsTable;
