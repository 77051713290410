import {
  DefaultValue,
  GraphQLReturn,
  RecoilState,
  graphQLSelector,
  refine,
  selector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import {
  bool,
  nullable,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { graphql } from 'relay-runtime';
import {
  ReportTableStateRefine,
  createReportTableStateSelectors,
} from '../../components/Reports';
import { MonitorSubmissionsRoute } from '../../routes';
import { sys_property_batches_bool_exp } from '../submissions/__generated__/recoilSubmissionBatchesQuery.graphql';
import { recoilPropertyBatchesQuery } from './__generated__/recoilPropertyBatchesQuery.graphql';
import { recoilPropertyBatchesTotalQuery } from './__generated__/recoilPropertyBatchesTotalQuery.graphql';
import { mrtToHasuraSort } from './utils';
import { Filter } from './consts';
import { merge } from 'lodash';

export const monitorSubmissionsTableState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissions-table-state',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [
          {
            id: 'property_batch.properties_flattened_union.property_mapping',
            desc: true,
          },
          { id: 'property_batch.property.property_code', desc: false },
        ],
      }
    ),
  });
export const packageReportModalState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissions/package-report-modal-state',
    refine: refine.withDefault(
      refine.nullable(
        refine.object({
          property_batch_id: refine.number(),
          report_slug: refine.string(),
          property_id: refine.number(),
        })
      ),
      null
    ),
  }) as RecoilState<{
    property_batch_id: number;
    report_slug: string;
    property_id?: number;
  } | null>;

export const reportSlugModalState = selector({
  key: 'monitor-submissions/report-slug-modal-state',
  get: ({ get }) => {
    const selected = get(packageReportModalState);
    if (!selected) {
      return '';
    }
    return selected.report_slug;
  },
  set: ({ set }, newValue) => {
    if (!newValue || newValue instanceof DefaultValue) {
      set(packageReportModalState, null);
    } else {
      set(packageReportModalState, (old) => {
        if (!old) {
          return old;
        }
        return { ...old, report_slug: newValue };
      });
    }
  },
});

export const modalSearchState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissions/modal-search-state',
    refine: refine.withDefault(refine.string(), ''),
  });

export const modalTablesState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissions/modal-tables-state',
    refine: ReportTableStateRefine,
  });

export const reportSelectors = createReportTableStateSelectors(
  reportSlugModalState,
  modalTablesState,
  modalSearchState
);

export const monitorSubmissionsTableSearch =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissons-table-search',
    refine: withDefault(string(), ''),
  });

export const submissionOverviewDialogState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'open-submission',
    refine: withDefault(
      object({
        propertyBatchId: withDefault(nullable(number()), undefined),
        tab: withDefault(nullable(number()), undefined),
        submissionId: withDefault(nullable(number()), undefined),
      }),
      {
        propertyBatchId: undefined,
        submissionId: undefined,
        tab: 0,
      }
    ),
  });

export const submissionOverviewDialogTabState = selector({
  key: 'submission-overview-dialog-tab-state',
  get: ({ get }) => {
    return get(submissionOverviewDialogState).tab;
  },
  set: ({ set }, newValue) => {
    set(submissionOverviewDialogState, (old) => {
      return { ...old, tab: newValue };
    });
  },
});

export const submissionOverviewDialogPropertyBatchIdState = selector({
  key: 'submission-overview-dialog-property-batch-id-state',
  get: ({ get }) => {
    return get(submissionOverviewDialogState).propertyBatchId;
  },
  set: ({ set }, newValue) => {
    set(submissionOverviewDialogState, (old) => {
      return { ...old, propertyBatchId: newValue };
    });
  },
});

export const submissionOverviewDialogSubmissionIdState = selector({
  key: 'submission-overview-dialog-submission-id-state',
  get: ({ get }) => {
    return get(submissionOverviewDialogState).submissionId;
  },
});

export const monitorSubmissionsFiltersState =
  MonitorSubmissionsRoute.searchParamsSelectorFamily({
    key: 'monitor-submissions-table-filters',
    refine: withDefault(
      object({
        Type: withDefault(writableArray(number()), []),
        Fund: withDefault(writableArray(string()), []),
        Status: withDefault(writableArray(string()), []),
        Interval: withDefault(string(), ''),
        Period: withDefault(string(), ''),
        Provider: withDefault(writableArray(number()), []),
        Validation: withDefault(string(), ''),
        Stage: withDefault(writableArray(string()), []),
        Batch: withDefault(writableArray(number()), []),
      }),
      {
        Type: [],
        Fund: [],
        Status: [],
        Interval: '',
        Period: '',
        Provider: [],
        Validation: '',
        Stage: [],
        Batch: [],
      }
    ),
  });

export const monitorSubmissionsFilterState = selectorFamily({
  key: 'monitor-submissions-filter-state',
  get:
    (filter: Filter) =>
    ({ get }) =>
      get(monitorSubmissionsFiltersState)[filter],
  set:
    (filter: Filter) =>
    ({ set }, newValue) => {
      set(monitorSubmissionsFiltersState, (old) => {
        if (newValue instanceof DefaultValue) {
          delete old[filter];
          return old;
        }
        return { ...old, [filter]: newValue };
      });
    },
});

export const monitorSubmissionsPropertyBatchWhereClause = selectorFamily<
  sys_property_batches_bool_exp,
  sys_property_batches_bool_exp
>({
  key: 'monitor-submissions-property-batch-where-clause',
  get:
    (base_where_clause: sys_property_batches_bool_exp = {}) =>
    ({ get }) => {
      const search = get(monitorSubmissionsTableSearch);
      const parsedSearch = `%${search ?? ''}%`;

      const {
        Provider,
        Type,
        Period,
        Fund,
        Interval,
        Batch,
        Validation,
        Stage,
        Status,
      } = get(monitorSubmissionsFiltersState);

      const where_clause: sys_property_batches_bool_exp = {
        submission: { is_closed: { _eq: false } },
        property: {
          property_type: { property_type_slug: { _neq: 'investment' } },
        },
        _or: [
          { submission: { submission_name: { _ilike: parsedSearch } } },
          {
            submission: {
              provider: { provider_name: { _ilike: parsedSearch } },
            },
          },
          { property: { property_name: { _ilike: parsedSearch } } },
          { property: { address: { _ilike: parsedSearch } } },
          { property: { fund: { _ilike: parsedSearch } } },
          { property: { entity_id: { _ilike: parsedSearch } } },
          { property: { type: { _ilike: parsedSearch } } },
        ],
      };

      if (Interval) {
        const interval_where_clause: sys_property_batches_bool_exp = {
          submission: {
            reporting_period: { _eq: Interval },
          },
        };
        merge(where_clause, interval_where_clause);
      }

      if (Provider.length) {
        const provider_where_clause: sys_property_batches_bool_exp = {
          submission: {
            provider: { provider_id: { _in: Provider } },
          },
        };
        merge(where_clause, provider_where_clause);
      }

      if (Fund.length) {
        const fund_where_clause: sys_property_batches_bool_exp = {
          property: {
            fund: { _in: Fund },
          },
        };
        merge(where_clause, fund_where_clause);
      }

      if (Type.length) {
        const type_where_clause: sys_property_batches_bool_exp = {
          submission: {
            submission_type: {
              submission_type_id: { _in: Type },
            },
          },
        };
        merge(where_clause, type_where_clause);
      }

      if (Batch.length) {
        const batch_where_clause: sys_property_batches_bool_exp = {
          submission: {
            submission_id: { _in: Batch },
          },
        };
        merge(where_clause, batch_where_clause);
      }

      if (Stage.length) {
        const Stage_where_clause: sys_property_batches_bool_exp = {
          property_batch_stage_id: {
            _in: Stage.flatMap((item) =>
              typeof item == 'string' ? item.split(',') : item
            ).map(Number),
          },
        };
        merge(where_clause, Stage_where_clause);
      }

      if (Validation.length) {
        const validation_where_clause: sys_property_batches_bool_exp = {
          _and: [
            {
              _or: [
                {
                  property_batch_validations: {
                    is_valid: { _eq: false },
                    validation_mode: { _eq: 'hard' },
                  },
                },
                {
                  property_batch_datasets: {
                    property_batch_dataset_validations: {
                      is_valid: { _eq: false },
                      validation_mode: { _eq: 'hard' },
                    },
                  },
                },
              ],
            },
          ],
        };
        merge(where_clause, validation_where_clause);
      }

      if (Period.length) {
        const period_where_clause: sys_property_batches_bool_exp = {
          submission: {
            reporting_period_start_date: { _eq: Period },
          },
        };
        merge(where_clause, period_where_clause);
      }

      if (Status.length) {
        const status_where_clause: sys_property_batches_bool_exp = {
          _and: [
            {
              property_batches_monitoring: {
                _or: [
                  { batch_status: { _in: Status } },
                  { submission_status: { _in: Status } },
                ],
              },
            },
          ],
        };
        merge(where_clause, status_where_clause);
      }

      merge(where_clause, base_where_clause);

      return where_clause;
    },
});

export const getPropertyBatches = graphQLSelector({
  query: graphql`
    query recoilPropertyBatchesQuery(
      $where_clause: sys_property_batches_bool_exp!
      $limit: Int = 10
      $offset: Int = 0
      $order_by: [sys_property_batches_monitoring_order_by!] = {}
    ) {
      sys_property_batches_monitoring(
        where: { property_batch: $where_clause }
        limit: $limit
        offset: $offset
        order_by: $order_by
      ) {
        property_batch_id @required(action: THROW)
        datasets_total
        datasets_uploaded
        datasets_uploaded_ratio
        validations_total
        validations_failed
        submission_status
        batch_status
        property_batch @required(action: THROW) {
          is_valid
          updated_at_datetime
          property_batch_id
          property_batch_stage_id
          users_with_access_to_batch_count
          property {
            property_id
            address
            property_code
            property_name
            fund
            entity_id
          }
          properties_flattened_union {
            property_mapping
            entity_id
          }
          last_stage: property_batch_stage_transitions(
            limit: 1
            order_by: { transition_datetime: desc }
            where: { property_batch_stage_id: { _neq: 40 } }
          ) {
            property_batch_stage_id
          }
          reviewedPackageReports: property_batch_package_reports_aggregate(
            where: {
              is_reviewed: { _eq: true }
              property_batch: { property_batch_stage_id: { _eq: 20 } }
            }
          ) {
            aggregate {
              count
            }
          }
          submission {
            submission_id
            submission_type {
              submission_type_label
            }
            provider {
              provider_id
              provider_name
            }
            due_date
            reporting_period
            reporting_period_start_date
            reporting_period_end_date
            submission_name
            submission_status_description
          }
        }
      }
    }
  ` as GraphQLReturn<recoilPropertyBatchesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const tableState = get(monitorSubmissionsTableState);
      const where_clause = get(monitorSubmissionsPropertyBatchWhereClause({}));

      return {
        where_clause,
        limit: tableState?.pagination.pageSize ?? 10,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: mrtToHasuraSort(tableState.sorting),
      };
    },
  mapResponse: (response) => {
    return response.sys_property_batches_monitoring;
  },
});

export type Batch = NonNullable<
  recoilPropertyBatchesQuery['response']['sys_property_batches_monitoring'][number]
>;

export const getPropertyBatchesTotal = graphQLSelector({
  query: graphql`
    query recoilPropertyBatchesTotalQuery(
      $where_clause: sys_property_batches_bool_exp!
    ) {
      total: sys_property_batches_aggregate(where: $where_clause) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilPropertyBatchesTotalQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        where_clause: get(monitorSubmissionsPropertyBatchWhereClause({})),
      };
    },
  mapResponse: (response) => response.total,
});
