import { useCherreValue } from '@cherre-frontend/data-fetching';
import React from 'react';
import NoContentMessage from './NoContentMessage';
import { ReviewerSection } from './ReviewerSection';
import { SubmissionUsersBaseTab } from './SubmissionUsersTab.base';
import { getSubmissionUsers } from './recoil';

type ReviewersTabProps = {
  batchName: string;
  batchId: number;
  entityId: string;
};

export const ReviewersTab: React.FC<ReviewersTabProps> = ({
  batchName,
  batchId,
  entityId,
}) => {
  const submissionUsers = useCherreValue(getSubmissionUsers(batchId));

  const noReviewers =
    submissionUsers?.reviewers_1.length === 0 &&
    submissionUsers?.reviewers_2.length === 0 &&
    submissionUsers?.reviewers_3.length === 0;

  const ctaLink = React.useMemo(() => {
    const users = [
      ...(submissionUsers?.reviewers_1 || []),
      ...(submissionUsers?.reviewers_2 || []),
      ...(submissionUsers?.reviewers_3 || []),
    ];
    const emails = [
      ...new Set(
        users.map((user) => user.user.sakura_user?.email).filter(Boolean)
      ),
    ].join(',');
    const subject = `Action Required in Data Submissions Portal - Entity ID ${entityId} • ${batchName} - ${batchId}`;
    return `mailto:${emails}?subject=${subject}`;
  }, [submissionUsers]);

  return (
    <SubmissionUsersBaseTab
      ctaTitle='Contact Reviewers'
      ctaLink={ctaLink}
      hideCta={noReviewers}
      userListComponent={
        noReviewers ? (
          <NoContentMessage message='No reviewers assigned.' />
        ) : (
          <>
            {submissionUsers?.reviewers_1?.length ? (
              <ReviewerSection
                title='Reviewer Level 1'
                reviewers={submissionUsers.reviewers_1}
              />
            ) : null}
            {submissionUsers?.reviewers_2?.length ? (
              <ReviewerSection
                title='Reviewer Level 2'
                reviewers={submissionUsers.reviewers_2}
              />
            ) : null}
            {submissionUsers?.reviewers_3?.length ? (
              <ReviewerSection
                title='Reviewer Level 3'
                reviewers={submissionUsers.reviewers_3}
              />
            ) : null}
          </>
        )
      }
    />
  );
};
