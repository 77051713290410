import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getClickCardDataPropertyDatasetsByIdsQuery } from './__generated__/getClickCardDataPropertyDatasetsByIdsQuery.graphql';
import { getClickCardDataPropertyMappingSetsByIdsQuery } from './__generated__/getClickCardDataPropertyMappingSetsByIdsQuery.graphql';

export const getClickCardPropertyDatasetsByIds = graphQLSelector({
  query: graphql`
    query getClickCardDataPropertyDatasetsByIdsQuery($dataset_ids: [Int!]) {
      sys_datasets(where: { dataset_id: { _in: $dataset_ids } }) {
        dataset_description
      }
    }
  ` as GraphQLReturn<getClickCardDataPropertyDatasetsByIdsQuery>,
  mapResponse: (resp) => {
    return resp.sys_datasets.map((obj) => obj.dataset_description);
  },
});

export const getClickCardPropertyDataMappingsByIds = graphQLSelector({
  query: graphql`
    query getClickCardDataPropertyMappingSetsByIdsQuery($mapping_ids: [Int!]) {
      sys_mapping_sets(where: { mapping_set_id: { _in: $mapping_ids } }) {
        mapping_set_description
      }
    }
  ` as GraphQLReturn<getClickCardDataPropertyMappingSetsByIdsQuery>,
  mapResponse: (resp) => {
    return resp.sys_mapping_sets.map((obj) => obj.mapping_set_description);
  },
});
