import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useGetTargetSchemaQuery } from './__generated__/useGetTargetSchemaQuery.graphql';

export enum TargetSchemaSlug {
  MAPPING = 'mapping',
  EXCLUDED_PROPERTY = 'excludedProperty',
  MASTER_COA = 'masterCoa',
  ACCOUNT_GROUPINGS = 'accountGroupings',
  PROPERTY = 'property',
}

export const $unifiedIngestGetTargetSchemaQuery = graphQLSelector({
  query: graphql`
    query useGetTargetSchemaQuery(
      $params: unified_ingest_target_schema_get_params!
    ) {
      unified_ingest_target_schema_get(params: $params) {
        target_schema
      }
    }
  ` as GraphQLReturn<useGetTargetSchemaQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_target_schema_get.target_schema;
  },
});

export const useUnifiedIngestGetTargetSchema = (
  slug: TargetSchemaSlug,
  mappingFieldId?: number
) => {
  const data = useCherreValue(
    slug
      ? $unifiedIngestGetTargetSchemaQuery({
          params: {
            slug,
            mapping_field_id: mappingFieldId,
          },
        })
      : constSelector([])
  );

  return {
    data,
  };
};
