import React from 'react';
import {
  Button,
  PageContainer,
  PageHeader,
  PageContent,
} from '@cherre-frontend/ui';
import UploadMasterFileDialog from './Panels/Upload/UploadMasterFileDialog';
import {
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { isTableEmpty, uploadMasterFileDialogOpenState } from './recoil';
import MasterFilesTable from './Panels/Table/MasterFilesTable';

export const UploadMasterFilesButton: React.FC = () => {
  const setUploadMasterFileDialogOpen = useCherreSetState(
    uploadMasterFileDialogOpenState
  );

  return (
    <Button
      variant='contained'
      onClick={() => setUploadMasterFileDialogOpen(true)}
      testID='testButton'
    >
      Upload File
    </Button>
  );
};

const MasterFilesPage: React.FC = () => {
  const isMasterFileTableEmpty = useCherreValue(isTableEmpty);

  return (
    <>
      <PageHeader title='Master Files'>
        {!isMasterFileTableEmpty && <UploadMasterFilesButton />}
      </PageHeader>
      <PageContent>
        <MasterFilesTable />
        <UploadMasterFileDialog />
      </PageContent>
    </>
  );
};

const MasterFileWrapper: React.FC = () => {
  return (
    <PageContainer id='MasterFilesPage'>
      <MasterFilesPage />
    </PageContainer>
  );
};

MasterFileWrapper.displayName = 'MasterFileWrapper';

export default MasterFileWrapper;
