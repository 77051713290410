import {
  GraphQLReturn,
  NotFoundError,
  QueryResult,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import {
  queryTsaDatasetsSubscription,
  queryTsaDatasetsSubscription$data,
} from './__generated__/queryTsaDatasetsSubscription.graphql';

export const $tsaDatasets = graphQLSelector({
  query: graphql`
    subscription queryTsaDatasetsSubscription($property_batch_id: Int!) {
      sys_property_batches_by_pk(property_batch_id: $property_batch_id) {
        property_batch_datasets {
          property_batch_dataset_id
          updated_at_datetime
          is_valid
          dataset_row_count
          validated_datetime
          dataset @required(action: THROW) {
            dataset_label
            dataset_id
            is_tsa_enabled
          }
          dataset_status {
            dataset_status_description
          }
          batch_file {
            file_name
          }
          warning_count: property_batch_dataset_validations_aggregate(
            where: {
              is_valid: { _eq: false }
              validation_mode: { _eq: "soft" }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  ` as GraphQLReturn<queryTsaDatasetsSubscription>,
  mapVariables: (property_batch_id: number) => () => ({ property_batch_id }),
  swr: (o, n) => o.params.property_batch_id !== n.params.property_batch_id,
  mapResponse: (rawResponse) => {
    const propertyBatch =
      rawResponse.sys_property_batches_by_pk as queryTsaDatasetsSubscription$data['sys_property_batches_by_pk'];

    if (!propertyBatch) {
      throw new NotFoundError('Property batch not found');
    }

    return {
      datasets: propertyBatch.property_batch_datasets
        .filter((d) => d.dataset.is_tsa_enabled)
        .map((batchDataset) => {
          return {
            id: batchDataset.property_batch_dataset_id,
            name: batchDataset.dataset.dataset_label + ' (Adjusted)',
            validation: batchDataset.dataset_status.dataset_status_description,
            isValid: batchDataset.is_valid,
            warningCount: batchDataset.warning_count.aggregate?.count ?? 0,
            rowCount: batchDataset.dataset_row_count,
            filename: batchDataset.batch_file?.file_name,
            updatedOn: batchDataset.updated_at_datetime,
          };
        }),
    };
  },
});

export type TsaDataset = QueryResult<typeof $tsaDatasets>['datasets'][number];
