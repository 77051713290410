export const FILTERS = [
  'Type',
  'Fund',
  'Status',
  'Interval',
  'Period',
  'Provider',
  'Validation',
  'Stage',
  'Batch',
] as const;

export type Filter = (typeof FILTERS)[number];
