import {
  Box,
  BoxProps,
  Button,
  Grid,
  Typography,
  TypographyProps,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import { DialogHeader } from '../DialogHeader';
import {
  useCherreEventWithRecoil,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Stage,
  assignDatasetsToPropertyIdState,
  dialogStage,
  enabledValidationsState,
  selectedDatasetsState,
} from '../../localState';
import ValidationRuleSelection from '../ValidationRuleSelection';
import { requiredValidationSlugs } from '../../consts';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const DatasetButtonBox = styled<React.FC<BoxProps & { active?: boolean }>>(Box)`
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey[600]};
  background-color: ${({ theme, active }) =>
    active ? theme.palette.grey[100] : 'white'};
  padding: 6px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const DatasetHeaderBox = styled<React.FC<BoxProps & { active?: boolean }>>(Box)`
  color: ${({ theme }) => theme.palette.grey[600]};
  background-color: 'white';
  padding: 6px;
  margin-bottom: 6px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const InstructionText = styled<React.FC<TypographyProps>>(Typography)`
  font-size: 14px;
`;

type SelectValidationsProps = {
  numRowsSelected: number;
  onClose: () => void;
};

type OpenDataset = {
  id: number;
  tsa: boolean;
} | null;

const SelectValidations: React.FC<SelectValidationsProps> = ({
  numRowsSelected,
  onClose,
}) => {
  const datasets = useCherreValue(selectedDatasetsState);
  const unifiedIngestEnabled = useFeatureFlag('DSPNewIngestion');

  const [enabledValidations, setEnabledValidations] = useCherreState(
    enabledValidationsState
  );

  const assignDatasetsToPropertyId = useCherreValue(
    assignDatasetsToPropertyIdState
  );

  const firstDataSetId = datasets?.[0].dataset_id;

  const [openDataset, setOpenDataset] = React.useState<OpenDataset>(
    firstDataSetId ? { id: firstDataSetId, tsa: false } : null
  );

  const selectedDataset = datasets?.find(
    (dataset) => dataset.dataset_id === openDataset?.id
  );

  const selectedDatasetRules = selectedDataset?.dataset_validation_rules ?? [];

  const selectedDatasetRulesForTsaOrStandard = openDataset?.tsa
    ? selectedDatasetRules.filter((rule) => rule.validation_params.tsa)
    : selectedDatasetRules;

  const selectedDatasetSortedRules = [
    ...selectedDatasetRulesForTsaOrStandard,
  ].sort((rule) =>
    requiredValidationSlugs.includes(rule.validation_slug) ? -1 : 1
  );

  const currentEnabledValidations =
    enabledValidations?.[openDataset?.tsa ? 'tsa' : 'standard'];

  const onNext = useCherreEventWithRecoil(
    'user went from select validations to select cross file validations',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectCrossFileValidations)
  );

  const onPrevDatasetTemplates = useCherreEventWithRecoil(
    'user went from select validations to select dataset templates',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectDatasetTemplate)
  );

  const onPrevDatasets = useCherreEventWithRecoil(
    'user went from select validations to select datasets',
    (ctx) => () => ctx.recoil.set(dialogStage, Stage.SelectDatasets)
  );

  const onEnabledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    tsa: boolean | undefined
  ) => {
    if (!enabledValidations) {
      throw new Error('enabledValidations is not defined');
    }

    const target = tsa ? 'tsa' : 'standard';

    if (e.target.checked) {
      setEnabledValidations({
        ...enabledValidations,
        [target]: {
          ...enabledValidations[target],
          [id]: 'soft',
        },
      });
    } else {
      const currentValidations = enabledValidations[target];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...rest } = currentValidations;

      setEnabledValidations({
        ...enabledValidations,
        [target]: rest,
      });
    }
  };

  const onValidationModeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    tsa: boolean | undefined
  ) => {
    if (!enabledValidations) {
      throw new Error('enabledValidations is not defined');
    }

    const target = tsa ? 'tsa' : 'standard';

    setEnabledValidations({
      ...enabledValidations,
      [target]: {
        ...enabledValidations[target],
        [id]: e.target.value as 'soft' | 'hard',
      },
    });
  };

  return (
    <>
      <DialogHeader
        title='Select Validations'
        subtitle={`${
          assignDatasetsToPropertyId ? 1 : numRowsSelected
        } Properties Selected • ${datasets?.length} Datasets`}
      />

      <InstructionText marginTop={3}>
        Select validation rules for each dataset and hard or soft check
        specifications before advancing.
      </InstructionText>

      <Box my={3}>
        <Grid container direction='row' alignItems='flex-start' gap={2}>
          <Box flex={2}>
            {datasets?.map((dataset) => {
              if (!dataset.is_tsa_enabled) {
                return (
                  <DatasetButtonBox
                    key={dataset.dataset_id}
                    active={dataset.dataset_id === openDataset?.id}
                    onClick={() =>
                      setOpenDataset({
                        id: dataset.dataset_id,
                        tsa: false,
                      })
                    }
                  >
                    {dataset.dataset_label}
                  </DatasetButtonBox>
                );
              }

              return (
                <Box>
                  <DatasetHeaderBox>{dataset.dataset_label}</DatasetHeaderBox>
                  <Box ml={4}>
                    <DatasetButtonBox
                      key={dataset.dataset_id}
                      active={
                        dataset.dataset_id === openDataset?.id &&
                        openDataset.tsa === false
                      }
                      onClick={() =>
                        setOpenDataset({
                          id: dataset.dataset_id,
                          tsa: false,
                        })
                      }
                    >
                      Standard
                    </DatasetButtonBox>
                    <DatasetButtonBox
                      key={dataset.dataset_id + '_tsa'}
                      active={
                        dataset.dataset_id === openDataset?.id &&
                        openDataset.tsa === true
                      }
                      onClick={() =>
                        setOpenDataset({
                          id: dataset.dataset_id,
                          tsa: true,
                        })
                      }
                    >
                      Topside Adjustment
                    </DatasetButtonBox>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Grid container gap={3} flex={3}>
            {selectedDatasetSortedRules.map((rule) => {
              return (
                <ValidationRuleSelection
                  id={rule.dataset_validation_rule_id}
                  title={rule.validation_label}
                  description={rule.validation_description || ''}
                  slug={rule.validation_slug}
                  onEnabledChange={(e) => {
                    onEnabledChange(
                      e,
                      rule.dataset_validation_rule_id,
                      openDataset?.tsa
                    );
                  }}
                  onValidationModeChange={(e) => {
                    onValidationModeChange(
                      e,
                      rule.dataset_validation_rule_id,
                      openDataset?.tsa
                    );
                  }}
                  enabled={
                    !!currentEnabledValidations &&
                    Object.keys(currentEnabledValidations).includes(
                      rule.dataset_validation_rule_id.toString()
                    )
                  }
                  value={
                    currentEnabledValidations?.[
                      rule.dataset_validation_rule_id
                    ] || 'soft'
                  }
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='nowrap'
      >
        <Grid item>
          <Button
            size='medium'
            variant='outlined'
            color='primary'
            onClick={
              unifiedIngestEnabled ? onPrevDatasetTemplates : onPrevDatasets
            }
            sx={{
              border: 'none',
            }}
          >
            Prev
          </Button>
        </Grid>

        <>
          <Grid
            item
            container
            direction='row'
            gap={2}
            justifyContent='flex-end'
          >
            <Grid item>
              <Button
                size='medium'
                variant='outlined'
                color='primary'
                onClick={onClose}
                sx={{
                  border: 'none',
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                size='medium'
                variant='contained'
                color='primary'
                //   disabled={!mappingIdToAssign}
                onClick={onNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </>
      </Grid>
    </>
  );
};

SelectValidations.displayName = 'SelectValidations';

export default SelectValidations;
