/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Button,
  Grid,
  PageContainer,
  PageContent,
  PageHeader,
  Typography,
  IconButton,
  ArrowBackIcon,
  CloseIcon,
  MuiAutocomplete as Autocomplete,
  useTheme,
  Box,
  DownloadFileIcon,
} from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';
import {
  useCherreValue,
  useCherreSetState,
  useCherreStateDebounced,
} from '@cherre-frontend/data-fetching';
import MappingSetValuesTable from './components/MappingDetailsTable';
import AddEditMappingDialogModal from './components/AddEditMappingDialog';
import {
  mappingSetValuesTableFilters,
  addEditMappingDialogState,
  addEditMappingFormState,
  getTargetDropdowns,
} from './recoil';
import { getMappingSet } from './queries/getMappingSet';
import DeleteMappingDialogModal from './components/DeleteMappingDialog';
import {
  HeaderGrid,
  FilterTextField,
  PanelStyled,
  ClearButton,
} from './styles';
import { providerDetailsTabState } from '../provider-detail/recoil';
import { ProviderDetailTabValues } from '../provider-detail';
import { getSourceCodeDropdown } from './queries/getSourceCodeDropdown';
import { MappingTypes } from './types';
import { useDownloadMappingFile } from '../../hooks/useDownloadMapping';
import { MappingDetailRoute } from '../../routes';
import { snakeCase } from 'lodash';

const FilterWrapper: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div>
      <Typography
        variant='body1'
        style={{ textTransform: 'uppercase', fontWeight: 600 }}
      >
        {label}
      </Typography>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          paddingTop: '12px',
        }}
      >
        {children}
      </Grid>
    </div>
  );
};

const MappingDetailPage: React.FC = () => {
  const theme = useTheme();
  const mappingSetId = useCherreValue(
    MappingDetailRoute.routeParamSelector
  )?.mapping_set_id;
  const setTabs = useCherreSetState(providerDetailsTabState);
  const mappingSet = useCherreValue(getMappingSet());
  const [filters, , setFilters] = useCherreStateDebounced(
    mappingSetValuesTableFilters
  );

  const setAddEditMappingFormState = useCherreSetState(addEditMappingFormState);
  const setAddEditMappingDialogState = useCherreSetState(
    addEditMappingDialogState
  );

  const downloadMappingFile = useDownloadMappingFile();

  const mappingType = mappingSet?.mapping_field.mapping_field_name;

  const targetCodeDropdownOptions = useCherreValue(
    getTargetDropdowns(mappingType as string)
  );
  const sourceCodeDropdownOptions = useCherreValue(getSourceCodeDropdown());

  const isFilterEmpty = useMemo(() => {
    const isEmpty = Object.values(filters ?? {}).every((value) => {
      return (
        !value || (typeof value === 'object' && Object.keys(value).length === 0)
      );
    });
    return isEmpty;
  }, [filters]);

  //compute last update
  const lastUpdate = useMemo(() => {
    const lastValuesUpdate =
      mappingSet?.mapping_set_values_aggregate.aggregate?.max
        ?.updated_at_datetime;
    const lastSetUpdate = mappingSet?.updated_at_datetime;
    return lastValuesUpdate && lastSetUpdate && lastValuesUpdate > lastSetUpdate
      ? lastValuesUpdate
      : lastSetUpdate;
  }, [mappingSet]);

  const customAttributes = (mappingSet?.mapping_field?.custom_attributes ??
    []) as { slug: string; label: string }[];

  return (
    <>
      <HeaderGrid container direction='row' justifyContent='flex-start'>
        <IconButton
          style={{ height: '48px', width: '48px', alignSelf: 'center' }}
          component={Link}
          onClick={() => setTabs(ProviderDetailTabValues.MAPPINGS)}
          to={`/dsp/providers/${mappingSet?.provider.provider_id}`}
        >
          <ArrowBackIcon style={{ fill: theme.palette.grey[900] }} />
        </IconButton>
        <PageHeader
          title={`${mappingSet?.mapping_field.mapping_field_description} Mapping`}
          subtitle={
            <span>
              {mappingSet?.mapping_set_description} &bull; Last Update:{' '}
              {lastUpdate ? moment(lastUpdate).format('MMM D, YYYY') : ''}
            </span>
          }
        >
          <Button
            sx={{ height: '40px' }}
            onClick={() =>
              downloadMappingFile(
                mappingSetId,
                mappingSet?.provider.provider_name ?? '',
                mappingSet?.organization.organization_label ?? '',
                mappingSet?.mapping_field.mapping_field_description ?? '',
                `${snakeCase(
                  mappingSet?.provider.provider_name ?? ''
                )}_${snakeCase(
                  mappingSet?.mapping_field.mapping_field_description ?? ''
                )}.xlsx`
              )
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <DownloadFileIcon sx={{ width: '15px', height: '15px' }} />
              <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                Download File
              </Typography>
            </Box>
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setAddEditMappingDialogState({ isOpen: true, type: 'Add' });
              setAddEditMappingFormState((prev) => ({
                ...prev,
                custom_attributes: customAttributes.map((attr) => ({
                  ...attr,
                  value: '',
                })),
              }));
            }}
          >
            Add Mapping
          </Button>
        </PageHeader>
      </HeaderGrid>
      <PageContent
        style={{
          flex: 'unset',
          marginBottom: '20px',
          paddingBottom: '20px',
        }}
      >
        <AddEditMappingDialogModal
          providerName={mappingSet?.provider.provider_name as string}
          targetOrganization={
            mappingSet?.organization.organization_label as string
          }
          mappingType={mappingType as MappingTypes}
          organizationId={mappingSet?.organization_id as number}
          mappingSetId={mappingSet?.mapping_set_id as number}
        />
        <DeleteMappingDialogModal />
        <Grid display='flex' columnGap={'50px'} rowGap={'20px'} flexWrap='wrap'>
          <FilterWrapper label='Source'>
            <Autocomplete
              options={sourceCodeDropdownOptions ?? []}
              getOptionLabel={(option) => option.label}
              style={{ width: '240px', height: '34px' }}
              size='small'
              value={filters?.source_code_filter}
              onChange={(_, value) =>
                setFilters((s) => {
                  return {
                    ...s,
                    source_code_filter: value
                      ? {
                          label: value.label,
                          value: value.value ?? '',
                        }
                      : null,
                  };
                })
              }
              renderInput={(params) => (
                <FilterTextField
                  {...params}
                  variant='outlined'
                  label='Source Code'
                  size='small'
                />
              )}
            />
            <FilterTextField
              variant='outlined'
              label='Source Label'
              size='small'
              value={filters?.source_label_filter}
              onChange={(e) =>
                setFilters((s) => ({
                  ...s,
                  source_label_filter: e.target.value,
                }))
              }
              InputProps={{
                endAdornment: filters?.source_label_filter && (
                  <ClearButton
                    onClick={() =>
                      setFilters((s) => ({ ...s, source_label_filter: '' }))
                    }
                  >
                    <CloseIcon fontSize='small' />
                  </ClearButton>
                ),
              }}
            />
          </FilterWrapper>
          <FilterWrapper label='Target'>
            <Autocomplete
              options={targetCodeDropdownOptions ?? []}
              getOptionLabel={(option) => option.label}
              style={{ width: '240px', height: '34px' }}
              size='small'
              value={filters?.target_code_filter}
              onChange={(_, value) =>
                setFilters((s) => {
                  return {
                    ...s,
                    target_code_filter: value
                      ? {
                          label: value.label,
                          value: value.value ?? '',
                        }
                      : null,
                  };
                })
              }
              renderInput={(params) => (
                <FilterTextField
                  {...params}
                  variant='outlined'
                  label='Target Code'
                  size='small'
                />
              )}
            />
            <FilterTextField
              variant='outlined'
              label='Target Label'
              size='small'
              value={filters?.target_label_filter}
              onChange={(e) =>
                setFilters((s) => ({
                  ...s,
                  target_label_filter: e.target.value,
                }))
              }
              InputProps={{
                endAdornment: filters?.target_label_filter && (
                  <ClearButton
                    onClick={() =>
                      setFilters((s) => ({ ...s, target_label_filter: '' }))
                    }
                  >
                    <CloseIcon fontSize='small' />
                  </ClearButton>
                ),
              }}
            />
          </FilterWrapper>
          {customAttributes?.map((att) => (
            <FilterWrapper key={att.slug} label={att.label}>
              <FilterTextField
                variant='outlined'
                label={att.label}
                size='small'
                value={filters?.custom_attribute_filters?.[att.slug] ?? ''}
                onChange={(e) => {
                  setFilters((s) => ({
                    ...s,
                    custom_attribute_filters: {
                      ...s.custom_attribute_filters,
                      [att.slug]: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  endAdornment: filters?.custom_attribute_filters?.[
                    att.slug
                  ] && (
                    <ClearButton
                      onClick={() => {
                        setFilters((s) => {
                          const newCustomAttributeFilters = {
                            ...s.custom_attribute_filters,
                          };
                          delete newCustomAttributeFilters[att.slug];
                          return {
                            ...s,
                            custom_attribute_filters: newCustomAttributeFilters,
                          };
                        });
                      }}
                    >
                      <CloseIcon fontSize='small' />
                    </ClearButton>
                  ),
                }}
              />
            </FilterWrapper>
          ))}
        </Grid>
        <Grid display={'flex'} marginTop={'20px'} justifyContent={'flex-end'}>
          {!isFilterEmpty && (
            <Button
              variant='outlined'
              style={{ fontWeight: 600 }}
              onClick={() =>
                setFilters({
                  source_code_filter: null,
                  source_label_filter: '',
                  target_code_filter: null,
                  target_label_filter: '',
                  custom_attribute_filters: {},
                })
              }
            >
              Clear All
            </Button>
          )}
        </Grid>
      </PageContent>
      <PageContent>
        <PanelStyled id='mappings'>
          <MappingSetValuesTable
            providerName={mappingSet?.provider.provider_name as string}
            targetOrganization={
              mappingSet?.organization.organization_label as string
            }
            customAttributes={customAttributes}
          />
        </PanelStyled>
      </PageContent>
    </>
  );
};

const MappingDetailWrapper: React.FC = () => {
  return (
    <PageContainer id='dsp/mapping-detail-page'>
      <MappingDetailPage />
    </PageContainer>
  );
};

MappingDetailWrapper.displayName = 'MappingDetailWrapper';

export default MappingDetailWrapper;
