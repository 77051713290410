/**
 * @generated SignedSource<<495b43f0e39f521443411882ffc81eda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPropertyBatchDatasetNewQuery$variables = {
  id?: number | null;
};
export type getPropertyBatchDatasetNewQuery$data = {
  readonly sys_property_batch_datasets: ReadonlyArray<{
    readonly dataset: {
      readonly dataset_description: string;
      readonly dataset_label: string;
      readonly unified_ingest_schemas: any | null;
    } | null;
    readonly dataset_id: number | null;
    readonly property_batch: {
      readonly property: {
        readonly provider: {
          readonly ingestion_config: any | null;
          readonly provider_id: number;
        } | null;
      };
      readonly property_id: number;
      readonly submission: {
        readonly submission_type_id: number;
      };
      readonly submission_id: number;
    };
    readonly property_batch_dataset_id: number;
    readonly property_batch_id: number;
  }>;
};
export type getPropertyBatchDatasetNewQuery = {
  response: getPropertyBatchDatasetNewQuery$data;
  variables: getPropertyBatchDatasetNewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "property_batch",
            "value": {
              "submission": {
                "is_closed": {
                  "_neq": true
                }
              }
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_dataset_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_datasets",
    "kind": "LinkedField",
    "name": "sys_property_batch_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_datasets",
        "kind": "LinkedField",
        "name": "dataset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unified_ingest_schemas",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_providers",
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ingestion_config",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provider_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submission_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_submissions",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submission_type_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getPropertyBatchDatasetNewQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getPropertyBatchDatasetNewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d7256f5d476874d63279be69e1941a97",
    "id": null,
    "metadata": {},
    "name": "getPropertyBatchDatasetNewQuery",
    "operationKind": "query",
    "text": "query getPropertyBatchDatasetNewQuery(\n  $id: Int\n) {\n  sys_property_batch_datasets(where: {property_batch_dataset_id: {_eq: $id}, property_batch: {submission: {is_closed: {_neq: true}}}}, limit: 1) {\n    property_batch_dataset_id\n    dataset_id\n    dataset {\n      dataset_description\n      dataset_label\n      unified_ingest_schemas\n    }\n    property_batch_id\n    property_batch {\n      property {\n        provider {\n          ingestion_config\n          provider_id\n        }\n      }\n      property_id\n      submission_id\n      submission {\n        submission_type_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7db1f248c6b1e4de825e615971c895a9";

export default node;
