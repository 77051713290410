import React from 'react';
import { Box, Typography, styled } from '@cherre-frontend/ui';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

type NoContentMessageProps = {
  body: string;
};

const NoContentMessage: React.FC<NoContentMessageProps> = ({ body }) => {
  return (
    <Container>
      <Typography variant='body1' color='textSecondary'>
        {body}
      </Typography>
    </Container>
  );
};

export default NoContentMessage;
