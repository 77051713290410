import { z } from 'zod';
import moment, { Moment } from 'moment';

const createErrorMap = (message: string) => ({ errorMap: () => ({ message }) });

export const INTERVALS = ['Monthly', 'Quarterly'] as const;

export const createBatchSchema = z.object({
  provider_id: z.number(createErrorMap('Provider')),
  submission_types: z.array(z.number(), createErrorMap('Submission Type')),
  submission_interval: z.enum(INTERVALS, createErrorMap('Submission Interval')),
  submission_period: z.custom<Moment>(moment.isMoment, 'Submission Period'),
  due_date: z.number(createErrorMap('Due Date')),
  fiscal_year_flag: z.boolean(createErrorMap('Fiscal Year Flag')),
});

export type CreateBatchInfo = z.TypeOf<typeof createBatchSchema>;
