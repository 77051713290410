/**
 * @generated SignedSource<<017c0a97f99520505546092c05986b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type get_batch_name_params = {
  provider_id: number;
  submission_interval: string;
  submission_period: string;
  submission_type: number;
};
export type queriesGetSubmissionNameQuery$variables = {
  params: get_batch_name_params;
};
export type queriesGetSubmissionNameQuery$data = {
  readonly _sys_get_batch_name: {
    readonly name: string;
  };
};
export type queriesGetSubmissionNameQuery = {
  response: queriesGetSubmissionNameQuery$data;
  variables: queriesGetSubmissionNameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "get_batch_name_output",
    "kind": "LinkedField",
    "name": "_sys_get_batch_name",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queriesGetSubmissionNameQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queriesGetSubmissionNameQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e6855deec8e64e581e1eb29287eb42d",
    "id": null,
    "metadata": {},
    "name": "queriesGetSubmissionNameQuery",
    "operationKind": "query",
    "text": "query queriesGetSubmissionNameQuery(\n  $params: get_batch_name_params!\n) {\n  _sys_get_batch_name(params: $params) {\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa25448bbbca03d72a4277fd7dabe1af";

export default node;
