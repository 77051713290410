import { selectorFamily } from '@cherre-frontend/data-fetching';
import { namespace } from '../consts';
import { Provider, getProviders } from './queries';

export const searchProvidersByName = selectorFamily<Provider[], string | null>({
  key: namespace('search-providers-by-name'),
  scoped: true,
  get:
    (search) =>
    ({ get }) => {
      return get(
        getProviders({
          where: search
            ? {
                _and: [
                  { _or: [{ properties: {} }, { providers_properties: {} }] },
                  { provider_name: { _ilike: `%${search}%` } },
                ],
              }
            : { _or: [{ properties: {} }, { providers_properties: {} }] },
          order_by: [{ provider_name: 'asc' }],
          limit: 10,
        })
      );
    },
});
