/**
 * @generated SignedSource<<98fa1706c9f7a293bf5188d0f28287c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getDatasetsQuery$variables = {};
export type getDatasetsQuery$data = {
  readonly sys_datasets: ReadonlyArray<{
    readonly dataset_id: number;
    readonly dataset_label: string;
    readonly dataset_slug: string;
    readonly dataset_validation_rules: ReadonlyArray<{
      readonly dataset_validation_rule_id: number;
      readonly validation_description: string | null;
      readonly validation_label: string;
      readonly validation_params: any;
      readonly validation_slug: string;
    }>;
    readonly is_tsa_enabled: boolean;
  }>;
};
export type getDatasetsQuery = {
  response: getDatasetsQuery$data;
  variables: getDatasetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "dataset_label": "asc"
        }
      }
    ],
    "concreteType": "sys_datasets",
    "kind": "LinkedField",
    "name": "sys_datasets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataset_slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_tsa_enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order_by",
            "value": {
              "validation_label": "asc"
            }
          },
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "is_active": {
                "_eq": true
              }
            }
          }
        ],
        "concreteType": "sys_dataset_validation_rules",
        "kind": "LinkedField",
        "name": "dataset_validation_rules",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validation_slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validation_label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validation_description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataset_validation_rule_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validation_params",
            "storageKey": null
          }
        ],
        "storageKey": "dataset_validation_rules(order_by:{\"validation_label\":\"asc\"},where:{\"is_active\":{\"_eq\":true}})"
      }
    ],
    "storageKey": "sys_datasets(order_by:{\"dataset_label\":\"asc\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getDatasetsQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getDatasetsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e04b42d0f6ac14b47b2ae0e00473157f",
    "id": null,
    "metadata": {},
    "name": "getDatasetsQuery",
    "operationKind": "query",
    "text": "query getDatasetsQuery {\n  sys_datasets(order_by: {dataset_label: asc}) {\n    dataset_label\n    dataset_id\n    dataset_slug\n    is_tsa_enabled\n    dataset_validation_rules(where: {is_active: {_eq: true}}, order_by: {validation_label: asc}) {\n      validation_slug\n      validation_label\n      validation_description\n      dataset_validation_rule_id\n      validation_params\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1e76c288fc36c6ebcb5dffae721a8ec";

export default node;
