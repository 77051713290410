/**
 * @generated SignedSource<<91caabd75fa4015fed899318296725c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getMappingSetsTotalSubscription$variables = {
  id?: number | null;
  search?: string | null;
};
export type getMappingSetsTotalSubscription$data = {
  readonly total: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getMappingSetsTotalSubscription = {
  response: getMappingSetsTotalSubscription$data;
  variables: getMappingSetsTotalSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": "%",
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_ilike",
    "variableName": "search"
  }
],
v2 = [
  {
    "alias": "total",
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "provider_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.0"
              },
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "fields": (v1/*: any*/),
                            "kind": "ObjectValue",
                            "name": "mapping_set_description"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.0"
                      },
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": (v1/*: any*/),
                                "kind": "ObjectValue",
                                "name": "mapping_field_description"
                              },
                              {
                                "fields": (v1/*: any*/),
                                "kind": "ObjectValue",
                                "name": "mapping_field_name"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "mapping_field"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "_or.1"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "_or"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_and.1"
              }
            ],
            "kind": "ListValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_sets_aggregate",
    "kind": "LinkedField",
    "name": "sys_mapping_sets_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_mapping_sets_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getMappingSetsTotalSubscription",
    "selections": (v2/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getMappingSetsTotalSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2f8cac3e7a5c030cf6514b5c1313b11a",
    "id": null,
    "metadata": {},
    "name": "getMappingSetsTotalSubscription",
    "operationKind": "subscription",
    "text": "subscription getMappingSetsTotalSubscription(\n  $id: Int\n  $search: String = \"%\"\n) {\n  total: sys_mapping_sets_aggregate(where: {_and: [{provider_id: {_eq: $id}}, {_or: [{mapping_set_description: {_ilike: $search}}, {mapping_field: {mapping_field_description: {_ilike: $search}, mapping_field_name: {_ilike: $search}}}]}]}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "46ce4da78888543587498b8dc61fc534";

export default node;
