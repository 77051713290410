/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useCherreEventWithRecoil,
  useCherreValue,
  useCherrePartialState,
  useCherreStateDebounced,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  Panel,
  Typography,
  MuiAutocomplete as Autocomplete,
  FormEntry,
  TextField,
  IconButton,
  CloseIcon,
  styled,
  ConfirmationModalConfirm,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import {
  addEditMappingSetDialogState,
  addEditMappingSetFormState,
  addEditMappingSetChecker,
  addEditMappingSetFormName,
} from '../recoil';
import { HeaderGrid, Container, DialogTitle } from '../styles';
import { getMappingFields } from '../queries/getMappingFields';
import { getMasterChartOfAccounts } from '../queries/getMasterChartOfAccounts';
import {
  MappingTypes,
  isSourcedFromMasterCOA,
  isExcludedPropertyType,
} from 'src/products/data-submission-portal/pages/mapping-detail/types';
import { useHistory } from 'react-router';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import { getDuplicatedMappingSet } from '../queries/getDuplicatedMappingSet';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useTheme } from '@material-ui/core';

const FieldErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const AddEditMappingSetDialogModal: React.FC<{
  confirmFunction: ConfirmationModalConfirm;
}> = ({ confirmFunction }) => {
  const { push } = useHistory();
  const theme = useTheme();

  const isExcludedPropertyEnabled = useFeatureFlag('DSPExcludedProperties');
  const newIngestion = useFeatureFlag('DSPNewIngestionAdmin');

  const provider_id = useCherreValue(
    ProviderDetailsRoute.routeParamSelector
  )?.provider_id;

  const dialogState = useCherreValue(addEditMappingSetDialogState)!;

  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingSetFormState
  );

  // control dialog options and visibility
  let mappingFields = useCherreValue(getMappingFields());
  if (!isExcludedPropertyEnabled) {
    mappingFields = mappingFields?.filter(
      (v) => !isExcludedPropertyType(v.mapping_field_name as MappingTypes)
    );
  }
  const chartOfAccounts = useCherreValue(getMasterChartOfAccounts({}));
  const isSourcedFromCOA = isSourcedFromMasterCOA(
    mappingForm?.mapping_field_name as MappingTypes
  );

  // control the visibility of the duplicate mapping set error message
  const [mappingSetNameDebounced, , setMappingSetNameDebounced] =
    useCherreStateDebounced(addEditMappingSetFormName);
  const isDuplicateMappingSet = useCherreValue(getDuplicatedMappingSet());

  // control the visibility of the is_multiple_mapping_set error message
  const [
    isSingleMappingSetCreated,
    singleMappingSetCreatedName,
    singleMappingFieldSelected,
  ] = useMemo(() => {
    const f = mappingFields?.find(
      (v) => v.mapping_field_id === mappingForm?.mapping_field_id
    );
    return [
      f && f.is_single_mapping_set && f.mapping_sets.length > 0,
      f?.mapping_sets[0]?.mapping_set_description,
      f?.mapping_field_description,
    ];
  }, [mappingForm, mappingFields]);

  const validateForm = useMemo(() => {
    return addEditMappingSetChecker(mappingForm);
  }, [mappingForm]);

  // dialog callbacks
  const onClose = useCherreEventWithRecoil(
    'user closed add mapping set dialog',
    (ctx) => () => {
      ctx.recoil.set(addEditMappingSetDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
      ctx.recoil.set(addEditMappingSetFormState, {
        provider_id: 0,
        mapping_field_id: 0,
        mapping_field_name: '',
        mapping_set_description: '',
        master_chart_of_account_id: 0,
        id: 0,
      });
    }
  );

  const onSubmit = useCherreEventWithRecoil(
    'user continue add mapping set dialog',
    (ctx) => () => {
      ctx.recoil.set(addEditMappingSetDialogState, (s) => ({
        ...s,
        isOpen: false,
      }));
      const selectedMappingField = mappingFields?.find(
        (v) => v.mapping_field_id === mappingForm?.mapping_field_id
      );

      const customAttributes = (selectedMappingField?.custom_attributes ??
        []) as { slug: string; label: string }[];

      confirmFunction({
        title: 'Bulk Upload Mappings',
        content: (
          <div style={{ fontFamily: theme.typography.fontFamily }}>
            <p>Bulk upload your mappings in Excel or CSV format.</p>
            <p>Please include the following fields in your upload:</p>
            <ul>
              <li>
                Source Mapping Code
                <span style={{ color: theme.palette.error.main }}>&nbsp;*</span>
              </li>
              <li>Source Mapping Label</li>
              <li>
                Target Mapping Code
                <span style={{ color: theme.palette.error.main }}>&nbsp;*</span>
              </li>
              <li>Target Mapping Label</li>
              {customAttributes.map((attr) => (
                <li key={attr.slug}>{attr.label}</li>
              ))}
            </ul>
            <p style={{ color: theme.palette.grey[700] }}>
              Fields marked with asterisks (*) are mandatory.
            </p>
          </div>
        ),
        handleConfirm: () => {
          push(
            `/dsp/upload-mapping${newIngestion ? '-new' : ''}/${
              mappingForm?.mapping_field_id
            }`
          );
        },
      });
    }
  );

  return (
    <Container open={Boolean(dialogState.isOpen)} onClose={onClose}>
      <Panel id='AddEditMappingSetDialog'>
        <HeaderGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs>
            <DialogTitle variant='h4'>
              {dialogState.type === 'Edit'
                ? 'Edit Mapping File'
                : 'Add Mapping File'}
            </DialogTitle>
          </Grid>
          <Grid item xs='auto'>
            <IconButton onClick={onClose} size='small'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </HeaderGrid>
        <Typography variant='body2' sx={{ paddingBottom: '16px' }}>
          Required fields marked with asterisk (*)
        </Typography>
        <Grid
          container
          direction='row'
          spacing={2}
          style={{ marginBottom: '20px' }}
        >
          <Grid container item direction='column'>
            <Grid item>
              <FormEntry required title='Name'>
                <TextField
                  id='mapping_set_description'
                  data-testid='mapping_set_description'
                  role='textbox'
                  variant='outlined'
                  size='small'
                  required
                  placeholder='Enter mapping name'
                  value={mappingSetNameDebounced}
                  onChange={(e) => {
                    setMappingSetNameDebounced(e.target.value);
                    setMappingForm({
                      mapping_set_description: e.target.value,
                      provider_id,
                    });
                  }}
                />
                {isDuplicateMappingSet && (
                  <FieldErrorMessage variant='body2'>
                    {`A ${singleMappingFieldSelected} mapping - “${mappingForm?.mapping_set_description}” - already exists for this provider. Name cannot be duplicated per Mapping Type.`}
                  </FieldErrorMessage>
                )}
              </FormEntry>
            </Grid>
          </Grid>
          <Grid container item direction='column'>
            <Grid item>
              <FormEntry required title='Mapping Type'>
                <Autocomplete
                  data-testid='mapping_field'
                  options={mappingFields ?? []}
                  getOptionLabel={(option) => option.mapping_field_description}
                  style={{ width: '100%' }}
                  size='small'
                  onChange={(_, value) =>
                    setMappingForm({
                      mapping_field_id: value?.mapping_field_id,
                      mapping_field_name: value?.mapping_field_name,
                    })
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant='outlined'
                        size='small'
                        style={{ width: '100%' }}
                        placeholder='Select'
                        role='textbox'
                      />
                    );
                  }}
                />
                {isSingleMappingSetCreated && (
                  <FieldErrorMessage variant='body2'>
                    {`A ${singleMappingFieldSelected} mapping - “${singleMappingSetCreatedName}” - already exists for this provider. Only one mapping per provider is allowed.`}
                  </FieldErrorMessage>
                )}
              </FormEntry>
            </Grid>
          </Grid>

          {isSourcedFromCOA ? (
            <Grid container item direction='column'>
              <Grid item>
                <FormEntry
                  required={isSourcedFromCOA}
                  title='Master Chart of Accounts'
                >
                  <Autocomplete
                    data-testid='master_chart_of_account_id'
                    options={chartOfAccounts ?? []}
                    getOptionLabel={(option) => option.chart_of_accounts_name}
                    style={{ width: '100%' }}
                    disabled={!isSourcedFromCOA}
                    size='small'
                    onChange={(_, value) =>
                      setMappingForm({
                        master_chart_of_account_id:
                          value?.master_chart_of_account_id,
                      })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant='outlined'
                          size='small'
                          style={{
                            width: '100%',
                            backgroundColor: !isSourcedFromCOA
                              ? '#f5f5f5'
                              : undefined,
                          }}
                          placeholder='Select'
                          role='textbox'
                        />
                      );
                    }}
                  />
                </FormEntry>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid container direction='row' justifyContent='flex-start' gap={2}>
          <Grid item>
            <Button
              variant='contained'
              style={{
                padding: '6px 8px',
              }}
              disabled={
                isSingleMappingSetCreated ||
                isDuplicateMappingSet ||
                validateForm.type === 'failure'
              }
              onClick={() => onSubmit()}
            >
              {dialogState.type === 'Edit' ? 'Save' : 'Continue'}
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};

export default AddEditMappingSetDialogModal;
