import React from 'react';
import {
  Typography,
  styled,
  Grid,
  IconButton,
  CloseIcon,
} from '@cherre-frontend/ui';
import { useCherreEventWithRecoil } from '@cherre-frontend/data-fetching';
import { assignMappingsDialogOpenState } from 'src/products/data-submission-portal/recoil/dialog';

const HeaderContainer = styled(Grid)``;

export type DialogHeaderProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
};

const Title = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  /* Headings/H4 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Subtitle = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  /* Headings/Subtitle 2 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  const onClose = useCherreEventWithRecoil(
    'user closed assign mappings dialog',
    (ctx) => () => {
      ctx.recoil.set(assignMappingsDialogOpenState, false);
    }
  );

  return (
    <HeaderContainer container>
      <Grid item xs>
        <Title variant='h4'>{props.title}</Title>
        <Subtitle variant='subtitle2'>{props.subtitle}</Subtitle>
      </Grid>
      <Grid item xs='auto'>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </HeaderContainer>
  );
};

DialogHeader.displayName = 'DialogHeader';
