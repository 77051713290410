/**
 * @generated SignedSource<<8de18e376561f383fabb444aa5c44b83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type useCreateIngestFileProgressSubscription$variables = {
  file_reference_souce: string;
};
export type useCreateIngestFileProgressSubscription$data = {
  readonly sys_batch_files: ReadonlyArray<{
    readonly processed_row_count: number | null;
    readonly row_count: number;
  }>;
};
export type useCreateIngestFileProgressSubscription = {
  response: useCreateIngestFileProgressSubscription$data;
  variables: useCreateIngestFileProgressSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file_reference_souce"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "file_reference_souce"
              }
            ],
            "kind": "ObjectValue",
            "name": "file_reference_souce"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_batch_files",
    "kind": "LinkedField",
    "name": "sys_batch_files",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "processed_row_count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "row_count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateIngestFileProgressSubscription",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateIngestFileProgressSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea26b4a7ec5c646833627fbc6a48926c",
    "id": null,
    "metadata": {},
    "name": "useCreateIngestFileProgressSubscription",
    "operationKind": "subscription",
    "text": "subscription useCreateIngestFileProgressSubscription(\n  $file_reference_souce: String!\n) {\n  sys_batch_files(where: {file_reference_souce: {_eq: $file_reference_souce}}) {\n    processed_row_count\n    row_count\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d8404074e15c60a427aae070f390fc8";

export default node;
