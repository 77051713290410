import {
  refine,
  searchParamsSelector,
  selector,
} from '@cherre-frontend/data-fetching';
import {
  bool,
  object,
  string,
  number,
  nullable,
  withDefault,
  writableArray,
  CheckerReturnType,
} from '@recoiljs/refine';
import { PROPERTY_MAPPINGS } from 'src/const';

export const selectedPropertiesToAssignChecker = object({
  entity_id: string(),
  property_id: number(),
  organization_id: number(),
  property_name: nullable(string()),
  property_mapping: nullable(string()),
  property_code: nullable(string()),
  provider: nullable(
    object({
      provider_name: string(),
      provider_id: number(),
    })
  ),
  parent_property_id: nullable(number()),
});

export const managedPropertyToAssignChecker = object({
  entity_id: string(),
  property_id: nullable(number()),
  organization_id: number(),
  property_name: nullable(string()),
  property_mapping: nullable(string()),
});

export const userChecker = object({
  property_role_user_id: nullable(number()),
  property_role_id: nullable(number()),
  user_id: number(),
  sakura_user: nullable(
    object({
      email: nullable(string()),
      first_name: nullable(string()),
      last_name: nullable(string()),
    })
  ),
});

export type userCheckerType = CheckerReturnType<typeof userChecker>;

export const assignDatasetsDialogOpenState = searchParamsSelector({
  key: 'assign-datasets-dialog-open-state',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const assignPreparersDialogOpenState = searchParamsSelector({
  key: 'assign-preparers',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const assignReviewersDialogOpenState = searchParamsSelector({
  key: 'assign-reviewers',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const warningBoxState = searchParamsSelector({
  key: 'warning-box-state',
  refine: withDefault(
    object({
      type: nullable(refine.stringLiterals(['warning', 'error'] as const)),
      header: nullable(string()),
      subtitle: nullable(string()),
    }),
    {
      type: undefined,
      header: undefined,
      subtitle: undefined,
    }
  ),
  routes: ['/**'],
});

export const selectedPropertiesToAssign = searchParamsSelector({
  key: 'selected-properties-to-assign',
  refine: withDefault(writableArray(selectedPropertiesToAssignChecker), []),
  routes: ['/**'],
});

export const selectedPropertiesToAssignContainsManyToOneProperty = selector({
  key: 'selected-properties-to-assign-contains-many-to-one-property',
  get: ({ get }) => {
    const properties = get(selectedPropertiesToAssign);
    return properties?.some(
      (property) => property.property_mapping === PROPERTY_MAPPINGS.MANY_TO_ONE
    );
  },
});

export const managedPropertyState = searchParamsSelector({
  key: 'managed-property-state',
  refine: withDefault(managedPropertyToAssignChecker, {
    entity_id: '',
    property_id: null,
    property_name: '',
    organization_id: 0,
    property_mapping: null,
  }),
  routes: ['/**'],
});

export const managedPropertyStateIsManyToOne = selector({
  key: 'managed-property-state-is-many-to-one',
  get: ({ get }) => {
    const property = get(managedPropertyState);
    return property.property_mapping === PROPERTY_MAPPINGS.MANY_TO_ONE;
  },
});

export const assignMappingsDialogOpenState = searchParamsSelector({
  key: 'assign-mappings',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const assignProviderDialogOpenState = searchParamsSelector({
  key: 'assign-provider-dialog-state',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const downloadAdjustmentsModalOpenState = searchParamsSelector({
  key: 'download-adjustments-modal-state',
  refine: withDefault(bool(), false),
  routes: ['/**'],
});

export const propertiesTableSelectedProvider = searchParamsSelector({
  key: 'properties-table-selected-providers',
  refine: withDefault(nullable(number()), null),
  routes: ['/**'],
});

export const selectedReviewersUsers = searchParamsSelector({
  key: 'selected-reviewers-users',
  refine: withDefault(
    object({
      reviewer1: withDefault(writableArray(userChecker), []),
      reviewer2: withDefault(writableArray(userChecker), []),
      reviewer3: withDefault(writableArray(userChecker), []),
    }),
    { reviewer1: [], reviewer2: [], reviewer3: [] }
  ),
  routes: ['/**'],
});

export const selectedPreparerUsers = searchParamsSelector({
  key: 'selected-preparer-users',
  refine: withDefault(writableArray(userChecker), []),
  routes: ['/**'],
});

export const selectedOperationalReviewerUsers = searchParamsSelector({
  key: 'selected-operational-reviewer-users',
  refine: withDefault(
    object({
      reviewer1: writableArray(userChecker),
      reviewer2: writableArray(userChecker),
      reviewer3: writableArray(userChecker),
    }),
    { reviewer1: [], reviewer2: [], reviewer3: [] }
  ),
  routes: ['/**'],
});

export const selectedFinancialReviewerUsers = searchParamsSelector({
  key: 'selected-financial-reviewer-users',
  refine: withDefault(
    object({
      reviewer1: writableArray(userChecker),
      reviewer2: writableArray(userChecker),
      reviewer3: writableArray(userChecker),
    }),
    { reviewer1: [], reviewer2: [], reviewer3: [] }
  ),
  routes: ['/**'],
});

export const startingPreparerUsers = searchParamsSelector({
  key: 'starting-preparer-users',
  refine: withDefault(writableArray(userChecker), []),
  routes: ['/**'],
});

export const startingFinancialReviewerUsers = searchParamsSelector({
  key: 'starting-financial-reviewer-users',
  refine: withDefault(writableArray(userChecker), []),
  routes: ['/**'],
});

export const startingOperationalReviewerUsers = searchParamsSelector({
  key: 'starting-operational-reviewer-users',
  refine: withDefault(writableArray(userChecker), []),
  routes: ['/**'],
});

export const selectedProviderToBeAssigned = searchParamsSelector({
  key: 'properties-table-selected-provider-to-be-assigned',
  refine: withDefault(number(), 0),
  routes: ['/**'],
});
