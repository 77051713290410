import { useCherreValue, useIsSuspended } from '@cherre-frontend/data-fetching';
import {
  Box,
  CloseIcon,
  IconButton,
  Link,
  Panel,
  Popover,
  Typography,
  popoverClasses,
  styled,
} from '@cherre-frontend/ui';
import React, { ReactNode, useState } from 'react';
import { getSakuraUsersInfo } from '../pages/provider-detail/Panels/PropertiesTab/queries/getSakuraUsersInfo';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[50]};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  grid-area: title;
`;

const CloseIconButton = styled(IconButton)`
  color: #9e9e9e;
  grid-area: close;
`;

const TooltipContainer = styled('div')`
  display: grid;
  grid-template-areas: 'title close' 'list list';
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const TooltipList = styled('div')`
  grid-area: list;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-bottom: 20px;
`;

type UsersClickCardTooltipProps = {
  onClose: () => void;
  icon: ReactNode;
  assigned_preparers: number[];
  assigned_fin_reviewers: number[];
  assigned_op_reviewers: number[];
};

const UsersClickCardTooltip: React.FC<UsersClickCardTooltipProps> = ({
  onClose,
  icon,
  assigned_fin_reviewers,
  assigned_op_reviewers,
  assigned_preparers,
}) => {
  const users = [
    ...new Set<number>([
      ...assigned_fin_reviewers,
      ...assigned_op_reviewers,
      ...assigned_preparers,
    ]),
  ];

  const sakuraUsersInfo = useCherreValue(getSakuraUsersInfo({ users }));

  const preparers = sakuraUsersInfo?.filter((user) =>
    assigned_preparers.includes(user.user_id)
  );
  const fin_reviewers = sakuraUsersInfo?.filter((user) =>
    assigned_fin_reviewers.includes(user.user_id)
  );
  const op_reviewers = sakuraUsersInfo?.filter((user) =>
    assigned_op_reviewers.includes(user.user_id)
  );

  return (
    <TooltipContainer>
      <Title>Users</Title>
      <CloseIconButton size='small' onClick={onClose}>
        <CloseIcon />
      </CloseIconButton>
      <Box>
        <Typography variant='h6'>Assigned Preparers</Typography>
        <TooltipList>
          {assigned_preparers.length === 0 ? (
            <Typography variant='body2'>None Assigned</Typography>
          ) : (
            preparers?.map((item) => (
              <>
                {icon}
                <Typography key={`preparer_${item.user_id}`}>
                  {[item.first_name, item.last_name].filter(Boolean).join(' ')}
                </Typography>
              </>
            ))
          )}
        </TooltipList>
        <Typography variant='h6'>Assigned Reviewers</Typography>
        <Typography variant='subtitle2'>Financial</Typography>
        <TooltipList>
          {assigned_fin_reviewers.length === 0 ? (
            <Typography variant='body2'>None Assigned</Typography>
          ) : (
            fin_reviewers?.map((item) => (
              <>
                {icon}
                <Typography key={`fin_reviewer_${item.user_id}`}>
                  {[item.first_name, item.last_name].filter(Boolean).join(' ')}
                </Typography>
              </>
            ))
          )}
        </TooltipList>
        <Typography variant='subtitle2'>Operational</Typography>
        <TooltipList>
          {assigned_op_reviewers.length === 0 ? (
            <Typography variant='body2'>None Assigned</Typography>
          ) : (
            op_reviewers?.map((item) => (
              <>
                {icon}
                <Typography key={`op_reviewer_${item.user_id}`}>
                  {[item.first_name, item.last_name].filter(Boolean).join(' ')}
                </Typography>
              </>
            ))
          )}
        </TooltipList>
      </Box>
    </TooltipContainer>
  );
};

export type UsersClickCardCellProps = {
  link_text: ReactNode;
  icon: ReactNode;
  assigned_preparers: number[];
  assigned_fin_reviewers: number[];
  assigned_op_reviewers: number[];
};

const CustomPopover = styled(Popover)`
  .${popoverClasses.paper} {
    border-radius: 10px;
    padding: 10px;
    background: var(--gray-900, #212121);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    color: white;
  }
`;

export const UsersClickCard: React.FC<UsersClickCardCellProps> = ({
  link_text,
  icon,
  assigned_preparers,
  assigned_fin_reviewers,
  assigned_op_reviewers,
}) => {
  const suspended = useIsSuspended();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  if (suspended) {
    return null;
  }

  return (
    <>
      {(assigned_preparers.length ||
        assigned_fin_reviewers.length ||
        assigned_op_reviewers.length) !== 0 && (
        <CustomPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Panel id='users-click-card-tooltip'>
            <UsersClickCardTooltip
              onClose={handleClose}
              icon={icon}
              assigned_preparers={assigned_preparers}
              assigned_fin_reviewers={assigned_fin_reviewers}
              assigned_op_reviewers={assigned_op_reviewers}
            />
          </Panel>
        </CustomPopover>
      )}
      <Link href='#' padding='10px' underline='none' onClick={handleClick}>
        {link_text}
      </Link>
    </>
  );
};

UsersClickCard.displayName = 'UsersClickCard';
