import { bool, number, object, withDefault } from '@recoiljs/refine';
import { refine } from '@cherre-frontend/data-fetching';
import { MappingDetailRoute } from '../../../routes';

export const addEditMappingDialogState =
  MappingDetailRoute.searchParamsSelectorFamily({
    key: 'add-edit-mapping',
    refine: withDefault(
      object({
        isOpen: bool(),
        type: refine.stringLiterals(['Add', 'Edit'] as const),
      }),
      {
        isOpen: false,
        type: 'Add',
      }
    ),
  });

export const deleteMappingDialogState =
  MappingDetailRoute.searchParamsSelectorFamily({
    key: 'delete-mapping',
    refine: withDefault(
      object({
        isOpen: bool(),
        mappingSetValueId: number(),
      }),
      {
        isOpen: false,
        mappingSetValueId: 0,
      }
    ),
  });
