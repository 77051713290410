import React, { useEffect, useMemo } from 'react';
import { AssignProviderDialogHeader } from './AssignProviderDialogHeader';
import {
  propertiesTableSelectedProvider,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';
import {
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  MRT_ColumnDef,
  Table,
  Typography,
  useTable,
} from '@cherre-frontend/ui';
import { getManyToOneChildProperties } from './recoil';

interface M1PropertyGroupDialogProps {
  onClose: () => void;
  onContinue: () => void;
}

export const M1PropertyGroupDialog: React.FC<M1PropertyGroupDialogProps> = ({
  onClose,
  onContinue,
}) => {
  const selectedProperties = useCherreValue(selectedPropertiesToAssign);
  const properties = useCherreValue(getManyToOneChildProperties());
  const setSelectedPropertiesToAssign = useCherreSetState(
    selectedPropertiesToAssign
  );

  const setSelectedProvider = useCherreSetState(
    propertiesTableSelectedProvider
  );

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'property_code',
          header: 'Target ID',
        },
        {
          accessorKey: 'entity_id',
          header: 'Source ID',
        },
        {
          accessorKey: 'provider.provider_name',
          header: 'Provider Name',
        },
      ] satisfies MRT_ColumnDef<any>[],
    []
  );

  const table = useTable({
    data: properties,
    columns,
    muiTablePaperProps: {
      sx: {
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '10px',
      },
      elevation: 0,
    },
  });

  useEffect(() => {
    if (properties && properties.length === 0) {
      onContinue();
    }
  }, [properties?.length]);

  return (
    <Grid display={'grid'} gap={'20px'}>
      <Grid display={'grid'} gap={'8px'}>
        <AssignProviderDialogHeader
          title={'M:1 Property Groups'}
          subtitle={`${selectedProperties?.length} ${
            selectedProperties?.length === 1 ? 'Property' : 'Properties'
          } Selected`}
          onClose={onClose}
        />
        <Typography variant='body1' fontSize={12}>
          These properties are part of M:1 property groups. Are there any other
          properties within these property groups that you would like to select
          and assign to a provider?
        </Typography>
      </Grid>
      <Table table={table} />
      <Grid container direction='row' gap={2} justifyContent='flex-end'>
        <Grid item>
          <Button
            size='medium'
            variant='outlined'
            color='primary'
            onClick={onClose}
            sx={{
              border: 'none',
              minWidth: '73px',
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            size='medium'
            variant='contained'
            color='primary'
            disabled={false}
            sx={{
              minWidth: '80px',
            }}
            onClick={() => {
              onContinue();
              const newSelectedProperties = table
                .getSelectedRowModel()
                .flatRows.map((r) => r.original);
              setSelectedProvider((prev) => {
                if (!prev) {
                  return null;
                }
                return newSelectedProperties.some(
                  (p) => p.provider?.provider_id !== prev
                )
                  ? null
                  : prev;
              });

              const newSelectedPropertiesMapped = newSelectedProperties.map(
                (property) => ({
                  entity_id: property.entity_id,
                  property_id: property.property_id,
                  property_name: property.property_name,
                  organization_id: property.organization_id,
                  property_mapping: property.property_mapping,
                  provider: property.provider ?? null,
                  parent_property_id: property.parent_property_id,
                  property_code: property.property_code,
                })
              );

              setSelectedPropertiesToAssign((prev) => [
                ...prev,
                ...newSelectedPropertiesMapped,
              ]);
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
