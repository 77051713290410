import { useCherrePartialState } from '@cherre-frontend/data-fetching';
import React from 'react';
import { addEditMappingFormState } from '../recoil';
import { DialogTextField } from '../styles';
import { isExcludedPropertyType, MappingTypes } from '../types';
import { Autocomplete } from '@cherre-frontend/ui';
import { getExcludedPropertiesOptions } from '../queries/addEditOptions';

export type AddEditSourceCodeInputProps = {
  mappingType: MappingTypes;
};

const AddEditSourceCodeInput: React.FC<AddEditSourceCodeInputProps> = ({
  mappingType,
}) => {
  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingFormState
  );
  if (isExcludedPropertyType(mappingType)) {
    return (
      <Autocomplete
        label='Source Code'
        getSelectedOption={(o) => mappingForm?.source_code === o.entity_id}
        selector={getExcludedPropertiesOptions()}
        getOptionLabel={(option) => option.entity_id}
        style={{ width: '300px' }}
        size='small'
        onChange={(_, value) =>
          setMappingForm({
            source_code: value?.entity_id ?? '',
            source_description: value?.entity_name ?? '',
            target_code: value?.property_code ?? '',
            target_description: value?.property_name ?? '',
          })
        }
      />
    );
  }
  return (
    <DialogTextField
      variant='outlined'
      size='small'
      label='Source Code'
      value={mappingForm?.source_code}
      onChange={(e) => {
        setMappingForm({ source_code: e.target.value });
      }}
    />
  );
};

export default AddEditSourceCodeInput;
