import React from 'react';
import { TabsConfig } from '../PropertyBatchAccordion.base/types';
import { PropertyBatch } from '../types';
import { TabsProps } from '@cherre-frontend/ui';
import { PackageReportsCombinedTable } from '../../../../../components/PackageReportsTable';
import {
  packageReportModalState,
  modalSearchState,
  reportSelectors,
  reportSlugModalState,
} from '../../../recoil';
import { TsaDatasetsTable } from '../../../../../components/TsaDatasetsTable';
import { useIsApproverPlus } from '../../../../../packages/dsp-role-based-rendering';

export const useTabsConfigCombinedReviewer = (
  propertyBatches: PropertyBatch[],
  investmentPropertyBatchId: number
): TabsConfig => {
  const [tabValue, setTabValue] = React.useState<TabsProps['value']>(0);

  const tabOnChange = (
    event: React.SyntheticEvent,
    newValue: TabsProps['value']
  ) => {
    setTabValue(newValue);
  };

  const isApproverPlus = useIsApproverPlus();

  return {
    tabOnChange,
    tabValue,
    tabs: [
      {
        label: 'Package Reports',
        value: 0,
      },
      ...(isApproverPlus
        ? [
            {
              label: 'Adjustments',
              value: 1,
            },
          ]
        : []),
    ],
    tabPanels: [
      {
        value: 0,
        panel: (
          <PackageReportsCombinedTable
            investmentPropertyBatchId={investmentPropertyBatchId}
            modalState={packageReportModalState}
            disableLastRowBorder
            property_batch_ids={propertyBatches.map((p) => p.property_batch_id)}
            modalSearchState={modalSearchState}
            reportSelectors={reportSelectors}
            reportSlugModalState={reportSlugModalState}
          />
        ),
      },
      ...(isApproverPlus
        ? [
            {
              value: 1,
              panel: (
                <TsaDatasetsTable
                  property_batch_id={investmentPropertyBatchId}
                />
              ),
            },
          ]
        : []),
    ],
  };
};
