/**
 * @generated SignedSource<<f1f7bbf9f7c86410ff18d6de67f32d0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getUserQuery$variables = {
  id: number;
};
export type getUserQuery$data = {
  readonly sys_users: ReadonlyArray<{
    readonly created_at_datetime: string;
    readonly is_active: boolean;
    readonly organization: {
      readonly settings: any | null;
    } | null;
    readonly organization_id: number;
    readonly provider: {
      readonly ingestion_config: any | null;
    } | null;
    readonly provider_id: number | null;
    readonly updated_at_datetime: string;
    readonly user_id: number;
  }>;
};
export type getUserQuery = {
  response: getUserQuery$data;
  variables: getUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "user_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_users",
    "kind": "LinkedField",
    "name": "sys_users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updated_at_datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ingestion_config",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_organizations",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "settings",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getUserQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ab555abad1845e0700477ebb67f24fb",
    "id": null,
    "metadata": {},
    "name": "getUserQuery",
    "operationKind": "query",
    "text": "query getUserQuery(\n  $id: Int!\n) {\n  sys_users(where: {user_id: {_eq: $id}}) {\n    is_active\n    organization_id\n    provider_id\n    user_id\n    created_at_datetime\n    updated_at_datetime\n    provider {\n      ingestion_config\n    }\n    organization {\n      settings\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d585ee0d18b5c4c8af52a3a7ab35c06d";

export default node;
