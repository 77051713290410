import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getDuplicatedMappingSetQuery } from './__generated__/getDuplicatedMappingSetQuery.graphql';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import {
  addEditMappingSetFormName,
  addEditMappingSetFormState,
} from '../recoil';

export const getDuplicatedMappingSet = graphQLSelector({
  query: graphql`
    query getDuplicatedMappingSetQuery(
      $provider_id: Int!
      $mapping_set_description: String!
      $mapping_field_id: Int!
    ) {
      sys_mapping_sets(
        where: {
          provider_id: { _eq: $provider_id }
          is_active: { _eq: true }
          mapping_set_description: { _ilike: $mapping_set_description }
          mapping_field_id: { _eq: $mapping_field_id }
        }
        limit: 1
      ) {
        mapping_set_id
      }
    }
  ` as GraphQLReturn<getDuplicatedMappingSetQuery>,
  mapResponse: (response) => {
    return response.sys_mapping_sets.length > 0;
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        provider_id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
        mapping_set_description: get(addEditMappingSetFormName) || '',
        mapping_field_id: get(addEditMappingSetFormState).mapping_field_id || 0,
      };
    },
});
