import React from 'react';
import { recoilGetProviderInfoQuery } from './__generated__/recoilGetProviderInfoQuery.graphql';
import { CheckCircleIcon, CloseIcon } from '@cherre-frontend/ui';

type Provider = recoilGetProviderInfoQuery['response']['sys_providers'][number];

export type MODE = 'ADD' | 'EDIT';

export const selectMode = (providerId: number | null | undefined): MODE => {
  if (typeof providerId === 'number') {
    return 'EDIT';
  }
  return 'ADD';
};

export const successMessage = (mode: MODE) => {
  switch (mode) {
    case 'ADD':
      return 'New Service Provider added';
    case 'EDIT':
      return 'Service Provider updated';
    default:
      throw new Error(`invalid mode ${mode}`);
  }
};

export const title = (mode: MODE, provider?: Provider) => {
  switch (mode) {
    case 'ADD':
      return 'Add New Provider';
    case 'EDIT':
      return provider?.provider_name || '';
    default:
      throw new Error(`invalid mode ${mode}`);
  }
};

export const helperText = (existingProvider: boolean) => {
  if (existingProvider) {
    return 'Provider already exists. Try another name.';
  }
  return '';
};

export const inputProps = (validName: boolean, existingProvider: boolean) => {
  if (validName) {
    return {
      endAdornment: (
        <CheckCircleIcon sx={{ fontSize: '18px' }} color='success' />
      ),
    };
  }
  if (existingProvider) {
    return {
      endAdornment: <CloseIcon sx={{ fontSize: '18px' }} color='error' />,
    };
  }
  return undefined;
};

export const inputState = (validName: boolean, existingProvider: boolean) => {
  if (validName) {
    return 'success';
  }
  if (existingProvider) {
    return 'error';
  }
  return undefined;
};

export const buttonText = (mode: MODE) => {
  switch (mode) {
    case 'ADD':
      return 'Add Provider';
    case 'EDIT':
      return 'Save';
    default:
      throw new Error(`invalid mode ${mode}`);
  }
};
