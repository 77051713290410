import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import moment from 'moment';
import { graphql } from 'react-relay';
import { recoilTotalDatasetsQuery } from './__generated__/recoilTotalDatasetsQuery.graphql';
import { recoilTotalFailedBatchValidationsQuery } from './__generated__/recoilTotalFailedBatchValidationsQuery.graphql';
import { recoilTotalFailedDatasetValidationsQuery } from './__generated__/recoilTotalFailedDatasetValidationsQuery.graphql';
import { recoilTotalFinancialDatasetsQuery } from './__generated__/recoilTotalFinancialDatasetsQuery.graphql';
import { recoilTotalFinancialUploadedDatasetsQuery } from './__generated__/recoilTotalFinancialUploadedDatasetsQuery.graphql';
import { recoilTotalOperationalDatasetsQuery } from './__generated__/recoilTotalOperationalDatasetsQuery.graphql';
import { recoilTotalOperationalUploadedDatasetsQuery } from './__generated__/recoilTotalOperationalUploadedDatasetsQuery.graphql';
import { recoilTotalPastDuePropertiesQuery } from './__generated__/recoilTotalPastDuePropertiesQuery.graphql';
import { recoilTotalPropertySubmissionsQuery } from './__generated__/recoilTotalPropertySubmissionsQuery.graphql';
import { recoilTotalRejectedPropertiesQuery } from './__generated__/recoilTotalRejectedPropertiesQuery.graphql';
import { recoilTotalStuckPropertiesQuery } from './__generated__/recoilTotalStuckPropertiesQuery.graphql';
import { recoilTotalSubmittedPropertiesQuery } from './__generated__/recoilTotalSubmittedPropertiesQuery.graphql';
import { recoilTotalUploadedDatasetsQuery } from './__generated__/recoilTotalUploadedDatasetsQuery.graphql';
import { monitorSubmissionsPropertyBatchWhereClause } from '../../recoil';
import { recoilAVGPropertiesCycleTimeQuery } from './__generated__/recoilAVGPropertiesCycleTimeQuery.graphql';

export const getTotalPropertySubmissions = graphQLSelector({
  query: graphql`
    subscription recoilTotalPropertySubmissionsQuery(
      $where_clause: sys_property_batches_bool_exp!
    ) {
      totalPropertiesSubmissions: sys_property_batches_aggregate(
        where: $where_clause
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalPropertySubmissionsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const where_clause = get(monitorSubmissionsPropertyBatchWhereClause({}));

      return {
        where_clause,
      };
    },
  mapResponse: (response) =>
    response.totalPropertiesSubmissions.aggregate?.count ?? 0,
});

export const getTotalUploadedDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalUploadedDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalUploadedDatasets: sys_property_batch_datasets_aggregate(
        where: {
          file_id: { _is_null: false }
          property_batch: $property_batch_where_clause
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalUploadedDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({})
        ),
      };
    },
  mapResponse: (response) =>
    response.totalUploadedDatasets.aggregate?.count ?? 0,
});

export const getTotalDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalDatasets: sys_property_batch_datasets_aggregate(
        where: { property_batch: $property_batch_where_clause }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({})
        ),
      };
    },
  mapResponse: (response) => response.totalDatasets.aggregate?.count ?? 0,
});

export const getTotalFinancialUploadedDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalFinancialUploadedDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalFinancialUploadedDatasets: sys_property_batch_datasets_aggregate(
        where: {
          file_id: { _is_null: false }
          property_batch: $property_batch_where_clause
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalFinancialUploadedDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({
            submission: {
              submission_type: { submission_type_slug: { _eq: 'financial' } },
              is_closed: { _eq: false },
            },
          })
        ),
      };
    },
  mapResponse: (response) =>
    response.totalFinancialUploadedDatasets.aggregate?.count ?? 0,
});

export const getTotalFinancialDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalFinancialDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalFinancialDatasets: sys_property_batch_datasets_aggregate(
        where: { property_batch: $property_batch_where_clause }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalFinancialDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({
            submission: {
              submission_type: { submission_type_slug: { _eq: 'financial' } },
              is_closed: { _eq: false },
            },
          })
        ),
      };
    },
  mapResponse: (response) =>
    response.totalFinancialDatasets.aggregate?.count ?? 0,
});

export const getTotalOperationalUploadedDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalOperationalUploadedDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalOperationalUploadedDatasets: sys_property_batch_datasets_aggregate(
        where: {
          file_id: { _is_null: false }
          property_batch: $property_batch_where_clause
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalOperationalUploadedDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({
            submission: {
              submission_type: { submission_type_slug: { _eq: 'operational' } },
              is_closed: { _eq: false },
            },
          })
        ),
      };
    },
  mapResponse: (response) =>
    response.totalOperationalUploadedDatasets.aggregate?.count ?? 0,
});

export const getTotalOperationalDatasets = graphQLSelector({
  query: graphql`
    subscription recoilTotalOperationalDatasetsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      totalOperationalDatasets: sys_property_batch_datasets_aggregate(
        where: { property_batch: $property_batch_where_clause }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalOperationalDatasetsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({
            submission: {
              submission_type: { submission_type_slug: { _eq: 'operational' } },
              is_closed: { _eq: false },
            },
          })
        ),
      };
    },
  mapResponse: (response) =>
    response.totalOperationalDatasets.aggregate?.count ?? 0,
});

export const getTotalFailedBatchValidations = graphQLSelector({
  query: graphql`
    subscription recoilTotalFailedBatchValidationsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      failedBatchValidations: sys_property_batch_validations_aggregate(
        where: {
          is_valid: { _eq: false }
          validation_mode: { _eq: "hard" }
          property_batch: $property_batch_where_clause
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalFailedBatchValidationsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({})
      );

      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) =>
    response.failedBatchValidations.aggregate?.count ?? 0,
});

export const getTotalFailedDatasetValidations = graphQLSelector({
  query: graphql`
    subscription recoilTotalFailedDatasetValidationsQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      failedDatasetValidation: sys_property_batch_dataset_validations_aggregate(
        where: {
          is_valid: { _eq: false }
          validation_mode: { _eq: "hard" }
          property_batch_dataset: {
            property_batch: $property_batch_where_clause
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalFailedDatasetValidationsQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({})
      );

      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) =>
    response.failedDatasetValidation.aggregate?.count ?? 0,
});

export const getTotalPastDueProperties = graphQLSelector({
  query: graphql`
    subscription recoilTotalPastDuePropertiesQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      pastDueProperties: sys_property_batches_aggregate(
        where: $property_batch_where_clause
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalPastDuePropertiesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const current_date = moment(Date.now()).format('YYYY-MM-DD');
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({
          submission: {
            is_closed: { _eq: false },
            due_date: { _lte: current_date },
          },
        })
      );

      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) => response.pastDueProperties.aggregate?.count ?? 0,
});

export const getTotalRejectedProperties = graphQLSelector({
  query: graphql`
    subscription recoilTotalRejectedPropertiesQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      rejectedProperties: sys_property_batches_aggregate(
        where: $property_batch_where_clause
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalRejectedPropertiesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        property_batch_where_clause: get(
          monitorSubmissionsPropertyBatchWhereClause({
            property_batch_stage: {
              property_batch_stage_description: { _eq: 'Rejected' },
            },
            submission: { is_closed: { _eq: false } },
          })
        ),
      };
    },
  mapResponse: (response) => response.rejectedProperties.aggregate?.count ?? 0,
});

export const getTotalSubmittedProperties = graphQLSelector({
  query: graphql`
    subscription recoilTotalSubmittedPropertiesQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      submittedProperties: sys_property_batches_aggregate(
        where: $property_batch_where_clause
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalSubmittedPropertiesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({
          property_batch_stage: {
            property_batch_stage_description: { _eq: 'Approved' },
          },
          submission: { is_closed: { _eq: false } },
        })
      );

      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) => response.submittedProperties.aggregate?.count ?? 0,
});

export const getTotalStuckProperties = graphQLSelector({
  query: graphql`
    subscription recoilTotalStuckPropertiesQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      stuckProperties: sys_property_batches_aggregate(
        where: $property_batch_where_clause
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilTotalStuckPropertiesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const updated_at_datetime = moment(Date.now())
        .subtract(3, 'days')
        .format('YYYY-MM-DD');
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({
          property_batch_stage: {
            property_batch_stage_description: { _neq: 'Approved' },
          },
          submission: { is_closed: { _eq: false } },
          updated_at_datetime: { _lte: updated_at_datetime },
        })
      );
      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) => response.stuckProperties.aggregate?.count ?? 0,
});

export const getAVGPropertiesCycleTime = graphQLSelector({
  query: graphql`
    subscription recoilAVGPropertiesCycleTimeQuery(
      $property_batch_where_clause: sys_property_batches_bool_exp!
    ) {
      sys_property_batches_aggregate(where: $property_batch_where_clause) {
        aggregate {
          avg {
            cycle_time
          }
        }
      }
    }
  ` as GraphQLReturn<recoilAVGPropertiesCycleTimeQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const property_batch_where_clause = get(
        monitorSubmissionsPropertyBatchWhereClause({
          property_batch_stage: {
            property_batch_stage_description: { _eq: 'Approved' },
          },
          submission: { is_closed: { _eq: false } },
        })
      );
      return {
        property_batch_where_clause,
      };
    },
  mapResponse: (response) =>
    response.sys_property_batches_aggregate.aggregate?.avg?.cycle_time ?? 0,
});
