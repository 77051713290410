import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { MRTUtils } from '@cherre-frontend/ui';
import { graphql } from 'react-relay';
import { queryGetFilterValuesQuery } from './__generated__/queryGetFilterValuesQuery.graphql';
import { queryGetBatchesQuery } from './__generated__/queryGetBatchesQuery.graphql';

export const getFilterValues = graphQLSelector({
  query: graphql`
    query queryGetFilterValuesQuery {
      providers: sys_submissions(
        distinct_on: provider_id
        where: { is_closed: { _eq: true } }
      ) {
        provider {
          label: provider_name
          value: provider_id
        }
      }
      types: sys_submissions(
        distinct_on: submission_type_id
        where: { is_closed: { _eq: true } }
      ) {
        submission_type {
          label: submission_type_label
          value: submission_type_id
        }
      }
    }
  ` as GraphQLReturn<queryGetFilterValuesQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (response) => ({
    providers: response.providers.map(({ provider }) => provider),
    types: response.types.map(({ submission_type }) => submission_type),
  }),
});

type GetBatchesVariables = {
  providerId: number | null;
  submissionTypeId: number | null;
  search: string | null;
  sorting: { id: string; desc: boolean }[];
};

export const getBatches = graphQLSelector({
  query: graphql`
    query queryGetBatchesQuery(
      $where: sys_submissions_bool_exp!
      $order_by: [sys_submissions_order_by!]
    ) {
      sys_submissions(where: $where, order_by: $order_by) {
        submission_id
        submission_name
        submission_type {
          submission_type_id
          submission_type_label
        }
        provider {
          provider_id
          provider_name
        }
        property_batches {
          property {
            property_id
          }
        }
        property_batches_aggregate {
          aggregate {
            count
          }
        }
        reporting_period
        reporting_period_start_date
        reporting_period_end_date
        due_date
      }
    }
  ` as GraphQLReturn<queryGetBatchesQuery>,
  mapVariables: (variables: GetBatchesVariables) => () => {
    const where: queryGetBatchesQuery['variables']['where'] = {
      is_closed: { _eq: true },
    };
    if (variables.providerId) {
      where.provider_id = { _eq: variables.providerId };
    }
    if (variables.submissionTypeId) {
      where.submission_type_id = { _eq: variables.submissionTypeId };
    }
    if (variables.search) {
      where._or = [
        { submission_name: { _ilike: `%${variables.search}%` } },
        { provider: { provider_name: { _ilike: `%${variables.search}%` } } },
        {
          submission_type: {
            submission_type_label: { _ilike: `%${variables.search}%` },
          },
        },
      ];
    }
    const order_by: queryGetBatchesQuery['variables']['order_by'] =
      MRTUtils.sorting(variables.sorting);
    return { where, order_by };
  },
  mapResponse: (response) => response.sys_submissions,
});

export type Batch = queryGetBatchesQuery['response']['sys_submissions'][number];
