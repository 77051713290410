import { useMemo } from 'react';
import { useCurrentPreparersAndReviewers } from './useCurrentPreparersAndReviewers';

export const useCurrentPreparersAndReviewersArrays = (
  load = false,
  reviewerSelectionType?: string
) => {
  const currentPreparersAndReviewers = useCurrentPreparersAndReviewers(load);

  const reviewerSlugs = [
    'reviewer_1',
    'reviewer_2',
    'reviewer_3',
    'view_only_1',
    'view_only_2',
    'view_only_3',
    'approver_plus_1',
    'approver_plus_2',
    'approver_plus_3',
  ];

  const currentPreparersAndReviewersArrays = useMemo(() => {
    const allPreparers = currentPreparersAndReviewers?.filter(
      (user) => user.property_role.property_role_slug === 'preparer'
    );
    const allReviewers = currentPreparersAndReviewers
      ?.filter((user) =>
        reviewerSlugs.includes(user.property_role.property_role_slug)
      )
      .filter((r) =>
        reviewerSelectionType
          ? r.submission_type?.submission_type_slug ===
            reviewerSelectionType.toLocaleLowerCase()
          : true
      );

    const preparerUserIds = [
      ...new Set(allPreparers?.map((user) => user.user_id)),
    ];
    const preparerUserList = preparerUserIds.map((id) => ({
      user_id: id,
      entity_ids: [
        ...new Set(
          allPreparers
            ?.filter((user) => user.user_id === id)
            .map((user) => user.property.entity_id)
        ),
      ],
    }));
    const reviewerUserIds = [
      ...new Set(allReviewers?.map((user) => user.user_id)),
    ];
    const reviewerUserList = reviewerUserIds.map((id) => ({
      user_id: id,
      entity_ids: [
        ...new Set(
          allReviewers
            ?.filter((user) => user.user_id === id)
            .map((user) => user.property.entity_id)
        ),
      ],
    }));
    return {
      preparers: preparerUserList ?? [],
      reviewers: reviewerUserList ?? [],
    };
  }, [currentPreparersAndReviewers]);

  return currentPreparersAndReviewersArrays;
};
