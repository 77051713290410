import {
  GraphQLReturn,
  graphQLSelector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilGetPropertiesRoleQuery } from './__generated__/recoilGetPropertiesRoleQuery.graphql';
import { recoilGetPropertiesInSubmissionQuery } from './__generated__/recoilGetPropertiesInSubmissionQuery.graphql';

const getPropertyIdsInSubmission = graphQLSelector({
  query: graphql`
    query recoilGetPropertiesInSubmissionQuery($submissionId: Int!) {
      sys_property_batches(where: { submission_id: { _eq: $submissionId } }) {
        properties_flattened_union {
          property_id
          parent_property_id
        }
      }
    }
  ` as GraphQLReturn<recoilGetPropertiesInSubmissionQuery>,
  mapResponse: (response) => {
    const ids = response.sys_property_batches
      .flatMap((batch) => [
        batch.properties_flattened_union?.property_id,
        batch.properties_flattened_union?.parent_property_id,
      ])
      .filter((id) => !!id) as number[];
    const uniqueIds = new Set(ids);

    return Array.from(uniqueIds);
  },
  mapVariables: (submissionId: number) => () => ({ submissionId }),
  resetCache: false,
});

const getPropertiesRolesQuery = graphQLSelector({
  query: graphql`
    query recoilGetPropertiesRoleQuery(
      $where: sys_properties_roles_users_bool_exp
    ) {
      sys_properties_roles_users(where: $where) {
        property_id
        property_role {
          property_role_set
          property_role_slug
        }
      }
    }
  ` as GraphQLReturn<recoilGetPropertiesRoleQuery>,
  mapResponse: (response) => response.sys_properties_roles_users,
  mapVariables:
    ({
      userId,
      propertyIds,
      submissionId,
    }: {
      userId: number;
      submissionId: number | null;
      propertyIds: number[] | null;
    }) =>
    () => ({
      where:
        propertyIds && submissionId
          ? {
              user_id: { _eq: userId },
              property_id: { _in: propertyIds },
              is_active: { _eq: true },
              _or: [
                { submission_type_id: { _is_null: true } },
                {
                  submission_type: {
                    submissions: { submission_id: { _eq: submissionId } },
                  },
                },
              ],
            }
          : { user_id: { _eq: userId }, is_active: { _eq: true } },
    }),
  resetCache: false,
});

export const getPropertiesRole = selectorFamily({
  key: 'recoilGetPropertiesRole',
  scoped: true,
  get:
    ({
      submissionId,
      userId,
      propertyIds = null,
    }: {
      userId: number;
      submissionId: number | null;
      propertyIds?: number[] | null;
    }) =>
    ({ get }) => {
      if (submissionId) {
        propertyIds = Array.from(get(getPropertyIdsInSubmission(submissionId)));
      }

      const response = get(
        getPropertiesRolesQuery({
          userId,
          propertyIds,
          submissionId,
        })
      );

      return response;
    },
});
