import { useCherreStateDebounced } from '@cherre-frontend/data-fetching';
import {
  Box,
  Grid,
  PageContainer,
  PageContent,
  PageHeader,
  Panel,
  SearchInput,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import { PersonaScope } from '../../packages/dsp-role-based-rendering';
import MonitorSubmissionsKPIs from './components/MonitorSubmissionsKPIs';
import MonitorSubmissionsTable from './components/MonitorSubmissionsTable';
import { SubmissionOverviewDialog } from './panels/SubmissionOverviewDialog';
import { monitorSubmissionsTableSearch } from './recoil';
import MonitorSubmissionsFilters from './components/MonitorSubmissionsFilters';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Search: React.FC = () => {
  const [search, , setSearch] = useCherreStateDebounced(
    monitorSubmissionsTableSearch
  );

  return (
    <SearchInput
      value={search ?? ''}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};

const MonitorSubmissionsPage: React.FC = () => {
  return (
    <PersonaScope persona='view_admin'>
      <PageContainer id='DSPSubmissionsMonitoringPage'>
        <PageHeader title={'Submission Insights'} />

        <Box mb={3}>
          <Panel id={'monitor-submissions-kpis'} disableParent={false}>
            <MonitorSubmissionsKPIs />
          </Panel>
        </Box>

        <PageContent>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            gap={2}
            mb={3}
            flexWrap={'wrap'}
          >
            <Search />
            <Panel id={'monitor-submissions-filters'} disableParent={false}>
              <MonitorSubmissionsFilters />
            </Panel>
          </Grid>
          <PanelStyled id={'monitor-submissions-table'} disableParent={false}>
            <MonitorSubmissionsTable />
          </PanelStyled>
        </PageContent>
      </PageContainer>

      <Panel id={'monitor-submissions-modal'} disableParent={false}>
        <SubmissionOverviewDialog />
      </Panel>
    </PersonaScope>
  );
};

MonitorSubmissionsPage.displayName = 'MonitorSubmissionsPage';

export default MonitorSubmissionsPage;
