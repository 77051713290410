import React from 'react';
import {
  useCherreEventWithRecoil,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import { EditIcon, MenuItem } from '@cherre-frontend/ui';
import { getPropertiesPagePropertiesSubscription$data } from '../queries/__generated__/getPropertiesPagePropertiesSubscription.graphql';
import { addEditPropertyDialogState, useInitEditPropertyForm } from '../recoil';

type EditPropertyActionMenuItemProps = {
  data: getPropertiesPagePropertiesSubscription$data['sys_properties_flattened_union'][number];
};
export const EditPropertyActionMenuItem: React.FC<
  EditPropertyActionMenuItemProps
> = ({ data }) => {
  const setAddEditDialogState = useCherreSetState(addEditPropertyDialogState);
  const initEditPropertyForm = useInitEditPropertyForm();

  const onEditClick = useCherreEventWithRecoil(
    'use requested edit property',
    () => async () => {
      initEditPropertyForm({
        property_id: data.property_id,
        property_code: data.property_code,
        provider_id: data.provider?.provider_id ?? null,
        address: data.address,
        type: data.type,
        fund: data.fund,
        property_name: data.property_name,
        entity_id: data.entity_id,
        entity_name: data.entity_name,
        is_active: data.is_active,
        custom_attributes: data.custom_attributes ?? {},
      });

      setAddEditDialogState({
        isOpen: true,
        type: 'Edit',
      });
    }
  );

  return (
    <MenuItem onClick={onEditClick}>
      <EditIcon sx={{ height: 16 }} />
      Edit
    </MenuItem>
  );
};
