/**
 * @generated SignedSource<<b36f3995ad79c79f04fbf62990c12dcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_target_schema_get_params = {
  mapping_field_id?: number | null;
  slug: string;
};
export type useGetTargetSchemaQuery$variables = {
  params: unified_ingest_target_schema_get_params;
};
export type useGetTargetSchemaQuery$data = {
  readonly unified_ingest_target_schema_get: {
    readonly target_schema: ReadonlyArray<any>;
  };
};
export type useGetTargetSchemaQuery = {
  response: useGetTargetSchemaQuery$data;
  variables: useGetTargetSchemaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_target_schema_get_output",
    "kind": "LinkedField",
    "name": "unified_ingest_target_schema_get",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "target_schema",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGetTargetSchemaQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGetTargetSchemaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f3c70911ad229633532b0772a57fcd3",
    "id": null,
    "metadata": {},
    "name": "useGetTargetSchemaQuery",
    "operationKind": "query",
    "text": "query useGetTargetSchemaQuery(\n  $params: unified_ingest_target_schema_get_params!\n) {\n  unified_ingest_target_schema_get(params: $params) {\n    target_schema\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2275c98989ec8406c26daac1661c5a1";

export default node;
