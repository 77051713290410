export const shouldShowStatusFilter = (report_slug: string) => {
  if (
    [
      'generic_mandatory_fields_validation_report',
      'generic_orphaned_mappings_validation_report',
    ].includes(report_slug)
  ) {
    return false;
  }
  return true;
};
