import React from 'react';
import {
  PageContainer,
  PageHeader,
  PageContent,
  styled,
  Panel,
  Grid,
  SearchInput,
  Button,
} from '@cherre-frontend/ui';
import { ProvidersPanel } from './Panels/ProvidersPanel/ProvidersPanel';
import { useCherreStateDebounced } from '@cherre-frontend/data-fetching';
import { providersTableSearch } from './recoil';
import { useHistory } from 'react-router-dom';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default function ServiceProviders() {
  const [search, , setSearch] = useCherreStateDebounced(providersTableSearch);
  const { push } = useHistory();

  return (
    <PageContainer id='ServiceProvidersPage'>
      <PageHeader title='Service Providers'>
        <FeatureFlagGate flag='DSPAddEditProvider'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => push('/dsp/providers/add')}
          >
            Add Provider
          </Button>
        </FeatureFlagGate>
      </PageHeader>
      <PageContent>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap={10}
        >
          <SearchInput
            value={search ?? ''}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <PanelStyled id='submissions'>
          <ProvidersPanel search={search} />
        </PanelStyled>
      </PageContent>
    </PageContainer>
  );
}
