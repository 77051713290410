import {
  GetCherreValue,
  GraphQLReturn,
  graphQLSelector,
  Writeable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { propertiesTableSearch, propertiesTableState } from '../recoil';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';
import { getPropertiesUsedValuesQuery } from './__generated__/getPropertiesUsedValuesQuery.graphql';
import {
  getPropertiesSubscription,
  sys_properties_bool_exp,
} from './__generated__/getPropertiesSubscription.graphql';
import { getPropertiesTotalSubscription } from './__generated__/getPropertiesTotalSubscription.graphql';
import { SubmissionTypeSlugs } from '../mutations/useAssignSingleChainReviewer';
import { getSubmissionTypes } from 'src/products/data-submission-portal/hooks/useCreateBatchForm/queries';

export type Property = Writeable<
  getPropertiesSubscription['response']['sys_properties_flattened_union'][number]
>;

const buildPropertiesWhere = (get: GetCherreValue): sys_properties_bool_exp => {
  const search = `%${get(propertiesTableSearch) ?? ''}%`;
  const provider_id = get(ProviderDetailsRoute.routeParamSelector).provider_id;

  return {
    _and: [
      { provider_id: { _eq: provider_id } },
      { is_active: { _eq: true } },
      {
        _or: [
          {
            _and: [
              { property_name: { _ilike: search } },
              { property_name: { _is_null: false } },
            ],
          },
          { entity_id: { _ilike: search } },
          {
            _and: [
              { address: { _ilike: search } },
              { address: { _is_null: true } },
            ],
          },
          { fund: { _ilike: search } },
        ],
      },
    ],
  };
};

export const getProperties = graphQLSelector({
  query: graphql`
    subscription getPropertiesSubscription(
      $where: sys_properties_flattened_union_bool_exp = {}
      $limit: Int = 10
      $offset: Int = 0
      $financial_type_id: Int = 1
      $operational_type_id: Int = 2
    ) {
      sys_properties_flattened_union(
        where: $where
        limit: $limit
        offset: $offset
      ) {
        entity_id
        entity_name
        property_id
        organization_id
        address
        is_active
        property_name
        fund
        type
        property_code
        property_mapping
        parent_property_id
        provider {
          provider_name
          provider_id
        }
        mapping_sets_count: properties_mapping_sets_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        users_count: properties_roles_users_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        users_preparers: properties_roles_users(
          where: {
            _and: { is_active: { _eq: true }, property_role_id: { _eq: 1 } }
          }
        ) {
          user_id
        }
        users_financial_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role: {
                property_role_slug: {
                  _in: [
                    "reviewer_1"
                    "reviewer_2"
                    "reviewer_3"
                    "approver_plus_1"
                    "approver_plus_2"
                    "approver_plus_3"
                  ]
                }
              }
              submission_type: {
                submission_type_id: { _eq: $financial_type_id }
              }
            }
          }
        ) {
          user_id
        }
        users_operational_reviewers: properties_roles_users(
          where: {
            _and: {
              is_active: { _eq: true }
              property_role: {
                property_role_slug: {
                  _in: [
                    "reviewer_1"
                    "reviewer_2"
                    "reviewer_3"
                    "approver_plus_1"
                    "approver_plus_2"
                    "approver_plus_3"
                  ]
                }
              }
              submission_type: {
                submission_type_id: { _eq: $operational_type_id }
              }
            }
          }
        ) {
          user_id
        }
        datasets_count: properties_datasets_aggregate(
          where: { is_active: { _eq: true } }
        ) {
          aggregate {
            count
          }
        }
        coa_mappings: properties_mapping_sets(
          where: {
            is_active: { _eq: true }
            mapping_set: {
              mapping_field: { mapping_field_name: { _eq: "gl_account_code" } }
            }
          }
        ) {
          mapping_set_id
        }
        mapping_set_ids: properties_mapping_sets(
          where: { is_active: { _eq: true } }
        ) {
          mapping_set_id
        }
        properties_batch_validation_rules(where: { is_active: { _eq: true } }) {
          batch_validation_rule_id
          validation_mode
        }
        properties_dataset_validation_rules(
          where: { is_active: { _eq: true } }
        ) {
          dataset_validation_rule_id
          validation_mode
        }
        investment_properties_dataset_validation_rules(
          where: { is_active: { _eq: true } }
        ) {
          dataset_validation_rule_id
          validation_mode
        }
        properties_datasets(where: { is_active: { _eq: true } }) {
          dataset_id
        }
      }
    }
  ` as GraphQLReturn<getPropertiesSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const tableState = get(propertiesTableState);
      const submissionTypes = get(getSubmissionTypes());

      const financialType = submissionTypes.find(
        (submissionType) =>
          submissionType.submission_type_slug === SubmissionTypeSlugs.FINANCIAL
      );
      const operationalType = submissionTypes.find(
        (submissionType) =>
          submissionType.submission_type_slug ===
          SubmissionTypeSlugs.OPERATIONAL
      );

      return {
        where: buildPropertiesWhere(get),
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        operational_type_id: operationalType?.submission_type_id,
        financial_type_id: financialType?.submission_type_id,
      };
    },
  mapResponse: (resp) => {
    return resp.sys_properties_flattened_union;
  },
});

export const getPropertiesTotal = graphQLSelector({
  query: graphql`
    subscription getPropertiesTotalSubscription(
      $where: sys_properties_flattened_union_bool_exp = {}
    ) {
      total: sys_properties_flattened_union_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getPropertiesTotalSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        where: buildPropertiesWhere(get),
      };
    },
  mapResponse: (resp) => {
    return resp.total;
  },
});

export const getPropertiesUsedValues = graphQLSelector({
  query: graphql`
    query getPropertiesUsedValuesQuery($id: Int) {
      fund_is_used: sys_properties_flattened_union_aggregate(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            { fund: { _is_null: false } }
            { is_active: { _eq: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      property_name_is_used: sys_properties_flattened_union_aggregate(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            { property_name: { _is_null: false } }
            { is_active: { _eq: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      address_is_used: sys_properties_flattened_union_aggregate(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            { address: { _is_null: false } }
            { is_active: { _eq: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      type_is_used: sys_properties_flattened_union_aggregate(
        where: {
          _and: [
            { provider_id: { _eq: $id } }
            { type: { _is_null: false } }
            { is_active: { _eq: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getPropertiesUsedValuesQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        id: get(ProviderDetailsRoute.routeParamSelector).provider_id,
      };
    },
  mapResponse: (resp) => {
    return {
      fund_is_used: !!resp.fund_is_used.aggregate?.count,
      property_name_is_used: !!resp.property_name_is_used.aggregate?.count,
      type_is_used: !!resp.type_is_used.aggregate?.count,
      address_is_used: !!resp.address_is_used.aggregate?.count,
    };
  },
});
