import { useCherreSetState } from '@cherre-frontend/data-fetching';
import {
  assignMappingDialogTitle,
  assignMappingDialogSubtitle,
} from 'src/products/data-submission-portal/components/AssignMappingDialog/localState';
import { assignMappingsDialogOpenState } from 'src/products/data-submission-portal/recoil/dialog';

export const useOpenAssignMapping = () => {
  const setAssignMappingsDialogOpenState = useCherreSetState(
    assignMappingsDialogOpenState
  );

  const setAssignMappingDialogTitle = useCherreSetState(
    assignMappingDialogTitle
  );

  const setAssignMappingDialogSubtitle = useCherreSetState(
    assignMappingDialogSubtitle
  );

  const openAssignMappingDialogModalWithTitles = (
    title: string,
    subtitle: string
  ) => {
    setAssignMappingsDialogOpenState(true);
    setAssignMappingDialogTitle(title);
    setAssignMappingDialogSubtitle(subtitle);
  };

  return openAssignMappingDialogModalWithTitles;
};
