import {
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Grid,
  IconButton,
  MRT_ColumnDef,
  PageContent,
  Table,
  Tooltip,
  tableBaseStyle,
  useTable,
  ReportFileIcon,
} from '@cherre-frontend/ui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import StatusChip, {
  ValidationStatusChipCodes,
} from '../../../../components/ValidationStatusChip';
import {
  BatchValidationInfo,
  batchValidationModalSlugState,
  batchValidationsInfo,
} from '../../recoil';
import { reportIconButtonStyle, reportIconStyle } from './styles';

const getValidationStatus = (validation: BatchValidationInfo) => {
  if (validation.is_valid) {
    return ValidationStatusChipCodes.validation_passed;
  }
  if (validation.validation_mode === 'soft') {
    return ValidationStatusChipCodes.validation_warning;
  }
  return ValidationStatusChipCodes.validation_failed;
};

//TODO: abstract table width logic
const BatchValidationTable: React.FC = () => {
  const [tableWidth, setTableWidth] = useState<number | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const setBatchValidationModal = useCherreSetState(
    batchValidationModalSlugState
  );

  useEffect(() => {
    const listener = () => {
      const boundingRect = tableRef.current?.getBoundingClientRect();
      if (boundingRect) {
        setTableWidth(boundingRect.width || 1);
      }
    };
    window.addEventListener('resize', listener);
    listener();
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const info = useCherreValue(batchValidationsInfo());

  const columns = useMemo<MRT_ColumnDef<BatchValidationInfo>[]>(
    () =>
      tableWidth
        ? [
            {
              header: 'Rule',
              accessorKey:
                'properties_batch_validation_rule.batch_validation_rule.validation_label',
              size: (tableWidth - 180) * 0.25,
              muiTableBodyCellProps: {
                sx: {
                  ...tableBaseStyle.muiTableBodyCellProps.sx,
                  fontWeight: 700,
                },
              },
            },
            {
              header: 'Description',
              accessorKey:
                'properties_batch_validation_rule.batch_validation_rule.validation_description',
              size: (tableWidth - 180) * 0.75,
            },
            {
              header: 'Validation',
              id: 'validation',
              size: 100,
              Cell: ({ row }) => (
                <StatusChip statusCode={getValidationStatus(row.original)} />
              ),
            },
            {
              header: '',
              accessorKey: 'property_batch_validation_id',
              size: 80,
              Cell: ({ row }) => (
                <Grid container justifyContent={'flex-end'}>
                  <Tooltip title='View Detail'>
                    <IconButton
                      style={reportIconButtonStyle}
                      size='small'
                      color='primary'
                      onClick={() => {
                        setBatchValidationModal(
                          row.original.properties_batch_validation_rule
                            ?.batch_validation_rule.validation_slug ?? ''
                        );
                      }}
                    >
                      <ReportFileIcon style={reportIconStyle} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ),
            },
          ]
        : [],
    [tableWidth]
  );
  const table = useTable({
    data: info,
    columns,
    getRowId: (row) => `${row.property_batch_validation_id}`,
    skeletonRowCount: 5,
    enableRowSelection: false,
    enableSorting: false,
    muiTableContainerProps: {
      sx: {
        overflow: 'hidden',
      },
    },
  });
  return (
    <PageContent>
      <div ref={tableRef}>
        <Table table={table} />
      </div>
    </PageContent>
  );
};

BatchValidationTable.displayName = 'BatchValidationPage/BatchValidationTable';

export default BatchValidationTable;
