import {
  CheckerReturnType,
  atom,
  refine,
} from '@cherre-frontend/data-fetching';
import {
  MappingTypes,
  isSourcedFromMasterCOA,
} from 'src/products/data-submission-portal/pages/mapping-detail/types';

const stringChecker = refine.custom((value) => {
  if (typeof value === 'string' && value !== '') {
    return value;
  } else {
    return null;
  }
});

export const addEditMappingSetChecker = refine.constraint(
  refine.object({
    provider_id: refine.number(),
    mapping_field_id: refine.number(),
    mapping_field_name: stringChecker,
    mapping_set_description: stringChecker,
    master_chart_of_account_id: refine.number(),
    id: refine.number(),
  }),
  (form) => {
    return isSourcedFromMasterCOA(form.mapping_field_name as MappingTypes)
      ? form.master_chart_of_account_id !== 0
      : true;
  }
);

export type AddEditMappingSetFormType = CheckerReturnType<
  typeof addEditMappingSetChecker
>;

export const addEditMappingSetFormState = atom<AddEditMappingSetFormType>({
  key: 'add-edit-mapping-set-form-state',
  default: {
    provider_id: 0,
    mapping_field_id: 0,
    mapping_field_name: '',
    mapping_set_description: '',
    master_chart_of_account_id: 0,
    id: 0,
  },
});

export const addEditMappingSetFormName = atom<string | undefined>({
  key: 'add-edit-mapping-set-form-name',
  default: '',
});
