import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePersona } from '../packages/dsp-role-based-rendering';

export const usePersonaAwarePush = () => {
  const { push } = useHistory();
  const persona = usePersona();
  return useCallback<typeof push>(
    (url: string, state) => {
      return push(url.replace('{{persona}}', persona), state);
    },
    [push, persona]
  );
};
