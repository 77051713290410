import React from 'react';
import { ReportsModal } from 'src/products/data-submission-portal/components/Reports';
import {
  batchValidationModalReportSlugState,
  batchValidationModalStatusFilterState,
  batchValidationReportSelectors,
  batchValidationsReportsState,
} from '../../recoil';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { BatchValidationReportHeader } from './BatchValidationReportHeader';

const getWhereClause = (
  status: 'Any Status' | 'Passed' | 'Failed' | undefined
) => {
  switch (status) {
    case 'Passed':
      return { is_valid: { _eq: true } };
    case 'Failed':
      return { is_valid: { _eq: false } };
    default:
      return undefined;
  }
};

const BatchValidationReportModal: React.FC = () => {
  const data = useCherreValue(batchValidationsReportsState);
  const filterStatus = useCherreValue(batchValidationModalStatusFilterState);

  if (!data) {
    return null;
  }

  const { reports, property_batch_id } = data;

  return (
    <ReportsModal
      reportSlugState={batchValidationModalReportSlugState}
      reports={reports || []}
      getArgs={() => ({ property_batch_id })}
      getWhere={() => getWhereClause(filterStatus)}
      getParams={({ report_view_type }) => ({ report_view_type })}
      selectors={batchValidationReportSelectors}
    >
      <BatchValidationReportHeader />
    </ReportsModal>
  );
};

export default BatchValidationReportModal;
