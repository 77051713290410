import React from 'react';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { GroupedPropertyBatchAccordionProps } from '../types';
import { PropertyBatchBaseAccordion } from '../PropertyBatchAccordion.base';
import { useTabsConfigReviewer } from './useTabsConfigReviewer';
import { useTabsConfigCombinedReviewer } from './useTabsConfigCombinedReviewer';
import { useWorkflowApplyActionToBatch } from '../hooks/useWorkflowApplyActionToBatch';
import { ConfirmationModal, useConfirmationModal } from '@cherre-frontend/ui';

import { getPreparers, getWorkflowAvailableActions } from '../recoil';
import { getActionFromStatus, getStatusFromAction } from './utils';
import { useIsApproverPlus } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { ReviewerAlert } from './ReviewerAlert';
import { WORKFLOW } from '../consts';

export const GroupPropertyBatchReviewerAccordion: React.FC<
  GroupedPropertyBatchAccordionProps
> = ({ propertyBatches, investmentPropertyBatch }) => {
  const { modalProps, confirm } = useConfirmationModal();

  const isApproverPlus = useIsApproverPlus();
  const hasTSA = !!investmentPropertyBatch.property_batch_datasets.length;

  const preparers = useCherreValue(
    getPreparers({
      user_ids: propertyBatches.flatMap((pb) =>
        pb.properties_flattened_union.properties_roles_users.map(
          (p) => p.user_id
        )
      ),
    })
  );

  const workflowAvailableActions = useCherreValue(
    getWorkflowAvailableActions(investmentPropertyBatch.property_batch_id)
  );

  const status = getStatusFromAction(workflowAvailableActions as string[]);

  const submitProperty = useWorkflowApplyActionToBatch(
    investmentPropertyBatch.property_batch_id,
    getActionFromStatus(status)
  );

  const preparersText = preparers?.sys_users
    ?.map(
      ({ sakura_user }) =>
        `${sakura_user?.first_name} ${sakura_user?.last_name}`
    )
    .join(', ');

  const hasPreparers = !!preparers?.sys_users?.length;

  const propertiesApproved = propertyBatches.filter(
    (p) => p.is_approved
  ).length;

  return (
    <>
      <ConfirmationModal {...modalProps} />
      <PropertyBatchBaseAccordion
        investmentPropertyBatch={investmentPropertyBatch}
        propertyBatches={propertyBatches}
        alertComponent={
          <ReviewerAlert
            submitStatus={status}
            submitProperty={submitProperty}
            showTSAText={isApproverPlus && hasTSA}
            isGrouped
          />
        }
        showViewToggle={
          workflowAvailableActions?.includes(
            WORKFLOW.REVIEWER_IS_READY_FOR_COMBINED_TOGGLE
          ) || propertiesApproved === propertyBatches.length
        }
        accordionSubtitleComponent={
          <>
            {hasPreparers ? <>Preparer: {preparersText} • </> : null}
            {propertiesApproved}/{propertyBatches.length} Propert
            {propertiesApproved === 1 ? 'y' : 'ies'} Approved
          </>
        }
        useTabsConfigCombined={(propertyBatches) =>
          useTabsConfigCombinedReviewer(
            propertyBatches,
            investmentPropertyBatch.property_batch_id
          )
        }
        useTabsConfig={(propertyBatch) =>
          useTabsConfigReviewer(
            propertyBatch,
            confirm,
            true,
            hasTSA,
            investmentPropertyBatch.property_batch_id
          )
        }
      />
    </>
  );
};
