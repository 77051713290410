import React from 'react';

import { ReportIcon, Button, styled } from '@cherre-frontend/ui';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { createBatchDialogOpenState } from '../../../recoil';

const NoBatchesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const NoBatchesHeader = styled('h3')`
  font-size: 20px;
  margin: 6px 0;
  line-height: normal;
`;

const NoBatchesContent = styled('p')`
  margin: 0;
  padding-bottom: 20px;
`;

export const NoBatches: React.FC = () => {
  const setCreateBatchDialogOpenState = useCherreSetState(
    createBatchDialogOpenState
  );

  return (
    <NoBatchesContainer>
      <ReportIcon fontSize='large' color='disabled' />
      <NoBatchesHeader>No Batches Yet</NoBatchesHeader>
      <NoBatchesContent>
        There are no submissions batches created yet.
      </NoBatchesContent>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setCreateBatchDialogOpenState(true)}
      >
        Create Batch
      </Button>
    </NoBatchesContainer>
  );
};

NoBatches.displayName = 'NoBatches';
