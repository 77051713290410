import React, { useState } from 'react';
import {
  Popover,
  Link,
  DataSettingsIcon,
  Typography,
  styled,
  popoverClasses,
  MRT_Row,
  IconButton,
  CloseIcon,
} from '@cherre-frontend/ui';
import { useIsSuspended } from '@cherre-frontend/data-fetching';

type DatasetsTooltipProps = {
  datasets: any[];
  onClose: () => void;
};

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[50]};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  grid-area: title;
`;

const CloseIconButton = styled(IconButton)`
  color: #9e9e9e;
  grid-area: close;
`;

const TooltipContainer = styled('div')`
  display: grid;
  grid-template-areas: 'title close' 'list list';
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const TooltipList = styled('div')`
  grid-area: list;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

const DatasetsTooltip: React.FC<DatasetsTooltipProps> = ({
  datasets,
  onClose,
}) => {
  return (
    <TooltipContainer>
      <Title>Assigned Datasets</Title>
      <CloseIconButton size='small' onClick={onClose}>
        <CloseIcon />
      </CloseIconButton>
      <TooltipList>
        {datasets.map((dataset) => (
          <React.Fragment key={dataset.dataset.dataset_label}>
            <DataSettingsIcon />
            <Typography>{dataset.dataset.dataset_label}</Typography>
          </React.Fragment>
        ))}
      </TooltipList>
    </TooltipContainer>
  );
};

export type DatasetsCellProps = {
  row: MRT_Row<any>;
};

const CustomPopover = styled(Popover)`
  .${popoverClasses.paper} {
    border-radius: 10px;
    padding: 10px;
    background: var(--gray-900, #212121);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    color: white;
  }
`;

export const DatasetsCell: React.FC<DatasetsCellProps> = ({ row }) => {
  const suspended = useIsSuspended();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  if (suspended) {
    return null;
  }
  return (
    <>
      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatasetsTooltip
          datasets={row.original.properties_datasets}
          onClose={handleClose}
        />
      </CustomPopover>
      <Link href='#' padding='10px' underline='none' onClick={handleClick}>
        {row.original.datasets_count || 0}
      </Link>
    </>
  );
};

DatasetsCell.displayName = 'DatasetsCell';
