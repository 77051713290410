import React, { useState } from 'react';
import {
  Box,
  Container,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  TablePagination,
  Typography,
} from '@cherre-frontend/ui';

import BatchCard from '../BatchCard';
import BatchListSkeleton from '../BatchCard/BatchListSkeleton';
import { BatchListContainer } from './styles';
import { Link, useLocation } from 'react-router-dom';
import {
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { getSubmissionBatches, submissionBatchesState } from '../../recoil';

export type BatchListProps = {
  isClosed: boolean;
  title: string;
  emptyListMessage: string;
  value: string;
};

const BatchList: React.FC<BatchListProps> = ({
  title,
  emptyListMessage,
  isClosed,
  value,
}) => {
  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useCherreState(submissionBatchesState);
  const submissionBatches = useCherreValue(
    getSubmissionBatches({
      is_closed: isClosed,
      pageIndex: tableState.pageIndex,
      pageSize: tableState.pageSize,
      value,
    })
  );
  const isSuspended = useIsSuspended();
  const { pathname } = useLocation();

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTableState((s) => ({
      ...s,
      pageIndex: newPage,
      pageSize: s.pageSize,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTableState((s) => ({
      ...s,
      pageIndex: 0,
      pageSize: parseInt(event.target.value, 10),
    }));
  };

  const handleChangeSorting = (event: SelectChangeEvent) => {
    const [sortBy, sortDirection] = event.target.value.split('-');
    setSorting(() => ({
      sortBy,
      sortDirection,
    }));
  };

  return (
    <Container style={{ padding: 0, width: '100%', maxWidth: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight='700' color='#000' variant='h5'>
          {title}
        </Typography>
        {!isClosed && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
            <Select
              data-testid='property-sorting-select'
              labelId='property-batch-sorting-select'
              value={`${sorting?.sortBy}-${sorting?.sortDirection}`}
              onChange={handleChangeSorting}
              sx={{ backgroundColor: 'white' }}
            >
              <MenuItem disabled value='disbabled' sx={{ fontSize: 'small' }}>
                SORT BY
              </MenuItem>
              <MenuItem value='created_at_datetime-desc'>
                Created Date - Newest
              </MenuItem>
              <MenuItem value='created_at_datetime-asc'>
                Created Date - Oldest
              </MenuItem>
              <MenuItem value='submission_name-asc'>
                Batch Name - Ascending
              </MenuItem>
              <MenuItem value='submission_name-desc'>
                Batch Name - Descending
              </MenuItem>
              <MenuItem value='due_date-asc'>
                Batch Due Date - Oldest to Newest
              </MenuItem>
              <MenuItem value='due_date-desc'>
                Batch Due Date - Newest to Oldest
              </MenuItem>
              <MenuItem value='reporting_period_start_date-asc'>
                Submission Period - Oldest to Newest
              </MenuItem>
              <MenuItem value='reporting_period_start_date-desc'>
                Submission Period - Newest to Oldest
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      {submissionBatches?.length === 0 ? (
        <Typography
          fontWeight='500'
          color='#9e9e9e'
          fontSize={14}
          variant='h6'
          paddingTop='10px'
        >
          {emptyListMessage}
        </Typography>
      ) : (
        <BatchListContainer>
          {isSuspended ? (
            <BatchListSkeleton listSize={3} />
          ) : (
            submissionBatches?.map((submissionBatch) => (
              <Link
                to={`${pathname}/${submissionBatch.submission_id}`}
                style={{ color: '#000' }}
                key={submissionBatch.submission_id}
              >
                <BatchCard submissionBatch={submissionBatch} />
              </Link>
            ))
          )}

          {isClosed ? (
            <TablePagination
              component='div'
              count={submissionBatches?.length ?? 0}
              page={tableState.pageIndex}
              onPageChange={handleChangePage}
              rowsPerPage={tableState.pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </BatchListContainer>
      )}
    </Container>
  );
};

BatchList.displayName = 'BatchList';

export default BatchList;
