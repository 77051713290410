import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getMappingFieldQuery } from './__generated__/getMappingFieldQuery.graphql';
import { UploadMappingSetRoute } from 'src/products/data-submission-portal/routes';

export const getMappingField = graphQLSelector({
  query: graphql`
    query getMappingFieldQuery($id: Int) {
      sys_mapping_fields(where: { mapping_field_id: { _eq: $id } }) {
        mapping_field_id
        mapping_field_name
        mapping_field_description
        custom_attributes
        organization {
          organization_slug
        }
      }
    }
  ` as GraphQLReturn<getMappingFieldQuery>,
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(UploadMappingSetRoute.routeParamSelector).mapping_field_id,
    }),
  mapResponse: (resp) => resp.sys_mapping_fields[0],
});
