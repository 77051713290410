import { tableBaseStyle } from '@cherre-frontend/ui';

export const muiTableHeadCellProps = (props) => ({
  sx: {
    ...tableBaseStyle.muiTableHeadCellProps(props).sx,
    borderBottom: props.table
      .getState()
      .sorting.some((s) => s.id === props.column.id)
      ? '1px solid black'
      : '1px solid var(--gray-200, #E4E4E4)',
  },
});

export const muiTableBodyCellProps = {
  sx: {
    ...tableBaseStyle.muiTableBodyCellProps.sx,
    height: '42px',
  },
};

export const disableColumnGrow = {
  muiTableHeadCellProps: (props) => ({
    sx: {
      ...muiTableHeadCellProps(props).sx,
      flex: '0 0 auto',
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      ...muiTableBodyCellProps.sx,
      flex: '0 0 auto',
    },
  },
};

export const typeColumnStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
};

export const actionsColumnStyle: React.CSSProperties = {
  display: 'flex',
  gap: 2,
  justifyContent: 'flex-end',
  width: '100%',
};

export const reportIconButtonStyle: React.CSSProperties = {
  width: 30,
  paddingLeft: 6,
};

export const reportIconStyle: React.CSSProperties = { fontSize: 20 };
