import React, { useEffect, useState } from 'react';
import {
  useTable,
  Table,
  MRT_ColumnDef,
  Typography,
  styled,
  MRT_SortingState,
  MRT_RowSelectionState,
} from '@cherre-frontend/ui';
import { Batch, getBatches } from '../query';
import moment from 'moment';
import { useCherreValue } from '@cherre-frontend/data-fetching';

const columns: MRT_ColumnDef<Batch>[] = [
  {
    id: 'submission_name',
    accessorKey: 'submission_name',
    header: 'Batch Name',
    size: 302,
  },
  {
    id: 'submission_type.submission_type_label',
    accessorKey: 'submission_type.submission_type_label',
    header: 'Type',
    size: 160,
  },
  {
    id: 'provider.provider_name',
    accessorKey: 'provider.provider_name',
    header: 'Provider',
    size: 200,
  },
  {
    id: 'property_batches_aggregate.count',
    accessorKey: 'property_batches_aggregate.aggregate.count',
    header: 'Properties',
    size: 150,
  },
  {
    id: 'reporting_period_start_date',
    accessorFn: (row) =>
      moment(row.reporting_period_start_date).format('MMM YYYY'),
    header: 'Period',
    size: 100,
  },
  {
    id: 'due_date',
    accessorFn: (row) => moment(row.due_date).format('DD/MM/YYYY'),
    header: 'Due Date',
    size: 100,
  },
];

const EmptyRowsFallback = styled(Typography)`
  display: grid;
  place-items: center;
  padding: 50px;
`;

export type BatchesTableProps = {
  rowSelection?: MRT_RowSelectionState;
  onRowSelectionChange?: React.Dispatch<
    React.SetStateAction<MRT_RowSelectionState>
  >;
  setPeriods?: React.Dispatch<React.SetStateAction<string[]>>;
  search: string | null;
  providerId: number | null;
  submissionTypeId: number | null;
};

const REPORTING_PERIOD_MAP = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
  quarterly: 'quarter',
  yearly: 'year',
} as const;

function calculateNextPeriodStartDate({
  reporting_period,
  reporting_period_start_date,
}) {
  const interval = REPORTING_PERIOD_MAP[reporting_period];
  const next_period = moment(reporting_period_start_date).add(1, interval);
  return next_period;
}

const BatchesTable: React.FC<BatchesTableProps> = ({
  rowSelection,
  onRowSelectionChange,
  setPeriods,
  search,
  providerId,
  submissionTypeId,
}) => {
  const [sorting, onSortingChange] = useState<MRT_SortingState>([
    { id: 'submission_name', desc: false },
  ]);
  const data = useCherreValue(
    getBatches({ search, providerId, submissionTypeId, sorting })
  );
  useEffect(() => {
    if (data) {
      const ks = Object.keys(rowSelection || {});
      setPeriods?.(
        ks.flatMap((k) => {
          const batch = data.find((b) => b.submission_id === Number(k));
          return batch
            ? [calculateNextPeriodStartDate(batch).format('MMM YYYY')]
            : [];
        })
      );
    }
  }, [data, rowSelection]);
  const table = useTable({
    data,
    columns,
    enableRowSelection: Boolean(onRowSelectionChange),
    state: { rowSelection: rowSelection || {}, sorting },
    muiTableContainerProps: { sx: { height: '40vh' } },
    onRowSelectionChange,
    onSortingChange,
    getRowId: (row) => `${row.submission_id}`,
    skeletonRowCount: 5,
    renderEmptyRowsFallback: () => (
      <EmptyRowsFallback>No Batches found</EmptyRowsFallback>
    ),
  });

  return <Table table={table} />;
};

BatchesTable.displayName = 'BatchesTableBatchesTable';

export default BatchesTable;
