import { refine, searchParamsSelector } from '@cherre-frontend/data-fetching';
import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';

export const assignPreparersSearch = searchParamsSelector({
  key: 'assign-preparers-search',
  refine: withDefault(string(), ''),
  routes: ['/**'],
});

export const manageUsersSearch = searchParamsSelector({
  key: 'manage-users-search',
  refine: withDefault(string(), ''),
  routes: ['/**'],
});

export const assignPropertiesDialogOpenState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'assign-properties',
    refine: withDefault(bool(), false),
  });

export const assignPropertiesConfirmationDialogOpenState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'assign-properties-confirmation',
    refine: withDefault(bool(), false),
  });

export const assignPropertiesTableSearch =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'assign-properties-search',
    refine: withDefault(string(), ''),
  });

export const assignPropertiesTableState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'assign-properties-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'entity_id', desc: false }],
      }
    ),
  });

export const propertiesTableSearch =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'properties-search',
    refine: withDefault(string(), ''),
  });

export const propertiesTableState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'properties-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [
          { id: 'entity_id', desc: false },
          { id: 'property_mapping', desc: true },
        ],
      }
    ),
  });
