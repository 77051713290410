import React from 'react';
import { Redirect } from 'react-router';
import { useSearchParam } from 'react-use';
import NotFound from 'src/products/shell/pages/NotFound';
import * as z from 'zod';

// Type for route configs WITH params
type NotificationConfigRouteWithParamsType<T extends z.ZodType> = {
  params: T;
  redirectTo: (params: z.infer<T>) => string;
};

// Type for route configs WITHOUT params
type NotificationConfigRouteWithoutParamsType = {
  params?: undefined;
  redirectTo: () => string;
};

// Union type to accommodate both types of route configs
type NotificationConfigRouteType<T extends z.ZodType | undefined> =
  T extends z.ZodType
    ? NotificationConfigRouteWithParamsType<T>
    : NotificationConfigRouteWithoutParamsType;

function createNotificationRouteConfig<T extends z.ZodType | undefined>(
  config: NotificationConfigRouteType<T>
) {
  return config as NotificationConfigRouteType<T>;
}

type NotificationConfigType = {
  [key: string]:
    | NotificationConfigRouteType<z.ZodType>
    | NotificationConfigRouteWithoutParamsType;
};

const notificationConfig: NotificationConfigType = {
  'review-submission': { redirectTo: () => '/dsp/reviewer/submissions' },
  'review-submission-detail': createNotificationRouteConfig({
    params: z.object({
      submission_id: z.coerce.number(),
    }),
    redirectTo: ({ submission_id }) =>
      `/dsp/reviewer/submissions/${submission_id}`,
  }),
  'prepare-submission': { redirectTo: () => '/dsp/preparer/submissions' },
  'prepare-submission-detail': createNotificationRouteConfig({
    params: z.object({
      submission_id: z.coerce.number(),
    }),
    redirectTo: ({ submission_id }) =>
      `/dsp/preparer/submissions/${submission_id}`,
  }),
  'single-comment': createNotificationRouteConfig({
    params: z.object({
      submission_id: z.coerce.number(),
      encoded_search_param_selector: z.coerce.string(),
      recipient_type_path_variable: z.coerce.string(),
    }),
    redirectTo: ({
      submission_id,
      recipient_type_path_variable,
      encoded_search_param_selector,
    }) =>
      `/dsp/${recipient_type_path_variable}/submissions/${submission_id}?${encoded_search_param_selector}`,
  }),
  'multiple-comments': createNotificationRouteConfig({
    params: z.object({
      submission_id: z.coerce.number(),
      encoded_search_param_selector: z.coerce.string(),
      recipient_type_path_variable: z.coerce.string(),
    }),
    redirectTo: ({
      submission_id,
      recipient_type_path_variable,
      encoded_search_param_selector,
    }) =>
      `/dsp/${recipient_type_path_variable}/submissions/${submission_id}?${encoded_search_param_selector}`,
  }),
  'new-reminders': createNotificationRouteConfig({
    params: z.object({
      encoded_search_param_selector: z.coerce.string(),
      recipient_type_path_variable: z.coerce.string(),
    }),
    redirectTo: ({
      recipient_type_path_variable,
      encoded_search_param_selector,
    }) =>
      `/dsp/${recipient_type_path_variable}/submissions?${encoded_search_param_selector}`,
  }),
};

const NotificationLink: React.FC = () => {
  const type = useSearchParam('type');

  const config = notificationConfig[type as keyof typeof notificationConfig];

  if (!type || !config) {
    return null;
  }

  let params: any | undefined;

  if (config.params) {
    const ret = config.params.safeParse(
      Object.fromEntries(new URLSearchParams(window.location.search).entries())
    );

    if (ret.success === false) {
      return <NotFound />;
    }

    params = ret.data;
  }

  return <Redirect to={config.redirectTo(params)} />;
};

export default NotificationLink;
