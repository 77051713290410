import {
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  IconButton,
  PackageReportCommentIcon,
  ReportFileIcon,
  Table,
  Tooltip,
  useTable,
  useTheme,
  // eslint-disable-next-line prettier/prettier
  type MRT_ColumnDef,
} from '@cherre-frontend/ui';
import { ChipStyled } from '../components/ChipStyled';
import { ReportNameCell } from '../components/ReportNameCell';
import { getLabel, useCheckReportHasComments } from '../utils';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  useClaims,
  usePersona,
} from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import {
  PropertyBatchStage,
  PropertyBatchStageID,
} from '../../../__generated__/constants';
import { packageReportSidebarState } from '../PackageReportSidebar/recoil';
import {
  actionsColumnStyle,
  disableColumnGrow,
  muiTableBodyCellProps,
  muiTableHeadCellProps,
  reportIconButtonStyle,
  reportIconStyle,
} from '../styles';
import {
  $packageReportState,
  $propertyBatchPackageReportInfo,
  PackageReport,
  ModalState,
} from '../recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export interface PackageReportsTableProps {
  property_batch_id: number;
  modalState: ModalState;
  disableLastRowBorder?: boolean;
}

export const PackageReportsTable: React.FC<PackageReportsTableProps> = ({
  modalState,
  property_batch_id,
  disableLastRowBorder,
}) => {
  const setPackageReportModal = useCherreSetState(modalState);
  const info = useCherreValue(
    $propertyBatchPackageReportInfo(property_batch_id)
  );
  const persona = usePersona();
  const states = useCherreValue(
    $packageReportState({ property_batch_id, persona })
  );

  const remindersFF = useFeatureFlag('DSPReminders');
  const isReviewer = useClaims('reviewer');
  const isPreparer = useClaims('preparer');
  const isAdmin = useClaims('view_admin');

  const setPackageReportSidebar = useCherreSetState(packageReportSidebarState);

  const stageId = info?.property_batch_stage
    .property_batch_stage_id as PropertyBatchStageID;

  const disablePackageReportDetail =
    !isPreparer &&
    !isAdmin &&
    stageId !== PropertyBatchStage.APPROVED.id &&
    stageId !== PropertyBatchStage.REVIEW_1.id &&
    stageId !== PropertyBatchStage.REVIEW_2.id &&
    stageId !== PropertyBatchStage.REVIEW_3.id;

  const isReviewed = (report: PackageReport) => {
    return states?.find((r) => r.package_report_id === report.package_report_id)
      ?.is_reviewed;
  };

  const isRejected = (report: PackageReport) => {
    return states?.some(
      (r) =>
        r.package_report_id === report.package_report_id &&
        r.is_reviewed === false
    );
  };

  const theme = useTheme();

  const checkReportHasComments = useCheckReportHasComments(
    property_batch_id,
    info
  );

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<PackageReport>[]>(
    () => [
      {
        id: 'report_name',
        accessorKey: 'package_report_label',
        header: 'Report Name',
        size: 150,
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => {
          const reviewed = isReviewed(row.original);
          return (
            <ReportNameCell
              renderedCellValue={renderedCellValue}
              reviewed={reviewed ?? false}
            />
          );
        },
      },
      {
        header: disablePackageReportDetail ? '' : 'Reviewed on',
        id: 'reviewed_on',
        size: 200,
        ...disableColumnGrow,
        accessorFn: (row) => {
          const reviewed_datetime =
            states?.find((r) => r.package_report_id === row.package_report_id)
              ?.reviewed_datetime ?? null;

          return reviewed_datetime
            ? moment(reviewed_datetime).format('MM/DD/YYYY h:mm a')
            : null;
        },
        Cell: ({ cell }) => {
          if (disablePackageReportDetail) {
            return null;
          }

          return <>{cell.getValue()}</>;
        },
      },
      {
        header: disablePackageReportDetail ? '' : 'Status',
        id: 'status',
        accessorKey: 'package_report_id',
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const reviewedA = isReviewed(rowA.original);
          const reviewedB = isReviewed(rowB.original);
          if (reviewedA && !reviewedB) {
            return 1;
          }
          if (!reviewedA && reviewedB) {
            return -1;
          }
          return 0;
        },
        Cell: ({ row }) => {
          const reviewed = isReviewed(row.original) ?? false;
          const rejected = isRejected(row.original) ?? false;

          if (disablePackageReportDetail) {
            return null;
          }

          const label = getLabel(isReviewer, reviewed, rejected, theme);

          return (
            <ChipStyled
              size='small'
              title={label.text}
              label={label.text}
              htmlColor={label.color}
              background={label.background}
            />
          );
        },
        size: 150,
        ...disableColumnGrow,
      },
      {
        id: 'actions',
        header: '',
        size: 80,
        ...disableColumnGrow,
        Cell: ({ row }) => {
          const reportHasComments = checkReportHasComments(
            row.original.package_report_id
          );

          return (
            <Box style={actionsColumnStyle}>
              <Tooltip
                title={
                  reportHasComments
                    ? `Comments ${remindersFF ? 'or reminders ' : ''}available`
                    : `No comments ${remindersFF ? 'or reminders' : ''}`
                }
              >
                <IconButton
                  style={reportIconButtonStyle}
                  size='small'
                  color='primary'
                  disabled={disablePackageReportDetail}
                  onClick={() => {
                    setPackageReportSidebar({
                      [row.original.report.report_slug]: {
                        sidebarOpen: true,
                        comment: '',
                        reminder: '',
                        showCompletedReminders: false,
                        tab: 0,
                      },
                    });
                    setPackageReportModal({
                      property_id: info?.property.property_id,
                      property_batch_id,
                      report_slug: row.original.report.report_slug,
                    });
                  }}
                >
                  <PackageReportCommentIcon
                    color={reportHasComments ? 'primary' : 'disabled'}
                    style={reportIconStyle}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='View Detail'>
                <IconButton
                  style={reportIconButtonStyle}
                  size='small'
                  color='primary'
                  disabled={disablePackageReportDetail}
                  onClick={() => {
                    setPackageReportModal({
                      property_id: info?.property.property_id,
                      property_batch_id,
                      report_slug: row.original.report.report_slug,
                    });
                    setPackageReportSidebar({});
                  }}
                >
                  <ReportFileIcon style={reportIconStyle} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [states, disablePackageReportDetail, checkReportHasComments, remindersFF]
  );

  const table = useTable({
    data: info?.package_reports ?? undefined,
    columns,
    layoutMode: 'grid',
    manualSorting: false,
    enableRowSelection: false,
    enableSelectAll: false,
    enableSorting: true,
    muiTableBodyRowProps: disableLastRowBorder
      ? {
          sx: {
            ':last-child td': {
              borderBottom: 'none',
            },
          },
        }
      : {},
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    getRowId: (originalRow) => {
      return originalRow.package_report_id.toString();
    },
    initialState: {
      sorting: [
        {
          id: 'report_name',
          desc: false,
        },
      ],
    },
  });

  return <Table table={table} />;
};
