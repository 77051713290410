/**
 * @generated SignedSource<<3cf5ef1b5e1dcb5ca39fb7ade1fb7468>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getMappingFieldsSubscription$variables = {
  provider_id: number;
};
export type getMappingFieldsSubscription$data = {
  readonly sys_mapping_fields: ReadonlyArray<{
    readonly custom_attributes: any | null;
    readonly is_single_mapping_set: boolean;
    readonly mapping_field_description: string;
    readonly mapping_field_id: number;
    readonly mapping_field_name: string;
    readonly mapping_sets: ReadonlyArray<{
      readonly mapping_set_description: string;
      readonly mapping_set_id: number;
    }>;
  }>;
};
export type getMappingFieldsSubscription = {
  response: getMappingFieldsSubscription$data;
  variables: getMappingFieldsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "mapping_field_description": "asc"
        }
      }
    ],
    "concreteType": "sys_mapping_fields",
    "kind": "LinkedField",
    "name": "sys_mapping_fields",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_field_description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_single_mapping_set",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "custom_attributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Literal",
                "name": "is_active",
                "value": {
                  "_eq": true
                }
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "provider_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "provider_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "sys_mapping_sets",
        "kind": "LinkedField",
        "name": "mapping_sets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_set_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapping_set_description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_mapping_fields(order_by:{\"mapping_field_description\":\"asc\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getMappingFieldsSubscription",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getMappingFieldsSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8be478787f3711aa27b3abedd87fc43f",
    "id": null,
    "metadata": {},
    "name": "getMappingFieldsSubscription",
    "operationKind": "subscription",
    "text": "subscription getMappingFieldsSubscription(\n  $provider_id: Int!\n) {\n  sys_mapping_fields(order_by: {mapping_field_description: asc}) {\n    mapping_field_id\n    mapping_field_name\n    mapping_field_description\n    is_single_mapping_set\n    custom_attributes\n    mapping_sets(where: {provider_id: {_eq: $provider_id}, is_active: {_eq: true}}) {\n      mapping_set_id\n      mapping_set_description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8156a778d865c5d939a525dc34ced07";

export default node;
