/**
 * @generated SignedSource<<954822d386e4d8386d542f439b89b91b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getProviderUserByIdQuery$variables = {
  user_id?: number | null;
};
export type getProviderUserByIdQuery$data = {
  readonly sys_users: ReadonlyArray<{
    readonly sakura_user: {
      readonly email: string;
      readonly first_name: string | null;
      readonly last_name: string | null;
    } | null;
    readonly user_id: number;
  }>;
};
export type getProviderUserByIdQuery = {
  response: getProviderUserByIdQuery$data;
  variables: getProviderUserByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "users_last_seen": {
            "last_login_at": "asc"
          }
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "user_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "user_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_users",
    "kind": "LinkedField",
    "name": "sys_users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sakura_user",
        "kind": "LinkedField",
        "name": "sakura_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getProviderUserByIdQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getProviderUserByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cc8cfbfe93edfc4e0966379b947634f2",
    "id": null,
    "metadata": {},
    "name": "getProviderUserByIdQuery",
    "operationKind": "query",
    "text": "query getProviderUserByIdQuery(\n  $user_id: Int\n) {\n  sys_users(where: {user_id: {_eq: $user_id}}, order_by: {users_last_seen: {last_login_at: asc}}) {\n    user_id\n    sakura_user {\n      email\n      first_name\n      last_name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8bfbda853eefe656d7b397fe14aff3f2";

export default node;
