import { useCherreValue } from '@cherre-frontend/data-fetching';
import React from 'react';
import NoContentMessage from './NoContentMessage';
import { SubmissionUsersBaseTab } from './SubmissionUsersTab.base';
import UserRow from './UserRow';
import { getSubmissionUsers } from './recoil';

type PreparersTabProps = {
  batchName: string;
  batchId: number;
  entityId: string;
};

export const PreparersTab: React.FC<PreparersTabProps> = ({
  batchName,
  batchId,
  entityId,
}) => {
  const submissionUsers = useCherreValue(getSubmissionUsers(batchId));

  const ctaLink = React.useMemo(() => {
    const emails = [
      ...new Set(
        submissionUsers?.preparers
          .map((preparer) => preparer.user.sakura_user?.email)
          .filter(Boolean)
      ),
    ].join(',');
    const subject = `Action Required in Data Submissions Portal - Entity ID ${entityId} • ${batchName} - ${batchId}`;
    return `mailto:${emails}?subject=${subject}`;
  }, [submissionUsers?.preparers]);

  return (
    <SubmissionUsersBaseTab
      ctaTitle='Contact Preparers'
      ctaLink={ctaLink}
      hideCta={submissionUsers?.preparers.length === 0}
      userListComponent={
        submissionUsers?.preparers.length === 0 ? (
          <NoContentMessage message='No preparers assigned.' />
        ) : (
          <>
            {submissionUsers?.preparers?.map((preparer) => (
              <UserRow
                key={preparer.property_role_user_id}
                email={preparer.user.sakura_user?.email}
                name={`${preparer.user.sakura_user?.first_name} ${preparer.user.sakura_user?.last_name}`}
              />
            ))}
          </>
        )
      }
    />
  );
};
