import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { PropertiesRoute } from '../../../../../routes';
import { refine, selector, noWait } from '@cherre-frontend/data-fetching';
import { getPropertiesPageProperties } from '../queries/getProperties';

export const propertiesPageTableSearch =
  PropertiesRoute.searchParamsSelectorFamily({
    key: 'properties-table-search',
    refine: withDefault(string(), ''),
  });

export const propertiesPageTableState =
  PropertiesRoute.searchParamsSelectorFamily({
    key: 'properties-page-table-state',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 50, pageIndex: 0 },
        sorting: [
          { id: 'property_mapping', desc: true },
          { id: 'property_code', desc: false },
          { id: 'provider.provider_name', desc: false },
        ],
      }
    ),
  });

export const PropertiesTableFiltersState =
  PropertiesRoute.searchParamsSelectorFamily({
    key: 'properties-table-filters',
    refine: withDefault(
      object({
        Provider: withDefault(string(), ''),
        Fund: withDefault(string(), ''),
        Status: withDefault(string(), ''),
        Type: withDefault(string(), ''),
      }),
      {
        Provider: 'Any Provider',
        Fund: 'Any Fund',
        Status: 'Any Status',
        Type: 'Any Type',
      }
    ),
  });

export const propertiesTableHasData = selector({
  key: 'properties-table-has-data',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const search = get(propertiesPageTableSearch);
      const loadable = get(noWait(getPropertiesPageProperties()));
      const data = loadable.valueMaybe();

      return (data && data.length > 0) || search;
    },
});
