import {
  GraphQLReturn,
  graphQLSelector,
  selector,
  selectorFamily,
  Writeable,
  DefaultValue,
} from '@cherre-frontend/data-fetching';
import {
  order_by,
  recoilSubmissionBatchQuery,
  recoilSubmissionBatchQuery$data,
  sys_property_batches_order_by,
} from './__generated__/recoilSubmissionBatchQuery.graphql';
import { PropertyType } from '../../../../__generated__/constants';
import { number, string, object, withDefault, array } from '@recoiljs/refine';
import { graphql } from 'relay-runtime';
import { SubmissionRoute } from 'src/products/data-submission-portal/routes';
import { recoilGetPropertyModelQuery } from './__generated__/recoilGetPropertyModelQuery.graphql';
import { recoilSubmissionBatchKPIsQuery } from './__generated__/recoilSubmissionBatchKPIsQuery.graphql';

export type SubmissionBatchResponse = recoilSubmissionBatchQuery$data;
export type SubmissionBatchItem = SubmissionBatchResponse['sys_submissions'][0];
export type Dataset =
  SubmissionBatchItem['property_batches'][0]['property_batch_datasets'][0]['dataset'];

const BatchDetailPropertiesStateRefine = withDefault(
  object({
    pagination: object({ pageSize: number(), pageIndex: number() }),
    stage: string(),
    sortBy: string(),
    sortDirection: string(),
  }),
  {
    pagination: { pageSize: 10, pageIndex: 0 },
    stage: '',
    sortBy: '',
    sortDirection: 'asc',
  }
);

export const batchDetailPropertiesState =
  SubmissionRoute.searchParamsSelectorFamily({
    key: 'bdpState',
    refine: BatchDetailPropertiesStateRefine,
  });

export const scrollState = SubmissionRoute.searchParamsSelectorFamily({
  key: 'scroll',
  refine: withDefault(number(), 0),
});

const expandedCardsSearchParamsState =
  SubmissionRoute.searchParamsSelectorFamily({
    key: 'expandedCards',
    refine: withDefault(array(number()), []),
  });

const CARDS_EXPANDED_STATE_SESSION_KEY = (submissionId: number) =>
  `property-batches/session-cards-expanded-state-${submissionId}`;
const expandedCardsSessionState = selector({
  key: 'property-batches/batch-detail-properties-expanded-cards-session',
  get: ({ get }) => {
    const submissionId = get(SubmissionRoute.routeParamSelector).submission_id;
    return JSON.parse(
      sessionStorage.getItem(CARDS_EXPANDED_STATE_SESSION_KEY(submissionId)) ??
        '[]'
    ) as number[];
  },
  set: ({ get }, newValue) => {
    const submissionId = get(SubmissionRoute.routeParamSelector).submission_id;
    sessionStorage.setItem(
      CARDS_EXPANDED_STATE_SESSION_KEY(submissionId),
      JSON.stringify(newValue)
    );
  },
});

export const expandedCardsState = selector<number[]>({
  key: 'expanded-all-cards-state',
  get: ({ get }) => {
    const expandedCardsSearchParams = get(expandedCardsSearchParamsState);
    const expandedCards = expandedCardsSearchParams.length
      ? expandedCardsSearchParams
      : get(expandedCardsSessionState);

    return expandedCards as number[];
  },
  set: ({ set }, cardsToExpand: number[] | DefaultValue) => {
    if (cardsToExpand instanceof DefaultValue) {
      set(expandedCardsSessionState, []);
      set(expandedCardsSearchParamsState, []);
      return;
    }

    set(expandedCardsSessionState, cardsToExpand);
    set(expandedCardsSearchParamsState, cardsToExpand);
  },
});

// you can do a selector to separate each expanded one, with a get and a set
export const expandedCardState = selectorFamily<boolean, number>({
  key: 'expanded-cards-state',
  get:
    (cardId) =>
    ({ get }) => {
      const expandedCardsSearchParams = get(expandedCardsSearchParamsState);
      const expandedCards = expandedCardsSearchParams.length
        ? expandedCardsSearchParams
        : get(expandedCardsSessionState);

      return expandedCards.some((id) => id === cardId) ?? false;
    },
  set:
    (cardId) =>
    ({ set }, newValue) => {
      const getNewList = (old: readonly number[]) => {
        const filtered = old.filter((id) => id !== cardId);
        if (newValue === true) {
          return [...filtered, cardId];
        } else {
          return filtered;
        }
      };

      set(expandedCardsSessionState, getNewList);
      set(expandedCardsSearchParamsState, getNewList);
    },
});

const getPropertyModel = graphQLSelector({
  query: graphql`
    query recoilGetPropertyModelQuery($submission_id: Int!) {
      sys_property_batches(
        where: {
          submission: { submission_id: { _eq: $submission_id } }
          properties_flattened_union: {}
        }
        limit: 1
      ) {
        properties_flattened_union {
          property_models {
            property_model_slug
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetPropertyModelQuery>,
  mapResponse: (r) =>
    r.sys_property_batches[0]?.properties_flattened_union?.property_models
      ?.property_model_slug === 'many_to_one',
});

export const getSubmissionBatchKPIs = graphQLSelector({
  mapVariables:
    () =>
    ({ get }) => {
      return {
        submission_id: get(SubmissionRoute.routeParamSelector).submission_id,
        investment_slug: PropertyType.INVESTMENT.label,
      };
    },
  mapResponse: (response) => {
    return response.sys_submissions?.[0];
  },
  query: graphql`
    subscription recoilSubmissionBatchKPIsQuery(
      $submission_id: Int
      $investment_slug: String!
    ) {
      sys_submissions(where: { submission_id: { _eq: $submission_id } }) {
        kpis: property_batches_aggregate(
          where: {
            property: {
              property_type: { property_type_slug: { _neq: $investment_slug } }
            }
          }
        ) {
          aggregate {
            kpi_properties_total_count: count
            sum {
              kpi_package_reports_reviewed_count
              kpi_validations_warn_count
              kpi_validations_failed_count
              kpi_datasets_uploaded_count
              kpi_datasets_total_count
              kpi_package_reports_total_count
              kpi_properties_submitted_count
              kpi_properties_approved_count
              kpi_properties_approved_or_rejected_count
            }
          }
        }
      }
    }
  ` as GraphQLReturn<recoilSubmissionBatchKPIsQuery>,
  resetCache: true,
  swr: false,
});

export const getSubmissionBatch = graphQLSelector({
  mapVariables:
    () =>
    ({ get }) => {
      const { pagination, sortBy, stage, sortDirection } = get(
        batchDetailPropertiesState
      );
      const submission_id = get(
        SubmissionRoute.routeParamSelector
      ).submission_id;

      const isManyToOne = get(getPropertyModel({ submission_id }));
      const parsedStage = stage === 'any-status' ? null : stage;

      const order_by: sys_property_batches_order_by[] = [
        {
          properties_flattened_union: {
            [isManyToOne ? 'entity_id' : sortBy]: sortDirection,
          },
        },
      ];
      const order_by_investment: sys_property_batches_order_by[] = [
        { property: { property_code: sortDirection as order_by } },
      ];

      return {
        submission_id,
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        investment_slug: PropertyType.INVESTMENT.label,
        order_by,
        order_by_investment,
        where: parsedStage
          ? {
              role_status: { _eq: parsedStage },
            }
          : {},
      };
    },
  mapResponse: (response) => {
    return response.sys_submissions?.[0];
  },
  query: graphql`
    subscription recoilSubmissionBatchQuery(
      $submission_id: Int
      $limit: Int
      $offset: Int
      $investment_slug: String
      $where: sys_property_batches_bool_exp!
      $order_by: [sys_property_batches_order_by!]
      $order_by_investment: [sys_property_batches_order_by!]
    ) {
      sys_submissions(where: { submission_id: { _eq: $submission_id } }) {
        submission_id
        property_batch_ids: property_batches {
          property_batch_id
        }
        property_batches_aggregate(
          where: {
            _and: [
              $where
              {
                property: {
                  property_type: {
                    property_type_slug: { _neq: $investment_slug }
                  }
                }
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        investment_property_batches: property_batches(
          where: {
            property: {
              property_type: { property_type_slug: { _eq: $investment_slug } }
            }
          }
          order_by: $order_by_investment
        ) {
          property_id
          property_batch_id
          property_batch_datasets {
            property_batch_dataset_id
            batch_file {
              file_name
            }
          }
        }
        property_batches(
          limit: $limit
          offset: $offset
          where: {
            _and: [
              $where
              {
                property: {
                  property_type: {
                    property_type_slug: { _neq: $investment_slug }
                  }
                }
              }
            ]
          }
          order_by: $order_by
        ) {
          is_in_last_review_stage
          property_batch_id
          is_approved
          is_valid
          kpi_package_reports_reviewed_count
          properties_flattened_union @required(action: THROW) {
            parent_property_id
            property_model_id
            property_name
            property_id
            entity_id
            property_code
            property_mapping
            property_models {
              property_model_slug
            }
            properties_datasets {
              template_id
              dataset_id
            }
            properties_roles_users(
              where: {
                property_role: { property_role_set: { _eq: "preparer" } }
              }
            ) {
              user_id
            }
          }
          property_batch_validations {
            is_valid
            validation_mode
          }
          role_status
          property_batch_stage {
            property_batch_stage_id
            property_batch_stage_description
          }
          datasets_uploaded: property_batch_datasets_aggregate(
            where: { file_id: { _is_null: false } }
          ) {
            aggregate {
              count
            }
          }
          package_reports(order_by: { package_report_label: asc }) {
            package_report_label
            package_report_id
            report @required(action: THROW) {
              report_slug
            }
          }
          property_batch_datasets {
            property_batch_dataset_id
            updated_at_datetime
            is_valid
            dataset_row_count
            validated_datetime
            batch_file {
              file_name
            }
            warning_count: property_batch_dataset_validations_aggregate(
              where: {
                is_valid: { _eq: false }
                validation_mode: { _eq: "soft" }
              }
            ) {
              aggregate {
                count
              }
            }
            valid_count: property_batch_dataset_validations_aggregate(
              where: { is_valid: { _eq: true } }
            ) {
              aggregate {
                count
              }
            }
            error_count: property_batch_dataset_validations_aggregate(
              where: {
                is_valid: { _eq: false }
                validation_mode: { _eq: "hard" }
              }
            ) {
              aggregate {
                count
              }
            }
            dataset {
              dataset_id
              dataset_label
            }
            dataset_status {
              dataset_status_id
              dataset_status_description
            }
          }
          comments_count: comments_aggregate {
            aggregate {
              count
            }
          }
          reminders_count: reminders_aggregate(
            where: { is_resolved: { _eq: false } }
          ) {
            aggregate {
              count
            }
          }
          comments {
            package_report_id
          }
        }
      }
    }
  ` as GraphQLReturn<recoilSubmissionBatchQuery>,
  resetCache: true,
  swr: false,
});

export type Submission = Writeable<
  NonNullable<recoilSubmissionBatchQuery['response']['sys_submissions'][number]>
>;

export type PropertyBatch = NonNullable<Submission['property_batches'][number]>;

export type PackageReport = NonNullable<
  PropertyBatch['package_reports']
>[number];
