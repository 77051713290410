import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  IngestionFlow,
  IngestionFlowActiveStepKey,
  IngestionFlowState,
} from 'src/products/ingestion/components/IngestionFlow/IngestionFlow';
import {
  $getPropertyBatchDatasetNew,
  $isApproverPlusQuery,
} from './queries/getPropertyBatchDataset';
import {
  constSelector,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { UploadDatasetNewRoute } from '../../routes';
import { $getPropertyDataset } from './queries/getPropertyDataset';
import { useAppContext, useCherreEvent } from '@cherre-frontend/core';
import { useAsyncCreateIngestFile } from '../../hooks/useCreateIngestFile';
import { lastUploadedPropertyBatchDatasetAtom } from '../submission-batch-details/recoil';
import { ingestFlowState } from './recoil';
import { useSelector } from 'react-redux';

type IngestionConfig = { formats: { [k: string]: string } } | undefined;

export default function UploadDatasetNew() {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();

  const property_batch_dataset = useCherreValue($getPropertyBatchDatasetNew());
  const dataset_id = useCherreValue(
    UploadDatasetNewRoute.routeParamSelector
  )?.dataset_id;

  if (!dataset_id) {
    throw new Error('No dataset ID found');
  }

  const property_dataset = useCherreValue(
    property_batch_dataset?.dataset_id && property_batch_dataset.property_batch
      ? $getPropertyDataset({
          dataset_id: property_batch_dataset.dataset_id,
          property_id: property_batch_dataset.property_batch.property_id,
        })
      : constSelector(null)
  );

  if (
    !property_batch_dataset ||
    !property_batch_dataset.property_batch.property_id ||
    !property_batch_dataset.dataset ||
    !dataset_id ||
    !property_dataset
  ) {
    console.error(
      'Missing property_batch_dataset, dataset_id or property_dataset'
    );
    goBack();
    return null;
  }

  const pushOrGoBack = useCallback(() => {
    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      push(
        `/dsp/preparer/submissions/${property_batch_dataset.property_batch.submission_id}`
      );
    }
  }, [
    location.state?.shouldGoBack,
    property_batch_dataset.property_batch.submission_id,
  ]);

  const [flowState, setFlowState] = useCherreState(ingestFlowState);

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);
  if (!ownerName) {
    console.error('Missing ownerName');
    pushOrGoBack();
    return null;
  }

  const createIngestFile = useAsyncCreateIngestFile();
  const setLastUploadedPropertyBatchDataset = useCherreSetState(
    lastUploadedPropertyBatchDatasetAtom
  );

  const context = useAppContext();

  const onComplete = useCherreEvent(
    'onCompleteCreateIngestFile',
    async (file_reference_source: string) => {
      if (!property_batch_dataset.dataset_id) {
        throw new Error('No dataset ID found');
      }

      const ingestFileResult = await createIngestFile({
        params: {
          dataset_id: property_batch_dataset.dataset_id,
          file_reference_source: `${ownerName}/${file_reference_source}`,
          property_batch_ids: [property_batch_dataset.property_batch_id],
          file_source: 'unified',
        },
      });

      if (ingestFileResult?.errors || !ingestFileResult?.output?.data) {
        context.showSnackbar({
          message: `${property_batch_dataset.dataset?.dataset_label} import failed! Please try again.`,
          type: 'error',
        });
      } else if (
        (ingestFileResult?.output?.data?.property_batches?.not_found?.length ??
          0) > 0
      ) {
        // If the property batch is not found, show missing entity dialog
        setLastUploadedPropertyBatchDataset({
          property_batch_id: property_batch_dataset.property_batch_id,
          dataset_id: property_batch_dataset.dataset_id,
        });
      } else {
        context.showSnackbar({
          message: `${property_batch_dataset.dataset?.dataset_label} import successful!`,
          type: 'success',
        });
      }
      pushOrGoBack();
    }
  );

  const state = useMemo(
    () => ({
      fileId: flowState?.fileId ?? undefined,
      signedUrl: flowState?.signedUrl ?? undefined,
      selectedSheet: flowState?.selectedSheet ?? undefined,
      selectedHeaderRows: flowState?.selectedHeaderRows ?? undefined,
      activeStepKey: (flowState?.activeStepKey ??
        'upload') as IngestionFlowActiveStepKey,
    }),
    [flowState]
  );

  const setState = useCallback(
    (newState: React.SetStateAction<IngestionFlowState>) => {
      setFlowState((prev) => ({
        ...prev,
        ...(typeof newState === 'function'
          ? newState({
              fileId: prev?.fileId ?? undefined,
              signedUrl: prev?.signedUrl ?? undefined,
              selectedSheet: prev?.selectedSheet ?? undefined,
              activeStepKey: (prev?.activeStepKey ??
                'upload') as IngestionFlowActiveStepKey,
              selectedHeaderRows: prev?.selectedHeaderRows ?? undefined,
              // validationResults: prev?.validationResults ?? undefined,
            })
          : newState),
      }));
    },
    []
  );

  const isApproverPlus = useCherreValue(
    $isApproverPlusQuery({
      property_batch_dataset_id:
        property_batch_dataset.property_batch_dataset_id,
      submission_type_id:
        property_batch_dataset.property_batch.submission.submission_type_id,
    })
  );

  const datasetSchemas = property_batch_dataset.dataset
    ?.unified_ingest_schemas as unknown as {
    original: {
      label: string;
      name: string;
      mandatory: boolean;
      dataType: string;
    }[];
    tsa: {
      label: string;
      name: string;
      mandatory: boolean;
      dataType: string;
    }[];
  };
  const targetSchema = isApproverPlus
    ? datasetSchemas.tsa
    : datasetSchemas.original;

  const templateId = isApproverPlus
    ? 'None'
    : property_dataset?.template_id ?? 'None';

  const datasetLabel = property_batch_dataset.dataset?.dataset_label;
  const datasetName = isApproverPlus
    ? `${datasetLabel} Adjustments`
    : datasetLabel;

  return (
    <IngestionFlow
      config={{
        templateId,
        ownerName,
        datasetName,
        datasetId: dataset_id.toString(),
        targetSchema: targetSchema,
      }}
      ingestionConfig={
        property_batch_dataset.property_batch.property.provider
          ?.ingestion_config as IngestionConfig
      }
      onComplete={onComplete}
      onCancel={pushOrGoBack}
      state={state}
      setState={setState}
    />
  );
}
