/**
 * @generated SignedSource<<903e21b02bce8ebbc3d8344270e3595f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type getSourceCodeDropdownSubscription$variables = {
  id: number;
};
export type getSourceCodeDropdownSubscription$data = {
  readonly sys_mapping_set_values: ReadonlyArray<{
    readonly label: string;
    readonly value: string | null;
  }>;
};
export type getSourceCodeDropdownSubscription = {
  response: getSourceCodeDropdownSubscription$data;
  variables: getSourceCodeDropdownSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "source_mapping_code"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_set_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_mapping_set_values",
    "kind": "LinkedField",
    "name": "sys_mapping_set_values",
    "plural": true,
    "selections": [
      {
        "alias": "label",
        "args": null,
        "kind": "ScalarField",
        "name": "source_mapping_code",
        "storageKey": null
      },
      {
        "alias": "value",
        "args": null,
        "kind": "ScalarField",
        "name": "source_mapping_label",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSourceCodeDropdownSubscription",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSourceCodeDropdownSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fcbdff84a43f9c9047d0c22d86d57697",
    "id": null,
    "metadata": {},
    "name": "getSourceCodeDropdownSubscription",
    "operationKind": "subscription",
    "text": "subscription getSourceCodeDropdownSubscription(\n  $id: Int!\n) {\n  sys_mapping_set_values(distinct_on: source_mapping_code, where: {mapping_set_id: {_eq: $id}}) {\n    label: source_mapping_code\n    value: source_mapping_label\n  }\n}\n"
  }
};
})();

(node as any).hash = "9233c5939429ee51471f4bfc8ad7d821";

export default node;
