/**
 * @generated SignedSource<<c1bec4b2c871976feb4cebdce1d4363c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type can_review_package_report_input = {
  property_batch_id: number;
};
export type queriesCanReviewPackageReportQuery$variables = {
  params: can_review_package_report_input;
};
export type queriesCanReviewPackageReportQuery$data = {
  readonly _sys_can_review_package_report: {
    readonly can_review: boolean;
  };
};
export type queriesCanReviewPackageReportQuery = {
  response: queriesCanReviewPackageReportQuery$data;
  variables: queriesCanReviewPackageReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "can_review_package_report_output",
    "kind": "LinkedField",
    "name": "_sys_can_review_package_report",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "can_review",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queriesCanReviewPackageReportQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queriesCanReviewPackageReportQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b4762e31496b527247cc4350d309af89",
    "id": null,
    "metadata": {},
    "name": "queriesCanReviewPackageReportQuery",
    "operationKind": "query",
    "text": "query queriesCanReviewPackageReportQuery(\n  $params: can_review_package_report_input!\n) {\n  _sys_can_review_package_report(params: $params) {\n    can_review\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7b0ed619fd8e34c9312779f703c0610";

export default node;
