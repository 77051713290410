import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getPropertyBatchDatasetQuery } from './__generated__/getPropertyBatchDatasetQuery.graphql';
import { UploadDatasetRoute } from 'src/products/data-submission-portal/routes';

export const getPropertyBatchDataset = graphQLSelector({
  query: graphql`
    query getPropertyBatchDatasetQuery($id: Int) {
      sys_property_batch_datasets(
        where: {
          property_batch_dataset_id: { _eq: $id }
          property_batch: { submission: { is_closed: { _neq: true } } }
        }
        limit: 1
      ) {
        property_batch_dataset_id
        dataset_id
        dataset {
          dataset_description
          dataset_label
        }
        property_batch_id
        property_batch {
          submission_id
        }
      }
    }
  ` as GraphQLReturn<getPropertyBatchDatasetQuery>,
  mapVariables:
    () =>
    ({ get }) => ({
      id: get(UploadDatasetRoute.routeParamSelector).dataset_id,
    }),
  mapResponse: (resp) => resp.sys_property_batch_datasets[0],
});
