/**
 * @generated SignedSource<<c935c7f3c266ec6feb47fb6cf116400e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SysUploadAccountGroupingInput = {
  account_grouping_name: string;
  file_reference_source: string;
  file_source: string;
};
export type useUploadAccountGroupingsMutation$variables = {
  input: SysUploadAccountGroupingInput;
};
export type useUploadAccountGroupingsMutation$data = {
  readonly _sys_upload_account_grouping: {
    readonly account_grouping_id: number;
  } | null;
};
export type useUploadAccountGroupingsMutation = {
  response: useUploadAccountGroupingsMutation$data;
  variables: useUploadAccountGroupingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SysUploadAccountGroupingOutput",
    "kind": "LinkedField",
    "name": "_sys_upload_account_grouping",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "account_grouping_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadAccountGroupingsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadAccountGroupingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "983b19a485a92fd87dc503988af91361",
    "id": null,
    "metadata": {},
    "name": "useUploadAccountGroupingsMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadAccountGroupingsMutation(\n  $input: SysUploadAccountGroupingInput!\n) {\n  _sys_upload_account_grouping(input: $input) {\n    account_grouping_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "294b8548d97f2fee3bc4ca0ce4b0d300";

export default node;
