import {
  GraphQLReturn,
  Writeable,
  graphQLSelector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getDatasetsQuery } from './__generated__/getDatasetsQuery.graphql';

export type Dataset = Writeable<
  getDatasetsQuery['response']['sys_datasets'][number]
>;

export const getDatasets = graphQLSelector({
  query: graphql`
    query getDatasetsQuery {
      sys_datasets(order_by: { dataset_label: asc }) {
        dataset_label
        dataset_id
        dataset_slug
        is_tsa_enabled
        dataset_validation_rules(
          where: { is_active: { _eq: true } }
          order_by: { validation_label: asc }
        ) {
          validation_slug
          validation_label
          validation_description
          dataset_validation_rule_id
          validation_params
        }
      }
    }
  ` as GraphQLReturn<getDatasetsQuery>,
  mapResponse: (resp) => {
    return resp.sys_datasets;
  },
});
