import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';
import { userCheckerType } from 'src/products/data-submission-portal/recoil/dialog';
import { useWarningBox } from './useWarningBox';

const ApproverPropertyRoles = [
  PropertyRole.REVIEWER_1,
  PropertyRole.REVIEWER_2,
  PropertyRole.REVIEWER_3,
  PropertyRole.APPROVER_PLUS_1,
  PropertyRole.APPROVER_PLUS_2,
  PropertyRole.APPROVER_PLUS_3,
];

const stages = ['reviewer1', 'reviewer2', 'reviewer3'] as const;

type UsersByStage = Record<(typeof stages)[number], userCheckerType[]>;

export const useCheckApproverPresent = (
  usersByChain: (UsersByStage | undefined)[]
) => {
  const {
    triggerApproverRequired,
    triggerMissingReviewerPreviousLevel,
    warningBoxState,
    clearWarningBoxState,
  } = useWarningBox();

  const check = () => {
    for (const users of usersByChain) {
      for (let stageIdx = 0; stageIdx < stages.length; ++stageIdx) {
        const stage = stages[stageIdx];

        const hasUsersInStage = !!users?.[stage]?.length;
        const hasUsersInNextStages = stages
          .filter((s, idx) => idx > stageIdx)
          .some((s) => !!users?.[s]?.length);

        if (!hasUsersInStage && hasUsersInNextStages) {
          triggerMissingReviewerPreviousLevel();
          return;
        }

        if (!hasUsersInStage) {
          // Ignore empty stages
          continue;
        }

        const hasApprover = users[stage].some((u) => {
          return ApproverPropertyRoles.some(
            (approverRole) => approverRole.id === u.property_role_id
          );
        });

        if (!hasApprover) {
          triggerApproverRequired();
          return false;
        }
      }
    }

    return true;
  };

  return {
    check,
    warningBoxState,
    clearWarningBoxState,
  };
};
