import {
  GraphQLReturn,
  constSelector,
  graphQLSelector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { queriesCanReviewPackageReportQuery } from './__generated__/queriesCanReviewPackageReportQuery.graphql';
import { queriesReviewPackageReportMutation } from './__generated__/queriesReviewPackageReportMutation.graphql';
import { queriesPackageReportRemindersSubscription } from './__generated__/queriesPackageReportRemindersSubscription.graphql';
import { queriesCanUploadTSAQuery } from './__generated__/queriesCanUploadTSAQuery.graphql';
import { queriesGetPropertyBatchDatasetIdQuery } from './__generated__/queriesGetPropertyBatchDatasetIdQuery.graphql';
import { PropertyRole } from 'src/products/data-submission-portal/__generated__/constants';

const _canReviewPackageReport = graphQLSelector({
  polling: true,
  query: graphql`
    query queriesCanReviewPackageReportQuery(
      $params: can_review_package_report_input!
    ) {
      _sys_can_review_package_report(params: $params) {
        can_review
      }
    }
  ` as GraphQLReturn<queriesCanReviewPackageReportQuery>,
  mapVariables: (property_batch_id: number) => () => ({
    params: { property_batch_id },
  }),
  mapResponse: (response) =>
    response._sys_can_review_package_report.can_review ?? false,
});

export const canReviewPackageReport = (property_batch_id?: number) =>
  property_batch_id
    ? _canReviewPackageReport(property_batch_id)
    : constSelector(false);

export const reviewPackageReportMutation = graphql`
  mutation queriesReviewPackageReportMutation(
    $params: review_package_report_input!
  ) {
    _sys_review_package_report(params: $params) {
      is_reviewed
    }
  }
` as GraphQLReturn<queriesReviewPackageReportMutation>;

export const packageReportRemindersCount = graphQLSelector({
  query: graphql`
    subscription queriesPackageReportRemindersSubscription(
      $property_batch_id: Int!
    ) {
      sys_reminders_aggregate(
        where: { property_batch_id: { _eq: $property_batch_id } }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<queriesPackageReportRemindersSubscription>,
  mapVariables: (property_batch_id: number) => () => ({
    property_batch_id,
  }),
  mapResponse: (response) =>
    response.sys_reminders_aggregate.aggregate?.count || 0,
});

const getPropertyBatchDatasetId = graphQLSelector({
  query: graphql`
    query queriesGetPropertyBatchDatasetIdQuery(
      $submission_id: Int!
      $property_id: Int!
      $dataset_id: Int!
    ) {
      sys_property_batch_datasets(
        where: {
          property_batch: {
            submission_id: { _eq: $submission_id }
            property_id: { _eq: $property_id }
          }
          dataset: {
            dataset_id: { _eq: $dataset_id }
            is_tsa_enabled: { _eq: true }
          }
        }
        limit: 1
      ) {
        property_batch_dataset_id
        property_batch {
          property {
            property_code
          }
          submission {
            submission_name
          }
        }
        dataset {
          dataset_slug
        }
      }
    }
  ` as GraphQLReturn<queriesGetPropertyBatchDatasetIdQuery>,
});

export const _canUploadTSAQuery = graphQLSelector({
  query: graphql`
    query queriesCanUploadTSAQuery(
      $property_batch_id: Int!
      $approver_plus_slugs: [String!]!
    ) {
      sys_property_batches(
        where: {
          property_batch_id: { _eq: $property_batch_id }
          _or: [
            {
              property: {
                properties_roles_users: {
                  property_role: {
                    property_role_slug: { _in: $approver_plus_slugs }
                  }
                }
              }
            }
            {
              properties_flattened_union: {
                property_mapping: { _eq: "1:1" }
                properties_roles_users: {
                  property_role: {
                    property_role_slug: { _in: $approver_plus_slugs }
                  }
                }
              }
            }
          ]
        }
        limit: 1
      ) {
        property_id
        submission_id
        properties_flattened_union {
          property_mapping
          parent_property_id
        }
        package_reports(
          where: {
            package_reports_datasets_aggregate: {
              count: { predicate: { _eq: 1 } }
            }
          }
        ) {
          package_reports_datasets {
            dataset_id
          }
        }
      }
    }
  ` as GraphQLReturn<queriesCanUploadTSAQuery>,
  mapVariables: (vars: { property_batch_id: number }) => () => ({
    ...vars,
    approver_plus_slugs: [
      PropertyRole.APPROVER_PLUS_1.label,
      PropertyRole.APPROVER_PLUS_2.label,
      PropertyRole.APPROVER_PLUS_3.label,
    ],
  }),
});

const canUploadTSAWithPropertyBatchDatasetId = selectorFamily({
  key: 'canUploadTSAWithPropertyBatchDatasetId',
  scoped: true,
  get:
    ({ property_batch_id }: { property_batch_id: number }) =>
    async ({ get }) => {
      const { sys_property_batches: batches } = await get(
        _canUploadTSAQuery({ property_batch_id })
      );

      const batch = batches[0];
      if (!batch) {
        return { canUploadTSA: false };
      }

      const package_report = batch.package_reports?.[0];
      if (!package_report) {
        console.error('No package report found');
        return { canUploadTSA: false };
      }

      const union = batch.properties_flattened_union;

      const property_id =
        union && union.property_mapping === '1:1'
          ? union.parent_property_id
          : batch.property_id;

      if (!property_id) {
        console.error('No property found');
        return { canUploadTSA: false };
      }

      const submission_id = batch.submission_id;

      const packageReportDataset = package_report.package_reports_datasets[0];

      if (!packageReportDataset) {
        console.error('No dataset found');
        return { canUploadTSA: false };
      }

      const dataset_id = packageReportDataset.dataset_id;

      const getPropertyBatchDatasetIdResult = get(
        getPropertyBatchDatasetId({ dataset_id, property_id, submission_id })
      );

      const propertyBatchDataset =
        getPropertyBatchDatasetIdResult.sys_property_batch_datasets[0];

      if (!propertyBatchDataset) {
        console.error('No property batch dataset found');
        return { canUploadTSA: false };
      }

      return {
        canUploadTSA: true,
        tsaPropertyBatchDatasetId:
          propertyBatchDataset.property_batch_dataset_id,
        dataset_id,
        property_code:
          propertyBatchDataset.property_batch.property.property_code,
        submission_name:
          propertyBatchDataset.property_batch.submission.submission_name,
        dataset_slug: propertyBatchDataset.dataset?.dataset_slug ?? '',
      };
    },
});

export const canUploadTSAQuery = (property_batch_id?: number) =>
  property_batch_id
    ? canUploadTSAWithPropertyBatchDatasetId({
        property_batch_id,
      })
    : constSelector({ canUploadTSA: false } as {
        canUploadTSA: boolean;
        tsaPropertyBatchDatasetId?: number;
        property_code?: string;
        dataset_id?: number;
        submission_name?: string;
        dataset_slug?: string;
      });
