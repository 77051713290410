import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { AccountGroupingDetailRoute } from 'src/products/data-submission-portal/routes';
import { getAccountTreeQuery } from './__generated__/getAccountTreeQuery.graphql';

export const getAccountTree = graphQLSelector({
  query: graphql`
    query getAccountTreeQuery($id: Int!) {
      sys_organization_account_trees(
        where: { organization_account_tree_id: { _eq: $id } }
      ) {
        organization_account_tree_id
        account_tree_name
        updated_at_datetime
        organization {
          organization_label
        }
      }
    }
  ` as GraphQLReturn<getAccountTreeQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const id = get(
        AccountGroupingDetailRoute.routeParamSelector
      ).account_grouping_id;
      return {
        id,
      };
    },
  mapResponse: (resp) => resp.sys_organization_account_trees[0],
});
