import { PropertyRoleSetID, PropertyRoleSet } from './__generated__/constants';

type ReviewerLikePropertyRoleSet = Extract<
  PropertyRoleSetID,
  'reviewer' | 'view_only'
>;

export const reviewerLikePropertyRoleSets: ReviewerLikePropertyRoleSet[] = [
  'reviewer',
  'view_only',
];

export const isPropertyRoleSet = (
  roleSet: string
): roleSet is PropertyRoleSetID => {
  return PropertyRoleSet.IDs.includes(roleSet as PropertyRoleSetID);
};

export const isReviewerLikePropertyRoleSet = (
  roleSet: string
): roleSet is ReviewerLikePropertyRoleSet => {
  const is = isPropertyRoleSet(roleSet);
  if (is) {
    return reviewerLikePropertyRoleSets.includes(
      roleSet as ReviewerLikePropertyRoleSet
    );
  }

  throw new Error(`Invalid PropertyRoleSet: ${roleSet}`);
};
