import {
  QueryResult,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  ActionsMenu,
  DatasetsIcon,
  Grid,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MenuItem,
  PersonIcon,
  Table,
  TogglesIcon,
  styled,
  tableBaseStyle,
  useTable,
  useTheme,
} from '@cherre-frontend/ui';
import React, { useEffect, useMemo } from 'react';
import { ClickCard } from 'src/products/data-submission-portal/components/ClickCard';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { StatusChip } from 'src/products/data-submission-portal/components/StatusChip';
import {
  getClickCardDataMappingsByIds,
  getDatasetsByIds,
} from '../queries/getClickCardData';
import {
  Property,
  getProperties,
  getPropertiesTotal,
  getPropertiesUsedValues,
} from '../queries/getProperties';
import { propertiesTableState } from '../recoil';
import { assignDatasetsToPropertyState } from '../../../../../components/AssignDatasetsDialog/localState';
import {
  assignMappingToPropertyState,
  mappingIdToAssignState,
} from '../../../../../components/AssignMappingDialog/localState';
import NoProperties from './NoProperties';
import { UsersClickCard } from '../../../../../components/UsersClickCard';
import {
  assignDatasetsDialogOpenState,
  managedPropertyState,
  selectedPropertiesToAssign,
} from 'src/products/data-submission-portal/recoil/dialog';
import { isPropertyActive } from 'src/products/data-submission-portal/utils/isPropertyActive';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
  width: '100%';
`;

export const disableColumnGrow = {
  muiTableHeadCellProps: (props) => ({
    sx: {
      ...tableBaseStyle.muiTableHeadCellProps(props).sx,
      flex: '0 0 auto',
    },
  }),
  muiTableBodyCellProps: {
    sx: {
      ...tableBaseStyle.muiTableBodyCellProps.sx,
      flex: '0 0 auto',
    },
  },
};

export type PropertiesPanelProps = {
  setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  rowSelection: MRT_RowSelectionState;
  search?: string;
  openAssignMappingDialogModalWithTitles: (
    title: string,
    subtitle: string
  ) => void;
};

const PropertiesTable: React.FC<PropertiesPanelProps> = ({
  search,
  setRowSelection,
  rowSelection,
  openAssignMappingDialogModalWithTitles,
}) => {
  const theme = useTheme();
  const [tableState, setTableState] = useCherreState(propertiesTableState);
  const setMappingIdToAssignState = useCherreSetState(mappingIdToAssignState);
  const assignMappingToProperty = useCherreSetState(
    assignMappingToPropertyState
  );
  const setAssignDatasetsToProperty = useCherreSetState(
    assignDatasetsToPropertyState
  );

  const setSelectedPropertiesToAssign = useCherreSetState(
    selectedPropertiesToAssign
  );
  const setManagedPropertyState = useCherreSetState(managedPropertyState);

  const total = useCherreValue(getPropertiesTotal());
  const usedValues = useCherreValue(getPropertiesUsedValues());
  const data = useCherreValue(getProperties());

  const setAssignDatasetsDialogOpenState = useCherreSetState(
    assignDatasetsDialogOpenState
  );

  useEffect(() => {
    const rowSelectionIds = Object.keys(rowSelection);
    const rowSelectionData = data?.filter((property) =>
      rowSelectionIds.includes(property.property_id.toString())
    );
    const mappedRowSelectionData =
      rowSelectionData?.map((property) => ({
        entity_id: property.entity_id,
        property_id: property.property_id,
        property_name: property.property_name,
        organization_id: property.organization_id,
        property_mapping: property.property_mapping,
        provider: property.provider ?? null,
        parent_property_id: property.parent_property_id,
        property_code: property.property_code,
      })) ?? [];
    setSelectedPropertiesToAssign(mappedRowSelectionData);
  }, [rowSelection, data]);

  const columns: MRT_ColumnDef<QueryResult<typeof getProperties>[number]>[] =
    useMemo(
      () => [
        {
          accessorKey: 'entity_id',
          header: 'Source ID',
          size: 120,
          ...disableColumnGrow,
        },
        {
          accessorKey: 'property_code',
          header: 'Target Entity ID',
          size: 170,
          ...disableColumnGrow,
        },
        {
          accessorKey: 'property_mapping',
          header: 'Mapping',
          size: 120,
          ...disableColumnGrow,
        },
        ...(usedValues?.property_name_is_used
          ? [
              {
                accessorKey: 'property_name',
                header: 'Name',
                size: 180,
              },
            ]
          : []),
        ...(usedValues?.address_is_used
          ? [
              {
                accessorKey: 'address',
                header: 'Address',
                size: 220,
              },
            ]
          : []),
        ...(usedValues?.fund_is_used
          ? [
              {
                accessorKey: 'fund',
                header: 'Fund',
                maxSize: 120,
                ...disableColumnGrow,
              },
            ]
          : []),
        ...(usedValues?.type_is_used
          ? [
              {
                accessorKey: 'type',
                header: 'Type',
                maxSize: 120,
                ...disableColumnGrow,
              },
            ]
          : []),
        {
          accessorFn: (row) => row.users_count.aggregate?.count ?? 0,
          id: 'properties_roles_users_aggregate.count',
          header: 'Users',
          size: 70,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '70px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '70px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <UsersClickCard
              assigned_preparers={
                row.original.users_preparers.length > 0
                  ? row.original.users_preparers.map((obj) => obj.user_id)
                  : []
              }
              assigned_fin_reviewers={
                row.original.users_financial_reviewers.length > 0
                  ? row.original.users_financial_reviewers.map(
                      (obj) => obj.user_id
                    )
                  : []
              }
              assigned_op_reviewers={
                row.original.users_operational_reviewers.length > 0
                  ? row.original.users_operational_reviewers.map(
                      (obj) => obj.user_id
                    )
                  : []
              }
              link_text={renderedCellValue}
              icon={<PersonIcon />}
            />
          ),
        },
        {
          accessorFn: (row) => row.mapping_sets_count.aggregate?.count ?? 0,
          id: 'properties_mapping_sets_aggregate.count',
          header: 'Mappings',
          size: 100,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '105px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '105px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <ClickCard
              title='Mappings'
              data={useCherreValue(
                getClickCardDataMappingsByIds({
                  mapping_ids: row.original.mapping_set_ids.map(
                    (obj) => obj.mapping_set_id
                  ),
                })
              )}
              link_text={renderedCellValue}
              icon={<TogglesIcon />}
            />
          ),
        },
        {
          accessorFn: (row) => row.datasets_count.aggregate?.count ?? 0,
          id: 'properties_datasets_aggregate.count',
          header: 'Datasets',
          size: 90,
          muiTableHeadCellProps: (props) => ({
            sx: {
              ...tableBaseStyle.muiTableHeadCellProps(props).sx,
              '& > .Mui-TableHeadCell-Content': {
                width: '95px',
              },
            },
          }),
          muiTableBodyCellProps: {
            sx: {
              ...tableBaseStyle.muiTableBodyCellProps.sx,
              '& > .MuiTypography-root': {
                width: '95px',
              },
            },
          },
          Cell: ({ renderedCellValue, row }) => (
            <ClickCard
              title='Datasets'
              data={useCherreValue(
                getDatasetsByIds({
                  dataset_ids: row.original.properties_datasets.map(
                    (obj) => obj.dataset_id
                  ),
                })
              )}
              id={row.original.property_id}
              link_text={renderedCellValue}
              icon={<DatasetsIcon />}
            />
          ),
        },
        {
          accessorFn: (row) => {
            return isPropertyActive(row);
          },
          header: 'DSP Status',
          Cell: ({ renderedCellValue }) => (
            <StatusChip active={renderedCellValue as boolean} />
          ),
          size: 110,
          muiTableHeadCellProps: (props) => ({
            align: 'right',
            sx: tableBaseStyle.muiTableHeadCellProps(props).sx,
          }),
          muiTableBodyCellProps: {
            align: 'right',
          },
        },
      ],
      []
    );

  const rowActionOptions = [
    {
      name: 'manage-users',
      label: <span>Manage Users</span>,
      icon: <PersonIcon sx={{ height: 16 }} />,
      onClick: (property: Property) => {
        setManagedPropertyState({
          entity_id: property.entity_id,
          property_id: property.property_id,
          property_name: property.property_name,
          organization_id: property.organization_id,
          property_mapping: property.property_mapping,
        });
      },
    },
    {
      name: 'manage-mappings',
      label: <span>Manage Mappings</span>,
      icon: <TogglesIcon sx={{ height: 16 }} />,
      onClick: (row) => {
        const currentMappingId = row.coa_mappings?.[0]?.mapping_set_id;
        setMappingIdToAssignState(currentMappingId);
        assignMappingToProperty(row);
        openAssignMappingDialogModalWithTitles(
          'Manage COA Mapping',
          `Entity ID ${row.entity_id} - ${row.entity_name}`
        );
      },
    },
    {
      name: 'manage-datasets',
      label: <span>Manage Datasets</span>,
      icon: <DatasetsIcon sx={{ height: 16 }} />,
      onClick: (row) => {
        setAssignDatasetsToProperty(row);
        setAssignDatasetsDialogOpenState(true);
      },
    },
    /*{
      name: 'reassign-property',
      label: <span>Reassign Property</span>,
      onClick: () => console.log('Row action clicked'),
    },
    {
      name: 'remove',
      label: <span>Remove</span>,
      onClick: () => console.log('Row action clicked'),
    },*/
  ];

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    renderRowActions: ({ row }) => (
      <Grid
        container
        justifyContent={'flex-end'}
        sx={{
          color: theme.palette.grey[500],
          '& .MuiIconButton-root': {
            color: theme.palette.grey[700],
          },
        }}
      >
        <ActionsMenu>
          {rowActionOptions.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => option.onClick(row.original)}
            >
              {option.icon} {option.label}
            </MenuItem>
          ))}
        </ActionsMenu>
      </Grid>
    ),
    state: {
      rowSelection,
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.property_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th:last-child': {
          width: '50px',
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBaseStyle.muiTableBodyRowProps.sx,
        '& td:last-child': {
          width: '50px',
        },
      },
    },
  });

  if (data && data.length === 0) {
    return search ? (
      <NoItemsFound title='No Properties Found' />
    ) : (
      <NoProperties />
    );
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

export default PropertiesTable;
