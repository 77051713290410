import { refine, atom } from '@cherre-frontend/data-fetching';
import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';

import { ProviderDetailsRoute } from '../../../../routes';

export const providerUsersTableSearch =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'providerUsers-search',
    refine: withDefault(string(), ''),
  });

export const providerUsersTableState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'providers-users-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'first_name', desc: false }],
      }
    ),
  });

export const disableUserDialogOpenState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'disable-modal',
    refine: withDefault(bool(), false),
  });

export const sendEmailDialogOpenState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'send-email-modal',
    refine: withDefault(bool(), false),
  });

export const selectedUserState = atom({
  key: 'selected-provider-user',
  default: {
    user_id: '',
    user_first_name: '',
    user_last_name: '',
    user_email: '',
    status: undefined,
  },
});
