import React from 'react';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Typography } from '@cherre-frontend/ui';
import { selectedOperationalReviewerUsers } from '../../../../recoil/dialog';
import { UserRoleSelect } from '../../components/UserRoleSelect';
import { useUserRoleSelect } from '../../hooks/useUserRoleSelect';

const OperationalReviewersForm: React.FC = () => {
  const [selectedOperationalReviewers, setSelectedOperationalReviewers] =
    useCherreState(selectedOperationalReviewerUsers);

  if (!selectedOperationalReviewers) {
    return null;
  }

  const { userRoleSelectPropsReviewers, showApproverPlusMessage } =
    useUserRoleSelect(
      selectedOperationalReviewers,
      setSelectedOperationalReviewers,
      true,
      'Operational'
    );

  return (
    <>
      <Typography variant='h6'>Operational Submissions</Typography>
      {userRoleSelectPropsReviewers.map((props) => (
        <UserRoleSelect key={props.level} {...props} />
      ))}
      {showApproverPlusMessage && (
        <Typography variant='body2' color='grey.600' align='center'>
          An Approver Plus must be the last reviewer in the chain.
        </Typography>
      )}
    </>
  );
};

export default OperationalReviewersForm;
