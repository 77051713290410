import React from 'react';
import { Chip, styled } from '@cherre-frontend/ui';

const ProviderNameChipStyled = styled(Chip)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  background-color: ${({ theme }) => theme.accents.info.light};
  padding: 0 7px;

  .MuiChip-label {
    padding: 0px;
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

type ProviderNameChipProps = {
  label: string;
};

export const ProviderNameChip: React.FC<ProviderNameChipProps> = ({
  label,
}) => {
  return <ProviderNameChipStyled label={label} />;
};

ProviderNameChip.displayName = 'ProviderProviderNameChip';
