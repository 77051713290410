import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { refine } from '@cherre-frontend/data-fetching';
import { AccountGroupingDetailRoute } from '../../routes';
import { atom } from 'recoil';

export const accountGroupingDetailPageTableSearch =
  AccountGroupingDetailRoute.searchParamsSelectorFamily({
    key: 'account-grouping-detail-table-search',
    refine: withDefault(string(), ''),
  });

export const accountGroupingDetailPageTableState =
  AccountGroupingDetailRoute.searchParamsSelectorFamily({
    key: 'account-grouping-detail-table-state',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'account_tree_id', desc: false }],
      }
    ),
  });

export const accountGroupingDetailTableFiltersState =
  AccountGroupingDetailRoute.searchParamsSelectorFamily({
    key: 'account-grouping-detail-table-filters',
    refine: withDefault(
      object({
        account_group_record_type: withDefault(writableArray(string()), []),
        account_group_code: withDefault(writableArray(string()), []),
        account_group_description: withDefault(writableArray(string()), []),
        gl_account_code: withDefault(writableArray(string()), []),
        total_into_code: withDefault(writableArray(string()), []),
        nest: withDefault(writableArray(string()), []),
      }),
      {
        account_group_record_type: [],
        account_group_code: [],
        account_group_description: [],
        gl_account_code: [],
        total_into_code: [],
        nest: [],
      }
    ),
  });

export const accountGroupingDetailsHasData = atom({
  key: 'account-grouping-table-has-data',
  default: false,
});
