import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  IngestionFlow,
  IngestionFlowActiveStepKey,
  IngestionFlowState,
} from 'src/products/ingestion/components/IngestionFlow/IngestionFlow';
import {
  constSelector,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { UploadDatasetBulkNewRoute } from '../../routes';
import { ingestFlowState } from './recoil';
import { $getBulkDataset } from './queries/getBulkDataset';
import { $getSubmission } from './queries/getSubmission';
import { bulkUploadModalState } from '../submission-batch-details/components/BulkUploadModal/recoil';
import { useCherreEvent } from '@cherre-frontend/core';
import { useSelector } from 'react-redux';

type IngestionConfig = { formats: { [k: string]: string } } | undefined;

export default function UploadDatasetNew() {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();

  const setBulkUploadModalState = useCherreSetState(bulkUploadModalState);

  const dataset_id = useCherreValue(
    UploadDatasetBulkNewRoute.routeParamSelector
  )?.dataset_id;
  const submission_id = useCherreValue(
    UploadDatasetBulkNewRoute.routeParamSelector
  )?.submission_id;
  const propertyBatches = useCherreValue(
    $getSubmission({ dataset_id: dataset_id!, submission_id: submission_id! })
  );
  const property_dataset = useCherreValue(
    propertyBatches && dataset_id
      ? $getBulkDataset({
          dataset_id: dataset_id!,
          property_ids: propertyBatches.map((property) => property.property_id),
        })
      : constSelector(null)
  );

  if (!property_dataset || !submission_id || !dataset_id) {
    console.error(
      'Missing property_batch_dataset, dataset_id or property_dataset'
    );
    goBack();
    return null;
  }

  const pushOrGoBack = useCallback(() => {
    setBulkUploadModalState(
      (state) =>
        ({
          ...state,
          open: false,
          closedSelectedDataset: null,
        } as const)
    );

    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      if (!submission_id) {
        return;
      }

      push(`/dsp/preparer/submissions/${submission_id}`);
    }
  }, [location.state?.shouldGoBack, submission_id]);

  const [flowState, setFlowState] = useCherreState(ingestFlowState);

  const ownerName = useSelector((state) => state.user.profile.value.domOwner);
  if (!ownerName) {
    console.error('Missing ownerName');
    pushOrGoBack();
    return null;
  }

  const onComplete = useCherreEvent(
    'onCompleteCreateIngestFile',
    async (file_reference_source: string) => {
      setBulkUploadModalState(
        (state) =>
          ({
            ...state,
            open: true,
            selectedDataset: dataset_id.toString() ?? '',
            uploadedFileId: `${ownerName}/${file_reference_source}`,
            step: 'SELECT_PROPERTIES',
          } as const)
      );

      push(`/dsp/preparer/submissions/${submission_id}`);
    }
  );

  const setState = useCallback(
    (newState: React.SetStateAction<IngestionFlowState>) => {
      setFlowState((prev) => ({
        ...prev,
        ...(typeof newState === 'function'
          ? newState({
              fileId: prev?.fileId ?? undefined,
              signedUrl: prev?.signedUrl ?? undefined,
              selectedSheet: prev?.selectedSheet ?? undefined,
              activeStepKey: (prev?.activeStepKey ??
                'upload') as IngestionFlowActiveStepKey,
              selectedHeaderRows: prev?.selectedHeaderRows ?? undefined,
              // validationResults: prev?.validationResults ?? undefined,
            })
          : newState),
      }));
    },
    []
  );

  return (
    <IngestionFlow
      config={{
        templateId: property_dataset.template_id ?? 'None',
        ownerName,
        datasetName: property_dataset.dataset?.dataset_label,
        datasetId: dataset_id.toString(),
        targetSchema:
          (property_dataset.dataset?.unified_ingest_schemas
            .original as unknown as {
            label: string;
            name: string;
            mandatory: boolean;
            dataType: string;
          }[]) ?? [],
      }}
      ingestionConfig={
        property_dataset.property.provider?.ingestion_config as IngestionConfig
      }
      onComplete={onComplete}
      onCancel={pushOrGoBack}
      state={{
        fileId: flowState?.fileId ?? undefined,
        signedUrl: flowState?.signedUrl ?? undefined,
        selectedSheet: flowState?.selectedSheet ?? undefined,
        selectedHeaderRows: flowState?.selectedHeaderRows ?? undefined,
        activeStepKey: (flowState?.activeStepKey ??
          'upload') as IngestionFlowActiveStepKey,
      }}
      setState={setState}
    />
  );
}
