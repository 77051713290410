import {
  Chip,
  ChipProps,
  SvgIconComponent,
  WarningIcon,
  styled,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React from 'react';
import { themeV5 } from 'src/const/themev5';

const statusMap = {
  onTime: {
    label: 'On Time',
    bgColor: themeV5.palette.grey[100],
    textColor: themeV5.palette.grey[700],
  },
  pastDue: {
    label: 'Past Due',
    bgColor: themeV5.accents.error.main,
    textColor: themeV5.palette.common.white,
    icon: WarningIcon,
  },
} as const;

type SubmissionBatchDueDateStatusChipStyledProps = {
  bgColor: string;
  textColor: string;
  icon?: React.ReactElement;
} & ChipProps;

const SubmissionBatchDueDateStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'textColor' !== prop && 'bgColor' !== prop,
})<SubmissionBatchDueDateStatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type SubmissionBatchDueDateStatusChipProps = {
  dueDate: string;
};

export const SubmissionBatchDueDateStatusChip: React.FC<
  SubmissionBatchDueDateStatusChipProps
> = ({ dueDate }) => {
  const status = moment().isAfter(dueDate)
    ? statusMap.pastDue
    : statusMap.onTime;

  const Icon: SvgIconComponent | undefined =
    'icon' in status ? status.icon : undefined;

  return (
    <SubmissionBatchDueDateStatusChipStyled
      label={status.label}
      icon={
        Icon ? (
          <Icon style={{ color: status.textColor, width: 12, height: 12 }} />
        ) : undefined
      }
      bgColor={status.bgColor}
      textColor={status.textColor}
    />
  );
};

SubmissionBatchDueDateStatusChip.displayName = 'SubmissionBatchStatusChip';
