/**
 * @generated SignedSource<<f5c4d2d83a018ab2e128fde36f9ee8e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSourceExistWithLinkPropertiesQuery$variables = {
  entity_id: string;
  property_id?: ReadonlyArray<number> | null;
};
export type getSourceExistWithLinkPropertiesQuery$data = {
  readonly sys_properties_flattened_union: ReadonlyArray<{
    readonly entity_id: string;
    readonly mappings_property_id: number | null;
    readonly property_code: string;
    readonly provider: {
      readonly provider_id: number;
      readonly provider_name: string;
    } | null;
  }>;
};
export type getSourceExistWithLinkPropertiesQuery = {
  response: getSourceExistWithLinkPropertiesQuery$data;
  variables: getSourceExistWithLinkPropertiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entity_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "entity_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "entity_id"
          },
          {
            "kind": "Literal",
            "name": "is_active",
            "value": {
              "_eq": true
            }
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_nin",
                "variableName": "property_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mappings_property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_flattened_union",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entity_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mappings_property_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSourceExistWithLinkPropertiesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSourceExistWithLinkPropertiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ad41122af8c3319ebbed53e00e163bc",
    "id": null,
    "metadata": {},
    "name": "getSourceExistWithLinkPropertiesQuery",
    "operationKind": "query",
    "text": "query getSourceExistWithLinkPropertiesQuery(\n  $entity_id: String!\n  $property_id: [Int!]\n) {\n  sys_properties_flattened_union(where: {entity_id: {_eq: $entity_id}, mappings_property_id: {_nin: $property_id}, is_active: {_eq: true}}) {\n    entity_id\n    mappings_property_id\n    property_code\n    provider {\n      provider_id\n      provider_name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1005b34afa249932e4a14d7746d624ea";

export default node;
