/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Box, Typography } from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import {
  selectedFinancialReviewerUsers,
  selectedOperationalReviewerUsers,
  selectedReviewersUsers,
} from '../../../recoil/dialog';
import WarningBox from './WarningBox';
import { UserRoleSelect } from './UserRoleSelect';
import { useWarningBox } from '../hooks/useWarningBox';
import {
  SelectedUsersType,
  useUserRoleSelect,
} from '../hooks/useUserRoleSelect';
import { assignReviewersDialogOpenState } from 'src/products/data-submission-portal/recoil/dialog';

const SingleChainForm: React.FC = () => {
  const [selectedUsers, setSelectedUsers] = useCherreState(
    selectedReviewersUsers
  );
  const [, setSelectedOperationalUsers] = useCherreState(
    selectedOperationalReviewerUsers
  );

  const [, setSelectedFinancialUsers] = useCherreState(
    selectedFinancialReviewerUsers
  );

  const [isOpen] = useCherreState(assignReviewersDialogOpenState);

  const { warningBoxState } = useWarningBox();

  if (!selectedUsers) {
    return null;
  }

  const setSelectedUsersProxy = useMemo(
    () =>
      (valOrUpdater: SelectedUsersType | ((v: SelectedUsersType) => any)) => {
        setSelectedUsers(valOrUpdater);
        setSelectedOperationalUsers(valOrUpdater);
        setSelectedFinancialUsers(valOrUpdater);
      },
    [setSelectedUsers, setSelectedOperationalUsers, setSelectedFinancialUsers]
  );

  const { userRoleSelectPropsReviewers, showApproverPlusMessage } =
    useUserRoleSelect(selectedUsers, setSelectedUsersProxy, isOpen);

  return (
    <>
      <WarningBox {...warningBoxState} />
      <Box display={'grid'} gap={2}>
        {userRoleSelectPropsReviewers.map((props) => (
          <UserRoleSelect key={props.level} {...props} />
        ))}
        {showApproverPlusMessage && (
          <Typography variant='body2' color='grey.600' align='center'>
            An Approver Plus must be the last reviewer in the chain.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SingleChainForm;
