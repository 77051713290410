import {
  GraphQLReturn,
  graphQLSelector,
  Writeable,
} from '@cherre-frontend/data-fetching';
import {
  getMappingSetValuesSubscription,
  sys_mapping_set_values_bool_exp,
} from './__generated__/getMappingSetValuesSubscription.graphql';
import { getMappingSetValuesTotalSubscription } from './__generated__/getMappingSetValuesTotalSubscription.graphql';
import { graphql } from 'react-relay';
import {
  mappingSetValuesTableFilters,
  mappingDetailsTableState,
  MappingSetValueFilters,
} from '../recoil';
import { MappingDetailRoute } from 'src/products/data-submission-portal/routes';
import { MRT_SortingState } from 'material-react-table';

const hasuraSort = (sort: MRT_SortingState) =>
  sort.map((sort) => {
    if (sort.id.includes('.')) {
      const [table, column] = sort.id.split('.');
      return {
        [table]: {
          [column]: sort.desc ? 'desc' : 'asc',
        },
      };
    }

    return {
      [sort.id]: sort.desc ? 'desc' : 'asc',
    };
  });

const incrementFilters = (filters: MappingSetValueFilters) => {
  const _and: Writeable<sys_mapping_set_values_bool_exp>[] = [];

  const customAttributeKeys = Object.keys(
    filters.custom_attribute_filters
  ).filter((key) => !!filters.custom_attribute_filters[key]);

  if (customAttributeKeys.length > 0) {
    _and.push({
      custom_attributes: {
        _contains: filters.custom_attribute_filters as Writeable<{
          [slug: string]: string;
        }>,
      },
    });
  }

  if (filters.source_code_filter?.label) {
    _and.push({
      source_mapping_code: {
        _ilike: `%${filters.source_code_filter.label}%`,
      },
    });
  }

  if (filters.source_label_filter) {
    _and.push({
      source_mapping_label: {
        _ilike: `%${filters.source_label_filter}%`,
      },
    });
  }

  if (filters.target_code_filter?.label) {
    _and.push({
      target_mapping_code: {
        _ilike: `%${filters.target_code_filter.label}%`,
      },
    });
  }

  if (filters.target_label_filter) {
    _and.push({
      target_mapping_label: {
        _ilike: `%${filters.target_label_filter}%`,
      },
    });
  }

  return _and;
};

export const getMappingSetValuesTotal = graphQLSelector({
  query: graphql`
    subscription getMappingSetValuesTotalSubscription(
      $where: sys_mapping_set_values_bool_exp = {}
    ) {
      total: sys_mapping_set_values_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<getMappingSetValuesTotalSubscription>,
  mapVariables:
    () =>
    ({ get }) => {
      const filters = get(mappingSetValuesTableFilters);

      const where: getMappingSetValuesTotalSubscription['variables']['where'] =
        {
          _and: [
            {
              mapping_set_id: {
                _eq: get(MappingDetailRoute.routeParamSelector).mapping_set_id,
              },
            },
            ...incrementFilters(filters),
          ],
        };

      return { where };
    },
  mapResponse: (resp) => resp.total,
});

export const getMappingSetValues = graphQLSelector({
  query: graphql`
    subscription getMappingSetValuesSubscription(
      $where: sys_mapping_set_values_bool_exp = {}
      $order_by: [sys_mapping_set_values_order_by!] = {}
      $limit: Int = 25
      $offset: Int = 0
    ) {
      sys_mapping_set_values(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        mapping_set_value_id
        source_mapping_code
        source_mapping_label
        target_mapping_code
        target_mapping_label
        custom_attributes
      }
    }
  ` as GraphQLReturn<getMappingSetValuesSubscription>,
  mapResponse: (resp) => {
    return resp.sys_mapping_set_values;
  },
  mapVariables:
    () =>
    ({ get }) => {
      const filters = get(mappingSetValuesTableFilters);
      const tableState = get(mappingDetailsTableState);

      const where: Writeable<
        getMappingSetValuesTotalSubscription['variables']['where']
      > = {
        _and: [
          {
            mapping_set_id: {
              _eq: get(MappingDetailRoute.routeParamSelector).mapping_set_id,
            },
          },
          ...incrementFilters(filters),
        ],
      };

      return {
        where,
        limit: tableState?.pagination.pageSize,
        offset:
          (tableState?.pagination.pageIndex ?? 0) *
          (tableState?.pagination.pageSize ?? 0),
        order_by: hasuraSort(tableState?.sorting ?? []),
      };
    },
});
