import {
  GraphQLReturn,
  graphQLSelector,
  QueryResult,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getProvidersWithUnassignedPropertiesQuery } from './__generated__/getProvidersWithUnassignedPropertiesQuery.graphql';

export const getProviders = graphQLSelector({
  query: graphql`
    query getProvidersWithUnassignedPropertiesQuery {
      sys_properties(
        where: {
          _not: { property_batches: {} }
          properties_roles_users: {
            is_active: { _eq: true }
            property_role_id: { _in: [2, 3, 4] }
          }
          properties_datasets: { is_active: { _eq: true } }
        }
        distinct_on: provider_id
      ) {
        provider {
          provider_name
          provider_id
        }
      }
    }
  ` as GraphQLReturn<getProvidersWithUnassignedPropertiesQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (response) =>
    response.sys_properties
      .map((property) => property.provider)
      .filter(Boolean) ?? [],
});

export type Provider = NonNullable<QueryResult<typeof getProviders>[number]>;
