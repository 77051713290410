import {
  QueryResult,
  serialize,
  useCherreState,
  useCherreValue,
  useMutation,
} from '@cherre-frontend/data-fetching';
import {
  MRT_ColumnDef,
  Table,
  styled,
  useTable,
  ActionsMenu,
  MenuItem,
  Grid,
  useTheme,
  LockIcon,
  EditIcon,
  useConfirmationModal,
  ConfirmationModal,
  MRT_Row,
  Typography,
  Tooltip,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { getProviders, getProvidersTotal } from '../../queries/getProviders';
import { providersTableState } from '../../recoil';
import {
  disableProviderMutation,
  openPropertyBatchesFromProvider,
  providerIdToDisableAtom,
  providerHasSettings,
} from './recoil';
import { SubmissionAccessModes } from '../../../add-edit-provider';
import { NoProviders } from './components/NoProviders';
import { ProviderStatusChip } from './components/ProviderStatusChip';
import { Link, useHistory } from 'react-router-dom';
import { BatchManagementRoute } from 'src/products/data-submission-portal/routes';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { SubmissionSettingsChip } from './components/SubmissionSettingsChip';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export type ProvidersPanelProps = {
  search?: string;
};

export const ProvidersPanel: React.FC<ProvidersPanelProps> = ({ search }) => {
  const theme = useTheme();
  const { push } = useHistory();
  const [tableState, setTableState] = useCherreState(providersTableState);

  const [providerIdToDisable, setProviderIdToDisable] = useCherreState(
    providerIdToDisableAtom
  );

  const user = useCherreValue(getDSPUser());

  const openPropertyBatches = useCherreValue(openPropertyBatchesFromProvider);

  const data = useCherreValue(getProviders());
  const total = useCherreValue(getProvidersTotal());

  const isEditEnabled = useFeatureFlag('DSPAddEditProvider');

  const disableProvider = useMutation(disableProviderMutation, {
    trackEvent: false,
    mapVariables: (providerId: number) => async () => ({
      provider_id: providerId,
    }),
    onCompleted: (result, ctx) => {
      ctx.recoil.reset(getProviders() as any);
      ctx.logger.debug('Provider disabled', result);

      const promises =
        result._sys_disable_provider.user_ids_to_disable_sakura.map((id) => {
          return fetch(
            `/api/v1/organizations/${user?.organization_id}/users/${id}`,
            {
              method: 'DELETE',
            }
          );
        });

      Promise.all(promises)
        .then(() => {
          ctx.logger.debug('Provider users disabled');
          ctx.showSnackbar({
            message: 'Provider was disabled successfully',
            type: 'success',
          });
          setProviderIdToDisable(0);
        })
        .catch((e) => {
          ctx.logger.error('Failed to disable provider users', {
            error: e,
            disabledProvider: result,
          });
          ctx.showSnackbar({
            message: 'Failed to disable provider users',
            type: 'error',
          });
        });
    },
    onError: (e, ctx) => {
      ctx.logger.error('Failed to disable provider', e);
      ctx.showSnackbar({
        message: 'Failed to disable provider',
        type: 'error',
      });
    },
  });

  const { modalProps, confirm } = useConfirmationModal();

  useEffect(() => {
    if (providerIdToDisable && openPropertyBatches) {
      const hasOpenBatches = !!openPropertyBatches?.length;

      const confirmProps = hasOpenBatches
        ? {
            title: 'Close Batches',
            confirmLabel: 'Ok',
            handleConfirm: () => setProviderIdToDisable(0),
            hideCancel: true,
            content: (
              <div>
                <Typography>
                  You must first close the following submission batches before
                  it can be deleted:
                </Typography>
                <ul>
                  {openPropertyBatches.map((batch) => (
                    <Typography
                      sx={{
                        '& a:hover': {
                          color: theme.palette.primary.light,
                        },
                      }}
                      component='li'
                      key={batch.submission_id}
                    >
                      <Link
                        target='_blank'
                        to={`${
                          BatchManagementRoute.routeDescriptor.path
                        }?${serialize({ search: batch.submission_name })}`}
                      >
                        {batch.submission_name}
                      </Link>
                    </Typography>
                  ))}
                </ul>
              </div>
            ),
          }
        : {
            title: 'Disable Provider',
            confirmLabel: 'Disable',
            hideCancel: false,
            handleCancel: () => setProviderIdToDisable(0),
            handleConfirm: () => disableProvider(providerIdToDisable),
            content: (
              <div>
                <Typography>
                  Disabling{' '}
                  <b>
                    {
                      data?.find((p) => p.provider_id === providerIdToDisable)
                        ?.provider_name
                    }
                  </b>{' '}
                  will de-activate all users associated with this provider,
                  remove all mapping assignments, and de-activate all properties
                  they are currently managing.
                </Typography>
              </div>
            ),
          };

      confirm(confirmProps);
    }
  }, [openPropertyBatches]);

  const rowActionOptions = [
    {
      name: 'edit',
      label: <span>Edit Provider</span>,
      icon: <EditIcon sx={{ height: 16 }} />,
      hide: () => !isEditEnabled,
      onClick: (row: MRT_Row<QueryResult<typeof getProviders>[number]>) => {
        push(`/dsp/providers/${row.original.provider_id}/edit`);
      },
    },
    {
      name: 'disable',
      label: <span>Disable</span>,
      icon: <LockIcon sx={{ height: 16 }} />,
      hide: (row: MRT_Row<QueryResult<typeof getProviders>[number]>) =>
        !row.original.is_active,
      onClick: (row: MRT_Row<QueryResult<typeof getProviders>[number]>) => {
        setProviderIdToDisable(row.original.provider_id);
      },
    },
  ];

  const hasProviderSubmissionTestingMode =
    user?.organization?.settings?.provider_submission_testing_mode;
  const providerSubmissionAccessFF = useFeatureFlag('DSPSubmissionTestingMode');

  const hasAccessSettings =
    providerSubmissionAccessFF && hasProviderSubmissionTestingMode;

  const approvalSettingsFF = useFeatureFlag('DSPBulkApproveSettings');
  const providerHasSettingsData = useCherreValue(providerHasSettings());

  const hasSubmissionSettings = approvalSettingsFF && providerHasSettingsData;

  const columns: MRT_ColumnDef<QueryResult<typeof getProviders>[number]>[] =
    useMemo(
      () =>
        [
          {
            accessorKey: 'provider_name',
            header: 'Name',
            size: 150,
            Cell: ({ row, renderedCellValue }) => {
              return (
                <Link to={`/dsp/providers/${row.original.provider_id}`}>
                  {renderedCellValue}
                </Link>
              );
            },
          },
          {
            accessorFn: (row) => row.user_count.aggregate?.count ?? 0,
            id: 'users_aggregate.count',
            header: 'Users',
            size: 0,
            maxSize: 40,
          },
          {
            accessorKey: 'provider_type.name',
            header: 'Provider Type',
            size: 350,
          },
          hasAccessSettings && {
            accessorKey: 'access_settings',
            header: 'Submission Access',
            size: 0,
            Cell: ({ row }) => (
              <Grid display='flex' gap={1}>
                {SubmissionAccessModes.filter((mode) =>
                  (
                    row.original.ingestion_config
                      ?.provider_submission_testing_mode ?? []
                  ).includes(mode.id)
                ).map((mode) => (
                  <Tooltip title={mode.description} key={mode.id}>
                    <SubmissionSettingsChip label={mode.label} />
                  </Tooltip>
                ))}
              </Grid>
            ),
          },
          hasSubmissionSettings && {
            accessorKey: 'ingestion_config',
            header: 'Submission Settings',
            size: 0,
            Cell: ({ row }) =>
              row.original.ingestion_config?.bulk_approve_submit_properties && (
                <Tooltip title='Ability to multi select property submissions as a reviewer and bulk approve or submit them at once. '>
                  <SubmissionSettingsChip label='Bulk Approval' />
                </Tooltip>
              ),
          },
          {
            accessorKey: 'is_active',
            header: 'Status',
            size: 0,
            maxSize: 40,
            Cell: ({ row }) => (
              <ProviderStatusChip active={row.original.is_active} />
            ),
          },
          {
            accessorKey: 'related_data_updated_at',
            header: 'Last Updated',
            size: 0,
            maxSize: 40,
            accessorFn: (row) =>
              moment(row.related_data_updated_at).format('MMM D, YYYY'),
          },
        ].filter(Boolean) as MRT_ColumnDef<
          QueryResult<typeof getProviders>[number]
        >[],
      [hasSubmissionSettings]
    );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowActions: true,
    enableRowSelection: false,
    enableSelectAll: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    renderRowActions: ({ row }) => {
      const hasActions = rowActionOptions.some(
        (option) => !option.hide || !option.hide(row)
      );

      return (
        hasActions && (
          <Grid
            container
            justifyContent={'flex-end'}
            sx={{
              color: theme.palette.grey[500],
              '& .MuiIconButton-root': {
                color: theme.palette.grey[700],
              },
            }}
          >
            <ActionsMenu>
              {rowActionOptions
                .filter((option) => !option.hide || !option.hide(row))
                .map((option) => (
                  <MenuItem
                    onClick={() => option.onClick && option.onClick(row)}
                    key={option.name}
                  >
                    {option.icon} {option.label}
                  </MenuItem>
                ))}
            </ActionsMenu>
          </Grid>
        )
      );
    },
    state: {
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.provider_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  if (data && data.length === 0) {
    return search ? (
      <NoItemsFound title='No Providers Found' />
    ) : (
      <NoProviders />
    );
  }

  return (
    <Container>
      <ConfirmationModal {...modalProps} />
      <Table table={table} />
    </Container>
  );
};

ProvidersPanel.displayName = 'ProvidersPanel';
