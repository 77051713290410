import React, { useMemo } from 'react';

import {
  ActionsMenu,
  Box,
  Button,
  CloudUploadIcon,
  DisabledSmallReplaceIcon,
  IconButton,
  MenuItem,
  ReportFileIcon,
  Table,
  Tooltip,
  useTable,
  SvgIconComponent,
  Panel,
  // eslint-disable-next-line prettier/prettier
  type MRT_ColumnDef,
} from '@cherre-frontend/ui';
import moment from 'moment';

import { DatasetStatus } from '../../__generated__/constants';
import {
  hasFile,
  needsUpload,
  getDatasetValidationStatusCode,
  fileIconMap,
} from './utils';

import ValidationStatusChip, {
  ValidationStatusChipCodes,
  validationStatusConfigMap,
} from '../ValidationStatusChip';
import {
  actionsColumnStyle,
  disableColumnGrow,
  muiTableBodyCellProps,
  muiTableHeadCellProps,
  reportIconButtonStyle,
  reportIconStyle,
  typeColumnStyle,
} from './styles';
import { usePersonaAwarePush } from '../../hooks/usePersonaAwarePush';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { $datasets, Dataset } from './query';
import { useClaims } from '../../packages/dsp-role-based-rendering';
import { useUploadDatasetPush } from '../../hooks/useUploadDatasetPush';
import { useCherreEvent } from '@cherre-frontend/core';

interface DatasetsTableProps {
  property_batch_id: number;
}

const DatasetsTableInternal = ({ property_batch_id }: DatasetsTableProps) => {
  const { isInPreparation, datasets } =
    useCherreValue($datasets(property_batch_id)) ?? {};
  const push = usePersonaAwarePush();
  const isPreparer = useClaims('preparer');
  const { uploadDatasetPush } = useUploadDatasetPush();

  const handleReportClick = useCherreEvent(
    'handleReportClick',
    (dataset: Dataset) => {
      push(`/dsp/{{persona}}/single-file-validations/${dataset.id}`);
    }
  );

  const handleUploadClick = useCherreEvent(
    'handleUploadClick',
    (dataset: Dataset) => {
      uploadDatasetPush(dataset.id, {
        shouldGoBack: true,
      });
    }
  );

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Dataset>[]>(
    () => [
      {
        id: 'type',
        accessorKey: 'name',
        header: 'Type',
        size: 150,
        Cell: ({ row, renderedCellValue }) => {
          const statusCode = getDatasetValidationStatusCode(
            row.original.validation,
            row.original.isValid,
            row.original.warningCount,
            row.original.rowCount
          );
          const color = statusCode
            ? validationStatusConfigMap.get(statusCode)?.bgColor
            : undefined;
          const Icon: SvgIconComponent | undefined = statusCode
            ? fileIconMap.get(statusCode)
            : undefined;

          return (
            <Box style={typeColumnStyle}>
              <span
                style={{
                  color: color,
                  display: 'flex',
                }}
              >
                {Icon ? <Icon /> : ''}
              </span>
              <span>{renderedCellValue}</span>
            </Box>
          );
        },
      },
      {
        accessorKey: 'filename',
        header: 'File Name',
        size: 150,
        Cell: ({ row, renderedCellValue }) => {
          const statusCode = getDatasetValidationStatusCode(
            row.original.validation,
            row.original.isValid,
            row.original.warningCount,
            row.original.rowCount
          );

          if (statusCode === ValidationStatusChipCodes.no_records) {
            return '';
          }

          return renderedCellValue;
        },
      },
      {
        id: 'updatedOn',
        accessorFn(row: Dataset) {
          if (hasFile(row.validation)) {
            return moment(row.updatedOn).format('MM/DD/YYYY');
          }
        },
        header: 'Last Updated',
        size: 150,
        Cell: ({ row, renderedCellValue }) => {
          const statusCode = getDatasetValidationStatusCode(
            row.original.validation,
            row.original.isValid,
            row.original.warningCount,
            row.original.rowCount
          );

          if (statusCode === ValidationStatusChipCodes.no_records) {
            return '';
          }

          return renderedCellValue;
        },
        ...disableColumnGrow,
      },
      {
        header: 'Validation',
        id: 'validation',
        Cell: ({ row }) => {
          const statusCode = getDatasetValidationStatusCode(
            row.original.validation,
            row.original.isValid,
            row.original.warningCount,
            row.original.rowCount
          );
          if (
            !statusCode ||
            statusCode === ValidationStatusChipCodes.pending ||
            statusCode === ValidationStatusChipCodes.no_records
          ) {
            return '';
          }

          return <ValidationStatusChip statusCode={statusCode} />;
        },
        size: 145,
        ...disableColumnGrow,
      },
      {
        id: 'actions',
        header: '',
        size: 80,
        ...disableColumnGrow,
        Cell: ({ row }) => {
          const statusCode = getDatasetValidationStatusCode(
            row.original.validation,
            row.original.isValid,
            row.original.warningCount,
            row.original.rowCount
          );
          const uploadNeeded = statusCode && needsUpload(statusCode);
          return (
            <Box style={actionsColumnStyle}>
              {uploadNeeded && isPreparer ? (
                <Button
                  startIcon={<CloudUploadIcon />}
                  size='small'
                  color='primary'
                  onClick={() => handleUploadClick(row.original)}
                  disabled={!isInPreparation}
                >
                  <span>Upload</span>
                </Button>
              ) : (
                <>
                  <Tooltip title='View Detail'>
                    <IconButton
                      style={reportIconButtonStyle}
                      size='small'
                      color='primary'
                      disabled={[
                        DatasetStatus.VALIDATING.label as string,
                        DatasetStatus.UPLOADING.label as string,
                        DatasetStatus.UPLOADED.label as string,
                        DatasetStatus.PENDING.label as string,
                      ].includes(row.original.validation)}
                      onClick={() => handleReportClick(row.original)}
                    >
                      <ReportFileIcon style={reportIconStyle} />
                    </IconButton>
                  </Tooltip>
                  {isPreparer ? (
                    <ActionsMenu>
                      <MenuItem
                        style={{ width: '200px', gap: '6px' }}
                        onClick={() => handleUploadClick(row.original)}
                        disabled={!isInPreparation}
                      >
                        <DisabledSmallReplaceIcon fontSize='small' />
                        Replace file
                      </MenuItem>
                    </ActionsMenu>
                  ) : null}
                </>
              )}
            </Box>
          );
        },
      },
    ],
    []
  );

  const table = useTable({
    data: datasets,
    columns,
    layoutMode: 'grid',
    manualSorting: false,
    enableRowSelection: false,
    enableSelectAll: false,
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    getRowId: (originalRow) => {
      return originalRow.id.toString();
    },
    enableTableHead: datasets?.some(
      (d) => d.validation !== DatasetStatus.PENDING.label
    ),
    initialState: {
      sorting: [
        {
          id: 'type',
          desc: false,
        },
      ],
    },
  });

  return <Table table={table} />;
};

export const DatasetsTable: React.FC<DatasetsTableProps> = (props) => {
  return (
    <Panel id={`DatasetsTable::${props.property_batch_id}`}>
      <DatasetsTableInternal {...props} />
    </Panel>
  );
};
