import React from 'react';
import { MRT_SortingState } from '@cherre-frontend/ui';
import { getUnassignedPropertiesPerProvider } from './query';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import Table from 'src/products/data-submission-portal/components/PropertiesTable';

export type PropertiesTableProps = {
  sorting: MRT_SortingState;
  onSortingChange: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
  provider_id?: number;
};

export const PropertiesTable: React.FC<PropertiesTableProps> = ({
  sorting,
  onSortingChange,
  provider_id,
}) => {
  const properties = useCherreValue(
    getUnassignedPropertiesPerProvider({ sorting, provider_id })
  );
  return (
    <Table
      data={properties}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  );
};
