import {
  Flatfile,
  IFlatfileImporterConfig,
  IImportSessionConfig,
} from '@flatfile/sdk';
import { useEffect, useRef } from 'react';

export const useFlatfile = (
  flatfile_token: { api: string; mount: string; token: string },
  mountOn: string,
  {
    onData,
    onComplete,
    onError,
    skipReview = false,
  }: {
    onData?: IImportSessionConfig['onData'];
    onComplete?: IImportSessionConfig['onComplete'];
    onError?: IFlatfileImporterConfig['onError'];
    skipReview?: boolean;
  }
) => {
  const sessionRef = useRef<Awaited<
    ReturnType<typeof Flatfile.requestDataFromUser>
  > | null>();

  useEffect(() => {
    if (!mountOn || sessionRef.current) {
      return;
    }

    const startSession = async () => {
      try {
        sessionRef.current = await Flatfile.requestDataFromUser({
          token: () => flatfile_token.token,
          onData: undefined,
          onComplete,
          onError,
          mountOn,
          apiUrl: flatfile_token.api,
          mountUrl: flatfile_token.mount,
          theme: {
            hideConfetti: true,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    console.log('starting flatfile session');
    startSession().then(() => {
      console.log('started flatfile session');

      if (skipReview) {
        setTimeout(() => {
          document
            ?.querySelector<HTMLIFrameElement>(`${mountOn} iframe`)
            ?.contentWindow?.postMessage('cherre_start_sf', '*');
        }, 500);
      }
    });

    return () => {
      sessionRef.current?.close();
    };
  }, [mountOn, onData, onComplete, onError, skipReview]);
};
