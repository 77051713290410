/**
 * @generated SignedSource<<89a9097b84dcf72ed14dd65671c90c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDeleteMappingSetMutation$variables = {
  mapping_set_id: number;
};
export type useDeleteMappingSetMutation$data = {
  readonly update_sys_mapping_sets: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_mapping_sets: {
    readonly affected_rows: number;
  } | null;
};
export type useDeleteMappingSetMutation = {
  response: useDeleteMappingSetMutation$data;
  variables: useDeleteMappingSetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mapping_set_id"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "_set",
    "value": {
      "is_active": false
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "mapping_set_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "mapping_set_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_properties_mapping_sets_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_mapping_sets",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "sys_mapping_sets_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_mapping_sets",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteMappingSetMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteMappingSetMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5f5d4c4a07f8984497f0e4a1716e2a64",
    "id": null,
    "metadata": {},
    "name": "useDeleteMappingSetMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteMappingSetMutation(\n  $mapping_set_id: Int!\n) {\n  update_sys_properties_mapping_sets(_set: {is_active: false}, where: {mapping_set_id: {_eq: $mapping_set_id}}) {\n    affected_rows\n  }\n  update_sys_mapping_sets(_set: {is_active: false}, where: {mapping_set_id: {_eq: $mapping_set_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5ddeadf1890086836ccce9d29ff35f0";

export default node;
