import React from 'react';
import { ReportHeader } from '../ReportModal/ReportHeader';
import { ReportsModal } from 'src/products/data-submission-portal/components/Reports';
import {
  modalReportSlugState,
  reportsState,
  modalStatusFilterState,
  reportSelectors,
} from '../../recoil';
import { shouldShowStatusFilter } from './utils';
import { useCherreValue } from '@cherre-frontend/data-fetching';

const getWhereClause = (
  status: 'Any Validation Status' | 'Passed' | 'Failed' | undefined
) => {
  switch (status) {
    case 'Passed':
      return { is_valid: { _eq: true } };
    case 'Failed':
      return { is_valid: { _eq: false } };
    default:
      return undefined;
  }
};

const ReportModal: React.FC = () => {
  const data = useCherreValue(reportsState);
  const filterStatus = useCherreValue(modalStatusFilterState);
  if (!data) {
    return null;
  }
  const { reports, property_batch_dataset_id } = data;
  return (
    <ReportsModal
      reportSlugState={modalReportSlugState}
      reports={reports || []}
      getArgs={() => ({ property_batch_dataset_id })}
      getWhere={(report_slug) => {
        if (shouldShowStatusFilter(report_slug)) {
          return getWhereClause(filterStatus);
        }
        return undefined;
      }}
      getParams={({ report_view_type }) => ({ report_view_type })}
      selectors={reportSelectors}
    >
      <ReportHeader />
    </ReportsModal>
  );
};

export default ReportModal;
