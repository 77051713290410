import React, { useMemo } from 'react';
import { Table, useTable, styled, useTheme, Panel } from '@cherre-frontend/ui';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import {
  accountGroupingDetailPageTableSearch,
  accountGroupingDetailPageTableState,
  accountGroupingDetailsHasData,
} from '../recoil';
import { getAccountTreeDetails } from '../queries/getAccountTreeDetail';
import capitalize from 'src/utils/capitalize';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

const AccountGroupingDetailTable: React.FC = () => {
  const theme = useTheme();

  const setHasData = useCherreSetState(accountGroupingDetailsHasData);
  const search = useCherreValue(accountGroupingDetailPageTableSearch);

  // control table
  const [tableState, setTableState] = useCherreState(
    accountGroupingDetailPageTableState
  );

  const details = useCherreValue(getAccountTreeDetails());
  const data = details?.data;
  const total = details?.total;

  const resultMode: 'no_items_found' | 'no_details' | 'table' = useMemo(() => {
    if (data && data.length > 0) {
      return 'table';
    } else if (search) {
      return 'no_items_found';
    } else {
      return 'no_details';
    }
  }, [data]);

  setHasData(resultMode !== 'no_details');

  // control suspend
  const isSuspended = useIsSuspended();
  if (isSuspended && !tableState) {
    return null;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'account_tree_id',
        header: 'Account Group ID',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'account_type',
        header: 'Account Group Record Type',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'account_group_code',
        header: 'Account Group Code',
        size: 100,
        sortDescFirst: false,
      },
      {
        accessorKey: 'account_code_description',
        header: 'Account Group Code Description',
        size: 250,
        sortDescFirst: false,
      },
      {
        accessorKey: 'gl_account_code',
        header: 'GL Account Code',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'is_normal_balance',
        header: 'Normal Balance',
        size: 50,
        accessorFn: (row) =>
          row.is_normal_balance !== null
            ? capitalize(row.is_normal_balance.toString())
            : '',
        sortDescFirst: false,
      },
      {
        accessorKey: 'is_contiguous',
        header: 'Contiguous',
        size: 50,
        accessorFn: (row) =>
          row.is_contiguous !== null
            ? capitalize(row.is_contiguous.toString())
            : '',
        sortDescFirst: false,
      },
      {
        accessorKey: 'total_into_code',
        header: 'Total Into Code',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'indent',
        header: 'Indent',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'is_hidden',
        header: 'Hide',
        size: 50,
        accessorFn: (row) =>
          row.is_hidden !== null ? capitalize(row.is_hidden.toString()) : '',
        sortDescFirst: false,
      },
      {
        accessorKey: 'is_negate',
        header: 'Negate',
        size: 50,
        accessorFn: (row) =>
          row.is_negate !== null ? capitalize(row.is_negate.toString()) : '',
        sortDescFirst: false,
      },
      {
        accessorKey: 'nest_level',
        header: 'Nest',
        size: 50,
        sortDescFirst: false,
      },
      {
        accessorKey: 'order_index',
        header: 'Order Index',
        size: 50,
        sortDescFirst: false,
      },
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    state: {
      pagination: tableState?.pagination,
      sorting: tableState?.sorting,
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.chart_of_account_id?.toString(),
    enableMultiSort: true,
    rowCount: total?.aggregate?.count || 0,
  });

  // When has data displays table. Otherwise, when search is empty, displays NoMappings component. Otherwise, displays NoItemsFound component
  return (
    <div style={{ width: '100%' }}>
      {resultMode === 'table' && (
        <div>
          <Container sx={{ width: '100%' }}>
            <Panel id='accountGroupingDetailTable' style={{ width: '100%' }}>
              <Table table={table} />
            </Panel>
          </Container>
        </div>
      )}
      {(resultMode === 'no_items_found' || resultMode === 'no_details') && (
        <NoItemsFound title='No Row Found' />
      )}
    </div>
  );
};

export default AccountGroupingDetailTable;
