import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getProviderUserByIdQuery } from './__generated__/getProviderUserByIdQuery.graphql';
import { ProviderUserEditUserRoute } from 'src/products/data-submission-portal/routes';

export const getProviderUserById = graphQLSelector({
  query: graphql`
    query getProviderUserByIdQuery($user_id: Int) {
      sys_users(
        where: { user_id: { _eq: $user_id } }
        order_by: { users_last_seen: { last_login_at: asc } }
      ) {
        user_id
        sakura_user {
          email
          first_name
          last_name
        }
      }
    }
  ` as GraphQLReturn<getProviderUserByIdQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const user_id = get(ProviderUserEditUserRoute.routeParamSelector).user_id;
      return {
        user_id,
      };
    },
  mapResponse: (resp) => {
    return resp.sys_users[0].sakura_user;
  },
});
