/**
 * @generated SignedSource<<4b5b1c51796df3ed3ce6d957e622c4ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getProvidersWithUnassignedPropertiesQuery$variables = {};
export type getProvidersWithUnassignedPropertiesQuery$data = {
  readonly sys_properties: ReadonlyArray<{
    readonly provider: {
      readonly provider_id: number;
      readonly provider_name: string;
    } | null;
  }>;
};
export type getProvidersWithUnassignedPropertiesQuery = {
  response: getProvidersWithUnassignedPropertiesQuery$data;
  variables: getProvidersWithUnassignedPropertiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_eq": true
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "distinct_on",
        "value": "provider_id"
      },
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "_not": {
            "property_batches": {}
          },
          "properties_datasets": {
            "is_active": (v0/*: any*/)
          },
          "properties_roles_users": {
            "is_active": (v0/*: any*/),
            "property_role_id": {
              "_in": [
                2,
                3,
                4
              ]
            }
          }
        }
      }
    ],
    "concreteType": "sys_properties",
    "kind": "LinkedField",
    "name": "sys_properties",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "provider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "sys_properties(distinct_on:\"provider_id\",where:{\"_not\":{\"property_batches\":{}},\"properties_datasets\":{\"is_active\":{\"_eq\":true}},\"properties_roles_users\":{\"is_active\":{\"_eq\":true},\"property_role_id\":{\"_in\":[2,3,4]}}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getProvidersWithUnassignedPropertiesQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getProvidersWithUnassignedPropertiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9810da4b3b560b272801f218e04c4c2c",
    "id": null,
    "metadata": {},
    "name": "getProvidersWithUnassignedPropertiesQuery",
    "operationKind": "query",
    "text": "query getProvidersWithUnassignedPropertiesQuery {\n  sys_properties(where: {_not: {property_batches: {}}, properties_roles_users: {is_active: {_eq: true}, property_role_id: {_in: [2, 3, 4]}}, properties_datasets: {is_active: {_eq: true}}}, distinct_on: provider_id) {\n    provider {\n      provider_name\n      provider_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b0648c96b6db04abd627cc6b7eb12cd";

export default node;
