/**
 * @generated SignedSource<<8c6d3f48fc2a0497a83a41a4d819d582>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type addProviderUserMutation$variables = {
  organization_id: number;
  provider_id: number;
  user_id: number;
};
export type addProviderUserMutation$data = {
  readonly insert_sys_users: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly created_at_datetime: string;
      readonly is_active: boolean;
      readonly organization_id: number;
      readonly provider_id: number | null;
      readonly updated_at_datetime: string;
      readonly user_id: number;
    }>;
  } | null;
};
export type addProviderUserMutation = {
  response: addProviderUserMutation$data;
  variables: addProviderUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organization_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "organization_id",
            "variableName": "organization_id"
          },
          {
            "kind": "Variable",
            "name": "provider_id",
            "variableName": "provider_id"
          },
          {
            "kind": "Variable",
            "name": "user_id",
            "variableName": "user_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "objects"
      }
    ],
    "concreteType": "sys_users_mutation_response",
    "kind": "LinkedField",
    "name": "insert_sys_users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_users",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at_datetime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at_datetime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addProviderUserMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addProviderUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cce98f024fab5e31536feffccecde5fd",
    "id": null,
    "metadata": {},
    "name": "addProviderUserMutation",
    "operationKind": "mutation",
    "text": "mutation addProviderUserMutation(\n  $organization_id: Int!\n  $provider_id: Int!\n  $user_id: Int!\n) {\n  insert_sys_users(objects: {organization_id: $organization_id, provider_id: $provider_id, user_id: $user_id}) {\n    affected_rows\n    returning {\n      is_active\n      organization_id\n      provider_id\n      user_id\n      created_at_datetime\n      updated_at_datetime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b09129219ee83e09bd5b04eb951d4278";

export default node;
