import { refine } from '@cherre-frontend/data-fetching';

const stringChecker = refine.custom((value) => {
  if (typeof value === 'string' && value !== '') {
    return value;
  } else {
    return null;
  }
});

//modify this so that the check will clear with a modificatio to first OR last name.
export const editProviderFirstNameUserFormChecker = refine.object({
  first_name: stringChecker,
});

export const editProviderLastNameUserFormChecker = refine.object({
  last_name: stringChecker,
});
