/**
 * @generated SignedSource<<2c4ad16aeb346cdd1e96de3f194eb551>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilReassignUserServiceProvidersQuery$variables = {
  id: number;
  search: string;
};
export type recoilReassignUserServiceProvidersQuery$data = {
  readonly sys_organizations: ReadonlyArray<{
    readonly label: string;
    readonly organization_id: number;
    readonly providers: ReadonlyArray<{
      readonly label: string;
      readonly value: number;
    }>;
  }>;
};
export type recoilReassignUserServiceProvidersQuery = {
  response: recoilReassignUserServiceProvidersQuery$data;
  variables: recoilReassignUserServiceProvidersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "organization_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_organizations",
    "kind": "LinkedField",
    "name": "sys_organizations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organization_id",
        "storageKey": null
      },
      {
        "alias": "label",
        "args": null,
        "kind": "ScalarField",
        "name": "organization_label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_ilike",
                    "variableName": "search"
                  }
                ],
                "kind": "ObjectValue",
                "name": "provider_name"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "sys_providers",
        "kind": "LinkedField",
        "name": "providers",
        "plural": true,
        "selections": [
          {
            "alias": "value",
            "args": null,
            "kind": "ScalarField",
            "name": "provider_id",
            "storageKey": null
          },
          {
            "alias": "label",
            "args": null,
            "kind": "ScalarField",
            "name": "provider_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilReassignUserServiceProvidersQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilReassignUserServiceProvidersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "af33d82c06b9c0c4b64c0548d986ce44",
    "id": null,
    "metadata": {},
    "name": "recoilReassignUserServiceProvidersQuery",
    "operationKind": "query",
    "text": "query recoilReassignUserServiceProvidersQuery(\n  $id: Int!\n  $search: String!\n) {\n  sys_organizations(where: {organization_id: {_eq: $id}}) {\n    organization_id\n    label: organization_label\n    providers(where: {provider_name: {_ilike: $search}}) {\n      value: provider_id\n      label: provider_name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bd67c32d1a29f494da7b25781217d67";

export default node;
