import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CloudUploadIcon,
  DocumentStackIcon,
  useTheme,
} from '@cherre-frontend/ui';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { uploadMasterFileDialogOpenState } from '../../recoil';

type Props = {
  searched?: boolean;
};

const NoFilesFound: React.FC<Props> = ({ searched }) => {
  const theme = useTheme();
  const setUploadMasterFileDialogOpen = useCherreSetState(
    uploadMasterFileDialogOpenState
  );

  return (
    <Grid container direction='column' alignItems='center' sx={{ pt: '90px' }}>
      <DocumentStackIcon
        sx={{ height: '36px', color: theme.palette.grey[400] }}
      />
      <Typography variant='h5' sx={{ fontWeight: 600 }}>
        No Master Files
      </Typography>
      <Typography>
        {searched
          ? 'There were no master files found'
          : 'There are no master files uploaded yet.'}
      </Typography>
      <Button
        startIcon={<CloudUploadIcon />}
        variant='contained'
        onClick={() => setUploadMasterFileDialogOpen(true)}
        style={{ marginTop: '16px' }}
      >
        Upload File
      </Button>
    </Grid>
  );
};

export default NoFilesFound;
