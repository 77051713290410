import { string, withDefault } from '@recoiljs/refine';
import { BatchValidationSummaryRoute } from '../../routes';
import {
  ReportTableStateRefine,
  createReportTableStateSelectors,
} from '../../components/Reports';
import {
  DefaultValue,
  GraphQLReturn,
  QueryResult,
  graphQLSelector,
  refine,
  selector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilBatchValidationsInfoQuery } from './__generated__/recoilBatchValidationsInfoQuery.graphql';
import { Report } from '../../components/Reports/types';

export const batchValidationsInfo = graphQLSelector({
  query: graphql`
    query recoilBatchValidationsInfoQuery($property_batch_id: Int!) {
      sys_property_batch_validations(
        where: { property_batch_id: { _eq: $property_batch_id } }

        order_by: {
          properties_batch_validation_rule: {
            batch_validation_rule: { validation_label: asc }
          }
        }
      ) {
        property_batch_validation_id
        property_batch_id
        is_valid
        validation_mode
        properties_batch_validation_rule {
          batch_validation_rule {
            validation_label
            validation_slug
            validation_description
            banner_config
            report {
              report_slug
              report_config
            }
          }
        }
        property_batch {
          property {
            entity_id
          }
        }
      }
    }
  ` as GraphQLReturn<recoilBatchValidationsInfoQuery>,
  mapVariables:
    () =>
    ({ get }) =>
      get(BatchValidationSummaryRoute.routeParamSelector),
  mapResponse: (resp) => resp.sys_property_batch_validations,
});

export const batchValidationsReportsState = selector<{
  reports: Report[];
  property_batch_id: number;
}>({
  key: 'batch-validations/reports',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const validations = get(batchValidationsInfo());
      const reports = validations
        .filter(
          (v) =>
            !!v.properties_batch_validation_rule?.batch_validation_rule.report
        )
        .map((v) => ({
          label:
            v.properties_batch_validation_rule?.batch_validation_rule
              .validation_label ?? '',
          slug:
            v.properties_batch_validation_rule?.batch_validation_rule.report
              .report_slug ?? '',
          disabled: false,
        }));
      return {
        reports,
        property_batch_id: validations[0].property_batch_id,
      };
    },
});

export type BatchValidationInfo = QueryResult<
  typeof batchValidationsInfo
>[number];

export const batchValidationModalSlugState =
  BatchValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'modal',
    refine: withDefault(string(), ''),
  });

export const modalBatchValidationState = selector({
  key: 'batch-validations/modal-validation-state',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedValidationSlug = get(batchValidationModalSlugState);

      if (!selectedValidationSlug) {
        return undefined;
      }
      const validations = get(batchValidationsInfo());

      return validations.find(
        (v) =>
          v.properties_batch_validation_rule?.batch_validation_rule
            .validation_slug === selectedValidationSlug
      );
    },
});

export const batchValidationModalReportSlugState = selector({
  key: 'batch-validations/modal-report-state',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const selectedValidation = get(modalBatchValidationState);
      if (!selectedValidation) {
        return '';
      }
      return (
        selectedValidation.properties_batch_validation_rule
          ?.batch_validation_rule.report?.report_slug || ''
      );
    },
  set:
    () =>
    ({ set, get }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(batchValidationModalSlugState, '');
        return;
      }

      const validations = get(batchValidationsInfo());
      const selectedReport = validations.find(
        (v) =>
          v.properties_batch_validation_rule?.batch_validation_rule.report
            ?.report_slug === newValue
      );
      if (!selectedReport) {
        set(batchValidationModalSlugState, '');
        return;
      }

      set(
        batchValidationModalSlugState,
        selectedReport.properties_batch_validation_rule?.batch_validation_rule
          .validation_slug ?? ''
      );
    },
});

export const batchValidationModalSearchState =
  BatchValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'search',
    refine: withDefault(string(), ''),
  });

export const batchValidationModalStatusFilterState =
  BatchValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'status',
    refine: withDefault(
      refine.stringLiterals(['Any Status', 'Failed', 'Passed'] as const),
      'Any Status'
    ),
  });

export const batchValidationTablesState =
  BatchValidationSummaryRoute.searchParamsSelectorFamily({
    key: 'tables',
    refine: ReportTableStateRefine,
  });

export const batchValidationReportSelectors = createReportTableStateSelectors(
  batchValidationModalReportSlugState,
  batchValidationTablesState,
  batchValidationModalSearchState
);
