import {
  PageContainer,
  PageContent,
  TextField,
  styled,
} from '@cherre-frontend/ui';

type StyledTextFieldProps = {
  state: 'error' | 'success' | undefined;
};

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  fieldset {
    ${({ state, theme }) =>
      state ? `border-color: ${theme.palette[state].main} !important;` : ''}
  }
  .MuiFormHelperText-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 9px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    ${({ state, theme }) =>
      state ? `color: ${theme.palette[state].main} !important;` : ''}
  }
`;

export const StyledPageContainer = styled(PageContainer)`
  width: 620px;
  margin: 0px auto;
  height: unset;
`;

export const StyledPageContent = styled(PageContent)`
  flex: unset;
  webkit-flex: unset;
  padding: 20px;
  border-radius: 20px;
`;
