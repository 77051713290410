import React from 'react';
import { Box, FileIcon, useTheme } from '@cherre-frontend/ui';

interface ReportNameCellProps {
  reviewed: boolean;
  renderedCellValue: React.ReactNode;
}

const typeColumnStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
};

export const ReportNameCell: React.FC<ReportNameCellProps> = ({
  reviewed,
  renderedCellValue,
}) => {
  const theme = useTheme();

  return (
    <Box style={typeColumnStyle}>
      <span
        style={{
          color: reviewed
            ? theme.accents.positive.main
            : theme.accents.warning.main,
          display: 'flex',
        }}
      >
        <FileIcon fontSize='small' />
      </span>
      <span>{renderedCellValue}</span>
    </Box>
  );
};
