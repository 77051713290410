import React from 'react';
import { BuildingIcon, styled, Button } from '@cherre-frontend/ui';
import { useHistory } from 'react-router';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';

const NoProvidersContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const NoProvidersHeader = styled('h3')`
  font-size: 20px;
  margin: 6px 0;
  line-height: normal;
`;

const NoProvidersContent = styled('p')`
  margin: 0;
  padding-bottom: 20px;
`;

export const NoProviders: React.FC = () => {
  const { push } = useHistory();
  return (
    <NoProvidersContainer>
      <BuildingIcon fontSize='large' color='disabled' />
      <NoProvidersHeader>No Providers Yet</NoProvidersHeader>
      <NoProvidersContent>
        There are no providers created yet.
      </NoProvidersContent>
      <FeatureFlagGate flag='DSPAddEditProvider'>
        <Button
          variant='contained'
          color='primary'
          onClick={() => push('/dsp/providers/add')}
        >
          Add Provider
        </Button>
      </FeatureFlagGate>
    </NoProvidersContainer>
  );
};

NoProviders.displayName = 'NoBatches';
