import { useHistory } from 'react-router';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export const useUploadDatasetPush = () => {
  const newIngest = useFeatureFlag('DSPNewIngestion');
  const { push } = useHistory();

  const uploadDatasetPush = (datasetId?: number, state?: unknown) => {
    if (!datasetId) {
      throw new Error('Missing dataset id');
    }

    push(`/dsp/upload-dataset${newIngest ? '-new' : ''}/${datasetId}`, state);
  };

  const uploadDatasetBulkPush = (
    datasetId?: number,
    submissionId?: number,
    state?: unknown
  ) => {
    if (!datasetId || !submissionId) {
      throw new Error('Missing dataset id or submission id');
    }

    push(
      `/dsp/upload-dataset${
        newIngest ? '-new' : ''
      }/${datasetId}/submission/${submissionId}`,
      state
    );
  };

  const uploadTsaDatasetPush = (datasetId?: number, state?: unknown) => {
    if (!datasetId) {
      throw new Error('Missing dataset id');
    }

    push(
      `/dsp/reviewer/upload-dataset${newIngest ? '-new' : ''}/${datasetId}`,
      state
    );
  };

  return {
    uploadDatasetPush,
    uploadDatasetBulkPush,
    uploadTsaDatasetPush,
  };
};
