/**
 * @generated SignedSource<<45ffc2c05ee3f2605be6d463a61dcfc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type queryDatasetsSubscription$variables = {
  property_batch_id: number;
};
export type queryDatasetsSubscription$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly property_batch_datasets: ReadonlyArray<{
      readonly batch_file: {
        readonly file_name: string;
      } | null;
      readonly dataset: {
        readonly dataset_label: string;
      };
      readonly dataset_row_count: number | null;
      readonly dataset_status: {
        readonly dataset_status_description: string;
      };
      readonly is_valid: boolean | null;
      readonly property_batch_dataset_id: number;
      readonly updated_at_datetime: string;
      readonly validated_datetime: string | null;
      readonly warning_count: {
        readonly aggregate: {
          readonly count: number;
        } | null;
      };
    }>;
    readonly property_batch_stage_id: number;
    readonly submission: {
      readonly is_closed: boolean;
    };
  }>;
};
export type queryDatasetsSubscription = {
  response: queryDatasetsSubscription$data;
  variables: queryDatasetsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "property_batch_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_batch_stage_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_submissions",
  "kind": "LinkedField",
  "name": "submission",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_closed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "property_batch_dataset_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated_at_datetime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_valid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataset_row_count",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validated_datetime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_datasets",
  "kind": "LinkedField",
  "name": "dataset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataset_label",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_dataset_statuses",
  "kind": "LinkedField",
  "name": "dataset_status",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataset_status_description",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "sys_batch_files",
  "kind": "LinkedField",
  "name": "batch_file",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "file_name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": "warning_count",
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "is_valid": {
          "_eq": false
        },
        "validation_mode": {
          "_eq": "soft"
        }
      }
    }
  ],
  "concreteType": "sys_property_batch_dataset_validations_aggregate",
  "kind": "LinkedField",
  "name": "property_batch_dataset_validations_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "sys_property_batch_dataset_validations_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "property_batch_dataset_validations_aggregate(where:{\"is_valid\":{\"_eq\":false},\"validation_mode\":{\"_eq\":\"soft\"}})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryDatasetsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batch_datasets",
            "kind": "LinkedField",
            "name": "property_batch_datasets",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v9/*: any*/),
                "action": "THROW",
                "path": "sys_property_batches.property_batch_datasets.dataset"
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryDatasetsSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "sys_property_batches",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_property_batch_datasets",
            "kind": "LinkedField",
            "name": "property_batch_datasets",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b6a5f1114ce49e767ed6d48e9dcc7e01",
    "id": null,
    "metadata": {},
    "name": "queryDatasetsSubscription",
    "operationKind": "subscription",
    "text": "subscription queryDatasetsSubscription(\n  $property_batch_id: Int!\n) {\n  sys_property_batches(where: {property_batch_id: {_eq: $property_batch_id}}) {\n    property_batch_stage_id\n    submission {\n      is_closed\n    }\n    property_batch_datasets {\n      property_batch_dataset_id\n      updated_at_datetime\n      is_valid\n      dataset_row_count\n      validated_datetime\n      dataset {\n        dataset_label\n      }\n      dataset_status {\n        dataset_status_description\n      }\n      batch_file {\n        file_name\n      }\n      warning_count: property_batch_dataset_validations_aggregate(where: {is_valid: {_eq: false}, validation_mode: {_eq: \"soft\"}}) {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6121caf4b63bce5da6dec6edce15da3b";

export default node;
