import { graphql } from 'relay-runtime';
import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { getOrganizationPropertyModelQuery } from './__generated__/getOrganizationPropertyModelQuery.graphql';

export const getOrganizationPropertyModel = graphQLSelector({
  query: graphql`
    query getOrganizationPropertyModelQuery {
      sys_organizations {
        property_model {
          property_model_id
          property_model_slug
        }
      }
    }
  ` as GraphQLReturn<getOrganizationPropertyModelQuery>,
  mapVariables: () => () => ({}),
  mapResponse: (response) => {
    const [organization] = response.sys_organizations;
    const { property_model } = organization || {};
    if (!property_model) {
      throw new Error(`Error while retrieving organization property model`);
    }

    const isManyToOne = property_model.property_model_slug === 'many_to_one';
    return {
      id: property_model.property_model_id,
      slug: property_model.property_model_slug,
      isManyToOne,
    };
  },
  resetCache: false,
});
