import React, { useMemo } from 'react';
import {
  Box,
  Button,
  DownloadFileIcon,
  Grid,
  PageHeader,
  styled,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { getMasterChartOfAccount } from '../queries/getMasterChartOfAccount';
import moment from 'moment';
import { getCOALatestUpdate } from '../queries/getCOADetail';
import { useDownloadCoAFile } from 'src/products/data-submission-portal/hooks/useDownloadMasterCoA';
import { MasterFileDetailRoute } from 'src/products/data-submission-portal/routes';
import { snakeCase } from 'lodash';

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

const MasterFilesDetailHeader: React.FC = () => {
  const theme = useTheme();

  const downloadCoAFile = useDownloadCoAFile();

  const masterCOA = useCherreValue(getMasterChartOfAccount());

  const latestUpdate = useCherreValue(getCOALatestUpdate());

  const lastUpdatedValue = useMemo(() => {
    if (masterCOA) {
      if (latestUpdate) {
        const masterLastUpdated = moment(masterCOA.updated_at_datetime);
        const latestDetailUpdate = moment(latestUpdate);
        return masterLastUpdated.isAfter(latestDetailUpdate)
          ? masterCOA.updated_at_datetime
          : latestUpdate;
      }
      return masterCOA.updated_at_datetime;
    }
  }, [masterCOA, latestUpdate]);

  const masterCoaID = useCherreValue(
    MasterFileDetailRoute.routeParamSelector
  )?.master_file_id;

  return (
    <HeaderGrid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          style={{ height: '48px', alignSelf: 'center' }}
          component={Link}
          to='/dsp/master-files'
        >
          <ArrowBack style={{ fill: theme.palette.grey[900] }} />
        </IconButton>
        <PageHeader
          title={'Master Chart of Accounts'}
          subtitle={
            <span>
              {masterCOA?.chart_of_accounts_name} &bull; Last Update:{' '}
              {lastUpdatedValue
                ? moment(lastUpdatedValue).format('MMM D, YYYY')
                : ''}
            </span>
          }
        />
      </Box>
      <Button
        sx={{ height: '40px' }}
        onClick={() =>
          downloadCoAFile(
            masterCoaID,
            `${snakeCase(
              masterCOA?.organization.organization_label
            )}_Master_Chart_of_Accounts.xlsx`
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <DownloadFileIcon sx={{ width: '15px', height: '15px' }} />
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            Download File
          </Typography>
        </Box>
      </Button>
    </HeaderGrid>
  );
};

export default MasterFilesDetailHeader;
