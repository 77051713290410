import React from 'react';

import {
  CardKPI,
  Grid,
  Panel as BasePanel,
  styled,
  PropertiesIcon,
  UploadFileIcon,
  CertificateIcon,
  FileOkIcon,
  useTheme,
  InfoIcon,
} from '@cherre-frontend/ui';
import CancelIcon from '@material-ui/icons/Cancel';
import { useCherreValue } from '@cherre-frontend/data-fetching';

import { PersonaScope } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { getSubmissionBatchKPIs } from '../PropertyBatches/recoil';

const Panel = styled(BasePanel)`
  height: 100%;
`;

const UploadedDatasets = () => {
  const uploadedDatasets = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_datasets_uploaded_count;
  const datasets = useCherreValue(getSubmissionBatchKPIs())?.kpis.aggregate?.sum
    ?.kpi_datasets_total_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Uploaded Datasets'}
      value={uploadedDatasets ?? ''}
      valueDetail={`of ${datasets}`}
      icon={<UploadFileIcon />}
      valueColor={theme.palette.common.black}
    />
  );
};

const FailedValidations = () => {
  const failedValidations = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_validations_failed_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Failed Validations'}
      value={failedValidations ?? ''}
      icon={<CancelIcon />}
      valueColor={theme.accents.error.main}
    />
  );
};

const ValidationsWarning = () => {
  const validationsWarning = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_validations_warn_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Validations Warning'}
      value={validationsWarning ?? ''}
      icon={<InfoIcon />}
      valueColor={theme.accents.warning.main}
    />
  );
};

const ReportsReviewed = () => {
  const reviewedReports = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_package_reports_reviewed_count;
  const reports = useCherreValue(getSubmissionBatchKPIs())?.kpis.aggregate?.sum
    ?.kpi_package_reports_total_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Reports Reviewed'}
      value={reviewedReports ?? ''}
      valueDetail={`of ${reports}`}
      icon={<FileOkIcon />}
      valueColor={theme.accents.positive.main}
    />
  );
};

const SubmittedProperties = () => {
  const submittedProperties = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_properties_submitted_count;
  const properties = useCherreValue(getSubmissionBatchKPIs())?.kpis.aggregate
    ?.kpi_properties_total_count;

  return (
    <CardKPI
      label={'Submitted Properties'}
      value={submittedProperties ?? ''}
      valueDetail={`of ${properties}`}
      icon={<PropertiesIcon />}
    />
  );
};

const ApprovedProperties = () => {
  const approvedProperties = useCherreValue(getSubmissionBatchKPIs())?.kpis
    .aggregate?.sum?.kpi_properties_approved_count;
  const properties = useCherreValue(getSubmissionBatchKPIs())?.kpis.aggregate
    ?.kpi_properties_total_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Approved Properties'}
      value={approvedProperties ?? ''}
      valueDetail={`of ${properties}`}
      icon={<CertificateIcon />}
      valueColor={theme.accents.positive.main}
    />
  );
};

const ApprovedOrRejectedProperties = () => {
  const approvedOrRejectedProperties = useCherreValue(getSubmissionBatchKPIs())
    ?.kpis.aggregate?.sum?.kpi_properties_approved_or_rejected_count;
  const properties = useCherreValue(getSubmissionBatchKPIs())?.kpis.aggregate
    ?.kpi_properties_total_count;
  const theme = useTheme();

  return (
    <CardKPI
      label={'Approved or Rejected Properties'}
      value={approvedOrRejectedProperties ?? ''}
      valueDetail={`of ${properties}`}
      icon={<PropertiesIcon />}
      valueColor={theme.accents.positive.main}
    />
  );
};

export function SubmissionBatchKPIs() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={2}>
        <Panel id='uploaded-datasets-kpi'>
          <UploadedDatasets />
        </Panel>
      </Grid>
      <PersonaScope persona='preparer'>
        <Grid item xs={12} sm={4} md={2}>
          <Panel id='failed-validations-kpi'>
            <FailedValidations />
          </Panel>
        </Grid>
      </PersonaScope>
      <PersonaScope persona='preparer'>
        <Grid item xs={12} sm={4} md={2}>
          <Panel id='validations-warning-kpi'>
            <ValidationsWarning />
          </Panel>
        </Grid>
      </PersonaScope>
      <Grid item xs={12} sm={4} md={2}>
        <Panel id='reports-reviewed-kpi'>
          <ReportsReviewed />
        </Panel>
      </Grid>
      <PersonaScope persona={'reviewer'}>
        <Grid item xs={12} sm={4} md={2}>
          <Panel id='approved-rejected-properties-kpi'>
            <ApprovedOrRejectedProperties />
          </Panel>
        </Grid>
      </PersonaScope>
      <Grid item xs={12} sm={4} md={2}>
        <Panel id='submitted-properties-kpi'>
          <SubmittedProperties />
        </Panel>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Panel id='approved-properties-kpi'>
          <ApprovedProperties />
        </Panel>
      </Grid>
    </Grid>
  );
}
