import { useCherreEventWithRecoil } from '@cherre-frontend/data-fetching';
import XLSX from 'xlsx';
import { getMappingFile } from './queries/getMappingFile';

export const useDownloadMappingFile = () => {
  return useCherreEventWithRecoil(
    'download mapping file',
    (ctx) =>
      async (
        id: number | undefined,
        provider_name: string,
        target_organization: string,
        mappingType: string,
        fileName: string
      ) => {
        const rawData = await ctx.recoil.getPromise(getMappingFile(id));

        const getMappedData = () => {
          if (rawData.data.length > 0) {
            return rawData.data.map((value) => {
              return {
                source_provider: provider_name,
                source_mapping_code: value.source_mapping_code,
                source_mapping_label: value.source_mapping_label ?? '',
                target_organization: target_organization,
                target_mapping_code: value.target_mapping_code,
                target_mapping_label: value.target_mapping_label ?? '',
              };
            });
          } else {
            return [
              {
                source_provider: '',
                source_mapping_code: '',
                source_mapping_label: '',
                target_organization: '',
                target_mapping_code: '',
                target_mapping_label: '',
              },
            ];
          }
        };

        const mappedData = getMappedData();

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              'Source Provider',
              'Source Code',
              'Source Label',
              'Target Organization',
              'Target Code',
              'Target Label',
            ],
          ],
          { origin: 'A1' }
        );

        const columns = Object.keys(mappedData[0]).map((key) => {
          const maxWidth = mappedData.reduce(
            (prev, current) => Math.max(prev, current[key].length),
            10
          );
          return { wch: Math.max(maxWidth, key.length) };
        });
        worksheet['!cols'] = columns;

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `${mappingType} Mapping`
        );
        XLSX.writeFile(workbook, fileName);
      }
  );
};
