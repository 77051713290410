import React from 'react';

import {
  CheckIcon,
  Chip,
  ChipProps,
  SvgIconComponent,
  CloseIcon,
  styled,
} from '@cherre-frontend/ui';

type BooleanStatusChipStyledProps = {
  active: boolean;
} & ChipProps;

const BooleanStatusChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => 'active' !== prop,
})<BooleanStatusChipStyledProps>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 21px;
  background-color: ${({ active, theme }) =>
    active ? theme.accents.positive.main : theme.palette.error.main};
  padding: 0 7px;

  .MuiChip-label {
    padding-right: 0px;
    padding-left: ${({ icon }) => (icon ? '4' : '0')}px;
    color: white;
  }

  .MuiChip-icon {
    margin: 0;
  }
`;

type BooleanStatusChipProps = {
  value: boolean;
};

export const BooleanStatusChip: React.FC<BooleanStatusChipProps> = ({
  value: active,
}) => {
  const Icon: SvgIconComponent = active ? CheckIcon : CloseIcon;

  return (
    <BooleanStatusChipStyled
      label={active ? 'Yes' : 'No'}
      icon={<Icon style={{ color: 'white', width: 12, height: 12 }} />}
      active={active}
    />
  );
};

BooleanStatusChip.displayName = 'ProviderBooleanStatusChip';
