/**
 * @generated SignedSource<<6e1d450792fe2e042cd3c239e384a09a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilBatchValidationsInfoQuery$variables = {
  property_batch_id: number;
};
export type recoilBatchValidationsInfoQuery$data = {
  readonly sys_property_batch_validations: ReadonlyArray<{
    readonly is_valid: boolean | null;
    readonly properties_batch_validation_rule: {
      readonly batch_validation_rule: {
        readonly banner_config: any;
        readonly report: {
          readonly report_config: any;
          readonly report_slug: string;
        };
        readonly validation_description: string | null;
        readonly validation_label: string;
        readonly validation_slug: string;
      };
    } | null;
    readonly property_batch: {
      readonly property: {
        readonly entity_id: string;
      };
    };
    readonly property_batch_id: number;
    readonly property_batch_validation_id: number;
    readonly validation_mode: string;
  }>;
};
export type recoilBatchValidationsInfoQuery = {
  response: recoilBatchValidationsInfoQuery$data;
  variables: recoilBatchValidationsInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_batch_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "order_by",
        "value": {
          "properties_batch_validation_rule": {
            "batch_validation_rule": {
              "validation_label": "asc"
            }
          }
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batch_validations",
    "kind": "LinkedField",
    "name": "sys_property_batch_validations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_validation_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_batch_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_valid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validation_mode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_batch_validation_rules",
        "kind": "LinkedField",
        "name": "properties_batch_validation_rule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_batch_validation_rules",
            "kind": "LinkedField",
            "name": "batch_validation_rule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validation_label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validation_slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validation_description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "banner_config",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "sys_reports",
                "kind": "LinkedField",
                "name": "report",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "report_slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "report_config",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_property_batches",
        "kind": "LinkedField",
        "name": "property_batch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_properties",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilBatchValidationsInfoQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilBatchValidationsInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "676e0bc3f5580d7d8ef3c876cc584415",
    "id": null,
    "metadata": {},
    "name": "recoilBatchValidationsInfoQuery",
    "operationKind": "query",
    "text": "query recoilBatchValidationsInfoQuery(\n  $property_batch_id: Int!\n) {\n  sys_property_batch_validations(where: {property_batch_id: {_eq: $property_batch_id}}, order_by: {properties_batch_validation_rule: {batch_validation_rule: {validation_label: asc}}}) {\n    property_batch_validation_id\n    property_batch_id\n    is_valid\n    validation_mode\n    properties_batch_validation_rule {\n      batch_validation_rule {\n        validation_label\n        validation_slug\n        validation_description\n        banner_config\n        report {\n          report_slug\n          report_config\n        }\n      }\n    }\n    property_batch {\n      property {\n        entity_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be9e551dc4541db9a4a98fdcdcead9f6";

export default node;
