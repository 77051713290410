import React from 'react';
import { useCherreEvent } from '@cherre-frontend/core';
import { Menu, MenuItem, PersonIcon } from '@cherre-frontend/ui';
import { SelectionButton } from '../PropertiesTabPanel';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import {
  assignPreparersDialogOpenState,
  assignReviewersDialogOpenState,
} from 'src/products/data-submission-portal/recoil/dialog';

const AssignUsersButton: React.FC = () => {
  const [anchorWidth, setAnchorWidth] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMoreClick = useCherreEvent(
    'handleMoreClick',
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setAnchorWidth(event.currentTarget.offsetWidth);
    }
  );

  const setAssignPreparersDialogOpenState = useCherreSetState(
    assignPreparersDialogOpenState
  );

  const setAssignReviewersDialogOpenState = useCherreSetState(
    assignReviewersDialogOpenState
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SelectionButton
        onClick={handleMoreClick}
        variant='outlined'
        color='primary'
        size='medium'
      >
        <PersonIcon sx={{ height: 16, alignSelf: 'center' }} />
        Assign Users
      </SelectionButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: anchorWidth ?? 0 },
        }}
      >
        <MenuItem
          onClick={() => {
            setAssignPreparersDialogOpenState(true);
            setAssignReviewersDialogOpenState(false);
            setAnchorEl(null);
          }}
        >
          Preparers
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAssignReviewersDialogOpenState(true);
            setAssignPreparersDialogOpenState(false);
            setAnchorEl(null);
          }}
        >
          Reviewers
        </MenuItem>
      </Menu>
    </>
  );
};

export default AssignUsersButton;
