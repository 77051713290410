import {
  bool,
  number,
  object,
  string,
  withDefault,
  writableArray,
} from '@recoiljs/refine';
import { ProviderDetailsRoute } from '../../../../../routes';
import { refine } from '@cherre-frontend/data-fetching';
import { atom } from 'recoil';

export const mappingSetsTableSearch =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'mapping-search',
    refine: withDefault(string(), ''),
  });

export const mappingSetsTableState =
  ProviderDetailsRoute.searchParamsSelectorFamily({
    key: 'mapping-sets-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'mapping_set_description', desc: false }],
      }
    ),
  });

export const mappingSetsTableHasData = atom({
  key: 'mapping-sets-table-has-data',
  default: false,
});
