import React from 'react';
import {
  Typography,
  styled,
  Grid,
  IconButton,
  CloseIcon,
} from '@cherre-frontend/ui';

export type AssignProviderDialogHeaderProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  onClose: () => void;
};

const Title = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  /* Headings/H4 */
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Subtitle = styled(Typography)`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AssignProviderDialogHeader: React.FC<
  AssignProviderDialogHeaderProps
> = (props) => {
  return (
    <Grid container>
      <Grid item xs>
        <Title variant='h5'>{props.title}</Title>
        <Subtitle variant='subtitle1'>{props.subtitle}</Subtitle>
      </Grid>
      <Grid item xs='auto'>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

AssignProviderDialogHeader.displayName = 'AssignProviderDialogHeader';
