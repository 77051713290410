/**
 * @generated SignedSource<<5ab606ec922696e1b0096b8ca481503b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getAllPropertiesUsedValuesQuery$variables = {};
export type getAllPropertiesUsedValuesQuery$data = {
  readonly address_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly fund_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly property_name_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
  readonly type_is_used: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type getAllPropertiesUsedValuesQuery = {
  response: getAllPropertiesUsedValuesQuery$data;
  variables: getAllPropertiesUsedValuesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_is_null": false
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_properties_flattened_union_aggregate_fields",
    "kind": "LinkedField",
    "name": "aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": "fund_is_used",
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "fund": (v0/*: any*/)
        }
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": "sys_properties_flattened_union_aggregate(where:{\"fund\":{\"_is_null\":false}})"
  },
  {
    "alias": "property_name_is_used",
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "property_name": (v0/*: any*/)
        }
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": "sys_properties_flattened_union_aggregate(where:{\"property_name\":{\"_is_null\":false}})"
  },
  {
    "alias": "address_is_used",
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "address": (v0/*: any*/)
        }
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": "sys_properties_flattened_union_aggregate(where:{\"address\":{\"_is_null\":false}})"
  },
  {
    "alias": "type_is_used",
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "type": (v0/*: any*/)
        }
      }
    ],
    "concreteType": "sys_properties_flattened_union_aggregate",
    "kind": "LinkedField",
    "name": "sys_properties_flattened_union_aggregate",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": "sys_properties_flattened_union_aggregate(where:{\"type\":{\"_is_null\":false}})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getAllPropertiesUsedValuesQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getAllPropertiesUsedValuesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8bd84dd36b43dac46bf847fc4428ee83",
    "id": null,
    "metadata": {},
    "name": "getAllPropertiesUsedValuesQuery",
    "operationKind": "query",
    "text": "query getAllPropertiesUsedValuesQuery {\n  fund_is_used: sys_properties_flattened_union_aggregate(where: {fund: {_is_null: false}}) {\n    aggregate {\n      count\n    }\n  }\n  property_name_is_used: sys_properties_flattened_union_aggregate(where: {property_name: {_is_null: false}}) {\n    aggregate {\n      count\n    }\n  }\n  address_is_used: sys_properties_flattened_union_aggregate(where: {address: {_is_null: false}}) {\n    aggregate {\n      count\n    }\n  }\n  type_is_used: sys_properties_flattened_union_aggregate(where: {type: {_is_null: false}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e013f16295ac7373e491f13a43b3924";

export default node;
