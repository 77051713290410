import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { getClickCardDataDatasetsByIdsQuery } from './__generated__/getClickCardDataDatasetsByIdsQuery.graphql';
import { getClickCardDataMappingSetsByIdsQuery } from './__generated__/getClickCardDataMappingSetsByIdsQuery.graphql';
import { getClickCardDataSourceMappingSetsByIdsQuery } from './__generated__/getClickCardDataSourceMappingSetsByIdsQuery.graphql';

export const getDatasetsByIds = graphQLSelector({
  query: graphql`
    query getClickCardDataDatasetsByIdsQuery($dataset_ids: [Int!]) {
      sys_datasets(where: { dataset_id: { _in: $dataset_ids } }) {
        dataset_description
      }
    }
  ` as GraphQLReturn<getClickCardDataDatasetsByIdsQuery>,
  mapResponse: (resp) => {
    return resp.sys_datasets.map((obj) => obj.dataset_description);
  },
});

export const getClickCardDataMappingsByIds = graphQLSelector({
  query: graphql`
    query getClickCardDataMappingSetsByIdsQuery($mapping_ids: [Int!]) {
      sys_mapping_sets(where: { mapping_set_id: { _in: $mapping_ids } }) {
        mapping_set_description
      }
    }
  ` as GraphQLReturn<getClickCardDataMappingSetsByIdsQuery>,
  mapResponse: (resp) => {
    return resp.sys_mapping_sets.map((obj) => obj.mapping_set_description);
  },
});

export const getClickCardSourceDataMappingsByIds = graphQLSelector({
  query: graphql`
    query getClickCardDataSourceMappingSetsByIdsQuery(
      $mapping: String!
      $property_id: Int!
    ) {
      sys_properties_datasets(
        where: {
          property_id: { _eq: $property_id }
          template_id: { _gt: "0" }
          is_active: { _eq: true }
          dataset: { dataset_description: { _eq: $mapping } }
        }
      ) {
        template_id
        dataset {
          dataset_description
        }
      }
    }
  ` as GraphQLReturn<getClickCardDataSourceMappingSetsByIdsQuery>,
  mapVariables:
    ({ mapping, property_id }: { mapping: string; property_id: number }) =>
    () => ({
      mapping,
      property_id,
    }),
  mapResponse: (resp) => {
    return resp.sys_properties_datasets
      .map((obj) => obj.template_id)
      .filter(Boolean);
  },
});
