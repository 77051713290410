import {
  DefaultValue,
  GraphQLReturn,
  RecoilState,
  atom,
  graphQLSelector,
  refine,
  selector,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'relay-runtime';
import {
  ReportTableStateRefine,
  createReportTableStateSelectors,
} from '../../components/Reports';
import { SubmissionRoute } from '../../routes';
import { recoilGetUnresolvedRemindersSubscription } from './__generated__/recoilGetUnresolvedRemindersSubscription.graphql';
import { recoilSubmissionSubscription } from './__generated__/recoilSubmissionSubscription.graphql';

export const lastUploadedPropertyBatchDatasetAtom = atom<{
  property_batch_id: number;
  dataset_id: number;
} | null>({
  key: 'submission-batch-details/last-uploaded-propertybatch-dataset',
  default: null,
});

export const packageReportModalState =
  SubmissionRoute.searchParamsSelectorFamily({
    key: 'submission-batch-details/package-report-modal-state',
    refine: refine.withDefault(
      refine.nullable(
        refine.object({
          property_batch_id: refine.number(),
          report_slug: refine.string(),
          property_id: refine.number(),
        })
      ),
      null
    ),
  }) as RecoilState<{
    property_batch_id: number;
    report_slug: string;
    property_id?: number;
  } | null>;

export const reportSlugModalState = selector({
  key: 'submission-batch-details/report-slug-modal-state',
  get: ({ get }) => {
    const selected = get(packageReportModalState);
    if (!selected) {
      return '';
    }
    return selected.report_slug;
  },
  set: ({ set }, newValue) => {
    if (!newValue || newValue instanceof DefaultValue) {
      set(packageReportModalState, null);
    } else {
      set(packageReportModalState, (old) => {
        if (!old) {
          return old;
        }
        return { ...old, report_slug: newValue };
      });
    }
  },
});

export const modalSearchState = SubmissionRoute.searchParamsSelectorFamily({
  key: 'submission-batch-details/modal-search-state',
  refine: refine.withDefault(refine.string(), ''),
});

export const modalTablesState = SubmissionRoute.searchParamsSelectorFamily({
  key: 'submission-batch-details/modal-tables-state',
  refine: ReportTableStateRefine,
});

export const reportSelectors = createReportTableStateSelectors(
  reportSlugModalState,
  modalTablesState,
  modalSearchState
);

export const getSubmission = graphQLSelector({
  mapVariables:
    () =>
    ({ get }) => {
      return get(SubmissionRoute.routeParamSelector);
    },
  mapResponse: (response) => response.sys_submissions?.[0],
  query: graphql`
    subscription recoilSubmissionSubscription($submission_id: Int) {
      sys_submissions(where: { submission_id: { _eq: $submission_id } }) {
        submission_id
        submission_name
        reporting_period_end_date
        due_date
        submission_type {
          submission_type_id
          submission_type_label
          datasets {
            dataset_id
            dataset_label
          }
        }
        property_batches {
          property_batch_id
          property_batch_datasets {
            dataset_id
            is_valid
          }
          property {
            property_id
            entity_id
            properties_datasets {
              dataset {
                dataset_id
                dataset_label
              }
            }
            reminders_aggregate(where: { is_resolved: { _eq: false } }) {
              aggregate {
                count
              }
            }
          }
        }
        property_batches_aggregate(
          where: {
            property: {
              property_type: { property_type_slug: { _neq: "investment" } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<recoilSubmissionSubscription>,
});

export const getUnresolvedReminders = graphQLSelector({
  mapVariables:
    () =>
    ({ get }) => {
      const submission = get(getSubmission());
      return {
        submission_type_id: submission?.submission_type?.submission_type_id,
        entity_ids: submission?.property_batches.map(
          (batch) => batch.property.entity_id
        ),
      };
    },
  mapResponse: (response) => response.sys_reminders,
  query: graphql`
    subscription recoilGetUnresolvedRemindersSubscription(
      $submission_type_id: Int!
      $entity_ids: [String!]
    ) {
      sys_reminders(
        where: {
          submission: {
            submission_type: {
              submission_type_id: { _eq: $submission_type_id }
            }
          }
          property: { entity_id: { _in: $entity_ids } }
          is_resolved: { _eq: false }
        }
      ) {
        property_batch_id
        reminder_id
        text
        submission {
          submission_id
          submission_type {
            submission_type_label
            submission_type_id
          }
        }
        property {
          entity_id
        }
        package_report {
          package_reports_datasets {
            dataset_id
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetUnresolvedRemindersSubscription>,
});
