import { useAppContext, useCherreEvent } from '@cherre-frontend/core';
import {
  constSelector,
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { Button, PageContainer, PageHeader, styled } from '@cherre-frontend/ui';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useAsyncCreateIngestFile } from '../../hooks/useCreateIngestFile';
import { useFlatfile } from '../../hooks/useFlatfile';
import { getFlatfileToken } from '../../recoil/getFlatfileToken';
import { getPropertyBatchDataset } from './queries/getPropertyBatchDataset';
import { lastUploadedPropertyBatchDatasetAtom } from '../submission-batch-details/recoil';

const FlatfileContainer = styled('div')`
  width: 100%;
  display: flex;
  flex: 1;
  border: 0;
  & iframe {
    width: 100%;
    flex: 1;
    border: 0;
  }
`;

const SubmissionsFileUploadPage: React.FC = () => {
  const { push, goBack, location } = useHistory<
    { shouldGoBack?: boolean } | undefined
  >();

  const property_batch_dataset = useCherreValue(getPropertyBatchDataset());
  const setLastUploadedPropertyBatchDataset = useCherreSetState(
    lastUploadedPropertyBatchDatasetAtom
  );

  const flatfile_token = useCherreValue(
    property_batch_dataset?.dataset_id
      ? getFlatfileToken({
          dataset_id: property_batch_dataset?.dataset_id,
        })
      : constSelector(null)
  );

  if (!flatfile_token || !property_batch_dataset) {
    goBack();
    return null;
  }
  const pushOrGoBack = () => {
    if (location.state?.shouldGoBack) {
      goBack();
    } else {
      push(
        `/dsp/preparer/submissions/${property_batch_dataset.property_batch.submission_id}`
      );
    }
  };

  const createIngestFile = useAsyncCreateIngestFile();
  const context = useAppContext();

  const ref = useRef(false);

  const onComplete = useCherreEvent(
    'onCompleteCreateIngestFile',
    async (data: { batchId: string }) => {
      // avoid duplicate calls
      if (ref.current === true) {
        return;
      }
      ref.current = true;

      if (!property_batch_dataset.dataset_id) {
        throw new Error('Dataset ID is required');
      }

      const ingestFileResult = await createIngestFile({
        params: {
          dataset_id: property_batch_dataset.dataset_id,
          file_reference_source: data.batchId,
          property_batch_ids: [property_batch_dataset.property_batch_id],
          file_source: 'flatfile',
        },
      });

      if (ingestFileResult?.errors || !ingestFileResult?.output?.data) {
        context.showSnackbar({
          message: `${property_batch_dataset.dataset?.dataset_label} import failed! Please try again.`,
          type: 'error',
        });
      } else if (
        (ingestFileResult?.output?.data?.property_batches?.not_found?.length ??
          0) > 0
      ) {
        // If the property batch is not found, show missing entity dialog
        setLastUploadedPropertyBatchDataset({
          property_batch_id: property_batch_dataset.property_batch_id,
          dataset_id: property_batch_dataset.dataset_id ?? 0,
        });
      } else {
        context.showSnackbar({
          message: `${property_batch_dataset.dataset?.dataset_label} import successful!`,
          type: 'success',
        });
      }
      pushOrGoBack();
    }
  );

  useFlatfile(flatfile_token, '#flatfile', { onComplete, skipReview: true });

  if (!property_batch_dataset || !property_batch_dataset.dataset) {
    return null;
  }

  const cancelUpload = () => {
    pushOrGoBack();
  };

  return (
    <PageContainer id='UploadDatasetPage'>
      <PageHeader
        title={`File Uploader: ${property_batch_dataset.dataset.dataset_label}`}
      >
        <Button color='primary' variant='outlined' onClick={cancelUpload}>
          Cancel Upload
        </Button>
      </PageHeader>
      <FlatfileContainer
        id='flatfile'
        key={property_batch_dataset.property_batch_dataset_id}
      />
    </PageContainer>
  );
};

export default SubmissionsFileUploadPage;
