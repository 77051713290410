/**
 * @generated SignedSource<<c674a0ac15090ed701f53208d3781acb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ingestion_params = {
  dataset_id: number;
  file_reference_source: string;
  file_source: string;
  property_batch_ids: ReadonlyArray<number>;
};
export type useCreateIngestFileAsyncMutation$variables = {
  params: ingestion_params;
};
export type useCreateIngestFileAsyncMutation$data = {
  readonly _sys_ingestion_ingest_async: string;
};
export type useCreateIngestFileAsyncMutation = {
  response: useCreateIngestFileAsyncMutation$data;
  variables: useCreateIngestFileAsyncMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "kind": "ScalarField",
    "name": "_sys_ingestion_ingest_async",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateIngestFileAsyncMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateIngestFileAsyncMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb40f03cf2278acf7c9305f5900d9377",
    "id": null,
    "metadata": {},
    "name": "useCreateIngestFileAsyncMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateIngestFileAsyncMutation(\n  $params: ingestion_params!\n) {\n  _sys_ingestion_ingest_async(params: $params)\n}\n"
  }
};
})();

(node as any).hash = "c9e060416478bd6ae8e77b98969707a5";

export default node;
