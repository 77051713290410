import { styled, Container, Toolbar, SearchInput } from '@cherre-frontend/ui';

export const HeaderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  padding: 0 !important;
`;

export const StyledToolbar = styled(Toolbar)`
  margin-top: -20px;
  display: flex;
  gap: 10px;
  padding-left: 0 !important;
`;

export const StyledSearchInput = styled(SearchInput)`
  border-radius: 20px;
  background-color: #ffffff;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 0 !important;
  padding: 0px !important;
  max-width: 100% !important;
  margin-left: 0;
  margin-right: 0;
`;
