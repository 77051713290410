import React, { useEffect, useMemo } from 'react';
import {
  useCherreState,
  useCherreValue,
  useCherreStateDebounced,
  useCherreSetState,
} from '@cherre-frontend/data-fetching';
import {
  Table,
  styled,
  tableBaseStyle,
  useTable,
  useTheme,
  Box,
  Typography,
  SearchInput,
  FileOkIcon,
  ConfirmationModal,
  useConfirmationModal,
  Button,
  DownloadFileIcon,
  Tooltip,
} from '@cherre-frontend/ui';
import {
  isTableEmpty,
  masterFilesTableSearch,
  masterFilesTableState,
  MasterFileTypes,
} from '../../recoil';
import moment from 'moment';
import { getMasterChartOfAccountsList } from '../../queries/getMasterChartOfAccountsList.ts';
import NoFilesFound from './NoFilesFound';
import DeleteMasterFileButton from './DeleteMasterFileButton';
import { Link } from 'react-router-dom';
import { useDownloadCoAFile } from 'src/products/data-submission-portal/hooks/useDownloadMasterCoA';
import { snakeCase } from 'lodash';
import { getAccountTreesList } from '../../queries/getAccountTreesList';
import { useDownloadAccountTreeFile } from 'src/products/data-submission-portal/hooks/useDownloadAccountTree';
import DeleteAccountGroupingButton from './DeleteAccountGroupingButton';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
  width: '100%';
  max-height: 80%;
`;

type MasterFile = {
  id: number;
  name: string;
  fileType: MasterFileTypes;
  updatedAt: string;
  organizationName: string;
  link: string;
};

const MasterFilesTable: React.FC = () => {
  const theme = useTheme();
  const [tableState, setTableState] = useCherreState(masterFilesTableState);
  const [search, , setSearch] = useCherreStateDebounced(masterFilesTableSearch);
  const { modalProps, confirm } = useConfirmationModal();
  const setIsTableEmpty = useCherreSetState(isTableEmpty);
  const downloadCoAFile = useDownloadCoAFile();
  const downloadAccountTreeFile = useDownloadAccountTreeFile();

  const masterCoaData = useCherreValue(getMasterChartOfAccountsList());
  const accountTreeData = useCherreValue(getAccountTreesList());

  const mappedData = useMemo(() => {
    //We can add more file types here as needed
    let data: MasterFile[] = [];
    if (masterCoaData && accountTreeData) {
      data = [
        ...data,
        ...masterCoaData.map((coa) => ({
          id: coa.master_chart_of_account_id,
          name: coa.chart_of_accounts_name,
          fileType: MasterFileTypes.MASTER_COA,
          updatedAt: coa.updated_at_datetime,
          organizationName: coa.organization.organization_label,
          link: `/dsp/master-files/${coa.master_chart_of_account_id}`,
        })),
        ...accountTreeData.map((accountTree) => ({
          id: accountTree.organization_account_tree_id,
          name: accountTree.account_tree_name,
          fileType: MasterFileTypes.ACCOUNT_GROUPINGS,
          updatedAt: accountTree.updated_at_datetime,
          organizationName: accountTree.organization?.organization_label ?? '',
          link: `/dsp/master-files/account-grouping/${accountTree.organization_account_tree_id}`,
        })),
      ];
    }
    return data;
  }, [masterCoaData, accountTreeData]);

  useEffect(() => {
    if (mappedData) {
      if (mappedData.length === 0) {
        setIsTableEmpty(true);
      }
      if (mappedData.length > 0) {
        setIsTableEmpty(false);
      }
    }
  }, [mappedData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 300,
        Cell: ({ row }) => {
          return (
            <Link to={row.original.link}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <FileOkIcon sx={{ color: theme.palette.success.dark }} />
                <Typography sx={{ color: theme.palette.primary.main }}>
                  {row.original.name}
                </Typography>
              </Box>
            </Link>
          );
        },
      },
      {
        accessorKey: 'fileType',
        header: 'File Type',
        size: 170,
      },
      {
        accessorKey: 'updatedAt',
        header: 'Last Updated',
        size: 170,
        Cell: ({ row }) => {
          return moment(row.original.updatedAt).format('MMM DD YYYY');
        },
      },
    ],
    []
  );

  const table = useTable({
    data: mappedData,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: true,
    enableRowSelection: false,
    enableBottomToolbar: false,
    enablePagination: false,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
        <Button style={{ minWidth: 'unset', padding: '6px 10px' }}>
          <Tooltip title='Download this file as an Excel spreadsheet.'>
            <DownloadFileIcon
              sx={{ height: '20px', width: '20px', display: 'flex' }}
              onClick={() =>
                row.original.fileType === MasterFileTypes.MASTER_COA
                  ? downloadCoAFile(
                      row.original.id,
                      `${snakeCase(
                        row.original.organizationName
                      )}_Master_Chart_of_Accounts.xlsx`
                    )
                  : downloadAccountTreeFile(
                      row.original.id,
                      `${snakeCase(
                        row.original.organizationName
                      )}_Account_Grouping.xlsx`
                    )
              }
            />
          </Tooltip>
        </Button>
        {row.original.fileType === MasterFileTypes.MASTER_COA ? (
          <DeleteMasterFileButton
            masterFileID={Number(row.original.id)}
            confirmFunction={confirm}
          />
        ) : (
          <DeleteAccountGroupingButton
            accountGroupingID={Number(row.original.id)}
            confirmFunction={confirm}
          />
        )}
      </Box>
    ),
    state: {
      sorting: tableState?.sorting || [],
    },
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.id?.toString(),
    enableMultiSort: false,
    enableStickyHeader: true,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
        },
      },
    },
  });

  if (mappedData && mappedData.length === 0 && search === '') {
    return <NoFilesFound />;
  }
  if (mappedData && mappedData.length === 0 && search) {
    return (
      <>
        <SearchInput
          value={search ?? ''}
          sx={{ width: '200px' }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <NoFilesFound searched />
      </>
    );
  }

  return (
    <>
      <SearchInput
        value={search ?? ''}
        sx={{ width: '200px' }}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Container>
        <Table table={table} />
      </Container>

      <ConfirmationModal {...modalProps} />
    </>
  );
};

export default MasterFilesTable;
