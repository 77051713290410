import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { MappingDetailRoute } from 'src/products/data-submission-portal/routes';
import { getSourceCodeDropdownSubscription } from './__generated__/getSourceCodeDropdownSubscription.graphql';

export const getSourceCodeDropdown = graphQLSelector({
  query: graphql`
    subscription getSourceCodeDropdownSubscription($id: Int!) {
      sys_mapping_set_values(
        distinct_on: source_mapping_code
        where: { mapping_set_id: { _eq: $id } }
      ) {
        label: source_mapping_code
        value: source_mapping_label
      }
    }
  ` as GraphQLReturn<getSourceCodeDropdownSubscription>,
  mapResponse: (resp) => {
    return resp.sys_mapping_set_values;
  },
  mapVariables:
    () =>
    ({ get }) => {
      return {
        id: get(MappingDetailRoute.routeParamSelector).mapping_set_id,
      };
    },
});
