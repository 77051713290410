import { useCherrePartialState } from '@cherre-frontend/data-fetching';
import React from 'react';
import { addEditMappingFormState } from '../recoil';
import { DialogTextField } from '../styles';

const AddEditSourceLabelInput: React.FC = () => {
  const [mappingForm, setMappingForm] = useCherrePartialState(
    addEditMappingFormState
  );
  return (
    <DialogTextField
      variant='outlined'
      size='small'
      label='Source Label'
      value={mappingForm?.source_description}
      onChange={(e) => {
        setMappingForm({ source_description: e.target.value });
      }}
    />
  );
};

export default AddEditSourceLabelInput;
