/**
 * @generated SignedSource<<ea683e213c5d01574e8797ce92ac84d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SysUploadMappingInput = {
  file_reference_source: string;
  file_source: string;
  mapping_field_id: number;
  mapping_set_description: string;
  master_chart_of_account_id: number;
  provider_id: number;
};
export type useUploadMappingNewMutation$variables = {
  input: SysUploadMappingInput;
};
export type useUploadMappingNewMutation$data = {
  readonly _sys_upload_mapping: {
    readonly mapping_set_id: number;
  } | null;
};
export type useUploadMappingNewMutation = {
  response: useUploadMappingNewMutation$data;
  variables: useUploadMappingNewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SysUploadMappingOutput",
    "kind": "LinkedField",
    "name": "_sys_upload_mapping",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapping_set_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadMappingNewMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadMappingNewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9bf85355d0d900efe51a03d32f975c1",
    "id": null,
    "metadata": {},
    "name": "useUploadMappingNewMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadMappingNewMutation(\n  $input: SysUploadMappingInput!\n) {\n  _sys_upload_mapping(input: $input) {\n    mapping_set_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d013bc18b074e74b9415a61e117a2d2";

export default node;
