import React, { useMemo } from 'react';
import { TabsConfig } from '../PropertyBatchAccordion.base/types';
import { PropertyBatch } from '../types';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  ConfirmationModalConfirm,
  TabsProps,
  Tooltip,
  Button,
} from '@cherre-frontend/ui';
import { hasFile } from 'src/products/data-submission-portal/components/DatasetsTable/utils';
import { isInPreparation } from '../utils';
import { TsaDatasetsTable } from '../../../../../components/TsaDatasetsTable';
import { PackageReportsTable } from '../../../../../components/PackageReportsTable';
import {
  modalSearchState,
  packageReportModalState,
  reportSelectors,
  reportSlugModalState,
} from '../../../recoil';
import {
  getActionFromStatus,
  getStatusFromAction,
  SubmitStatus,
} from './utils';
import { useWorkflowApplyActionToBatch } from '../hooks/useWorkflowApplyActionToBatch';
import { getWorkflowAvailableActions } from '../recoil';
import { useIsApproverPlus } from '../../../../../packages/dsp-role-based-rendering';
import { DatasetsTable } from '../../../../../components/DatasetsTable';
import { PropertyBatchStage } from 'src/products/data-submission-portal/__generated__/constants';

export const useTabsConfigReviewer = (
  propertyBatch: Pick<
    PropertyBatch,
    | 'property_batch_datasets'
    | 'property_batch_id'
    | 'property_batch_stage'
    | 'properties_flattened_union'
    | 'is_in_last_review_stage'
  >,
  confirmModal: ConfirmationModalConfirm,
  hideButtonIfDisabled?: boolean,
  hasTSA?: boolean,
  investmentPropertyBatchId?: number
): TabsConfig => {
  const [tabValue, setTabValue] = React.useState<TabsProps['value']>(0);

  const isApproved =
    propertyBatch.property_batch_stage.property_batch_stage_id ===
    PropertyBatchStage.APPROVED.id;

  const tabOnChange = (
    event: React.SyntheticEvent,
    newValue: TabsProps['value']
  ) => {
    setTabValue(newValue);
  };

  const disablePackageReports = propertyBatch.property_batch_datasets.some(
    (d) => !hasFile(d.dataset_status.dataset_status_description) || !d.is_valid
  );

  const workflowAvailableActions = useCherreValue(
    getWorkflowAvailableActions(propertyBatch.property_batch_id)
  );

  const status = getStatusFromAction(workflowAvailableActions as string[]);
  const disabled = status === ''; // disabled if no action available

  const submitProperty = useWorkflowApplyActionToBatch(
    propertyBatch.property_batch_id,
    getActionFromStatus(status)
  );

  const statusLabel = useMemo(() => {
    if (disabled) {
      return propertyBatch.is_in_last_review_stage ? 'Approve' : 'Submit';
    }

    return status === SubmitStatus.APPROVE
      ? 'Approve'
      : status === SubmitStatus.REJECT
      ? 'Reject'
      : 'Submit';
  }, [disabled, status, propertyBatch.is_in_last_review_stage]);

  const tooltipTitle =
    status === SubmitStatus.APPROVE
      ? 'Approve property'
      : status === SubmitStatus.REJECT
      ? 'Return property to Preparer'
      : 'Submit property to Reviewer';

  const showAdjustments =
    hasTSA &&
    investmentPropertyBatchId &&
    useIsApproverPlus() &&
    (!propertyBatch.properties_flattened_union ||
      propertyBatch.properties_flattened_union.property_mapping === '1:1');

  return {
    tabOnChange,
    tabValue,
    tabs: [
      {
        label: 'Package Reports',
        disabled: disablePackageReports,
        value: 0,
      },
      ...(showAdjustments
        ? [
            {
              label: 'Adjustments',
              disabled: isInPreparation(
                propertyBatch.property_batch_stage.property_batch_stage_id
              ),
              value: 1,
            },
          ]
        : []),
      {
        label: 'Validation Reports',
        disabled: isInPreparation(
          propertyBatch.property_batch_stage.property_batch_stage_id
        ),
        value: 2,
      },
    ],
    tabPanels: [
      {
        value: 0,
        panel: (
          <PackageReportsTable
            disableLastRowBorder
            property_batch_id={propertyBatch.property_batch_id}
            modalState={packageReportModalState}
            modalSearchState={modalSearchState}
            reportSelectors={reportSelectors}
            reportSlugModalState={reportSlugModalState}
          />
        ),
      },
      ...(showAdjustments && investmentPropertyBatchId
        ? [
            {
              value: 1,
              panel: (
                <TsaDatasetsTable
                  property_batch_id={investmentPropertyBatchId}
                />
              ),
            },
          ]
        : []),
      {
        value: 2,
        panel: (
          <DatasetsTable property_batch_id={propertyBatch.property_batch_id} />
        ),
      },
    ],
    tabActionsComponent:
      disabled && hideButtonIfDisabled
        ? null
        : !isApproved && (
            <Tooltip title={tooltipTitle}>
              <span>
                <Button
                  variant='contained'
                  disabled={disabled}
                  onClick={() =>
                    confirmModal({
                      title: `${statusLabel} Property Submission`,
                      content:
                        status === SubmitStatus.REJECT
                          ? 'Return property to preparer for corrections and re-upload.'
                          : `Are you sure you want to submit the property ${
                              propertyBatch.properties_flattened_union
                                .property_name ?? ''
                            } - ID ${
                              propertyBatch.properties_flattened_union
                                .property_code
                            }?`,
                      confirmLabel:
                        status === SubmitStatus.REJECT
                          ? 'Reject'
                          : 'Yes, Submit',
                      cancelLabel: 'Cancel',
                      handleConfirm: () => submitProperty(),
                    })
                  }
                >
                  {statusLabel} Property
                </Button>
              </span>
            </Tooltip>
          ),
  };
};
