import React from 'react';

import { SearchAssetIcon, styled } from '@cherre-frontend/ui';

const NoPropertiesFoundContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NoPropertiesFoundHeader = styled('h3')`
  font-size: 20px;
  margin: 6px 0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

type NoPropertiesFoundProps = {
  title: string;
};

export const NoPropertiesFound: React.FC<NoPropertiesFoundProps> = ({
  title,
}) => {
  return (
    <NoPropertiesFoundContainer>
      <SearchAssetIcon fontSize='large' color='disabled' />
      <NoPropertiesFoundHeader>{title}</NoPropertiesFoundHeader>
    </NoPropertiesFoundContainer>
  );
};

NoPropertiesFound.displayName = 'NoBatchesFound';
