import { useCherreValue } from '@cherre-frontend/data-fetching';
import { Panel } from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { usePersona } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { expandedCardState } from '../../PropertyBatches/recoil';
import { SinglePropertyBatchBaseAccordionProps } from './types';
import { usePropertyStatus } from '../hooks/usePropertyStatus';
import { GenericPropertyBatchBaseAccordion } from './generic';
import { PropertyBatchTab } from './PropertyBatchTab';

export const SinglePropertyBatchBaseAccordion: React.FC<
  SinglePropertyBatchBaseAccordionProps
> = ({
  propertyBatch,
  investmentPropertyBatch,
  accordionSubtitleComponent,
  alertComponent,
  ...tabConfig
}) => {
  const user = useCherreValue(getDSPUser());
  const persona = usePersona();

  const id =
    investmentPropertyBatch?.property_batch_id ??
    propertyBatch.property_batch_id;

  const expanded = useCherreValue(expandedCardState(id));

  const {
    property_batch_datasets: datasets,
    property_batch_validations: validations,
    kpi_package_reports_reviewed_count,
  } = propertyBatch;

  const failedValidations = validations.filter((v) => !v.is_valid).length;

  const chipStatus = usePropertyStatus(propertyBatch.role_status);

  const batchHasComments = useMemo(() => {
    const hasComments = propertyBatch.comments_count.aggregate?.count
      ? propertyBatch.comments_count.aggregate?.count > 0
      : false;
    const hasReminders = propertyBatch.reminders_count.aggregate?.count
      ? propertyBatch.reminders_count.aggregate?.count > 0
      : false;
    return hasComments || hasReminders;
  }, [propertyBatch]);

  return (
    <GenericPropertyBatchBaseAccordion
      id={id}
      hasComments={batchHasComments}
      chipStatus={chipStatus}
      accordionHeaderComponent={
        <>
          {persona === 'preparer' || user?.provider_id ? '' : 'Target '}ID{' '}
          {persona === 'preparer' || user?.provider_id
            ? propertyBatch.properties_flattened_union.entity_id
            : propertyBatch.properties_flattened_union.property_code}
        </>
      }
      accordionSubtitleComponent={
        <>
          {accordionSubtitleComponent}
          {propertyBatch.datasets_uploaded.aggregate?.count}/{datasets.length}{' '}
          Dataset
          {datasets.length !== 1 && 's'} • {failedValidations} Failed Validation
          {failedValidations !== 1 && 's'} •{' '}
          {kpi_package_reports_reviewed_count} Packages Reviewed
        </>
      }
    >
      {expanded && (
        <Panel id={`property-batch-alert-${id}`} disableParent={false}>
          {alertComponent}
          <PropertyBatchTab tabConfig={tabConfig} />
        </Panel>
      )}
    </GenericPropertyBatchBaseAccordion>
  );
};
