/**
 * @generated SignedSource<<e76f1eec697ba610814663063e746ac9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queriesCanUploadTSAQuery$variables = {
  approver_plus_slugs: ReadonlyArray<string>;
  property_batch_id: number;
};
export type queriesCanUploadTSAQuery$data = {
  readonly sys_property_batches: ReadonlyArray<{
    readonly package_reports: ReadonlyArray<{
      readonly package_reports_datasets: ReadonlyArray<{
        readonly dataset_id: number;
      }>;
    }> | null;
    readonly properties_flattened_union: {
      readonly parent_property_id: number | null;
      readonly property_mapping: string | null;
    } | null;
    readonly property_id: number;
    readonly submission_id: number;
  }>;
};
export type queriesCanUploadTSAQuery = {
  response: queriesCanUploadTSAQuery$data;
  variables: queriesCanUploadTSAQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "approver_plus_slugs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_batch_id"
},
v2 = {
  "fields": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "approver_plus_slugs"
            }
          ],
          "kind": "ObjectValue",
          "name": "property_role_slug"
        }
      ],
      "kind": "ObjectValue",
      "name": "property_role"
    }
  ],
  "kind": "ObjectValue",
  "name": "properties_roles_users"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      (v2/*: any*/)
                    ],
                    "kind": "ObjectValue",
                    "name": "property"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_or.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      (v2/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "property_mapping",
                        "value": {
                          "_eq": "1:1"
                        }
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "properties_flattened_union"
                  }
                ],
                "kind": "ObjectValue",
                "name": "_or.1"
              }
            ],
            "kind": "ListValue",
            "name": "_or"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "property_batch_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_batch_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_property_batches",
    "kind": "LinkedField",
    "name": "sys_property_batches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "property_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submission_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_properties_flattened_union",
        "kind": "LinkedField",
        "name": "properties_flattened_union",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_mapping",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parent_property_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "package_reports_datasets_aggregate": {
                "count": {
                  "predicate": {
                    "_eq": 1
                  }
                }
              }
            }
          }
        ],
        "concreteType": "sys_package_reports",
        "kind": "LinkedField",
        "name": "package_reports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sys_package_reports_datasets",
            "kind": "LinkedField",
            "name": "package_reports_datasets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataset_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "package_reports(where:{\"package_reports_datasets_aggregate\":{\"count\":{\"predicate\":{\"_eq\":1}}}})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queriesCanUploadTSAQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queriesCanUploadTSAQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7d63ce52ae800051d707f04dad294a84",
    "id": null,
    "metadata": {},
    "name": "queriesCanUploadTSAQuery",
    "operationKind": "query",
    "text": "query queriesCanUploadTSAQuery(\n  $property_batch_id: Int!\n  $approver_plus_slugs: [String!]!\n) {\n  sys_property_batches(where: {property_batch_id: {_eq: $property_batch_id}, _or: [{property: {properties_roles_users: {property_role: {property_role_slug: {_in: $approver_plus_slugs}}}}}, {properties_flattened_union: {property_mapping: {_eq: \"1:1\"}, properties_roles_users: {property_role: {property_role_slug: {_in: $approver_plus_slugs}}}}}]}, limit: 1) {\n    property_id\n    submission_id\n    properties_flattened_union {\n      property_mapping\n      parent_property_id\n    }\n    package_reports(where: {package_reports_datasets_aggregate: {count: {predicate: {_eq: 1}}}}) {\n      package_reports_datasets {\n        dataset_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9a4cb61693705a34c427dd877b0a9ca";

export default node;
