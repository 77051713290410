/**
 * @generated SignedSource<<1bb311eb71006adda8203452cab6308e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SysUploadMasterFileInput = {
  chart_of_accounts_name: string;
  file_reference_source: string;
  file_source: string;
};
export type useUploadMasterFileMutation$variables = {
  input: SysUploadMasterFileInput;
};
export type useUploadMasterFileMutation$data = {
  readonly _sys_upload_master_file: {
    readonly master_chart_of_account_id: number;
  } | null;
};
export type useUploadMasterFileMutation = {
  response: useUploadMasterFileMutation$data;
  variables: useUploadMasterFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SysUploadMasterFileOutput",
    "kind": "LinkedField",
    "name": "_sys_upload_master_file",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "master_chart_of_account_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadMasterFileMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadMasterFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "151e5b635ac7f835eae1401cb64e41b7",
    "id": null,
    "metadata": {},
    "name": "useUploadMasterFileMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadMasterFileMutation(\n  $input: SysUploadMasterFileInput!\n) {\n  _sys_upload_master_file(input: $input) {\n    master_chart_of_account_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb1291132ba4c9cf5d3a1d1b623b130e";

export default node;
