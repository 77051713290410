import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useLocation } from 'react-router';
import { propertiesTableSelectedProvider } from 'src/products/data-submission-portal/recoil/dialog';
import { ProviderDetailsRoute } from 'src/products/data-submission-portal/routes';

export const useGetProviderId = () => {
  const route = useLocation();

  if (route.pathname.includes('properties')) {
    const propertiesProviderId = useCherreValue(
      propertiesTableSelectedProvider
    );

    return propertiesProviderId ?? 0;
  } else {
    const currentRoute = useCherreValue(
      ProviderDetailsRoute.routeParamSelector
    );
    return currentRoute?.provider_id ?? 0;
  }
};
