import { Theme } from '@cherre-frontend/ui';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { useCallback } from 'react';
import { getUnresolvedRemindersForPropertyBatch } from './recoil';
import { recoilPropertyBatchPackageReportInfoQuery$data } from './__generated__/recoilPropertyBatchPackageReportInfoQuery.graphql';

export const getLabel = (
  isReviewer: boolean,
  reviewed: boolean,
  rejected: boolean,
  theme: Theme
) => {
  if (rejected) {
    return {
      text: 'Rejected',
      color: theme.accents.error.main,
      background: theme.accents.error.light,
    };
  } else if (isReviewer && reviewed) {
    return {
      text: 'Approved',
      color: theme.accents.positive.main,
      background: theme.accents.positive.light,
    };
  } else if (isReviewer && !reviewed) {
    return {
      text: 'Pending',
      color: theme.accents.deals.main,
      background: theme.accents.warning.light,
    };
  } else if (!isReviewer && reviewed) {
    return {
      text: 'Reviewed',
      color: theme.accents.positive.main,
      background: theme.accents.positive.light,
    };
  } else {
    return {
      text: 'Pending Review',
      color: theme.accents.deals.main,
      background: theme.accents.warning.light,
    };
  }
};

export const useCheckReportHasComments = (
  propertyBatchId: number,
  info?: recoilPropertyBatchPackageReportInfoQuery$data['sys_property_batches_by_pk']
) => {
  const reminders = useCherreValue(
    getUnresolvedRemindersForPropertyBatch(propertyBatchId)
  );

  return useCallback(
    (packageReportId: number) => {
      if (!info) {
        return false;
      }

      const hasComments = info?.comments.some((comment) => {
        return comment.package_report_id === packageReportId;
      });
      const hasReminders = reminders?.some((reminder) => {
        return reminder.package_report_id === packageReportId;
      });
      return hasComments || hasReminders;
    },
    [reminders]
  );
};
