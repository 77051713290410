import { CheckResult } from '@cherre-frontend/data-fetching';

export const formatter = {
  tooltipMessage: (checkResult: CheckResult<unknown>) => {
    if (checkResult.type === 'success') {
      return undefined;
    }
    const template = (field: string) =>
      `Please fill out the following required fields: ${field}`;
    switch (checkResult.path.toString()) {
      case '<root>.first_name':
        return template('First Name');
      case '<root>.last_name':
        return template('Last Name');
      case '<root>.email':
        return template('Email');
      default:
        return 'Please select all required fields before proceeding';
    }
  },
};
